import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Table
import HouseholdsTable from '../../Tables/Households/HouseholdsTable'

// Action bar
import ActionBar from './ActionBar'

// Pagination
import PaginationFooter from '../../PaginationFooter'


// Material UI Components
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@mui/icons-material/Search'

const SEARCH_QUERY_LIMIT = 2000
const ITEMS_PER_PAGE = 20

class HouseholdTab extends Component {

  state = {
    query: "",
    limit: ITEMS_PER_PAGE,
    offset: 0,
    isFilterSet: false,
    // Sorting property for Reseller`s list.
    activeInactive: "desc",
    isShowingSearchResults: false,
    currentReseller: "",
    sortDirection: "desc",
    sortDirectionStartDate: "desc",
    sortDirectionEndDate: "desc",
    sortByValue: "_ts",
  }

  componentDidMount() {
    const { getHouseholds } = this.props
    const { query, limit, offset, sortDirection, sortByValue } = this.state

    getHouseholds({ q: query, limit, offset, sortDirection: sortDirection, sortByValue: sortByValue, householdStatus: this.getActiveInactive() })
  }

  getMore = () => {
    this.setState({ limit: ITEMS_PER_PAGE, offset: this.state.offset + ITEMS_PER_PAGE }, () => this.refreshList())
  }

  getLess = () => {
    if (this.state.offset > 0) this.setState({ limit: ITEMS_PER_PAGE, offset: this.state.offset - ITEMS_PER_PAGE }, () => this.refreshList())
  }

  refreshList = () => {
    const { query, limit, offset, currentReseller, isFilterSet, sortDirection, sortByValue } = this.state
    const { getHouseholds, getHouseholdsByResellerId } = this.props

    if (isFilterSet) getHouseholdsByResellerId({ resellerId: currentReseller, offset: offset, limit: limit, sortDirection: sortDirection, sortByValue: sortByValue, householdStatus: this.getActiveInactive() })
    return getHouseholds({ q: query, limit: limit, offset: offset, sortDirection: sortDirection, sortByValue: sortByValue, householdStatus: this.getActiveInactive() })
  }

  // Depending if we have a filter set the API call will get the API call again with a 0 offset
  navHome = () => {
    const { query, limit, offset, currentReseller, isFilterSet, sortDirection, sortByValue } = this.state
    const { getHouseholds, getHouseholdsByResellerId } = this.props

    this.setState({ offset: 0 }, () => {
      if (isFilterSet) getHouseholdsByResellerId({ resellerId: currentReseller, offset: 0, limit: limit, sortDirection: sortDirection, sortByValue: sortByValue, householdStatus: this.getActiveInactive() })
      return getHouseholds({ q: query, limit: ITEMS_PER_PAGE, offset: 0, sortDirection: sortDirection, sortByValue: sortByValue, householdStatus: this.getActiveInactive() })
    })

  }

  getActiveInactive = () =>
  {
    const { activeInactive } = this.state
    if(activeInactive === "desc") return "monitored"
    if(activeInactive === "asc") return "unmonitored"
    return "all"
  }

  // If the query is null then we reset the pagination state
  handleOnSearch = (query) => {
    const { getHouseholds } = this.props
    const { sortDirection, sortByValue } = this.state


    if (query && query.length >= 3) {
      this.setState({ isShowingSearchResults: true })
      getHouseholds({ q: query, limit: SEARCH_QUERY_LIMIT, offset: 0, sortDirection: sortDirection, sortByValue: sortByValue })
    }
    else if (query.length === 0) {
      this.setState({ isShowingSearchResults: true })
      getHouseholds({ q: query, limit: ITEMS_PER_PAGE, offset: this.state.offset, sortDirection: sortDirection, sortByValue: sortByValue })
    }

  }

  handleGetHouseholdsByResellerId = (resellerId) => {
    const { getHouseholdsByResellerId } = this.props
    const { sortDirection, sortByValue } = this.state

    this.setState({ isFilterSet: true, currentReseller: resellerId, isShowingSearchResults: false })
    getHouseholdsByResellerId({ resellerId: resellerId, offset: 0, limit: ITEMS_PER_PAGE, sortDirection: sortDirection, sortByValue: sortByValue })
  }

  // Updates the sort order by desc or asc, also gets different list depending if the filter is set
  handleUpdateSortOrder = (sortByValue) => {
    const { getHouseholds, getHouseholdsByResellerId } = this.props

    // Sort all households
    if (!this.state.isFilterSet) {
      getHouseholds(
        {
          q: this.state.query,
          limit: this.state.limit,
          offset: this.state.offset,
          sortDirection: this.state.sortDirection === "desc" ? "asc" : "desc",
          sortByValue: sortByValue,
          householdStatus: this.state.activeInactive === "desc" ? "active" : "inactive",
        })
    }
    else // Sort the filtered by reseller is set
    {
      getHouseholdsByResellerId(
        {
          resellerId: this.state.currentReseller,
          offset: 0,
          limit: ITEMS_PER_PAGE,
          sortDirection: this.state.sortDirection === "desc" ? "asc" : "desc",
          sortByValue: sortByValue,
          householdStatus: this.state.activeInactive === "desc" ? "active" : "inactive",
        })
    }

    this.setState(
      {
        sortDirection: this.state.sortDirection === "desc" ? "asc" : "desc",
        sortByValue: sortByValue,
        sortDirectionStartDate: sortByValue === "billing.billingStartDate" ? this.state.sortDirection === "desc" ? "asc" : "desc" : this.state.sortDirection,
        sortDirectionEndDate: sortByValue === "billing.billingEndDate" ? this.state.sortDirection === "desc" ? "asc" : "desc" : this.state.sortDirection,
      })

  }

  filterByActiveInactive = () =>
  {
    const { activeInactive, sortByValue, isFilterSet } = this.state
    const { getHouseholds, getHouseholdsByResellerId } = this.props

    console.log(activeInactive);

    this.setState({ activeInactive: activeInactive === "desc" ? "asc" : "desc" }, () => {

      // Sort all households
      if (!isFilterSet)
      {
        getHouseholds(
          {
            q: this.state.query,
            limit: this.state.limit,
            offset: this.state.offset,
            sortDirection: this.state.sortDirection,
            sortByValue: sortByValue,
            householdStatus: this.getActiveInactive(),
          })
      }
      else // Sort the filtered by reseller is set
      {
        getHouseholdsByResellerId(
          {
            resellerId: this.state.currentReseller,
            offset: 0,
            limit: ITEMS_PER_PAGE,
            sortDirection: this.state.sortDirection,
            sortByValue: sortByValue,
            householdStatus: this.getActiveInactive(),
          })
      }

    })
  }

  render() {

    const { households, resellerHouseholds, resellers, getHouseholds, isGettingAllHouseholds, token } = this.props
    const { sortByValue } = this.state

    return (
      <div>
        <ActionBar
          sortByValue={sortByValue}
          token={token}
          households={this.state.isFilterSet ? resellerHouseholds : households}
          sortDirection={this.state.sortDirection}
          cancelFilter={() => this.setState({ isFilterSet: false })}
          getHouseholds={getHouseholds}
          onSelectItem={(resellerId) => this.handleGetHouseholdsByResellerId(resellerId)}
          resellers={resellers}
          onTextChange={(query) => this.handleOnSearch(query)} />

        {isGettingAllHouseholds ? <Grid container justify="center"><CircularProgress style={styles.progressSpinner} /></Grid> :
          households && households.length > 0 &&
          <div>
            {/* Main Table List */}
            <HouseholdsTable
              filterByActiveInactive={(value) => this.filterByActiveInactive(value) }
              activeInactive={this.state.activeInactive}
              sortDirectionStartDate={this.state.sortDirectionStartDate}
              sortDirectionEndDate={this.state.sortDirectionEndDate}
              sortByValue={this.state.sortByValue}
              sortDirection={this.state.sortDirection}
              changeSortOrder={(sortByValue) => this.handleUpdateSortOrder(sortByValue)}
              resellerId={""}
              onTableItemClick={() => console.log("onTableItemClick")}
              households={this.state.isFilterSet ? resellerHouseholds : households} />
          </div>
        }

        {/* No Data View (Nothing found etc..) */}
        {!isGettingAllHouseholds && households && households.length === 0 &&
          < Grid style={{ padding: 200 }} container justifyContent="center">
            <SearchIcon style={{ width: 30, height: 30, paddingTop: 5 }} />
            <Typography variant="h4" component="body2">{"No Households Found"}</Typography>
          </Grid>
        }

        {!isGettingAllHouseholds && !this.state.isShowingSearchResults &&
          <PaginationFooter
            onHomeClick={() => this.navHome()}
            getMore={() => this.getMore()}
            getLess={() => this.getLess()} />}
      </div>
    )
  }
}

HouseholdTab.propTypes = {
  getHouseholds: PropTypes.func,
  query: PropTypes.string,
  limit: PropTypes.number,
  offset: PropTypes.number,
  households: PropTypes.array,
  resellers: PropTypes.array,
  getHouseholdsByResellerId: PropTypes.func,
  isGettingAllHouseholds: PropTypes.bool,
  resellerHouseholds: PropTypes.array,
  token: PropTypes.string,
}

const styles = {
  progressSpinner: {
    marginTop: 100,
  },
}

export default HouseholdTab