import React, { useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { purple } from '@material-ui/core/colors';
import { getInitials } from '../utils/getInitials';
import { AssignedRolesList } from './AssignedRolesList';
import { RolesListModal } from '../RolesListModal';
import { useDispatch, useSelector } from 'react-redux';
import { addUserToOrganisationBegin, addUserToOrganisationClear } from '../../../../../redux/domains/organisations/actionCreators';
import { ApplicationState } from '../../../../../interfaces/reduxState';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SnackAlert } from '../../Shared/SnackAlert';

interface UserRoleAssignModalProps
{
  handleClose: () => void;
  open: boolean;
  parentOuId: string;
}

export const UserRoleAssignModal = ({ handleClose, open, parentOuId }: UserRoleAssignModalProps) =>
{
  const [ email, setEmail ] = useState("")
  const [ roles, setRoles ] = useState<string[]>([])
  const [ showRolesListModal, setShowRolesListModal ] = useState(false);

  const addUserToOrganisationSuccessResult = useSelector(({ organisations }: ApplicationState) => organisations.addUserToOrganisationSuccessResult)
  const isAddingUserToOrganisation = useSelector(({ organisations }: ApplicationState) => organisations.isAddingUserToOrganisation)
  const addUserToOrganisationError = useSelector(({ organisations }: ApplicationState) => organisations.addUserToOrganisationError)

  const dispatch = useDispatch();

  const handleExit = () =>
  {
    setEmail("")
    setRoles([])
    dispatch(addUserToOrganisationClear())
  }

  const handleRoleSelect = (role: string) =>
  {
    setRoles(roles.concat(role))
    setShowRolesListModal(false)
  }

  const handleRoleRemove = (role: string) => setRoles(roles.filter(r => r !== role))

  const handleSave = () =>
  {
    const user = {
      email: email,
      roles: roles,
    }
    dispatch(addUserToOrganisationBegin(parentOuId, user))
  }

  return(
    <Dialog
      TransitionProps={{
        onExit: handleExit,
      }}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}>
      <DialogTitle>Add existing user and assign roles</DialogTitle>
      <DialogContent>

        {/* User email input  */}
        <Grid container direction="row" alignItems="center" spacing={3}>

          <Grid item>
            <IconButton>
              <Avatar style={{ width: 100, height: 100, backgroundColor: purple[500], fontSize: 32 }}>{getInitials(email)}</Avatar>
            </IconButton>
          </Grid>

          <Grid item style={{ flex: 1 }}>
            <TextField
              onChange={(e: any) => setEmail(e.target.value)}
              value={email}
              autoFocus
              id="Email"
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
            />
          </Grid>

        </Grid>


        <AssignedRolesList
          onAssignRole={() => setShowRolesListModal(true)}
          onItemRemove={(role) => handleRoleRemove(role)}
          roles={roles}
          // @ts-ignore
          selectedItem={"something"} />


        {/* Assign Roles Modal */}
        <RolesListModal
          onRoleSelect={(role) => handleRoleSelect(role)}
          handleClose={() => setShowRolesListModal(false)}
          open={showRolesListModal}
        />

        <SnackAlert open={addUserToOrganisationSuccessResult ? true : false } onClose={() => dispatch(addUserToOrganisationClear())} alertMsg={addUserToOrganisationSuccessResult} color="success" />
        <SnackAlert open={addUserToOrganisationError ? true : false } onClose={() => dispatch(addUserToOrganisationClear())} alertMsg={addUserToOrganisationError} color="error" />


      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          disabled={addUserToOrganisationSuccessResult ? true : false}
          endIcon={isAddingUserToOrganisation && <CircularProgress size={16} />}
          onClick={handleSave}>{isAddingUserToOrganisation ? `Adding...` : "Add"}</Button>
      </DialogActions>
    </Dialog>
  )
}

