import { theme as proTheme } from './proTheme'
import { theme as v1Theme } from './v1Theme'

export default function getTheme(theme) {
  switch (theme) {
    case 'pro':
      return proTheme

    default:
      return v1Theme
  }
}