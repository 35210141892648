import React, { Component } from 'react'
// @ts-ignore
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Typograph from '@material-ui/core/Typography'

interface BatteryGraph2Props {
  formattedData: {
    formatted: [];
    keys: string[];
  }
}

const lineColors: string[] = [
  "#ef5350",
  "#EC407A",
  "#AB47BC",
  "#7E57C2",
  "#5C6BC0",
  "#42A5F5",
  "#26C6DA",
  "#26A69A",
  "#66BB6A",
  "#9CCC65",
  "#FFCA28",
  "#FFA726",
  "#2E7D32",
  "#0D47A1",
  "#757575",
  "#607D8B",
  "#E64A19",
  "#311B92",
  "#004D40",
  "#33691E",
  "#9575CD",
  "#AD1457",
]



export class BatteryGraph2 extends Component<BatteryGraph2Props, { opacity: number[] }> {

  state = {
    opacity: [],
  }

  componentDidUpdate(prevprops: BatteryGraph2Props) {
    if (prevprops != this.props) {
      this.setState({
        opacity: this.props.formattedData.keys.map((k, i) => {
          return 1;
        }),
      })
    }
  }

  handleEnter = (a: any, b: any, c: any) => {
    const { opacity } = this.state
    this.setState({ opacity: opacity.map((o, i) => i == b ? 1.0 : 0.2) })
  }
  handleLeave = (a: any, b: any, c: any) => {
    const { opacity } = this.state
    // const [opacity, setOpacity] = React.useState([])
    // setOpacity({ ...opacity, [a.dataKey]: 0.5 })
    this.setState({ opacity: opacity.map(() => 1) })
  }

  render() {

    const {
      opacity,
    } = this.state;

    const {
      formattedData,
    } = this.props;
    return (
      <div style={styles.container}>
        <div style={styles.heading}>
          <Typograph variant="h6" color='textSecondary'>Sensor Battery Levels</Typograph>
          <Typograph variant="subtitle1" color="textSecondary">Shows sensor battery levels over the year</Typograph>
        </div>

        <ResponsiveContainer width="100%" height={600}>
          <LineChart
            width={'100%'}
            height={600}
            data={formattedData.formatted}>
            <XAxis style={{ fontSize: 10 }} angle={0} textAnchor='end' dataKey="timestamp" />
            <YAxis />
            <Tooltip />
            <Legend onMouseOver={this.handleEnter} onMouseOut={this.handleLeave} />
            {formattedData.keys.map((sensorName: string, key: number) => {
              return <Line dot={false} key={key} type="monotone" dataKey={sensorName} stroke={lineColors[key]} strokeWidth={2} fill={lineColors[key]} connectNulls strokeOpacity={opacity[key]} />
            })}
          </LineChart>
        </ResponsiveContainer>
      </div>
    )
  }
}


const styles = {
  container: {
    width: '100%',
    height: 700,
    fontFamily: "Roboto, sans-serif",
  },
  heading: {
    paddingLeft: 35,
    paddingBottom: 12,
  },
}
