import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

// Material UI components
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

// Material UI icons
import Battery20 from '@mui/icons-material/Battery20'
import Battery30 from '@mui/icons-material/Battery30'
import Battery50 from '@mui/icons-material/Battery50'
import Battery60 from '@mui/icons-material/Battery60'
import Battery80 from '@mui/icons-material/Battery80'
import Battery90 from '@mui/icons-material/Battery90'
import BatteryAlert from '@mui/icons-material/BatteryAlert'
import BatteryFull from '@mui/icons-material/BatteryFull'

const BatteryItem = ({ sensor }) => {

  BatteryItem.propTypes = {
    sensor: PropTypes.object,
  }

  return (
    <div>
      <Grid justify="center" container item >

        {/* Depending on the battery level render a different icon */}

        {sensor.batteryLevel === 100 && <BatteryFull style={{ position: "relative", bottom: 3, color: "#4CAF50" }} />}
        {sensor.batteryLevel >= 90 && sensor.batteryLevel < 100 && <Battery90 style={{ position: "relative", bottom: 3, color: "#4CAF50" }} />}
        {sensor.batteryLevel >= 80 && sensor.batteryLevel < 90 && <Battery80 style={{ position: "relative", bottom: 3, color: "#9CCC65" }} />}
        {sensor.batteryLevel >= 60 && sensor.batteryLevel < 80 && <Battery60 style={{ position: "relative", bottom: 3, color: "#8BC34A" }} />}
        {sensor.batteryLevel >= 50 && sensor.batteryLevel < 60 && <Battery50 style={{ position: "relative", bottom: 3, color: "#8BC34A" }} />}
        {sensor.batteryLevel >= 30 && sensor.batteryLevel < 50 && <Battery30 style={{ position: "relative", bottom: 3, color: "#FDD835" }} />}
        {sensor.batteryLevel >= 20 && sensor.batteryLevel < 30 && <Battery20 style={{ position: "relative", bottom: 3, color: "#f44336" }} />}
        {sensor.batteryLevel < 20 && <BatteryAlert style={{ position: "relative", bottom: 3, color: "#c62828" }} />}

        <Typography variant="body2" component="p">{sensor.batteryLevel}%</Typography>

      </Grid>

      <Grid item>
        <Typography align="center" style={{ fontSize: 12 }} variant="body2" component="p">{sensor.friendlyName}</Typography>
        <Typography color="textSecondary" align="center" style={{ fontSize: 10 }} variant="body2" component="p">{sensor.lastSeen && moment(sensor.lastSeen).fromNow()}</Typography>

      </Grid>
    </div>
  )
}

export default BatteryItem