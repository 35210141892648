import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';

import ClearIcon from '@mui/icons-material/Clear';
import HomeIcon from '@mui/icons-material/Home';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import { AuditLogRow } from './AuditLogRow';
import { useAuditLogs } from './hooks/useAuditLogs';
import { LoadingView } from './LoadingView';
import { NoResults } from './NoResults';

interface AuditLogsViewProps
{
  token: string;
}

export const AuditLogsView = ({ token }: AuditLogsViewProps) =>
{
  const [ isLoading, query, pageBack, pageForward, currentPage, auditRecords, handleSearchWithDebounce, navHome, clearSearch ] = useAuditLogs(token)
  return(
    <div style={styles.container}>
      <TextField
        InputProps={{
          endAdornment: <InputAdornment position="end">
            <IconButton onClick={clearSearch}>
              {query && <ClearIcon />}
            </IconButton>
          </InputAdornment>,
        }}
        value={query}
        color="primary"
        label="Search by description"
        placeholder="Search by description"
        onChange={(e) => handleSearchWithDebounce(e.target.value)}
        fullWidth
        variant="outlined"
        style={styles.input}/>

      {isLoading ? <LoadingView /> : !isLoading && auditRecords.length > 0 ? <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={styles.headerText}>Event time</TableCell>
              <TableCell style={styles.headerText}>Description</TableCell>
              <TableCell style={styles.headerText} align="right">Household</TableCell>
              <TableCell style={styles.headerText} align="right">Admin</TableCell>
              <TableCell style={styles.headerText} align="right">User Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {auditRecords.map((row, key) => <AuditLogRow key={key} row={row} />)}
          </TableBody>
        </Table>
      </TableContainer> :
        <NoResults /> }

      {/* Pagination Footer */}
      <div style={styles.paginationFooter}>
        <Typography color="textSecondary">{`Page ${currentPage}`}</Typography>
        <IconButton onClick={pageBack}>
          <ArrowBack/>
        </IconButton>

        <IconButton onClick={navHome}>
          <HomeIcon />
        </IconButton>

        <IconButton onClick={pageForward}>
          <ArrowForward />
        </IconButton>
      </div>

    </div>
  )
}


const styles = {
  container: {
    marginTop: 12,
  },
  input: {
    marginBottom: 12,
  },
  paginationFooter: {
    padding: 12,
    textAlign: 'center' as 'center',
  },
  headerText: {
    fontWeight: 'bold' as 'bold',
  },
}