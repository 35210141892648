import React, { Component } from "react";
import { SystemHealthChart } from "./SystemHealthChart";
import { HouseholdHealthRollup } from "./interface";
import { StatsView } from "./StatsView";

interface SystemHealthTabTabProps {
  getRollup(): void;
  householdsHealthRollup: HouseholdHealthRollup[];
  householdsHealthRollupError: string;
  isGettingHouseholdsHealth: boolean;
}

class SystemHealthTab extends Component<SystemHealthTabTabProps> {
  componentDidMount() {
    const { getRollup } = this.props;
    getRollup();
  }

  render() {
    const { householdsHealthRollup, isGettingHouseholdsHealth } = this.props;
    return (
      <div>
        {!isGettingHouseholdsHealth && (
          <>
            <SystemHealthChart householdsHealthRollup={householdsHealthRollup} />
            <StatsView householdsHealthRollup={householdsHealthRollup} />
          </>
        )}
      </div>
    );
  }
}

export default SystemHealthTab;
