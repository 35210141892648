import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector } from 'react-redux';
import { ISensor } from "@intelicare/shared/interfaces/household/ISensor"
import { ApplicationState } from '../../../../../interfaces/reduxState';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Sensors from '@mui/icons-material/Sensors';
import Avatar from '@mui/material/Avatar';

interface AddSensorModalProps
{
  open: boolean;
  close: () => void;
  onSensorAdd: (sensor: ISensor) => void;
}

export const AddSensorModal = ({ open, close, onSensorAdd }: AddSensorModalProps) =>
{

  const sensors = useSelector((state: ApplicationState) => state.householdsV2.sensorDetails)

  return(
    <Dialog open={open} onClose={close} >
      <DialogTitle>Add sensor to room</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Assign sensor to this room
        </DialogContentText>
        <List>
          {sensors && sensors.map((sensor: ISensor) => {
            return (
              <ListItem button onClick={() => onSensorAdd(sensor)}>
                <ListItemAvatar>
                  <Avatar style={styles.avatar}>
                    <Sensors />
                  </Avatar>
                </ListItemAvatar>
                {/* @ts-ignore */}
                <ListItemText primary={sensor.friendlyName || sensor.name} secondary={`${sensor.product} - ${sensor.roomName}`} />
              </ListItem>
            )
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  avatar: {
    backgroundColor: "#42A5F5",
  },
}