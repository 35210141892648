const convertCSV = (sensorEvents) => {

  return sensorEvents.map(sensor => {
    return {
      eventTime: sensor.eventTime,
      sensorId: sensor.sensorId,
      deviceType: sensor.deviceType,
      newState: sensor.newState,
      oldState: sensor.oldState,
      roomType: sensor.roomType,
      behaviour: sensor.behaviour ? sensor.behaviour : "None",
    }
  })
}

export default convertCSV