import React, { Component } from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import NotificationIcon from './NotificationIcon'
import ActionedIcon from './ActionedIcon'

import styles from './HouseholdInfoStyles'

import { img, colors } from '../../../../redux'


const proDefaultAvatar = img.pro.defaultAvatar
const {
  alert,
  amber,
  okay,
  disable,
} = colors

export class HouseholdInfoView extends Component {
  constructor(props) {
    super(props)

    this.getAvatar = this.getAvatar.bind(this)
  }

  getAvatar() {
    const { household } = this.props
    if (!household.avatar) return proDefaultAvatar
    if (household.avatar === "BAD_DATA") return proDefaultAvatar
    return household.avatar
  }

  render() {
    const {
      household,
      classes,
      toggleFavourite,
      isDeveloper,
      viewHousehold,
    } = this.props

    let statusColor = disable // triagePriority === 0
    if (household.triagePriority === 1)
      statusColor = okay
    if (household.triagePriority === 2)
      statusColor = amber
    if (household.triagePriority === 3)
      statusColor = alert

    return (
      <Grid container direction="row"
        className={classes.Root}
        style={{ borderLeftColor: statusColor }}
      >
        <img alt="avatar" className={classes.Avatar} src={this.getAvatar()} />
        <Grid item container xs wrap="nowrap" zeroMinWidth direction="column" style={{ paddingLeft: 16 }}>
          <Grid item zeroMinWidth>
            <Typography variant="h6" noWrap>{household.friendlyName}</Typography>
          </Grid>
          <Grid item zeroMinWidth xs>
            <Grid container item xs direction="row" alignItems="center" zeroMinWidth>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={() => { viewHousehold() }}
              >
                View
              </Button>
              <NotificationIcon
                alertCount={household.alertCount > 0 ? household.alertCount : 0} // Don't display a negative notification count
                notificationCount={household.notificationCount > 0 ? household.notificationCount : 0} // Don't display a negative notification count
                onClick={viewHousehold}
              />
              {isDeveloper &&
                <ActionedIcon />
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid >
    )
  }
}

HouseholdInfoView.propTypes = {
  household: PropTypes.object,
  classes: PropTypes.object,
  toggleFavourite: PropTypes.func,
  isDeveloper: PropTypes.bool,
  viewHousehold: PropTypes.func,
}

export default withStyles(styles)(HouseholdInfoView)
