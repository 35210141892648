import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Home from '@mui/icons-material/Home'
import InteliIcon from '../../../components/InteliIcon'
import { history } from '../../../hoc/CustomRouter'

const HouseholdNotFound = () => {

  return (
    <Grid style={styles.container} spacing={2} container justify="center" direction="column">
      <Grid item>
        <InteliIcon color={'grey'} name="sentiment_dissatisfied" size={80} />
        <Typography color="textSecondary" variant="h5">{`Looks like this household doesn't exist`}</Typography>
      </Grid>
      <Grid item>
        <Button
          size="large"
          startIcon={<Home />}
          variant="contained"
          color="primary"
          onClick={() => history.push("/")}>
          Households List</Button>
      </Grid>
    </Grid>
  )
}

const styles = {
  container: {
    padding: 100,
    textAlign: "center",
  },
}

export default HouseholdNotFound