export const notificationSeverities = {
  'ALERT': 'ALERT',
  'NOTIFICATION': 'NOTIFICATION',
  'INFO': 'INFO',
}

export const notificationStatus = {
  'ACTIVE': 'ACTIVE',
  'ACKNOWLEDGED': 'ACKNOWLEDGED',
}

export const notificationSeverityValues = {
  'ALERT': 1,
  'NOTIFICATION': 2,
  'INFO': 3,
}

export const notificationStatusValues = {
  'ACTIVE': 1,
  'ACKNOWLEDGED': 2,
}