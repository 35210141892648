import React, { useState, useEffect } from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import { getInitials } from '../Modals/utils/getInitials'
import { teal } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import { ConfirmDelete } from '../../../../components/ConfirmDelete'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetailsForCacheBegin, removeUserFromOrganisationBegin } from '../../../../redux/domains/organisations/actionCreators'
import { EditUserRoleModal } from '../Modals/CreateEditUserModal/EditUserRoleModal'
import { ApplicationState } from '../../../../interfaces/reduxState'
import Skeleton from 'react-loading-skeleton'
import { amILoading } from '../Modals/utils/amILoading'
interface UserItemProps
{
  id: string;
  organisationId: string;
  roles: string[]
}

export const UserItem = ({ id, roles, organisationId }: UserItemProps) =>
{
  const [ showConfirmDelete, setShowConfirmDelete ] = useState(false)
  const [ showEditUser, setShowEditUser ] = useState(false)

  const user = useSelector(({ organisations }: ApplicationState ) => organisations.userCache[id])
  const isGettingUserWithId = useSelector(({ organisations }: ApplicationState ) => organisations.isGettingUserWithId)

  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(getUserDetailsForCacheBegin(id))

  }, [])

  const handleRemoveUser = (userId: string, organisationId: string) =>
  {
    dispatch(removeUserFromOrganisationBegin(organisationId, userId))
  }

  return(
    <Card style={styles.container}>
      <CardHeader
        avatar={amILoading(id, isGettingUserWithId) ? <Skeleton circle /> : <Avatar style={styles.avatar}>{getInitials(user ? user.firstName + " " + user.lastName : "")}</Avatar>}
        title={amILoading(id, isGettingUserWithId) ? <Skeleton /> : user ? user.email : ""}
        subheader={roles.toString()}
        action={
          <>
            <IconButton onClick={() => setShowEditUser(true)} aria-label="edit">
              <Edit />
            </IconButton>
            <IconButton onClick={() => setShowConfirmDelete(true)} aria-label="delete">
              <Delete />
            </IconButton>
          </>
        }
      />

      <ConfirmDelete
        content="Do you want to remove this user?"
        title="Confirm Remove User"
        open={showConfirmDelete}
        handleClose={() => setShowConfirmDelete(false)}
        onConfirmAction={() => handleRemoveUser(id, organisationId)}
      />

      <EditUserRoleModal
        currentRoles={roles}
        userId={id}
        user={user}
        parentOuId={organisationId}
        open={showEditUser}
        handleClose={() => setShowEditUser(false)
        }
      />

    </Card>
  )
}

const styles = {
  avatar: {
    backgroundColor: teal[500],
  },
  container: {
    margin: 6,
  },
}