import React, { Component } from "react"

// MUI
import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'
import Switch from '@material-ui/core/Switch'
import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip';

// Interfaces
import { BehaviourDescription, BEHAVIOUR_TYPES } from '@intelicare/shared/enums'
import { AlertingDescription } from '../AlertDescriptions'
import { Sensor } from '@intelicare/shared/interfaces/gateway/Gateway'

// Icons
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

// Modals
import ConfirmDialog from "../../../ConfirmDialog/ConfirmDialog"
import { ConfigureAlertsModal } from '../../../ConfigureAlertsModal/ConfigureAlertsModal'
import ConfigureIncidentDetectionModal from '../../../ConfigureIncidentDetectionModal'
import { ConfigureMealPrepAlertModal } from '../../../ConfigureMealPrepAlertModal/ConfigureMealPrepAlertModal'
import { ConfigureClimateAlertModal } from '../../../ConfigureClimateAlertModal/ConfigureClimateAlertModal'
import { ConfigureWashingAlertModal } from '../../../ConfigureWashingAlertModal/ConfigureWashingAlertModal'
import { ConfigureSocialAlertModal } from '../../../ConfigureSocialAlertModal/ConfigureSocialAlertModal'
import ConfigureShowerAlertsModal from '../../../ConfigureShowerAlertsModal'
import { IBehaviourData } from "@intelicare/shared/interfaces/behaviours/Behaviour"
import { ZoneApiResponseItem } from "@intelicare/shared/interfaces/household/Zone"

interface InsightItemProps {
  isSupport: boolean;
  isDeveloper: boolean;
  behaviour: any;
  sensors: Sensor[];
  onUpdate: (behaviour: IBehaviourData) => void;
  zoneId: string;
  zonesAndRooms: ZoneApiResponseItem[];
}



export class InsightItem extends Component<InsightItemProps>
{
  state = {
    expanded: false,
    showDialog: false,
    openConfigureAlertsDialog: false,
    openMealPrepDialog: false,
    openShowerModal: false,
    openClimateDialog: false,
    openWashingDialog: false,
    openSocialDialog: false,
    openIncidentDialog: false,
    error: false,
    behaviour: this.props.behaviour,
  }

  getKeyByValue(object: any, value: any): string {
    const result = Object.keys(object).find(key => object[key] === value) as string
    return result
  }

  getBehaviourDescription() {
    const { behaviour } = this.props
    // @ts-ignore
    const description = BehaviourDescription[this.getKeyByValue(BEHAVIOUR_TYPES, behaviour.type)] || ''
    return description
  }

  toggleExpand() {
    const { expanded } = this.state
    this.setState({ expanded: !expanded })
  }

  setEnabledError = () =>
  {
    this.setState({ error: true })
  }

  updateBehaviour(behaviour: IBehaviourData) {
    const { sensors, onUpdate } = this.props

    let isEnable;
    if (sensors.length > 0)
    {
      switch (behaviour.name) {
        case 'mealPrep01':
          isEnable = !!sensors.find(s => s.behaviour === 'mealPrep')
          break;
        case 'doorAlert01':
          isEnable = !!sensors.find(s => s.access === 'true')
          break;
        case 'doorInfoMessage01':
          isEnable = !!sensors.find(s => s.access === 'true')
          break;
        case 'socialIsolation01':
          isEnable = !!sensors.find(s => s.access === 'true')
          break;
        case 'washing01':
          isEnable = !!sensors.find(s => s.behaviour === 'washing')
          break;
        default:
          isEnable = true
      }

    }
    else
    {
      isEnable = false
    }
    if (behaviour.enabled && !isEnable) this.setEnabledError()

    this.setState({ behaviour: behaviour })
    onUpdate(behaviour);
  }

  onConfigureAlertPress(behaviourType: string) {
    if (behaviourType === 'mealPrep') {
      this.setState({ openMealPrepDialog: true })
    }
    else if (behaviourType === 'climate') {
      this.setState({ openClimateDialog: true })
    } else if (behaviourType === "incidentDetection") {
      this.setState({ openIncidentDialog: true })
    } else if (behaviourType === "washing") {
      this.setState({ openWashingDialog: true })
    } else if (behaviourType === "socialIsolation") {
      this.setState({ openSocialDialog: true })
    } else {
      this.setState({ openConfigureAlertsDialog: true })
    }
  }

  render() {
    const { expanded, behaviour } = this.state
    const { isDeveloper, isSupport, onUpdate, zoneId, zonesAndRooms } = this.props
    if (
      behaviour.type === BEHAVIOUR_TYPES.INCIDENT_DETECTION
    ) {
      if (isDeveloper || isSupport) {
        // return the component
      }
      else {
        return <></>
      }
    }

    return (
      <Card style={styles.cardContainer}>
        <ListItem dense>
          <Grid container direction="row">

            {/* Toggle */}
            <Grid item>
              <Switch
                checked={behaviour.enabled}
                value={behaviour.enabled}
                onChange={(ev, checked) => this.updateBehaviour({ ...behaviour, enabled: checked })}
              />
            </Grid>

            {/* Title */}
            <Grid item >
              <Typography color="textPrimary" variant="h6" style={styles.heading}>{behaviour.friendlyName}</Typography>
            </Grid>

            {/* More dropdown */}
            <Grid item>
              <Tooltip title="Show the insight configuration and rules">
                <IconButton onClick={() => this.toggleExpand()}>
                  {!expanded ? <ExpandLess style={{ color: "grey" }} /> : <ExpandMore style={{ color: "grey" }} />}
                </IconButton>
              </Tooltip>
            </Grid>


            <Grid item xs={12}>
              <Typography color="textSecondary" variant="body1">{this.getBehaviourDescription()}</Typography>

              <ConfirmDialog
                zoneId={zoneId}
                behaviour={this.getKeyByValue(BEHAVIOUR_TYPES, behaviour.type)}
                open={this.state.showDialog}
                handleClose={() => this.setState({ showDialog: false })}>
              </ConfirmDialog>

              {/* Render the modal in the list item only if the type is of alert */}
              {(behaviour.type === "doorAlert" || behaviour.type === "doorInfoMessage") &&
                <ConfigureAlertsModal
                  zoneId={zoneId}
                  onUpdate={(behaviour) => onUpdate(behaviour)}
                  type={behaviour.type}
                  open={this.state.openConfigureAlertsDialog}
                  handleClose={() => this.setState({ openConfigureAlertsDialog: false })}
                />}
              {behaviour.type === "mealPrep" &&
                <ConfigureMealPrepAlertModal
                  behaviour={behaviour}
                  onUpdate={(behaviour) => onUpdate(behaviour)}
                  open={this.state.openMealPrepDialog}
                  handleClose={() => this.setState({ openMealPrepDialog: false })}
                />}
              {/* Open the Climate dialog page after 'CONFIGURE ALERTING' was clicked*/}
              {behaviour.type === BEHAVIOUR_TYPES.CLIMATE &&
                <ConfigureClimateAlertModal
                  zonesAndRooms={zonesAndRooms}
                  zoneId={zoneId}
                  behaviour={behaviour}
                  onUpdate={(behaviour) => onUpdate(behaviour)}
                  open={this.state.openClimateDialog}
                  handleClose={() => this.setState({ openClimateDialog: false })}
                />}
              {(behaviour.type === BEHAVIOUR_TYPES.WASHING) &&
                <ConfigureWashingAlertModal
                  behaviour={behaviour}
                  onUpdate={(behaviour) => onUpdate(behaviour)}
                  open={this.state.openWashingDialog}
                  handleClose={() => this.setState({ openWashingDialog: false })}
                />}
              {(behaviour.type === BEHAVIOUR_TYPES.SOCIAL_ISOLATION) &&
                <ConfigureSocialAlertModal
                  behaviour={behaviour}
                  onUpdate={(behaviour) => onUpdate(behaviour)}
                  open={this.state.openSocialDialog}
                  handleClose={() => this.setState({ openSocialDialog: false })}
                />}
              {(behaviour.type === "incidentDetection" && (isDeveloper || isSupport)) &&
                <ConfigureIncidentDetectionModal
                  open={this.state.openIncidentDialog}
                  handleClose={() => this.setState({ openIncidentDialog: false })}
                />}
              {behaviour.type === BEHAVIOUR_TYPES.SHOWER_USE &&
                <ConfigureShowerAlertsModal
                  open={this.state.openShowerModal}
                  handleClose={() => this.setState({ openShowerModal: false })}
                />}
              {/* Render the alert modal open button only if it's the alert behaviours */}
              {(behaviour.type === "doorAlert" || behaviour.type === "doorInfoMessage" || behaviour.type === BEHAVIOUR_TYPES.MEAL_PREP ||
                (behaviour.type === BEHAVIOUR_TYPES.SOCIAL_ISOLATION) || behaviour.type === BEHAVIOUR_TYPES.WASHING || behaviour.type === BEHAVIOUR_TYPES.CLIMATE) &&
                <Button
                  startIcon={<NotificationImportantIcon />}
                  onClick={() => this.onConfigureAlertPress(behaviour.type)}
                  style={styles.button}
                  color="secondary"
                  size="small"
                  variant="outlined">
                  Configure Alerting
                </Button>}

              {/* Hide the reset behaviours for the door alerting, insight detection, and shower use insights */}
              {(behaviour.type === "doorAlert" || behaviour.type === "doorInfoMessage" || behaviour.type === "incidentDetection" || behaviour.type === BEHAVIOUR_TYPES.SHOWER_USE) ? <></> :
                <Button
                  startIcon={<RotateLeftIcon />}
                  style={styles.button}
                  color="secondary"
                  size="small"
                  variant="outlined"
                  onClick={() => { this.setState({ showDialog: true }) }}>
                  Reset Behaviour
                </Button>}
              {(behaviour.type === "incidentDetection" && (isDeveloper || isSupport)) &&
                <Button
                  startIcon={<NotificationImportantIcon />}
                  onClick={() => this.onConfigureAlertPress(behaviour.type)}
                  style={styles.button}
                  color="secondary"
                  size="small"
                  variant="outlined">
                  Configure Incident Detection
                </Button>}
              {behaviour.type === BEHAVIOUR_TYPES.SHOWER_USE &&
                <Button
                  startIcon={<NotificationImportantIcon />}
                  onClick={() => this.setState({ openShowerModal: true })}
                  style={styles.button}
                  color="secondary"
                  size="small"
                  variant="outlined">
                  Configure Shower Alerts
                </Button>}
            </Grid>
          </Grid>

        </ListItem>
        <Collapse in={expanded}>
          <div style={{ textAlign: 'left', margin: 12 }}>
            {/* @ts-ignore */}
            {AlertingDescription[behaviour.type] &&
              <Typography color="textSecondary" variant="body1">
                {/* @ts-ignore */}
                {AlertingDescription[behaviour.type]}
              </Typography>}
          </div>


        </Collapse>
      </Card>
    )
  }
}


const styles = {
  button: {
    margin: 6,
  },
  heading: {
    paddingTop: 3,
  },
  cardContainer: {
    margin: 12,
  },
}
