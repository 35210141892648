import React from "react";

import { Chart } from "react-google-charts";

import { AlertBehaviour } from "../../interfaces/AlertBehaviour";
import { GraphBuilder } from "./helper/GraphBuilder";

interface TimeRangeProps {
  alertWindowStart: string;
  alertWindowEnd: string;
  doorInfoWindowStart: string;
  doorInfoWindowEnd: string;
}

export const TimeRange = ({ alertWindowStart, alertWindowEnd, doorInfoWindowStart, doorInfoWindowEnd }: TimeRangeProps) => {
  return (
    <Chart
      width={"auto"}
      height={"auto"}
      chartType="Timeline"
      loader={<div>Loading Chart</div>}
      data={new GraphBuilder(alertWindowStart, alertWindowEnd, doorInfoWindowStart, doorInfoWindowEnd).buildGraphs()}
      options={{
        showRowNumber: true,
      }}
      rootProps={{ "data-testid": "1" }}
    />
  );
};
