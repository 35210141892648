import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from '@mui/icons-material/Info';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ErrorIcon from '@mui/icons-material/Error';
import { alertRed, infoBlue, okayGreen, warningOrange } from "../../themes/proTheme";
import { Pending } from "@mui/icons-material";

type size = "small" | "inherit" | "large" | "medium" | undefined;
interface notificationIcon {
  tooltip: string;
  icon: any;
}

export default function getIcon(type: string, status: string, size: size = "large") {
  let icon = undefined;
  const icons = new Map<string, Map<string, notificationIcon>>();
  icons.set("ALERT", new Map<string, notificationIcon>().
    set("ACTIVE", { tooltip: "Active Alert", icon: <ErrorIcon style={styles.warningIconStyle} fontSize={size}/> }).
    set("PENDING", { tooltip: "Pending Alert", icon: <Pending style={styles.infoStyle} fontSize={size}></Pending> }).
    set("ACKNOWLEDGED", { tooltip: "Acknowledged Alert", icon: <CheckCircleIcon style={styles.resolvedStyle} fontSize={size}/> })
  );
  icons.set("NOTIFICATION", new Map<string, notificationIcon>().
    set("ACTIVE", { tooltip: "Active Notification", icon: <NotificationsActiveIcon style={styles.notificationStyle} fontSize={size}/> }).
    set("ACKNOWLEDGED", { tooltip: "Acknowledged Notification", icon: <NotificationsIcon style={styles.resolvedStyle} fontSize={size}/> })
  );
  icons.set("INFO", new Map<string, notificationIcon>().
    set("ACKNOWLEDGED", { tooltip: "Info", icon: <InfoIcon style={styles.infoStyle} fontSize={size}/> })
  );
  const iconsForType = icons.get(type);
  if (iconsForType) icon = iconsForType.get(status);
  if (icon) return <Tooltip title={icon.tooltip}>{icon.icon}</Tooltip>;
}

const styles = {
  warningIconStyle: { color: alertRed.main },
  resolvedStyle: { color: okayGreen.main },
  notificationStyle: { color: warningOrange.main },
  infoStyle: { color: infoBlue.main },
};
