import { connect } from 'react-redux'
import { RealtimeAlerting } from '../components/RealtimeAlertingSettingsView'
const mapState = (state: any) =>
{
  return {
    token: state.user.token,
    householdId: state.householdsV2.activeHouseholdId,
  }
}

export default connect(mapState, null)(RealtimeAlerting)