
import React from 'react'
import PropTypes from 'prop-types'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

const SensorSelect = ({ sensors, currentItem, onSelectChange }) => {

  SensorSelect.propTypes = {
    currentItem: PropTypes.array,
    onSelectChange: PropTypes.func,
    sensors: PropTypes.array,
  }

  return (
    <FormControl variant="outlined">
      <InputLabel id="demo-simple-select-outlined-label">{"Select Radar Sensor"}</InputLabel>
      <Select
        labelId="sensor-select"
        id="sensor-select-id"
        value={currentItem && currentItem.length > 0 && currentItem[0].id}
        label={currentItem && currentItem.length > 0 && currentItem[0].friendlyName}
      >
        {sensors && sensors.length > 0 && sensors.map((sensor, key) => {
          return <MenuItem onClick={() => onSelectChange(sensor)} key={key} value={sensor.id}>{sensor.friendlyName}</MenuItem>
        })}
      </Select>
    </FormControl>
  )
}

export default SensorSelect
