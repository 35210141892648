import React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ResellerIcon from '@mui/icons-material/SupervisedUserCircle'
import HomeIcon from '@mui/icons-material/Home'

const TabHeader = ({ onTabChange, currentTab }) => {

  TabHeader.propTypes = {
    onTabChange: PropTypes.func,
    currentTab: PropTypes.number,
  }

  return (
    <Tabs
      onChange={onTabChange}
      style={styles.tabs}
      value={currentTab}
      indicatorColor="secondary"
      textColor="secondary"
      aria-label="tabs">
      <Tab icon={<ResellerIcon />} label="Partners" />
      <Tab icon={<HomeIcon />} label="Households" />
    </Tabs>
  )
}

const styles = {

  tabs: {
    paddingTop: 20,
  },
}

export default TabHeader