import { connect } from 'react-redux'
import GatewayDetailsView from '../components/GatewayDetailsView'
import { compose } from 'redux'
import { withErrorHandler, Fallback } from '../../../../../hoc/withErrorHandler'
import sharedStore from '../../../../../redux'

const getGatewayTitle = sharedStore.systemSelector.getGatewayTitle
const callAPI = sharedStore.userStore.callAPI

const GATEWAYDATA = sharedStore.apiEndpoints.GATEWAYDATA

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGatewayData: (gatewayId, householdId) => {
      dispatch(callAPI(GATEWAYDATA({ gatewayId, householdId })))
    },
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.system.isFetchingGateway,
  gatewayTitle: getGatewayTitle(state),
})

/*  Note: mapStateToProps is where you should use `reselect` to create selectors, ie:

    import { createSelector } from 'reselect'
    const counter = (state) => state.counter
    const tripleCount = createSelector(counter, (count) => count * 3)
    const mapStateToProps = (state) => ({
      counter: tripleCount(state)
    })

    Selectors can compute derived data, allowing Redux to store the minimal possible state.
    Selectors are efficient. A selector is not recomputed unless one of its arguments change.
    Selectors are composable. They can be used as input to other selectors.
    https://github.com/reactjs/reselect    */

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorHandler(Fallback)
)(GatewayDetailsView)
