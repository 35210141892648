import _ from 'lodash'

export const GATEWAY_BATTERY_REPORT = (params) => {
  return {
    prefix: 'GATEWAY_BATTERY_REPORT',
    urls: [
      _.template('gateway/battery/report?<%= gatewayId %>')(params),
    ],
    params: params,
  }
}

export const GATEWAY_BATTERY_REPORT_ALL = (params) => {
  return {
    prefix: 'GATEWAY_BATTERY_REPORT_ALL',
    urls: [`gateway/battery/report?${params.queryString}`],
    params: { method: "GET" },
  }
}

export const GET_HOUSEHOLD_FROM_GATEWAY_ID = (params) => {
  return {
    prefix: 'GET_HOUSEHOLD_FROM_GATEWAY_ID',
    urls: [`gateway/battery/report?${params.queryString}`],
    params: { method: "GET" },
  }
}


export const ACTIVITYGRAPH = (params) => ({
  prefix: 'ACTIVITYGRAPH',
  urls: [
    _.template('data/graph2/<%= householdId %>/<%= timePeriod %>?summaryType=breakdown')(params),
  ],
  params: params,
})

export const ACTIVITY_GRAPH_WITH_ZONES = (params) => ({
  prefix: 'ACTIVITY_GRAPH_WITH_ZONES',
  urls: [`data/graph2/${params.householdId}/${params.timePeriod}?summaryType=breakdown`],
  params: params,
})

export const CLIENTSDATA = () => ({
  prefix: 'CLIENTSDATA',
  urls: [
    'data/user',
  ],
})

export const DATA_SENSOR_EVENT = (params) => ({
  prefix: 'DATA_SENSOR_EVENT',
  urls: [
    _.template('data/sensor/event/<%= householdId%>')(params),
  ],
  params: params,
})

export const GATEWAY_ADD_SENSOR_WAIT = (params) => ({
  prefix: 'GATEWAY_ADD_SENSOR_WAIT',
  urls: [
    _.template('gateway/<%= gatewayId%>/add/sensor/wait?householdId=<%= householdId%>')(params),
  ],
  params: { body: JSON.stringify(params.body), method: params.method },
})

export const GATEWAY_ADD_SENSOR_POLL = (params) => ({
  prefix: 'GATEWAY_ADD_SENSOR_POLL',
  urls: [
    _.template('gateway/<%= gatewayId%>/add/sensor/<%= sensorId%>/poll')(params),
  ],
  params: params,
})

export const GATEWAY_REMOTE_SERVICE = (params) => ({
  prefix: 'GATEWAY_REMOTE_SERVICE',
  urls: [
    _.template('gateway/remote-service/<%= gatewayId%>')(params),
  ],
  params: { body: JSON.stringify(params.body), method: 'POST' },
})

export const GATEWAY_FIRMWARE_DATA = (params) => ({
  prefix: 'GATEWAY_FIRMWARE_DATA',
  urls: [
    _.template('data/gateway-firmware/?searchValue=<%= searchValue%>&searchField=<%= searchField%>&dataSize=<%= dataSize%>&dataSizeSequence=<%= dataSizeSequence%>')(params),
  ],
})

export const GATEWAY_FIRMWARE_LOGFILE = (params) => ({
  prefix: 'GATEWAY_FIRMWARE_LOGFILE',
  urls: [
    _.template('data/gateway-firmware/?id=<%= gatewayId%>&logfile=<%= logFile%>')(params),
  ],
})

export const GATEWAY_FIRMWARE_LOGFILE_LIST = (params) => ({
  prefix: 'GATEWAY_FIRMWARE_LOGFILE_LIST',
  urls: [
    _.template('data/gateway-firmware/?gatewayId=<%= gatewayId%>')(params),
  ],
})

export const GATEWAY_FIRMWARE_UPDATE = (params) => ({
  prefix: 'GATEWAY_FIRMWARE_UPDATE',
  urls: [
    _.template('gateway/update-scheduler?gatewayId=<%= gatewayId%>')(params),
  ],
  params: { method: 'POST' },
})

export const GATEWAY_FIRMWARE_GET_JOB_ID = (jobId) => ({
  prefix: 'GATEWAY_FIRMWARE_GET_JOB_ID',
  urls: [
    `gateway/update-scheduler?jobId=${jobId}`,
  ],
  params: { method: 'GET' },
})

export const GATEWAY_FIRMWARE_CHECK = (params) => ({
  prefix: 'GATEWAY_FIRMWARE_CHECK',
  urls: [
    _.template('data/gateway-firmware/?id=<%= gatewayId%>')(params),
  ],
})


export const GET_ALL_NOTIFICATION_COUNT = ({ timeLimit, type, status, order, offset, limit }) => ({
  prefix: 'GET_ALL_NOTIFICATION_COUNT',
  urls: [
    `data/notifications?count=true&timeLimit=${timeLimit}&type=${type}&status=${status}&order=${order}&offset=${offset}&limit=${limit}`,
  ],
})

export const EXPORT_NOTIFICATIONS = ({ householdId, timeLimit, type, status, order, offset, limit }) => ({
  prefix: 'EXPORT_NOTIFICATIONS',
  urls: [
    `data/notifications?householdId=${householdId}&timeLimit=${timeLimit}&type=${type}&status=${status}&order=${order}&offset=${offset}&limit=${limit}`,
  ],
})

export const GET_ALL_ACTIVE_ALERTS = ({ timeLimit, order, offset, limit }) => ({
  prefix: 'GET_ALL_ACTIVE_ALERTS',
  urls: [
    `data/notifications?timeLimit=${timeLimit}&type=ALERT&status=ACTIVE&order=${order}&offset=${offset}&limit=${limit}`,
  ],
})

export const GET_ALL_NOTIFICATIONS = ({ timeLimit, type, status, order, offset, limit, search }) => ({
  prefix: 'GET_ALL_NOTIFICATIONS',
  urls: [
    `data/notifications?&timeLimit=${timeLimit}&type=${type}&status=${status}&order=${order}&offset=${offset}&limit=${limit}&search=${search}`,
  ],
})

export const GET_NOTIFICATIONS_HOUSEHOLD = ({ householdId, timeLimit, type, status, order, offset, limit, search }) => ({
  prefix: 'GET_NOTIFICATIONS_HOUSEHOLD',
  urls: [
    `data/notifications?householdId=${householdId}&timeLimit=${timeLimit}&type=${type}&status=${status}&order=${order}&offset=${offset}&limit=${limit}&search=${search}`,
  ],
})

export const NOTIFICATIONS_MANAGER_SEND = (params) => ({
  prefix: 'NOTIFICATIONS_MANAGER_SEND',
  params: { body: JSON.stringify(params.notification), method: 'PUT' },
  urls: [
    'notifications/manager',
  ],
})

export const OVERALLACTIVITYGRAPH = (params) => ({
  prefix: 'OVERALLACTIVITYGRAPH',
  urls: [
    _.template('data/graph2/<%= householdId %>/<%= timePeriod %>?summaryType=overall')(params),
  ],
  params: params,
})

export const SIPTOKEN = (params) => ({
  prefix: 'SIPTOKEN',
  urls: [
    'sip/token',
  ],
  params: params,
})

export const HOUSEHOLD_AUTHORIZE = (params) => ({
  prefix: 'HOUSEHOLD_AUTHORIZE',
  urls: [
    _.template('gateway/authorize/<%= gatewayId%>')(params),
  ],
  params: { body: JSON.stringify({}), method: 'POST' },
})

export const HOUSEHOLD_CREATE = (params) => ({
  prefix: 'HOUSEHOLD_CREATE',
  urls: [
    _.template('data/household')(params),
  ],
  params: { body: JSON.stringify(params), method: 'POST' },
})

export const DELETE_HOUSEHOLD_BY_GATEWAY_ID = (gatewayId, householdId) => ({
  prefix: 'DELETE_HOUSEHOLD_BY_GATEWAY_ID',
  urls: [`data/household`],
  params: {
    method: 'DELETE',
    body: JSON.stringify({
      gatewayId: gatewayId,
      householdId: householdId,
    }),
  },
})

export const HOUSEHOLDS_SENSORS = (params) => ({
  prefix: 'HOUSEHOLDS_SENSORS',
  urls: [
    _.template('data/household/<%= householdId%>/sensors')(params),
  ],
  params: { body: JSON.stringify(params.body), method: params.method },
})

export const HOUSEHOLDS_SENSORS_DELETE = (params) => ({
  prefix: 'HOUSEHOLDS_SENSORS_DELETE',
  urls: [
    _.template('data/household/<%= householdId%>/sensors')(params),
  ],
  params: { body: JSON.stringify(params.body), method: params.method },
})

export const HOUSEHOLDS_BILLING_PAYMENT_SOURCE = (params) => ({
  prefix: 'HOUSEHOLDS_BILLING_PAYMENT_SOURCE',
  urls: [
    _.template('data/household/<%= householdId%>/billing/payment/source<%= query%>')(params),
  ],
  params: { body: JSON.stringify(params.body), method: params.method },
})

export const HOUSEHOLD_USER = (params) => ({
  prefix: 'HOUSEHOLD_USER',
  urls: [
    _.template('data/household/<%= householdId%>/user/<%= userId%><%= query%>')(params),
  ],
  params: { body: JSON.stringify(params.body), method: params.method },
})

export const HOUSEHOLD_AVATARS = (params = { method: 'GET', body: null }) => ({
  prefix: 'HOUSEHOLD_AVATARS',
  urls: [
    'data/avatar',
  ],
  params: {
    method: params.method,
    body: params.method === 'POST' ? JSON.stringify(params.body) : undefined,
  },
})

export const HOUSEHOLD_AVATAR_UPLOAD = (params) => ({
  prefix: 'HOUSEHOLD_AVATAR_UPLOAD',
  urls: [
    _.template('data/household/<%= householdId%>/avatar')(params),
  ],
  params: { body: params.image, contentType: 'text/plain', method: 'POST' },
})

export const HOUSEHOLD_SETTING_UPDATE = (params) => ({
  prefix: 'HOUSEHOLD_SETTING_UPDATE',
  urls: [
    _.template('data/household/<%= householdId%>/settings?setting=<%= setting%>&value=<%= value%>')(params),
  ],
  params: { ...params, method: 'POST', responseType: null }, //body: JSON.stringify(params) },
})

export const HOUSEHOLD_CARER_LIST = (params) => ({
  prefix: "HOUSEHOLD_CARER_LIST",
  urls: [
    _.template('household/<%= householdId%>/authorisation')(params),
  ],
  params: params,
})

export const HOUSEHOLD_CARER_ADD = (params) => ({
  prefix: "HOUSEHOLD_CARER_ADD",
  urls: [
    _.template('household/<%= householdId%>/authorisation')(params),
  ],
  params: { ...params, method: 'POST', body: JSON.stringify({ action: 'add', email: params.email }) },
})

export const HOUSEHOLD_CARER_REMOVE = (params) => ({
  prefix: "HOUSEHOLD_CARER_REMOVE",
  urls: [
    _.template('household/<%= householdId%>/authorisation')(params),
  ],
  params: { ...params, method: 'POST', body: JSON.stringify({ action: 'remove', userId: params.userId, emailAddress: params.email }) },
})

export const SLEEP_GRAPH_DATA = (params) => ({
  prefix: "SLEEP_GRAPH_DATA",
  urls: [
    _.template('data/sleep/<%= householdId%>')(params),
  ],
  params: { ...params },
})

export const USER_INFO = () => ({
  prefix: "USER_INFO",
  urls: [
    'data/user/profile',
  ],
})

export const USER_INFO_SAVE = (params) => ({
  prefix: "USER_INFO_SAVE",
  urls: [
    'data/user/profile',
  ],
  params: {
    method: 'PATCH',
    body: JSON.stringify(params.details),
    responseType: null,
  },
})

export const HOUSEHOLD_SETTING_BULK = (params) => ({
  prefix: 'HOUSEHOLD_SETTING_BULK',
  urls: [
    _.template('data/household/<%= householdId%>/settings')(params),
  ],
  params: {
    method: 'POST',
    body: JSON.stringify(params.settings),
    responseType: null,
  },
})



export const AVERAGEACTIVITY = (params) => ({
  prefix: 'AVERAGEACTIVITY',
  urls: [
    _.template('data/graph2/<%= householdId %>/<%= timePeriod %>?summaryType=average')(params),
  ],
  params: params,
})

export const REQUEST_REPORT = (params) => ({
  prefix: 'REQUEST_REPORT',
  urls: [
    _.template(!params.reportId ? 'data/report/<%= householdId %>' : 'data/report/<%= householdId %>?reportId=<%= reportId %>')(params),
  ],
  params: { method: 'GET', reportId: params.reportId },
})

export const HOUSEHOLD_V2_LIST_PAGE = (params) => ({
  prefix: 'HOUSEHOLD_V2_LIST_PAGE',
  urls: [
    _.template('v2/household/list?top=<%= pageSize %>&skip=<%= pagePosition %>&search=<%= searchQuery %>&order=<%= order %>&householdStatus=<%= householdStatus %>&systemHealthStatus=<%= systemHealthStatus %>&triagePriority=<%= triagePriority %>')(params),
  ],
  params: params,
})

export const HOUSEHOLD_V2_GET = (params) => ({
  prefix: 'HOUSEHOLD_V2_GET',
  urls: [
    _.template('v2/household/<%= householdId %>')(params),
  ],
  params: { ...params },
})

export const HOUSEHOLD_V2_ACTIVITYSTREAM = (params) => ({
  prefix: 'HOUSEHOLD_V2_ACTIVITYSTREAM',
  urls: [
    _.template('v2/household/<%= householdId %>/activitystream?start=<%= start %>&end=<%= end %>')(params),
  ],
  params: params,
})

export const HOUSEHOLD_V2_ACTIVITYSTREAM_PREVIEW = (params) => ({
  prefix: 'HOUSEHOLD_V2_ACTIVITYSTREAM_PREVIEW',
  urls: [
    _.template('v2/household/<%= householdId %>/activitystream?start=<%= start %>&end=<%= end %>')(params),
  ],
  params: params,
})

export const HOUSEHOLD_V2_SENSORS_STATE = (params) => ({
  prefix: 'HOUSEHOLD_V2_SENSORS_STATE',
  urls: [
    _.template('v2/household/<%= householdId %>/sensors/state')(params),
  ],
  params: params,
})


export const HOUSEHOLD_GRAPHS = (params) => ({
  prefix: 'HOUSEHOLD_GRAPHS',
  urls: [
    _.template('household/<%= householdId %>/graphs')(params),
  ],
})

export const HOUSEHOLD_DATA_BED = (params) => {
  if (params.startTime) {
    return {
      prefix: 'HOUSEHOLD_DATA_BED',
      urls: [
        _.template('household/<%= householdId %>/data/bed/<%= sensorId %>?startTime=<%= startTime %>')(params),
      ],
      params: { ...params },
    }
  } else return {
    prefix: 'HOUSEHOLD_DATA_BED',
    urls: [
      _.template('household/<%= householdId %>/data/bed/<%= sensorId %>')(params),
    ],
    params: { ...params },
  }
}
export const HOUSEHOLDS_STATUS = (params) => {
  return {
    prefix: 'HOUSEHOLDS_STATUS',
    urls: [
      _.template('v2/households/status')(params),
    ],
    params: { ...params },
  }
}

export const GET_HOUSEHOLD_HEALTH_ROLLUP = (params) => {
  return {
    prefix: 'GET_HOUSEHOLD_HEALTH_ROLLUP',
    urls: ['v2/households/status?graphs=true'],
  }
}

export const CONFIGURE_SENSOR = (params) => {
  return {
    prefix: 'CONFIGURE_SENSOR',
    urls: ['sensor/configuration'],
    params: {
      body: JSON.stringify({
        householdId: params.householdId,
        sensorId: params.sensorId,
      }),
      method: 'POST',
    },
  }
}

export const GET_RESELLERS = (params) => ({
  prefix: 'GET_RESELLERS',
  urls: [`data/reseller?type=resellers&q=${params.q}&limit=${params.limit}&offset=${params.offset}`],
  params: { method: "GET" },
})

export const GET_HOUSEHOLDS = (params) => ({
  prefix: 'GET_HOUSEHOLDS',
  urls: [`data/reseller?type=households&q=${params.q}&limit=${params.limit}&offset=${params.offset}&sortDirection=${params.sortDirection}&sortByValue=${params.sortByValue}&householdStatus=${params.householdStatus}`],
  params: { method: "GET" },
})

export const LINK_HOUSEHOLD_TO_RESELLER = (params) => ({
  prefix: 'LINK_HOUSEHOLD_TO_RESELLER',
  urls: [`data/reseller?type=households&resellerId=${params.resellerId}&householdId=${params.householdId}`],
  params: { method: "PATCH" },
})

export const LINK_INVOICE_TO_HOUSEHOLD = (params) => ({
  prefix: 'LINK_INVOICE_TO_HOUSEHOLD',
  urls: [`data/reseller?type=households&invoice=${params.invoice}&householdId=${params.householdId}`],
  params: { method: "PATCH" },
})

export const DELETE_RESELLER = (params) => ({
  prefix: 'DELETE_RESELLER',
  urls: [`data/reseller?resellerId=${params.resellerId}`],
  params: { method: "DELETE" },
})

export const GET_RADAR_GRAPH = (params) => ({
  prefix: 'GET_RADAR_GRAPH',
  urls: [`data/radar/${params.householdId}?timeRange=${params.timeRange}&sensorId=${params.sensorId}`],
  params: { method: "GET" },
})

export const EXPORT_DATA = (params) => ({
  prefix: 'EXPORT_DATA',
  urls: [params.export === 'export' ? `data/radar/${params.householdId}?export=${params.export}&sensorId=${params.sensorId}&startTime=${params.startTime}&endTime=${params.endTime}` : `data/radar/${params.householdId}?export=${params.export}`],
  params: { method: "GET", householdId: params.householdId },
})

export const SET_HOUSEHOLD_BILLING_START_DATE = (params) => ({
  prefix: 'SET_HOUSEHOLD_BILLING_START_DATE',
  urls: [params.billingStartDate ?
    `data/reseller?type=households&householdId=${params.householdId}&billingStartDate=${params.billingStartDate}` :
    `data/reseller?type=households&householdId=${params.householdId}&billingEndDate=${params.billingEndDate}`],
  params: { method: "PATCH" },
})

export const CREATE_RESELLER = (reseller) => ({
  prefix: 'CREATE_RESELLER',
  urls: [`data/reseller`],
  params: {
    method: "POST",
    body: JSON.stringify({
      address: reseller.address,
      name: reseller.name,
      email: reseller.email,
      contactNumber: reseller.contactNumber,
      abn: reseller.abn,
    }),
  },
})

export const UPDATE_RESELLER = (id, reseller) => ({
  prefix: 'UPDATE_RESELLER',
  urls: [`data/reseller?type=resellers&resellerId=${id}`],
  params: {
    method: "PATCH",
    body: JSON.stringify({
      address: reseller.address,
      name: reseller.name,
      email: reseller.email,
      contactNumber: reseller.contactNumber,
      abn: reseller.abn,
    }),
  },
})

export const GET_HOUSEHOLDS_BY_RESELLER_ID = (params) => ({
  prefix: 'GET_HOUSEHOLDS_BY_RESELLER_ID',
  urls: [`data/reseller?type=households&limit=${params.limit}&offset=${params.offset}&resellerId=${params.resellerId}&sortDirection=${params.sortDirection}&sortByValue=${params.sortByValue}&householdStatus=${params.householdStatus}`],
  params: { method: "GET" },
})

export const RESET_BEHAVIOUR = (params) => {
  return {
    prefix: 'RESET_BEHAVIOUR',
    urls: ['reset-behaviour'],
    params: {
      body: JSON.stringify({
        householdId: params.householdId,
        behaviour: params.behaviour,
        zoneId: params.zoneId,
      }),
      method: 'POST',
    },
  }
}

export const GET_HEALTH_METRICS = (params) => ({
  prefix: 'GET_HEALTH_METRICS',
  urls: [`data/health-metrics?householdId=${params.householdId}&metricType=${params.metricType}&timestamp=${params.timestamp}&graphMode=${params.graphMode}`],
  params: {
    method: "GET",
  },
})

export const GET_HEALTH_METRICS_CUSTOM = (params) => ({
  prefix: 'GET_HEALTH_METRICS_CUSTOM',
  urls: [`data/health-metrics?householdId=${params.householdId}&metricType=${params.metricType}&dataSource=${params.dataSource}&timestamp=${params.timestamp}&graphMode=${params.graphMode}`],
  params: {
    method: "GET",
  },
})

export const GET_HEALTH_METRICS_TYPES = (params) => ({
  prefix: 'GET_HEALTH_METRICS_TYPES',
  urls: [`data/health-metrics?householdId=${params.householdId}&types=true`],
  params: {
    method: "GET",
  },
})

export const GET_HEALTH_SUMMARY = (params) => ({
  prefix: 'GET_HEALTH_SUMMARY',
  urls: [`data/health-metrics?householdId=${params.householdId}&latest=true`],
  params: {
    method: "GET",
  },
})

export const ADD_HEALTH_METRIC = (body) => ({
  prefix: 'ADD_HEALTH_METRIC',
  urls: [`data/health-metrics?householdId=${body.householdId}`],
  params: {
    method: "POST",
    body: JSON.stringify({
      metricType: body.metricType,
      metricValue: body.metricValue,
      timestamp: body.timestamp,
      dataSource: body.dataSource,
    }),
  },
})

export const SYNC_FITBIT_DATA = (body) => ({
  prefix: 'SYNC_FITBIT_DATA',
  urls: [`fitbit-handler`],
  params: {
    method: "POST",
    body: JSON.stringify(body),
  },
})

export const FITBIT_INITIAL_SYNC = (body) => ({
  prefix: 'FITBIT_INITIAL_SYNC',
  urls: [`fitbit-handler?initialSync=true`],
  params: {
    method: "POST",
    body: JSON.stringify(body),
  },
})

export const TOGGLE_PRO_USER = (params) => ({
  prefix: 'TOGGLE_PRO_USER',
  urls: [`system/management?email=${params.email}&enablePro=${params.enablePro}`],
  params: {
    method: "POST",
  },
})

export const ADD_PENDANT  = (householdId, pendant) => ({
  prefix: 'ADD_PENDANT',
  urls: [`household-pendant-config/${householdId}`],
  params: {
    method: "POST",
    body: JSON.stringify(pendant),
  },
})

export const UPDATE_PENDANT  = (householdId, pendantId, pendant) => ({
  prefix: 'UPDATE_PENDANT',
  urls: [`household-pendant-config/${householdId}?pendantId=${pendantId}`],
  params: {
    method: "PATCH",
    body: JSON.stringify(pendant),
  },
})

export const REMOVE_PENDANT  = (householdId, pendantId) => ({
  prefix: 'REMOVE_PENDANT',
  urls: [`household-pendant-config/${householdId}?pendantId=${pendantId}`],
  params: {
    method: "DELETE",
  },
})

export const ALEXA_INITIAL_SYNC = (body) => ({
  prefix: 'SYNC_ALEXA_ACCOUNT',
  urls: [`alexa-handler`],
  params: {
    method: "POST",
    body: JSON.stringify(body),
  },
})

export const ADD_GUARDIAN_AI = (body) => ({
  prefix: 'ADD_GUARDIAN_AI',
  urls: [`guardian-ai-provisioner`],
  params: {
    method: "POST",
    body: JSON.stringify(body),
  },
})

export const GET_HEALTH_METRICS_DEVICES = (householdId) => ({
  prefix: 'GET_HEALTH_METRICS_DEVICES',
  urls: [`health-metrics-devices?householdId=${householdId}`],
  params: {
    method: "GET",
  },
})

export const GET_ADD_DEVICE_URL = (householdId) => ({
  prefix: 'GET_ADD_DEVICE_URL',
  urls: [`health-metrics-devices?householdId=${householdId}&getAddUrl=true`],
  params: {
    method: "GET",
  },
})

export const SYNC_PERSONA_DATA = (householdId) => ({
  prefix: 'SYNC_PERSONA_DATA',
  urls: [`health-metrics-devices?householdId=${householdId}&syncData=true`],
  params: {
    method: "GET",
  },
})

export const GET_HOUSEHOLD_ACCESS_AUDIT_LOGS = (householdId, timeRange) => ({
  prefix: 'GET_HOUSEHOLD_ACCESS_AUDIT_LOGS',
  urls: [`household/${householdId}/authorisation?getAuditRecords=true&timeRange=${timeRange}`],
  params: {
    method: "GET",
  },
})

export const UPDATE_INCIDENT_DETECTION = (householdId, body) => ({
  prefix: 'UPDATE_INCIDENT_DETECTION',
  urls: [`data/household/${householdId}/sensors`],
  params: {
    method: "PATCH",
    body: JSON.stringify(body),
  },
})