import { Builder } from "./ApiBuilder";

export interface ProvisioningGateway
{
  id: string;
  gatewayid: string,
  provisioning: {
    localipaddress: string;
    version: string;
  };
  _ts: string,
  ttl: string,
}

export class GatewayApi extends Builder
{
  token: string;

  constructor(token: string)
  {
    super(true)
    this.token = token;
  }
  getProvisioning = async (): Promise<ProvisioningGateway[] | undefined> =>
  {
    try
    {
      const response = await this.call("GET", `/api/data/gateway/all?provisioning=true`, null, this.token)
      if(response) return response.data;
    }
    catch(error)
    {
      return undefined
    }

  }

  getGateway = async (gatewayId: string): Promise<any> =>  {
    try {
      const response = await this.call("GET", `/api/data/gateway/${gatewayId}`, null, this.token)
      if(response) return response.data;
    } catch(error) {
      return undefined;
    }
  }

  clearProvisioningRequest = async (gatewayId: string): Promise<{result: string} | undefined> =>
  {
    const response = await this.call("DELETE", `/api/data/gateway/${gatewayId}?provisioning=true`, null, this.token)
    if(response) return response.data;
  }
}