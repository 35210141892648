import React, { Component } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import TextField from '@material-ui/core/TextField'

interface SensorModalParametersTableProps
{
  parameters: SensorParameter[];
  onDelete: (id: string) => void;
  handleTextFieldChange: (rowId: string, columnName: string, value: string | number) => void
}
interface SensorParameter
{
  id: string;
  name: string;
  parameter: string | number;
  value: string | number;
}

export class SensorModalParametersTable extends Component<SensorModalParametersTableProps>
{
  render()
  {

    const { parameters, onDelete, handleTextFieldChange } = this.props;
    return(
      <TableContainer component={Paper}>
        <Table aria-label="parameters table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Parameter</TableCell>
              <TableCell>Value</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parameters.map((row) => (
              <TableRow hover key={row.id}>

                <TableCell component="th" scope="row">
                  <TextField onChange={(e) => handleTextFieldChange(row.id, "name", e.target.value)} value={row.name} placeholder="Name" />
                </TableCell>

                <TableCell>
                  <TextField onChange={(e) => handleTextFieldChange(row.id, "parameter", e.target.value)} value={row.parameter} placeholder="Parameter"/>
                </TableCell>

                <TableCell>
                  <TextField onChange={(e) => handleTextFieldChange(row.id, "value", e.target.value)} value={row.value} placeholder="Value" />
                </TableCell>

                <TableCell align="center">
                  <IconButton onClick={() => onDelete(row.id)}><DeleteIcon /></IconButton>
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}