/**
 * Sorts and array by property
 * @param array The array to be sorted
 * @param property The property that will be used to sort the array
 * @param order 0 - ASC | 1 - DESC
 * @returns The sorted array.
 */
export const sortAlphabetically = (array: any[], property: any, order: string = "asc"): any[] => {
  const sorted = array.sort((a, b) => {
    if(order.toLowerCase() === "asc") {
      if(a[property] > b[property]) { return -1; }
      if(a[property] < b[property]) { return 1; }
      return 0;
    } else {
      if(a[property] < b[property]) { return -1; }
      if(a[property] > b[property]) { return 1; }
      return 0;
    }
  });

  return sorted;
}
