import { connect } from 'react-redux'
import AppErrorOverlayView from '../components/AppErrorOverlayView'
import sharedStore from '../../../redux'

const logoutStart = sharedStore.userStore.logoutStart


const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => {
      dispatch(logoutStart())
    },
  }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(AppErrorOverlayView)
