import {
  takeEvery,
} from 'redux-saga/effects'

import { history } from '../../../hoc/CustomRouter'

// eslint-disable-next-line require-yield
function* changePath(action) {
  try {
    //console.log(action)
    history.push(action.path)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

function* locationSaga() {
  yield takeEvery("PATH_CHANGE", changePath)
}

export default locationSaga
