import React, { useEffect, useState } from 'react'
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import InteliIcon from '../../../../../components/InteliIcon';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton'
import { NoActivities } from './NoActivities'
import LastMotionEvent from './LastMotionEvent'
import Slide from '@material-ui/core/Slide'

const REFRESH_STREAM_INTERVAL = 60000
const STREAM_FETCH_AMOUNT_HOURS = 48;
interface ActivityStreamProps
{
  householdId: string;
  getActivityFeed: (householdId: string, start: string, end: string) => void;
  isGettingActivityStream: boolean;
  activities: {
    isFetching: boolean;
    start: string;
    end: string;
    events: Event[]
  } | undefined
}

interface Event
{
  activityType: string;
  deviceId: string;
  friendlyName: string;
  friendlyTime: string;
  householdId: string;
  icon: {color: string, name: string, family: string},
  id: string;
  name: string;
  timestamp: string;
  zoneId?: string;
  zoneFriendlyName?: string;
}

export const ActivityStreamList = ({ householdId, getActivityFeed, activities, isGettingActivityStream }: ActivityStreamProps) =>
{
  const [ id, setId] = useState(null);
  useEffect(() => {

    // Get the list
    getActivityFeed(householdId, moment().subtract(STREAM_FETCH_AMOUNT_HOURS, "hours").toISOString(), moment().toISOString());
    // Start the timer
    const id = setInterval(() => getActivityFeed(householdId, moment().subtract(STREAM_FETCH_AMOUNT_HOURS, "hours").toISOString(), moment().toISOString()), REFRESH_STREAM_INTERVAL)

    // @ts-ignore
    setId(id)

    // Clear the interval on umount
    return function cleanUp()
    {
      clearInterval(id)
    }
  }, [])



  const combineSensorNameAndActivityType = (item: Event): string => {
    if(item.activityType && item.friendlyName && item.friendlyName !== "Unknown") return `${item.activityType} (${item.friendlyName})`
    if(item.activityType === "bathVisit") return "Bath Visit"
    if(item.activityType === "mealPrep") return "Meal Prep"
    return item.activityType;
  }

  if(isGettingActivityStream)
  {
    return(<div style={styles.skeletonContainer}>
      <Skeleton width={"95%"} count={20} style={styles.skeletonListItem} />
    </div>)
  }
  return(
    activities && activities.events && activities.events.length > 0 ?
      <>
        <LastMotionEvent householdId={householdId}/>
        <Card style={styles.cardContainer}>
          <List>
            {activities && activities.events && activities.events.map((item, key) => {
              return (
                <Slide key={key} direction="up" in={true} >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar style={{ backgroundColor: item.icon.color }}>
                        <InteliIcon name={item.activityType === 'bathVisit' ? 'sink-small' : item.icon.name} family={item.icon.family} color="white" />
                      </Avatar>
                    </ListItemAvatar>
                    <Tooltip title={moment(item.timestamp).calendar()} placement="bottom-start">
                      <ListItemText primary={combineSensorNameAndActivityType(item)} secondary={item.zoneFriendlyName ? `${item.zoneFriendlyName} - ${moment(item.timestamp).fromNow()}` : moment(item.timestamp).fromNow()} />
                    </Tooltip>
                  </ListItem>
                </Slide>
              )
            })}
          </List>
        </Card> </> : <NoActivities />

  );
}

const styles = {
  cardContainer: {
    width: 300,
  },
  skeletonListItem: {
    margin: 8,
    height: 60,
  },
  skeletonContainer: {
    backgroundColor: "white",
  },
};

