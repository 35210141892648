import moment from 'moment-timezone'
import { Moment } from 'moment-timezone'

export interface ActivityHistory {
  date: string;
  actual: number;
  dayCountActual: number;
  dayCountFixed: number;
}

/**
 * Calculate stats for the last n entries of the data array
 * @param samples number of entries at end of data array to use
 * @param data data to sample
 */
export function calcEndSampleStats(samples: number, data: number[]): { mean: number, sd: number } {
  const sample = getSample(samples, data.length - samples, data);
  return calcAverageAndStdDev(sample);
}

/**
* Calculate rolling average and standard deviation for data
* @param samples length of samples to use for rolling average
* @param data data to calculate with
*/
export function calcRollingAverage(samples: number, data: number[]): { mean: number, sd: number }[] {
  const result: { mean: number, sd: number }[] = [{
    mean: data[0],
    sd: 0,
  }];
  for (let i = 1; i < samples - 1; i++) {
    const sample = getSample(i + 1, 0, data);
    result.push(calcAverageAndStdDev(sample));
  }
  for (let i = 0; i < data.length - samples + 1; i++) {
    const sample = getSample(samples, i, data);
    result.push(calcAverageAndStdDev(sample));
  }
  result[0].sd = result[1].sd;
  return result;
}

export function calcAverage(data: number[]): number {
  return data.reduce((p, c) => p + c, 0) / data.length;
}

export function calcAverageAndStdDev(data: number[]): { mean: number; sd: number } {
  const avg = calcAverage(data);
  const sd = calcStdDev(avg, data);
  return { mean: avg, sd: sd };
}

export function calcStdDev(average: number, data: number[]): number {
  const variance = data.map(v => (v - average) * (v - average)).reduce((p, c) => p + c, 0) / (data.length - 1);
  return Math.sqrt(variance);
}


export function getSample(samples: number, start: number, data: number[]): number[] {
  return data.slice(start, start + samples);
}

/**
* Get sample from the right end of two arrays butted together - sample length may or may not span the arrays
* @param sample length of sample
* @param data1 first array
* @param data2 second array
* @returns sample of one or both arrays
*/
export function getEndCombinedSample(sample: number, data1: number[], data2: number[]): number[] {
  if (data2.length < sample) {
    const result: number[] = [];
    const data1required = sample - data2.length;
    result.push(...data1.slice(data1.length - data1required));
    result.push(...data2);
    return result;
  } else {
    return data2.slice(data2.length - sample);
  }
}

/**
 * Group historic data by day of week
 * Uses unix timestamp for date
 * @param data data sample to group
 * @returns [0: Sun, 1: Mon, 2: Tue, 3: Wed, 4: Thu, 5: Fri, 6: Sat]
 */
export function groupByDayOfWeek(timezone: string, data: ActivityHistory[]): { [day: string]: ActivityHistory[] } {
  const week: { [day: string]: ActivityHistory[] } = {};
  for (const day of data) {
    const time = moment(day.date).tz(timezone);
    const dayOfWeek = time.day();
    if (!week[dayOfWeek]) week[dayOfWeek] = [];
    week[dayOfWeek].push(day);
  }
  return week;
}
