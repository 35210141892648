import React from 'react'
import PropTypes from 'prop-types'

// Material UI components
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

// Icons
import Forward from '@mui/icons-material/ArrowForward'
import Back from '@mui/icons-material/ArrowBack'
import Home from '@mui/icons-material/Home'


const PaginationFooter = ({ getLess, getMore, onHomeClick }) => {
  PaginationFooter.propTypes = {
    getLess: PropTypes.func,
    getMore: PropTypes.func,
    onHomeClick: PropTypes.func,
  }

  return (
    <Grid style={{ paddingTop: 10 }} justifyContent="center" container direction="row">
      <Button startIcon={<Back />} onClick={() => getLess()} style={{ marginRight: 10 }} color="primary" variant="outlined">Previous Page</Button>
      <Button startIcon={<Home />} onClick={() => onHomeClick()} style={{ marginRight: 10 }} color="primary" variant="outlined">Home</Button>
      <Button endIcon={<Forward />} onClick={() => getMore()} color="primary" variant="outlined">Next Page</Button>
    </Grid>
  )
}

export default PaginationFooter