import React, { useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import moment from 'moment'
import { Organisation } from '../../../../api/OrganisationApi';
import { purple, pink } from '@mui/material/colors';
import { getInitials } from '../Modals/utils/getInitials';
import FacilityIcon from '@mui/icons-material/Apartment';
import HouseIcon from '@mui/icons-material/House';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import DeleteIcon from '@mui/icons-material/Delete';

import { UserItem } from './UserItem';
import { useEffect } from 'react';
import { sortAlphabetically } from '../../../../helpers/arraySorting';

import { useSelector } from "react-redux";
import { ApplicationState } from '../../../../interfaces/reduxState';

interface OrgItemProps {
  organisation: Organisation;
  organisations: Organisation[];
  addFacility: (ouId: string) => void;
  addHousehold: (ouId: string) => void;
  addUser: (ouId: string) => void;
  deleteNode: (ouId: string) => void;
}

export const OrgItem = ({ organisation, organisations, addFacility, addHousehold, addUser, deleteNode }: OrgItemProps) => {
  const [children, setChildren] = useState<Organisation[]>([]);
  const [expanded, setExpanded] = useState(false);
  const isDeveloper = useSelector(({ user }: ApplicationState) => user.isDeveloper)
  const isSupport = useSelector(({ user }: ApplicationState) => user.isSupport)

  const onItemExpand = (organisation: Organisation) => {
    setExpanded(!expanded)
    updateChildren(organisation)
  }

  const updateChildren = (organisation: Organisation) => {
    // For each child id find the organisation
    if (!organisation) return;
    const updatedChildren = organisation.children.map(childId => {
      const matchedOrg = organisations.find((org) => org.id === childId)
      if (matchedOrg) return matchedOrg;
    }) as Organisation[]

    const sortedArray = sortAlphabetically(updatedChildren, "type");

    setChildren(sortedArray)
  }

  useEffect(() => {
    // When we receiver updates from dispatch then render the children lookup items again
    updateChildren(organisation)
  }, [organisations])

  const handleAddFacility = (e: any, ouId: string) => {
    e.preventDefault();
    e.stopPropagation();

    addFacility(ouId)
  }

  const handleAddHousehold = (e: any, ouId: string) => {
    e.preventDefault();
    e.stopPropagation()

    addHousehold(ouId)
  }

  const handleAddUser = (e: any, ouId: string) => {
    e.preventDefault();
    e.stopPropagation()

    addUser(ouId)
  }

  const handleDeleteNode = (e: any, ouId: string) => {
    e.preventDefault();
    e.stopPropagation();

    deleteNode(ouId);
  }
  if (!organisation) return <div />;
  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        onClick={() => onItemExpand(organisation)}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">

        <Grid container alignItems="center" spacing={4} >
          <Grid item >
            <Avatar style={organisation.type === "household" ? styles.child : styles.avatar}>{getInitials(organisation.friendlyName)}</Avatar>
          </Grid>

          <Grid item minWidth={300}>
            <Typography>{organisation.friendlyName}</Typography>
            <Typography variant="subtitle2" color="textSecondary">{organisation.type === "household" ? 'Household' : "Access Group"}</Typography>
          </Grid>

          {organisation.type !== 'household' ? <Grid item minWidth={200}>
            <Typography>{organisation && organisation.children && organisation.children.length === 1 ? `(1 child)` : `(${organisation.children.length} children)`}</Typography>
          </Grid> :

            <Grid item minWidth={200}>
              <Typography>{organisation && organisation.access && Object.keys(organisation.access).length === 1 ? `(1 user)` : `(${Object.keys(organisation.access).length} users)`}</Typography>
            </Grid>
          }

          {/* Last updated */}
          <Grid item minWidth={200}>
            <Typography color="textSecondary">{moment(organisation.lastUpdated).fromNow()}</Typography>
          </Grid>

          {/* Action buttons for org nodes */}
          {organisation.type !== 'household' &&
            <>
              <Grid item>
                <Tooltip title="Added a sub location to this location">
                  <IconButton onClick={(e) => handleAddFacility(e, organisation.id)} ><FacilityIcon /></IconButton>
                </Tooltip>
              </Grid>

              {(isDeveloper || isSupport) && <Grid item>
                <Tooltip title="Added a household to this location">
                  <IconButton onClick={(e) => handleAddHousehold(e, organisation.id)} ><HouseIcon /></IconButton>
                </Tooltip>
              </Grid>}

              <Grid item>
                <Tooltip title="Add a user to this location">
                  <IconButton onClick={(e) => handleAddUser(e, organisation.id)} ><PersonAddAltIcon /></IconButton>
                </Tooltip>
              </Grid>

              {/* Only allow delete if org has no children */}
              {organisation.children && organisation.children.length === 0 && <Grid item>
                <Tooltip title="Remove">
                  <IconButton onClick={(e => handleDeleteNode(e, organisation.id))}><DeleteIcon /></IconButton>
                </Tooltip>
              </Grid>}
            </>}

          {/* Actions for household nodes */}
          {organisation.type === 'household' &&
            <Grid item>
              <Tooltip title="Add a user to this household">
                <IconButton onClick={(e) => handleAddUser(e, organisation.id)} ><PersonAddAltIcon /></IconButton>
              </Tooltip>

              <Tooltip title="Remove">
                <IconButton onClick={(e => handleDeleteNode(e, organisation.id))}><DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>}

        </Grid>


      </AccordionSummary>

      <AccordionDetails>
        <>
          {children && children.map((child, key) => (
            <OrgItem
              addUser={addUser}
              addHousehold={addHousehold}
              addFacility={addFacility}
              organisation={child}
              key={key}
              organisations={organisations}
              deleteNode={deleteNode}
            />
          ))}

          {organisation.type === 'household' && Object.keys(organisation.access).map(userId => <UserItem organisationId={organisation.id} id={userId} roles={organisation.access[userId]} />)}

        </>
      </AccordionDetails>
    </Accordion>
  )
}

const styles = {
  row: {
    alignItems: 'center',
    flexDirection: 'row' as 'row',
    display: 'flex',
  },
  childCardItem: {
    margin: 6,
    padding: 10,
    flexDirection: 'row' as 'row',
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: purple[500],
    marginRight: 6,
    marginLeft: 6,
  },
  child: {
    backgroundColor: pink[500],
    marginRight: 6,
    marginLeft: 6,
  },
  avatarContainer: {
    flexDirection: "row" as "row",
    display: "flex" as "flex",
    alignItems: 'center',
  },
}