export default (theme) => ({
  Modal: {
    minWidth: '540px',
  },
  ModalHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  ModalActions: {
    display: 'flex',
  },
  ModalActionsContainer: {
    width: '100%',
  },
  ResolutionDescription: {
    width: '100%',
  },
})
