import moment from 'moment'
import access from 'safe-access'
export const formatNotifications = (notifications) =>
{
  try
  {
    const formatted = notifications.map((notification) => {
      return {
        friendlyName: notification.householdFriendlyName,
        householdId: notification.householdId,
        timestamp: new Date(notification.timestamp).toISOString(),
        status: notification.status,
        triggerPersonId: notification.triggerPersonId,
        severity: notification.severity,
        message: access(notification, 'recipients[0].message'),
        recipientsType: access(notification, 'recipients[0].type'),
        resolution: access(notification, 'resolution.message'),
        resolutionTime: access(notification, 'resolution.timestamp') && moment(access(notification, 'resolution.timestamp')).format("DD-MM-YY hh:mm:ssZ"),
        resolutionType: access(notification, 'resolution.type'),
      }
    })

    return formatted
  }
  catch(error)
  {
    return []
  }
}

export const getInsightsFromNotification = (action) => {
  try {
    const notification = JSON.parse(action.params.body)
    if (notification.message.toLowerCase().includes("meal")) {
      return "Meal Preparation";
    }
  } catch (err) {
    // Error
  }
  return ""
}

export const updateDocumentTitleWithNotificationsCount = (action, state, operator) =>
{
  try
  {
    // Don't do any alert updating if the type isn't ALERT - E.G notification type
    if(access(action, 'payload.type') !== "ALERT") return state.activeAlerts;
    // If the alerts was already 0, then just return
    if(state.activeAlerts === 0) return state.activeAlerts;

    let updatedAlertCount
    if(operator === "ADD") updatedAlertCount = state.activeAlerts + 1
    if(operator === "REMOVE") updatedAlertCount = state.activeAlerts - 1


    if(document.title.includes("(") && document.title.includes(")"))
    {
      document.title = document.title.replace(/\s*\(.*?\)\s*/g, `(${updatedAlertCount}) `)
      return updatedAlertCount
    }

    document.title = `(${updatedAlertCount}) ${document.title}`

    return updatedAlertCount
  }
  catch(error)
  {
    // Error
  }
}