import React from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import Alert, { Color } from '@material-ui/lab/Alert';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
import CircularProgess from '@material-ui/core/CircularProgress';
interface SnackAlertProps
{
  onClose: () => void;
  open: boolean;
  alertMsg: string;
  color: Color;
  isLoading?: boolean;
}

function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

export const SnackAlert = ({ onClose, open, alertMsg, color, isLoading }: SnackAlertProps) =>
{
  return ( <Snackbar
    onClose={onClose}
    TransitionComponent={TransitionUp}
    autoHideDuration={6000}
    open={open} >
    <Alert icon={isLoading && <CircularProgess size={16} style={styles.spinner}/>} severity={color} variant="filled" color={color}>{alertMsg}</Alert>
  </Snackbar>
  )
}

const styles = {
  spinner: {
    color: 'white',
    alignSelf: 'center',
  },
}