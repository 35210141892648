import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';


import AppContainer from './containers/AppContainer'
import createStore from './store/createStore'

import { appInsights } from './api/AppInsights'

import './themes/index.css'

appInsights.trackPageView({ name: `web-portal`, uri: window.origin })


const store = createStore({})



ReactDOM.render(
  <React.StrictMode>
    <AppContainer store={store} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
