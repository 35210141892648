import { createSelector } from 'reselect'
import _ from 'lodash'
import access from 'safe-access'
import moment from 'moment-timezone'
import { enums } from '@intelicare/shared'
import {
  iconForEvent,
  nameForActivity,
} from '../../shared/util/activity'

import {
  getIcon, getBehavioursOfType,
} from '../../shared/util/behaviour'

import {
  textForSensorStatus,
  deriveSensorDataForHousehold,
  friendlyDateTime,
  iconForSensorType,
} from '../../shared/selectors/helper'


import {
  okay,
  amber,
  alert,
  disable,
  colorForStatusValue,
  away,
} from '../../shared/util/colors'
import { theme } from '../../../themes/proTheme'

const { SENSOR_STATUS_REASONS } = enums


export const getHouseholds = (state) => state.householdsV2.households
export const householdListHasMore = (state) => state.householdsV2.hasMore
export const getActiveHouseholdId = (state) => state.householdsV2.activeHouseholdId
export const getStatusSummary = (state) => state.householdsV2.statusSummary
export const getSensorDetailsFetching = (state) => state.householdV2.sensorDetailsFetching

export const inactiveHouseholdCount = createSelector(
  [getStatusSummary],
  (statusSummary) => {
    if (statusSummary && statusSummary.householdStatus) {
      return statusSummary.householdStatus["INACTIVE"]
    }
    return undefined
  }
)

export const systemSummaryData = createSelector(
  [getStatusSummary],
  (statusSummary) => {
    if (statusSummary && statusSummary.systemStatus) {
      const { systemStatus } = statusSummary
      return [
        { label: 'Okay (Normal)', color: okay, x: systemStatus['NORMAL'] },
        { label: 'Watch (Warning)', color: amber, x: systemStatus['WARNING'] },
        { label: 'Offline (Alert)', color: alert, x: systemStatus['ALERT'] },
        { label: 'Inactive (Unmonitored)', color: disable, x: systemStatus['NOT_MONITORED'] },
        { label: 'Away (away)', color: away, x: systemStatus['AWAY'] },
      ]
    }
    return []
  }
)

export const householdGraphStatus = createSelector(
  [getStatusSummary],
  (statusSummary) => {
    if (statusSummary && statusSummary.householdStatus) {
      const { householdStatus } = statusSummary
      return [
        { label: 'Okay', color: okay, x: householdStatus['OKAY'] },
        { label: 'Watch', color: amber, x: householdStatus['WATCH'] },
        { label: 'Alert', color: alert, x: householdStatus['ALERT'] },
      ]
    }
    return []
  }
)

export const getActiveHousehold = createSelector(
  [getHouseholds, getActiveHouseholdId],
  (households, activeHouseholdId) => {
    return households.find(household => household.householdId === activeHouseholdId)
  }
)

export const getActiveHouseholdDataNode = createSelector(
  [getActiveHousehold],
  (activeHousehold) => {
    if (activeHousehold && activeHousehold.data)
      return activeHousehold.data
    return {}
  }
)

export const getActiveHouseholdDataNodeBehaviours = createSelector(
  [getActiveHousehold],
  (activeHousehold) => {
    if (activeHousehold && activeHousehold.data && activeHousehold.data.behaviours)
      return activeHousehold.data.behaviours
    return []
  }
)

export const isHouseholdFetching = createSelector(
  [getActiveHousehold],
  // eslint-disable-next-line no-unused-vars
  (household) => {
    if (household && household.data) {
      if (household.isFetching ||
        household.data.isFetching || household.data.isSummaryFetching)
        return true
      return false
    }
    return true
  }
)

export const getActivityData = createSelector(
  [getActiveHousehold],
  // eslint-disable-next-line no-unused-vars
  (household) => {
    if (!household || household === undefined) return
    if (!household.activity || !household.activity.events ||
      household.activity.length === 0) return

    const events =
      _(household.activity.events)
        .map((record) => {
          var icon = iconForEvent(record.activityType)
          let updatedRecord = Object.assign({}, record, {
            icon: {
              color: theme.palette.info.main,
              name: icon.name,
              family: icon.family,
            },
            friendlyTime: (access(household, 'timezone') ?
              moment(record.timestamp).tz(household.timezone).format('h:mm a') :
              moment(record.timestamp).format('h:mm a')),
            name: nameForActivity(record),
            zoneId: record.zoneId || undefined,
            zoneFriendlyName: record.zoneFriendlyName || undefined,
          })
          return updatedRecord
        })
        .value()

    const uniqueEvents = events.reduce((a, i) => {
      if (!a.find((v) => v.id === i.id)) return [...a, i]
      return a
    }, [])

    uniqueEvents.sort(function compare(a, b) {
      if (a.timestamp > b.timestamp) {
        return -1
      }
      if (a.timestamp < b.timestamp) {
        return 1
      }
      return 0
    })

    const result = {
      isFetching: household.activity.isFetching,
      start: household.activity.start,
      end: household.activity.end,
      events: uniqueEvents,
    }
    return result
  }
)

export const getOverallActivityGraphData = createSelector(
  [getActiveHousehold],
  // eslint-disable-next-line no-unused-vars
  (household) => {
    if (household.graphData &&
      household.graphData.overallActivityGraph)
      return household.graphData.overallActivityGraph
    return {}
  }
)

export const getActivityGraphData = createSelector(
  [getActiveHousehold],
  // eslint-disable-next-line no-unused-vars
  (household) => {
    if (household.graphData &&
      household.graphData.activityGraph)
      return household.graphData.activityGraph
    return {}
  }
)

export const getAverageActivityGraphData = createSelector(
  [getActiveHousehold],
  // eslint-disable-next-line no-unused-vars
  (household) => {
    if (household.graphData &&
      household.graphData.averageActivityGraph)
      return household.graphData.averageActivityGraph
    return {}
  }
)

export const convertSensorDetailsV2 = createSelector(
  [getActiveHousehold],
  (household) => {
    if (!access(household, 'data.sensors')) return []
    const sensors = household.data.sensors
      .filter(sensor => !sensor.parentId)
      .map(sensor => {
        const statusColor = colorForStatusValue(sensor.status)
        return {
          ...sensor,
          lastSeenString: friendlyDateTime(sensor.lastSeen, household.timezone),
          batteryLevelString: (sensor.batteryLevel !== null ? sensor.batteryLevel + "%" : "-"),
          statusText: textForSensorStatus(sensor.status),
          statusReasonText: SENSOR_STATUS_REASONS[sensor.statusReason],
          icon: {
            ...iconForSensorType(sensor.type),
            color: statusColor,
          },
        }
      })
    return sensors
  }
)


export const getSensorBarDataV2 = createSelector(
  [getActiveHousehold],
  (household) => {
    let sensors
    if (!access(household, 'data.sensors')) return []
    if (!household.sensorsState || !household.sensorsState.states ||
      household.sensorsState.states.length === 0) return []

    sensors = household.data.sensors
    const sensorsStates = household.sensorsState.states
    sensors = sensors.map(sensor => {
      let thisSensorState = sensorsStates.filter(sensorState => sensor.id === sensorState.id)
      if (thisSensorState.length > 0) {
        return { ...sensor, value: thisSensorState[0].value, timestamp: thisSensorState[0].timestamp }
      } else
        return { ...sensor }
    }).filter(sensor => {
      return sensor.type === enums.SensorTypes.MOTION
        || sensor.type === enums.SensorTypes.DOOR
        || sensor.type === enums.SensorTypes.TEMPERATURE
        || sensor.type === enums.SensorTypes.POWER
        || sensor.type === enums.SensorTypes.VIBRATION
        || sensor.type === enums.SensorTypes.SHOWER
        || sensor.type === enums.SensorTypes.BED_OCCUPANCY
        || sensor.type === enums.SensorTypes.HEART_RATE
        || sensor.type === enums.SensorTypes.RESPIRATION
        || sensor.type === enums.SensorTypes.DISTANCE
        || sensor.type === enums.SensorTypes.ACTIVITY_LEVEL
    }).map(sensor => {
      let friendlyTime
      // Shouldn't use moment() or fromNow() because selectors should be
      // pure functions (the same input should always result in same output)
      if (sensor.timestamp && moment(sensor.timestamp) > moment()) {
        friendlyTime = 'Just now'
        if (moment(sensor.timestamp).diff(moment(), 'seconds') > 30) {
          if (process.env.REACT_APP_APP_RESOURCE === 'WEB') {
            const { AppInsights } = require('applicationinsights-js')
            AppInsights.trackEvent("SensorTimestampOutOfSyncError", {
              now: moment().toISOString(),
              sensor: JSON.stringify(sensor),
            })
          }
        }
      } else {
        friendlyTime = moment(sensor.timestamp).tz(household.data.timezone).fromNow()
      }
      if (!sensor.timestamp) friendlyTime = 'More than 2 weeks ago'
      return { ...sensor, friendlyTime }
    })
    sensors = sensors.sort(function (a, b) {
      if (!a.timestamp) return 1
      if (!b.timestamp) return -1
      var timestampA = a.timestamp.toUpperCase() // ignore upper and lowercase
      var timestampB = b.timestamp.toUpperCase() // ignore upper and lowercase
      if (timestampA < timestampB) {
        return 1
      }
      if (timestampA > timestampB) {
        return -1
      }
      // names must be equal
      return 0
    })
    deriveSensorDataForHousehold(sensors)
    return sensors
  }
)

export const getActiveHouseholdLastFetched = createSelector(
  [getActiveHousehold],
  (household) => {
    return access(household, 'data.lastFetched')
  }
)

export const householdCapabilities = createSelector(
  [getActiveHousehold],
  (activeHousehold) => {
    return {
      voice: access(activeHousehold, 'data.gatewayId') ? true : false,
      text: access(activeHousehold, 'data.gatewayId') ? true : false,
      zwave: access(activeHousehold, 'data.gatewayId') ? true : false,
    }
  }
)


/* Behaviours */
export const getBehaviourSensors = createSelector(
  [getActiveHouseholdDataNode],
  (household, behaviourType) => {
    const behaviour = getBehavioursOfType(household, behaviourType)
    if (!behaviour || !behaviour.sensors) return []
    return behaviour.sensors.map(sensor => {
      return {
        ...sensor,
        friendlyTime: friendlyDateTime(access(sensor, 'latestReading.timestamp'), household.timezone),
      }
    })
  }
)

export const getBehaviourIcon = createSelector(
  [getActiveHouseholdDataNode],
  (household, behaviourType) => {
    return getIcon(getBehavioursOfType(household, behaviourType))
  }
)

export const getActiveHouseholdSettings = createSelector(
  [getActiveHousehold],
  (activeHousehold) => {
    let settings = {}
    settings.gatewayId = access(activeHousehold, 'data.gatewayId')
    settings.id = access(activeHousehold, 'householdId')
    settings.friendlyName = access(activeHousehold, 'friendlyName')
    settings.avatar = access(activeHousehold, 'avatar')
    settings.behaviours = access(activeHousehold, 'data.behaviours')
    settings.sleep = {
      sleepingStart: access(activeHousehold, 'data.sleepingStart'),
      sleepingFinish: access(activeHousehold, 'data.sleepingFinish'),
    }
    return settings
  }
)

/* Household Sensors for settings view */

export const getFeatureToggles = createSelector(
  [getActiveHouseholdDataNode],
  (activeHousehold) => access(activeHousehold, 'features')
)

export const getBehaviourSettings = createSelector(
  [getActiveHouseholdDataNodeBehaviours],
  (behaviours) => {
    let behavioursRemapped = behaviours.map((behaviour) => {
      switch (behaviour.enabled) {
        case 'true':
          behaviour.enabled = true
          break
        case 'false':
          behaviour.enabled = false
          break

        default:
          break
      }

      let configItems = []
      _.forEach(behaviour.userConfiguration, (item, key) => {
        let min
        let max
        if (item.range) {
          let range = item.range.match(/^(\d*)\-(\d*)$/) // eslint-disable-line no-useless-escape
          min = Number.parseInt(range[1])
          max = Number.parseInt(range[2])
        } else {
          min = 0
          max = 0
        }

        configItems.push(
          {
            name: key,
            settingPath: `behaviours.${behaviour.name}.${key}`,
            type: item.type,
            label: item.uiLabel,
            value: item.value,
            isRequired: item.mandatory,
            min: min,
            max: max,
            options: item.options || [],
            maximumLength: item.maximumLength || 0,
          }
        )
      })

      return {
        friendlyName: behaviour.friendlyName,
        name: behaviour.name,
        enabled: behaviour.enabled,
        userConfiguration: configItems,
        type: behaviour.type,
      }
    })

    return behavioursRemapped
  }
)
