import React, { Component } from 'react'
import PropTypes from 'prop-types'
import util from 'util'
import _ from 'lodash'
import _Fallback from '../components/Fallback'

import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
  },
})

export function errorCallback(error, stackTrace, props) {
  appInsights.trackException(error)
  appInsights.trackEvent("WebError", {
    error,
    stackTrace: stackTrace && stackTrace.componentStack,
    props: JSON.stringify(props),
  })
  console.log(`Processed error: ${error}: ${util.inspect(stackTrace)}`)
}

export const Fallback = _Fallback

class WithErrorHandler extends Component {
  pathname;

  displayName = `withErrorHandler(${Component.displayName})`

  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    }
  }

  componentDidMount() {
    if (Component.pageName) {
      this.pathname = window.location.href
      appInsights.startTrackPage(Component.pageName)
    }
  }

  componentWillUnmount() {
    if (Component.pageName) {
      appInsights.stopTrackPage(Component.pageName, this.pathname)
    }
  }

  componentDidCatch(error, info) {
    const { errorCallback } = this.props
    this.setState({ hasError: true, error, errorInfo: info })
    errorCallback(error, info, this.props)
  }

  render() {
    const { TargetComponent, FallbackComponent } = this.props
    if (this.state.hasError) {
      const { error, errorInfo } = this.state
      return (
        <FallbackComponent
          {...this.props}
          error={error}
          errorInfo={errorInfo}
          targetFriendlyName={Component.friendlyName}
        />
      )
    }
    return <TargetComponent {...this.props} />
  }

  static propTypes = {
    TargetComponent: PropTypes.any,
    FallbackComponent: PropTypes.any,
    errorCallback: PropTypes.func,
  }
}

function _withErrorHandler(errorCallback, FallbackComponent, Component) {
  return (props) => {
    return (
      <WithErrorHandler {...props} errorCallback={errorCallback} FallbackComponent={FallbackComponent} TargetComponent={Component} />
    )
  }
}

export const withErrorHandler = _.curry(_withErrorHandler)(errorCallback)