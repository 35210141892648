import { connect } from 'react-redux'
import access from 'safe-access'
import SensorsStateView from '../components/SensorsStateView'
import { withErrorHandler, Fallback } from '../../../hoc/withErrorHandler'

import sharedStore from '../../../redux'
const { getSensorBarDataV2 } = sharedStore.householdsV2Selector
const callAPI = sharedStore.userStore.callAPI
const HOUSEHOLD_V2_SENSORS_STATE = sharedStore.apiEndpoints.HOUSEHOLD_V2_SENSORS_STATE


const mapDispatchToProps = (dispatch) => {
  return {
    fetchSensorsForHousehold: (householdId, timeAgo) => {
      dispatch(callAPI(HOUSEHOLD_V2_SENSORS_STATE({ householdId })))
    },
  }
}

const mapStateToProps = (state) => ({
  sensors: getSensorBarDataV2(state),
  isFetching: access(state, 'households.sensor.isFetching'),
})

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Fallback)(SensorsStateView))
