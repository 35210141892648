import React from "react";
import { animated, useSpring } from "react-spring";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
// @ts-ignore
import { select, color, scaleTime, scaleLinear, scaleOrdinal, schemeCategory10, axisBottom, axisLeft, RGBColor, line, area, curveBumpX } from 'd3';

import { BEHAVIOUR_TYPES, BEHAVIOUR_STATUS } from "@intelicare/shared/enums";

import './DailyActivityGraph.scss'
import { ActivityHistory, calcAverage, calcAverageAndStdDev, calcRollingAverage, calcStdDev, groupByDayOfWeek } from "./RollingAverage";
import moment from "moment";
import { brandPurple, brandPink } from '../../themes/proTheme'
import { Grid } from "@mui/material";

const DEFAULT_HEIGHT = 400

interface Props {
  householdData: {
    timezone: string;
    behaviours: any[]
  }
}

interface State {
  width: number;
  height: number;
  period: number;
  averageType: '7day' | '14day' | '30day' | 'dow';
  type: 'total' | 'actual' | 'fixed';
}


interface GeneralActivity {
  history: ActivityHistory[]
}

interface MyEvt extends Event {
  pageX: number;
  pageY: number;
  offsetX: number;
  offsetY: number;
}

interface LineData {
  y: number;
  x: number;
}
interface AreaData {
  x: number;
  y0: number;
  y1: number;
}

export default class DailyActivityGraph extends React.Component<Props, State> {

  private activityGraphRef: React.RefObject<HTMLDivElement>;
  private foreground: string;
  private background: string;

  constructor(props: Props) {
    super(props);
    this.activityGraphRef = React.createRef<HTMLDivElement>();

    this.background = 'rgba(255,255,255,0.5)'
    this.foreground = 'rgba(0,0,0,1.0)'

    this.state = {
      // colors: d3.scaleOrdinal(props.theme.graphColors.setB),
      width: window.innerWidth - 200,
      height: DEFAULT_HEIGHT,
      period: 7,
      averageType: '30day',
      type: 'fixed',
    }
  }

  componentDidMount() {
    const household = this.props.householdData;
    const generalActivity = household.behaviours.filter(b => b.type === BEHAVIOUR_TYPES.GENERAL_ACTIVITY) as GeneralActivity[];
    if (!generalActivity) return;
    generalActivity.map(ga => this.renderGraph(household.timezone, ga));
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.period != prevState.period || this.state.averageType != prevState.averageType || this.state.type != prevState.type) {
      const household = this.props.householdData;
      const generalActivity = household.behaviours.filter(b => b.type === BEHAVIOUR_TYPES.GENERAL_ACTIVITY) as GeneralActivity[];
      if (!generalActivity) return;
      generalActivity.map(ga => this.renderGraph(household.timezone, ga));
    }
  }

  mouseover(e: any, d: ActivityHistory) {
    const tt = select('#popup');
    tt.attr('class', 'popup fadein');
  }

  mousemove(e: any, d: { orig: { date: moment.Moment; actual: number; mean: number; sd: number; } }) {
    const tt = select('#popup');
    const data = d.orig;
    if (data && data.mean) {
      tt.html(`${data.date}</br>Activity: ${data.actual.toFixed(2)}</br>Mean: ${data.mean.toFixed(2)} sd: ${data.sd.toFixed(2)}`)
    } else
      tt.html('')
    tt.style("left", (e.offsetX + 80) + "px")
      .style("top", (e.pageY + 20) + "px")
    e.target.setAttribute('r', 10)
  }

  mouseout(e: any, d: ActivityHistory) {
    const tt = select('#popup');
    tt.attr('class', 'popup fadeout')
    e.target.setAttribute('r', 5)
  }

  renderGraph(timezone: string, generalActivity: GeneralActivity) {

    const { width, height, period, averageType, type } = this.state;

    if (!generalActivity.history || !generalActivity.history.length) return;

    select(this.activityGraphRef.current)
      .select("svg")
      .remove()
    //d3 get div and add svg element of given size
    const svg = select(this.activityGraphRef.current)
      .append("svg")
      .attr('class', 'dailyActivityGraph')
      .attr("viewBox", "0 0 " + width + " " + DEFAULT_HEIGHT)
      .attr("width", "100%")
      .attr("height", DEFAULT_HEIGHT)

    const sortedHistory = generalActivity.history.sort((a, b) => a.date > b.date ? 1 : -1)

    const dailyData = sortedHistory.map(sh => {
      switch (type) {
        case 'total':
          return sh.actual;
        case 'actual':
          return sh.dayCountActual;
        case 'fixed':
          return sh.dayCountFixed;
      }
    })

    let sampleSize = 7;
    switch (averageType) {
      case '7day':
        sampleSize = 7;
        break;
      case '14day':
        sampleSize = 14;
        break;
      case '30day':
        sampleSize = 28;
        break;
      case 'dow':
        sampleSize = 7
        break;
    }


    let stats;

    if (averageType === 'dow') {
      const dowData = groupByDayOfWeek(timezone, sortedHistory);
      const dowStats: { mean: number; sd: number }[] = [];
      for (const dow in dowData) {
        dowStats.push(calcAverageAndStdDev(dowData[dow].map(s => {
          switch (type) {
            case 'total':
              return s.actual;
            case 'actual':
              return s.dayCountActual;
            case 'fixed':
              return s.dayCountFixed;
          }
        })))
      }

      stats = dailyData.map((dd, i) => {
        const date = sortedHistory[i].date;
        const dow = moment(date).day();
        return {
          date: date,
          actual: dd,
          mean: dowStats[dow].mean,
          sd: dowStats[dow].sd,
        }
      })
    }

    else {
      const ra = calcRollingAverage(sampleSize, dailyData);
      stats = dailyData.map((dd, i) => ({
        date: sortedHistory[i].date,
        actual: dd,
        mean: ra[i].mean,
        sd: ra[i].sd,
      }))

    }
    const sample = stats.slice(-period);

    const maxT = sample[sample.length - 1].date;
    const minT = sample[0].date;

    const { minY, maxY } = this.getMinMax(sample);

    const oneDayMs = 24 * 3600 * 1000;
    const endOflastDay = new Date(maxT).valueOf() + oneDayMs;

    const timeScale = scaleTime()
      .domain([new Date(minT), new Date(endOflastDay)])
      .range([40, width - 30])

    const yscale = scaleLinear()
      .domain([minY, maxY])
      .range([height - 30, 30])

    const colorSet = scaleOrdinal(schemeCategory10);

    const dayWidth = timeScale(Date.now()) - timeScale(Date.now() - oneDayMs);

    this.renderArea(svg, sample.map((d, i) => ({
      y0: yscale(d.mean + d.sd),
      y1: yscale(d.mean - d.sd),
      x: timeScale(new Date(d.date)),
    })), brandPurple.main);

    this.renderLine(svg, sample.map((d, i) => ({
      y: yscale(d.actual),
      x: timeScale(new Date(d.date)),
      orig: {
        ...d,
        date: new Date(d.date).toDateString(),
      },
    })), brandPink.main);

    this.renderXAxis(timeScale, svg);
    this.renderYAxis(yscale, svg);
  }

  private renderYAxis(yscale: any, svg: any) {
    const yaxis = axisLeft(yscale);
    svg.append('g')
      .attr('transform', 'translate(40,0)')
      .call(yaxis)
      .selectAll('line,path')
      .attr('stroke', this.foreground)
      .selectAll('text')
      .attr('fill', this.foreground);
  }

  private renderXAxis(timeScale: any, svg: any) {
    const timeAxis = axisBottom(timeScale);
    svg.append('g')
      .attr('transform', `translate(0,${DEFAULT_HEIGHT - 30})`)
      .call(timeAxis)
      .selectAll('line,path')
      .attr('stroke', this.foreground)
      .selectAll('text')
      .attr('fill', this.foreground);
  }

  private renderLine(svg: any, data: LineData[], color: string) {
    const actual = line<LineData>()
      .curve(curveBumpX)
      .x((d: LineData) => d.x)
      .y((d: LineData) => d.y);

    svg.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke-width", 3)
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round")
      .attr("stroke", color)
      .attr("d", actual);

    svg.append('g')
      .selectAll('circle')
      .data(data)
      .enter()
      .append('circle')
      .attr('r', 5)
      .attr('cx', (d: LineData) => d.x)
      .attr('cy', (d: LineData) => d.y)
      .attr('stroke', color)
      .attr('fill', color)
      .on('mouseover', this.mouseover)
      .on('mousemove', this.mousemove)
      .on('mouseleave', this.mouseout);
  }

  private renderArea(svg: any, data: AreaData[], color: string) {
    const areaLine = area<AreaData>()
      .curve(curveBumpX)
      .x((d: AreaData) => d.x)
      .y0((d: AreaData) => d.y0)
      .y1((d: AreaData) => d.y1);

    svg.append("path")
      .datum(data)
      .attr("fill", color)
      .attr("stroke-width", 1.5)
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round")
      .attr("stroke", brandPurple.light)
      .attr("d", areaLine);
  }

  getMinMax(data: { actual: number; mean: number; sd: number }[]): { maxY: number, minY: number } {
    const maxY = data.reduce((p: number, c) => {
      const val = c.mean + c.sd;
      if (val > p) return val;
      return p;
    }, 0);

    const minY = data.reduce((p: number, c) => {
      const val = c.mean - c.sd;
      if (val < p) return val;
      return p;
    }, 100);

    return { maxY, minY }
  }

  render() {
    const { period, averageType, type } = this.state;
    return (
      <div ref={this.activityGraphRef}>
        <Typography component='div'>
          <Grid container>
            <Grid item style={{ paddingRight: 20 }}>
              <p>Display Period</p>
              <Button onClick={() => this.setState({ period: 7 })} variant={period === 7 ? 'outlined' : 'text'}>7 Days</Button>
              <Button onClick={() => this.setState({ period: 30 })} variant={period === 30 ? 'outlined' : 'text'}>30 Days</Button>
            </Grid>
            <Grid item style={{ paddingRight: 20 }}>
              <p>Average period</p>
              <Button onClick={() => this.setState({ averageType: '7day' })} variant={averageType === '7day' ? 'outlined' : 'text'}>7 Day Avg</Button>
              <Button onClick={() => this.setState({ averageType: '14day' })} variant={averageType === '14day' ? 'outlined' : 'text'}>14 Day Avg</Button>
              <Button onClick={() => this.setState({ averageType: '30day' })} variant={averageType === '30day' ? 'outlined' : 'text'}>30 Day Avg</Button>
              <Button onClick={() => this.setState({ averageType: 'dow' })} variant={averageType === 'dow' ? 'outlined' : 'text'}>Day of week Avg</Button>
            </Grid>
            <Grid item>
              <p>Activity Count Method</p>
              <Button onClick={() => this.setState({ type: 'total' })} variant={type === 'total' ? 'outlined' : 'text'}>Total</Button>
              <Button onClick={() => this.setState({ type: 'actual' })} variant={type === 'actual' ? 'outlined' : 'text'}>Actual</Button>
              <Button onClick={() => this.setState({ type: 'fixed' })} variant={type === 'fixed' ? 'outlined' : 'text'}>Fixed</Button>
            </Grid>
          </Grid>
          <div id='popup' className='popup'></div>
        </Typography >
      </div >
    )
  }

}
