import React from 'react'
import Typography from '@mui/material/Typography';
import Avatar from '@material-ui/core/Avatar'
import TableRow from '@mui/material/TableRow';
import { getInitials } from '../Modals/utils/getInitials';
import TableCell from '@mui/material/TableCell';
import { purple } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../interfaces/reduxState';
import { User } from '../../../../api/OrganisationApi';
import { useEffect } from 'react';
import { getUserDetailsForCacheBegin } from '../../../../redux/domains/organisations/actionCreators';
import { amILoading } from '../Modals/utils/amILoading';
import Skeleton from '@mui/material/Skeleton';

interface UserListItemProps
{
  userId: string;
  roles: string[];
  onUserClick: (userId: string) => void;
}

export const UserListItem = ({ userId, roles, onUserClick }: UserListItemProps) =>
{
  const dispatch = useDispatch()
  const user = useSelector(({ organisations }: ApplicationState ) => organisations.userCache[userId])
  const isGettingUserWithId = useSelector(({ organisations }: ApplicationState ) => organisations.isGettingUserWithId)

  useEffect(() => {
    if(!user) dispatch(getUserDetailsForCacheBegin(userId))
  }, [])

  return(
    <TableRow
      onClick={() => onUserClick(userId)}
      hover
      key={userId}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
      <TableCell component="th" scope="row">
        {
          amILoading(userId, isGettingUserWithId) ?
            <div style={styles.avatarContainer}>
              <Skeleton variant="circular" height={50} width={50} style={styles.skeletonAvatar} />
              <Skeleton variant="text" width={150} />
            </div>

            :
            <div style={styles.avatarContainer}>
              <Avatar style={styles.avatar}>{getInitials(user && user.firstName && user.lastName ? user.firstName + " " + user.lastName : "")}</Avatar>
              <Typography>{user ? user.firstName + " " + user.lastName : ""}</Typography>
            </div>
        }

      </TableCell>

      { amILoading(userId, isGettingUserWithId) ?
        <>
          <TableCell align="right">
            <Skeleton width={150} />
          </TableCell>
          <TableCell align="right">
            <Skeleton width={150}/>
          </TableCell>
        </>
        :
        <>
          <TableCell align="right">{user ? user.email : ""}</TableCell>
          <TableCell align="right">{roles.toString()}</TableCell>
        </>
      }


    </TableRow>
  )
}


const styles = {
  avatar: {
    backgroundColor: purple[500],
    marginRight: 6,
  },
  avatarContainer: {
    flexDirection: "row" as "row",
    display: "flex" as "flex",
    alignItems: 'center',
  },
  skeletonAvatar: {
    marginRight: 6,
  },
}