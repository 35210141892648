
import moment from 'moment';
import { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callAPI } from '../../../../../../../redux/domains/user/userStore';
import { GET_HEALTH_METRICS_CUSTOM, GET_HEALTH_METRICS_TYPES } from '../../../../../../../redux/shared/services/APIEndpoints';
import { ChartTypes } from '../AllMetrics';

export const useHealthMetrics = (householdId: string) =>
{
  const [ chartType, setType ] = useState<ChartTypes>("bar");
  const [ currentItem, setCurrentItem ] = useState("Select a metric");

  const dispatch = useDispatch();
  const types = useSelector(({ healthMetrics }: any) => healthMetrics.healthMetricTypes)
  const isGettingTypes = useSelector(({ healthMetrics }: any) => healthMetrics.isGettingTypes)
  const isGettingCustomTypeGraph = useSelector(({ healthMetrics }: any) => healthMetrics.isGettingCustomTypeGraph)
  const customGraph = useSelector(({ healthMetrics }: any) => healthMetrics.customGraph)

  useEffect(() => {
    dispatch(callAPI(GET_HEALTH_METRICS_TYPES({ householdId: householdId })))

    return () => clearMetricsOnUnMount()

  }, [])

  const clearMetricsOnUnMount = () =>
  {
    dispatch({ type: "CLEAR_GET_HEALTH_METRICS_CUSTOM" })
  }


  const handleOnChoose = (item: string) =>
  {
    setCurrentItem(item)
    // Dispatch the call to get the data
    const source = item.split(" - ")[0]
    const metricType = item.split(" - ")[1]
    dispatch(callAPI(GET_HEALTH_METRICS_CUSTOM({ householdId, dataSource: source, metricType: metricType, graphMode: true, timestamp: moment().subtract(30, 'days').toISOString() })))
  }

  return [ isGettingTypes, isGettingCustomTypeGraph, currentItem, handleOnChoose, types, chartType, setType, customGraph ]
}