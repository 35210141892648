import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import ActivityIcon from '@mui/icons-material/DirectionsWalk'

export const NoActivities = () =>
{
  return(
    <Card style={styles.container}>
      <CardContent>
        <div style={styles.contentContainer}>
          <ActivityIcon style={styles.icon}/>
          <Typography color="textSecondary">Looks like there's no activity here</Typography>
        </div>
      </CardContent>
    </Card>
  )
}

const styles = {
  container: {
    minHeight: 1000,
  },
  contentContainer: {
    paddingTop: 12,
    textAlign: 'center' as 'center',
  },
  icon: {
    color: "grey",
    width: 50,
    height: 50,
  },
}