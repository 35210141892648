import { Builder } from "./ApiBuilder";

export class HouseholdApi extends Builder
{
  constructor(public token: string)
  {
    super(true)
  }
  getHousehold = async (householdId: string): Promise<string | undefined> =>
  {
    try
    {
      const respsonse = await this.call(`GET`, `/api/v2/household/${householdId}`, null, this.token);
      if(respsonse && respsonse.data) return respsonse.data
      return undefined
    }
    catch(error)
    {
      return undefined;
    }

  }

  getGateway = async (gatewayId: string): Promise<string | undefined> =>
  {
    try
    {
      const respsonse = await this.call(`GET`, `/api/data/gateway/${gatewayId}`, null, this.token);
      if(respsonse && respsonse.data) return respsonse.data
      return undefined
    }
    catch(error)
    {
      return undefined;
    }
  }
}