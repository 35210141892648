import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr'
import { takeEvery, put, take, cancelled } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { HOUSEHOLD_SET_STALE } from '../householdsV2/householdsStore'
import { setAlertsMinimised } from '../notifications/notificationsStore'
import access from 'safe-access'
import { Builder } from '../../../api/ApiBuilder'

const Promise = require('bluebird')
const async = Promise.coroutine

let token
let WSS_NEGOTIATE_URL = `${process.env.REACT_APP_API_URL_V3}/api`
let connection
let channel
const TARGET_MESSAGE = 'newMessage'
const WEBPOP_TOPIC = "webpop_topic"

export const azureToken = (state) => state.user.token


function* websocketSaga() {
  yield takeEvery("LOGIN", websocketHandler)
  yield takeEvery("LOGOUT", () => connection.stop())
  yield takeEvery("REFRESH_TOKEN_SUCCESS", handleTokenChange)
  yield takeEvery("REFRESH_TOKEN_HOUSEHOLDS_SUCCESS", handleTokenChange)
}

function handleTokenChange(action) {
  token = action.details.azureToken || action.details.access_token
}

/**
 * Handles signalr initialization and socket events
 */
function* websocketHandler(action) {

  console.log("Starting websocketHandler")
  try {

    const tokenRefresher = new Builder(true);
    connection = new HubConnectionBuilder()
      .withAutomaticReconnect()
      .withUrl(WSS_NEGOTIATE_URL, { accessTokenFactory: () => tokenRefresher.refreshToken() })
      .build()

    connection.keepAliveIntervalInMilliseconds = 900000 // 15 mins

    yield startConnection()

    while (true) { // eslint-disable-line no-constant-condition
      const { event } = yield take(channel)

      if (!event) continue
      if (event.type !== 'household') continue

      if(access(event, 'data.status'))
      {
        yield handleInboundNotification(event)
      }
      else
      {
        yield handleHouseholdListChanged(event)
      }
    }
  }
  catch(error)
  {
    console.log("Error starting connection")
  } finally {
    if (yield cancelled()) {
      connection.off(TARGET_MESSAGE)
      connection.off(WEBPOP_TOPIC)
      connection.stop()
    }
  }
}

function* handleHouseholdListChanged(event)
{
  let reduxAction = {
    type: HOUSEHOLD_SET_STALE,
    event: { id: event.id, stale: true },
  }
  yield put(reduxAction)
}

function* handleInboundNotification(event)
{
  const payload = { type: "ALERT" }
  if(event.data.status === "ACKNOWLEDGED")
  {
    yield put({ type: "ALERT_RESOLVED", payload })
    yield put(setAlertsMinimised(true))
  }

  if(event.data.status === "ACTIVE")
  {
    yield put({ type: "NEW_ALERT_RECEIVED", payload })
    yield put(setAlertsMinimised(false))
  }

}

function* startConnection() {

  channel = eventChannel(emitter => {

    connection.on(TARGET_MESSAGE, (event) => {
      emitter({ event })
    })

    connection.on(WEBPOP_TOPIC, (event) => {
      emitter({ event })
    })

    connection.onclose(async(function* (error) { // eslint-disable-line no-unused-vars
      while (connection.state === HubConnectionState.Disconnected) {
        yield sleep(5000)
        try {
          yield connection.start()
        } catch (error) {
          console.log("Error connecting to signalR hub")
        }
      }
    }))
    return () => {
      // Perform any cleanup you need here
    }
  })

  yield connection.start()

}

function sleep(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

export default websocketSaga
