import { CircularProgress } from '@material-ui/core';
import React from 'react'
import { NoMetricData } from './NoMetricData';
import { GraphTypes } from './GraphTypes';
import { useHealthMetrics } from './hooks/useHealthMetrics';
import { LoadingGraph } from './LoadingGraph';
import { MetricChooser } from './MetricChooser'
import { MetricGraph } from './MetricGraph'


export type ChartTypes = "bar" | "area" | "line" | "pie" | "radialBar"

interface AllMetricsProps
{
  householdId: string;
}

export const AllMetrics = ({ householdId }: AllMetricsProps) =>
{

  const [ isGettingTypes, isGettingCustomTypeGraph, currentItem, handleOnChoose, types, chartType, setType, customGraph ] = useHealthMetrics(householdId);


  return(
    <div>
      <div style={styles.actionsRow}>
        { !isGettingTypes ? <MetricChooser currentItem={currentItem} onChoose={handleOnChoose} items={types || []} /> : <CircularProgress size={24} color="primary" />}
        <div style={{ flex: 1 }}></div>
        <GraphTypes selectedItem={chartType} onItemClick={(item) => setType(item)}/>
      </div>


      { isGettingCustomTypeGraph &&  <LoadingGraph /> }
      { !isGettingCustomTypeGraph && customGraph && customGraph.length > 0 ? <MetricGraph height={400} data={customGraph} graphType={chartType} /> : <NoMetricData currentItem={currentItem}/>}

    </div>
  )
}
const styles = {
  container: {

  },
  actionsRow: {
    flexDirection: 'row' as 'row',
    display: 'flex' as 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: 24,
    paddingLeft: 24,
    paddingBottom: 24,
  },
}