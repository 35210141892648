import {
  all,
  put,
  takeEvery,
} from 'redux-saga/effects'

import access from 'safe-access'

import {
  VIEW_HOUSEHOLD_INFORMATION,
  viewHousehold,
  finishedLoadingHouseholdView,
} from '../../domains/households/householdsStore'

import {
  // ACTIVITYFEED,
  ACTIVITYGRAPH,
  // HOUSEHOLDGATEWAY,
  OVERALLACTIVITYGRAPH,
  AVERAGEACTIVITY,
  HOUSEHOLD_GRAPHS,
} from '../../shared/services/APIEndpoints'

import {
  callAPISaga,
} from '../../shared/services/WebAPI'

function* getDashboardInformation(action) {
  const household = access(action, 'household')
  // const gatewayId = access(action, 'household.gatewayId')
  yield put(viewHousehold(household))
  // const activityEndpoint = ACTIVITYFEED({ gatewayId: gatewayId, householdId: household.id })
  // const gatewayEndpoint = HOUSEHOLDGATEWAY({ gatewayId: gatewayId, householdId: household.id })
  const activityGraphEndpoint = ACTIVITYGRAPH({
    householdId: household.id,
    timePeriod: 24,
  })
  const overallActivityGraphEndpoint = OVERALLACTIVITYGRAPH({
    householdId: household.id,
    timePeriod: 168,
  })
  const averageActivityEndpoint = AVERAGEACTIVITY({
    householdId: household.id,
    timePeriod: 168,
  })

  try {
    yield all([
      // callAPISaga(activityEndpoint),
      // callAPISaga(gatewayEndpoint),
      callAPISaga(activityGraphEndpoint),
      callAPISaga(overallActivityGraphEndpoint),
      callAPISaga(averageActivityEndpoint),
      callAPISaga(HOUSEHOLD_GRAPHS({ householdId: household.id })),
    ])
  } catch (err) {
    // eslint-disable-next-line no-console,no-undef
    process.env.NODE_ENV && console.error(err)
  }
  yield put(finishedLoadingHouseholdView())
}

/*
  Starts loginAndGetUsers on each dispatched `LOGIN_START` action.
  Allows concurrent fetches.
*/
function* householdInformationSaga() {
  yield takeEvery(VIEW_HOUSEHOLD_INFORMATION, getDashboardInformation)
}

export default householdInformationSaga
