import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'


// Redux
import redux from '../../../../redux'

const callAPI = redux.userStore.callAPI

const {
  LINK_HOUSEHOLD_TO_RESELLER } = redux.apiEndpoints

const UnlinkHouseholdModal = (
  {
    open,
    onClose,
    resellerId,
    linkHouseholdToReseller,
    linkHouseholdResult,
    linkHouseholdError,
    clearLinkingResult,
    householdId,
    householdName,
  }) => {

  UnlinkHouseholdModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    resellerId: PropTypes.string,
    linkHouseholdToReseller: PropTypes.func,
    linkHouseholdResult: PropTypes.object,
    linkHouseholdError: PropTypes.object,
    isLinkingHousehold: PropTypes.bool,
    clearLinkingResult: PropTypes.func,
    householdId: PropTypes.string,
    householdName: PropTypes.string,
  }

  // Close the modal if we unlink the reseller, also clean up the reseller linking result in Redux
  useEffect(() => {
    if (linkHouseholdResult && linkHouseholdResult.result.resellerId === "None") {
      clearLinkingResult()
      onClose()
    }
  })

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Unlink Household"}</DialogTitle>
      <DialogContent>

        <DialogContentText>
          Do you want to unlink the household {householdName}
        </DialogContentText>


      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Cancel
        </Button>
        <Button onClick={() => linkHouseholdToReseller({ householdId: householdId, resellerId: "None" })} color="primary" autoFocus>
          Yes, Unlink
        </Button>
      </DialogActions>
    </Dialog >
  )
}

const mapStateToProps = state => {
  return {
    linkHouseholdResult: state.householdsV2.linkHouseholdResult,
    linkHouseholdError: state.householdsV2.linkHouseholdError,
    isLinkingHousehold: state.householdsV2.isLinkingHousehold,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    linkHouseholdToReseller: (params) => dispatch(callAPI(LINK_HOUSEHOLD_TO_RESELLER(params))),
    clearLinkingResult: () => dispatch({ type: "CLEAR_LINK_HOUSEHOLD_TO_RESELLER" }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnlinkHouseholdModal)