import { connect } from 'react-redux'
import { AuditLogsView } from './AuditLogsView'

const mapStateToProps = (state: any) =>
{
  return {
    token: state.user.token,
  }
}

export default connect(mapStateToProps, null)(AuditLogsView)