import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@mui/icons-material/Search'

import moment from 'moment'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

const SensorReportTable = ({ sensorEvents = [] }) => {

  SensorReportTable.propTypes = {
    sensorEvents: PropTypes.array,
  }

  const classes = useStyles()

  return (
    <div>
      {sensorEvents && sensorEvents.length > 0 ? <Table className={classes.table} aria-label="sensor report table">
        <TableHead>
          <TableRow>
            <TableCell>Event Time</TableCell>
            <TableCell align="right">DeviceId</TableCell>
            <TableCell align="right">SensorId</TableCell>
            <TableCell align="right">Device Type</TableCell>
            <TableCell align="right">New State</TableCell>
            <TableCell align="right">Old State</TableCell>
            <TableCell align="right">Room Type</TableCell>
            <TableCell align="right">Behaviour</TableCell>
            <TableCell align="right">Event Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sensorEvents && sensorEvents.length > 0 && sensorEvents.map((event) => (
            <TableRow hover key={event.id}>
              <TableCell component="th" scope="row">
                {`${moment.utc(event.eventTime)} (${moment(event.eventTime).fromNow()})`}
              </TableCell>
              <TableCell align="right">{event.deviceId}</TableCell>
              <TableCell align="right">{event.sensorId}</TableCell>
              <TableCell align="right">{event.deviceType}</TableCell>
              <TableCell align="right">{event.newState}</TableCell>
              <TableCell align="right">{event.oldState}</TableCell>
              <TableCell align="right">{event.roomType}</TableCell>
              <TableCell align="right">{event.behaviour ? event.behaviour : "None"}</TableCell>
              <TableCell align="right">{event.eventType}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> :
        <div style={{ textAlign: "center", flex: 1 }}>
          <SearchIcon />
          <Typography variant="h3" component="h3">No events to see here</Typography>
        </div>}
    </div>
  )
}

export default SensorReportTable