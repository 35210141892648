import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import InteliIcon from '../../../../components/InteliIcon'
import { TILE_TYPES } from '../enums'

import styles from './BehaviourRollupStyles'
import sharedStore from '../../../../redux'
import moment from "moment";

const colorForStatusValue = sharedStore.colors.colorForStatusValue
const getColoredClassificationIcon = sharedStore.behaviour.getColoredClassificationIcon

const { enums } = require('@intelicare/shared')
const TIMEOUT_DURATION = 500;
const {
  BEHAVIOUR_STATUS,
  BehaviourClassification,
  BehaviourClassificationFriendly,
} = enums

const FRIENDLY_STATUS = {
  NORMAL: "Normal",
  WARNING: "Warning",
  ALERT: "Alert",
  UNKNOWN: "Unknown",
  LEARNING: "Learning",
  DISABLED: 'Disabled',
}

class BehaviourRollupView extends Component {
  state = {
    timeOut: undefined,
  }

  constructor(props) {
    super(props)
    this.tileId = `tile-${uuidv4()}`
  }

  // Private functions
  _onTileHover = () => {
    this.timeOut = setTimeout(() => {
      const { household, getHousehold, onShowSnack, classification, status } = this.props
      if ((!household.data) ||
        (!household.data.isFetching && !household.data.behaviours)) getHousehold(household.householdId)
      this.getEvents()


      const statusColor = colorForStatusValue(status || BEHAVIOUR_STATUS.UNKNOWN)
      // Callback to show the snackbar
      const icon = getColoredClassificationIcon(
        BehaviourClassification[classification],
        statusColor
      )

      onShowSnack(TILE_TYPES.BEHAVIOUR, true, household, icon, BehaviourClassification, BehaviourClassificationFriendly, classification)
    }, TIMEOUT_DURATION);
  }

  _onTileHoverOut = () => {
    clearTimeout(this.timeOut);
    const { onShowSnack } = this.props
    onShowSnack("", false, null, null, null, null, null)
  }


  getEvents = () => {
    const { fetchActivityfeedV2, householdId } = this.props;
    fetchActivityfeedV2(
      householdId,
      moment().subtract(24, "hours").toISOString(),
      moment().toISOString()
    );
  };

  render() {
    const {
      classes,
      classification,
      status,
      onClick,
    } = this.props

    const statusColor = colorForStatusValue(status || BEHAVIOUR_STATUS.UNKNOWN)

    const icon = getColoredClassificationIcon(
      BehaviourClassification[classification],
      statusColor
    )

    return (
      <Grid
        automation_attribute={classification + "_ROLLUP"}
        id={this.tileId}
        item
        xs
        className={classes.TileContainer}
        onClick={onClick}
        onMouseOver={this._onTileHover}
        onMouseLeave={this._onTileHoverOut}>
        <InteliIcon name={icon.name} family={icon.family} color={icon.color} size={36} />
        <Typography style={{ color: statusColor }}>{BehaviourClassificationFriendly[classification]}</Typography>
        <Typography style={{ color: statusColor }}>{FRIENDLY_STATUS[status] || 'Unknown'}</Typography>
      </Grid>
    )
  }

  static propTypes = {
    fetchActivityfeedV2: PropTypes.func,
    classes: PropTypes.object.isRequired,
    classification: PropTypes.string.isRequired,
    status: PropTypes.string,
    household: PropTypes.object,
    onClick: PropTypes.func,
    householdId: PropTypes.string,
    getHousehold: PropTypes.func,
    activityStreamEventsError: PropTypes.any,
    isGettingActivityEvents: PropTypes.bool,
    activityStreamEvents: PropTypes.array,
    isDeveloper: PropTypes.bool,
    onShowSnack: PropTypes.func,
  }
}

export default withStyles(styles)(BehaviourRollupView)



