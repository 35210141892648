import React from 'react'
import Card from '@material-ui/core/Card'
import Avatar from '@material-ui/core/Avatar'
import CardHeader from '@material-ui/core/CardHeader'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import HomeIcon from '@mui/icons-material/Home';
import { PresenceBehaviour } from './util/getPresenceBehaviour';
import { SOCIAL_ISOLATION_COLORS } from './util/colors';

interface HomeStatusProps
{
  presence: PresenceBehaviour
}


export const HomeStatus = ({ presence } : HomeStatusProps) =>
{
  return(
    <Card style={styles.cardContainer}>
      <CardHeader
        titleTypographyProps={{ variant:'h6' }}
        avatar={
          <Avatar style={{ ...styles.avatar, backgroundColor: presence.status.msg === "The resident is home." ? SOCIAL_ISOLATION_COLORS.NORMAL : SOCIAL_ISOLATION_COLORS.WARNING }}>
            {presence.status.msg === "The resident is home." ? <HomeIcon fontSize="large"/> : <SupervisedUserCircleIcon fontSize="large" /> }
          </Avatar>
        }

        title="Home Status"
        subheader={presence.status.msg}
      />
    </Card>
  )
}

const styles = {
  cardContainer: {
    padding: 12,
    flex: 1,
    borderRadius: 6,
    marginBottom: 12,
  },
  avatar: {
    width: 100,
    height: 100,
  },
  icon: {
    width: 100,
    height: 100,
  },
}