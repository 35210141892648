/* eslint-disable  */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";

import { withTheme, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";

import PortalOverlay, { PortalOverlayStyles } from "../../components/PortalOverlay";

import ProfileSettings from "./pages/ProfileSettings";
import FamilyResponsePlanSettings from "./pages/FamilyResponsePlanSettings";
import SleepSettings from "./pages/SleepSettings";
import { ZoneInsightsView } from "./pages/ZoneInsights/ZoneInsightsView";
import { MonitoringService } from "./pages/MonitorService/MonitorService";
import PermissionSettings from "./pages/PermissionSettings";
import HealthMetricSettings from "./pages/HealthMetrics/HealthMetricsSettings";
import AlexaSettings from "./pages/AlexaSettings";
import AuditRecords from './pages/AuditRecords'
import RealtimeAlerting from './pages/RealtimeAlerting'
import styles from "./HouseholdSettingsStyles";

import { appInsights } from '../../api/AppInsights';
import { ZoneConfigurationView } from "./pages/ZoneConfiguration/ZoneConfigurationView";

class HouseholdSettingsPortalView extends Component {
  static pageName = "householdSettings";

  constructor(props) {
    super(props);
    this.state = {
      page: "profile",
      pageTitle: "Profile",
    };

    this.changePage = this.changePage.bind(this);
  }

  changePage(pageName) {
    let pageTitle = "";
    if (pageMap && pageMap[pageName] && pageMap[pageName].title) {
      pageTitle = pageMap[pageName].title;
    }

    this.setState({ page: pageName, pageTitle: pageTitle })

    appInsights.trackPageView(pageName);
  }

  componentDidMount() {
    const { activeHouseholdId, getCarers, showPage } = this.props;
    getCarers(activeHouseholdId);
    if (showPage) {
      this.changePage(showPage);
    }

    appInsights.trackPageView({name: "Household Settings"})
  }

  render() {
    const {
      close,
      classes,
      theme,
      isDeveloper,
      isSupport,
      isDemoUser,
    } = this.props;
    const { page, pageTitle } = this.state;

    return (
      <PortalOverlay
        automation_attribute="householdSettingsModal"
        style={{ height: "80%" }}
      >
        <div
          className={classes.OverlayHeader}
          style={{ background: theme.palette.primary.main }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <Icon classes={{ root: classes.icon }}>home</Icon>
            </Grid>
            <Grid item>
              <Typography
                noWrap
                style={{ color: theme.palette.primary.contrastText }}
                classes={{ title: classes.title }}
                variant="h6"
              >
                Household Settings {"\u226b"} {pageTitle}
              </Typography>
            </Grid>
          </Grid>
          <IconButton onClick={close}>
            <Icon classes={{ root: classes.closeIcon }}>close</Icon>
          </IconButton>
        </div>
        <div className={classes.OverlayBody}>
          <div className={classes.OverlaySidebar}>
            <SettingsMenu
              changePage={this.changePage}
              isDemoUser={isDemoUser}
              isDeveloper={isDeveloper}
              isSupport={isSupport}
            />
          </div>
          <PageLoader page={page} close={close} />
        </div>
      </PortalOverlay>
    );
  }

  static propTypes = {
    close: PropTypes.func.isRequired,
    theme: PropTypes.object,
  };
}

const pageMap = {
  profile: {
    component: ProfileSettings,
    icon: <Icon color="primary">account_circle</Icon>,
    title: "Profile",
  },
  familyResponse: {
    component: FamilyResponsePlanSettings,
    icon: <Icon color="primary">local_hospital</Icon>,
    title: "Family Response Plan",
  },
  sleepTime: {
    component: SleepSettings,
    icon: <Icon color="primary">night_shelter</Icon>,
    title: "Sleep and Wake Times",
  },
  insights: {
    component: ZoneInsightsView,
    icon: <Icon color="primary">insights</Icon>,
    title: "Insights",
  },
  permissions: {
    component: PermissionSettings,
    icon: <Icon color="primary">lock</Icon>,
    title: "Permissions",
  },
  zoneConfig: {
    component: ZoneConfigurationView,
    icon: <Icon color="primary">supervised_user_circle</Icon>,
    title: "Zones",
  },
  auditRecords: {
    component: AuditRecords,
    icon: <Icon color="primary">security</Icon>,
    title: "Audit Records",
  },
  healthMetrics: {
    component: HealthMetricSettings,
    icon: <Icon color="primary">favorite_border</Icon>,
    title: "Health Metrics",
  },
  alexa: {
    component: AlexaSettings,
    icon: <Icon color="primary">sync</Icon>,
    title: "Alexa Sync",
  },
  monitorService: {
    component: MonitoringService,
    icon: <Icon color="primary">support_agent</Icon>,
    title: "Monitoring Services",
  },
  realtimeAlerting: {
    component: RealtimeAlerting,
    icon: <Icon color="primary">radar</Icon>,
    title: "Vital Monitoring",
  },
};

class SettingsMenu extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isDeveloper, isSupport, isDemoUser } = this.props;
     
    return (
      <MenuList>
        {Object.keys(pageMap).map((key) => {
          if(key !== "monitorService" && key !== "realtimeAlerting" || key !== "zoneConfig" || key !== "zoneInsights" || (isDeveloper||isSupport))
          {
            return (
            <SettingsMenuItem
              isDeveloper={isDeveloper}
              isSupport={isSupport}
              isDemoUser={isDemoUser}
              key={key}
              icon={pageMap[key].icon}
              text={pageMap[key].title}
              onClick={() => this.props.changePage(key)}
            />
            )
          }
        })}
      </MenuList>
    );
  }
}

class _SettingsMenuItem extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { text, icon, classes, onClick } = this.props;
    return (
      <MenuItem onClick={onClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText classes={{ primary: classes.menuText }}>
          {text}
        </ListItemText>
      </MenuItem>
    );
  }
}
const SettingsMenuItem = withStyles(styles)(_SettingsMenuItem);
class PageLoader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { page, close } = this.props;

    const pageItem = pageMap[page];

    return <pageItem.component close={close} />;
  }
}

export class ErrorPage extends Component {
  static pageName = "householdSettingsError";
  static propTypes = {
    close: PropTypes.func,
  };
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <PortalOverlay>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={true}
          autoHideDuration={6000}
          onClose={this.props.close}
          message={<span>Error opening household settings!</span>}
        />
      </PortalOverlay>
    );
  }
}

export default compose(
  withStyles(styles),
  withStyles(PortalOverlayStyles),
  withTheme
)(HouseholdSettingsPortalView);
