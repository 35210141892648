import React from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { PresenceBehaviour } from './util/getPresenceBehaviour';
import { OutingPeriodGraph } from '../../graphs/OutingPeriodGraph';

interface OutingsViewProps
{
  householdTz: string;
  presence: PresenceBehaviour;
}

export const OutingsView = ({ householdTz, presence }: OutingsViewProps) =>
{

  return(
    <Card
      style={styles.container}>
      <Typography style={styles.title} variant="h6">Outing Times</Typography>
      <OutingPeriodGraph outingHistory={presence.notHomeHistory} householdTz={householdTz} />
    </Card>
  )
}

const styles = {
  container: {
    flex: 1,
    padding: 12,
  },
  title: {
    padding: 12,
  },
}