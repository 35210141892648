import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ListItemIcon } from '@material-ui/core';
import getIcon from './getIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { display: 'flex' },
    paper: { marginRight: theme.spacing(2) },
  }),
);

interface TypeFilterMenuProps {
  onChangeListener: (value: any) => void;
}

export default function StatusFilterMenu(props: TypeFilterMenuProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) return;
    if (typeof event === 'string') props.onChangeListener(event);
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) anchorRef.current!.focus();
    prevOpen.current = open;
  }, [open]);

  const iconSize = "small"
  return <div className={classes.root}>
    <Button
      ref={anchorRef}
      aria-controls={open ? 'menu-list-grow' : undefined}
      aria-haspopup="true"
      onClick={handleToggle}
      variant="outlined"
    >
      Filter by status
    </Button>
    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={open}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                <MenuItem onClick={() => handleClose("")}><ListItemIcon />All</MenuItem>
                <MenuItem onClick={() => handleClose("ACTIVE")}>
                  <ListItemIcon>
                    {getIcon("ALERT", "ACTIVE", iconSize)}
                    {getIcon("NOTIFICATION", "ACTIVE", iconSize)}
                  </ListItemIcon>
                  Active
                </MenuItem>
                <MenuItem onClick={() => handleClose("PENDING")}>
                  <ListItemIcon>{getIcon("ALERT", "PENDING", iconSize)}</ListItemIcon>
                  Pending
                </MenuItem>
                <MenuItem onClick={() => handleClose("ACKNOWLEDGED")}>
                  {getIcon("ALERT", "ACKNOWLEDGED", iconSize)}
                  {getIcon("NOTIFICATION", "ACKNOWLEDGED", iconSize)}
                  {getIcon("INFO", "ACKNOWLEDGED", iconSize)}
                  Acknowledged
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </div>;
}