import { connect } from 'react-redux';
import React from 'react'
//@ts-ignore
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { CircularProgress, Typography } from '@material-ui/core';
import { useShowerEvents } from './hooks/useShowerEvents';

import moment from 'moment';
import { brandPurple } from '../../../../../../themes/proTheme';
import { InteliIcon } from '../../../../../../components/InteliIcon/InteliIcon';

interface ShowerUsageGraphProps
{
  paniId: string | undefined;
  token: string;
  principal: string;
  householdId: string;
}


const ShowerUsageGraph = ({ paniId, token, principal, householdId }: ShowerUsageGraphProps) =>
{
  const [ isLoading, graphData ] = useShowerEvents(token, principal, paniId, householdId);

  const formatTooltip = (value: number, name: string, props: any) =>
  {
    let durationStr = ''
    // calc the diff and provide human readable formats
    const diffMin = moment(props.payload.end).diff(moment(props.payload.start), "minutes");
    if(diffMin === 1) durationStr = '1 minute'
    if(diffMin === 0) durationStr = 'Less than a minute'
    if(diffMin > 1) durationStr = `${diffMin} minutes`

    return `
    ${value} litres - duration : ${durationStr}`
  }

  return(
    <div style={styles.container}>
      {!isLoading && graphData && graphData.length > 0 ? <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width="100%"
          height="100%"
          data={graphData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="start" tickFormatter={(item: string) => moment(item).fromNow()} />
          <YAxis />
          <Tooltip
            labelFormatter={(timestamp: string) => `${moment(timestamp).format("LLLLZ")} - ${moment(timestamp).fromNow()}`}
            formatter={(value: number, name: string, props: any) => formatTooltip(value, name, props)}/>
          <Legend />
          <Line type="monotone" dataKey="usage" stroke={brandPurple.light} strokeWidth="2" activeDot={{ r: 8 }} />

        </LineChart>
      </ResponsiveContainer> : isLoading ?
        <div style={styles.loadingView}>
          <CircularProgress variant="indeterminate" color="secondary" />
          <Typography color="textSecondary">Getting Events</Typography>
        </div> :
        <div style={styles.loadingView}>
          <InteliIcon name="bathtub" family="material" size={64} color="grey" />
          <Typography color="textSecondary">Couldn't find any events for this sensor ({paniId})</Typography>
        </div> }
    </div>
  )
}

const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    width: "100%",
    height: 300,
  },
  loadingView: {
    textAlign: 'center' as 'center',
    padding: 100,
  },
}

const mapStateToProps = ({ user }: any) =>
{
  return {
    token: user.token,
    principal: user.principal,
  }
}

export default connect(mapStateToProps)(ShowerUsageGraph)