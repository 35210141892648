import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'

import InteliIcon from "../../components/InteliIcon"
import ViewTableColumns from './ViewTableColumns'

import { colors } from '../../redux'

const primaryText = colors.primaryText

export default class SearchableTableToolbar extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selectOpen: false,
    }

    this.updateSearchString = _.debounce(props.updateSearchString, 300)
  }

  static propTypes = {
    updateSearchString: PropTypes.func.isRequired,
    updateColumns: PropTypes.func.isRequired,
    allColumns: PropTypes.array.isRequired,
    selectedColumns: PropTypes.array.isRequired,
  }

  toggleSelectOpen = () => this.setState({ selectOpen: !this.state.selectOpen })

  render() {
    const {
      allColumns,
      selectedColumns,
      updateColumns,
    } = this.props

    return (
      <Toolbar>

        <TextField style={{ marginTop: 0, minWidth: 200 }}
          onChange={(evt) => this.updateSearchString(evt.target.value)}
          hintText='Search here'
        />

        <InteliIcon
          onClick={this.toggleSelectOpen}
          color={primaryText}
          name='reorder'
          family='material'
        />
        {
          this.state.selectOpen &&
          <ViewTableColumns
            close={this.toggleSelectOpen}
            updateColumns={updateColumns}
            allColumns={allColumns}
            selectedColumns={selectedColumns} />
        }
      </Toolbar>
    )
  }
}
