import { BEHAVIOUR_TYPES } from '@intelicare/shared/enums';
import { Moment } from "moment";

export interface PresenceBehaviour
{
  classification: "presence";
  enabled: boolean;
  friendlyName: "Presence";
  id: string;
  lastUpdated: string;
  lastAcknowledged: string;
  name: "presence";
  status: {
      msg: string;
      value: "NORMAL";
  };
  type: "presence";
  friendlyState: string;
  notHomeHistory: NotHomeHistory[];
  state: string;
  statistics: {
      average: number;
      current: number;
      outingsPerWeek: number[];
      stdDev: number;
      direction?: string;
      color?: string;
      status?: string;
      message?: string;
  };
}

export interface NotHomeHistory
{
    start: Moment;
    text?: string;
    end: Moment;
}


export const getPresenceBehaviour = (householdV2: any): PresenceBehaviour => {
  return householdV2.data.behaviours.find((b: any) => b.type === BEHAVIOUR_TYPES.PRESENCE && b.classification === BEHAVIOUR_TYPES.PRESENCE)
}
