import { connect } from 'react-redux'
import { compose } from 'redux'
import UserProfilePortalView, { ErrorPage } from './UserProfilePortalView'
import { withErrorHandler } from '../../hoc/withErrorHandler'
import sharedStore from '../../redux'
const getUserAvatar = sharedStore.userSelector.getUserAvatar
const updateUserInfo = sharedStore.userStore.updateUserInfo
const clearUserInfoMsg = sharedStore.userStore.clearUserInfoMsg

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserInfo: (details) => {
      dispatch(updateUserInfo(details))
    },
    clearNotification: () => {
      dispatch(clearUserInfoMsg())
    },
  }
}

const mapStateToProps = (state) => {

  return {
    firstName: state.user.firstName,
    surname: state.user.lastName,
    mobilePhone: state.user.mobilePhone,
    avatar: getUserAvatar(state),
    wasSaveError: state.user.errorSavingUser,
    wasSaveSuccess: state.user.saveUserSuccess,
    isSaving: state.user.isSavingUser,
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorHandler(ErrorPage)
)(UserProfilePortalView)
