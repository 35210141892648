import { connect } from 'react-redux'

import HealthMetrics from './HealthMetrics'
import access from 'safe-access'
import { apiEndpoints, householdsV2Selector, householdsV2Store } from '../../../../../../redux'
import { callAPI } from '../../../../../../redux/domains/user/userStore'
const { GET_HEALTH_SUMMARY, SYNC_FITBIT_DATA, FITBIT_INITIAL_SYNC, HOUSEHOLD_V2_GET, HOUSEHOLD_SETTING_BULK, SYNC_PERSONA_DATA } = apiEndpoints
const { updateBehaviourSetting } = householdsV2Store
const getActiveHousehold = householdsV2Selector.getActiveHousehold
const isHouseholdFetching = householdsV2Selector.isHouseholdFetching

const mapStateToProps = state =>
{
  return {
    isGettingSummary: state.healthMetrics.isGettingSummary,
    summary: state.healthMetrics.summary,
    summaryError: state.healthMetrics.summaryError,
    activeHouseholdId: state.householdsV2.activeHouseholdId,
    fitbitSyncError: access(state, 'healthMetrics.syncFitbitError.message'),
    personaSyncError: access(state, 'healthMetrics.syncPersonaError.message'),
    isSyncingFitbitData: state.healthMetrics.isSyncingFitbitData,
    isSyncingPersonaData: state.healthMetrics.isSyncingPersonaData,
    updateSuccess: access(state, 'households.settings.success'),
    isSaving: access(state, 'households.settings.isSaving'),
    isSyncedWithFitbit: access(getActiveHousehold(state), 'data.fitbitData.synced'),
    isHealthMetricsEnabled: access(getActiveHousehold(state), 'data.features.enableHealthMetrics'),
    isPersonaHealthMetricsEnabled: access(getActiveHousehold(state), 'data.features.enablePersonaHealthMetrics'),
    householdDocHealthMetrics: access(getActiveHousehold(state), 'data.healthMetrics'),
    isFetching: isHouseholdFetching(state),
    lastFitbitSync: access(getActiveHousehold(state), "data.fitbitData.lastSynced"),
    lastPersonaSync: access(getActiveHousehold(state), "data.personaSync.lastSynced"),
  }
}

const mapDispatchToProps = dispatch =>
{
  return {
    getSummary: (params) => dispatch(callAPI(GET_HEALTH_SUMMARY(params))),
    updateLatestMetrics: (summaryItems, metric) => dispatch(
      { type: "UPDATE_HEALTH_SUMMARY",
        data: {
          summary: summaryItems,
          updatedMetric: metric,
        } }),
    syncFitbitData: (householdId, timestamp) => {
      const body = {
        householdId: householdId,
      }
      dispatch(callAPI(SYNC_FITBIT_DATA(body)))
      dispatch(updateBehaviourSetting(householdId, 'fitbitData.lastSynced', timestamp))
    },
    syncPersonaData: (householdId, timestamp) => {
      dispatch(callAPI(SYNC_PERSONA_DATA(householdId)))
      dispatch(updateBehaviourSetting(householdId, 'personaSync.lastSynced', timestamp))
    },
    fitbitInitialSync: (householdId, accessCode, redirectUrl) => {
      const body = {
        householdId: householdId,
        accessCode: accessCode,
        redirectUrl: redirectUrl,
      }
      dispatch(callAPI(FITBIT_INITIAL_SYNC(body)))
    },
    clearSyncData: () => {
      dispatch({ type: "CLEAR_SYNC_FITBIT_DATA" })
      dispatch({ type: "CLEAR_SYNC_PERSONA_DATA" })
    },
    refreshHousehold: (householdId) => {
      dispatch(callAPI(HOUSEHOLD_V2_GET({
        householdId,
      })))
    },
    enableHealthMetrics: (householdId) => {
      const params = {
        householdId: householdId,
        settings: {
          'features.enableHealthMetrics': true,
        },
      }
      dispatch(callAPI(HOUSEHOLD_SETTING_BULK(params))) // Update household doc
      dispatch(updateBehaviourSetting(householdId, 'features.enableHealthMetrics', true))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HealthMetrics)