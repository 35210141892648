import { Household, Organisation, User, UserDetails } from "../../../api/OrganisationApi"
import {
  ADD_HOUSEHOLD_TO_ORGANISATION_BEGIN,
  ADD_HOUSEHOLD_TO_ORGANISATION_CLEAR,
  ADD_HOUSEHOLD_TO_ORGANISATION_ERROR,
  ADD_HOUSEHOLD_TO_ORGANISATION_SUCCESS,
  ADD_ORGANISATION_BEGIN,
  ADD_ORGANISATION_CLEAR,
  ADD_ORGANISATION_ERROR,
  ADD_ORGANISATION_SUCCESS,
  ADD_ROLE_BEGIN,
  ADD_ROLE_CLEAR,
  ADD_ROLE_ERROR,
  ADD_ROLE_SUCCESS,
  ADD_USER_TO_ORGANISATION_BEGIN,
  ADD_USER_TO_ORGANISATION_CLEAR,
  ADD_USER_TO_ORGANISATION_ERROR,
  ADD_USER_TO_ORGANISATION_SUCCESS,
  DELETE_ORGANISATION_BEGIN,
  DELETE_ORGANISATION_CLEAR,
  DELETE_ORGANISATION_ERROR,
  DELETE_ORGANISATION_SUCCESS,
  GET_USER_DETAILS_BEGIN,
  GET_USER_DETAILS_CLEAR,
  GET_USER_DETAILS_ERROR,
  GET_USER_DETAILS_FOR_CACHE_BEGIN,
  GET_USER_DETAILS_FOR_CACHE_ERROR,
  GET_USER_DETAILS_FOR_CACHE_SUCCESS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_ORG_DETAILS_BEGIN,
  GET_USER_ORG_DETAILS_CLEAR,
  GET_USER_ORG_DETAILS_ERROR,
  GET_USER_ORG_DETAILS_SUCCESS,
  LIST_ORGANISATIONS_BEGIN,
  LIST_ORGANISATIONS_ERROR,
  LIST_ORGANISATIONS_SUCCESS,
  REMOVE_ROLE_BEGIN,
  REMOVE_ROLE_CLEAR,
  REMOVE_ROLE_ERROR,
  REMOVE_ROLE_SUCCESS,
  REMOVE_USER_FROM_ORGANISATION_BEGIN,
  REMOVE_USER_FROM_ORGANISATION_CLEAR,
  REMOVE_USER_FROM_ORGANISATION_ERROR,
  REMOVE_USER_FROM_ORGANISATION_SUCCESS,
  SEARCH_ORGANISATIONS_BEGIN,
  SEARCH_ORGANISATIONS_CLEAR,
  STORE_HOUSEHOLD_SEARCH_RESULTS,
  VIEW_ORGANISATION_DETAILS,
} from "./actions"

export const listOrganisationsBegin = () => {
  return {
    type: LIST_ORGANISATIONS_BEGIN,
  }
}

export const listOrganisationsSuccess = (organisations: Organisation[]) => {
  return {
    type: LIST_ORGANISATIONS_SUCCESS,
    data: organisations,
  }
}

export const listOrganisationsError = (error: any) => {
  return {
    type: LIST_ORGANISATIONS_ERROR,
    error: error,
  }
}

export const viewOrganisationDetails = (organisation: Organisation) => {
  return {
    type: VIEW_ORGANISATION_DETAILS,
    data: organisation,
  }
}

export const addHouseholdToOrganisationBegin = (ouId: string, household: Organisation) => {
  return {
    type: ADD_HOUSEHOLD_TO_ORGANISATION_BEGIN,
    data: { ouId, household },
  }
}

export const addHouseholdToOrganisationSuccess = (organisations: Organisation[]) => {
  return {
    type: ADD_HOUSEHOLD_TO_ORGANISATION_SUCCESS,
    data: organisations,
  }
}

export const addHouseholdToOrganisationError = (error: any) => {
  return {
    type: ADD_HOUSEHOLD_TO_ORGANISATION_ERROR,
    error: error,
  }
}

export const addHouseholdToOrganisationClear = () => {
  return {
    type: ADD_HOUSEHOLD_TO_ORGANISATION_CLEAR,
  }
}

export const addUserToOrganisationBegin = (ouId: string, user: User) => {
  return {
    type: ADD_USER_TO_ORGANISATION_BEGIN,
    data: { ouId, user },
  }
}

export const addUserToOrganisationSuccess = (organisations: Organisation[]) => {
  return {
    type: ADD_USER_TO_ORGANISATION_SUCCESS,
    data: organisations,
  }
}

export const addUserToOrganisationError = (error: any) => {
  return {
    type: ADD_USER_TO_ORGANISATION_ERROR,
    error: error,
  }
}

export const addUserToOrganisationClear = () => {
  return {
    type: ADD_USER_TO_ORGANISATION_CLEAR,
  }
}

export const searchOrganisationsBegin = (query: string) => {
  return {
    type: SEARCH_ORGANISATIONS_BEGIN,
    data: { query },
  }
}

export const searchOrganisationsClear = () => {
  return {
    type: SEARCH_ORGANISATIONS_CLEAR,
  }
}

export const removeUserFromOrganisationBegin = (ouId: string, userId: string | undefined) => {
  return {
    type: REMOVE_USER_FROM_ORGANISATION_BEGIN,
    data: { ouId, userId },
  }
}

export const removeUserFromOrganisationSuccess = (organisations: Organisation[]) => {
  return {
    type: REMOVE_USER_FROM_ORGANISATION_SUCCESS,
    data: organisations,
  }
}

export const removeUserFromOrganisationError = (error: any) => {
  return {
    type: REMOVE_USER_FROM_ORGANISATION_ERROR,
    error: error,
  }
}

export const removeUserFromOrganisationClear = () => {
  return {
    type: REMOVE_USER_FROM_ORGANISATION_CLEAR,
  }
}

export const storeHouseholdSearchResultAction = (action: any) => {
  return {
    type: STORE_HOUSEHOLD_SEARCH_RESULTS,
    data: action.data,
  }
}

export const getUserOrgDetailsBegin = (userId: string) => {
  return {
    type: GET_USER_ORG_DETAILS_BEGIN,
    data: { userId },
  }
}

export const getUserOrgDetailsSuccess = (userDetails: any) => {
  return {
    type: GET_USER_ORG_DETAILS_SUCCESS,
    data: userDetails,
  }
}

export const getUserOrgDetailsError = (error: any) => {
  return {
    type: GET_USER_ORG_DETAILS_ERROR,
    error: error,
  }
}

export const getUserOrgDetailsClear = () => {
  return {
    type: GET_USER_ORG_DETAILS_CLEAR,
  }
}

export const addRoleBegin = (ouId: string, userId: string, roles: string[]) => {
  return {
    type: ADD_ROLE_BEGIN,
    data: { ouId, userId, roles },
  }
}

export const addRoleSuccess = (organisation: Organisation) => {
  return {
    type: ADD_ROLE_SUCCESS,
    data: organisation,
  }
}

export const addRoleError = (error: any) => {
  return {
    type: ADD_ROLE_ERROR,
    error: error,
  }
}

export const addRoleClear = () => {
  return {
    type: ADD_ROLE_CLEAR,
  }
}

export const addOrganisationBegin = (ouId: string, friendlyName: string) => {
  return {
    type: ADD_ORGANISATION_BEGIN,
    data: { ouId, friendlyName },
  }
}

export const addOrganisationSuccess = (organisation: Organisation[]) => {
  return {
    type: ADD_ORGANISATION_SUCCESS,
    data: organisation,
  }
}

export const addOrganisationError = (error: any) => {
  return {
    type: ADD_ORGANISATION_ERROR,
    error: error,
  }
}

export const addOrganisationClear = () => {
  return {
    type: ADD_ORGANISATION_CLEAR,
  }
}

export const deleteOrganisationBegin = (ouId: string) => {
  return {
    type: DELETE_ORGANISATION_BEGIN,
    data: { ouId },
  }
}

export const deleteOrganisationSuccess = (organisation: Organisation) => {
  return {
    type: DELETE_ORGANISATION_SUCCESS,
    data: organisation,
  }
}

export const deleteOrganisationError = (error: any) => {
  return {
    type: DELETE_ORGANISATION_ERROR,
    error: error,
  }
}

export const deleteOrganisationClear = () => {
  return {
    type: DELETE_ORGANISATION_CLEAR,
  }
}

export const removeRoleBegin = (ouId: string, userId: string, roles: string[]) =>
{
  return {
    type: REMOVE_ROLE_BEGIN,
    data: { ouId, userId, roles },
  }
}

export const removeRoleSuccess = (organisation: Organisation) =>
{
  return {
    type: REMOVE_ROLE_SUCCESS,
    data: organisation,
  }
}

export const removeRoleError = (error: any) =>
{
  return {
    type: REMOVE_ROLE_ERROR,
    error: error,
  }
}

export const removeRoleClear = () =>
{
  return {
    type: REMOVE_ROLE_CLEAR,
  }
}

export const getUserDetailsBegin = (userId: string) =>
{
  return {
    type: GET_USER_DETAILS_BEGIN,
    data: { userId },
  }
}

export const getUserDetailsSuccess = (user: UserDetails) =>
{
  return {
    type: GET_USER_DETAILS_SUCCESS,
    data: { user },
  }
}

export const getUserDetailsError = (error: any) =>
{
  return {
    type: GET_USER_DETAILS_ERROR,
    error: error,
  }
}

export const getUserDetailsClear = () =>
{
  return {
    type: GET_USER_DETAILS_CLEAR,
  }
}

export const getUserDetailsForCacheBegin = (userId: string) =>
{
  return {
    type: GET_USER_DETAILS_FOR_CACHE_BEGIN,
    data: { userId },
  }
}

export const getUserDetailsForCacheSuccess = (user: UserDetails) =>
{
  return {
    type: GET_USER_DETAILS_FOR_CACHE_SUCCESS,
    data: { user },
  }
}

export const getUserDetailsForCacheError = (error: any) =>
{
  return {
    type: GET_USER_DETAILS_FOR_CACHE_ERROR,
    error: error,
  }
}