import { createSelector } from 'reselect'
import _ from 'lodash'
import moment from 'moment'

import {
  okay,
} from '../../shared/util/colors'

const iconForEvent = (activity) => {
  switch (activity) {
    // TODO Update when Dave implements next API
    // case 'Sleeping':
    //   return 'hotel'
    // case 'Bathroom':
    //   return 'wc'
    // case 'Up and About':
    //   return 'directions_walk'
    // case 'Away from home':
    //   return 'directions_bike'
    // case 'Kitchen Activity':
    //   return 'local_dining'
    // case 'Resting':
    //   return 'airline_seat_recline_extra'
    default:
      return 'power'
  }
}


const getDatafeed = (state) => state.datafeed.data

export const convertDatafeed = createSelector(
  [getDatafeed],
  (datafeed) => {
    var result =
      _(datafeed)
        .map((record) => {
          let label = record.entity_id || ""

          return {
            id: `${record.entity_id}-${record.last_updated}`,
            key: `${record.entity_id}-${record.last_updated}`,
            label: label,
            value: record.state,
            displayIcon: {
              name: iconForEvent(record.entity_id),
              color: okay,
            },
            completeIcon: {
              name: "done",
              color: okay,
            },
            prettyTime: moment.utc(record.last_updated).fromNow(),
            // endTime: moment.utc(record.last_updated).fromNow(), // TODO should be last record
            lastUpdated: moment.utc(record.last_updated),
          }
        })
        .sortBy(["lastUpdated"])
        .reverse()
        .value()

    return result
  }
)
