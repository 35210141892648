import React from 'react'
import Typography from '@material-ui/core/Typography';
import { AlertSlider } from './AlertSlider';
import { useRealtimeAlerting } from '../hooks/useRealtimeAlerting';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
interface RealtimeAlertingProps
{
  token: string;
  householdId: string;
}

export const RealtimeAlerting = ({ token, householdId }: RealtimeAlertingProps) =>
{
  const [ isLoading, save, setHeartRate, setRespiration, setActivity, heartRate, respiration, activity] = useRealtimeAlerting(token, householdId);

  return(
    <div style={styles.container}>
      <Typography variant="h5">Realtime alerting thresholds</Typography>
      <Typography variant="body1" color="textSecondary">
        Vital monitoring is for households that have real time vital monitoring enabled.
        Configuring the upper and lower thresholds will change how the real time dashboard displays alerts.</Typography>

      <AlertSlider
        label="bpm"
        type="heartrate"
        max={200}
        title="Heart Rate"
        onValueChange={(newValue: number | number[]) => setHeartRate(newValue as number[])}
        icon="favorite"
        values={heartRate} />

      <AlertSlider
        label="rpm"
        type="respiration"
        max={100}
        title="Respiration"
        onValueChange={(newValue: number | number[]) => setRespiration(newValue as number[])}
        icon="favorite_border"
        values={respiration} />

      <AlertSlider
        label="/4"
        type="activity"
        max={4}
        title="Activity"
        onValueChange={(newValue: number | number[]) => setActivity(newValue as number[])}
        icon="directions_walk"
        values={activity} />

      <div style={styles.buttonRow}>
        <Button
          onClick={save}
          startIcon={isLoading && <CircularProgress size={16} />}
        >Save</Button>
        <Button onClick={save}>Close</Button>
      </div>


    </div>
  )
}

const styles = {
  buttonRow: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: "",
  },
  container: {
    margin: 20,
    textAlign: 'left' as 'left',
  },
  headingRowContainer: {
    flexDirection: "row" as 'row',
    display: 'flex',
    alignItems: 'center',
    color: 'grey',
    marginBottom: 28,
  },
  cardContainer: {
    marginTop: 12,
    marginBottom: 12,
    padding: 16,
  },
  icon: {
    paddingRight: 6,
  },
}