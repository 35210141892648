import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'


// Icons
import HomeIcon from "@mui/icons-material/Home"
import LinkIcon from '@mui/icons-material/Link'
import LinkOff from '@mui/icons-material/LinkOff'
import ClockIcon from '@mui/icons-material/AccessTime'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import moment from 'moment'

// Modal
import SubscriptionStartDateModal from '../../Modals/SubscriptionStartDateModal'
import SubscriptionEndDateModal from '../../Modals/SubscriptionEndDateModal'
import LinkInvoiceModal from '../../Modals/LinkInvoiceModal'

import UnlinkHouseholdModal from '../../Modals/UnlinkHouseholdModal'

const HouseholdTableItem = ({ household, resellerId }) => {

  HouseholdTableItem.propTypes = {
    household: PropTypes.object,
    onTableItemClick: PropTypes.func,
    resellerId: PropTypes.string,
    isLinking: PropTypes.bool,
    linkButtonText: PropTypes.string,
    linkHouseholdResult: PropTypes.object,
    linkHouseholdError: PropTypes.string || PropTypes.object,
    clearLinkResult: PropTypes.func,
  }

  const [showBillingDateModal, setShowBillingDateModal] = useState(false)
  const [showBillingEndDateModal, setShowBillingEndDateModal] = useState(false)
  const [showUnlinkModal, setShowUnlinkModal] = useState(false)
  const [showLinkInvoiceModal, setShowLinkInvoiceModal] = useState(false)

  const getSubscriptionEndDateColor = (date) => {

    // Colors
    const Colors = {
      PAST: "#f44336",
      WITHIN_2_MONTHS: "#F9A825",
      MORE_THAN_2_MONTHS: "#4CAF50",
    }

    const subscriptionEndDate = moment.unix(date / 1000)

    // If the date is in the past then set it to red
    if (subscriptionEndDate.isBefore()) return { color: "white", backgroundColor: Colors.PAST }
    // If it's within 2 months
    if (moment().diff(subscriptionEndDate, "days") > -60) return { color: "white", backgroundColor: Colors.WITHIN_2_MONTHS }
    // If it's more than 2 months away
    if (moment().diff(subscriptionEndDate, "days") < -60) return { color: "white", backgroundColor: Colors.MORE_THAN_2_MONTHS }

  }

  // The billing start date may come in as a unix time stamp or Date object
  const parseBillingStartDate = (billingStartDate) => {

    if (billingStartDate) {
      const formattedDate = moment.unix(household.billing.billingStartDate / 1000).format("DD/MM/YYYY")
      if (formattedDate !== "Invalid date") return formattedDate

      return moment(household.billing.billingStartDate).format("DD/MM/YYYY")
    }
  }

  return (
    <TableRow hover key={household.name}>
      <TableCell component="th" scope="row">
        <Grid container >
          <HomeIcon />
          <div style={{ marginTop: 2, paddingLeft: 6 }}>{household.friendlyName}</div>
        </Grid>
      </TableCell>

      <TableCell align="right">{household.householdStatus === "monitored" ? "Monitored" : household.householdStatus === "unmonitored" ? "Unmonitored" : "Resident Away"}</TableCell>
      <TableCell align="right">{household.gatewayId ? household.gatewayId : "No Gateway"}</TableCell>

      {/* Expecting the billing start date to be unix time */}
      <TableCell align="right">

        <Button
          size="small"
          onClick={() => setShowBillingDateModal(true)}
          startIcon={<ClockIcon />}
          variant="outlined">{household.billing.billingStartDate ? parseBillingStartDate(household.billing.billingStartDate) : "Set"}</Button>

      </TableCell>

      {/* Expecting the billing end date to be unix time */}
      <TableCell align="right">

        <Button
          style={getSubscriptionEndDateColor(household.billing.billingEndDate)}
          size="small"
          onClick={() => setShowBillingEndDateModal(true)}
          startIcon={<ClockIcon />}
          variant="outlined">{household.billing.billingEndDate ? moment.unix(household.billing.billingEndDate / 1000).format("DD/MM/YYYY") : "Set"}</Button>

      </TableCell>

      <TableCell align="right">
        <Tooltip title="Link an invoice to this household">
          <IconButton
            onClick={() => setShowLinkInvoiceModal(true)}
            size="small"
            variant="outlined">
            <MonetizationOnIcon />
          </IconButton>
        </Tooltip>
        {household.billing.invoice ?
          <Tooltip title="Open the invoice in a new tab">
            <IconButton target={"_blank"} href={household.billing.invoice} ><OpenInNewIcon /></IconButton>
          </Tooltip> :
          <Tooltip title="This household has no invoice linked to it">
            <IconButton target={"_blank"} ><LinkOff /></IconButton>
          </Tooltip>}
      </TableCell>


      <TableCell align="right">

        {household.resellerId && household.resellerId !== "None" ? <Button
          onClick={() => setShowUnlinkModal(true)}
          color="secondary"
          variant="outlined"
          size="small"
          startIcon={<LinkIcon />}>Unlink</Button> :
          <Button
            color="secondary"
            variant="outlined"
            disabled={true}
          >
            Not Linked
          </Button>}

      </TableCell>


      <SubscriptionStartDateModal
        householdId={household.id}
        householdName={household.friendlyName}
        open={showBillingDateModal}
        onClose={() => setShowBillingDateModal(false)} />

      <SubscriptionEndDateModal
        householdId={household.id}
        householdName={household.friendlyName}
        open={showBillingEndDateModal}
        onClose={() => setShowBillingEndDateModal(false)} />

      <UnlinkHouseholdModal
        householdId={household.id}
        householdName={household.friendlyName}
        open={showUnlinkModal}
        onClose={() => setShowUnlinkModal(false)}
      />

      <LinkInvoiceModal
        invoice={household.billing.invoice}
        open={showLinkInvoiceModal}
        householdId={household.id}
        householdName={household.friendlyName}
        onClose={() => setShowLinkInvoiceModal(false)}
      />

    </TableRow >
  )
}



export default HouseholdTableItem