import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Typography,
} from '@material-ui/core'

import _ from 'lodash'
import moment from "moment"
import access from 'safe-access'
import { withTheme } from '@material-ui/core/styles'

import './ActivityGraphStacked.scss'

import sharedStore from '../../redux'
import * as d3 from 'd3'

const GraphService = sharedStore.activityService
const nightime = sharedStore.colors.nightTime

const DEFAULT_HEIGHT = 300

class ActivityGraphView extends Component {
  constructor(props) {
    super(props)
    this.activityGraphRef = React.createRef()
    this.state = {
      colors: d3.scaleOrdinal(props.theme.graphColors.setB),
      width: window.innerWidth - 200,
      height: DEFAULT_HEIGHT,
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this._resizeDebounce = _.debounce(this.updateWindowDimensions, 150)
  }

  componentDidMount() {
    const { width } = this.state
    const { data, averageData } = this.props
    window.addEventListener('resize', this._resizeDebounce)
    this.updateGraph(width, data, averageData)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resizeDebounce)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth - 200 })
  }

  componentDidUpdate(lastProps, lastState) {
    const { width } = this.state
    const { data, averageData } = this.props
    if (data !== lastProps.data || averageData !== lastProps.averageData || width !== lastState.width) {
      this.updateGraph(width, data, averageData)
    }
  }

  updateActivityGraph = (timePeriod) => {
    const {
      fetchActivityGraphData,
      householdId,
    } = this.props

    fetchActivityGraphData(householdId, timePeriod)
  }

  drawNightBars(svg, nightbars) {
    nightbars.forEach((n) => {
      svg.append("rect")
        .attr("x", n.x)
        .attr("y", 0)
        .attr("width", n.width)
        .attr("height", DEFAULT_HEIGHT - 20)
        .attr("fill", nightime)
    })
  }

  updateGraph = (width, data, averageData) => {
    const { household } = this.props
    const { timezone } = household
    let size = {
      width: width,
      height: DEFAULT_HEIGHT,
    }

    d3.select(this.activityGraphRef.current)
      .select("svg")
      .remove()

    //d3 get div and add svg element of given size
    var svg = d3.select(this.activityGraphRef.current)
      .append("svg")
      .attr("viewBox", "0 0 " + width + " " + DEFAULT_HEIGHT)
      .attr("width", "100%")
      .attr("height", DEFAULT_HEIGHT)


    if (data == null || data.info === undefined || data.length === 0) {
      svg.append("text")
        .text("No activity recorded for this period")
        .attr("x", (width) / 2)
        .attr("y", DEFAULT_HEIGHT / 2 + 5)
        .attr("text-anchor", "middle")
        .attr("font-size", 24)
      return
    }

    var { buckets, starttime, endtime } = data.info

    const dataCopy = JSON.parse(JSON.stringify(data.data))

    const sensors = {}
    dataCopy.forEach((cv) => {
      for (let key in cv) {
        if (key !== "timestamp") {
          if (sensors[key] === undefined)
            sensors[key] = ''
          if (cv.count === undefined) {
            cv.count = cv[key]
          } else {
            cv.count += cv[key]
          }
        }
      }
    })

    let maxValue = 0
    dataCopy.forEach(item => {
      maxValue = item.count > maxValue ? item.count : maxValue
    })
    if (averageData.data && averageData.data.length) {
      averageData.data.forEach(d => maxValue = (d.value) > maxValue ? (d.value) : maxValue)
    }

    var startHeight = 20

    var startTime
    var endTime
    if (timezone) {
      startTime = moment(starttime).tz(timezone)
      endTime = moment(endtime).tz(timezone)
    } else {
      startTime = moment(starttime)
      endTime = moment(endtime)
    }

    //create a scale of type time (maps from time value to drawing)
    var timescale = d3.scaleTime()
      .domain([startTime, endTime])
      .range([20, width - 20])

    var barWidth = (width - 20) / buckets

    var nightbars = GraphService.getNightbars(timescale, size, d3, household)

    this.drawNightBars(svg, nightbars)

    //setup yscale for each count
    var yscale = d3.scaleLinear()
      .domain([0, maxValue])
      .range([0, DEFAULT_HEIGHT - startHeight - 10])

    var friendlyNameFilterRegex = /[\s,'"]/g

    _.each(sensors, (s, key) => {
      svg.append("g")
        .attr('id', "series_" + key.replace(friendlyNameFilterRegex, "_"))
        .attr("fill", d3.color(this.state.colors(key)).brighter(0.3))
        .attr("stroke", this.state.colors(key))
        .attr("stroke-width", 2)
    })

    dataCopy.forEach((datapoint) => {
      let ystart = startHeight
      _.each(datapoint, (value, key) => {
        if (key === "count" || key === "timestamp") return true
        svg.select("#series_" + key.replace(friendlyNameFilterRegex, "_"))
          .append("rect")
          .attr("x", () => {
            var xpos
            if (!timezone) {
              xpos = timescale(moment(datapoint.timestamp))
            } else {
              xpos = timescale(moment(datapoint.timestamp).tz(timezone))
            }
            return xpos + 3
          })
          .attr("y", () => {
            return DEFAULT_HEIGHT - ystart - yscale(value)
          })
          .attr("width", () => {
            return barWidth
          })
          .attr("height", () => {
            const height = yscale(value)
            return height === 0 ? height : height - 1
          })
          .attr("rx", 3)
          .attr("ry", 3)
          .append("title")
          .text(() => {
            if (!timezone) {
              return `${moment(datapoint.timestamp).format('ddd h:mma')} ${value}`
            } else {
              let timezoneAbbr = moment().tz(timezone).zoneAbbr()
              return `${moment(datapoint.timestamp).tz(timezone).format('ddd h:mma')} ${timezoneAbbr} ${value}`
            }
          })
        ystart += yscale(value)
      })
    })

    var valueline = d3.line()
      .curve(d3.curveMonotoneX)
      .x((d) => {
        return timescale(timezone ? moment(d.timestamp).tz(timezone) : moment(d.timestamp))
      })
      .y((d) => {
        return DEFAULT_HEIGHT - startHeight - yscale(d.value)
      })

    if (averageData.data) {
      //draw the path
      svg.append("path")
        .datum(averageData.data)
        .attr("d", valueline)
        .attr("fill", "none")
        .attr("stroke", this.state.colors(0))
        .attr("stroke-width", 2)
    }

    //setup the x axis
    var xaxis = d3.axisBottom()
      .scale(timescale).tickFormat(d => {
        if (timezone) {
          return moment(d).tz(timezone).format('ddd h A')
        } else {
          return moment(d).format('ddd h A')
        }
      })

    //draw the x axis
    svg.append("g")
      .attr("transform", "translate(0, " + (DEFAULT_HEIGHT - 20) + ")")
      .call(xaxis)
  }

  render() {
    const sensors = access(this.props, 'data.info.sensors')
    return (
      <div>
        <Button onClick={() => this.updateActivityGraph('24')}>24 h</Button>
        {/* <Button onClick={() => this.updateActivityGraph('169')}>1 w</Button> */}
        <div className='ActivityGraph' ref={this.activityGraphRef}>
        </div>
        {
          sensors && sensors.map(s => <Indicator key={s} label={s} color={this.state.colors(s)} />)
        }
        <Indicator key='average' label='1 Week Average' color={this.state.colors(0)} />
      </div>
    )
  }
}

ActivityGraphView.propTypes = {
  fetchActivityGraphData: PropTypes.func.isRequired,
  data: PropTypes.any,
  averageData: PropTypes.any,
  gatewayId: PropTypes.string,
  householdId: PropTypes.string,
  household: PropTypes.object,
  theme: PropTypes.object,
}

export default withTheme(ActivityGraphView)

const Indicator = (props) => (
  <span className={'graphLegendItem'}>
    <Typography>
      <span className='graphLegendIndicator' style={{ backgroundColor: props.color }}></span>
      &nbsp;{props.label}
    </Typography>
  </span>
)
/* eslint-enable */
