import moment from 'moment'
export const getPillColor = (timestampSeconds: string) =>
{
  const checkinTimestamp = moment(Number(timestampSeconds) * 1000);
  const now = moment();
  const daysSinceCheckin = now.diff(checkinTimestamp, "days")

  if(daysSinceCheckin <= 10) return 'green'
  if(daysSinceCheckin <= 12) return 'orange'
  if(daysSinceCheckin <= 15) return 'red'

  return 'grey'
}