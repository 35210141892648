import { all, fork } from 'redux-saga/effects'
import locationSagaWeb from './domains/location/locationSagaWeb'
import refreshTokenSaga from './refreshTokenSaga'
import sharedStore from '../redux'
import exportDataSaga from '../redux/domains/export/exportSaga'
import { organisationSagas } from '../redux/domains/organisations/organisationSagas'
import { zoneSagas } from '../redux/domains/zones/zoneSagas'
import { settingsSaga } from '../redux/domains/settings/settingsSaga'


export default function* root() {
  yield all([
    fork(sharedStore.apiSaga),
    fork(sharedStore.householdsDataSaga),
    fork(sharedStore.householdInformationSaga),
    fork(locationSagaWeb),
    fork(sharedStore.loginSagaWeb),
    fork(sharedStore.logoutSaga),
    fork(sharedStore.userSaga),
    fork(refreshTokenSaga),
    fork(sharedStore.reportSaga),
    fork(sharedStore.userWebsocketSaga),
    fork(sharedStore.householdV2Saga),
    fork(organisationSagas),
    fork(exportDataSaga),
    fork(zoneSagas),
    fork(settingsSaga),
  ])
}
