import { connect } from 'react-redux'
import BatteryReportGraphTab from './BatteryReportGraphTab'
import sharedStore from '../../../../../redux'

import { GATEWAY_BATTERY_REPORT_ALL, GET_HOUSEHOLD_FROM_GATEWAY_ID } from "../../../../../redux/shared/services/APIEndpoints"

const callAPI = sharedStore.userStore.callAPI


const mapDispatchToProps = (dispatch) => {
  return {
    getAllBatteryLevels: (params) => dispatch(callAPI(GATEWAY_BATTERY_REPORT_ALL(params))),
    getHouseholdNameFromGatewayId: (params) => dispatch(callAPI(GET_HOUSEHOLD_FROM_GATEWAY_ID(params))),
  }
}

const mapStateToProps = (state) => ({
  isFetchingBatteryReport: state.graphs.isFetchingBatteryReport,
  allGatewaysBatteryReport: state.graphs.allGatewayBatteryReport,
})

export default connect(mapStateToProps, mapDispatchToProps)(BatteryReportGraphTab)
