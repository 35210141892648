import axios, { AxiosResponse, Method } from "axios";

import { PublicClientApplication } from "@azure/msal-browser";
import { config, authRequest } from '../msal/config'
import { b2cPolicies } from '../msal/config'

export class Builder {
  baseUrl: string | undefined;
  hasTokenExpired: boolean;
  newToken: string;

  constructor(useV3: boolean = false) {
    this.baseUrl = useV3 ? process.env.REACT_APP_API_URL_V3 : process.env.REACT_APP_API_URL;

    this.hasTokenExpired = false;
    this.newToken = "";
  }
  // Main API call
  call = async (method: Method, endpoint: string, body: any, token: string): Promise<AxiosResponse | undefined> => {

    const options = {
      method: method,
      headers: {
        authorization: `Bearer ${this.hasTokenExpired && this.newToken !== "" ? this.newToken : token}`,
        "Access-Control-Allow-Origin": "*",
      },
      url: `${this.baseUrl + endpoint}`,
      data: body,
    };

    try {
      const response = await axios(options);
      return response;
    }
    catch (error) {
      console.error(error)
      //@ts-ignore
      if (error && error.response && error.response.status && error.response.status === 401) {
        console.log("Got a 401, refreshing token")
        // Store the new token
        this.newToken = await this.refreshToken()
        this.hasTokenExpired = true;
        return await this.call(method, endpoint, body, this.newToken)
      }

      throw (error)
    }
  };

  // Get a new token if we 401
  refreshToken = async (): Promise<string> => {
    try {
      // This should be the same instance you pass to MsalProvider
      const msalInstance = new PublicClientApplication(config);
      const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
      const accounts = msalInstance.getAllAccounts();
      if (!activeAccount && accounts.length === 0) {
        /*
          * User is not signed in. Throw error or wait for user to login.
          * Do not attempt to log a user in outside of the context of MsalProvider
          */
      }

      const response = await msalInstance.acquireTokenSilent({
        scopes: authRequest.b2cScopes,
        account: accounts[0],
      });

      console.log("Success got a fresh token")
      return response.idToken;

    }
    catch (error) {
      if (error instanceof Error) {
        console.error(`Error refreshing token: ${error.message}\n${error.stack}`);
      }
      if (error instanceof String) {
        console.error(error);
      }
      const msalInstance = new PublicClientApplication(config);
      const activeAccount = msalInstance.getActiveAccount();
      await msalInstance.acquireTokenRedirect({
        scopes: authRequest.b2cScopes,
      });
    }
    return "";
  }
}
