import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withFormik } from 'formik'
import { compose } from 'redux'

import { withTheme, withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'

import IconButton from '@material-ui/core/IconButton'
import combineStyles from '../../components/Utils/CombineStyles'
import TextField from '@material-ui/core/TextField'
import Slide from '@material-ui/core/Slide';

import PortalOverlay from '../../components/PortalOverlay'
import { portalStyles } from '../../styles/defaultStyles'

import formStyles from './UserProfilePortalStyles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const snackbarPosition = {
  horizontal: 'center',
  vertical: 'bottom',
}

const styles = combineStyles(portalStyles, formStyles)

const Transition = React.forwardRef(function Transition(
  props,
  ref,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class UserProfilePortalView extends Component {
  static pageName = 'userProfile'
  constructor(props) {
    super(props)
    this.renderNewAvatar = this.renderNewAvatar.bind(this)
    this.imageInputRef = React.createRef()
  }

  static propTypes = {
    avatar: PropTypes.any,
    close: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    values: PropTypes.object,
    initialValues: PropTypes.object,
    dirty: PropTypes.bool,
    setFieldValue: PropTypes.func.isRequired,
    wasSaveSuccess: PropTypes.bool,
    wasSaveError: PropTypes.bool,
    isSaving: PropTypes.bool,
    clearNotification: PropTypes.func,
    classes: PropTypes.any,
    theme: PropTypes.object,
  }

  renderNewAvatar(event) {
    const { setFieldValue } = this.props
    let reader = new FileReader()
    reader.onloadend = () => {
      setFieldValue("photo", reader.result)
    }

    reader.readAsDataURL(event.currentTarget.files[0])
  }

  getUserInitials = () =>
  {
    const { values } = this.props

    try
    {
      const { givenName, surname } = values;
      return givenName[0] + surname[0];
    }
    catch(error)
    {
      return "IC";
    }
  }

  render() {
    const {
      close,
      handleSubmit,
      handleReset,
      isSubmitting,
      handleChange,
      values,
      dirty,
      isSaving,
      clearNotification,
      wasSaveSuccess,
    } = this.props
    return (
      <Dialog
        onClose={close}
        TransitionComponent={Transition}
        open={true} >
        <DialogTitle>User Settings</DialogTitle>

        <DialogContent>
          <div style={{ flexDirection: 'row', display: "flex" }}>

            <div style={{ alignItems: 'center', display: 'flex' }}>
              <IconButton component='label' label='Select Photo'>
                {values.photo && <Avatar style={{ width: 150, height: 150, alignSelf: "center" }} src={values.photo} alt="avatar" />}
                {!values.photo && <Avatar style={{ width: 150, height: 150, alignSelf: "center", backgroundColor: "#63318B", fontSize: 60 }}>{this.getUserInitials()}</Avatar>}
                <input id="photo" ref={this.imageInputRef} name="photo" type="file" onChange={this.renderNewAvatar} style={{ display: 'none' }} />
              </IconButton>
            </div>

            <div style={{ flexDirection: 'column' }}>
              <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
                <TextField
                  style={{ margin: 12 }}
                  variant="outlined"
                  type='text'
                  id='givenName'
                  label="First Name"
                  onChange={handleChange}
                  value={values.givenName}
                />
                <TextField
                  style={{ margin: 12 }}
                  type='text'
                  variant='outlined'
                  id='surname'
                  label="Second Name"
                  onChange={handleChange}
                  value={values.surname}
                />

              </div>

              <div style={{ display: 'flex' }}>
                <TextField
                  fullWidth
                  style={{ margin: 12  }}
                  type='text'
                  variant='outlined'
                  id='mobilePhone'
                  label="Mobile Phone"
                  onChange={handleChange}
                  value={values.mobilePhone}
                />
              </div>


            </div>

          </div>
          <Snackbar
            anchorOrigin={snackbarPosition}
            open={isSaving}
            autoHideDuration={6000}
            message={<span>Saving changes to user settings...</span>}
          />
          <Snackbar
            anchorOrigin={snackbarPosition}
            open={this.props.wasSaveError}
            autoHideDuration={6000}
            message={<span>Unable to save changes to user settings!</span>}
            onClose={clearNotification}
          />
          <Snackbar
            anchorOrigin={snackbarPosition}
            open={wasSaveSuccess}
            autoHideDuration={6000}
            message={<span>Successfully updated user settings.</span>}
            onEnter={handleReset}
            onClose={clearNotification}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleReset} disabled={isSubmitting || !dirty}>Discard</Button>
          {dirty &&
                <Button color="primary" onClick={handleSubmit} disabled={isSaving || isSubmitting || !dirty}>Save</Button>
          }
          {!dirty &&
                <Button color="primary" onClick={close}>Close</Button>
          }
        </DialogActions>
      </Dialog>
    )
  }
}

export class ErrorPage extends React.Component {
  static pageName = 'userProfileError'
  static propTypes = {
    close: PropTypes.func,
  }

  render() {
    return (
      <PortalOverlay style={{ minWidth: 0 }}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          autoHideDuration={6000}
          onClose={this.props.close}
          message={<span>Error loading user profile</span>}
        />
      </PortalOverlay>
    )
  }
}

export default compose(
  withTheme,
  withStyles(styles),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props) => {
      return ({
        givenName: props.firstName,
        surname: props.surname,
        photo: props.avatar,
        mobilePhone: props.mobilePhone,
      })
    },
    handleSubmit: (values, actions) => {
      actions.props.updateUserInfo({
        givenName: (values.givenName !== actions.props.firstName ? values.givenName : undefined),
        surname: (values.surname !== actions.props.surname ? values.surname : undefined),
        photo: (values.photo !== null ? values.photo : undefined),
        mobilePhone: (values.mobilePhone !== actions.props.mobilePhone ? values.mobilePhone : undefined),
      })
      actions.setSubmitting(false)
    },
  }))(UserProfilePortalView)
