import React from 'react'

// Material UI components
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide'
import { TransitionProps } from '@material-ui/core/transitions';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const HealthMetricsEnabledModal = (props: ModalInterface): JSX.Element =>
{
  return(<Dialog
    open={props.open}
    TransitionComponent={Transition}
    onClose={props.close}
  >
    <DialogTitle id="health-metrics-enabled-modal-title">Health Metrics Enabled!</DialogTitle>
    <DialogContent>
      <DialogContentText id="health-metrics-enabled-modal-description" style={{ textAlign: 'justify' }}>
        Welcome to Intelicare Health Metrics! Here you will be able to manually record and monitor your health as well as sync data with
        third-party applications and services. To get started, visit the Health Metrics Settings in the Household Settings menu.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.close} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>)
}

interface ModalInterface {
  open: boolean,
  close(): void,
}