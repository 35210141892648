import { connect } from 'react-redux'

import RealtimeStatus from '../components/RealtimeStatus'
import sharedStore from '../../../redux'

import { purgeHouseholds } from '../../../redux/domains/householdsV2/householdsStore'

const callAPI = sharedStore.userStore.callAPI

const {
  HOUSEHOLD_V2_LIST_PAGE,
} = sharedStore.apiEndpoints

const mapStateToProps = (state: any) =>
{
  return {
    households: state.householdsV2.households,
    token: state.user.token,
    isFetching: state.householdsV2.isFetching,
  }
}

const mapDispatchToProps = (dispatch: any) =>
{
  return {
    getHouseholdListNextPage: (pagePosition: number, searchQuery: string, order: string, householdStatus: string, systemHealthStatus: string, triagePriority: string) => {
      dispatch(callAPI(HOUSEHOLD_V2_LIST_PAGE({
        pageSize: 20,
        pagePosition,
        searchQuery: searchQuery || '*',
        order,
        householdStatus,
        systemHealthStatus,
        triagePriority,
      })))
    },
    purgeList: () => dispatch(purgeHouseholds()),

  }
}


export default connect(mapStateToProps, mapDispatchToProps)(RealtimeStatus)