import React, { Component } from 'react'
import PropTypes from 'prop-types'
import access from 'safe-access'

import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import InteliIcon from '../../../../../components/InteliIcon'

import SensorDetails from './SensorDetailsView'
import styles from './BehaviourTooltipStyle'

import { helper, colors } from '../../../../../redux'
const friendlyDateTime = helper.friendlyDateTime
const colorForStatusValue = colors.colorForStatusValue

class BehaviourDetailsView extends Component {

  render() {
    const {
      household,
      behaviours,
      icon,
      classes,
    } = this.props

    let renderedBehaviours = behaviours.map(function (behaviour, i) {
      const statusColor = colorForStatusValue(access(behaviour, 'status.value'))

      let statusValue = access(behaviour, 'status.value') || ''
      let statusMsg = access(behaviour, 'status.msg') || 'No data to display'

      return (
        <div key={i}>
          <CardHeader
            avatar={
              <InteliIcon
                name={icon.name}
                family={icon.family}
                color={statusColor}
                size={32}
              />
            }
            title={
              <Typography variant='subtitle1'>
                {behaviour.friendlyName}
              </Typography>
            }
            subheader={
              <Typography color="textSecondary" variant='caption'>
                {`Last updated: ${friendlyDateTime(behaviour.lastUpdated, household.timezone)}`}
              </Typography>
            } />
          <CardContent classes={{ root: classes.behaviourCard }}>
            <Typography gutterBottom className={classes.StatusLabels} classes={{ gutterBottom: classes.ExtraGutter }}>
              <span className={classes.StatusBadge} style={{ backgroundColor: statusColor }}>{statusValue}</span>
              {statusMsg}
            </Typography>
            {
              behaviour.notHomeHistory &&
              behaviour.notHomeHistory.length > 0 &&
              _renderNotHomeHistory(behaviour.notHomeHistory)
            }
            {behaviour.sensors && <SensorDetails household={household} behaviour={behaviour} sensors={behaviour.sensors} />}
          </CardContent>
        </div>
      )

    })

    return (
      <div>{renderedBehaviours}</div>
    )
  }

  static propTypes = {
    household: PropTypes.any.isRequired,
    behaviours: PropTypes.any.isRequired,
    icon: PropTypes.any.isRequired,
    classes: PropTypes.any.isRequired,
  }
}

const _renderNotHomeHistory = (notHomeHistory) => {
  const historyTexts = notHomeHistory.map((h, i) => (
    <span key={i}>
      <Typography variant='body2'>{h.text}</Typography>
    </span>
  ))
  return (
    <div>
      <legend>
        <Typography variant='subtitle1'>
          Outing History
        </Typography>
      </legend>
      {historyTexts}
    </div>
  )
}

export default withStyles(styles)(BehaviourDetailsView)
