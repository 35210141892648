import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Alert from '@material-ui/lab/Alert'

import { callAPI } from '../../../../redux/domains/user/userStore'
import { SET_HOUSEHOLD_BILLING_START_DATE } from '../../../../redux/shared/services/APIEndpoints'

const SubscriptionEndDateModal = (
  { open,
    onClose,
    householdName,
    householdId,
    setBillingDate,
    isSettingBillingDate,
    setBillingDateError,
    setBillingDateSuccess,
    clearSetBillingDate }) => {

  SubscriptionEndDateModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    onConfirmAction: PropTypes.func,
    householdName: PropTypes.string,
    householdId: PropTypes.string,
    setBillingDate: PropTypes.func,
    isSettingBillingDate: PropTypes.bool,
    setBillingDateError: PropTypes.object || PropTypes.string,
    setBillingDateSuccess: PropTypes.object || PropTypes.string,
    clearSetBillingDate: PropTypes.func,
  }

  const [billingEndDate, setBillingDateValue] = useState("")

  return (
    <Dialog
      onExit={() => clearSetBillingDate()}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Change subscription end date"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Set the subscription end date for {householdName}
        </DialogContentText>

        <Grid justifyContent="center" container>
          <TextField
            variant="outlined"
            onChange={(e) => setBillingDateValue(new Date(e.target.value).getTime())}
            id="date"
            type="date"
          > </TextField>
        </Grid>

        {setBillingDateSuccess && <Alert style={{ marginTop: 12 }} severity="success">Successfully changed the subscription end date for the household</Alert>}
        {setBillingDateError && <Alert style={{ marginTop: 12 }} severity="error">Looks like there was an issue setting the subscription end date</Alert>}

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {setBillingDateSuccess ? "Close" : "Cancel"}
        </Button>
        {!setBillingDateSuccess && <Button onClick={() => setBillingDate({ householdId: householdId, billingEndDate: billingEndDate })} color="primary" autoFocus>
          {isSettingBillingDate ? "Saving..." : "Save"}
        </Button>}
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = state => {
  return {
    isSettingBillingDate: state.householdsV2.isSettingBillingDate,
    setBillingDateError: state.householdsV2.setBillingDateError,
    setBillingDateSuccess: state.householdsV2.setBillingDateSuccess,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setBillingDate: (params) => dispatch(callAPI(SET_HOUSEHOLD_BILLING_START_DATE(params))),
    clearSetBillingDate: () => dispatch({ type: "CLEAR_SET_HOUSEHOLD_BILLING_START_DATE" }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionEndDateModal)