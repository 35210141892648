import React from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ISensor } from '@intelicare/shared/interfaces/household/ISensor'

interface TabHeaderProps
{
  onChangeTab: (sensor: ISensor, index: number) => void;
  tabItems: ISensor[];
  currentTab: number
}

export const TabHeader = ({ onChangeTab, tabItems, currentTab }: TabHeaderProps) =>
{
  return(
    <div style={styles.container}>
      <Tabs value={currentTab} aria-label="basic tabs example">
        {tabItems.map((item, key) => <Tab key={key} label={item.friendlyName} onClick={() => onChangeTab(item, key)} />)}
      </Tabs>
    </div>)
}

const styles = {
  container: {
    paddingBottom: 12,
  },
}