import { connect } from 'react-redux'
import NightGraphView from '../components/NightGraphView'
import sharedStore from '../../../redux'
const callAPI = sharedStore.userStore.callAPI
const SLEEP_GRAPH_DATA = sharedStore.apiEndpoints.SLEEP_GRAPH_DATA
const { getSleepTimes } = sharedStore.householdsSelector

const mapDispatchToProps = (dispatch) => {
  return {
    getData: (householdId) => {
      dispatch(callAPI(SLEEP_GRAPH_DATA({
        householdId,
      })))
    },
  }
}

const mapStateToProps = (state) => ({
  getSleepTimes: (householdData) => getSleepTimes(householdData),
})

export default connect(mapStateToProps, mapDispatchToProps)(NightGraphView)
