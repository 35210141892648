import access from 'safe-access'

const systemColumns = [
  { label: 'Icon', value: 'icon', type: "icon", sort: 1 },
  { label: 'Name', value: 'name', type: "string", sort: 2 },
  { label: 'Status', value: 'statusText', type: "string", sort: 3 },
  { label: 'Last seen', value: 'lastseenStr', type: "string", sort: 4 },
  { label: 'Actions', value: 'Gateway', type: 'actions', sort: 5 },
]

const sensorColumns = [
  { label: 'Icon', value: 'icon', type: "icon", sort: 1 },
  { label: 'Name', value: 'name', type: "string", sort: 2 },
  { label: 'Room', value: 'room', type: "string", sort: 3 },
  { label: 'Status', value: 'statusText', type: "string", sort: 4 },
  { label: 'Battery', value: 'batteryLevelString', type: "string", sort: 5 },
  { label: 'Last seen', value: 'lastSeenString', type: "string", sort: 6 },
]

// ------------------------------------
// Constants
// ------------------------------------

export const SYSTEMDATA_BEGIN = 'SYSTEMDATA_BEGIN'
export const SYSTEMDATA_SUCCESS = 'SYSTEMDATA_SUCCESS'
export const SYSTEMDATA_ERROR = 'SYSTEMDATA_ERROR'
export const UPDATE_SELECTED_SYSTEM_COLUMNS = 'UPDATE_SELECTED_SYSTEM_COLUMNS'

export const VIEW_GATEWAY = 'VIEW_GATEWAY'
export const UPDATE_GATEWAY = 'UPDATE_GATEWAY'
export const GATEWAYDATA_BEGIN = 'GATEWAYDATA_BEGIN'
export const GATEWAYDATA_SUCCESS = 'GATEWAYDATA_SUCCESS'
export const GATEWAYDATA_ERROR = 'GATEWAYDATA_ERROR'
export const UPDATE_SELECTED_GATEWAY_COLUMNS = 'UPDATE_SELECTED_GATEWAY_COLUMNS'

export const TOGGLE_PRO_USER_BEGIN = 'TOGGLE_PRO_USER_BEGIN'
export const TOGGLE_PRO_USER_SUCCESS = 'TOGGLE_PRO_USER_SUCCESS'
export const TOGGLE_PRO_USER_ERROR = 'TOGGLE_PRO_USER_ERROR'
export const CLEAR_TOGGLE_PRO_USER = 'CLEAR_TOGGLE_PRO_USER'

export const GET_HOUSEHOLD_HEALTH_ROLLUP_BEGIN = 'GET_HOUSEHOLD_HEALTH_ROLLUP_BEGIN'
export const GET_HOUSEHOLD_HEALTH_ROLLUP_SUCCESS = 'GET_HOUSEHOLD_HEALTH_ROLLUP_SUCCESS'
export const GET_HOUSEHOLD_HEALTH_ROLLUP_ERROR = 'GET_HOUSEHOLD_HEALTH_ROLLUP_ERROR'


const updateGatewayInArr = (gateways, gatewayToUpdate) => {
  return gateways.map(gateway => {
    if (gateway.gatewayid === gatewayToUpdate.gatewayid) {
      return { ...gateway, ...gatewayToUpdate }
    }
    return gateway
  })
}

// ------------------------------------
// Actions
// ------------------------------------

////////////////////////////////////////////////
// System Data
////////////////////////////////////////////////
export function updateSelectedSystemColumns(newColumns) {
  return {
    type: UPDATE_SELECTED_SYSTEM_COLUMNS,
    newColumns: newColumns,
  }
}

export function viewGateway(gateway) {
  return {
    type: VIEW_GATEWAY,
    activeGateway: gateway,
  }
}

export function updateGateways(gateway) {
  return {
    type: UPDATE_GATEWAY,
    gateway: gateway,
  }
}

////////////////////////////////////////////////
// Gateway Data
////////////////////////////////////////////////
export function updateSelectedGatewayColumns(newColumns) {
  return {
    type: UPDATE_SELECTED_GATEWAY_COLUMNS,
    newColumns: newColumns,
  }
}


// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  allSystemColumns: systemColumns,
  selectedSystemColumns: systemColumns,
  allGatewayColumns: sensorColumns,
  selectedGatewayColumns: sensorColumns,
  isFetchingSystem: false,
  gateways: [],
  isFetchingGateway: false,
  gateway: undefined,
  proUser: {
    result: "",
    error: "",
    isLoading: false,
  },
}

export default function systemReducer(state = initialState, action) {
  switch (action.type) {
    case VIEW_GATEWAY:
      return {
        ...state,
        activeGateway: action.activeGateway,
        gateway: undefined,
      }
    case UPDATE_GATEWAY:
      return {
        ...state,
        gateways: updateGatewayInArr(state.gateways, action.gateway),
      }
    case UPDATE_SELECTED_SYSTEM_COLUMNS:
      return {
        ...state,
        selectedSystemColumns: action.newColumns,
      }
    case SYSTEMDATA_BEGIN: {
      return {
        ...state,
        isFetchingSystem: true,
      }
    }
    case SYSTEMDATA_SUCCESS: {
      return Object.assign({}, state, {
        isFetchingSystem: false,
        gateways: access(action, 'data.gateways'),
      })
    }
    case SYSTEMDATA_ERROR: {
      return {
        ...state,
        isFetchingSystem: false,
      }
    }
    case UPDATE_SELECTED_GATEWAY_COLUMNS:
      return {
        ...state,
        selectedGatewayColumns: action.newColumns,
      }
    case GATEWAYDATA_BEGIN: {
      return {
        ...state,
        isFetchingGateway: true,
      }
    }
    case GATEWAYDATA_SUCCESS: {
      return Object.assign({}, state, {
        isFetchingGateway: false,
        gateway: action.data,
      })
    }
    case GATEWAYDATA_ERROR: {
      return {
        ...state,
        isFetchingGateway: false,
      }
    }
    case TOGGLE_PRO_USER_BEGIN: {
      return {
        ...state,
        proUser: {
          isLoading: true,
        },
      }
    }
    case TOGGLE_PRO_USER_SUCCESS: {
      return {
        ...state,
        proUser: {
          isLoading: false,
          result: action.data.result,
        },
      }
    }
    case TOGGLE_PRO_USER_ERROR: {
      return {
        ...state,
        proUser: {
          isLoading: false,
          error: action.error.message,
        },
      }
    }
    case CLEAR_TOGGLE_PRO_USER: {
      return {
        ...state,
        proUser: {
          result: "",
          error: "",
          isLoading: false,
        },
      }
    }

    case GET_HOUSEHOLD_HEALTH_ROLLUP_BEGIN:
      return {
        ...state,
        isGettingHouseholdsHealth: true,
      }

    case GET_HOUSEHOLD_HEALTH_ROLLUP_SUCCESS:
      return {
        ...state,
        householdsHealthRollup: action.data,
        isGettingHouseholdsHealth: false,
      }

    case GET_HOUSEHOLD_HEALTH_ROLLUP_ERROR:
      return {
        ...state,
        isGettingHouseholdsHealth: false,
        householdsHealthRollupError: action.error,
      }
    default:
      return state
  }
}
