import {
  put,
  takeEvery,
} from 'redux-saga/effects'
import {
  GET_REPORT_LOOP_BEGIN,
  GET_REPORT_LOOP_SUCCESS,
  GET_REPORT_LOOP_ERROR,
  GET_REPORT_ERROR,
} from './reportsStore'
import {
  REQUEST_REPORT,
} from '../../shared/services/APIEndpoints'
import {
  callAPISaga,
} from '../../shared/services/WebAPI'

const delay = (ms) => new Promise(res => setTimeout(res, ms))

let poll

function* pollForReport(action) {

  let reportId = ''
  let attempts = 0
  const MAX_ATTEMPTS = 30

  const result = yield callAPISaga(REQUEST_REPORT({ householdId: action.householdId }))

  if (result.status === 200)
  {
    if (result.data.uri === "Unknown")
    {
      yield put({ type: GET_REPORT_LOOP_ERROR, error: 'Error unable to retrieve report because the reportId could not be found. Please contact InteliCare support.' })
    }
    else
    {
      yield put({ type: GET_REPORT_LOOP_SUCCESS, reportId: reportId, uri: result.data.uri })
    }
    return
  }
  else if (result.status === 202)
  {

    reportId = result.data.reportId
    poll = true
    do
    {
      try
      {
        const result = yield callAPISaga(REQUEST_REPORT({ householdId: action.householdId, reportId: reportId }))
        if (result.status === 200)
        {
          if (result.data.uri === "Unknown")
          {
            yield put({ type: GET_REPORT_LOOP_ERROR, error: 'Error unable to retreive report' })
          }
          else
          {
            yield put({ type: GET_REPORT_LOOP_SUCCESS, reportId: reportId, uri: result.data.data })
          }

          poll = false
        }

        if (attempts === MAX_ATTEMPTS)
        {
          yield put({ type: GET_REPORT_LOOP_ERROR, error: 'Timeout' })
          poll = false
        }

        yield delay(5000)
        attempts += 1
      }
      catch (error)
      {
        // Keep looping if we don't have the data yet
        console.log(error)
        yield delay(5000)
      }

    } while (attempts <= MAX_ATTEMPTS && poll)
  }
  else
  {
    yield put({ type: GET_REPORT_LOOP_ERROR, error: 'There is not enough data to generate a report yet. Please try again later.' })
  }

}

function* reportSaga() {
  yield takeEvery(GET_REPORT_LOOP_BEGIN, pollForReport)
  yield takeEvery(GET_REPORT_ERROR, () => poll = false)
}

export default reportSaga
