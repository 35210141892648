import Typography from '@material-ui/core/Typography'
import Search from '@mui/icons-material/Search'
import React from 'react'

export const NothingFound = () =>
{
  return(
    <div style={styles.nothingFound}>
      <Search style={styles.icon}/>
      <Typography variant="h5" color="textSecondary">No Households Found</Typography></div>
  )
}

const styles = {
  nothingFound: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 400,
    display: 'flex',
  },
  icon: {
    height: 32,
    width: 32,
    color: 'grey',
  },
}