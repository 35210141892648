import Card from '@material-ui/core/Card'
import { Household } from '../../../../../redux/domains/householdsV2/householdStoreInterfaces'
import { AvatarSection } from '../MultiNightGuages/AvatarSection'
import MultiNightGraphItem from '../../../../../components/MultiNightGraphItem/MultiNightGraphItem'

interface RowWithAvatarProps
{
  data: any;
  household: Household | undefined;
  isFetching: boolean;
}

export const RowWithAvatar = ({ data, household, isFetching }: RowWithAvatarProps) =>
{
  return(
    <Card style={styles.container}>
      <div style={styles.avatar}><AvatarSection friendlyName={data.friendlyName} lastUpdated="" /></div>
      <MultiNightGraphItem household={household} height={100} data={data} isFetching={isFetching} />
    </Card>
  )
}

const styles = {
  container: {
    display: 'flex',
    margin: 12,
    padding: 6,
    alignItems: 'center',
  },
  avatar: {
    marginRight: 16,
    marginLeft: 16,
  },
}