import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Paper,
  MenuItem,
  Select,
  InputBase,
  IconButton,
} from '@material-ui/core'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

import sharedStore from '../../../../redux'
import { ConnectingAirportsOutlined } from '@mui/icons-material'
const { ACTION_SET_SEARCH_QUERY_PLACEHOLDER_TEXT } = sharedStore.householdsV2Store


const MIN_SEARCH_LEN = 3
const DEBOUNCE_TIME = 1000

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    padding: '4px',
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  selectContainer: {
    padding: '4px 8px 4px 8px',
    marginRight: 10,
  },
  select: {
    borderBottomWidth: 0,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 0,
  },
}

class SearchBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      debounce: false,
      data: this.props.lastSearchQuery ? this.props.lastSearchQuery : '',
      order: 'triage',
      householdStatus: "monitored",
      systemHealthStatus: "",
      triagePriority: "",
    }
  }

  _setDebounce() {
    this.setState(state => ({ ...state, debounce: true }))
    setTimeout(function () {
      this.setState(state => ({ ...state, debounce: false }))
      clearTimeout()
      this._search()
    }.bind(this), DEBOUNCE_TIME)
  }

  convertToSearchString(data) {
    let searchString = undefined
    if (data.length > 0) {
      searchString = `${data}*`
    }
    return searchString
  }

  _search = () => {
    const { search } = this.props
    // if (!this.state.data) return
    search(this.convertToSearchString(this.state.data), this.state.order, this.state.householdStatus, this.state.systemHealthStatus)
  }

  _searchOnType = (e) => {
    const { search } = this.props
    this.setState({ ...this.state, data: e.target.value })
    if (this.state.debounce) return
    this._setDebounce()
    if (e.target.value && e.target.value.length < MIN_SEARCH_LEN) return
    search(this.convertToSearchString(e.target.value), this.state.order, this.state.householdStatus, this.state.systemHealthStatus, this.state.triagePriority)
  }

  _clear = () => {
    const { search, setSearchQueryHelperText } = this.props
    this.setState({ ...this.state, data: '' })
    setSearchQueryHelperText("Search a household...")
    // Clear SearchBar
    search(undefined, this.state.order, this.state.householdStatus)
  }

  _handleChangeScoring = (e) => {
    const { search } = this.props
    this.setState({ ...this.state, order: e.target.value })
    search(this.convertToSearchString(this.state.data), e.target.value, this.state.householdStatus, this.state.systemHealthStatus, this.state.triagePriority)
  }

  _handleHouseholdStatusFilterChanged = (e) => {
    const { search } = this.props
    this.setState({ ...this.state, householdStatus: e.target.value })

    search(this.convertToSearchString(this.state.data), this.state.order, e.target.value, this.state.systemHealthStatus, this.state.triagePriority)
  }

  render() {
    const {
      classes,
      lastSearchQuery,
      queryHelperText,
    } = this.props

    return (
      <div className={classes.container}>
        <Paper className={classes.root} elevation={1}>
          <IconButton className={classes.iconButton} onClick={this._search} aria-label="Search">
            <SearchIcon />
          </IconButton>
          <InputBase
            id='searchInput'
            className={classes.input}
            placeholder={queryHelperText}
            value={this.state.data}
            onChange={this._searchOnType}
          />
          {(this.state.data || lastSearchQuery || queryHelperText) &&
            <IconButton className={classes.iconButton} onClick={this._clear} aria-label="Clear">
              <ClearIcon />
            </IconButton>
          }
        </Paper>
        {/* Triage filter */}
        <Paper className={classes.selectContainer} elevation={1}>
          <Select
            onChange={this._handleChangeScoring}
            name='scoring'
            autoWidth
            input={<InputBase
              className={classes.input}
              value={this.state.order}
            />
            }
          >
            <MenuItem value={'triage'}>Triage</MenuItem>
            <MenuItem value={'alpha'}>A-Z</MenuItem>
          </Select>
        </Paper>
        {/* HouseholdStatus search filter */}
        <Paper className={classes.selectContainer} elevation={1}>
          <Select
            onChange={this._handleHouseholdStatusFilterChanged}
            name='Household Status'
            autoWidth
            input={<InputBase
              className={classes.input}
              value={this.state.householdStatus}
            />
            }
          >
            <MenuItem value={'monitored'}>Monitored</MenuItem>
            <MenuItem value={'unmonitored'}>Unmonitored</MenuItem>
            <MenuItem value={'away'}>Resident Away</MenuItem>
            <MenuItem value={'all'}>Show All</MenuItem>

          </Select>
        </Paper>
      </div>
    )
  }
}

SearchBar.propTypes = {
  classes: PropTypes.object.isRequired,
  search: PropTypes.func,
  lastSearchQuery: PropTypes.string,
  queryHelperText: PropTypes.string,
  setSearchQueryHelperText: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    queryHelperText: state.householdsV2.queryHelperText,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSearchQueryHelperText: (value) => dispatch(ACTION_SET_SEARCH_QUERY_PLACEHOLDER_TEXT(value)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SearchBar))
