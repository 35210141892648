import { Skeleton } from "@mui/material"

export const LoadingGauges = () =>
{
  return(
    <div style={styles.container}>

      <div>
        <Skeleton variant="circular" width={100} height={100} animation="wave"/>
        <Skeleton variant="text" width={100}  animation="wave"/>
      </div>

      <div>
        <Skeleton variant="circular" width={100} height={100} animation="wave"/>
        <Skeleton variant="text" width={100}  animation="wave"/>
      </div>

      <div>
        <Skeleton variant="circular" width={100} height={100} animation="wave"/>
        <Skeleton variant="text" width={100}  animation="wave"/>

      </div>

      <div>
        <Skeleton variant="circular" width={100} height={100} animation="wave"/>
        <Skeleton variant="text" width={100}  animation="wave"/>
      </div>

      <div>
        <Skeleton variant="circular" width={100} height={100} animation="wave"/>
        <Skeleton variant="text" width={100}  animation="wave"/>
      </div>

    </div>
  )
}

const styles = {
  container: {
    display: "flex",
    flex: 1,
    justifyContent: "space-around",
  },
}