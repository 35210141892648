import React, { useState } from 'react'
import { Organisation } from '../../../../api/OrganisationApi';
import { sortAlphabetically } from '../../../../helpers/arraySorting';
import { SearchBar } from '../Shared/SearchBar';
import { OrgItem } from './OrgItem';

interface OrgStructureListProps {
  organisations: Organisation[];
  parentId: string;
  addFacility: (ouId: string) => void;
  addHousehold: (ouId: string) => void;
  addUser: (ouId: string) => void;
  deleteNode: (ouId: string) => void;
}

export const OrgStructureList = ({ organisations, parentId, addFacility, addHousehold, addUser, deleteNode }: OrgStructureListProps) => {
  const [query, setQuery] = useState("");

  sortAlphabetically(organisations, "type");

  // Prevent organizations to display chilren that does not belong to them.
  const filteredOrgs = organisations.filter(o =>
    o.type !== 'household' && o.friendlyName.toLowerCase().includes(query.toLowerCase()) && o.id === parentId
  );

  return (
    <div>
      <SearchBar value={query} onTextChange={setQuery} title="Search" onClear={() => setQuery("")} />

      {filteredOrgs.map((organisation, key) => (
        <OrgItem
          addUser={addUser}
          addHousehold={addHousehold}
          addFacility={addFacility}
          organisations={organisations}
          key={key}
          organisation={organisation}
          deleteNode={deleteNode}
        />
      ))}

    </div>
  )
}