import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';

import sharedStore from '../../../../../redux'
import { connect } from 'react-redux'

const { callAPI } = sharedStore.userStore
const { HOUSEHOLDS_SENSORS_DELETE, REMOVE_PENDANT } = sharedStore.apiEndpoints

// Use a custom delete endpoint for deleting a pendant
const SENSOR_TYPE_PENDANT = "pendant"

let updatedSensorsList = false


const ConfirmDeleteModal = ({ handleClose, open, sensor, onDeleteCallback, deleteSensor, deletePendant, household, isDeletingSensor, sensorDeleteResult, deleted, sensorDeleteError, resetModalState }) => {
  ConfirmDeleteModal.propTypes = {
    handleClose: PropTypes.func,
    open: PropTypes.bool,
    sensor: PropTypes.object,
    onDeleteCallback: PropTypes.func,
    deleteSensor: PropTypes.func,
    household: PropTypes.object,
    isDeletingSensor: PropTypes.bool,
    sensorDeleteResult: PropTypes.string,
    deleted: PropTypes.bool,
    sensorDeleteError: PropTypes.string,
    resetModalState: PropTypes.func,
    deletePendant: PropTypes.func,
  }

  const [checked, setChecked] = useState(false)

  const handleCheckBoxChange = (e) => {
    setChecked(e.target.checked)
  }

  const handleDelete = () => {

    if(sensor.type === SENSOR_TYPE_PENDANT)
    {
      deletePendant({
        householdId: household.householdId,
        pendantId: sensor.id,
      })
    }
    else
    {
      deleteSensor({
        householdId: household.householdId,
        body: { sensorId: sensor.id },
        method: "DELETE",
      })
    }

  }

  useEffect(() => {
    // Update the sensorsList in the parent component
    if (deleted && updatedSensorsList === false && sensor && sensor.id)
    {
      onDeleteCallback(sensor)
      updatedSensorsList = true
    }
  })

  const handleOnEntered = () => {
    updatedSensorsList = false
    if (sensor.statusReason !== "ok" || sensor.status !== "NORMAL") {
      setChecked(true)
    }
  }

  const handleOnExit = () => {
    updatedSensorsList = false
    setChecked(false)
    resetModalState()
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onExit={() => handleOnExit()}
      onEntered={() => handleOnEntered()}
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-delete-title"
      aria-describedby="confirm-delete-description">
      <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
      <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {!deleted && !sensorDeleteError ? <div>

          <div style={{ justifyContent: 'center', flexGrow: 1, display: 'flex' }}> <DeleteIcon style={{ color: 'grey', fontSize: 64 }}/></div>

          <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete the sensor {sensor.friendlyName} ?
            <br></br>
            {
              sensor.statusReason === "ok" && sensor.status === "NORMAL" && <div><span style={{ color: "red" }}>
                <br></br>
              WARNING: This sensor is in a healthy state, are you really sure you want to delete it?</span>
              <div style={{ flexDirection: "row" }}>Confirm Delete<Checkbox checked={checked} onChange={handleCheckBoxChange}></Checkbox></div>
              </div>
            }
          </DialogContentText> </div> :

          <div>
            <div style={{ justifyContent: 'center', flexGrow: 1, display: 'flex' }}><DoneIcon style={{ color: 'grey', fontSize: 64 }}/></div>
            <DialogContentText>
              {sensorDeleteResult}
            </DialogContentText>
          </div>
        }
        {sensorDeleteError && <DialogContentText style={{ color: "red" }}>{sensorDeleteError}</DialogContentText>}
      </DialogContent>
      {!deleted ? <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button startIcon={isDeletingSensor && <CircularProgress size={16} />} disabled={!checked} onClick={handleDelete} color="primary" autoFocus>
          Yes, delete it
        </Button>
      </DialogActions> : <DialogActions>
        <Button onClick={handleClose} color="primary">
            Close
        </Button>
      </DialogActions>}
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {
    isDeletingSensor: state.householdsV2.isDeletingSensor,
    deleted: state.householdsV2.deleted,
    sensorDeleteResult: state.householdsV2.sensorDeleteResult,
    sensorDeleteError: state.householdsV2.sensorDeleteError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteSensor: (params) => dispatch(callAPI(HOUSEHOLDS_SENSORS_DELETE(params))),
    deletePendant: (params) => dispatch(callAPI(REMOVE_PENDANT(params.householdId, params.pendantId))),
    resetModalState: () => dispatch({ type: "HOUSEHOLDS_SENSORS_RESET_MODAL" }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDeleteModal)