import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Alert from '@material-ui/lab/Alert'

const ConfirmDeleteModal = ({ open, onClose, onConfirmAction, isDeletingReseller, deleteResellerResult, deleteResellerError, clearDeleteReseller }) => {

  ConfirmDeleteModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    onConfirmAction: PropTypes.func,
    isDeletingReseller: PropTypes.bool,
    deleteResellerResult: PropTypes.object,
    deleteResellerError: PropTypes.object,
    clearDeleteReseller: PropTypes.func,
  }

  return (
    <Dialog
      onExit={() => clearDeleteReseller()}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Confirm deleting this reseller"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this reseller?
        </DialogContentText>

        {deleteResellerResult && <Alert style={{ marginTop: 12 }} severity="success">Successfully deleted the reseller!</Alert>}
        {deleteResellerError && <Alert style={{ marginTop: 12 }} severity="error">{`Error deleting reseller`}</Alert>}

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {deleteResellerResult ? "Close" : "Cancel"}
        </Button>
        {!deleteResellerResult && <Button disabled={isDeletingReseller} onClick={onConfirmAction} color="primary" autoFocus>
          {isDeletingReseller ? "Deleting..." : "Delete"}
        </Button>}
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDeleteModal