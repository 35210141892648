import React, { useState } from 'react'
import { ISensor } from '@intelicare/shared/interfaces/household/ISensor'
import Card from '@material-ui/core/Card'
import Typograph from '@material-ui/core/Typography'
import { SensorTypes } from '@intelicare/shared/enums'
import ShowerUsageGraph from './graphs/ShowerUsageGraph'
import { TabHeader } from './TabHeader'
import Typography from '@material-ui/core/Typography'
import InteliIcon from '../../../../../components/InteliIcon'

interface ShowerUsageCardProps
{
  sensors: ISensor[];
  householdId: string;
}

export const ShowerUsageCard = ({ sensors, householdId }: ShowerUsageCardProps) =>
{

  const paniSensors = sensors.filter(sensor => sensor.type === SensorTypes.PANI)
  const [ currentTab, setCurrentTab ] = useState(0);
  const [ sensorId, setSensorId ] = useState(paniSensors && paniSensors.length > 0 ? paniSensors[0].paniId : "");
  const handleChangeTab = (sensor: ISensor, index: number) =>
  {
    setCurrentTab(index)
    if(sensor && sensor.paniId) setSensorId(sensor.paniId)
  }

  return (
    <Card style={styles.cardContainer}>

      <Typograph variant="h6">Shower usage over the past month</Typograph>

      {paniSensors && paniSensors.length > 0 ?
        <>
          <TabHeader
            currentTab={currentTab}
            onChangeTab={handleChangeTab}
            tabItems={paniSensors}/>

          <ShowerUsageGraph householdId={householdId} paniId={sensorId} />
        </> :
        <div style={styles.noSensors}>
          <InteliIcon name="bathtub" family="material" size={64} color="grey" />
          <Typography color="textSecondary">No Pani Shower Sensors Found</Typography>
        </div>
      }


    </Card>
  )
}


const styles = {
  cardContainer: {
    height: 400,
    padding: 12,
  },
  noSensors: {
    textAlign: 'center' as "center",
    padding: 120,
  },
}