import React, { useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { purple } from '@material-ui/core/colors';
import { getInitials } from '../utils/getInitials';
import { AssignedRolesList } from './AssignedRolesList';
import { RolesListModal } from '../RolesListModal';
import { addUserToOrganisationBegin, addUserToOrganisationClear, getUserOrgDetailsClear, removeUserFromOrganisationBegin } from '../../../../../redux/domains/organisations/actionCreators';
import { Organisation } from '../../../../../api/OrganisationApi';

import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../../interfaces/reduxState';
import { SnackAlert } from '../../Shared/SnackAlert';
import { ConfirmDelete } from '../../../../../components/ConfirmDelete';

interface CreateUserModalProps
{
  handleClose: () => void;
  open: boolean;
  organisationDetails: Organisation;
}

export const CreateUserModal = ({ handleClose, open, organisationDetails }: CreateUserModalProps) =>
{
  const [ email, setEmail ] = useState("")
  const [ currentRoles, setCurrentRoles ] = useState<string[]>([])

  const [ showConfirmRemoveRole, setShowConfirmRemoveRole ] = useState(false)
  const [ showRolesListModal, setShowRolesListModal ] = useState(false)

  const [ selectedRole, setSelectedRole ] = useState("")

  const dispatch = useDispatch();
  const addUserToOrganisationSuccessResult = useSelector(({ organisations }: ApplicationState) => organisations.addUserToOrganisationSuccessResult)
  const isAddingUserToOrganisation = useSelector(({ organisations }: ApplicationState) => organisations.isAddingUserToOrganisation)
  const addUserToOrganisationError = useSelector(({ organisations }: ApplicationState) => organisations.addUserToOrganisationError)

  const handleRoleSelect = (role: string) =>
  {
    setSelectedRole(role)
    setShowConfirmRemoveRole(true)
  }

  const handleExit = () =>
  {
    setEmail("")
    setCurrentRoles([])
    dispatch(addUserToOrganisationClear())
    dispatch(getUserOrgDetailsClear())
  }

  const handleAssignRole = (role: string) =>
  {
    setShowRolesListModal(false)
    if(organisationDetails)setCurrentRoles(currentRoles.concat(role))
  }

  const handleRemoveRole = (role: string) =>
  {
    setShowConfirmRemoveRole(false)
    if(organisationDetails) setCurrentRoles(currentRoles.filter(r => r !== role))
  }

  const handleAddUserToOrganisation = () =>
  {

    if(organisationDetails && email)
    {
      const user = {
        email: email,
        roles: currentRoles,
      }
      dispatch(addUserToOrganisationBegin(organisationDetails.id, user))
    }

  }

  return(
    <Dialog
      TransitionProps={{
        onExit: handleExit,
      }}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}>
      <DialogTitle>Invite user to {organisationDetails.friendlyName}</DialogTitle>
      <DialogContent>

        {/* User email input  */}
        <Grid container direction="row" alignItems="center" spacing={3}>

          <Grid item>
            <IconButton>
              <Avatar style={{ width: 100, height: 100, backgroundColor: purple[500], fontSize: 32 }}>{getInitials(email)}</Avatar>
            </IconButton>
          </Grid>

          <Grid item style={{ flex: 1 }}>
            <TextField
              onChange={(e: any) => setEmail(e.target.value)}
              value={email}
              autoFocus
              id="Email"
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
            />
          </Grid>

        </Grid>



        <AssignedRolesList
          onAssignRole={() => setShowRolesListModal(true)}
          onItemRemove={(role) => handleRoleSelect(role)}
          roles={currentRoles}
          selectedItem={organisationDetails} />

        {/* Confirm Remove Role */}
        <ConfirmDelete
          handleClose={() => setShowConfirmRemoveRole(false)}
          onConfirmAction={() => handleRemoveRole(selectedRole)}
          open={showConfirmRemoveRole}
          title="Remove role"
          content="Do you want to remove this role for this household for this user?"/>

        {/* Assign Roles Modal */}
        <RolesListModal
          onRoleSelect={(role) => handleAssignRole(role)}
          handleClose={() => setShowRolesListModal(false)}
          open={showRolesListModal}
        />

        <SnackAlert color="info" open={isAddingUserToOrganisation} onClose={() => dispatch(addUserToOrganisationClear())} alertMsg={"Adding user to organisation"} />
        <SnackAlert color="success" open={addUserToOrganisationSuccessResult !== "" ? true : false} onClose={() => dispatch(addUserToOrganisationClear())} alertMsg={addUserToOrganisationSuccessResult} />
        <SnackAlert color="error" open={addUserToOrganisationError !== "" ? true : false} onClose={() => dispatch(addUserToOrganisationClear())} alertMsg={addUserToOrganisationError} />


      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button disabled={(addUserToOrganisationSuccessResult !== "" || !email) ? true : false} onClick={handleAddUserToOrganisation}>{addUserToOrganisationSuccessResult ? "Saved" : "Save"}</Button>
      </DialogActions>
    </Dialog>
  )
}

