exports.clientStore = require('./domains/clients/clientsStore')
exports.graphsStore = require('./domains/graphs/graphsStore')
exports.householdsStore = require('./domains/households/householdsStore')
exports.householdsV2Store = require('./domains/householdsV2/householdsStore')
exports.alexa = require('./domains/alexa/reducer')
exports.healthMetrics = require('./domains/healthMetrics/reducer')
exports.graphsV2Store = require('./domains/householdsV2/graphsV2Store')
exports.reportStore = require('./domains/reports/reportsStore')
exports.reportSaga = require('./domains/reports/reportSaga').default
exports.dataFeedStore = require('./domains/datafeed/datafeedStore')
exports.systemStore = require('./domains/system/systemStore')
exports.userStore = require('./domains/user/userStore')
exports.notificationsStore = require('./domains/notifications/notificationsStore')
exports.locationStore = require('./domains/location/locationStore')
exports.locationConstants = require('./domains/location/locationConstants')

exports.householdsSelector = require('./domains/households/householdsSelector')
exports.householdsV2Selector = require('./domains/householdsV2/householdsSelector')
exports.graphsSelector = require('./domains/graphs/graphsSelector')
exports.userSelector = require('./domains/user/userSelector')
exports.notificationsSelector = require('./domains/notifications/notificationsSelector')
exports.systemSelector = require('./domains/system/systemSelector')
exports.datafeedSelector = require('./domains/datafeed/datafeedSelector')

exports.createLoggingMiddleware = require('./middleware/loggingMiddleware').default

exports.apiSaga = require('./domains/user/apiSaga').default
exports.householdsDataSaga = require('./domains/households/householdsDataSaga').default
exports.householdInformationSaga = require('./domains/households/householdInformationSaga').default
exports.webSocketSaga = require('./domains/notifications/notificationsWebsocketSaga').default
exports.householdV2Saga = require('./domains/householdsV2/householdsSaga').default

exports.overallActivityService = require('./shared/services/OverallActivityService')
exports.activityService = require('./shared/services/ActivityService')

exports.colors = require('./shared/util/colors')
exports.sharedStyles = require('./shared/util/styles')
exports.enums = require('./shared/util/enums')
exports.behaviour = require('./shared/util/behaviour')
exports.math = require('./shared/util/math')
exports.network = require('./shared/util/network')
exports.status = require('./shared/util/status')
exports.toolbelt = require('./shared/util/toolbelt')

exports.webApi = require('./shared/services/WebAPI')
exports.apiEndpoints = require('./shared/services/APIEndpoints')

exports.helper = require('./shared/selectors/helper')
exports.dashboardStatusSelector = require('./shared/selectors/dashboardStatusSelector')

exports.img = {
  defaultAvatar: require('./shared/img/default_avatar.png'),
  iconWhite: require('./shared/img/icon_white.png'),
  iconOnly: require('./shared/img/icon_only.png'),
  anonymous: require('./shared/img/anonymous.png'),
  logoColor: require('./shared/img/logo_color.png'),
  houseWhite: require('./shared/img/house_white.svg'),
  house: require('./shared/img/house.svg'),
  heartWhite: require('./shared/img/heart_white.svg'),
  heart: require('./shared/img/heart.svg'),
  pro: {
    defaultAvatar: require('./shared/img/pro/default_avatar.png'),
  },
}

exports.userConstants = require('./domains/user/userConstants')
exports.userWebsocketSaga = require('./domains/user/userWebsocketSaga').default
exports.loginSagaWeb = require('./domains/user/loginSagaWeb').default
exports.logoutSaga = require('./domains/user/logoutSaga').default
exports.userSaga = require('./domains/user/userDataSaga').default
exports.adal = require('./shared/services/adal').default()