import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import { DOMESTIC_COLOURS } from './utils/colors'
import Check from '@mui/icons-material/CheckCircle'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import { InsightNotEnabled } from './InsightNotEnabled'

interface WashingCardProps
{
  washingBehaviour: any;
}

export const WashingCard = ({ washingBehaviour }: WashingCardProps) =>
{
  return(
    washingBehaviour.enabled ? <Card style={styles.cardContainer}>
      <CardHeader
        titleTypographyProps={{ variant:'h6' }}
        avatar={
          <Avatar style={{ ...styles.avatar, backgroundColor: washingBehaviour.status.value === "NORMAL" ? DOMESTIC_COLOURS.NORMAL : DOMESTIC_COLOURS.WARNING }}>
            {washingBehaviour.status.value === "WARNING" ? <ErrorOutline fontSize="large"/> : <Check fontSize="large" /> }
          </Avatar>
        }
        title="Washing"
        subheader={washingBehaviour.status.msg}
      />
    </Card> : <InsightNotEnabled title="Washing"/>
  )
}

const styles = {
  cardContainer: {
    padding: 12,
    flex: 1,
    borderRadius: 6,
    marginBottom: 12,
    minWidth: 300,
  },
  avatar: {
    width: 100,
    height: 100,
  },
  icon: {
    width: 100,
    height: 100,
  },
}