import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import React from "react";

const Loading = () => {
  return (
    <Grid
      container
      direction="column"
      style={{ minHeight: "100vh" }}
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
          <Typography color="textSecondary">Getting Gateways</Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export { Loading };
