import React from "react";

import { Chart } from "react-google-charts";
import { HouseholdHealthRollup } from "./interface";

import CircularProgress from "@material-ui/core/CircularProgress";

import moment from "moment";

interface householdsHealthRollupProps {
  householdsHealthRollup: HouseholdHealthRollup[];
}

const formatHouseholdHealthChartData = (rollup: HouseholdHealthRollup[]) => {
  const chartData = [];

  chartData.push(["Day", "ALERT", "INACTIVE", "OK", "WATCH"]);

  try {
    for (const item of rollup) {
      chartData.push([moment(item.timestamp).format("DD/MM/YYYY"), item.householdHealth["ALERT"], item.householdHealth["INACTIVE"], item.householdHealth["OKAY"], item.householdHealth["WATCH"]]);
    }

    return chartData;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const HouseholdHealthChart = ({ householdsHealthRollup }: householdsHealthRollupProps) => {
  return (
    <Chart
      width={"auto"}
      height={"500px"}
      chartType="Line"
      loader={
        <div style={styles.chartLoadingContainer}>
          <CircularProgress color="secondary" />
        </div>
      }
      data={formatHouseholdHealthChartData(householdsHealthRollup)}
      options={{
        chart: {
          title: "Household health over the last 6 months",
          subtitle: "in number of units",
        },
      }}
      rootProps={{ "data-testid": "3" }}
    />
  );
};

const styles = {
  chartLoadingContainer: {
    width: "auto",
    height: "600px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
};
