import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import DoNotDisturbOffIcon from '@mui/icons-material/DoNotDisturbOff';

interface InsightNotEnabledProps
{
  title: string;
}

export const InsightNotEnabled = ({ title }: InsightNotEnabledProps) =>
{
  return(
    <Card style={styles.cardContainer}>
      <CardHeader
        titleTypographyProps={{ variant:'h6' }}
        avatar={
          <Avatar style={{ ...styles.avatar, backgroundColor: 'grey' }}>
            <DoNotDisturbOffIcon fontSize="large"/>
          </Avatar>
        }
        title={title}
        subheader="Insight not enabled"
      />
    </Card>
  )
}

const styles = {
  cardContainer: {
    padding: 12,
    flex: 1,
    borderRadius: 6,
    marginBottom: 12,
    minWidth: 300,
  },
  avatar: {
    width: 100,
    height: 100,
  },
  icon: {
    width: 100,
    height: 100,
  },
}