/*
 * Description: Replacement chart for the system and household status health
 * Author: Guy Bridge
 * Date: 30/07/2020
*/

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Chart } from 'react-google-charts'
import Typography from '@material-ui/core/Typography'
import "../Donut.css"

import formatChart from '../util/formatChart'
import searchHelper from '../util/searchHelper'
import { theme } from '../../../themes/proTheme'


const Donut = ({ arcLabel, householdHealth = [], systemData = [], type, getHouseholdListNextPage, clearHouseholdList, setSearchQueryHelperText, fetchStatusSummary }) => {

  Donut.propTypes = {
    arcLabel: PropTypes.string,
    householdHealth: PropTypes.array,
    systemData: PropTypes.array,
    type: PropTypes.string,
    getHouseholdListNextPage: PropTypes.func,
    clearHouseholdList: PropTypes.func,
    setSearchQueryHelperText: PropTypes.func,
  }

  useEffect(() => fetchStatusSummary(), [])

  return (
    <div style={{ padding: 6 }}>
      <Typography color="textSecondary" variant="subtitle1" style={{ marginBottom: -30 }}>{arcLabel}</Typography>
      <Chart
        chartEvents={[
          {
            eventName: 'select',
            callback: ({ chartWrapper }) => {
              const chart = chartWrapper.getChart()
              const selection = chart.getSelection()
              if (selection.length === 1) {
                const [selectedItem] = selection
                const dataTable = chartWrapper.getDataTable()
                const { row } = selectedItem
                const filterTerm = dataTable.getValue(row, 0)

                clearHouseholdList()
                searchHelper(type, filterTerm, getHouseholdListNextPage, setSearchQueryHelperText)

              }
              else {
                searchHelper(type, "", getHouseholdListNextPage, setSearchQueryHelperText)
              }

            },
          },
        ]}
        width={'30vw'}
        chartType="PieChart"
        data={formatChart(type === "householdHealth" ? householdHealth : systemData)}
        options={{
          sliceVisibilityThreshold: 0,
          titlePosition: "center",
          backgroundColor: "transparent",
          pieHole: 0.3,
          legend: {
            position: "bottom",
            textStyle: {
              fontSize: 11,
            },
          },
          colors: ['#ed2e7d', '#66348b', "#65727b"],
          fontSize: 12,
          pieSliceText: "value", // Could be label or value
          slices: {
            0: { color: theme.palette.okay.main, offset: 0 },
            1: { color: theme.palette.warning.main, offset: 0 },
            2: { color: theme.palette.alert.main, offset: 0 },
            3: { color: '#9E9E9E', offset: 0 },
          },
          titleTextStyle: { fontSize: 0, color: "#535353" },
          tooltip: {
            showColorCode: true,
            textStyle: { fontSize: 16 },
            ignoreBounds: false,
          },
          animation: {
            duration: 1000,
            easing: 'out',
            startup: true,
          },
        }}
        rootProps={{ 'data-testid': '3' }}
      />
    </div>
  )
}

export default Donut
