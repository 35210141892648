export default theme => ({
  Root: {
    display: 'flex',
    flexDirection: 'column',
  },
  TableHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  TableTitle: {
    flexGrow: 1,
    padding: '0px 15px',
  },
  icon: {
    position: 'relative',
    top: 3,
    fontSize: '6em',
  },
  default: {
    display: 'inline-block',
    width: '100%',
    textAlign: 'center',
  },
  defaultHeader: {
    textAlign: 'center',
  },
  tileCells: {
    whiteSpace: 'normal',
    padding: '0 !important',
    height: '100%',
  },
  alertButton: {
    color: '#FFFFFF',
    display: 'block',
    width: '100%',

    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    fontSize: '0.68vw',
    height: 'auto',
  },
  amberButton: {
    color: '#333333',
    display: 'block',
    width: '100%',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    fontSize: '0.68vw',
    height: 'auto',
    // fontSize: '0.68em',
  },
  okayButton: {
    color: '#333333',
    display: 'block',
    width: '100%',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    fontSize: '0.68vw',
    height: 'auto',
    //fontSize: '0.68em',
  },
  normalButton: {
    display: 'block',
    color: '#333333',
    width: '100%',
    backgroundColor: '#CCCCCC',
    textAlign: 'center',
    fontSize: '0.68vw',
    height: 'auto',
    whiteSpace: 'pre-wrap',
    // fontSize: '0.68em',
  },

})
