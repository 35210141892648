
import { SettingsActions } from './actions';
import { SettingsStore } from './interfaces';

const initialState: SettingsStore = {
  isUpdating: false,
  isGetting: false,
  successResult: "",
  errorResult: "",
  zones: [],
  behaviours: [],
}

export const settingsReducer = (state: SettingsStore = initialState, action: any): SettingsStore =>
{
  switch(action.type)
  {
    case SettingsActions.UPDATE_ZONE_BEHAVIOUR_SETTING_BEGIN:
      return {
        ...state,
        isUpdating: true,
        errorResult: "",
        successResult: "",
      }
    case SettingsActions.UPDATE_ZONE_BEHAVIOUR_SETTING_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        successResult: "Successfully updated behaviour settings",
        zones: state.zones && state.zones.length > 0 ? state.zones.map(zone => {
          zone.behaviours = zone.behaviours.map(behaviour => behaviour.id === action.data.id ? action.data : behaviour)
          return zone
        }) : [],
        behaviours: state.behaviours.map(behaviour => behaviour.id === action.data.id ? action.data : behaviour),
      }
    case SettingsActions.UPDATE_ZONE_BEHAVIOUR_SETTING_ERROR:
      return {
        ...state,
        isUpdating: false,
        errorResult: action.error.message,
      }
    case SettingsActions.CLEAR_SETTINGS_ACTIONS:
      return {
        ...state,
        errorResult: "",
        successResult: "",
      }
    case SettingsActions.GET_BEHAVIOURS_BY_ZONE_BEGIN:
      return {
        ...state,
        isGetting: true,
      }
    case SettingsActions.GET_BEHAVIOURS_BY_ZONE_SUCCESS:
      return {
        ...state,
        isGetting: false,
        zones: action.data,
      }
    case SettingsActions.GET_BEHAVIOURS_BY_ZONE_ERROR:
      return {
        ...state,
        isGetting: false,
        errorResult: action.error.message,
      }
    case SettingsActions.GET_BEHAVIOURS_BEGIN:
      return {
        ...state,
        isGetting: true,
      }
    case SettingsActions.GET_BEHAVIOURS_SUCCESS:
      return {
        ...state,
        isGetting: false,
        behaviours: action.data,
      }
    case SettingsActions.GET_BEHAVIOURS_ERROR:
      return {
        ...state,
        isGetting: false,
        errorResult: action.error.message,
      }
    default:
      return state;
  }
}