import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// Material UI components
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'

import CircularProgress from '@material-ui/core/CircularProgress'

// Icons
import ClearIcon from '@mui/icons-material/Clear'
import RefreshIcon from '@mui/icons-material/Refresh'
import GetAppIcon from '@mui/icons-material/GetApp'
import { CSVLink } from "react-csv"

import moment from 'moment'

// Redux
import { callAPI } from '../../redux/domains/user/userStore'
import { apiEndpoints } from '../../redux'
const EXPORT_NOTIFICATIONS = apiEndpoints.EXPORT_NOTIFICATIONS

const MAX_NOTIFICATIONS_TO_EXPORT = 50000

const TableActions = (
  { type,
    status,
    monthValue,
    handleDateRangeChange,
    getNotificationsWithState,
    clearFilters,
    handleSearch,
    search,
    exportedNotifications,
    isExportingNotifications,
    exportNotificationsError,
    exportNotifications,
    householdId,
    clearExportedNotifications }) =>
{
  TableActions.propTypes = {
    type: PropTypes.string,
    status: PropTypes.string,
    monthValue: PropTypes.number,
    handleDateRangeChange: PropTypes.func,
    getNotificationsWithState: PropTypes.func,
    clearFilters: PropTypes.func,
    handleSearch: PropTypes.func,
    notificationsForExport: PropTypes.array,
    search: PropTypes.string,
    exportedNotifications: PropTypes.array,
    isExportingNotifications: PropTypes.bool,
    exportNotificationsError: PropTypes.any,
    exportNotifications: PropTypes.func,
    householdId: PropTypes.string,
    clearExportedNotifications: PropTypes.func,
  }

  useEffect(() => {
    return () => {
      clearExportedNotifications()
    }
  }, [])

  return (
    <div style={{ paddingBottom: 10 }}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <TextField
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            label="Search Notifications"
            fullWidth
            placeholder="Search Notifications..."
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">
                {/* Conditionally render the clear filter if type or status is selected */}
                {(type || status || search ) && <IconButton onClick={() => clearFilters()}><ClearIcon /></IconButton>}
              </InputAdornment>,
            }}>
          </TextField>
        </Grid>

        <Grid item style={{ flex: 1 }}>
          <Select
            onChange={(e) => handleDateRangeChange(e.target.value)}
            variant="outlined" displayEmpty value={monthValue}>
            <MenuItem value={1}>1 Month</MenuItem>
            <MenuItem value={2} >2 Months</MenuItem>
            <MenuItem value={3}>3 Months</MenuItem>
            <MenuItem value={6}>6 Months</MenuItem>
            <MenuItem value={12}>All Time</MenuItem>
          </Select>
        </Grid>

        {exportedNotifications && exportedNotifications.length > 0 && !isExportingNotifications &&
      <Grid item>
        <CSVLink
          filename={`InteliCare-Notifications.csv`}
          data={exportedNotifications} target="_blank">
          <Button style={{ marginTop: 8 }} >Download</Button>
        </CSVLink>
      </Grid>}

        <Tooltip title="Download notifications for the selected time range and filter">
          <Grid item >
            {isExportingNotifications ? <CircularProgress style={{ marginTop: 12 }} color="secondary" size={20} /> :
              <IconButton onClick={() => exportNotifications(
                { timeLimit: moment().subtract(monthValue, "months").unix(),
                  type: type,
                  status: status,
                  offset: 0,
                  householdId: householdId,
                  order: "DESC",
                  limit: MAX_NOTIFICATIONS_TO_EXPORT })} >
                <GetAppIcon />
              </IconButton> }
          </Grid>
        </Tooltip>



        <Grid item>
          <IconButton automation_attribute="notificationsRefreshButton" onClick={() => getNotificationsWithState()}><RefreshIcon /></IconButton>
        </Grid>

      </Grid>

    </div>
  )
}

const mapStateToProps = state =>
{
  return {
    isExportingNotifications: state.notifications.isExportingNotifications,
    exportedNotifications: state.notifications.exportedNotifications,
    exportNotificationsError: state.notifications.exportNotificationsError,
  }
}

const mapDispatchToProps = dispatch =>
{
  return {
    exportNotifications: (params) => dispatch(callAPI(EXPORT_NOTIFICATIONS(params))),
    clearExportedNotifications: () => dispatch({ type: "CLEAR_EXPORTED_NOTIFICATIONS" } ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableActions)