
export const LIST_ORGANISATIONS_BEGIN = "LIST_ORGANISATIONS_BEGIN"
export const LIST_ORGANISATIONS_SUCCESS = "LIST_ORGANISATIONS_SUCCESS"
export const LIST_ORGANISATIONS_ERROR = "LIST_ORGANISATIONS_ERROR"

export const ADD_HOUSEHOLD_TO_ORGANISATION_BEGIN = 'ADD_HOUSEHOLD_TO_ORGANISATION_BEGIN'
export const ADD_HOUSEHOLD_TO_ORGANISATION_SUCCESS = 'ADD_HOUSEHOLD_TO_ORGANISATION_SUCCESS'
export const ADD_HOUSEHOLD_TO_ORGANISATION_ERROR = 'ADD_HOUSEHOLD_TO_ORGANISATION_ERROR'
export const ADD_HOUSEHOLD_TO_ORGANISATION_CLEAR = 'ADD_HOUSEHOLD_TO_ORGANISATION_CLEAR'


export const ADD_USER_TO_ORGANISATION_BEGIN = 'ADD_USER_TO_ORGANISATION_BEGIN'
export const ADD_USER_TO_ORGANISATION_SUCCESS = 'ADD_USER_TO_ORGANISATION_SUCCESS'
export const ADD_USER_TO_ORGANISATION_ERROR = 'ADD_USER_TO_ORGANISATION_ERROR'
export const ADD_USER_TO_ORGANISATION_CLEAR = 'ADD_USER_TO_ORGANISATION_CLEAR'

export const REMOVE_USER_FROM_ORGANISATION_BEGIN = "REMOVE_USER_FROM_ORGANISATION_BEGIN"
export const REMOVE_USER_FROM_ORGANISATION_SUCCESS = "REMOVE_USER_FROM_ORGANISATION_SUCCESS"
export const REMOVE_USER_FROM_ORGANISATION_ERROR = "REMOVE_USER_FROM_ORGANISATION_ERROR"
export const REMOVE_USER_FROM_ORGANISATION_CLEAR = 'REMOVE_USER_FROM_ORGANISATION_CLEAR'

export const GET_USER_ORG_DETAILS_BEGIN = 'GET_USER_ORG_DETAILS_BEGIN'
export const GET_USER_ORG_DETAILS_SUCCESS = 'GET_USER_ORG_DETAILS_SUCCESS'
export const GET_USER_ORG_DETAILS_ERROR = 'GET_USER_ORG_DETAILS_ERROR'
export const GET_USER_ORG_DETAILS_CLEAR = 'GET_USER_ORG_DETAILS_CLEAR'

export const GET_USER_DETAILS_BEGIN = 'GET_USER_DETAILS_BEGIN'
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS'
export const GET_USER_DETAILS_ERROR = 'GET_USER_ORG_DETAILS_ERROR'
export const GET_USER_DETAILS_CLEAR = 'GET_USER_ORG_DETAILS_CLEAR'

export const GET_USER_DETAILS_FOR_CACHE_BEGIN = 'GET_USER_DETAILS_FOR_CACHE_BEGIN'
export const GET_USER_DETAILS_FOR_CACHE_SUCCESS = 'GET_USER_DETAILS_FOR_CACHE_SUCCESS'
export const GET_USER_DETAILS_FOR_CACHE_ERROR = 'GET_USER_DETAILS_FOR_CACHE_ERROR'

export const SEARCH_ORGANISATIONS_BEGIN = "SEARCH_ORGANISATIONS_BEGIN";
export const SEARCH_ORGANISATIONS_CLEAR = "SEARCH_ORGANISATIONS_CLEAR"

export const VIEW_ORGANISATION_DETAILS = "VIEW_ORGANISATION_DETAILS"
export const STORE_HOUSEHOLD_SEARCH_RESULTS = "STORE_HOUSEHOLD_SEARCH_RESULTS"

export const ADD_ORGANISATION_BEGIN = "ADD_ORGANISATION_BEGIN"
export const ADD_ORGANISATION_SUCCESS = "ADD_ORGANISATION_SUCCESS"
export const ADD_ORGANISATION_ERROR = "ADD_ORGANISATION_ERROR"
export const ADD_ORGANISATION_CLEAR = "ADD_ORGANISATION_CLEAR"

export const ADD_ROLE_BEGIN = "ADD_ROLE_BEGIN"
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS"
export const ADD_ROLE_ERROR = "ADD_ROLE_ERROR"
export const ADD_ROLE_CLEAR = "ADD_ROLE_CLEAR"

export const REMOVE_ROLE_BEGIN = "REMOVE_ROLE_BEGIN"
export const REMOVE_ROLE_SUCCESS = "REMOVE_ROLE_SUCCESS"
export const REMOVE_ROLE_ERROR = "REMOVE_ROLE_ERROR"
export const REMOVE_ROLE_CLEAR = "REMOVE_ROLE_CLEAR"

export const DELETE_ORGANISATION_BEGIN = "DELETE_ORGANISATION_BEGIN"
export const DELETE_ORGANISATION_SUCCESS = "DELETE_ORGANISATION_SUCCESS"
export const DELETE_ORGANISATION_ERROR = "DELETE_ORGANISATION_ERROR"
export const DELETE_ORGANISATION_CLEAR = "DELETE_ORGANISATION_CLEAR"