import React from 'react'
import PropTypes from 'prop-types'
import { Chart } from 'react-google-charts'
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress'

const formatChartData = (filteredEvents) => {

  try {

    const formattedChartData = []
    formattedChartData.push(["Room", "Old State", "New State"])

    filteredEvents.map(event => {

      const row = [`${event.roomType} - ${event.deviceType} (${moment(event.eventTime).fromNow()})`, Number(event.oldState), Number(event.newState)]
      return formattedChartData.push(row)
    })

    return formattedChartData

  }
  catch (error) {
    return []
  }


}

const SensorStateGraph = ({ filteredEvents }) => {

  SensorStateGraph.propTypes = {
    filteredEvents: PropTypes.array,
  }

  return (
    <Chart
      width={'100%'}
      height={'600px'}
      chartType="ScatterChart"
      loader={<div style={{ textAlign: "center" }}><CircularProgress /></div>}
      data={formatChartData(filteredEvents)}
      options={{
        title: 'Sensor State Activity',
        hAxis: { title: 'Sensor Type', titleTextStyle: { color: '#333' } },
        vAxis: { minValue: 0 },
        // For the legend to fit, we make the chart area smaller
        // lineWidth: 25
        animation: {
          duration: 1000,
          easing: 'out',
          startup: true,
        },
      }}
      // For tests
      rootProps={{ 'data-testid': '1' }}
    />
  )
}

export default SensorStateGraph