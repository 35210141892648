import access from 'safe-access'

// ------------------------------------
// Constants
// ------------------------------------
export const LOGIN_START = 'LOGIN_START'
export const LOGIN = 'LOGIN'
export const USER_INFO = 'USER_INFO'
export const USER_INFO_BEGIN = 'USER_INFO_BEGIN'
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS'
export const USER_INFO_ERROR = 'USER_INFO_ERROR'
export const USER_INFO_SAVE = 'USER_INFO_SAVE'
export const USER_INFO_SAVE_BEGIN = 'USER_INFO_SAVE_BEGIN'
export const USER_INFO_SAVE_SUCCESS = 'USER_INFO_SAVE_SUCCESS'
export const USER_INFO_SAVE_ERROR = 'USER_INFO_SAVE_ERROR'
export const DISMISS_USER_INFO_NOTIFICATION = 'DISMISS_USER_INFO_NOTIFICATION'
export const REFRESH_TOKEN_BEGIN = 'REFRESH_TOKEN_BEGIN'
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS'
export const REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR'
export const REFRESH_TOKEN_HOUSEHOLDS_BEGIN = 'REFRESH_TOKEN_HOUSEHOLDS_BEGIN'
export const REFRESH_TOKEN_HOUSEHOLDS_SUCCESS = 'REFRESH_TOKEN_HOUSEHOLDS_SUCCESS'
export const REFRESH_TOKEN_HOUSEHOLDS_ERROR = 'REFRESH_TOKEN_HOUSEHOLDS_ERROR'
export const LOGOUT = 'LOGOUT'
export const LOGOUT_START = 'LOGOUT_START'
export const CALL_API = 'CALL_API'
export const ACTION_TOGGLE_DRAWER = "ACTION_TOGGLE_DRAWER"
// ------------------------------------
// Actions
// ------------------------------------
export function loginStart(details, redirect) {
  return {
    type: LOGIN_START,
    details: details,
    redirect: redirect,
  }
}

export function login(details) {
  return {
    type: LOGIN,
    details: details,
  }
}
// details - givenName, surname, photo
export function updateUserInfo(details) {
  return {
    type: USER_INFO_SAVE,
    details: details,
  }
}

export function updateUserInfoSuccess() {
  return {
    type: USER_INFO_SAVE_SUCCESS,
  }
}

export function updateUserInfoError() {
  return {
    type: USER_INFO_SAVE_ERROR,
  }
}

export function getUserInfoStart() {
  return {
    type: USER_INFO_BEGIN,
  }
}

export function getUserProfile()
{
  return {
    type: USER_INFO,
  }
}

export function getUserInfoSuccess(details) {
  return {
    type: USER_INFO_SUCCESS,
    details,
  }
}

export function clearUserInfoMsg() {
  return {
    type: DISMISS_USER_INFO_NOTIFICATION,
  }
}

export function getUserInfoError() {
  return {
    type: USER_INFO_ERROR,
  }
}

export function refreshTokenStart() {
  return {
    type: REFRESH_TOKEN_BEGIN,
  }
}

export function refreshTokenHouseholdStart() {
  return {
    type: REFRESH_TOKEN_HOUSEHOLDS_BEGIN,
  }
}

export function refreshTokenSuccess() {
  return {
    type: REFRESH_TOKEN_SUCCESS,
  }
}

export function refreshTokenError() {
  return {
    type: REFRESH_TOKEN_ERROR,
  }
}

export function logoutStart() {
  return {
    type: LOGOUT_START,
  }
}

export function callAPI(endpoint, params = {}) {
  return {
    type: CALL_API,
    endpoint: endpoint,
    params: params,
  }
}

export function actionToggleDrawer()
{
  return {
    type: ACTION_TOGGLE_DRAWER,
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isAuthenticated: false,
  isDeveloper: false,
  isSupport: false,
  isBetaUser: false,
  isDemoUser: false,
  inteliCareProUser: false,
  token: undefined,
  refreshToken: undefined,
  firstName: undefined,
  lastName: undefined,
  mobilePhone: undefined,
  loginError: undefined,
  principal: undefined,
  profile: undefined,
  tokenRefresh: {
    isFetching: false,
  },
}

export default function userReducer(state = initialState, action) {

  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: !!access(action, 'details.decodedToken.oid'),
        token: access(action, 'details.decodedToken.token'),
        email: access(action, 'details.decodedToken.email'),
        inteliCareProUser: access(action, 'details.decodedToken.InteliCareProUser') || false,
        isDeveloper: access(action, 'details.decodedToken.isDeveloper') || false,
        isSupport: access(action, 'details.decodedToken.isSupport') || false,
        isBetaUser: access(action, 'details.decodedToken.isBetaUser') || false,
        isDemoUser: access(action, 'details.decodedToken.oid') === "bafb50e8-3763-4d1e-b23d-0e247a6d52ed" ? true : false,
        loginError: action.details.loginError,
        principal: access(action, 'details.profile.oid'),
        profile: action.details.profile,
        // Only used on web app for iframe refresh
        // https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-v2-protocols-implicit
      }
    case USER_INFO_SAVE_SUCCESS:
      return {
        ...state,
        isSavingUser: false,
        saveUserSuccess: true,
        errorSavingUser: false,
        firstName: state.tempUserInfo.firstName,
        lastName: state.tempUserInfo.lastName,
        mobilePhone: state.tempUserInfo.mobilePhone,
        avatar: state.tempUserInfo.avatar,
        tempUserInfo: null,
      }

    case USER_INFO_SAVE_ERROR:
      return {
        ...state,
        isSavingUser: false,
        errorSavingUser: true,
      }

    case DISMISS_USER_INFO_NOTIFICATION:
      return {
        ...state,
        saveUserSuccess: false,
        errorSavingUser: false,
      }
    case USER_INFO_SAVE:
      return {
        ...state,
        saveUserSuccess: false,
        isSavingUser: true,
        errorSavingUser: false,
        tempUserInfo: {
          firstName: access(action, 'details.givenName') || state.firstName,
          lastName: access(action, 'details.surname') || state.lastName,
          avatar: access(action, 'details.photo') || state.avatar,
          mobilePhone: access(action, 'details.mobilePhone') || state.mobilePhone,
        },
      }
    case USER_INFO_SAVE_BEGIN:
      return {
        ...state,
        isSavingUser: true,
      }
    case USER_INFO_BEGIN:
      return {
        ...state,
        isFetchingUser: true,
      }

    case USER_INFO_ERROR:
      return {
        ...state,
        isFetchingUser: false,
      }

    case USER_INFO_SUCCESS:
      return {
        ...state,
        isFetchingUser: false,
        firstName: access(action, 'data.givenName'),
        lastName: access(action, 'data.surname'),
        avatar: access(action, 'data.photo'),
        mobilePhone: access(action, 'data.mobilePhone'),
      }

    case REFRESH_TOKEN_BEGIN:
      return {
        ...state,
        tokenRefresh: {
          isFetching: true,
        },
      }

    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.details.access_token,
        tokenRefresh: {
          isFetching: false,
        },
      }

    case REFRESH_TOKEN_ERROR:
      return {
        ...state,
        authorized: {
          isFetching: false,
          error: action.error,
        },
      }

    case REFRESH_TOKEN_HOUSEHOLDS_BEGIN:
      return {
        ...state,
        tokenRefresh: {
          isFetching: true,
        },
      }

    case REFRESH_TOKEN_HOUSEHOLDS_SUCCESS:
      return {
        ...state,
        token: action.details.access_token,
        tokenRefresh: {
          isFetching: false,
        },
      }

    case REFRESH_TOKEN_HOUSEHOLDS_ERROR:
      return {
        ...state,
        authorized: {
          isFetching: false,
          error: action.error,
        },
      }
    case ACTION_TOGGLE_DRAWER:
      return {
        ...state,
        isDrawerOpen: !state.isDrawerOpen,
      }

    default:
      return state
  }
}
