import React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

interface MetricChooserProps
{
  items: string[];
  onChoose: (item: string) => void;
  currentItem: string;
}

export const MetricChooser = ({ items, onChoose, currentItem }: MetricChooserProps) =>
{

  return(
    <div>
      <Select variant="outlined" value={currentItem}>

        {items.map((item, key) => <MenuItem value={item} key={key} onClick={() => onChoose(item)}>{item}</MenuItem>)}

      </Select>
    </div>
  )
}
