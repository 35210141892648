import { connect } from 'react-redux'
import { compose } from 'redux'

import ReportsView from '../components/ReportsView'
import { withErrorHandler, Fallback } from '../../../hoc/withErrorHandler'

import sharedStore from '../../../redux'
const {
  getHouseholds,
  householdListHasMore,
} = sharedStore.householdsV2Selector
const { getHouseholdReport, triggerViewReport } = sharedStore.reportStore
const purgeHouseholds = sharedStore.householdsV2Store.purgeHouseholds
const callAPI = sharedStore.userStore.callAPI
const {
  HOUSEHOLD_V2_LIST_PAGE,
} = sharedStore.apiEndpoints

const mapDispatchToProps = (dispatch) => ({
  getReport: (householdId) => dispatch(getHouseholdReport(householdId)),
  viewReport: () => dispatch(triggerViewReport(true)),
  getHouseholdListNextPage: (pagePosition, searchQuery, order) => {
    dispatch(callAPI(HOUSEHOLD_V2_LIST_PAGE({
      householdStatus: "all",
      systemHealthStatus: "",
      triagePriority: "",
      pageSize: 20,
      pagePosition,
      searchQuery: searchQuery || '*',
      order,
    })))
  },
  purgeHouseholds: () => {
    dispatch(purgeHouseholds())
  },
})

const mapStateToProps = (state) => ({
  households: getHouseholds(state),
  hasMore: householdListHasMore(state),
  reportInfo: state.reports,
  isDeveloper: state.user.isDeveloper,
  isSupport: state.user.isSupport,
})


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorHandler(Fallback)
)(ReportsView)
