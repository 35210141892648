import React from 'react'
import PropTypes from 'prop-types'
import DocumentTitle from 'react-document-title'
import _ from 'lodash'

import ContentStrapline from '../../../../components/ContentStrapline'
import { FirmwareListTable } from './FirmwareList/FirmwareListTable'
import "react-table/react-table.css"
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Snackbar,
  IconButton,
  Grid,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'


import SearchIcon from '@mui/icons-material/Search'
import RouterIcon from '@mui/icons-material/Router'
import ClearIcon from '@mui/icons-material/Clear'


import Title from '../../../../components/Title'

import { Loading } from './Loading'

import RemoteCommandsModal from "./RemoteCommandsModal"
import FirmwareUpdateStatusModal from './FirmwareUpdateStatusModal'
import { SensorCommandsModal } from './SensorCommandsModal';
import TopFilterBar from "./TopFilterBar"

import { appInsights } from '../../../../api/AppInsights'

export default class GatewayFirmwareView extends React.Component {
  static friendlyName = 'Developer Console'
  static pageName = 'gatewayFirmwareView'

  constructor(props) {
    super(props)
    this.state = {
      logDialogOpen: false,
      dialogTitle: null,
      dialogGatewayId: null,
      dialogFileList: [],
      showRemoteCommandsModal: false,
      gatewayId: "",
      gatewaysToUpdate: [],
      showFirmwareUpdateSnackbar: false,
      showFirmwareUpdateModal: false,
    }
  }

  hideLogsDialog = () => {
    this.setState({
      logDialogOpen: false,
      dialogTitle: null,
      dialogGatewayId: null,
      dialogFileList: [],
    })
  };

  showLogsDialog(row) {
    const {
      getLogFileList,
    } = this.props
    getLogFileList(row.gatewayId)
    this.setState({
      dialogTitle: row.gatewayId + " logs",
      dialogGatewayId: row.gatewayId,
      dialogLogFile: undefined,
    })
  }

  componentDidUpdate(lastProps) {
    const { logFileResponse } = this.props
    if (!logFileResponse.isFetching && lastProps.logFileResponse.isFetching) {
      this.setState(state => ({
        ...state,
        logDialogOpen: true,
        dialogLogFile: logFileResponse.logfileData,
        dialogFileList: logFileResponse.logfileDataList,
      }))
    }
  }

  handleCloseModal = () => {
    const { clearRemoteCommandsModal } = this.props
    clearRemoteCommandsModal()
    this.setState({ showRemoteCommandsModal: false })
  }

  viewLogFile(gatewayId, filename) {
    const {
      getLogFile,
    } = this.props
    getLogFile(gatewayId, filename)
  }

  handleOnSelectForFirmwareUpdate = (row, checked) => {

    const { gatewaysToUpdate } = this.state
    if(checked) return this.setState({ gatewaysToUpdate: [...gatewaysToUpdate, row.gatewayId] })
    return this.setState({ gatewaysToUpdate: gatewaysToUpdate.filter(gateway => gateway !== row.gatewayId) })
  }

  runBulkGatewayUpdate = () =>
  {
    const { gatewaysToUpdate } = this.state
    const { runGatewayUpdate } = this.props

    if(gatewaysToUpdate.length > 0)
    {
      this.setState({ showFirmwareUpdateSnackbar: true })
      runGatewayUpdate(gatewaysToUpdate.map(gw => `'${gw}'`).join(","))
    }

  }

  closeSnack = (event, reason) => {
    if (reason === 'clickaway') return
    this.setState({ showFirmwareUpdateSnackbar: false })
  };

  componentDidMount()
  {
    appInsights.trackPageView({ name: "Developer Console" })
  }

  render() {

    const {
      isFetching,
      gatewayFirmware,
      runGatewayUpdate,
      token,
    } = this.props

    const { gatewaysToUpdate, showFirmwareUpdateSnackbar, showSensorCommandsModal } = this.state

    const getRowData = () => {
      if (!isFetching) {
        return Object.values(_.omit(gatewayFirmware, ['count']))
      } else {
        return []
      }
    }

    return (
      <div className='households'>
        <DocumentTitle title='InteliHub Admin Console' />

        <ContentStrapline
          title='InteliHub Admin Console'
        />
        <Title title="InteliHub Admin Console" />
        <TopFilterBar
          totalDataSizeSequence={gatewayFirmware.count}
          fetchFirmwareData={this.props.fetchFirmwareData}
        />
        {/* Firmware update control buttond */}
        <Grid container direction="row">

          <Button
            style={{ marginLeft: 12 }}
            variant="outlined"
            onClick={() => this.setState({ showFirmwareUpdateModal: true })}
            startIcon={<RouterIcon />}>Show Update Status</Button>

          {/* Only show the bulk update if the user has selected gateways */}
          {gatewaysToUpdate && gatewaysToUpdate.length > 0 &&
        <div>
          <Button
            onClick={this.runBulkGatewayUpdate}
            startIcon={<RouterIcon />}
            style={{ marginLeft: 12 }}
            color="secondary"
            variant="outlined">{`Run bulk update (${gatewaysToUpdate.length} gateways selected)`}
          </Button>
          <IconButton size="small" onClick={() => this.setState({ gatewaysToUpdate: [] })}>
            <ClearIcon></ClearIcon>
          </IconButton></div>}


        </Grid>

        { isFetching ? <Loading /> :

          <FirmwareListTable
            onShowSensorCommands={(row) => this.setState({ gatewayId: row.gatewayId, showSensorCommandsModal: true })}
            runGatewayUpdate={runGatewayUpdate}
            onSelectForFirmwareUpdate={this.handleOnSelectForFirmwareUpdate}
            rows={getRowData()}
            onShowCommands={(row) => this.setState({ gatewayId: row.gatewayId, showRemoteCommandsModal: true })}
            onShowLogs={(row) => this.showLogsDialog(row)} />
        }
        <div>

          <Snackbar open={showFirmwareUpdateSnackbar} autoHideDuration={6000} onClose={this.closeSnack}>
            <Alert variant="filled" onClose={this.closeSnack} severity="success">
          Successfully scheduled firmware update for {gatewaysToUpdate.length} gateways !
            </Alert>
          </Snackbar>

          <FirmwareUpdateStatusModal
            open={this.state.showFirmwareUpdateModal}
            handleClose={() => this.setState({ showFirmwareUpdateModal: false })}/>

          <RemoteCommandsModal
            remoteErrorResult={this.props.remoteErrorResult}
            remoteResult={this.props.remoteResult}
            executeService={this.props.executeService}
            gatewayId={this.state.gatewayId}
            show={this.state.showRemoteCommandsModal}
            onHide={() => this.handleCloseModal()} />

          <SensorCommandsModal
            token={token}
            gatewayId={this.state.gatewayId}
            open={showSensorCommandsModal}
            onClose={() => this.setState({ showSensorCommandsModal: false })}
          />

          <Dialog
            open={this.state.logDialogOpen}>
            <DialogTitle>{this.state.dialogTitle}</DialogTitle>
            <DialogContent>
              {(this.state.dialogFileList.length > 0) ?
                this.state.dialogFileList.map((item, index) => {
                  const length = this.state.dialogFileList.length
                  if (index > length - 6) {
                    return (
                      <Button
                        onClick={() => {
                          this.viewLogFile(this.state.dialogGatewayId, item)
                        }}
                        key={index}
                        labelStyle={{ color: 'rgba(0,0,0,.9)' }}
                      >
                        {item}
                      </Button>
                    )
                  } else {
                    return
                  }
                })
                : <div style={{ padding: 16, textAlign: 'center' }}>
                  <SearchIcon />
                  <Typography variant="h6" color="textSecondary">No update logs for gateway</Typography>
                </div>
              }
              {
                this.state.dialogFileList.length > 0 && this.state.dialogLogFile &&
                <pre>
                  {this.state.dialogLogFile}
                </pre>
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={this.hideLogsDialog}>Close</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    )
  }

}

GatewayFirmwareView.propTypes = {
  fetchFirmwareData: PropTypes.func.isRequired,
  getLogFile: PropTypes.func.isRequired,
  getLogFileList: PropTypes.func.isRequired,
  runGatewayUpdate: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  gatewayFirmware: PropTypes.array,
  logFileResponse: PropTypes.object,
  remoteResult: PropTypes.func,
  executeService: PropTypes.func,
  remoteErrorResult: PropTypes.object,
  clearRemoteCommandsModal: PropTypes.func,
  token: PropTypes.string,
}