/* eslint-disable no-undef */
import {
  put,
  takeEvery,
} from 'redux-saga/effects'

import {
  callAPISaga,
  errorPrefix,
} from '../../shared/services/WebAPI'

function* refreshAndCall(action) {
  let endpoint = action.endpoint

  if (!endpoint && process.env.NODE_ENV) {
    process.env.NODE_ENV && window.alert("Tried to call an API without specifying an endpoint")
    return
  }

  try {

    yield callAPISaga(endpoint)

  } catch (err) {
    yield put({
      type: errorPrefix(endpoint),
      error: err,
    })
  }
}

/*
  Starts refreshAndCall on each dispatched `CALL_API` action.
*/
function* apiSaga() {
  // yield takeEvery(action => /_BEGIN/.test(action.type), checkTokenAndForward)
  yield takeEvery("CALL_API", refreshAndCall)
}

export default apiSaga
