import { useState } from 'react'
import { NotificationApi } from '../../../../../api/NotificationApi'

type UseNotificationsHook = [(householdId: string, severity: string, message: string) => void, boolean, string, string]
export const useNotifications = (token: string, sourceType: string, clientNo: string): UseNotificationsHook =>
{
  const [ isLoading, setLoading ] = useState(false);
  const [ success, setSuccess ] = useState("")
  const [ error, setError ] = useState("")


  const sendTestNotification = async (householdId: string, severity: string, message: string) =>
  {
    setLoading(true);
    setSuccess("");
    setError("");
    const testApi = new NotificationApi(token)
    const response = await testApi.sendTest(householdId, severity, message, sourceType, clientNo)
    if(response && response.result) setSuccess(response.result)
    if(response && response.error) setError(response.error)
    setLoading(false);
  }

  return [ sendTestNotification, isLoading, success, error]
}