import { ZoneActions } from './actions'
import { addNewRoom, addSensorToRoomInZone, removeRoomFromZoneInState, removeSensorFromRoomInZone, updateRoomNameAndSensors } from './helpers'
import { ZoneState } from './interfaces'

export const initialZoneState: ZoneState = {
  isRemovingRoom: false,
  removeRoomError: "",
  removeRoomSuccess: "",
  isCreatingZone: false,
  createSuccess: "",
  createZoneError: "",
  isUpdatingZone: false,
  updateSuccess: "",
  updateZoneError: "",
  isDeletingZone: false,
  deleteSuccess: "",
  deleteZoneError: "",
  isGettingZones: false,
  getSuccess: "",
  getZonesError: "",
  zones: [],
  showSnack: false,
  snackMessage: "",
  snackType: "success",
  gateway: undefined,
}

export const zoneReducer = (state = initialZoneState, action: any): ZoneState =>
{
  switch(action.type)
  {
    case ZoneActions.CREATE_ZONE_BEGIN:
      return {
        ...state,
        isCreatingZone: true,
        createZoneError: "",
        createSuccess: "",
      }
    case ZoneActions.CREATE_ZONE_SUCCESS:
      return {
        ...state,
        isCreatingZone: false,
        createSuccess: "Created new zone OK",
        zones: state.zones.concat(action.data),
      }
    case ZoneActions.CREATE_ZONE_ERROR:
      return {
        ...state,
        isCreatingZone: false,
        createZoneError: action.error.message,
      }
    case ZoneActions.CLEAR_ADD_ZONE_STATUS:
      return {
        ...state,
        createZoneError: "",
        createSuccess: "",
      }
    case ZoneActions.GET_ZONES_BEGIN:
      return {
        ...state,
        isGettingZones: true,
        getSuccess: "",
        getZonesError: "",
      }
    case ZoneActions.GET_ZONES_SUCCESS:
      return {
        ...state,
        isGettingZones: false,
        getSuccess: "Got all zones OK",
        zones: action.data,
      }
    case ZoneActions.GET_ZONES_ERROR:
      return {
        ...state,
        isGettingZones: false,
        getZonesError: action.error.message,
      }
    case ZoneActions.UPDATE_ZONE_BEGIN:
      return {
        ...state,
        isUpdatingZone: true,
        updateZoneError: "",
        updateSuccess: "",
      }
    case ZoneActions.UPDATE_ZONE_SUCCESS:
      return {
        ...state,
        isUpdatingZone: false,
        zones: state.zones.map(zone => action.data.zoneId === zone.zoneId ? action.data : zone), // update state with the new zone data
        updateSuccess: "Updated zone OK",
      }
    case ZoneActions.UPDATE_ZONE_ERROR:
      return {
        ...state,
        isUpdatingZone: false,
        updateZoneError: action.error.message,
      }
    case ZoneActions.REMOVE_ZONE_BEGIN:
      return {
        ...state,
        isDeletingZone: true,
        deleteZoneError: "",
        deleteSuccess: "",
      }
    case ZoneActions.REMOVE_ZONE_SUCCESS:
      return {
        ...state,
        isDeletingZone: false,
        zones: state.zones.filter(zone => zone.zoneId !== action.data.zoneId), // Remove the deleted zone from state
        deleteSuccess: "Successfully removed zone",
      }
    case ZoneActions.REMOVE_ZONE_BEGIN:
      return {
        ...state,
        isDeletingZone: false,
        deleteZoneError: action.error.message,
      }
    case ZoneActions.CLEAR_REMOVE_ZONE_STATUS:
      return {
        ...state,
        deleteSuccess: "",
        deleteZoneError: "",
      }
    case ZoneActions.CLEAR_UPDATE_ZONE_STATUS:
      return {
        ...state,
        updateSuccess: "",
        updateZoneError: "",
      }
    case ZoneActions.REMOVE_ROOM_FROM_ZONE_BEGIN:
      return {
        ...state,
        isRemovingRoom: true,
        removeRoomError: "",
        removeRoomSuccess: "",
      }
    case ZoneActions.REMOVE_ROOM_FROM_ZONE_SUCCESS:
      return {
        ...state,
        isRemovingRoom: false,
        removeRoomSuccess: action.data.roomid,
        zones: removeRoomFromZoneInState(state, action),
      }
    case ZoneActions.REMOVE_ROOM_FROM_ZONE_ERROR:
      return {
        ...state,
        isRemovingRoom: false,
        removeRoomError: action.error.message,
      }
    case ZoneActions.CLEAR_REMOVE_ROOM_FROM_ZONE:
      return {
        ...state,
        removeRoomError: "",
        removeRoomSuccess: "",
      }
    case ZoneActions.UPDATE_ROOM_NAME_SUCCESS:
      return {
        ...state,
        zones: updateRoomNameAndSensors(state, action),
      }
    case ZoneActions.ADD_ROOM_SUCCESS:
      return {
        ...state,
        zones: addNewRoom(state, action),
      }
    case ZoneActions.SHOW_SNACK:
      return {
        ...state,
        showSnack: true,
        snackMessage: action.data.message,
        snackType: action.data.type,
      }
    case ZoneActions.HIDE_SNACK:
      return {
        ...state,
        showSnack: false,
        snackMessage: "",
        snackType: "success",
      }
    case ZoneActions.ADD_SENSOR_TO_ROOM_SUCCESS:
      return {
        ...state,
        zones: addSensorToRoomInZone(state, action),
      }
    case ZoneActions.REMOVE_SENSOR_FROM_ROOM_SUCCESS:
      return {
        ...state,
        zones: removeSensorFromRoomInZone(state, action),
      }
    case ZoneActions.GET_GATEWAY_SUCCESS:
      return {
        ...state,
        gateway: action.data,
      }
    default:
      return state
  }
}