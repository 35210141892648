import React from "react"
import PropTypes from 'prop-types'
import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"

// interface LoadingProps {
//   text: string;
// }

export const Loading = ({ text }) => {

  Loading.propTypes = {
    text: PropTypes.string,
  }
  return (
    <div style={styles.container}>
      <CircularProgress color="secondary" />
      <Typography color="textSecondary" variant="body2">
        {text}
      </Typography>
    </div>
  )
}

const styles = {
  container: {
    textAlign: "center",
  },
}
