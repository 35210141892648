import React, { Component } from 'react'
import PropTypes from 'prop-types'

const PageContext = React.createContext()
export class PageProvider extends Component {
  constructor(props) {
    super(props)
    const setTitle = (title, { iconLeft = null, statusOverride = null, pageButtons = null }) => {
      if (title !== this.state.title) {
        this.setState({
          ...this.state,
          iconLeft: iconLeft,
          title: title,
          statusOverride: statusOverride,
          pageButtons: pageButtons,
        })
      }
    }
    this.state = {
      title: 'InteliCare',
      setTitle: setTitle,
      iconLeft: null,
      statusOverride: null,
      pageButtons: null,
    }
  }



  render() {
    const { children } = this.props
    return (
      <PageContext.Provider
        value={this.state}>
        {children}
      </PageContext.Provider>
    )
  }
}

PageProvider.propTypes = {
  children: PropTypes.object,
}

export const PageConsumer = PageContext.Consumer
//export const PageContext = React.createContext('Needs a title')