import { v4 as uuid } from 'uuid';


export class SensorParameter
{
  id: string;
  name: string;
  parameter: number | string;
  value: number | string;

  constructor(id: string, name: string, parameter: number | string, value: number | string)
  {
    this.id = id || uuid();
    this.name = name;
    this.parameter = parameter;
    this.value = value;
  }
}

