import NotificationsContainer from './container/NotificationsContainer'
import HouseholdNotifications from '../../routes/HouseholdNotifications'
// Sync route definition
export default {
  path: '/notifications',
  component : NotificationsContainer,
  routes: [
    HouseholdNotifications,
  ],
}
