import React from 'react'
import InteliIcon from '../../../../../../components/InteliIcon'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

export const NoHealthMetrics = (props: NotMetricsInterface) =>
{
  return(<div style={{ textAlign: 'center' }}>
    <InteliIcon color="secondary" name="favorite_border"/>
    <Typography color="textSecondary">{props.message}</Typography>
    {props.showEnabledButton &&
      <Button style={{ marginTop: 5 }} size="small" variant="contained" onClick={props.enable}>Enable</Button>
    }
  </div>)
}

interface NotMetricsInterface {
  message: string,
  showEnabledButton: boolean,
  enable(): void,
}

