import { connect } from 'react-redux'
import Component from '../components/MealTimeGraphView'

const mapDispatchToProps = (dispatch) => {
  return {}
}

const mapStateToProps = (state) => ({
  data: state.graphs.graphs.data.mealTime || {},
  isFetching: state.graphs.graphs.isFetching,
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
