import SystemContainer from './container/SystemContainer'
import GatewayDetails from './components/GatewayDetails'
// Sync route definition
export default {
  path: '/system',
  component : SystemContainer,
  routes : [
    GatewayDetails,
  ],
}
