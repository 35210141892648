import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

// import { amber, okay } from '../../redux/shared/util/colors'
import { brandPink, infoBlue } from '../../themes/proTheme';

const styles = (theme) => ({
  guageLabel: {
    fontFamily: theme.typography.fontFamily,
  },
})

const guageShape = 'M 0 10 a 10 10 0 0 1 0.603 -3.420 l 2.819 1.026 a 8 8 0 0 0 -0.422 2.394 l -3 0'
const needleShape = 'M 10.5 10 l -0.4 -6.5 l -0.2 0 l -0.4 6.5 a 0.5 0.5 0 0 0 1 0'

function RotateArc(angle) {
  return `translate(3, 2.5) scale(0.8) rotate(${angle + 1.25}, 10, 10)`
}

function PositionLabelLow() {
  return 'translate(3, 7) rotate(-65) scale(0.18)'
}

function PositionLabelNormal() {
  return 'translate(11, 2) scale(0.18)'
}

function PositionLabelHigh() {
  return 'translate(19.5, 7) rotate(65) scale(0.18)'
}

export default withStyles(styles)(class Guage extends Component {

  static propTypes = {
    classes: PropTypes.object,
    width: PropTypes.number,
    labelLow: PropTypes.string,
    labelNormal: PropTypes.string,
    labelHigh: PropTypes.string,
    value: PropTypes.number,
    max: PropTypes.number,
    min: PropTypes.number,
  }

  render() {
    const { classes, width, max, min, value, labelLow, labelNormal, labelHigh } = this.props
    const height = width / 2
    const scale = (width || 150) / 22.0
    const valueScaled = ((value || 0.5) - (min || 0)) / ((max || 1) - (min || 0))
    let indicatorAngle = valueScaled * 180 - 90
    if (indicatorAngle < -90) indicatorAngle = -90
    if (indicatorAngle > 90) indicatorAngle = 90
    const lowerColour = brandPink.main;
    const middleColour = infoBlue.main;
    const higherColour = brandPink.main;

    return (
      <div width={width} height={height} >
        <svg width={width} height={height}>
          <g transform={`scale(${scale}, ${scale})`}>
            <path d={guageShape} fill={lowerColour} stroke={'gray'} strokeWidth={0.1} transform={RotateArc(0)} />
            <path d={guageShape} fill={lowerColour} stroke={'gray'} strokeWidth={0.1} transform={RotateArc(22.5)} />
            <path d={guageShape} fill={middleColour} stroke={'gray'} strokeWidth={0.1} transform={RotateArc(45)} />
            <path d={guageShape} fill={middleColour} stroke={'gray'} strokeWidth={0.1} transform={RotateArc(67.5)} />
            <path d={guageShape} fill={middleColour} stroke={'gray'} strokeWidth={0.1} transform={RotateArc(90)} />
            <path d={guageShape} fill={middleColour} stroke={'gray'} strokeWidth={0.1} transform={RotateArc(112.5)} />
            <path d={guageShape} fill={higherColour} stroke={'gray'} strokeWidth={0.1} transform={RotateArc(135)} />
            <path d={guageShape} fill={higherColour} stroke={'gray'} strokeWidth={0.1} transform={RotateArc(157.5)} />
            <path d={needleShape} fill={'gray'} transform={RotateArc(indicatorAngle)} />
            <text className={classes.guageLabel} fill={lowerColour} textAnchor='middle' transform={PositionLabelLow()}>{labelLow || 'Low'}</text>
            <text className={classes.guageLabel} fill={middleColour} textAnchor='middle' transform={PositionLabelNormal()}>{labelNormal || 'Normal'}</text>
            <text className={classes.guageLabel} fill={higherColour} textAnchor='middle' transform={PositionLabelHigh()}>{labelHigh || 'High'}</text>
          </g>
        </svg>
      </div >
    )
  }
})
