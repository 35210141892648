import { useState } from 'react'
import { PaniNotification, PaniNotificationPostBody } from '@intelicare/shared/interfaces/pani/Pani'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import Slider from '@material-ui/core/Slider';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import React from 'react'
import Button from '@material-ui/core/Button';
import { purple } from '@mui/material/colors';
import { useEffect } from 'react';

interface NotificationItemProps
{
  notification: PaniNotification;
  onDelete: (notificationId: string) => void;
  onUpdate: (notification: PaniNotificationPostBody, notificationId: string) => void;
  onCreate: (notification: PaniNotificationPostBody) => void;
  isNew: boolean;
}

export const NotificationItem = ({ notification, onDelete, onUpdate, isNew, onCreate }: NotificationItemProps) =>
{
  const [ slider, setSlider ] = useState(0);

  useEffect(() => {
    setSlider(Math.round(notification.threshold / 60))
  }, [notification])

  const handleUpdateNotification = () =>
  {
    const updated: PaniNotificationPostBody = {
      url: "",
      event: "time",
      threshold: slider * 60,
      unit: "l",
    }
    onUpdate(updated, notification.id)
  }

  const handleCreateNotification = () =>
  {
    const notification: PaniNotificationPostBody = {
      url: "",
      event: "time",
      threshold: slider * 60,
      unit: "l",
    }
    onCreate(notification)
  }

  return(
    <Card style={styles.container}>
      <CardHeader
        avatar={
          <Avatar style={styles.avatar} aria-label="recipe">
            <Icon>timer</Icon>
          </Avatar>
        }

        title="Time trigger notification"
        subheader={`Send alert after ${Math.round(slider)} minutes`}
      />
      <CardContent>
        <Slider
          value={slider}
          valueLabelDisplay="auto"
          max={60}
          onChange={(e: any, value) => setSlider(value as number)} />
      </CardContent>

      <CardActions>
        <Button onClick={() => onDelete(notification.id)}>Delete</Button>
        {isNew ?
          <Button onClick={handleCreateNotification}>Create</Button>
          :
          <Button onClick={handleUpdateNotification}>Update</Button> }
      </CardActions>

    </Card>
  )
}

const styles = {
  container: {
    flex: 1,
    margin: 6,
  },
  avatar: {
    backgroundColor: purple[500],
  },
}