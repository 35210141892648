import React, { useState } from 'react';

import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import AddIcon from '@mui/icons-material/Add';
import Fab from '@material-ui/core/Fab';
import { SensorList } from './SensorList'
import { SensorModal } from './SensorModal';
import { ISensorConfiguration, SensorConfiguration } from '../../../../model/SensorConfiguration';
import Skeleton from 'react-loading-skeleton'

// Custom hooks
import { useSensors } from './hooks/useSensors'
import { useFilter as buildFilter } from './hooks/useFilter';
interface SensorConfigurationViewProps
{
  token: string;
}

const SensorConfigurationView = ({ token }: SensorConfigurationViewProps ) =>
{
  const [ showSensorModal, setShowAddSensorModal ] = useState(false);
  const [ currentSensor, setCurrentSensor ] = useState<SensorConfiguration | undefined>(undefined)
  const [ isGettingSensorList, sensorsList, getSensors ] = useSensors({ token })
  const [ query, setQuery ] = useState("")

  const handleEditSensor = (sensorConfiguration: ISensorConfiguration) =>
  {
    setCurrentSensor(sensorConfiguration)
    setShowAddSensorModal(true)
  }

  const handleCloseModal = () =>
  {
    setCurrentSensor(undefined)
    setShowAddSensorModal(false)
    getSensors()
  }

  return(
    <div style={styles.container}>
      <TextField onChange={(e) => setQuery(e.target.value)} label="Search" style={styles.searchBar} variant="outlined" fullWidth placeholder="Search" />

      {isGettingSensorList ? <Skeleton style={styles.skeletonItem} height={50} count={10}/> :
        <SensorList sensorsList={buildFilter(query, sensorsList)} handleOpen={(sensorConfiguration) => handleEditSensor(sensorConfiguration)} />
      }

      <Fab
        onClick={() => setShowAddSensorModal(true)}
        color="secondary"
        aria-label="add"
        style={styles.fabStyle}>
        <AddIcon />
      </Fab>

      <SensorModal token={token} currentSensor={currentSensor} title={currentSensor ? "Edit Sensor" : "Add Sensor"} open={showSensorModal} onClose={handleCloseModal} />


    </div>

  )
}

const styles = {
  container: {
    margin: 12,
  },
  searchBar: {
    paddingBottom: 12,
  },
  fabStyle: {
    margin: 0,
    top: 'auto' as 'auto',
    right: 50,
    bottom: 50,
    left: 'auto' as 'auto',
    position: 'fixed' as 'fixed',
  },
  skeletonItem: {
    marginTop: 10,
    marginBottom: 10,
  },
}

const mapStateToProps = (state: any) =>
{
  return {
    token: state.user.token,
  }
}

export default connect(mapStateToProps, null)(SensorConfigurationView)