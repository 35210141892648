import { connect } from 'react-redux'
import { toggleFeature } from '../../store/domains/featurePreviews/featurePreviewsStore'

import UserWelcomeView from './UserWelcomeView'
import sharedStore from '../../redux'
import { actionToggleDrawer } from '../../redux/domains/user/userStore'
const { logoutStart, callAPI } = sharedStore.userStore
const { USER_INFO } = sharedStore.apiEndpoints;
const getUserName = sharedStore.userSelector.getUserName
const getUserAvatar = sharedStore.userSelector.getUserAvatar

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => {
      dispatch(logoutStart())
    },
    toggleFeature: (featureKey) => {
      dispatch(toggleFeature(featureKey))
    },
    getProfile: () => dispatch(callAPI(USER_INFO())),
    closeDrawer: () => dispatch(actionToggleDrawer()),
  }
}

const mapStateToProps = (state) => {
  return {
    username: getUserName(state),
    avatar: getUserAvatar(state),
    isLoading: state.user.isFetchingUser,
    isDeveloper: state.user.isDeveloper,
    isSupport: state.user.isSupport,
    featurePreviews: state.featurePreviews,
    isDrawerOpen: state.isDrawerOpen,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserWelcomeView)
