import { RowItem, sortOrder } from "../FirmwareListTable";
import moment from "moment";


export const sortFirmware = (firmwareSortOrder: sortOrder, rows: RowItem[]): RowItem[] =>
{
  let updated: RowItem[] = [];
  if (firmwareSortOrder === "desc") {
    updated = rows.sort(
      (a: RowItem, b: RowItem) =>
        moment(a.version).unix() - moment(b.version).unix()
    );
  }

  if (firmwareSortOrder === "asc") {
    updated = rows.sort(
      (a: RowItem, b: RowItem) =>
        moment(b.version).unix() - moment(a.version).unix()
    );
  }

  return updated;
}

export const sortHouseholds = (householdSortOrder: sortOrder, rows: RowItem[]): RowItem[] =>
{
  let updated: RowItem[] = [];
  if (householdSortOrder === "desc") {
    updated = rows.sort((a: RowItem, b: RowItem) =>
      a.friendlyName < b.friendlyName ? -1 : 0
    );
  }

  if (householdSortOrder === "asc") {
    updated = rows.sort((a: RowItem, b: RowItem) =>
      b.friendlyName < a.friendlyName ? -1 : 0
    );
  }

  return updated;
}

export const sortUpdateStatus = (updateStatusSortOrder: sortOrder, rows: RowItem[]): RowItem[] =>
{
  let updated: RowItem[] = [];
  if (updateStatusSortOrder === "desc") {
    updated = rows.sort((a: RowItem, b: RowItem) =>
      a.firmwareStatus < b.firmwareStatus ? -1 : 0
    );
  }

  if (updateStatusSortOrder === "asc") {
    updated = rows.sort((a: RowItem, b: RowItem) =>
      b.firmwareStatus < a.firmwareStatus ? -1 : 0
    );
  }

  return updated;
}

export const sortLastUpdate = (lastUpdateSortOrder: sortOrder, rows: RowItem[]): RowItem[] =>
{
  let updated: RowItem[] = [];
  if (lastUpdateSortOrder === "desc") {
    updated = rows.sort((a: RowItem, b: RowItem) =>
      a.lastFirmwareUpdate < b.lastFirmwareUpdate ? -1 : 0
    );
  }

  if (lastUpdateSortOrder === "asc") {
    updated = rows.sort((a: RowItem, b: RowItem) =>
      b.lastFirmwareUpdate < a.lastFirmwareUpdate ? -1 : 0
    );
  }

  return updated
}

export const sortGatewayStatus = (gatewayStatus: sortOrder, rows: RowItem[]): RowItem[] =>
{
  let updated: RowItem[] = [];
  if (gatewayStatus === "desc") {
    updated = rows.sort((a: RowItem, b: RowItem) =>
      a.connectionState < b.connectionState ? -1 : 0
    );
  }

  if (gatewayStatus === "asc") {
    updated = rows.sort((a: RowItem, b: RowItem) =>
      b.connectionState < a.connectionState ? -1 : 0
    );
  }

  return updated
}