import { InstallerStep } from "./InstallerStep";
import { SensorParameter } from "./SensorParameter";
import { ISubSensor } from "./SubSensor";

export interface ISensorConfiguration
{
  id: string;
  docType: "configuration";
  configurationName: "add-sensor";
  productName: string;
  deviceIds: string[];
  subSensors: ISubSensor[];
  manufacturer: string;
  parameters: SensorParameter[];
  installerSteps: InstallerStep[];
  iconImageName: string;
  _etag: string;
}

export class SensorConfiguration implements ISensorConfiguration
{
  id: string;
  _etag: string;
  docType: "configuration";
  configurationName: "add-sensor";
  productName: string;
  deviceIds: string[];
  subSensors: ISubSensor[];
  manufacturer: string;
  parameters: SensorParameter[];
  installerSteps: InstallerStep[];
  iconImageName: string;
  constructor(_etag: string, id: string, iconImageName: string, productName: string, deviceIds: string[], subSensors: ISubSensor[], manufacturer: string, parameters: SensorParameter[], installerSteps: InstallerStep[])
  {
    this.id = id || "";
    this._etag = _etag || "";
    this.iconImageName = iconImageName;
    this.productName = productName;
    this.deviceIds = deviceIds;
    this.subSensors = subSensors;
    this.manufacturer = manufacturer;
    this.parameters = parameters;
    this.docType = "configuration";
    this.configurationName = "add-sensor";
    this.installerSteps = installerSteps;
  }


}


