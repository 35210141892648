import {
  alertStatus,
  amberStatus,
} from './status'
import { enums } from '@intelicare/shared'
import { theme } from '../../../themes/proTheme';

export const brand = '#E0204D'

export const brand2 = '#5f1232'

export const alert = theme.palette.alert.main

export const amber = theme.palette.warning.main;

export const okay = theme.palette.okay.main;

export const normal = '#004E80'

export const brandBlue = "#005879"

export const complementaryPurple = '#2E1250'

export const primaryText = '#212121'

export const secondaryText = '#757575'

export const nightTime = '#abc6df'

export const border = 'rgba(0,0,0,0.1)'

export const drawerBg = 'rgba(0,0,0,.7)'

export const disable = 'grey'

export const away = "#531988"

const { SENSOR_STATUS } = enums

export const colorForStatus = (status) => {
  switch (status) {
    case alertStatus: return alert
    case amberStatus: return amber
    default: return okay
  }
}

export const colorForReadableStatus = (readableStatus) => {
  switch (readableStatus) {
    case 'Alert': return alert
    case 'Watch': return amber
    default: return okay
  }
}

export const colorForStatusValue = (statusValue) => {
  switch (statusValue) {
    case SENSOR_STATUS.ALERT: return alert
    case SENSOR_STATUS.WARNING: return amber
    case SENSOR_STATUS.NORMAL: return okay
    default: return '#666666'
  }
}

export const colorForSystemStatus = (readableStatus) => {
  switch (readableStatus) {
    case 'Offline': return alert
    case 'Watch': return amber
    default: return okay
  }
}

export const colorForMessage = (status) => {
  switch (status) {
    case 'ALERT': return alert
    case 'NOTIFICATION': return amber
    default: return okay
  }
}

export const rowColorForStatus = (status) => {
  switch (status) {
    case alertStatus: return alert
    default: return 'inherit'
  }
}
