
export class InstallerStep
{
  id: number;
  instruction: string;
  imageUrl: string;

  constructor(id: number, instruction: string, imageUrl: string)
  {
    this.id = id
    this.instruction = instruction;
    this.imageUrl = imageUrl;
  }
}