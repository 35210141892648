import React from "react"
import Avatar from "@material-ui/core/Avatar"
import IconButton from "@material-ui/core/IconButton"
import Typography from '@material-ui/core/Typography'
import AccountBoxIcon from '@mui/icons-material/AccountCircle';
import { getInitials } from "../../routes/Organisations/component/Modals/utils/getInitials";
import { useTheme } from '@mui/material/styles'

interface UserNameAvatarProps
{
  avatar: string;
  onAvatarClick: (e: any) => void;
  username: string;
}

export const UserNameAvatar = ({ avatar, username, onAvatarClick }: UserNameAvatarProps) =>
{
  const theme = useTheme()

  const styles = {
    userNameContainer: {
      flexGrow: 1,
    },
    container: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
    },
    avatar: {
      width: 40,
      height: 40,
      color: "white",
      backgroundColor: theme.palette.secondary.light,
    },
  }
  console.log("theme", theme)
  return(
    <div style={styles.container}>
      {avatar ?
        <IconButton onClick={onAvatarClick} >
          <Avatar src={avatar} style={styles.avatar}>{getInitials(username)}</Avatar>
        </IconButton> :
        <IconButton onClick={(e) => onAvatarClick(e.target)} >
          <Avatar src={avatar} style={styles.avatar}>{getInitials(username)}</Avatar>
        </IconButton> }
      <div style={styles.userNameContainer}>
        <Typography noWrap>{username}</Typography>
      </div>

    </div>
  )
}

