import React from 'react'
import PropTypes from 'prop-types'
import access from 'safe-access'

import withStyles from '@material-ui/core/styles/withStyles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Linkifier from 'react-linkifier';

import InteliAvatar from '../../components/Utils/InteliAvatar'

import styles from './ViewNotificationViewStyles'
import { validateText } from '../../helpers/textValidation'

import { enums } from '../../redux'
const { notificationSeverities } = enums

class ViewNotificationView extends React.Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    notification: PropTypes.object,
    resolve: PropTypes.func,
    createAction: PropTypes.func,
    close: PropTypes.func,
    open: PropTypes.bool,
    goToHousehold: PropTypes.func.isRequired,
    path: PropTypes.string,
    getHousehold: PropTypes.func.isRequired,
    households: PropTypes.array,
    isDeveloper: PropTypes.bool,
    isSupport: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.state = {
      resolutionType: undefined,
      resolutionNote: undefined,
      showGoToHouseAction: false,
    }

    this.onUpdateResolutionNote = this.onUpdateResolutionNote.bind(this)
  }

  componentDidMount() {
    const { path } = this.props
    if (path && path === ('/notifications')) {
      this.setState({ showGoToHouseAction: true })
    }
  }

  componentDidUpdate(prevProps) {
    const { path } = this.props
    if (this.props.path !== prevProps.path) {
      if (path && path === ('/notifications')) {
        this.setState({ showGoToHouseAction: true })
      }
    }
  }

  _reset = () => {
    this.setState({
      resolutionType: undefined,
      resolutionNote: undefined,
      showGoToHouseAction: false,
    })
  }

  _resetAndClose = () => {
    this._reset()
    this.props.close()
  }

  _goToHousehold = () => {
    const {
      notification,
      goToHousehold,
    } = this.props

    this._reset()
    this.props.close()

    goToHousehold(notification.householdId)
  }

  _resolveAndClose = () => {
    const {
      resolve,
      notification,
    } = this.props

    const {
      resolutionType,
      resolutionNote,
    } = this.state

    const resolution = {
      type: resolutionType,
      message: resolutionNote,
    }

    // Append the resolution note to the notification object
    notification.resolution = resolution
    // Remove the recipients
    delete notification.recipients

    const params = {
      notification: notification,
    }

    resolve(params)

    this._resetAndClose()
  }

  _onCheckResolutionType = (evt, resolutionType) => {
    this.setState(state => ({
      ...state,
      resolutionType: resolutionType,
    }))
  }

  onUpdateResolutionNote(evt) {
    const value = access(evt, 'target.value')
    this.setState(state => ({
      ...state,
      resolutionNote: validateText(value),
    }))
  }

  _isResolved = (notification) => {
    return notification.resolution !== undefined
  }

  _isInfo = (notification) => {
    return notification.type === notificationSeverities.INFO
  }

  getAlertedBehaviours = (description) => {
    if (!description) return []

    const behaviours = []
    description = description.toLowerCase()
    if (description.includes("meal preparation") || description.includes("meal activity")) {
      behaviours.push("Meal Preparation")
    }
    if (description.includes("nighttime") || description.includes("night-time") || description.includes("night time")) {
      behaviours.push("Night-time Activity")
    }
    return behaviours
  }

  render() {
    const {
      classes,
      open,
      notification,
      getHousehold,
      isDeveloper,
      isSupport,
    } = this.props

    const {
      resolutionType,
    } = this.state

    const household = getHousehold(access(notification, 'householdId'))
    const description = access(notification, 'recipients[0].message')
    const alertedBehaviours = this.getAlertedBehaviours(description)

    return (
      <Dialog
        TransitionProps={{ onExit: () => this.setState({ resolutionNote: "" }) }}
        title={notification.type}
        autoScrollBodyContent
        modal={true}
        open={open}
      >
        <div className={classes.Modal}>
          <DialogTitle>
            <Typography>{notification.type}</Typography>
            <div className={classes.ModalHeader}>
              <InteliAvatar
                src={household && household.avatar}
                size={80}
              />
              <Typography variant='h5' automation_attribute="notificationModalHouseholdName">
                {notification.householdFriendlyName}
              </Typography>
            </div>
          </DialogTitle>

          <DialogContent>
            <Typography variant='h6'>
              Description
            </Typography>

            <Typography automation_attribute="notificationModalDescription">
              <Linkifier target="_blank">
                {description}
              </Linkifier>
            </Typography>

            {
              !this._isInfo(notification) && (
                <FormControl className={classes.ModalActionsContainer}>
                  <Typography variant='h6'>
                    Resolution
                  </Typography>
                  {!this._isResolved(notification) &&
                    <div className={classes.ModalActions}>
                      <RadioGroup
                        onChange={this._onCheckResolutionType}
                        name='notification-type'
                      >
                        <FormControlLabel
                          value='FALSE_ALARM'
                          label='False alarm'
                          control={<Radio />}
                        />
                        <FormControlLabel
                          value='ATTENDED_TO'
                          label='I have attended to it'
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </div>
                  }
                  {!!resolutionType &&
                    <div className={classes.ResolutionDescription}>
                      <Typography>
                        Resolution Notes (certain special characters are not allowed)
                      </Typography>
                      <TextField
                        fullWidth={true}
                        placeholder="Enter a resolution reason"
                        multiLine={true}
                        onChange={this.onUpdateResolutionNote}
                        value={this.state.resolutionNote}
                        rows={3}
                      />
                      {((isDeveloper || isSupport) && alertedBehaviours.length > 0) && <Typography style={{ color: 'red', marginTop: 5 }}>
                        Alerting will be reset for the following behaviours: {alertedBehaviours.join(", ")}
                      </Typography>}
                    </div>
                  }
                </FormControl>
              )
            }
            {this._isResolved(notification) &&
              <div>
                <Typography variant='subtitle1'>Type: {notification.resolution.type}</Typography>
                <Typography variant='subtitle1'>Description: {notification.resolution.message}</Typography>
              </div>
            }
          </DialogContent>

          <DialogActions>
            {(this._isResolved(notification) || this._isInfo(notification)) &&
              <Button key={1} primary keyboardFocused onClick={this._resetAndClose}>Close</Button>
            }
            {
              (!this._isResolved(notification) && !this._isInfo(notification)) &&
              <div>
                <Button key={1} primary onClick={this._resetAndClose}>Cancel</Button>
                <Button key={2} primary keyboardFocused onClick={this._resolveAndClose} disabled={!resolutionType} >Submit</Button>
              </div>
            }
          </DialogActions>
        </div>
      </Dialog >
    )
  }
}

export default withStyles(styles)(ViewNotificationView)
