export default (theme) => ({
  Household: {
    backgroundColor: '#EFEFF0',
    width: '100%',
    height: '100%',
  },
  LoadingIndicator: {
    colorPrimary: '#c4274e',
  },
  MainContent: {
    padding: '20px',
  },
  contentContainerContent: {
    backgroundColor: '#EFEFF0',
    paddingTop: '0px',
    marginTop: '5px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },


})
