import { IBehaviourItem } from "../../../../../../api/BehavioursGraphApi";
import moment from "moment";

interface DataSetItem
{
  label: string;
  data: number[];
  borderColor: string;
  fill: any;
}

const LINE_TENSION = 0.5
const POINT_RADIUS = 1
const BORDER_WIDTH = 1

export const chartAdapter = (behaviourItem?: IBehaviourItem) =>
{
  // Chart.js required format
  const formattedData = {
    labels: [] as string[], // Array of dates
    datasets: [] as DataSetItem[],
  };

  if(behaviourItem)
  {
    formattedData.labels = behaviourItem.graphData.map(item => moment(item.timestamp).format("DD/MM"))

    const trend = {
      label: "Trend",
      data: [],
      borderColor: "#424242",
      backgroundColor: "#FAFAFA",
      fill: false,
    }

    const actual = {
      pointRadius: POINT_RADIUS,
      lineTension: LINE_TENSION,
      label: "Actual",
      data: behaviourItem.graphData.map(item => item.actual ),
      borderColor: "#00ADB0",
      backgroundColor: "#00ADB0",
      fill: false,
    }

    const low = {
      pointRadius: POINT_RADIUS,
      lineTension: LINE_TENSION,
      borderWidth: BORDER_WIDTH,
      label: "Low",
      data: behaviourItem.graphData.map(item => item.low >= 0 ? item.low : 0),
      borderColor: "rgba(99, 49, 139)",
      backgroundColor: "rgba(99, 49, 139)",
      fill: { above: 'rgba(99, 49, 139, 0.1)', below: 'rgba(99, 49, 139, 0.1)', target: { value: 0 } },
    }

    const high = {
      pointRadius: POINT_RADIUS,
      lineTension: LINE_TENSION,
      borderWidth: BORDER_WIDTH,
      label: "High",
      data: behaviourItem.graphData.map(item => item.high),
      borderColor:  'rgba(240, 35, 130, 128)',
      backgroundColor: "rgba(240, 35, 130, 128)",
      fill: { above: 'rgba(240, 35, 130, 0.1)', below: 'rgba(240, 35, 130, 0.1)', target: { value: 20000 } },
    }

    formattedData.datasets.push(actual);
    formattedData.datasets.push(high);
    formattedData.datasets.push(low);
    formattedData.datasets.push(trend);


    return formattedData;
  }

}