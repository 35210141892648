import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './OverallActivityGraphView.scss'
import {
  Button,
  Typography,
} from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import moment from 'moment-timezone'
import _ from 'lodash'
import sharedStore from '../../../redux'
import * as d3 from 'd3'
const GraphService = sharedStore.overallActivityService


const DEFAULT_HEIGHT = 300
// const DEFAULT_WIDTH = 1200

class OverallActivityGraphView extends Component {
  constructor(props) {
    super(props)
    this.overallGraphRef = React.createRef()
    this.state = {
      colors: props.theme.graphColors.setB,
      width: window.innerWidth - 200,
      height: DEFAULT_HEIGHT,
      timePeriodInHours: 168,
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this._resizeDebounce = _.debounce(this.updateWindowDimensions, 150)
  }

  componentDidMount() {
    const { width } = this.state
    const { data } = this.props
    window.addEventListener('resize', this._resizeDebounce)
    this.updateGraph(width, data)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resizeDebounce)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth - 200 })
  }

  componentDidUpdate(lastProps, lastState) {
    const { width } = this.state
    const { data } = this.props
    if (data !== lastProps.data || width !== lastState.width) {
      this.updateGraph(width, data)
    }
  }

  updateOverallActivityGraph = (timePeriod) => {
    const {
      fetchOverallActivityGraphData,
      householdId,
    } = this.props

    const timePeriodInHours = timePeriod || this.state.timePeriodInHours

    fetchOverallActivityGraphData(householdId, timePeriod)

    this.setState({
      timePeriodInHours: timePeriodInHours,
    })
  }

  drawNightBars(svg, timescale) {
    const { timezone } = this.props
    var nightticks = timescale.ticks(d3.timeHour.every(6))

    nightticks.forEach((t) => {
      var sunrise
      var sunset
      if (!timezone) {
        sunset = moment(t).add(-1, "days").hour(21).minute(0)
        sunrise = moment(t).hour(6).minute(0)
      } else {
        sunset = moment(t).tz(timezone).add(-1, "days").hour(21).minute(0)
        sunrise = moment(t).tz(timezone).hour(6).minute(0)
      }
      svg.append("rect")
        .attr("x", timescale(sunset))
        .attr("y", 0)
        .attr("width", timescale(sunrise) - timescale(sunset))
        .attr("height", DEFAULT_HEIGHT - 20)
        .attr("fill", d3.rgb(171, 198, 223))
    })
  }

  updateGraph = (width, data) => {
    const { timezone } = this.props

    //clean up (for redrawing graph)
    d3.select(this.overallGraphRef.current)
      .select("svg")
      .remove()

    //d3 get div and add svg element of given size
    var svg = d3.select(this.overallGraphRef.current)
      .append("svg")
      .attr("viewBox", "0 0 " + width + " " + DEFAULT_HEIGHT)
      .attr("width", "100%")
      .attr("height", DEFAULT_HEIGHT)

    //display no data label in case of no data...
    if (data == null || data.info === undefined || data.length === 0) {
      svg.append("text")
        .text("No activity recorded for this period")
        .attr("x", (width) / 2)
        .attr("y", DEFAULT_HEIGHT / 2 + 5)
        .attr("text-anchor", "middle")
        .attr("font-size", 24)
      return
    }

    let size = { width: width - 20, height: DEFAULT_HEIGHT - 20 }
    let lineData = GraphService.getBaselineData(data, size, d3)
    let pathData = GraphService.getPathData(data, size, d3)

    //this.drawNightBars(svg, lineData.timescale)

    svg.append("path")
      .attr("d", lineData.data)
      .attr("fill", "none")
      .attr("stroke", this.state.colors[0])
      .attr("stroke-width", 2)

    //draw the path
    svg.append("path")
      .attr("d", pathData.data)
      .attr("fill", "none")
      .attr("stroke", this.state.colors[1])
      .attr("stroke-width", 2)

    // setup y axis
    var yaxis = d3.axisLeft(lineData.yscale)

    svg.append("g")
      .attr("transform", "translate(30, 0)")
      .call(yaxis)

    //setup the x axis
    var xaxis = d3.axisBottom()
      .scale(lineData.timescale).tickFormat(d => {
        let timeFormat
        const localTime = moment(d).tz(timezone || 'utc')
        const WEEK = 24 * 7
        if (this.state.timePeriodInHours < 2 * WEEK) {
          if (parseFloat(localTime.format("HH")) < 12) {
            timeFormat = 'ddd h A'
          } else {
            timeFormat = 'h A'
          }
        } else {
          timeFormat = 'MMM D'
        }
        return localTime.format(timeFormat)
      })

    const offset = 3
    //draw the x axis
    svg.append("g")
      .attr("transform", `translate(${offset}, ${DEFAULT_HEIGHT - 20})`)
      .call(xaxis)

  }

  render() {
    const { colors } = this.state
    return (
      <div>
        <Button onClick={() => this.updateOverallActivityGraph(168)}>1 w</Button>
        <Button onClick={() => this.updateOverallActivityGraph(720)}>1 mo</Button>
        <Button onClick={() => this.updateOverallActivityGraph(2160)}>3 mo</Button>
        <Button onClick={() => this.updateOverallActivityGraph(8640)}>1 year</Button>
        <div className='OverallActivityGraph' ref={this.overallGraphRef}>
        </div>
        {this.props.data &&
          <div>
            <Indicator color={colors[1]} label='Activity Level' />
            <Indicator color={colors[0]} label='Activity Trend' />
          </div>
        }
      </div>
    )
  }

}

OverallActivityGraphView.propTypes = {
  fetchOverallActivityGraphData: PropTypes.func.isRequired,
  data: PropTypes.any,
  householdId: PropTypes.string,
  timezone: PropTypes.string,
  theme: PropTypes.object,
}

export default withTheme(OverallActivityGraphView)

const Indicator = (props) => (
  <span className={'graphLegendItem'}>
    <Typography>
      <span className='graphLegendIndicator' style={{ backgroundColor: props.color }}></span>
      &nbsp;{props.label}
    </Typography>
  </span>
)
/* eslint-enable */
