import { useDispatch, useSelector } from "react-redux"
import { ZoneWithBehaviours } from "@intelicare/shared/interfaces/household/Zone"
import { useEffect } from "react"
import { MultiNightGuagesView } from "./MultiNightGuagesView"
import { ApplicationState } from "../../../../../interfaces/reduxState"
import { getBehavioursByZoneBegin } from "../../../../../redux/domains/settings/creators"
import { LoadingGauges } from './LoadingGauges'
import { NoZones } from "./NoZones"

interface MultiResidentGuagesSectionProps
{
  behaviours: ZoneWithBehaviours[];
  timezone: string;
}

export const MultiResidentGuagesSection = ({ behaviours, timezone }: MultiResidentGuagesSectionProps) =>
{

  const householdId = useSelector(({ householdsV2 }: ApplicationState) => householdsV2.activeHouseholdId)
  const zonesWithBehaviours = useSelector(({ settings }: ApplicationState) => settings.zones)
  const isGetting = useSelector(({ settings }: ApplicationState) => settings.isGetting)

  const dispatch = useDispatch();


  useEffect(() => {

    dispatch(getBehavioursByZoneBegin(householdId, "getAndSortByZone"))

  }, [])
  return(
    <div>
      {
        isGetting && <LoadingGauges />
      }

      {
        !isGetting && zonesWithBehaviours && zonesWithBehaviours.length > 0 && zonesWithBehaviours.map(zone => {
          return (<MultiNightGuagesView behaviours={zone.behaviours} timezone={timezone} friendlyName={zone.friendlyName} />)
        })
      }

      {
        !isGetting && zonesWithBehaviours && zonesWithBehaviours.length === 0 && <NoZones />
      }
    </div>
  )
}