import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SystemView from '../cards/SystemView'
import SensorsState from '../../../../components/SensorsState/container/SensorsState'
import { useEffect } from 'react'
import { getZonesBegin } from '../../../../redux/domains/zones/actionCreators'
import { ApplicationState } from '../../../../interfaces/reduxState'
import { LoadingSystemHealthTab } from './LoadingSystemHealthTab'
interface SystemHealthTabProps
{
  isDeveloper: boolean;
  isSupport: boolean;
  activeV2Household: any;
  isFetching: boolean;
  useNewLook: boolean;
  householdId: string;
  hasZones: boolean;
}

export const SystemHealthTab = ({ isDeveloper, isSupport, activeV2Household, isFetching, useNewLook, householdId, hasZones }: SystemHealthTabProps) =>
{

  const dispatch = useDispatch()
  const zones = useSelector(({ zones }: ApplicationState) => zones.zones)
  const isGettingZones = useSelector(({ zones }: ApplicationState) => zones.isGettingZones)


  useEffect(() => {

    if(hasZones) dispatch(getZonesBegin(householdId))

  }, [])

  return(
    <div>
      { isGettingZones ?
        <LoadingSystemHealthTab />
        :
        <>
          <SystemView
            zones={zones}
            hasZones={hasZones}
            useNewLook={useNewLook}
            isDeveloper={isDeveloper}
            isSupport={isSupport}
            id="system"
            aria-label="systemHealth"
            household={activeV2Household}
            isFetching={isFetching}
          />
          <div style={{ marginTop: 12 }}>
            <SensorsState
            // @ts-ignore
              householdId={householdId} />
          </div> </>}

    </div>
  )
}