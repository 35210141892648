import { put, select, takeLatest } from "redux-saga/effects"
import { SettingsApi } from "../../../api/SettingsApi"
import { SettingsActions } from "./actions"
import { getBehavioursByZoneError, getBehavioursByZoneSuccess, getBehavioursError, getBehavioursSuccess, updateBehaviourError, updateBehaviourSuccess } from "./creators"

function* updateBehaviour(action: any): any
{
  try
  {
    const state = yield select((state) => state)
    const settingsApi = new SettingsApi(state.user.token)
    const response = yield settingsApi.updateBehaviour(action.data.householdId, action.data.behaviour);
    yield put(updateBehaviourSuccess(response))
  }
  catch(error)
  {
    yield put(updateBehaviourError(error))

  }

}

function* getBehavioursByZone(action: any): any
{
  try
  {
    const state = yield select((state) => state)
    const settingsApi = new SettingsApi(state.user.token)
    const res = yield settingsApi.getBehaviours(action.data.householdId, action.data.query)
    yield put(getBehavioursByZoneSuccess(res))
  }
  catch(error)
  {
    yield put(getBehavioursByZoneError(error))
  }

}

function* getBehaviours(action: any): any
{
  try
  {
    const state = yield select((state) => state)
    const settingsApi = new SettingsApi(state.user.token)
    const res = yield settingsApi.getBehaviours(action.data.householdId, action.data.query)
    yield put(getBehavioursSuccess(res))
  }
  catch(error)
  {
    yield put(getBehavioursError(error))
  }

}

export function* settingsSaga()
{
  yield takeLatest(SettingsActions.UPDATE_ZONE_BEHAVIOUR_SETTING_BEGIN, updateBehaviour)
  yield takeLatest(SettingsActions.GET_BEHAVIOURS_BY_ZONE_BEGIN, getBehavioursByZone)
  yield takeLatest(SettingsActions.GET_BEHAVIOURS_BEGIN, getBehaviours)
}