/* eslint-disable */
import React, { Component } from 'react'
import access from 'safe-access'
import * as emailValidator from 'email-validator'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import buildComponent from './BasePageConnector'
import FormButtons from '../FormButtons'

const NULLGUIDTEST = /00000000-0000-0000-0000-000000000000_\d+/
const HIDE_SNACK_DURATION = 10000

class PermissionSettings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      carerEmail: '',
      emailError: false,
      confirmRemove: false,
      removingItemId: undefined,
      removingCarerName: undefined,
      removingCarerEmail: undefined,
      showSnack: false,
      snackSeverity: "success",
      snackMessage: "",
    }

    this.validateEmail = this.validateEmail.bind(this)
    this.addCarer = this.addCarer.bind(this)
    this.removeCarer = this.removeCarer.bind(this)
    this.definatelyRemoveCarer = this.definatelyRemoveCarer.bind(this)
    this.cancelRemove = this.cancelRemove.bind(this)
  }

  componentDidUpdate(lastProps) {
    const { isSaving, addingCarer, removingCarer, activeHousehold, getCarers, carerError } = this.props;
    const { carerEmail } = this.state;
    if (lastProps.isSaving === true && isSaving === false) {
      this.props.setSubmitting(false);
    }
    if (lastProps.addingCarer === true && addingCarer === false) {
      getCarers(activeHousehold.householdId)
      if (!carerError) this.setState({ showSnack: true, snackSeverity: "success", snackMessage: `Successfully added carer ${carerEmail} to the household`})
      if(carerError) this.setState({ showSnack: true, snackSeverity: "error", snackMessage: `Looks like there was an issue adding ${carerEmail} to the household. Please try again.`})

      this.setState({ carerEmail: '' })

    }
    if (lastProps.removingCarer === true && removingCarer === false) {
      getCarers(activeHousehold.householdId)

      this.setState({ showSnack: true, snackSeverity: "success", snackMessage: `Successfully removed carer from the household.`})
      this.setState(state => ({ removingItemId: undefined }))
    }

  }

  validateEmail(event) {
    const emailValue = access(event, 'target.value')
    const valid = emailValidator.validate(emailValue)
    this.setState(state => ({ ...state, carerEmail: emailValue, emailError: !valid }))
  }

  addCarer() {
    const { addCarer, activeHousehold } = this.props
    const { carerEmail } = this.state
    addCarer(activeHousehold.householdId, carerEmail)
  }

  removeCarer(itemId) {
    this.setState(state => ({
      ...state, confirmRemove: true,
      removingItemId: itemId,
    }))
  }

  definatelyRemoveCarer() {
    const { removeCarer, activeHousehold, values } = this.props
    const { removingItemId } = this.state
    const userId = access(values[removingItemId], 'objectId')
    const removingCarerEmail = access(values[removingItemId], 'email')
    const params = {
      householdId: activeHousehold.householdId,
      userId: NULLGUIDTEST.test(removingItemId) ? undefined : userId,
      email: NULLGUIDTEST.test(removingItemId) ? removingCarerEmail : undefined,
    }
    removeCarer(params)
    this.setState(state => ({
      ...state,
      confirmRemove: false,
      removingCarerEmail: removingCarerEmail,
    }))
  }

  cancelRemove() {
    this.setState(state => ({
      ...state,
      confirmRemove: false,
      removingItemId: undefined,
    }))
  }

  render() {
    const {
      values,
      initialValues,
      classes,
      setFieldValue,
      handleChange,
      fetchingCarers,
      addingCarer,
      removingCarer,
      activeHousehold
    } = this.props
    const {
      carerEmail,
      emailError,
      confirmRemove,
      removingItemId,
      removingCarerEmail,
      showSnack,
      snackSeverity,
      snackMessage,
    } = this.state;

    const removingCarerName = access(values[removingItemId], 'name')

    return (
      <div className={classes.OverlayMain}>
        <div className={classes.OverlaySection}>
          <Grid container direction='column'>
            <Grid item>
              <Grid container direction='row'>
                <Grid item sm={12} md={3}>
                  <Typography variant='h6' className={classes.label}>Add Carer</Typography>
                </Grid>
                <Grid item sm={10} md={8}>
                  <TextField
                    InputProps={{
                      classes: {
                        root: classes.focusedEmail,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                        error: classes.emailError,
                      }
                    }}
                    variant='outlined'
                    margin='dense'
                    type='email'
                    error={emailError}
                    fullWidth placeholder='email'
                    value={carerEmail}
                    onChange={this.validateEmail} />
                </Grid>
                <Grid item sm={2} md={1}>
                  <Button disabled={emailError || addingCarer || carerEmail === ''} onClick={this.addCarer} className={classes.label}>Add</Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction='row'>
                <Grid item sm={false} md={3}>
                </Grid>
                <Grid item sm={12} md={8}>
                  {fetchingCarers && <Progress label="Getting Carers" />}
                  {removingCarer &&  <Progress label={`Removing Carer ${removingCarerEmail}`} />}
                  {addingCarer && <Progress label={`Adding Carer ${carerEmail}`}/>}
                </Grid>
                <Grid item sm={false} md={1}>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction='row'>
                <Grid item sm={12} md={3} style={{ width: '100%', paddingTop: '16px' }}>
                  <Typography variant='h6' className={classes.label}>Carers</Typography>
                </Grid>
                <Grid item sm={12} md={8}>
                  <List className={classes.portalList}>
                    {
                      Object.keys(values).map(k =>
                        <ListItem key={k} disableGutters>
                          <Grid container direction='row' className={classes.listItem} justify='space-between'>
                            <Grid sm={9} item>
                              <Grid container direction='column'>
                                <Grid item>
                                  <Typography>
                                    {values[k].name}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography variant='caption'>
                                    {values[k].email}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid sm={3} item>
                              <Button
                                onClick={() => this.removeCarer(k)}
                                disabled={removingCarer && removingItemId === k}
                                className={classes.fillCell}
                              >Remove</Button>
                            </Grid>
                          </Grid>
                        </ListItem>
                      )
                    }
                  </List>
                </Grid>
                <Grid sm={false} md={1} item>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div >
        <div className={classes.OverlayFooter}>
          <FormButtons {...this.props} />
        </div>
        <Dialog open={confirmRemove} classes={{ root: classes.confirmDialog }}>
          <DialogTitle>
            Are you sure you want to remove carer {removingCarerName} from household {activeHousehold.friendlyName}?
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.cancelRemove}>Cancel</Button>
            <Button onClick={this.definatelyRemoveCarer}>Remove</Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={showSnack}
          autoHideDuration={HIDE_SNACK_DURATION}
          onClose={() => this.setState({ showSnack: false })}>
          <Alert severity={snackSeverity} variant="filled">{snackMessage}</Alert>
        </Snackbar>
      </div>
    )
  }
}

const Progress = ({label}) =>
{
  return(
    <div style={styles.progressContainer}>
      <Typography color="textSecondary">{label}</Typography>
      <CircularProgress color='secondary'/>
    </div>
  )
}

export default buildComponent(PermissionSettings, {
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    const data = access(props, 'activeHousehold.carers.data')
    if (Array.isArray(data)) {
      return data.reduce((p, c, i) => {
        p[`${c.objectId}_${i}`] = { ...c }
        return p
      }, {})
    }
    return {}
  },
  handleSubmit: () => console.log('updating permissions stuff'),
})

const styles = {
  progressContainer: {
    textAlign: 'center',
    paddingBottom: 12,
  }
}
