
export default function handleResource() {
  switch (process.env.REACT_APP_APP_RESOURCE) {
    case 'NATIVE':
      return require('./adalNative')
    case 'WEB':
      return require('./adalWeb')
    default:
      throw new Error(`No adal component found for resource type ${process.env.REACT_APP_APP_RESOURCE}`)
  }
}
