import React, { Component } from 'react'

import './BrandLoader.scss'
const logo = require("./intelicare-logo.png")
export default class BrandLoader extends Component {

  render() {

    const imgStyle = {
      width: '300px',
      height: '300px',
    }

    return (
      <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', minHeight: "100vh" }}>
        <img alt="Loading House Heart"
          className='brand-loader--heart'
          style={imgStyle}
          src={logo}
          automation_attribute="loadingHeart"
        />
      </div>
    )
  }
}
