export default theme => ({
  root: {
    marginBottom: 20,
  },
  SensorStates: {
    display: 'flex',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  gridItem: {
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 16,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: 120,
  },
  StatusLabel: {
    width: 120,
    textAlign: 'center',
  },
  SensorActivityLoader: {
    display: 'flex',
    width: '100%',
    minHeight: '100px',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  SensorActivityEmpty: {
    display: 'flex',
    minHeight: '100px',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
})
