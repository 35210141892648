import { Builder } from "./ApiBuilder";

export type mementoAccess = { [user: string]: AccessRole[]; };

export interface Facility {
  id: string;
  lastUpdated: string;
  friendlyName: string;
  parent: string | undefined;
  composite: boolean;
  children: Facility[] | undefined;
}

export enum AccessRole {
  Administrator = "Administrator",
  BillingOwner = "Billing Owner",
  Carer = "Carer",
  Clinical = "Clinical",
  EscalationUser = "Escalation User",
  Maintainer = "Maintainer",
}

export const accessRoles: Role[] = [
  { name: "Administrator", description: "Has full access to the household" },
  { name: "Billing Owner", description: "Can access billing information" },
  { name: "Carer", description: "Can access user health information" },
  { name: "Clinical", description: "Can access user health information" },
  { name: "Escalation User", description: "Ask Mike I guess" },
  { name: "Maintainer", description: "Has access to system health" },
]

export interface Role
{
  name: string;
  description: string
}

export interface User
{
  email: string;
  roles: AccessRole[] | string[]
}
export interface Household
{
  friendlyName: string;
  householdId: string;
  timezone: string;
  roles: string[];
}

export interface AccessItem
{
  [key: string]: string[];
}

export interface Organisation
{
  id: string;
  friendlyName: string;
  lastUpdated: string;
  access: AccessItem;
  type?: "household" | "organisation";
  parent: string;
  children: any[];
}

export interface UserDetails
{
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

export class OrganisationApi extends Builder
{

  constructor(public token: string)
  {
    super(true);
  }

  listOrganisations = async (): Promise<Organisation[]> =>
  {
    const response = await this.call("GET", "/api/ac/organisations", null, this.token)
    if(response && response.data && response.data.ou) return response.data.ou
    return []
  }

  addHousehold = async (ouId: string, household: Household): Promise<Organisation[]> =>
  {
    const response = await this.call("POST", `/api/ac/ou/${ouId}/addHousehold`, household, this.token)
    if(response && response.data && response.data.ou) return response.data.ou;
    return []
  }

  addUser = async (ouId: string, user: User): Promise<Organisation[]> =>
  {
    const response = await this.call("POST", `/api/ac/ou/${ouId}/addUser`, user, this.token)
    if(response && response.data && response.data.ou) return response.data.ou;
    return []
  }

  removeUser = async (ouId: string, userId: string): Promise<Organisation[]> =>
  {
    const response = await this.call("POST", `/api/ac/ou/${ouId}/removeUser`, { userId }, this.token)
    if(response && response.data && response.data.ou) return response.data.ou;
    return []
  }

  getUser = async (userId: string): Promise<any> =>
  {
    const response = await this.call("GET", `/api/ac/user/${userId}/organisation`, null, this.token)
    if(response && response.data && response.data.ou) return response.data.ou;
  }

  getUserDetails = async (userId: string): Promise<UserDetails | undefined> =>
  {
    const response = await this.call("GET", `/api/ac/user/${userId}`, null, this.token)
    if(response && response.data) return response.data;
    return
  }

  addRole = async (ouId: string, userId: string, roles: string[]): Promise<Organisation | undefined> =>
  {
    const response = await this.call("POST", `/api/ac/ou/${ouId}/addRoles`, { userId, roles }, this.token)
    if(response && response.data && response.data.ou) return response.data.ou;
  }

  removeRole = async (ouId: string, userId: string, roles: string[]): Promise<Organisation | undefined> =>
  {
    const response = await this.call("POST", `/api/ac/ou/${ouId}/removeRoles`, { userId, roles }, this.token)
    if(response && response.data && response.data.ou) return response.data.ou;
  }

  addOrganisation = async (ouId: string, friendlyName: string): Promise<Organisation[]> =>
  {
    const response = await this.call("POST", `/api/ac/ou/${ouId}/add`, { friendlyName }, this.token)
    if(response && response.data && response.data.ou) return response.data.ou;
    return []
  }

  public async deleteNode(ouId: string): Promise<Organisation | undefined> {
    const response = await this.call("POST", `/api/ac/ou/${ouId}/delete`, undefined, this.token);
    if(response && response.data && response.data.ou) return response.data.ou;
  }
}