import Skeleton from '@mui/material/Skeleton'

export const LoadingSystemHealthTab = () =>
{
  return(
    <div>
      <div style={styles.actions}>
        <Skeleton width={30} height={30} animation="wave" variant="circular" style={styles.item} />
        <Skeleton width={30} height={30} animation="wave" variant="circular" style={styles.item} />
        <Skeleton width={30} height={30} animation="wave" variant="circular" style={styles.item} />
        <Skeleton width={30} height={30} animation="wave" variant="circular" style={styles.item} />
        <Skeleton width={30} height={30} animation="wave" variant="circular" style={styles.item} />
      </div>
      <Skeleton height={80} animation="wave" />
      <Skeleton height={80} animation="wave" />
      <Skeleton height={80} animation="wave" />
      <Skeleton height={80} animation="wave" />
      <Skeleton height={80} animation="wave" />
      <Skeleton height={80} animation="wave" />
    </div>
  )

}

const styles = {
  actions: {
    display: 'flex',
    flex: 1,
    justifyContent: "flex-end",
  },
  item: {
    margin: 6,
  },
}