import _ from 'lodash'
import access from 'safe-access'
import { enums } from '@intelicare/shared'

import {
  alert,
  amber,
  okay,
} from './colors'

const { BEHAVIOUR_TYPES, BehaviourClassification, BehaviourClassificationFriendly } = enums

// This is deprecated - use shared enums
export const BehaviourType = BEHAVIOUR_TYPES

export const Classifications = {
  ...BehaviourClassification,
  SYSTEM: 'systemHealth',
  NONE: 'none',
}

export const ClassificationsLabel = {
  ACTIVITY: 'Activity',
  MEAL_PREP: 'Meal Preparation',
  CLIMATE: 'Climate',
  SLEEP: 'Sleep',
  DOMESTIC: 'Domestic Activity',
  PRESENCE: 'Presence',
  SOCIAL: 'Social',
  NONE: 'None',
}

export const Status = {
  NORMAL: 'NORMAL',
  LEARNING: 'LEARNING',
  WARNING: 'WARNING',
  ALERT: 'ALERT',
  HOME: 'Home',
  AWAY: 'Away',
  OKAY: "Okay",
}

export const FriendlyClassificationStatusLabel = {
  NORMAL: 'Okay',
  LEARNING: 'Learning',
  WARNING: 'Check',
  ALERT: 'Alert',
  DISABLED: 'Disabled',
  INITIALISED: "Initialised",
}

export const StatusPriority = {
  'NORMAL': 1,
  'LEARNING': 1,
  'WARNING': 2,
  'ALERT': 3,
}

export const getStatusColor = (status) => {
  switch (status) {
    case Status.NORMAL:
      return okay
    case Status.LEARNING:
      return okay
    case Status.WARNING:
      return amber
    case Status.ALERT:
      return alert
    case Status.HOME:
      return okay
    case Status.AWAY:
      return okay
    case Status.OKAY:
      return okay
    default:
      return "#000000"
  }
}

export const getClassificationIcon = (classification) => {
  switch (classification) {
    case Classifications.ACTIVITY:
      return { name: 'directions_walk', family: 'material' }
    case Classifications.CLIMATE:
      return { name: 'ac_unit', family: 'material' }
    case Classifications.DOMESTIC:
      return { name: 'local_laundry_service', family: 'material' }
    case Classifications.MEAL_PREP:
      return { name: 'restaurant', family: 'material' }
    case Classifications.NONE:
      return { name: 'not_interested', family: 'material' }
    case Classifications.PRESENCE:
      return { name: 'home', family: 'material' }
    case Classifications.SLEEP:
      return { name: 'hotel', family: 'material' }
    case Classifications.SOCIAL:
      return { name: 'people', family: 'material' }
    default:
      return { name: 'not_interested', family: 'material' }
  }
}

export const getColoredClassificationIcon = (classification, color) => {
  const tileIcon = getClassificationIcon(classification)
  return {
    name: tileIcon.name,
    family: tileIcon.family,
    color: color,
  }
}

export const getIcon = (behaviour) => {
  let icon
  switch (behaviour.type) {
    case 'arise':
      icon = { name: 'wb_sunny', family: 'material' }
      break
    case 'nighttimeActivity':
      icon = { name: 'hotel', family: 'material' }
      break
    case 'generalActivity':
      icon = { name: 'directions_walk', family: 'material' }
      break
    case "mealPrep":
      icon = { name: 'restaurant', family: 'material' }
      break
    case "washing":
      icon = { name: 'local_laundry_service', family: 'material' }
      break
    case "climate":
      icon = { name: 'ac_unit', family: 'material' }
      break
    case "presence":
      icon = { name: 'home', family: 'material' }
      break
    case "showerUse":
      icon = { name: 'bath', family: 'awesome' }
      break
    default:
      icon = { name: 'not_interested', family: 'material' }
      break
  }
  icon.color = getBehaviourColorStatus(behaviour, behaviour.type)
  return icon
}

export function getClassificationTile(classification, household) {
  const classificationKey = getClassificationKey(classification)
  const label = BehaviourClassificationFriendly[classificationKey]
  return {
    icon: getClassificationIcon(classification),
    color: getGroupBehaviourColorStatus(household, classification),
    label,
  }
}

function getClassificationKey(classification) {
  for (const key in BehaviourClassification) {
    if (BehaviourClassification[key] === classification) return key
  }
}

export const getFriendlyState = (activeHousehold, classification) => {
  const behaviour = activeHousehold.behaviours.find(b =>
    b.classification === classification
  )
  return access(behaviour, 'friendlyState') || 'Unknown'
}

export const getBehaviourColorStatus = (activeHousehold, type) => {
  if (isStatusWarning(activeHousehold, type))
    return amber
  else if (isStatusAlert(activeHousehold, type))
    return alert
  else
    return okay
}

export const getFriendlyBehaviourStatus = (activeHousehold, type) => {
  const isStatusConcerning = isStatusWarning(activeHousehold, type)
    || isStatusAlert(activeHousehold, type)
  return isStatusConcerning ? 'Check' : 'Okay'
}

export const getBehaviour = (activeHousehold, classification) => {
  if (!activeHousehold.behaviours) return {}
  return activeHousehold.behaviours.find(behaviour =>
    behaviour.classification === classification
  )
}

export const getBehavioursOfType = (activeHousehold, type) => {
  if (!activeHousehold || !activeHousehold.behaviours) return []
  return activeHousehold.behaviours.filter(behaviour =>
    behaviour.type === type
  )
}

export const getFirstBehavioursOfType = (activeHousehold, type) => {
  if (!activeHousehold || !activeHousehold.behaviours) return undefined
  return activeHousehold.behaviours.find(behaviour =>
    behaviour.type === type
  )
}

export const isStatusWarning = (activeHousehold, type) => {
  return _.some(activeHousehold.behaviours, {
    type: type,
    status: {
      value: Status.WARNING,
    },
  })
}

export const isStatusAlert = (activeHousehold, type) => {
  return _.some(activeHousehold.behaviours, {
    type: type,
    status: {
      value: Status.ALERT,
    },
  })
}

export const getGroupBehaviourColorStatus = (activeHousehold, classification) => {
  if (!activeHousehold.behaviours) return 'grey'
  if (isGroupStatusWarning(activeHousehold, classification))
    return amber
  else if (isGroupStatusAlert(activeHousehold, classification))
    return alert
  else
    return okay
}

const getClassificationBehaviours = (behaviours, classification) => _.filter((behaviours || []), behaviour => access(behaviour, 'classification') === classification)
const getActivityBehaviours = behaviours => getClassificationBehaviours(behaviours, Classifications.ACTIVITY)

export const getGroupFriendlyBehaviourStatus = (activeHousehold, classification) => {
  const isStatusConcerning = isGroupStatusWarning(activeHousehold, classification)
    || isGroupStatusAlert(activeHousehold, classification)
  /* Check if there is a single friendly message and display it (only apply to activity for now) */
  if (classification === Classifications.ACTIVITY) {
    if (getActivityBehaviours(activeHousehold.behaviours).length === 1) {
      return access(getActivityBehaviours(activeHousehold.behaviours)[0], 'status.msg') || 'Unknown'
    }
  }
  return isStatusConcerning ? 'Check' : 'Okay'
}

export const isGroupStatusWarning = (activeHousehold, classification) => {
  return !!activeHousehold.behaviours.find(behaviour => {
    if (!behaviour.enabled) return false
    if (behaviour.classification !== classification) return false
    if (behaviour.status.value === Status.WARNING) return true
  })
}

export const isGroupStatusAlert = (activeHousehold, classification) => {
  return !!activeHousehold.behaviours.find(behaviour => {
    if (!behaviour.enabled) return false
    if (behaviour.classification !== classification) return false
    if (behaviour.status.value === Status.ALERT) return true
  })
}
