import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ReactTooltip from "react-tooltip";

import { GsmStatsApi, IGsmRunStatistics } from '../../../../../api/GsmStatsApi';
import { CardItem } from './CardItem';
import Tooltip from '@material-ui/core/Tooltip'
import moment from 'moment'
import { HistoryChart } from './HistoryChart';
import { ErrorList } from './ErroList';
import { LoadingSkeleton } from './LoadingSkeleton';
import MapChart from './MapChart';

const DISPLAY_LAST_48_HOURS_OF_ERRORS = 48;
interface GsmStatisticsTabProps
{
  token: string;
  principal: string
}

export const GsmStatisticsTab = ({ token, principal }: GsmStatisticsTabProps) =>
{
  const [ stats, setStats ] = useState<IGsmRunStatistics[]>([])
  const [ latestStat, setLatestStat ] = useState<IGsmRunStatistics>()

  const [ isLoading, setIsLoading ] = useState(false);

  const [content, setContent] = useState("");

  useEffect(() => {

    async function getStats()
    {
      setIsLoading(true)
      const stats = await new GsmStatsApi(token, principal).getStats(moment().subtract(7, 'days').toISOString(), "DESC")
      if(stats && stats.length > 0)
      {
        setStats(stats)
        setLatestStat(stats[0])
      }

      setIsLoading(false)
    }

    getStats();

  }, [])

  // Used if the run time is less than a minute
  const getRunTimeInSeconds = (latestStat: IGsmRunStatistics | undefined) =>
  {
    try
    {
      if(latestStat && latestStat.runDuration === 0)
      {
        const seconds = moment(latestStat.runEnd).diff(moment(latestStat.runStart), "seconds")
        return `${seconds} seconds`;
      }

      if(latestStat && latestStat.runDuration) return `${latestStat.runDuration} minutes`;

      return "0 minutes"

    }
    catch(error)
    {
      return "0 minutes";
    }
  }



  return(
    <div>

      {!isLoading ? <>
        <Typography style={styles.heading} variant="h3" color="textSecondary">{`General Status Message - Last run ${latestStat && moment(latestStat.runStart).fromNow()}`}</Typography>

        <Grid container spacing={3} justifyContent="space-evenly">

          <Grid item xs={12} lg={4} md={12} sm={12} xl={4}>

            <CardItem
              title="Notifications Processed"
              subTitle={`${latestStat && latestStat.runTimezones.length} Timezones`}
              stat={`${latestStat && latestStat.notificationsProcessed}`}
              color="#AB47BC"
              fontSize={30} />

          </Grid>

          <Grid item xs={12} lg={4} md={12} sm={12} xl={4}>

            <CardItem
              title="Run Duration"
              subTitle={`${latestStat && moment(latestStat.runStart).format("LLLZ")}`}
              stat={getRunTimeInSeconds(latestStat)}
              color="#EC407A" fontSize={20}/>

          </Grid>

          <Grid item xs={12} lg={4} md={12} sm={12} xl={4}>

            <CardItem
              title="Next run time"
              subTitle={`${latestStat && moment(latestStat.nextRunTimestamp).format("LLLZ")}`}
              stat={`${latestStat && moment(latestStat.nextRunTimestamp).fromNow()}`}
              color="#5C6BC0"
              fontSize={16}/>

          </Grid>

        </Grid>



        <Grid container spacing={3} justifyContent="space-evenly">

          <Grid item lg={6} sm={12} md={12} xs={12} xl={6}>
            <Typography style={styles.heading} variant="h3" color="textSecondary">{`Notification history over time`}</Typography>
            <HistoryChart gsmRunStatistics={stats}/>
          </Grid>

          <Grid item lg={6} sm={12} md={12} xs={12} xl={6}>
            <Tooltip title={latestStat ? latestStat.runTimezones.toString() : "No Timezones"}>
              <Typography style={{ ...styles.heading, marginLeft: 10 }} variant="h3" color="textSecondary">{`Last run timezone`}</Typography>
            </Tooltip>
            {/* <GeoChart timezones={latestStat && latestStat.runTimezones}/> */}
            <MapChart timezones={latestStat && latestStat.runTimezones} setTooltipContent={setContent}/>
            <ReactTooltip><div style={styles.toolTip}>{content}</div></ReactTooltip>
          </Grid>

        </Grid>

        <Typography style={styles.heading} variant="h3" color="textSecondary">{`Errors over the last 48 hours`}</Typography>

        <ErrorList gsmRunStatistics={stats.slice(0, DISPLAY_LAST_48_HOURS_OF_ERRORS)} />

      </>: <LoadingSkeleton />}

    </div>
  )
}


const styles = {
  container: {

  },
  heading: {
    marginTop: 12,
    marginBottom: 12,
  },
  toolTip: {
    fontFamily: "Roboto, sans-serif",
  },
}