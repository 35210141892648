import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import Title from '../../../components/Title'
import SearchBar from '../../HouseholdList/components/SearchBar'

import { colors } from '../../../redux'

import styles from './ReportsStyles'
import { appInsights } from "../../../api/AppInsights";

const loader = (
  <Grid container item xs={12} justify='center' alignContent='center'>
    <Typography variant="h5" gutterBottom>Loading ...</Typography>
  </Grid>
)

class ReportsView extends Component {

  constructor(props) {
    super(props)

    this.state = {
      order: undefined,
      searchQuery: '',
    }

    this.getViewReportButton = this.getViewReportButton.bind(this)
  }

  loadItems() {
    const {
      households,
      getHouseholdListNextPage,
    } = this.props

    const {
      searchQuery,
      order,
    } = this.state

    getHouseholdListNextPage(households.length, searchQuery, order)
  }

  componentDidMount()
  {
    appInsights.trackPageView({ name: "Reports" })
  }

  search = (searchQuery, order) => {
    const { purgeHouseholds } = this.props
    purgeHouseholds()
    this.setState({ ...this.state, searchQuery: searchQuery, order: order })
  }

  render() {
    const {
      classes,
      households,
      hasMore,
    } = this.props

    const {
      searchQuery,
    } = this.state

    return (
      <div style={styles.container}>
        <Title
          title='Reports'
          pageButtons={
            <SearchBar search={this.search} lastSearchQuery={searchQuery} />
          } />
        <InfiniteScroll
          pageStart={0}
          loadMore={this.loadItems.bind(this)}
          hasMore={hasMore}
          loader={loader}>
          {households.map(h => (
            <Grid container justify='center' xs={12} key={`household_${h.householdId}`} className={classes.HouseholdRow}>
              <Grid item xs={6}>
                <Typography className={classes.HouseholdLabel}>{h.friendlyName}</Typography>
              </Grid>
              <Grid item container justify='center' xs={6}>
                {this.getViewReportButton(h)}
              </Grid>
            </Grid>
          ))}
        </InfiniteScroll>
      </div>
    )
  }

  getViewReportButton(h) {
    const { classes, reportInfo, getReport, viewReport } = this.props
    if (reportInfo.householdId !== h.householdId) return (
      <Button className={classes.ReportButton} onClick={() => getReport(h.householdId)}>Get Latest</Button>
    )
    if (reportInfo.fetchingReport) return (<CircularProgress color={colors.brand} className={classes.progress} />)
    return (<Button className={classes.ReportButton} onClick={viewReport}>View</Button>)
  }

  static propTypes = {
    classes: PropTypes.any,
    households: PropTypes.array,
    getReport: PropTypes.func,
    reportInfo: PropTypes.any,
    viewReport: PropTypes.func,
    hasMore: PropTypes.bool,
    getHouseholdListNextPage: PropTypes.func,
    purgeHouseholds: PropTypes.func,
  }
}

export default withStyles(styles)(ReportsView)
