import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import clsx from 'clsx'



import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
} from '@material-ui/core'

import LinearProgress from '@material-ui/core/LinearProgress';


import { withStyles } from '@material-ui/core/styles'

import access from 'safe-access'

// Redux
import { callAPI } from '../../redux/domains/user/userStore'
import { apiEndpoints } from '../../redux'
import { validateText } from '../../helpers/textValidation'

import ReactList from 'react-list'

import ActiveAlert from './ActiveAlert'
import './ActiveAlerts.scss'

import moment from 'moment'

const GET_ALL_ACTIVE_ALERTS = apiEndpoints.GET_ALL_ACTIVE_ALERTS




class ActiveAlerts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      allowSubmit: true,
      activeAlert: undefined,
      minimised: false,
      resolutionType: undefined,
      resolutionNote: undefined,
      showInputError: false,
    }
  }

  static propTypes = {
    createAction: PropTypes.func.isRequired,
    resolve: PropTypes.func.isRequired,
    setAlertsMinimised: PropTypes.func.isRequired,
    goToHousehold: PropTypes.func.isRequired,
    alerts: PropTypes.number,
    isDeveloper: PropTypes.bool.isRequired,
    isSupport: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    activeAlertNotifications: PropTypes.any,
    isGettingActiveAlerts: PropTypes.bool,
    activeAlertNotificationsError: PropTypes.any,
    getActiveAlerts: PropTypes.func,
    open: PropTypes.bool,
    messageSending: PropTypes.bool,
  }

  getActions = () => {

    const { messageSending } = this.props
    let buttons = [
      <Button
        key={1}
        color="primary"
        onClick={() => { this._minimise(true) }}
      >Go to alerts</Button>,
      <Button
        key={2}
        color="primary"
        onClick={() => { this._minimise(false) }}
      >View household</Button>,
      <Button
        key={3}
        color="primary"
        onClick={this._submit}
      >{messageSending ? "Resolving..." : "Submit"}</Button>,
    ]
    if (this.props.isDeveloper || this.props.isSupport) {
      buttons.push(<Button
        key={4}
        color="primary"
        onClick={this._developerHide}
      >Close</Button>
      )
    }
    return buttons
  }

  _getSelectedAlert = () => {
    return this.state.activeAlert || this.props.activeAlertNotifications[0]
  }

  _developerHide = () => {
    const {
      setAlertsMinimised,
    } = this.props

    setAlertsMinimised(true)
    this.setState({ minimised: true })

    document.body.classList.toggle('minimised-modal', true)
  }

  _minimise = (gotoAlerts = false) => {
    const {
      setAlertsMinimised,
      goToHousehold,
    } = this.props

    let selectedAlert = this._getSelectedAlert()

    setAlertsMinimised(true)
    this.setState({ minimised: true })

    document.body.classList.toggle('minimised-modal', true)

    setTimeout(() => {
      goToHousehold(selectedAlert.householdId, gotoAlerts ? 'notifications' : undefined)
    }, 500)
  }

  _restore = () => {
    const {
      setAlertsMinimised,
    } = this.props

    document.body.classList.toggle('minimised-modal', false)
    setAlertsMinimised(false)
    this.setState({ minimised: false })
  }

  _viewAlert = (alert) => {
    const {
      activeAlert,
    } = this.state
    if (activeAlert && activeAlert.id !== alert.id) {
      this._resetSelection()
    }

    this.setState({ activeAlert: alert })
    this._updateResolutionNote(null, undefined)
    this._updateResolutionType(null, undefined)
  }

  _renderItem = (index, key) => {
    const {
      activeAlertNotifications,
      classes,
    } = this.props

    let itemAlert = activeAlertNotifications[index]

    if (!itemAlert) {
      return
    }

    let selectedAlert = this._getSelectedAlert()

    return (
      <div
        automation_attribute="alertItem"
        key={key}
        className={clsx(classes.dialogListItem, selectedAlert.id === itemAlert.id ? classes.dialogListItemSelected : undefined)}
        onClick={() => this._viewAlert(itemAlert)}>
        <Typography>{moment(itemAlert.timestamp).format("LLL")}</Typography>
        <Typography color="textPrimary">{itemAlert.householdFriendlyName}</Typography>
        <Typography color="textSecondary">{access(itemAlert, 'recipients[0].message')}</Typography>
      </div>
    )
  }

  _updateResolutionType = (elm, resolutionType) => {
    this.setState({
      resolutionType: resolutionType,
    })
  }

  _updateResolutionNote = (elm, resolutionNote) => {
    this.setState({ resolutionNote: validateText(resolutionNote) })
  }

  _resetAll = () => {
    this.setState({
      resolutionType: undefined,
      resolutionNote: undefined,
      activeAlert: undefined,
    })
  }

  _resetSelection = () => {
    this.setState({
      resolutionType: undefined,
      resolutionNote: undefined,
      activeAlert: undefined,
    })
  }

  _submit = () => {

    const {
      resolve,
    } = this.props

    const {
      resolutionType,
      resolutionNote,
    } = this.state

    const resolution = {
      type: resolutionType,
      message: resolutionNote,
    }

    let selectedAlert = this._getSelectedAlert()

    // Perform some form validation
    if (!resolutionType || !resolutionNote)
    {
      this.setState({ showInputError: true })
      return
    }

    // Append the resolution note to the notification object
    selectedAlert.resolution = resolution

    // Remove the recipients
    delete selectedAlert.recipients

    const params = {
      notification: selectedAlert,
    }

    resolve(params)

    this._resetAll()
  }

  getAlerts = () =>
  {
    const { getActiveAlerts } = this.props
    getActiveAlerts({ type: "ALERT", status: "ACTIVE", offset: 0, limit: 50, order: "DESC" })
  }

  render() {
    const {
      classes,
      activeAlertNotifications,
      open,
      isGettingActiveAlerts,
      messageSending,
      setAlertsMinimised,
      isDeveloper,
      isSupport,
    } = this.props

    const {
      resolutionType,
      resolutionNote,
      showInputError,
    } = this.state

    let selectedAlert = this._getSelectedAlert()
    return (
      <Dialog
        onEnter={() => this.getAlerts()}
        open={open}
        onClose={this._resetAndClose}
        fullWidth={true}
        maxWidth='lg'
        classes={{ root: classes.dialog }}
      >
        <div automation_attribute="alertDialog">
          <DialogTitle disableTypography={true}>
            <Typography variant="h5" classes={{ root: classes.title }}>Active Alerts</Typography>
          </DialogTitle>
          <DialogContent
            classes={{ root: classes.dialogContentRoot }}>
            <Grid container direction="row">
              <Grid item xs={3}>
                {/* The following div makes scrolling in ReactList work */}
                <div style={{ overflow: 'auto', maxHeight: '50vh' }}>
                  <ReactList
                    itemRenderer={this._renderItem}
                    length={(activeAlertNotifications || []).length}
                  />
                </div>
              </Grid>
              <Grid item xs classes={{ item: classes.notificationContainer }}>
                <ActiveAlert
                  isGettingActiveAlerts={isGettingActiveAlerts}
                  showInputError={showInputError}
                  alert={selectedAlert}
                  setAlertsMinimised={setAlertsMinimised}
                  updateResolutionType={this._updateResolutionType}
                  updateResolutionNote={this._updateResolutionNote}
                  resolutionType={resolutionType}
                  resolutionNote={resolutionNote}
                  isDeveloper={isDeveloper}
                  isSupport={isSupport}
                />
              </Grid>

            </Grid>
          </DialogContent>
          <DialogActions>
            {this.getActions()}
          </DialogActions>
        </div>

        {messageSending && <LinearProgress color="secondary" />}


      </Dialog >
    )
  }
}

const mapStateToProps = state =>
{
  return {
    activeAlertNotifications: state.notifications.activeAlertNotifications,
    activeAlertNotificationsError: state.notifications.activeAlertNotificationsError,
    isGettingActiveAlerts: state.notifications.isGettingActiveAlerts,
    messageSending: state.notifications.messageSending,
    isDeveloper: state.user.isDeveloper,
    isSupport: state.user.isSupport,
  }
}

const mapDispatchToProps = dispatch =>
{
  return {
    getActiveAlerts: (params) => dispatch(callAPI(GET_ALL_ACTIVE_ALERTS(params))),
  }
}
const styles = theme => ({
  dialog: {
    transform: 'translateZ(0)',
  },
  title: {
    color: 'white',
  },
  dialogContentRoot: {
    padding: 0,
    maxHeight: '50vh',
    overflow: 'hidden',
  },
  dialogListItem: {
    padding: 15,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgba(0, 0, 0, 0.2)',
    background: 'white',
  },
  dialogListItemSelected: {
    background: theme.palette.secondary.main,
  },
  notificationContainer: {
    padding: 40,
  },
})



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ActiveAlerts))
