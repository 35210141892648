import { Room } from "@intelicare/shared/interfaces/gateway/Gateway";
import { ISensor } from "@intelicare/shared/interfaces/household/ISensor";
import { ZoneApiResponseItem } from "@intelicare/shared/interfaces/household/Zone";
import { Builder } from "./ApiBuilder";

export class ZoneApi extends Builder
{
  constructor(public token: string)
  {
    super(true)
  }

  createZone = async (householdId: string, zone: ZoneApiResponseItem): Promise<ZoneApiResponseItem | undefined> =>
  {
    const response = await this.call("POST", `/api/zones/${householdId}`, zone, this.token)
    if(response && response.data) return response.data
  }

  getZones = async (householdId: string): Promise<ZoneApiResponseItem[]> =>
  {
    const response = await this.call("GET", `/api/zones/${householdId}`, null, this.token)
    if(response && response.data) return response.data
    return []
  }

  updateZone = async (householdId: string, zone: ZoneApiResponseItem): Promise<ZoneApiResponseItem | undefined> =>
  {
    const response = await this.call("PATCH", `/api/zones/${householdId}`, zone, this.token)
    if(response && response.data) return response.data
  }

  removeZone = async (householdId: string, zoneId: string): Promise<string> =>
  {
    const response = await this.call("DELETE", `/api/zones/${householdId}?zoneId=${zoneId}`, null, this.token)
    if(response && response.data) return response.data
    return ""
  }

  removeRoom = async (householdId: string, roomId: string): Promise<{msg: string} | undefined> =>
  {
    const response = await this.call("DELETE", `/api/zones/${householdId}?roomId=${roomId}`, null, this.token)
    if(response && response.data) return response.data
    return undefined
  }

  updateRoomName = async (householdId: string, room: Room): Promise<Room | undefined> =>
  {
    const response = await this.call("PATCH", `/api/zones/${householdId}`, room, this.token)
    if(response && response.data) return response.data
    return undefined
  }

  createRoom = async (householdId: string, room: Room): Promise<Room | undefined> =>
  {
    const response = await this.call("POST", `/api/zones/${householdId}`, room, this.token)
    if(response && response.data) return response.data
    return undefined
  }

  addSensorToRoom = async (householdId: string, nodeid: string, roomid: string): Promise<{msg: string, roomid: number, sensors: ISensor[]} | undefined> =>
  {
    const response = await this.call("POST", `/api/zones/${householdId}?nodeid=${nodeid}&roomid=${roomid}`, {}, this.token)
    if(response && response.data) return response.data
    return undefined
  }

  removeSensorFromRoom = async (householdId: string, nodeid: string): Promise<{msg: string, nodeid: number} | undefined> =>
  {
    const response = await this.call("DELETE", `/api/zones/${householdId}?nodeid=${nodeid}`, {}, this.token)
    if(response && response.data) return response.data
    return undefined
  }
}

