import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import ConfirmDeleteHouseholdModal from '../../routes/DeveloperConsole/GatewayFirmware/components/FirmwareList/ConfirmDeleteHouseholdModal'

import {
  Button,
  CircularProgress,
  Snackbar,
} from '@material-ui/core'

const snackbarPosition = {
  horizontal: 'center',
  vertical: 'bottom',
}

export default class FormButtons extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showError: false,
      showSuccess: false,
      showConfirmDelete: false,
    }

    this.clearError = this.clearError.bind(this)
    this.clearSuccess = this.clearSuccess.bind(this)
  }

  componentDidUpdate(lastProps) {
    const { isSaving, saveError } = this.props
    if (lastProps.isSaving === true && isSaving === false) {
      if (saveError !== undefined) {
        this.setState(state => ({ ...state, showError: true }))
      } else {
        this.setState(state => ({ ...state, showSuccess: true }))
      }
    }
  }

  clearError() {
    this.setState(state => ({ ...state, showError: false }))
  }

  clearSuccess() {
    this.setState(state => ({ ...state, showSuccess: false }))
  }

  render() {
    const {
      close,
      handleSubmit,
      handleReset,
      isSubmitting,
      dirty,
      activeHousehold,
      activeHouseholdSettings,
      isDeveloper,
      isSupport,
      isSuccess,
      errors,
    } = this.props
    const hasErrors = errors && Object.keys(errors).length > 0;
    const { showError, showSuccess, showConfirmDelete } = this.state
    const { friendlyName, householdId } = activeHousehold
    const { gatewayId } = activeHouseholdSettings;
    return (
      <div>
        {( isDeveloper || isSupport) && <IconButton color="secondary" onClick={() => this.setState({ showConfirmDelete: true })}><DeleteIcon /></IconButton> }

        {/* <footer> */}
        <span><Button color="secondary" onClick={handleReset} disabled={isSubmitting || !dirty}>Discard</Button></span>
        <span>{dirty &&
          <Button startIcon={isSubmitting && <CircularProgress size={18} />} color="primary" onClick={handleSubmit} disabled={isSubmitting || !dirty || hasErrors}>Save</Button>
        }</span>
        <span><Button color="primary" onClick={close} disabled={dirty && !isSubmitting}>Close</Button></span>
        {/* </footer> */}
        <Snackbar
          anchorOrigin={snackbarPosition}
          open={isSubmitting && isSuccess==undefined}
          autoHideDuration={6000}
          message={<span>Saving changes to household settings...</span>}
        />
        <Snackbar
          anchorOrigin={snackbarPosition}
          open={showError}
          autoHideDuration={6000}
          message={<span>Unable to save changes to household settings!</span>}
          onClose={this.clearError}
        />
        <Snackbar
          anchorOrigin={snackbarPosition}
          open={showSuccess}
          autoHideDuration={6000}
          message={<span>Successfully updated household settings.</span>}
          onEnter={handleReset}
          onClose={this.clearSuccess}
        />
        {friendlyName && <ConfirmDeleteHouseholdModal row={{ friendlyName, householdId, gatewayId }} open={showConfirmDelete} handleClose={() => this.setState({ showConfirmDelete: false })}/>}
      </div>
    )
  }

  static propTypes = {
    close: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    saveError: PropTypes.object,
    activeHousehold: PropTypes.object,
    activeHouseholdSettings: PropTypes.object,
    isDeveloper: PropTypes.bool,
    isSupport: PropTypes.bool,
    isSuccess: PropTypes.bool,
    isShowError: PropTypes.bool,
    showEnableError: PropTypes.func.isRequired,
    errors: PropTypes.bool,
  }
}