import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useMsal, useAccount } from "@azure/msal-react";
import { locationChange } from "../redux/domains/location/locationStore";
import { b2cPolicies } from "../msal/config";
import moment from 'moment'
import BrandLoader from "../components/BrandLoader";
export const PASSWORD_RESET_ERROR_CODE = "AADB2C90118";
export const PASSWORD_RESET_CANCELLED_CODE= "AADB2C90091"
const KEY_AUTH_DATA = "KEY_AUTH_DATA"
interface AuthProviderProps {
  storeToken(params: any): void;
  locationChange(location: string): void;
  isAuthenticated(value: boolean): void;
}

export const handlePasswordReset = () => {
  if (window.location.href.includes(PASSWORD_RESET_ERROR_CODE)) {
    window.location.href = b2cPolicies.authorities.forgotPassword.authority;
  }

  if(window.location.href.includes(PASSWORD_RESET_CANCELLED_CODE))
  {
    window.location.href = `https://app.${process.env.REACT_APP_ENVIRONMENT}.intelicare.io`
  }

  return <BrandLoader />;
};

const isDevMode = () =>
{
  if(process.env.REACT_APP_ENVIRONMENT === "dev" &&  window.location.href.includes("http://localhost:3000")) return true;
  return false
}

const tokenHasExpired = (parsedAuth: {exp: number}) =>
{
  return moment(parsedAuth.exp).isAfter(moment())
}

const AuthProvider = ({ storeToken, isAuthenticated }: AuthProviderProps) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const handleStoreToken = async () => {
    try {

      // Dev mode token cache speed up
      if(isDevMode())
      {
        const cachedDecodedToken = localStorage.getItem(KEY_AUTH_DATA)
        if(cachedDecodedToken)
        {
          const parsedAuth = JSON.parse(cachedDecodedToken)
          if(!tokenHasExpired(parsedAuth))
          {
            // Token has expired
            console.log("Using cached auth")
            isAuthenticated(true);
            storeToken({ decodedToken: parsedAuth });
            locationChange("/#");
            return
          }
          console.log("Token expired...going to refresh")
        }
      }

      await instance.handleRedirectPromise();
      // If we don't have an account it's handled by the MsalAuthenticationTemplate HOC in AppContainer
      if (account) {
        console.log("We have an account");
        try {
          const response = await instance.acquireTokenSilent({
            scopes: ["openid"],
            account: account,
          });
          if (response) {
            if (response.idToken) {
              console.log("We have an idToken");
              // Callback so we render all the routes
              isAuthenticated(true);
            }
            // Store token in Redux
            const decodedToken = {
              ...response.idTokenClaims,
              token: response.idToken,
            };
            storeToken({ decodedToken: decodedToken });

            // Store the token locally
            isDevMode() && localStorage.setItem(KEY_AUTH_DATA, JSON.stringify(decodedToken))

            locationChange("/#");
          }
        } catch (error) {
          console.log("Error getting token silent", error);
          // Clear out the session storage
          sessionStorage.clear();
          window.location.reload();
        }
      } else {
        console.warn("No Account");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleStoreToken();
  }, [account, instance]);

  return <></>;
};

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    storeToken: (details: any) => dispatch({ type: "LOGIN_START", details: details }),
    locationChange: (location: string) => dispatch(locationChange(location)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider);
