import { connect } from 'react-redux'
import View from './ActivityGraphStackedView'
import sharedStore from '../../redux'
const getActivityGraphData = sharedStore.householdsV2Selector.getActivityGraphData
const getAverageActivityData = sharedStore.householdsV2Selector.getAverageActivityGraphData
const getActiveHousehold = sharedStore.householdsV2Selector.getActiveHousehold
const callAPI = sharedStore.userStore.callAPI

const ACTIVITYGRAPH = sharedStore.apiEndpoints.ACTIVITYGRAPH
const AVERAGEACTIVITY = sharedStore.apiEndpoints.AVERAGEACTIVITY


const mapDispatchToProps = (dispatch) => {
  return {
    fetchActivityGraphData: (householdId, period) => {
      dispatch(callAPI(ACTIVITYGRAPH({
        timePeriod: period,
        householdId,
      })))
      dispatch(callAPI(AVERAGEACTIVITY({
        timePeriod: 168,
        householdId,
      })))
    },
  }
}

const mapStateToProps = (state) => ({
  data: getActivityGraphData(state).data,
  averageData: getAverageActivityData(state),
  isFetching: getActivityGraphData(state).isFetching,
  household: getActiveHousehold(state).data,
})

export default connect(mapStateToProps, mapDispatchToProps)(View)
