import React from 'react'
import ListItem from '@mui/material/ListItem';
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import './list.css'
import { teal } from '@mui/material/colors';
import { NoRolesView } from './NoRolesView';
import { Organisation } from '../../../../../api/OrganisationApi';
import { getInitials } from '../utils/getInitials';

interface AssignedRolesListProps
{
  roles: string[];
  selectedItem: Organisation | undefined;
  onItemRemove: (item: any) => void;
  onAssignRole: () => void;
}

export const AssignedRolesList = ({ roles, selectedItem, onItemRemove, onAssignRole }: AssignedRolesListProps) =>
{
  return(
    <div style={styles.container} >
      <Button disabled={selectedItem ? false : true} onClick={onAssignRole} startIcon={<AddIcon />}>Assign Roles</Button>
      <List className="scrollBar" style={styles.list}>

        {
          roles && roles.length > 0 ? roles.map((role, key) => {
            return <Card key={key} style={styles.containerCard}>

              <ListItem
                secondaryAction={
                  <IconButton
                    onClick={() => onItemRemove(role)}
                    edge="end"
                    aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                } >
                <ListItemAvatar>
                  <Avatar style={styles.avatar}>
                    {getInitials(role)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={role}
                  secondary="assigned role"
                />
              </ListItem>
            </Card>

          })  : <NoRolesView selectedItem={selectedItem} />
        }



      </List>
    </div>
  )
}

const styles = {
  containerCard: {
    margin: 8,
  },
  avatar: {
    backgroundColor: teal[500],
    justifyContent: 'center',
  },
  list: {
    height: 400,
    overflowY: 'scroll' as 'scroll',
  },
  container: {
    textAlign: 'center' as 'center',
  },
}