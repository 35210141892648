// Generic add modal
import { useState } from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

interface AddRoomModalProps
{
  title: string;
  open: boolean;
  close: () => void;
  contentText: string;
  inputBoxDescription: string;
  confirmAction: (data: string) => void;
  dropDownItems?: string[];
}

const filter = createFilterOptions<string>();


export const AddRoomModal = ({ title, open, close, confirmAction, contentText, inputBoxDescription, dropDownItems }: AddRoomModalProps) =>
{
  // Hook to validate text input and store the input value
  const [ data, setData ] = useState("")

  return(
    <Dialog open={open} onClose={close} TransitionProps={{ onEntering: () => setData("") }} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {contentText}
        </DialogContentText>
        <Autocomplete
          value={data}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              setData(newValue);
            } else if (newValue) {
              // Create a new value from the user input
              setData(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option);
            if (inputValue !== '' && !isExisting) {
              filtered.push(inputValue);
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={dropDownItems || []}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option) {
              return option;
            }
            // Regular option
            return option;
          }}
          renderOption={(props, option) => <li {...props}>{option}</li>}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} label="Enter a new room name or choose from existing non zone rooms" />
          )}
        />


      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button onClick={() => confirmAction(data)} >Save</Button>
      </DialogActions>
    </Dialog>
  )
}