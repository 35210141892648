// @ts-nocheck
import React, { Component } from 'react'
import moment from 'moment-timezone'
import { GraphDataV2 } from '../cards/Presence/util/outingsGraphFormatter'
import { NotHomeHistory } from '../cards/Presence/util/getPresenceBehaviour'
import Typography from '@material-ui/core/Typography'

const colors = ['rgb(31,120,180)', 'rgb(138, 197, 109)', 'rgba(113, 126, 210, 0.5)', 'rgb(102, 42, 140)']

const MAX_DATA_POINTS = 7

const TextColor = "grey"


interface OutingPeriodsGraphProps
{
  outingHistory: NotHomeHistory[];
  householdTz: string;
}
export class OutingPeriodGraph extends Component<OutingPeriodsGraphProps> {

  state = {
    dimensions: {
      width: 1000,
      height: 500,
    },
  }

  componentDidMount() {
    this.setState({ dimensions: { width: window.innerWidth - 700, height: 500 } })

    window.addEventListener('resize', () => this.setState({ dimensions: { width: window.innerWidth - 700, height: 500 } }))
  }

  componentWillUnmount()
  {
    window.removeEventListener('resize', () => {})
  }

  getMinuteAsDecimal(minute: number) {
    return minute / 60
  }

  convertToGraphData(outingHistory: NotHomeHistory[], householdTimezone: string) {
    if (!householdTimezone) householdTimezone = 'GMT'
    const graphData: GraphDataV2[] = []
    // Convert outing start and end to moments
    for (let i = 0; i < outingHistory.length; i++) {
      outingHistory[i] = {
        start: moment(outingHistory[i].start).tz(householdTimezone),
        end: moment(outingHistory[i].end).tz(householdTimezone),
      }
    }

    // Create daily record
    const rollingDate = moment().tz(householdTimezone).subtract(MAX_DATA_POINTS - 1, "days").startOf('day')
    for (let i = 0; i < (MAX_DATA_POINTS); i++) {
      const dayOutings = {
        id: i,
        date: rollingDate.clone(),
        shortDate: rollingDate.format('DD/MM').toString(),
        outings: [],
        outingTotal: 0,
      }
      graphData.push(dayOutings)
      rollingDate.add(1, "day")
    }

    for (let i = 0; i < outingHistory.length; i++) {
      const outingStart = outingHistory[i].start
      const outingEnd = outingHistory[i].end
      // Outing was same day
      const itemIndex = graphData.findIndex((o) => o.date.isSame(outingStart, 'day'))
      if (itemIndex === -1) continue
      if (outingStart.isSame(outingEnd, 'day')) {
        if (graphData[itemIndex]) graphData[itemIndex].outings.push({
          startHour: (outingStart.hour() + this.getMinuteAsDecimal(outingStart.minute())),
          endHour: (outingEnd.hour() + this.getMinuteAsDecimal(outingEnd.minute())),
        })
        continue
      }
      // Outing was overnight or longer
      if (graphData[itemIndex]) {
        graphData[itemIndex].outings.push({
          startHour: (outingStart.hour() + this.getMinuteAsDecimal(outingStart.minute())),
          endHour: 24,
        })
      }

      let j = itemIndex + 1
      if (j >= graphData.length) continue
      let date = graphData[j].date;
      while (outingEnd.isAfter(date.clone().endOf('day'), 'minutes')) {
        graphData[j].outings.push({
          startHour: 0,
          endHour: 24,
        })
        j++
        if (j > graphData.length - 1 || !graphData[j]) break
        date = graphData[j].date
      }

      if (outingEnd.isSameOrBefore(date.clone().endOf('day')) && graphData[j]) {
        graphData[j].outings.push({
          startHour: 0,
          endHour: (outingEnd.hour() + this.getMinuteAsDecimal(outingEnd.minute())),
        })
      }
    }
    return graphData
  }


  render() {
    const {
      outingHistory,
      householdTz,
    } = this.props
    if (!outingHistory) return <div></div>


    const graphLeftMargin = 60
    const graphBottomMargin = 150

    const { width, height } = this.state.dimensions
    const graphHeight = height - graphBottomMargin
    const graphWidth = width - graphLeftMargin
    const columnSize = (graphWidth) / MAX_DATA_POINTS

    const graphData = this.convertToGraphData(outingHistory, householdTz)
    let longestOuting = 4 // Set a minimum for the graph
    const graphDivision = 12
    graphData.forEach((o) => { if (o.outingTotal > longestOuting) longestOuting = o.outingTotal })

    return (
      <div style={styles.container} >
        {graphData.length === 0 ?
          <Typography>No outings</Typography> :
          <div style={styles.container} >
            {this.state.dimensions ?
              <svg height={height} width={width}>
                <rect
                  height={(graphHeight / 4)}
                  width={graphWidth - 5}
                  x={graphLeftMargin}
                  y={0}
                  fill='#e1e1e1'
                />
                <rect
                  height={(graphHeight / 4)}
                  width={graphWidth - 5}
                  x={graphLeftMargin}
                  y={graphHeight * 0.75}
                  fill='#e1e1e1'
                />
                {/* Graph grid lines */}
                {(() => {
                  const lines = []
                  for (let i = 0; i < graphDivision; i++) {
                    lines.push(<line
                      key={i}
                      x1={graphLeftMargin}
                      x2={width - 5}
                      y1={i * (graphHeight / graphDivision)}
                      y2={i * (graphHeight / graphDivision)}
                      stroke='#f4f4f4'
                    />)
                  }
                  return lines
                })()}

                {/* Graph bars */}
                {graphData.map((d) => {
                  // Create stacked column
                  const column = []
                  const colorId = 0

                  const hourDivision = graphHeight / 24

                  d && d.outings && d.outings.forEach((e, i) => {
                    const barHeight = (e.endHour - e.startHour) * hourDivision
                    // let barHeight = -1 * ((e.endHour - e.startHour) * hourDivision)
                    column.push(<rect
                      key={`${d.id}-${i}`}
                      height={barHeight}
                      width={columnSize - 5}
                      x={(d.id * columnSize) + graphLeftMargin}
                      // y={((e.startHour) * hourDivision) - barHeight}
                      y={e.startHour * hourDivision}
                      fill={colors[(colorId + i)%2]}
                    />)
                  })
                  return column
                })}

                {/* Graph outing time of day labels */}
                {(() => {
                  const fontSize = 12
                  const hoursText = []
                  const timeInterval = 24 / graphDivision
                  for (let i = 0; i <= graphDivision; i++) {
                    let timeHour = i*timeInterval
                    const ampmString = timeHour < 12 || timeHour == 24 ? 'am' : 'pm'
                    timeHour = timeHour % 12 == 0 ? 12 : timeHour % 12
                    hoursText.push(
                      <text
                        key={i}
                        x={0}
                        y={i === 0 ? fontSize : (i * (graphHeight / graphDivision)) + (fontSize / 2)}
                        fontSize={fontSize}
                        fill={TextColor}
                      >
                        {timeHour + ampmString}
                      </text>
                    )
                  }
                  return hoursText
                })()}

                {/* Graph bar dates */}
                {(() => {
                  const column = []
                  const fontSize = 12
                  graphData.forEach((d) => {
                    column.push(
                      <text
                        key={d.id}
                        x={(d.id * columnSize) + graphLeftMargin}
                        y={graphHeight + (graphBottomMargin * 0.4)}
                        fontSize={fontSize}
                        fill={TextColor}
                        transform={`rotate(-90 ${(d.id * columnSize) + graphLeftMargin + (columnSize * 0.5) + 2} ${graphHeight + (graphBottomMargin * 0.4)})`}
                      >
                        {d.id === (graphData.length - 1) ? 'Today' : d.shortDate}
                      </text>
                    )
                  })
                  return column
                })()}

              </svg>
              : undefined}
          </div>
        }
      </div>
    )
  }


}

const styles = {
  container: {
    flex: 1,
    fontFamily: 'Roboto, sans-serif',
  },
  graphTitle: {
    flex: 0,
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'Source Sans Pro, sans-serif',
    paddingBottom: 6,
  },
}