import { connect } from 'react-redux'
import OverallActivityGraphView from '../components/OverallActivityGraphView'
import sharedStore from '../../../redux'
const getOverallActivityGraphData = sharedStore.householdsV2Selector.getOverallActivityGraphData
const getActiveHousehold = sharedStore.householdsV2Selector.getActiveHousehold
const callAPI = sharedStore.userStore.callAPI
const OVERALLACTIVITYGRAPH = sharedStore.apiEndpoints.OVERALLACTIVITYGRAPH


const mapDispatchToProps = (dispatch) => {
  return {
    fetchOverallActivityGraphData: (householdId, period) => {
      dispatch(callAPI(OVERALLACTIVITYGRAPH({
        householdId,
        timePeriod: period,
      })))
    },
  }
}

const mapStateToProps = (state) => ({
  data: getOverallActivityGraphData(state).data,
  isFetching: getOverallActivityGraphData(state).isFetching,
  timezone: getActiveHousehold(state).timezone,
})

export default connect(mapStateToProps, mapDispatchToProps)(OverallActivityGraphView)
