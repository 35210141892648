import { compose } from 'redux'
import { connect } from 'react-redux'
import access from 'safe-access'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import {
  withFormik,
} from 'formik'

import Styles from '../HouseholdSettingsStyles'
import { PortalOverlayStyles } from '../../../components/PortalOverlay'

import sharedStore from '../../../redux'
const callAPI = sharedStore.userStore.callAPI
const {
  HOUSEHOLD_SETTING_BULK,
  HOUSEHOLD_AVATAR_UPLOAD,
  HOUSEHOLD_SETTING_UPDATE,
  HOUSEHOLD_CARER_LIST,
  HOUSEHOLD_CARER_ADD,
  HOUSEHOLD_CARER_REMOVE,
} = sharedStore.apiEndpoints
const {
  updateBehaviourSetting,
  updateAvatar,
} = sharedStore.householdsV2Store



function getActiveHousehold(state) {
  return state.householdsV2.households.find(h => h.householdId === state.householdsV2.activeHouseholdId)
}

function getBehaviourSettings(state) {
  const activeHousehold = getActiveHousehold(state)
  let behaviours = access(activeHousehold, 'data.behaviours')
  let behavioursRemapped = behaviours.map((behaviour) => {
    switch (behaviour.enabled) {
      case 'true':
        behaviour.enabled = true
        break
      case 'false':
        behaviour.enabled = false
        break

      default:
        break
    }

    let configItems = []
    _.forEach(behaviour.userConfiguration, (item, key) => {
      let min
      let max
      if (item.range) {
        let range = item.range.match(/^(\d*)\-(\d*)$/) // eslint-disable-line no-useless-escape
        min = Number.parseInt(range[1])
        max = Number.parseInt(range[2])
      } else {
        min = 0
        max = 0
      }

      configItems.push(
        {
          name: key,
          settingPath: `behaviours.${behaviour.name}.${key}`,
          type: item.type,
          label: item.uiLabel,
          value: item.value,
          isRequired: item.mandatory,
          min: min,
          max: max,
          options: item.options || [],
          maximumLength: item.maximumLength || 0,
        }
      )
    })

    return {
      friendlyName: behaviour.friendlyName,
      name: behaviour.name,
      enabled: behaviour.enabled,
      userConfiguration: configItems,
      type: behaviour.type,
      zoneId: behaviour.zoneId,
      alertWindow: behaviour.alertWindow,
    }
  })
  return behavioursRemapped

}

function getMonitorService(state) {
  const activeHousehold = getActiveHousehold(state)
  let monitorService = access(activeHousehold, 'data.monitorService')
  if (!monitorService || !monitorService.enabled) {
    monitorService = {
      enabled: false,
      type: null,
      serviceProviderUserId: null,
      notifications: [],
    }
  }
  return monitorService
}

export function getActiveHouseholdSettings(state) {
  const activeHousehold = getActiveHousehold(state)
  let settings = {}
  settings.gatewayId = access(activeHousehold, 'data.gatewayId')
  settings.id = access(activeHousehold, 'householdId')
  settings.friendlyName = access(activeHousehold, 'friendlyName')
  settings.avatar = access(activeHousehold, 'avatar')
  settings.behaviours = access(activeHousehold, 'data.behaviours')
  settings.sleep = {
    sleepingStart: access(activeHousehold, 'data.sleepingStart'),
    sleepingFinish: access(activeHousehold, 'data.sleepingFinish'),
  }
  return settings
}

function fetchingCarers(state) {
  const activeHousehold = getActiveHousehold(state)
  return access(activeHousehold, 'carers.fetchingCarers')
}

function addingCarer(state) {
  const activeHousehold = getActiveHousehold(state)
  return access(activeHousehold, 'carers.addingCarer')
}

function removingCarer(state) {
  const activeHousehold = getActiveHousehold(state)
  return access(activeHousehold, 'carers.removingCarer')
}

function carerError(state) {
  const activeHousehold = getActiveHousehold(state)
  return access(activeHousehold, 'carers.error')
}

export default function buildComponent(component, formikData) {
  return compose(
    connect(
      (state) => ({
        behaviours: getBehaviourSettings(state),
        monitorService: getMonitorService(state),
        activeHousehold: getActiveHousehold(state),
        activeHouseholdSettings: getActiveHouseholdSettings(state),
        isSaving: state.households.settings.isSaving,
        saveError: state.households.settings.error,
        isSuccess: state.households.settings.success,
        fetchingCarers: fetchingCarers(state),
        addingCarer: addingCarer(state),
        removingCarer: removingCarer(state),
        carerError: carerError(state),
        healthMetricsSummary: access(state, "healthMetrics.summary"),
        isDeveloper: state.user.isDeveloper,
        isSupport: state.user.isSupport,
        isShowError:state.households.settings.isShowError,
        sensors: state.householdsV2.sensorDetails,
      }),
      (dispatch) => {
        return {
          updateSettings: (params) => {
            Object.keys(params.settings).forEach(key => {
              console.log("updateSettings", params)
              dispatch(updateBehaviourSetting(params.householdId, key, params.settings[key]))
            })
            dispatch(callAPI(HOUSEHOLD_SETTING_BULK(params)))
          },
          updateAvatar: (params) => {
            dispatch(updateAvatar(params.householdId, params.image))
            dispatch(callAPI(HOUSEHOLD_AVATAR_UPLOAD(params)))
          },
          updateSetting: (params) => {
            console.log("updateSetting", params)
            dispatch(updateBehaviourSetting(params.householdId, params.setting, params.value))
            dispatch(callAPI(HOUSEHOLD_SETTING_UPDATE(params)))
          },
          updateSettingBulk: (params) => {
            console.log("updateSettingBulk", params)
            dispatch(updateBehaviourSetting(params.householdId, params.setting, params.value))
            const value = {
              householdId:params.householdId ,
              settings: {
                monitorService: params.value,
              },
            }
            dispatch(callAPI(HOUSEHOLD_SETTING_BULK(value)))
          },
          getCarers: (householdId) => {
            dispatch(callAPI(HOUSEHOLD_CARER_LIST({ householdId })))
          },
          addCarer: (householdId, email) => {
            dispatch(callAPI(HOUSEHOLD_CARER_ADD({ householdId, email })))
          },
          removeCarer: (params) => {
            dispatch(callAPI(HOUSEHOLD_CARER_REMOVE(params)))
          },
        }
      }),
    withStyles(Styles),
    withStyles(PortalOverlayStyles),
    withFormik(formikData),
  )(component)
}
