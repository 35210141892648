export default theme => ({
  root: {

  },
  HouseholdRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'space-between',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 4,
    paddingBottom: 4,
    borderBottom: '1px solid lightgrey',
  },
  HouseholdLabel: {
    textAlign: 'center',
  },
  ReportButton: {
  },
  container: {
    padding: '12px',
  },
  progress: {},
})
