
const SENSOR_VALUES = {
  MOTION: "motion",
  ACTIVE: "Active",
  SENSOR_OTHER: "Other",
}

// Gets the latest motion sensor activity and formats it for display in the card header view
export const getLastMotionEvent = (sensors) =>
{
  try
  {
    // Attempt to find active sensors first
    for(const sensor of sensors)
    {
      // Get the first motion
      if(sensor.type === SENSOR_VALUES.MOTION && sensor.value === SENSOR_VALUES.ACTIVE) return parseSensorData(sensor)
    }

    // Failing that just try for the most recent sensor
    for(const sensor of sensors)
    {
      // Get the first motion
      if(sensor.type === SENSOR_VALUES.MOTION) return parseSensorData(sensor)
    }

    return { friendlyName: "", friendlyTime: "", icon: "home" }

  }
  catch(error)
  {
    return { friendlyName: "", friendlyTime: "", icon: "home" }
  }
}

/**
 * @returns An object containing the friendyName and friendlyTime of the last motion sensor event
 * @param {Array} sensor Array of sensors
 */
const parseSensorData = (sensor) =>
{
  let friendlyName = ""
  const friendlyTime = sensor.friendlyTime
  const icon = sensor.icon

  // If the sensor roomType is Other then use the friendlyName instead
  if(sensor.roomType === SENSOR_VALUES.SENSOR_OTHER)
  {
    friendlyName = sensor.friendlyName.split(" ")[0]
  }
  else
  {
    friendlyName = sensor.roomType
  }


  return { friendlyName, friendlyTime, icon }
}