import { connect } from 'react-redux'
import { ViewResellerModal } from './ViewResellerModal'

// Redux
import redux from '../../../../../redux'

const callAPI = redux.userStore.callAPI

const {
  GET_HOUSEHOLDS_BY_RESELLER_ID, UPDATE_RESELLER,
  DELETE_RESELLER } = redux.apiEndpoints

const mapStateToProps = state => {
  return {
    isGettingHouseholds: state.householdsV2.isGettingHouseholds,
    resellerHouseholds: state.householdsV2.resellerHouseholds,
    resellerHouseholdsGetError: state.householdsV2.resellerHouseholdsGetError,
    updateResellerSuccess: state.householdsV2.updateResellerSuccess,
    updateResellerError: state.householdsV2.updateResellerError,
    isUpdatingReseller: state.householdsV2.isUpdatingReseller,
    isDeletingReseller: state.householdsV2.isDeletingReseller,
    deleteResellerResult: state.householdsV2.deleteResellerResult,
    deleteResellerError: state.householdsV2.deleteResellerError,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getHouseholdsByResellerId: (params) => dispatch(callAPI(GET_HOUSEHOLDS_BY_RESELLER_ID(params))),
    updateReseller: (id, reseller) => dispatch(callAPI(UPDATE_RESELLER(id, reseller))),
    clearUpdateReseller: () => dispatch({ type: "CLEAR_UPDATE_RESELLER" }),
    deleteReseller: (params) => dispatch(callAPI(DELETE_RESELLER(params))),
    clearDeleteReseller: () => dispatch({ type: "CLEAR_DELETE_RESELLER" }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewResellerModal)