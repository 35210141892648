import React, { Component } from "react";

// Material UI components
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

// Material UI icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";

// Custom
import { Household } from "./RealtimeStatus";



// Util
import moment from "moment";
import { getHouseholdInitials } from "../util/getHouseholdInitials";
import { HouseholdStatusItem } from "./HouseholdStatusItem";
import { RealtimeAlerting } from "../../../api/RealtimeAlertingApi";
import { HouseholdListModal } from "../../Organisations/component/Modals/HouseholdListModal";

const AVATAR_COLOR = "#9B59B6";
const AVATAR_ALERT_COLOR = "#E74C3C";
const ALERT_BORDER_COLOR = "#E74C3C";
const ICON_COLOR = "white";
const HIDE_SNACK_DURATION = 3000;
interface HouseholdCardProps {
  household: Household;
  activityStreamEvents?: any;
  token: string;
  updateId?: any;
  updateSpeed: number;
}

export interface Vital
{
  friendlyName: string;
  id: string;
  timestamp: string;
  description: string;
  value: number | string | JSX.Element;
  type: string;
}

class HouseholdCard extends Component<HouseholdCardProps> {
  updateId: any;
  constructor(props: HouseholdCardProps) {
    super(props);
    this.updateId = null;
  }

  state = {
    updateCount: 0,
    lastActivity: "",
    lastActivityTimestamp: "",
    lastRoom: "",
    lastRoomTimestamp: "",
    doorStatus: "",
    doorStatusFriendlyName: "",
    doorStatusTimestamp: "",
    showActivityAlert: false,
    showDoorAlert: false,
    showMotionAlert: false,
    showSnack: false,
    vitals: [],
  };

  updateEvents = async () => {
    const { household, token } = this.props;
    const { lastActivity, doorStatus, updateCount, lastRoom } = this.state;

    try {
      const realtimeAlertingApi = new RealtimeAlerting(token);

      const response = await realtimeAlertingApi.getHousehold(household.householdId);

      if (response && response.results) {
        this.setState({
          lastActivity: response.results.lastActivity.value,
          lastActivityTimestamp: response.results.lastActivity.timestamp,
          doorStatus: response.results.doorStatus.value,
          doorStatusFriendlyName: response.results.doorStatus.friendlyName,
          doorStatusTimestamp: response.results.doorStatus.timestamp,
          lastRoom: response.results.lastRoom.friendlyName,
          lastRoomTimestamp: response.results.lastRoom.timestamp,
          updateCount: updateCount + 1,
          vitals: response.results.vitals,
        });

        // Show an alert
        if (response.results.lastActivity.value !== lastActivity && updateCount > 0) this.setState({ showActivityAlert: true });
        if (response.results.doorStatus.value !== doorStatus && updateCount > 0) this.setState({ showDoorAlert: true });
        if (response.results.lastRoom.friendlyName !== lastRoom && updateCount > 0) this.setState({ showMotionAlert: true });
      }
    } catch (error) {
      //
    }
  };

  componentDidMount() {
    const { updateSpeed } = this.props;

    this.updateEvents();

    this.startNewInterval(updateSpeed);
  }

  startNewInterval = (updateSpeed: number) => {
    this.updateId = setInterval(() => this.updateEvents(), updateSpeed);
  };

  componentDidUpdate(lastProps: any, prevState: any) {
    const { updateSpeed, token } = this.props;
    // If the updateSpeed changes from the parent then clear and start a new interval
    if (lastProps.updateSpeed !== updateSpeed) {
      clearInterval(this.updateId);
      this.startNewInterval(updateSpeed);
    }
  }

  componentWillUnmount() {
    clearInterval(this.updateId);
  }

  handleResolve = (e: any) => {
    e.stopPropagation();
    this.setState({ showMotionAlert: false, showActivityAlert: false, showDoorAlert: false, showSnack: true });
  };


  render() {
    const { household } = this.props;

    const {
      lastActivity,
      showSnack,
      lastActivityTimestamp,
      showMotionAlert,
      showDoorAlert,
      showActivityAlert,
      lastRoom,
      lastRoomTimestamp,
      doorStatus,
      doorStatusFriendlyName,
      doorStatusTimestamp,
      vitals,
    } = this.state;
    return (
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.container}>
        <Card>
          <CardActionArea onClick={() => (window.location.href = "/#/households/" + household.householdId)}>
            <CardHeader
              avatar={
                <Avatar style={{ backgroundColor: showActivityAlert ? AVATAR_ALERT_COLOR : AVATAR_COLOR, height: 55, width: 55 }} aria-label="avatar">
                  {getHouseholdInitials(household.friendlyName)}
                </Avatar>
              }
              action={
                (showMotionAlert || showActivityAlert || showDoorAlert) && (
                  <IconButton onClick={this.handleResolve} aria-label="acknowledge">
                    <CheckCircleIcon />
                  </IconButton>
                )
              }
              titleTypographyProps={{ variant: "h5" }}
              subheaderTypographyProps={{ variant: "h6" }}
              title={household.friendlyName}
              subheader={lastActivityTimestamp ? moment(lastActivityTimestamp).fromNow() : "Unknown"}
            />

            <CardContent>
              <Grid container justifyContent="flex-start" spacing={3}>

                <HouseholdStatusItem
                  type="motion"
                  value={<DirectionsWalkIcon style={styles.iconStyle} />}
                  statusTitle={`Last Activty: ${lastActivity}` || "Unknown"}
                  description={lastActivityTimestamp ? moment(lastActivityTimestamp).fromNow() : "Unknown"}
                  showAlert={false} />

                <HouseholdStatusItem
                  type="motion"
                  value={<DirectionsWalkIcon style={styles.iconStyle} />}
                  statusTitle={`Last Room: ${lastRoom}` || "Unknown"}
                  description={lastRoomTimestamp ? moment(lastRoomTimestamp).fromNow() : "Unknown"}
                  showAlert={showMotionAlert} />

                <HouseholdStatusItem
                  type="door"
                  value={<MeetingRoomIcon style={styles.iconStyle} />}
                  statusTitle={doorStatus && doorStatusFriendlyName ? doorStatus + " (" + doorStatusFriendlyName + ")" : "Door Status Unknown"}
                  description={doorStatusTimestamp ? moment(doorStatusTimestamp).fromNow() : "Unknown"}
                  showAlert={showDoorAlert} />



                {vitals.filter((v: Vital) => v.type !== 'distance').map((vital: Vital, key) => {
                  return(
                    <HouseholdStatusItem
                      type={vital.type}
                      value={vital.value}
                      statusTitle={vital.friendlyName}
                      description={vital.timestamp ? moment(vital.timestamp).fromNow() : "None detected"}
                      showAlert={false}
                      key={key} />
                  )
                })}


              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>

        <Snackbar open={showSnack} autoHideDuration={HIDE_SNACK_DURATION} onClose={() => this.setState({ showSnack: false })}>
          <Alert variant="filled" severity="success">
            Alert Acknowledged!
          </Alert>
        </Snackbar>
      </Grid>
    );
  }
}

const styles = {
  container: {
    padding: 12,
  },
  iconStyle: { color: ICON_COLOR },
  defaultRow: { display: "flex", flexDirection: "row" as "row" },
  alertStyles: { borderColor: ALERT_BORDER_COLOR, borderStyle: "solid", borderRadius: 10, borderWidth: 1, display: "flex", flexDirection: "row" as "row" },
};

export { HouseholdCard };
