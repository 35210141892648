import React, { Component } from 'react'
import PropTypes, { any } from 'prop-types'
import clsx from 'clsx'
import access from 'safe-access'

import { withStyles, withTheme } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Collapse from '@material-ui/core/Collapse'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { enums } from '@intelicare/shared'
import { behaviour, colors } from '../../../../../../redux'

import InteliIcon from '../../../../../../components/InteliIcon'
import MealTimeGraph from '../../../graphs/MealTimeGraph'
import ComposedGraph from '../../../graphs/ComposedGraph'

const colorForStatusValue = colors.colorForStatusValue
const getColoredClassificationIcon = behaviour.getColoredClassificationIcon

const {
  BEHAVIOUR_STATUS,
  BehaviourClassification,
} = enums


class MealView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded: false,
    }
  }

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }))
  };

  componentDidUpdate(lastProps, _lastState) {
    if (this.props.expanded !== lastProps.expanded) {
      this.setState(state => ({ ...state, expanded: this.props.expanded }))
    }
  }

  render() {
    const {
      classes,
      isFetching,
      householdV2,
      useNewLook,
    } = this.props

    let icon
    const status = access(householdV2, 'behaviourRollup.MEAL_PREP')
    const statusColor = colorForStatusValue(status || BEHAVIOUR_STATUS.UNKNOWN)
    icon = getColoredClassificationIcon(
      BehaviourClassification.MEAL_PREP,
      statusColor
    )

    return (
      <Card className={classes.root} ref={this.containerRef} automation_attribute="mealPrepCard">
        { !useNewLook && <CardHeader automation_attribute="mealPrepHeader"
          classes={{
            root: classes.headerRoot,
            title: classes.headerTitle,
            subheader: classes.headerSubheader,
          }}
          title={<Typography variant="h5">Meal Preparation Activity</Typography>}
          subheader={<Typography color="textSecondary" variant="body1">Shows meal preparation activity for a household</Typography>}
          action={
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: this.state.expanded,
              })}
              onClick={this.handleExpandClick}
              aria-expanded={this.state.expanded}
              aria-label="Show more"
            >
              <ExpandMoreIcon
                automation_attribute="mealPrepExpandArrow"
                fontSize='large'
                color='primary'
                style={{ backgroundColor: '#F0F0F0', borderRadius: '50%' }}
              />
            </IconButton>
          }
          avatar={
            <div>
              <InteliIcon
                size={55}
                name={icon.name}
                family={icon.family}
                color={icon.color}
              />
            </div>
          }
        /> }
        {
          isFetching && this.state.expanded &&
          <CardContent>
            <CircularProgress color="primary" />
          </CardContent>
        }
        {
          !isFetching && this.props.graphs.mealPrep &&
          <Collapse in={useNewLook || this.state.expanded} timeout="auto" unmountOnExit>
            <CardContent automation_attribute="mealPrepContent">
              <Grid container direction="column" automation_attribute="mealPrepGraphs">
                <Grid item style={{ paddingBottom: 16 }}>
                  <Typography variant="h6">Meal Preparation Count During Meal Times</Typography>
                  <MealTimeGraph />
                </Grid>
                <Grid item>
                  <Typography variant="h6">Meal Preparation Count Over the Past Month</Typography>
                  <ComposedGraph data={this.props.graphs.mealPrep} />
                </Grid>
              </Grid>
            </CardContent>
          </Collapse>
        }
      </Card>
    )
  }
}

MealView.propTypes = {
  graphs: PropTypes.object,
  behaviours: any,
  classes: PropTypes.object,
  id: PropTypes.string,
  isFetching: PropTypes.bool,
  theme: PropTypes.object,
  household: PropTypes.object,
  householdV2: PropTypes.object,
  tiles: PropTypes.func,
  useHouseholdV2: PropTypes.bool,
  expanded: PropTypes.bool,
  useNewLook: PropTypes.bool,
}

const styles = theme => ({
  root: {
    marginBottom: 20,
  },
  headerRoot: {
    background: 'white',
  },
  headerTitle: {
    color: 'black',
  },
  headerSubheader: {
    color: 'black',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.short,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
})

export default withTheme(withStyles(styles)(MealView))
