
import { withStyles } from '@material-ui/core/styles'
import * as React from 'react' // tslint:disable-line
import { Component } from 'react' // tslint:disable-line
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceArea,
  ReferenceLine,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts'
import moment from 'moment'


class ComposedGraph extends Component {

  constructor(props) {
    super(props)
    this.state = {
      height: 300,
    }
  }

  render() {
    const { values, bars, lines, ticks, tickFormat, start, end } = this.props.data
    return (
      <ResponsiveContainer width={"100%"} height={this.state.height}>
        <ComposedChart
          data={values}
          width={"80%"}
          height={this.state.height}
          margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="unixTs"
            type="number"
            domain={[start.unix(), end.unix()]}
            tickFormatter={tick => moment(tick * 1000).format(tickFormat)}
            ticks={ticks}
            xAxisId={0}
          />
          <YAxis
            allowDecimals={false}
            label={{ value: 'Number of Events', angle: -90 }}
            width={80} />
          <Legend />
          {this.getBars(bars)}
          {this.getLines(lines)}
          {this.getReferenceAreas(this.props.data.referenceAreas)}
          {this.getReferenceLines(this.props.data.referenceLines)}
        </ComposedChart>
      </ResponsiveContainer>
    )
  }

  getColor() {
    return this.props.theme.palette.info.main;
  }

  getBars(bars = []) {
    return bars.map((bar, index) => {
      return (
        <Bar
          key={bar.id}
          barSize={30}
          fill={bar.color || this.getColor()}
          stroke={bar.stroke}
          dataKey={bar.id}
          name={bar.label}
          stackId={bar.stackId || 'a'}
          strokeWidth={2}
        />)
    })
  }

  getLines(lines = []) {
    return lines.map((line, index) => {
      return (
        <Line
          key={index}
          type={line.type || 'monotone'}
          dot={line.dot || false}
          dataKey={line.id}
          stroke={this.getColor()}
          strokeWidth={2}
          name={line.label} />)
    })
  }

  getReferenceAreas(areas = []) {
    return areas.map((area, index) => {
      return (
        <ReferenceArea
          key={index}
          x1={area.x1}
          x2={area.x2}
          fill="#8884d8"
          fillOpacity="0.2"
        />)
    })
  }

  getReferenceLines(lines = []) {
    return lines.map((line, index) => {
      return (
        <ReferenceLine
          key={index}
          ifOverflow="extendDomain"
          x={line.x}
          y={line.y}
          label={line.label}
          stroke={line.color}
          strokeDasharray="4 4" />)
    })
  }

  getArea(areas = []) {
    return areas.map((area, index) =>
      <Area
        key={index}
        type="monotone"
        dataKey={area.id}
        stroke="#8884d8"
        fill="#8884d8"
        name={area.label} />,
    )
  }
}

ComposedGraph.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.object,
}

const styles = {}

export default withTheme(withStyles(styles)(ComposedGraph))
