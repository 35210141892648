import React from 'react'
import PropTypes from 'prop-types'

import './ContentStraplineView.scss'

// TODO: Delete this component
const ContentStraplineView = ({ onLogout, status, title, subtitle }) => (
  <div style={{ display: 'none' }}></div>
)

ContentStraplineView.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  status: PropTypes.string,
  onLogout: PropTypes.func,
}

export default ContentStraplineView
