import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Material UI components
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
// Icons
import SearchIcon from '@mui/icons-material/Search'

// Household list component
import HouseholdList from '../Lists/Households/HouseholdList'

// Redux
import redux from '../../../../redux'

const SEARCH_QUERY_LIMIT = 2000
const INITIAL_GET_LIMIT = 100

const callAPI = redux.userStore.callAPI

const {
  GET_HOUSEHOLDS,
  LINK_HOUSEHOLD_TO_RESELLER } = redux.apiEndpoints

const LinkHouseholdModal = (
  {
    open,
    onClose,
    householdsList,
    getHouseholds,
    isGettingAllHouseholds,
    resellerId,
    linkHouseholdToReseller,
    linkHouseholdResult,
    clearLinkingResult,
  }) => {

  LinkHouseholdModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    householdsList: PropTypes.array,
    getHouseholds: PropTypes.func,
    isGettingAllHouseholds: PropTypes.bool,
    resellerId: PropTypes.string,
    linkHouseholdToReseller: PropTypes.func,
    linkHouseholdResult: PropTypes.object,
    linkHouseholdError: PropTypes.object,
    isLinkingHousehold: PropTypes.bool,
    clearLinkingResult: PropTypes.func,
  }

  const [query, setQuery] = useState("")

  // Close the modal if we successfuly link the reseller, also clean up the reseller linking result in Redux
  useEffect(() => {
    if (linkHouseholdResult && linkHouseholdResult.result.resellerId === resellerId) {
      clearLinkingResult()
      onClose()
    }
  })

  const handleSearch = (query) => {
    if (query && query.length >= 3) {
      getHouseholds({ limit: SEARCH_QUERY_LIMIT, offset: 0, sortDirection: "desc", sortByValue: "_ts", q: query })
    }
    else if (query.length === 0) {
      getHouseholds({ limit: SEARCH_QUERY_LIMIT, offset: 0, sortDirection: "desc", sortByValue: "_ts", q: "" })
    }
  }

  return (
    <Dialog
      // On the initial enter, only get a few households
      onEnter={() => getHouseholds({ limit: INITIAL_GET_LIMIT, offset: 0, sortDirection: "desc", sortByValue: "_ts", q: "" })}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Link Household"}</DialogTitle>
      <DialogContent>

        <div container direction="row" style={{ paddingTop: 12, paddingBottom: 20 }} >
          <TextField
            onChange={(e) => setQuery(e.target.value)}
            fullWidth={true}
            placeholder="Search all households"
            label="Search all households"
            variant="outlined"
            InputProps={{
              endAdornment:
                (<InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleSearch(query)}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>),
            }}
            onKeyPress={(e) => e.key === "Enter" && handleSearch(query)}
          />
        </div>

        {!isGettingAllHouseholds && householdsList && householdsList.length === 0 &&
          <Grid style={{ paddingTop: 20 }} container justifyContent="center">
            <Typography color="textSecondary" variant="h5" component="h5">No Households Found</Typography>
          </Grid>}

        {isGettingAllHouseholds ? <Grid style={{ paddingTop: 20 }} container justifyContent="center"><CircularProgress /></Grid> :

          <HouseholdList
            onItemClick={(householdId) => linkHouseholdToReseller({ householdId: householdId, resellerId: resellerId })}
            resellerId={resellerId} households={householdsList || []} />
        }





      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog >
  )
}

const mapStateToProps = state => {
  return {
    householdsList: state.householdsV2.householdsList,
    isGettingAllHouseholds: state.householdsV2.isGettingAllHouseholds,
    linkHouseholdResult: state.householdsV2.linkHouseholdResult,
    linkHouseholdError: state.householdsV2.linkHouseholdError,
    isLinkingHousehold: state.householdsV2.isLinkingHousehold,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    linkHouseholdToReseller: (params) => dispatch(callAPI(LINK_HOUSEHOLD_TO_RESELLER(params))),
    getHouseholds: (params) => dispatch(callAPI(GET_HOUSEHOLDS(params))),
    clearLinkingResult: () => dispatch({ type: "CLEAR_LINK_HOUSEHOLD_TO_RESELLER" }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkHouseholdModal)