import React from 'react'
import Card from '@material-ui/core/Card'
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import MultiActivityGraphViewBreakdown, { DEFAULT_HEIGHT } from '../MultiActivityGraphBreakdown/MultiActivityGraphViewBreakdown'

import { getInitials } from '../../routes/Organisations/component/Modals/utils/getInitials';
import { IHouseholdDocument } from '@intelicare/shared/interfaces/household/IHouseholdDocument'
interface ActivityRowItemProps
{
  friendlyName: string;
  sensors: [];
  household: IHouseholdDocument;
}

export const ActivityRowItem = ({ friendlyName, sensors, household }: ActivityRowItemProps) =>
{
  return(
    <Card style={styles.container}>

      <div style={styles.profileContainer}>
        <div style={styles.avatarContainer}><Avatar style={styles.avatar}>{getInitials(friendlyName)}</Avatar></div>
        <Typography variant="caption" color="textSecondary" align="center">{friendlyName}</Typography>
      </div>

      <MultiActivityGraphViewBreakdown data={sensors} household={household} />

    </Card>
  )
}


const styles = {
  container: {
    margin: 6,
    padding: 6,
    height: DEFAULT_HEIGHT,
    display: 'flex',
    flex: 1,
  },
  avatar: {
    backgroundColor: '#4DB6AC',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  profileContainer: {
    marginRight: 6,
    marginLeft: 6,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: "column" as "column",
  },
}