import React from 'react'
import HealthMetrics from '../cards/HealthMetrics'

interface HealthMetricsTabProps
{
  isDeveloper: boolean;
  isSupport: boolean;
  activeV2Household: any;
  openHealthMetricSettings: () => void;
  useNewLook: boolean;
}

export const HealthMetricsTab = ({ activeV2Household, isDeveloper, openHealthMetricSettings, useNewLook }: HealthMetricsTabProps) =>
{
  return(<div>
    <HealthMetrics
      id="health"
      aria-label="health"
      householdV2={activeV2Household}
      isDeveloper={isDeveloper}
      openHealthMetricSettings={openHealthMetricSettings}
      useNewLook={useNewLook}
    />
  </div>)
}