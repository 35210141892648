import { connect } from 'react-redux'
import SearchableTable from '../../../../../components/SearchableTable'
import sharedStore from '../../../../../redux'
const householdSummaryStatus = sharedStore.householdsSelector.householdSummaryStatus
const updateSelectedGatewayColumns = sharedStore.systemStore.updateSelectedGatewayColumns
const convertSensorDetails = sharedStore.systemSelector.convertSensorDetails


const mapDispatchToProps = (dispatch) => {
  return {
    updateColumns: (newColumns) => {
      dispatch(updateSelectedGatewayColumns(newColumns))
    },
    onSelectRow: (gateway) => {
    },
  }
}

function mapStateToProps(state) {
  return {
    allColumns: state.system.allGatewayColumns,
    columns: state.system.selectedGatewayColumns,
    status: householdSummaryStatus(state),
    rows: convertSensorDetails(state),
    selectableRow: false,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchableTable)

