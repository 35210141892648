
import { AlertThresholds } from '@intelicare/shared/interfaces/household/AlertThresholds';
import { useEffect, useState } from 'react'
import { RealtimeAlerting } from "../../../../../api/RealtimeAlertingApi"

type RealtimeAlertingHook = [
    boolean,
    () => void,
    (values: number[]) => void,
    (values: number[]) => void,
    (values: number[]) => void,
    number[],
    number[],
    number[],
    string,
  ]

export const useRealtimeAlerting = (token: string, householdId: string): RealtimeAlertingHook =>
{
  const [ isLoading, setIsLoading ] = useState(false);
  const [ heartRate, setHeartRate ] = useState<number[]>([40, 120])
  const [ respiration, setRespiration ] = useState<number[]>([12, 20])
  const [ activity, setActivity ] = useState<number[]>([2, 3])
  const [ saved, setSaved ] = useState<string>("");

  // on component mount - get the metrics
  useEffect(() => {
    get()
  }, [])

  const get = async () =>
  {
    const api = new RealtimeAlerting(token);
    setIsLoading(true)
    const response = await api.getThresholds(householdId);
    setIsLoading(false)
    if(response && response.heartRate && response.respiration && response.activity )
    {
      setHeartRate([response.heartRate.high, response.heartRate.low])
      setRespiration([response.respiration.high, response.respiration.low])
      setActivity([response.activity.high, response.activity.low])
    }
  }

  const save = async () =>
  {
    const thresholdsToSave: AlertThresholds = {
      heartRate: { low: heartRate[0], high: heartRate[1] },
      respiration: { low: respiration[0], high: respiration[1] },
      activity: { low: activity[0], high: activity[1] },
      distance: { low: 0, high: 2.5 },
    }

    const api = new RealtimeAlerting(token);
    setIsLoading(true)
    const response = await api.setThresholds(householdId, thresholdsToSave);
    setIsLoading(false)
    if(response && response.heartRate && response.respiration && response.activity) setSaved("Saved thresholds OK");
  }

  return [ isLoading, save, setHeartRate, setRespiration, setActivity, heartRate, respiration, activity, saved]
}