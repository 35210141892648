import React from 'react'
import { connect } from 'react-redux'
//@ts-ignore
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useIotEvents } from './hooks/useIotEvents';
import { CircularProgress, Typography } from '@material-ui/core';
import moment from 'moment'
import { brandPink, brandPurple, infoBlue } from '../../../../../../themes/proTheme';
import BarChart from '@mui/icons-material/BarChart'
interface PersonalRoutineGraphProps
{
  token: string;
  householdId: string;
  gatewayId: string;
}

const PersonalRoutineGraph = ({ token, householdId, gatewayId }: PersonalRoutineGraphProps) =>
{
  const colors = [
    brandPink.light,
    brandPurple.light,
    infoBlue.light,
    brandPink.dark,
    brandPurple.dark,
    infoBlue.dark,
    brandPink.light,
    brandPurple.light,
    infoBlue.light,
    brandPink.dark,
    brandPurple.dark,
    infoBlue.dark,
  ]
  const [ isLoading, iotEventData ] = useIotEvents(token, householdId, gatewayId, moment().subtract(7, 'days').toISOString())
  return(
    <div style={styles.container}>
      {!isLoading && iotEventData && iotEventData.length > 0 ? <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width="100%"
          height="100%"
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis tickCount={7} dataKey="eventTime" tickFormatter={(timestamp: string) => moment(timestamp).format("DD/MM h a")} />
          <YAxis type="number" dataKey={(value: any)=> parseInt(value.newState)} domain={[0, 'auto']} />
          <Tooltip
            labelFormatter={(timestamp: string) => `${moment(timestamp).calendar()} - ${moment(timestamp).fromNow()}`}
            formatter={(item: number) => `${Math.floor(item)}W`} />
          <Legend />

          {
            iotEventData.map((item, key) => {
              return <Line key={key} data={item.events.reverse()} type="monotone" name={item.sensorFriendlyName} dataKey="newState" stroke={colors[key]} strokeWidth="3" activeDot={{ r: 8 }} dot={false} />
            })
          }
        </LineChart>
      </ResponsiveContainer> : isLoading ?
        <div style={styles.loadingView}>
          <CircularProgress variant="indeterminate" color="secondary" />
          <Typography color="textSecondary">Getting Events</Typography>
        </div>
        : <div style={styles.loadingView}>
          <BarChart htmlColor="grey" style={{ fontSize: 64 }} />
          <Typography color="textSecondary">No Sensors with Personal Routine insight enabled</Typography>
        </div>}
    </div>
  )
}




const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
    width: "100%",
    height: 300,
  },
  loadingView: {
    textAlign: 'center' as 'center',
    padding: 100,
  },
}

const mapStateToProps = ({ user, householdsV2 }: any) =>
{
  return {
    token: user.token,
    principal: user.principal,
    householdId: householdsV2.activeHouseholdId,
    gatewayId: householdsV2.activeGatewayId,
  }
}

export default connect(mapStateToProps)(PersonalRoutineGraph)
