import { createSelector } from 'reselect'
import access from 'safe-access'
import jwtDecode from 'jwt-decode'

export const getUserDetails = (state) => state.user
export const getPrincipalId = (state) => state.user.principal
export const getAccessToken = (state) => state.user.token
export const getRefreshToken = (state) => state.user.refreshToken
export const isRefreshing = (state) => state.user.tokenRefresh.isFetching

export const getUserName = createSelector(
  [getUserDetails],
  (user) => [user.firstName, user.lastName].filter(name => !!name).join(' ')
)

export const getUserAvatar = createSelector(
  [getUserDetails],
  (user) => user.avatar
)

export const getTokenDetails = createSelector(
  [ getAccessToken ],
  (accessToken) => {
    const decodedIdToken = jwtDecode(accessToken)

    return decodedIdToken
  }
)

export const getTokenExpirationDate = createSelector(
  [ getTokenDetails ],
  (tokenDetails) => {
    const expiration = access(tokenDetails, 'exp')

    return expiration
  }
)
