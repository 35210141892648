import React, { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { SearchBar } from '../Shared/SearchBar';
import { AccessItem, User } from '../../../../api/OrganisationApi';
import { UserListItem } from './UserListItem';
interface UsersListProps
{
  onUserClick: (userId: string) => void;
  users: AccessItem
}

export const UsersList = ({ onUserClick, users }: UsersListProps) =>
{
  const [ query, setQuery ] = useState("")

  return(
    <div>
      <SearchBar value={query} onTextChange={setQuery} title="Search Users" onClear={() => setQuery("")}/>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Roles</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(users).filter(userId => userId.toLowerCase().includes(query.toLowerCase())).map((userId, key) => <UserListItem key={key} onUserClick={() => onUserClick(userId)} userId={userId} roles={users[userId]} /> )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
