import { timeStringToDate, UTCtimeStringToDate } from ".";
import moment from "moment";

export class GraphBuilder
{
  graphData: ((string | Date)[] | { type: string; id: string; }[])[];
  alertWindowStart: string;
  alertWindowEnd: string;
  doorInfoWindowStart: string
  doorInfoWindowEnd: string;

  constructor(alertWindowStart: string, alertWindowEnd: string, doorInfoWindowStart: string, doorInfoWindowEnd: string)
  {
    this.graphData = [];

    this.alertWindowStart = alertWindowStart;
    this.alertWindowEnd = alertWindowEnd;
    this.doorInfoWindowStart = doorInfoWindowStart
    this.doorInfoWindowEnd = doorInfoWindowEnd;
  }

  addInfoMessages = () =>
  {
    if(this.doorInfoWindowStart && this.doorInfoWindowEnd)
    {
      try {
        if (this.doorInfoWindowEnd < this.doorInfoWindowStart || this.doorInfoWindowEnd === this.doorInfoWindowStart) {
          this.graphData.push(["Door Info Messages", timeStringToDate(this.doorInfoWindowStart), moment(this.doorInfoWindowEnd, "HH:mm").add(24, "hours").toDate()]);
        } else {
          this.graphData.push(["Door Info Messages", timeStringToDate(this.doorInfoWindowStart), timeStringToDate(this.doorInfoWindowEnd)]);
        }
      } catch (error) {
        this.graphData.push(["Door Info Messages", new Date(), new Date()]);
      }
    }
    else
    {
      this.graphData.push(["Door Info Messages", new Date(), new Date()]);
    }
  }

  addAlerts = () =>
  {
    if(this.alertWindowStart && this.alertWindowEnd)
    {
      try {
        if (this.alertWindowEnd < this.alertWindowStart || this.alertWindowEnd === this.alertWindowStart) {
          this.graphData.push(["Door Alerts", timeStringToDate(this.alertWindowStart), moment(this.alertWindowEnd, "HH:mm").add(24, "hours").toDate()]);
        } else {
          this.graphData.push(["Door Alerts", timeStringToDate(this.alertWindowStart), timeStringToDate(this.alertWindowEnd)]);
        }
      } catch (error) {
        this.graphData.push(["Door Alerts", new Date(), new Date()]);
      }
    }
    else
    {
      this.graphData.push(["Door Alerts", new Date(), new Date()]);
    }

  };

  buildGraphs = () => {

    this.graphData.push([
      { type: "string", id: "Time Range" },
      { type: "date", id: "Start" },
      { type: "date", id: "End" },
    ]);

    this.addInfoMessages();
    this.addAlerts();

    return this.graphData

  }

}