import { connect } from 'react-redux'
import BedSensorView from './BedSensorView'
import { HOUSEHOLD_DATA_BED } from '../../redux/shared/services/APIEndpoints'
import { callAPI } from '../../redux/domains/user/userStore';

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBedSensorData: (householdId, sensorId, startTime, append) => {
      dispatch(callAPI(HOUSEHOLD_DATA_BED({
        householdId: householdId,
        sensorId: sensorId,
        startTime: startTime,
        append: append,
      })))
    },
  }
}

const mapStateToProps = (state) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(BedSensorView)
