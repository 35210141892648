import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TableItem from './TableItem'

class ResellerTable extends Component {

  render() {

    const { onTableItemClick } = this.props
    return (
      <TableContainer style={styles.container} component={Paper}>
        <Table aria-label="reseller table">
          <TableHead>
            <TableRow >
              <TableCell>Company Name</TableCell>
              <TableCell align="right">ABN</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Contact Number</TableCell>
              <TableCell align="right">Address</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.resellers.map((reseller, key) => (
              <TableItem onTableItemClick={onTableItemClick} key={key} reseller={reseller} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

ResellerTable.propTypes = {
  resellers: PropTypes.array,
  onTableItemClick: PropTypes.func,
}

const styles = {
  container: {
    marginTop: 16,
  },
}

export default ResellerTable