import { connect } from 'react-redux'
import { OrganisationDetailsView } from './OrganisationDetailsView'

const mapState = (state: any) =>
{
  return {

  }
}

const mapDispatch = (dispatch: any) =>
{
  return {

  }
}

export default connect(mapState, mapDispatch)(OrganisationDetailsView)