import React, { Component } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import _ from 'lodash'
import Routes from '../../routes'
import { compose } from 'redux'
import withWidth from '@material-ui/core/withWidth'
import CircularProgress from '@material-ui/core/CircularProgress'

import withStyles from '@material-ui/core/styles/withStyles'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'

import MuiAlert from '@material-ui/lab/Alert';

import BrandLoader from '../../components/BrandLoader'
import DocumentTitle from 'react-document-title'
import ActiveAlerts from '../../components/ActiveAlerts'
import AppStoreOverlay from '../../components/AppStoreOverlay'
import ViewNotification from '../../components/ViewNotification'
import InteliIcon from '../../components/InteliIcon/index'
import {
  ReportDialogTitle,
  ReportDialogContent,
  ReportDialogActions,
} from './ReportDialog'

import styles from './AppWrapperStyles'

import { PDFReader } from 'reactjs-pdf-view'
import * as localForage from 'localforage'
import MiniDrawer from './MiniDrawer/MiniDrawer'

const HIDE_SNACK_DURATION = 15000

class AppWrapperView extends Component {

  constructor(props) {
    super(props)

    this.state = {
      open: true,
      showReportSnack: false,
      reportSnackType: '',
      reportSnackContent: '',
      reportLink: '',
      viewReport: false,
      isLoading: true,
      showNewFeature: false,
    }

    this.toggleDrawer = this.toggleDrawer.bind(this)
  }

  async componentDidMount() {
    try {
      const seenNewFeature = await localForage.getItem('healthMetricsNewFeatureSeen')
      this.setState({ showNewFeature: seenNewFeature !== "true" })
    } catch (err) {
      console.log(err)
    }
  }

  toggleDrawer() {
    this.setState(state => ({
      ...state,
      open: !this.state.open,
    }))
  }

  componentDidUpdate(oldProps) {
    const { reportInfo } = this.props
    if (oldProps.reportInfo !== reportInfo) {
      if (reportInfo.viewReport !== oldProps.reportInfo.viewReport) {
        return
      }
      if (oldProps.reportInfo.fetchingReport === reportInfo.fetchingReport) {
        return
      }
      if (reportInfo.fetchingReport === true) {
        //draw fetching report toast
        this.setState(state => ({
          ...state,
          reportSnackType: 'fetch',
          reportSnackContent: 'Fetching Report',
          showReportSnack: true,
          isLoading: true,
        }))
      }
      if (reportInfo.fetchingReport === false) {
        if (reportInfo.error) {
          //draw error toast
          this.setState(state => ({
            ...state,
            reportSnackType: 'error',
            reportSnackContent: reportInfo.error,
            showReportSnack: true,
            isLoading: true,
          }))
        } else {
          //draw report available toast
          this.setState(state => ({
            ...state,
            reportSnackType: 'done',
            reportSnackContent: 'Report avaible',
            reportLink: reportInfo.report,
            showReportSnack: true,
            isLoading: true,
          }))
        }
      }
    }
  }

  closeSnack = () => {
    this.setState(state => ({ ...state, showReportSnack: false }))
  }

  downloadReport = () => {

    window.open(this.props.uri)

  }

  isIe = () => {
    const isIE = /*@cc_on!@*/false || !!document.documentMode
    const isEdge = !isIE && !!window.StyleMedia
    return isIE || isEdge
  }


  createBlobUrl = (base64) =>
  {
    if(base64)
    {
      const byteCharacters = atob(base64)
      const byteNumbers = new Array(byteCharacters.length)
      for(let i = 0; i < byteCharacters.length; i++)
      {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: "application/pdf" })
      const blobUrlLink = URL.createObjectURL(blob)
      return blobUrlLink
    }

  }

  render() {
    const {
      isLoggedIn,
      isDeveloper,
      isSupport,
      inteliCareProUser,
      closeMessage,
      openMessage,
      messageIsOpen,
      resolveMessage,
      createAction,
      /* reports */
      reportInfo,
      viewReport,
      closeReport,
      /* activeAlerts */
      activeAlerts,
      minimisedAlerts,
      setAlertsMinimised,
      goToHousehold,
      /* style info */
      classes,
      location,
      showMessageSentSnack,
      snackType,
      clearSnack,
    } = this.props

    const {
      showReportSnack,
      reportSnackType,
      reportSnackContent,
    } = this.state


    var { open } = this.state


    if (!isLoggedIn) {
      // Refactor out to use same component as LOGIN
      return (
        <div style={{ width: '100%', height: '100%', position: 'fixed' }}>
          <div id='container' className='dot'>
            <div className='animate-intelicare'>
              <BrandLoader />
            </div>
          </div>
        </div>)
    }

    if (inteliCareProUser) {

      return (
        <div className={classes.root}>
          <DocumentTitle title='InteliCare' />

          <MiniDrawer location={location} content={<Routes />}/>

          <ViewNotification
            open={messageIsOpen && (_.isEmpty(activeAlerts) || minimisedAlerts)}
            close={closeMessage}
            resolve={resolveMessage}
            createAction={createAction}
            notification={openMessage || {}}
          />

          {
            <ActiveAlerts
              open={activeAlerts > 0 && !minimisedAlerts}
              createAction={createAction}
              resolve={resolveMessage}
              alerts={activeAlerts}
              setAlertsMinimised={setAlertsMinimised}
              goToHousehold={goToHousehold}
              isDeveloper={isDeveloper}
              isSupport={isSupport}
            />
          }

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={showReportSnack}
            autoHideDuration={reportSnackType === 'done' ? null : 6000}
            onClose={this.closeSnack}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{reportSnackContent}</span>}
            action={[
              reportSnackType === 'done' &&
              <Button key="undo" color={'default'} size="small" onClick={viewReport}>
                <span style={{ color: 'white' }}>View</span>
              </Button>,
              <Button key="close"
                aria-label="Close" color={'default'}
                className={classes.close}
                onClick={this.closeSnack}>
                <InteliIcon family={'material'} name={'close'} color={'white'} onClick={this.closeSnack} />
              </Button>,
            ]}
          />

          <Dialog open={reportInfo.viewReport} maxWidth={false} fullscreen={true}>
            <ReportDialogTitle onClose={closeReport}>Household Report</ReportDialogTitle>
            <ReportDialogContent>
              {this.state.isLoading && <div style={{ textAlign: "center" }}><CircularProgress></CircularProgress></div>}
              <PDFReader
                onDocumentComplete={()=> this.setState({ isLoading: false })}
                showAllPage
                url={this.props.uri}>
              </PDFReader>
            </ReportDialogContent>
            <ReportDialogActions>
              <Button onClick={closeReport} color="default">
                Close
              </Button>
              <Button onClick={this.downloadReport} color="default">
                Download
              </Button>
            </ReportDialogActions>
          </Dialog>


          <Snackbar open={showMessageSentSnack} autoHideDuration={HIDE_SNACK_DURATION} onClose={clearSnack}>
            <Alert onClose={clearSnack} severity={snackType}>
              {snackType === "success" ? "Successfully resolved notification" :
                snackType === "info" ? "Updating..." : "Error resolving notification"}
            </Alert>
          </Snackbar>

        </div>
      )
    } else {
      return (
        <div className={classes.root}>
          <DocumentTitle title='InteliCare' />
          <AppStoreOverlay />
        </div>
      )
    }

  }
}

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

AppWrapperView.propTypes = {
  //react components
  location: PropTypes.any,
  classes: PropTypes.any,
  width: PropTypes.any,
  //configuration
  isLoggedIn: PropTypes.bool.isRequired,
  isDeveloper: PropTypes.bool.isRequired,
  isSupport: PropTypes.bool.isRequired,
  isDemoUser: PropTypes.bool,
  inteliCareProUser: PropTypes.bool.isRequired,
  messageIsOpen: PropTypes.bool.isRequired,
  resolveMessage: PropTypes.func.isRequired,
  createAction: PropTypes.func.isRequired,
  closeMessage: PropTypes.func,
  openMessage: PropTypes.object,
  username: PropTypes.string,
  uri: PropTypes.string,
  // notes
  activeHousehold: PropTypes.object,
  // active alerts
  goToHousehold: PropTypes.func.isRequired,
  setAlertsMinimised: PropTypes.func.isRequired,
  activeAlerts: PropTypes.number,
  isFetchingHouseholds: PropTypes.bool,
  minimisedAlerts: PropTypes.bool,
  householdHealth: PropTypes.object,
  systemData: PropTypes.array,
  push: PropTypes.any,
  //report
  reportInfo: PropTypes.object,
  viewReport: PropTypes.func,
  closeReport: PropTypes.func,
  showMessageSentSnack: PropTypes.bool,
  snackType: PropTypes.string,
  clearSnack: PropTypes.func,
}

// export default AppWrapperView
export default compose(
  withStyles(styles),
  withWidth(),
)(AppWrapperView)
