import React from 'react'
import PropTypes from 'prop-types'
import DocumentTitle from 'react-document-title'
import InfiniteScroll from 'react-infinite-scroller'

import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'

import Title from '../../../components/Title'
import HouseholdRow from './HouseholdRow'
import SearchBar from './SearchBar'
import RefreshSnackBar from './RefreshSnackbar'
import CircularProgress from '@material-ui/core/CircularProgress'

import { TILE_TYPES } from './enums'
import { BEHAVIOUR_STATUS } from '@intelicare/shared/enums'

// Snacking
import BehaviourTooltip from './BehaviourRollup/BehaviourTooltip'
import Snackbar from '@material-ui/core/Snackbar';
import SystemTooltipView from './SystemHealth/SystemTooltipView'
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import HealthMetricsTooltipView from './HealthMetrics/HealthMetricsTooltipView'

import { HouseholdListTable } from './HouseholdListTable/HouseholdListTable'

import { appInsights } from '../../../api/AppInsights'

const loader = (
  <Grid key='loader' container item xs={12} justifyContent='center' alignContent='center'>
    <CircularProgress style={{ padding: 10 }}></CircularProgress>
  </Grid>
)

export default class HouseholdListView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      households: [],
      refreshSnackOpen: false,
      searchQuery: this.props.lastSearchQuery ? this.props.lastSearchQuery : undefined,
      order: 'triage',
      householdStatus: "monitored",
      systemHealthStatus: "",
      triagePriority: "",
      showSnack: false,
      householdSnack: {},
      householdSnackIcon: "",
      BehaviourClassification: "",
      BehaviourClassificationFriendly: "",
      classification: "",
      snackType: "",
      healthMetricsStatus: BEHAVIOUR_STATUS.UNKNOWN,
    }
  }

  loadItems() {
    const {
      households,
      getHouseholdListNextPage,
      hasMore,
    } = this.props

    if (hasMore) return getHouseholdListNextPage(households.length, this.state.searchQuery, this.state.order, this.state.householdStatus)
  }

  search = (searchQuery, order, householdStatus, systemHealthStatus, triagePriority) => {
    this.setState(
      {
        ...this.state,
        searchQuery: searchQuery,
        order: order,
        householdStatus: householdStatus,
        systemHealthStatus: systemHealthStatus,
        triagePriority: triagePriority,
      })

    this._purgeHouseholds()
  }

  _purgeHouseholds = () => {
    const { purgeHouseholds, useNewLook } = this.props

    this.setState(state => ({ ...state, refreshSnackOpen: false }))
    purgeHouseholds()
  }

  staleHousehold() {
    this.setState(state => ({ ...state, refreshSnackOpen: true }))
  }

  // Callback from onMouseOver in BehaviourRollupView
  onShowSnack = (snackType, showSnack, household, icon, BehaviourClassification, BehaviourClassificationFriendly,
    classification, healthMetricsStatus = BEHAVIOUR_STATUS.UNKNOWN) =>
  {
    if(showSnack) return this.setState(
      {
        snackType,
        showSnack,
        householdSnack: household,
        householdSnackIcon: icon,
        BehaviourClassification,
        BehaviourClassificationFriendly,
        classification,
        healthMetricsStatus,
      })

    this.setState({ showSnack })
  }


  SlideTransition(props) {
    return <Slide {...props} direction="right" />;
  }

  componentDidMount()
  {
    appInsights.trackPageView({ name: "Household List" })
  }

  render() {
    const {
      households,
      viewHousehold,
      useHouseholdv2,
      isDeveloper,
      isSupport,
      isDemoUser,
      hasMore,
      lastSearchQuery,
      getHousehold,
      useNewLook,
    } = this.props

    const { showSnack, householdSnack, householdSnackIcon, BehaviourClassification,
      BehaviourClassificationFriendly, classification, snackType, healthMetricsStatus } = this.state

    if (households.find((o) => o.stale) && !this.state.refreshSnackOpen)
      this.staleHousehold()

    let listItems = [<div key='none'></div>]
    for (let household of households) {
      if (!household.householdId) continue
      listItems.push(
        <div key={household.householdId}>
          <HouseholdRow
            onShowSnack={this.onShowSnack}
            fetchActivityfeedV2={this.props.fetchActivityfeedV2}
            activityStreamEventsError={this.props.activityStreamEventsError}
            isGettingActivityEvents={this.props.isGettingActivityEvents}
            activityStreamEvents={this.props.activityStreamEvents}
            key={household.householdId}
            household={household}
            viewHousehold={viewHousehold}
            useHouseholdv2={useHouseholdv2}
            isDeveloper={isDeveloper}
            isSupport={isSupport}
            isDemoUser={isDemoUser}
            getHousehold={getHousehold}
          />
          <Divider
          />
        </div >
      )
    }


    return (
      <div>
        <DocumentTitle title='Households' />
        <Title
          title="Households"
          pageButtons={
            // This one here
            <SearchBar search={this.search} lastSearchQuery={lastSearchQuery} householdStatus={this.state.householdStatus} />
          } />
        <div className='col-xs-12 content--container-content col-main-content'>
          {useNewLook ?
            <HouseholdListTable
              onShowSnack={this.onShowSnack}
              fetchActivityfeedV2={this.props.fetchActivityfeedV2}
              activityStreamEventsError={this.props.activityStreamEventsError}
              isGettingActivityEvents={this.props.isGettingActivityEvents}
              activityStreamEvents={this.props.activityStreamEvents}
              households={households}
              viewHousehold={viewHousehold}
              useHouseholdv2={useHouseholdv2}
              isDeveloper={isDeveloper}
              isSupport={isSupport}
              isDemoUser={isDemoUser}
              getHousehold={getHousehold}
              pageStart={0}
              loadMore={this.loadItems.bind(this)}
              hasMore={hasMore}
              loader={loader}
            />
            : <InfiniteScroll
              pageStart={0}
              loadMore={this.loadItems.bind(this)}
              hasMore={hasMore}
              loader={loader}>
              {listItems}
            </InfiniteScroll> }
        </div>
        <RefreshSnackBar
          open={this.state.refreshSnackOpen}
          purgeHouseholds={this._purgeHouseholds}
        />

        {/* Snacking */}
        {showSnack && <Snackbar
          key={this.tileId}
          TransitionComponent={this.SlideTransition}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          onClose={() => this.setState({ showSnack: false })}
          open={showSnack}>
          <Box boxShadow={24} style={styles.snackStyle}>

            {snackType === TILE_TYPES.BEHAVIOUR ? <BehaviourTooltip
              isDeveloper={isDeveloper}
              activityStreamEventsError={this.props.activityStreamEventsError}
              isGettingActivityEvents={this.props.isGettingActivityEvents}
              activityStreamEvents={this.props.activityStreamEvents}
              household={householdSnack}
              behaviourType={BehaviourClassification[classification]}
              icon={householdSnackIcon}
              classificationFriendlyName={BehaviourClassificationFriendly[classification]}
            />
              : snackType === TILE_TYPES.SYSTEM_HEALTH ?
                <SystemTooltipView
                  household={householdSnack}
                  status={householdSnack.systemHealthStatus}
                  sensors={householdSnack.data ? householdSnack.data.sensors : undefined}
                /> :
                // HEATLH_METRICS
                <HealthMetricsTooltipView
                  healthMetricsStatus={healthMetricsStatus}
                  household={householdSnack}
                />}
          </Box>


        </Snackbar> }

      </div >
    )
  }
}

const styles = {
  snackStyle: { margin: 0, padding: 12, textAlign: "left", backgroundColor: "#F5F5F5", borderRadius: 10 },
}

HouseholdListView.propTypes = {
  households: PropTypes.array,
  getHouseholdListNextPage: PropTypes.func,
  viewHousehold: PropTypes.func,
  useHouseholdv2: PropTypes.bool,
  isDeveloper: PropTypes.bool,
  isSupport: PropTypes.bool,
  isDemoUser: PropTypes.bool,
  purgeHouseholds: PropTypes.func,
  hasMore: PropTypes.bool,
  lastSearchQuery: PropTypes.string,
  getHousehold: PropTypes.func,
  queryHelperText: PropTypes.string,
  fetchActivityfeedV2: PropTypes.func,
  activityStreamEventsError: PropTypes.any,
  isGettingActivityEvents: PropTypes.bool,
  activityStreamEvents: PropTypes.array,
  useNewLook: PropTypes.bool,
}
