import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'

import React from 'react'
import Insights from '@mui/icons-material/Insights'

interface NoMetricDataProps
{
  currentItem: string
}

export const NoMetricData = ({ currentItem }: NoMetricDataProps) =>
{
  return(
    <Card style={styles.container}>

      <div style={{ flexDirection: 'column', textAlign: 'center' }}>
        <Insights style={styles.insightIcon} />
        {currentItem === "Select a metric" ?
          <Typography variant="h5" color="textSecondary">Select a metric</Typography>
          :
          <Typography variant="h5" color="textSecondary">No data for this metric</Typography>}

      </div>

    </Card>
  )
}

const styles = {
  container: {
    height: 400,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    margin: 24,
  },
  insightIcon: {
    color: 'grey',
    fontSize: 100,
  },
}