import {
  ADD_HOUSEHOLD_TO_ORGANISATION_BEGIN,
  ADD_HOUSEHOLD_TO_ORGANISATION_CLEAR,
  ADD_HOUSEHOLD_TO_ORGANISATION_ERROR,
  ADD_HOUSEHOLD_TO_ORGANISATION_SUCCESS,
  ADD_ORGANISATION_BEGIN,
  ADD_ORGANISATION_CLEAR,
  ADD_ORGANISATION_ERROR,
  ADD_ORGANISATION_SUCCESS,
  ADD_ROLE_BEGIN,
  ADD_ROLE_CLEAR,
  ADD_ROLE_ERROR,
  ADD_ROLE_SUCCESS,
  ADD_USER_TO_ORGANISATION_BEGIN,
  ADD_USER_TO_ORGANISATION_CLEAR,
  ADD_USER_TO_ORGANISATION_ERROR,
  ADD_USER_TO_ORGANISATION_SUCCESS,
  DELETE_ORGANISATION_BEGIN,
  DELETE_ORGANISATION_CLEAR,
  DELETE_ORGANISATION_ERROR,
  DELETE_ORGANISATION_SUCCESS,
  GET_USER_DETAILS_BEGIN,
  GET_USER_DETAILS_CLEAR,
  GET_USER_DETAILS_ERROR,
  GET_USER_DETAILS_FOR_CACHE_BEGIN,
  GET_USER_DETAILS_FOR_CACHE_ERROR,
  GET_USER_DETAILS_FOR_CACHE_SUCCESS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_ORG_DETAILS_BEGIN,
  GET_USER_ORG_DETAILS_CLEAR,
  GET_USER_ORG_DETAILS_ERROR,
  GET_USER_ORG_DETAILS_SUCCESS,
  LIST_ORGANISATIONS_BEGIN,
  LIST_ORGANISATIONS_ERROR,
  LIST_ORGANISATIONS_SUCCESS,
  REMOVE_ROLE_BEGIN,
  REMOVE_ROLE_CLEAR,
  REMOVE_ROLE_ERROR,
  REMOVE_ROLE_SUCCESS,
  REMOVE_USER_FROM_ORGANISATION_BEGIN,
  REMOVE_USER_FROM_ORGANISATION_CLEAR,
  REMOVE_USER_FROM_ORGANISATION_ERROR,
  REMOVE_USER_FROM_ORGANISATION_SUCCESS,
  SEARCH_ORGANISATIONS_BEGIN,
  SEARCH_ORGANISATIONS_CLEAR,
  STORE_HOUSEHOLD_SEARCH_RESULTS,
  VIEW_ORGANISATION_DETAILS } from "./actions";

import { ActionTypes, OrganisationStore } from "./interfaces"
import { addUserToOrganisationReducer, updateUserCache } from "./reducerFunctions";



export const initialOrganisationState: OrganisationStore = {
  organisations: [],
  filteredOrganisations: [],
  isFetching: false,
  isAddingHouseholdToOrganisation: false,
  successAddingHouseholdToOrganisation: "",
  errorAddingHouseholdToOrganisation: "",
  addUserToOrganisationSuccessResult: "",
  addUserToOrganisationError: "",
  isAddingUserToOrganisation: false,
  removeUserFromOrganisationError: "",
  isRemovingUserFromOrganisation: false,
  removeUserFromOrganisationSuccess: "",
  organisationsListError: "",
  isGettingUserDetails: false,
  isGettingUserWithId: "",
  userDetailsError: "",
  userDetails: {
    isGettingUser: false,
    user: {
      id: "",
      email: "",
      firstName: "",
      lastName: "",
    },
    assignedHouseholds: [],
  },
  userCache: {},
  organisationSearchQuery: "",
  addOrganisationError: "",
  addOrganisationSuccess: "",
  addRoleSuccess: "",
  addRoleError: "",
  householdsSearchResults: [],
  removeRoleSuccess: "",
  removeRoleError: "",
  organisationDetails: { // The current organisation in view
    id: "",
    friendlyName: "",
    lastUpdated: "",
    parent: "",
    access: {},
    children: [],
  },
  isDeletingNode: false,
  deleteNodeError: "",
  deleteNodeSuccess: "",

}


export const organisationReducer = (state = initialOrganisationState, action: ActionTypes) =>
{
  switch(action.type)
  {
    case LIST_ORGANISATIONS_BEGIN:
      return {
        ...state,
        isFetching: true,
        organisationsListError: "",
      }
    case LIST_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        organisations: action.data,
        organisationsListError: "",
      }
    case LIST_ORGANISATIONS_ERROR:
      return {
        ...state,
        isFetching: false,
        organisationsListError: action.error.message,
      }
    case VIEW_ORGANISATION_DETAILS:
      return {
        ...state,
        organisationDetails: action.data,
      }
    case ADD_HOUSEHOLD_TO_ORGANISATION_BEGIN:
      return {
        ...state,
        isAddingHouseholdToOrganisation: true,
      }
    case ADD_HOUSEHOLD_TO_ORGANISATION_SUCCESS:
      return {
        ...state,
        isAddingHouseholdToOrganisation: false,
        successAddingHouseholdToOrganisation: "Successfully added household to organisation",
        organisations: state.organisations.concat(action.data[1]).reverse(),
      }
    case ADD_HOUSEHOLD_TO_ORGANISATION_ERROR:
      return {
        ...state,
        isAddingHouseholdToOrganisation: false,
        errorAddingHouseholdToOrganisation: action.error,
      }
    case ADD_USER_TO_ORGANISATION_BEGIN:
      return {
        ...state,
        isAddingUserToOrganisation: true,
      }
    case ADD_USER_TO_ORGANISATION_SUCCESS:
      return {
        ...state,
        isAddingUserToOrganisation: false,
        addUserToOrganisationSuccessResult: "Successfully added user to organisation",
        organisationDetails: addUserToOrganisationReducer(action.data, state),
      }
    case ADD_USER_TO_ORGANISATION_ERROR:
      return {
        ...state,
        isAddingUserToOrganisation: false,
        addUserToOrganisationError: action.error.message,
      }
    case ADD_USER_TO_ORGANISATION_CLEAR:
      return {
        ...state,
        addUserToOrganisationSuccessResult: "",
        addUserToOrganisationError: "",
      }
    case ADD_HOUSEHOLD_TO_ORGANISATION_CLEAR:
      return {
        ...state,
        errorAddingHouseholdToOrganisation: "",
        successAddingHouseholdToOrganisation: "",
      }
    case SEARCH_ORGANISATIONS_BEGIN:
      return {
        ...state,
        organisationSearchQuery: action.data.query,
        filteredOrganisations: state.organisations.filter(organisation => organisation && organisation.friendlyName && organisation.friendlyName.toLowerCase().includes(action.data.query.toLowerCase())),
      }
    case SEARCH_ORGANISATIONS_CLEAR:
      return {
        ...state,
        organisationSearchQuery: "",
        filteredOrganisations: [],
      }
    case REMOVE_USER_FROM_ORGANISATION_BEGIN:
      return {
        ...state,
        isRemovingUserFromOrganisation: true,
      }
    case REMOVE_USER_FROM_ORGANISATION_SUCCESS:
      return {
        ...state,
        isRemovingUserFromOrganisation: false,
        removeUserFromOrganisationSuccess: "Successfully removed user from this organisation",
      }
    case REMOVE_USER_FROM_ORGANISATION_ERROR:
      return {
        ...state,
        isRemovingUserFromOrganisation: false,
        removeUserFromOrganisationError: action.error,
      }
    case REMOVE_USER_FROM_ORGANISATION_CLEAR:
      return {
        ...state,
        removeUserFromOrganisationError: "",
        removeUserFromOrganisationSuccess: "",
      }
    case STORE_HOUSEHOLD_SEARCH_RESULTS:
      return {
        ...state,
        householdsSearchResults: action.data,
      }
    case GET_USER_ORG_DETAILS_BEGIN:
      return {
        ...state,
        isGettingUserDetails: true,
      }
    case GET_USER_ORG_DETAILS_SUCCESS:
      return {
        ...state,
        isGettingUserDetails: false,
        userDetails: {
          ...state.userDetails,
          assignedHouseholds: action.data,
        },
      }
    case GET_USER_ORG_DETAILS_ERROR:
      return {
        ...state,
        isGettingUserDetails: false,
        userDetailsError: action.error,
      }
    case GET_USER_ORG_DETAILS_CLEAR:
      return {
        ...state,
        userDetails: {
          user: {
            id: "",
            firstName: "",
            lastName: "",
            email: "",
          },
          assignedHouseholds: [],
        },
        userDetailsError: "",
      }
    case ADD_ORGANISATION_BEGIN:
      return {
        ...state,
        isFetching: true,
      }
    case ADD_ORGANISATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        addOrganisationSuccess: "Successfully added a new organisation",
        organisations: state.organisations.concat(action.data[1]).reverse(),
      }
    case ADD_ORGANISATION_ERROR:
      return {
        ...state,
        isFetching: false,
        addOrganisationError: action.error.message,
      }
    case ADD_ORGANISATION_CLEAR:
      return {
        ...state,
        addOrganisationSuccess: "",
        addOrganisationError: "",
      }
    case ADD_ROLE_BEGIN:
      return {
        ...state,
        isFetching: true,
      }
    case ADD_ROLE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        addRoleSuccess: "Successfully added new role",
      }
    case ADD_ROLE_ERROR:
      return {
        ...state,
        isFetching: false,
        addRoleError: action.error.message,
      }
    case ADD_ROLE_CLEAR:
      return {
        ...state,
        addRoleSuccess: "",
        addRoleError: "",
      }
    case REMOVE_ROLE_BEGIN:
      return {
        ...state,
        isFetching: true,
      }
    case REMOVE_ROLE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        removeRoleSuccess: "Successfully removed role for this user!",
      }
    case REMOVE_ROLE_ERROR:
      return {
        ...state,
        isFetching: false,
        removeRoleError: action.error.message,
      }
    case REMOVE_ROLE_CLEAR:
      return {
        ...state,
        removeRoleError: "",
        removeRoleSuccess: "",
      }
    case DELETE_ORGANISATION_BEGIN:
      return {
        ...state,
        isDeletingNode: true,
      }
    case DELETE_ORGANISATION_SUCCESS:
      return {
        ...state,
        isDeletingNode: false,
        deleteNodeSuccess: "Successfully deleted access group!",
      }
    case DELETE_ORGANISATION_ERROR:
      return {
        ...state,
        isDeletingNode: false,
        deleteNodeError: action.error.message,
      }
    case DELETE_ORGANISATION_CLEAR:
      return {
        ...state,
        deleteNodeSuccess: "",
        deleteNodeError: "",
      }
    case GET_USER_DETAILS_BEGIN:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isGettingUser: true,
        },
      }
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isGettingUser: false,
          user: action.data.user,
        },
      }
    case GET_USER_DETAILS_ERROR:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isGettingUser: false,
          getUserDetailsError: action.error.message,
        },
      }
    case GET_USER_DETAILS_CLEAR:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          getUserDetailsError: "",
        },
      }
    case GET_USER_DETAILS_FOR_CACHE_BEGIN:
      return {
        ...state,
        isGettingUserWithId: action.data.userId,
      }
    case GET_USER_DETAILS_FOR_CACHE_SUCCESS:
      return {
        ...state,
        isGettingUserWithId: "",
        userCache: updateUserCache(action.data.user, state),
      }
    case GET_USER_DETAILS_FOR_CACHE_ERROR:
      return {
        ...state,
        isGettingUserWithId: "",
      }
    default:
      return state;
  }
}