import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

// @ts-ignore
import DocumentTitle from 'react-document-title'
import Title from '../../../components/Title'

// Material Components
import Fab from '@material-ui/core/Fab'
import Zoom from '@mui/material/Zoom';

// Material Icons
import AddIcon from '@mui/icons-material/Add'
import { OrganisationList } from './OrganisationList/OrganisationList'
import { SearchBar } from './Shared/SearchBar'
import { CreateOrganisationModal } from './Modals/CreateEditOrganisation/CreateOrganisationModal'
import { useEffect } from 'react';
import { ApplicationState } from '../../../interfaces/reduxState';
import { LoadingOrgsList } from './OrganisationList/LoadingOrgsList';
import { listOrganisationsBegin, searchOrganisationsBegin, searchOrganisationsClear } from '../../../redux/domains/organisations/actionCreators';
import { ErrorView } from './Shared/ErrorView';

export const OrganisationsView = () =>
{
  const [ showCreateOrganisation, setOpenCreateOrgModal ] = useState(false)

  const organisations = useSelector(({ organisations }: ApplicationState) => organisations.organisations)
  const organisationsListError = useSelector(({ organisations }: ApplicationState) => organisations.organisationsListError)

  const filteredOrganisations = useSelector(({ organisations }: ApplicationState) => organisations.filteredOrganisations)
  const isFetching = useSelector(({ organisations }: ApplicationState) => organisations.isFetching)
  const organisationSearchQuery = useSelector(({ organisations }: ApplicationState) => organisations.organisationSearchQuery)

  const dispatch = useDispatch()

  useEffect(() => {

    dispatch(listOrganisationsBegin())

  }, [])

  return (
    <div style={styles.container}>
      <DocumentTitle title='Organisations' />
      <Title title="Organisations" />
      <SearchBar
        value={organisationSearchQuery}
        onTextChange={(text: string) => dispatch(searchOrganisationsBegin(text))}
        title="Search organisations"
        onClear={() => dispatch(searchOrganisationsClear())}
      />
      {!isFetching && !organisationsListError
        ? <OrganisationList organisations={organisationSearchQuery ? filteredOrganisations : organisations} />
        : !isFetching && organisationsListError ? <ErrorView error={organisationsListError} /> :
          <LoadingOrgsList /> }
      <Zoom
        in={true}
        timeout={250}
        style={{
          transitionDelay: `250ms`,
        }}
        unmountOnExit
      >
        <Fab
          onClick={() => setOpenCreateOrgModal(true)}
          color="primary"
          style={styles.fab}><AddIcon /></Fab>
      </Zoom>
      <CreateOrganisationModal
        parentOuId="intelicare"
        open={showCreateOrganisation}
        handleClose={() => setOpenCreateOrgModal(false)}
      />
    </div>
  )
}

const styles = {
  container: {
    marginLeft: 12,
    marginRight: 12,
    paddingTop: 12,
  },
  fab: {
    margin: 0,
    top: 'auto' as 'auto',
    right: 50,
    bottom: 50,
    left: 'auto' as 'auto',
    position: 'fixed' as 'fixed',
  },
}