import React, { useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import InputAdornment from '@material-ui/core/InputAdornment';
import { IBehaviourData } from "@intelicare/shared/interfaces/behaviours/Behaviour"

interface MealPrepBehaviour extends IBehaviourData {
  warnLimitInHours: number;
}

type ConfigureMealPrepAlertModalProps = {
  open: boolean,
  handleClose: () => void,
  behaviour: MealPrepBehaviour,
  onUpdate: (behaviour: MealPrepBehaviour) => void;
}

export const ConfigureMealPrepAlertModal = (props: ConfigureMealPrepAlertModalProps) => {
  const { open, handleClose, behaviour, onUpdate } = props
  const [warnLimit, setWarnLimit] = useState(String(behaviour.warnLimitInHours))
  const [errMsg, setErrMsg] = useState("")

  const validateLimit = (value: string) => {
    return /^[0-9]*$/.test(value) && value.length <= 3
  }

  const resetFields = () => {
    setErrMsg("")
  }

  const hasBeenModified = () => {
    return warnLimit !== String(behaviour.warnLimitInHours)
  }

  const validateInputs = () => {
    if (!warnLimit) {
      setErrMsg("Field is empty")
      return false
    }

    if (Number(warnLimit) < 24 || Number(warnLimit) > 48) {
      setErrMsg("Limit must be within 24-48 hours")
      return false
    }

    setErrMsg("")
    return true
  }

  const onSave = () => {
    if (validateInputs()) {
      onUpdate({ ...behaviour, warnLimitInHours: Number(warnLimit) })
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onEnter={() => resetFields()}
      open={open}
      onClose={handleClose}
      aria-labelledby="configure-meal-prep-alert-dialog-title"
      aria-describedby="configure-meal-prep-alert-dialog-description"
    >
      <DialogTitle>
        <NotificationsIcon
          style={{
            position: "relative",
            top: 5,
            right: 5,
          }}
        />
        {"Configure Meal Prep Alerting"}
      </DialogTitle>

      <DialogContent style={styles.container}>
        <DialogContentText variant='h3'>
          Meal Prep Warning
        </DialogContentText>
        {/* @ts-ignore */}
        <Grid style={styles.inputRow}>
          <DialogContentText>
          Number of hours with no meal prep activity before sending a WARNING notification
          </DialogContentText>
          <TextField
            style={styles.inputField}
            value={warnLimit}
            variant="outlined"
            onChange={(e) => {
              const value = e.target.value
              if (validateLimit(value)) {
                setWarnLimit(value)
              }
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">Hrs</InputAdornment>,
            }}
          />
        </Grid>

        { errMsg && <Alert severity="error">{errMsg}</Alert> }
      </DialogContent>
      <DialogActions>
        <Button disabled={!hasBeenModified()} onClick={resetFields} color="primary">
          Reset
        </Button>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button disabled={!hasBeenModified()} onClick={onSave} color="primary" autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = {
  container: {
    padding: 25,
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputField: {
    width: 90,
    paddingLeft: 50,
    paddingBottom: 10,
  },
};
