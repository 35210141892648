import React, { Component } from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

import InteliIcon from '../../../../../components/InteliIcon'

import BehaviourDetails from './BehaviourDetailsView'

import { DoorStatus } from './DoorStatus/DoorStatus'
//  Setting up values for tooltip
class BehaviourTooltipView extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    household: PropTypes.any.isRequired,
    behaviourType: PropTypes.string.isRequired,
    icon: PropTypes.any.isRequired,
    fetchActivityfeedV2: PropTypes.func,
    activityStreamEventsError: PropTypes.any,
    isGettingActivityEvents: PropTypes.bool,
    activityStreamEvents: PropTypes.array,
    isDeveloper: PropTypes.bool,
  }

  getBehaviours() {
    const { household, behaviourType } = this.props
    if (!household.data.behaviours) return []
    return household.data.behaviours.filter(behaviour => {
      return behaviourType === behaviour.classification && behaviour.enabled
    })
  }

  render() {
    const {
      classes,
      household,
      icon,
      behaviourType,
    } = this.props

    let behaviours = []
    if (household.data && !household.data.isFetching && household.data.behaviours) {
      behaviours = this.getBehaviours()
    }

    return (
      <div className={classes.ToolTip}>
        <div style={styles.headingContainer}>
          <InteliIcon name="home" family="awesome" size={24} style={styles.headingIcon}/>
          <Typography variant='h6'>{household.friendlyName}</Typography>
        </div>
        <div className={classes.LoadingData}>
          {household.data && household.data.isFetching &&
            <CircularProgress
              variant='indeterminate'
              size={30}
              align='center'
              style={{ alignSelf: 'center' }}
              color='primary'
            />
          }
          {behaviours.length > 0 &&
            <BehaviourDetails household={household.data} behaviours={behaviours} icon={icon} />
          }
          {household.data && household.data.isFetching === false && behaviours.length === 0 &&
            <div className={classes.NoData}>
              <div className={classes.NoDataIcon}>
                <InteliIcon
                  name={icon.name}
                  family={icon.family}
                  color={icon.color}
                  size={30}
                />
              </div>
              <Typography variant="overline">No data to display</Typography>
            </div>
          }
        </div>
        {behaviourType === "activity" &&
         <DoorStatus
           fetchActivityfeedV2={this.props.fetchActivityfeedV2}
           activityStreamEventsError={this.props.activityStreamEventsError}
           isGettingActivityEvents={this.props.isGettingActivityEvents}
           activityStreamEvents={this.props.activityStreamEvents}
           householdId={household.householdId} />}
      </div>
    )
  }
}

const styles = {
  headingContainer: {
    flexDirection: 'row',
    display: 'flex',
    padding: 6,
  },
  headingIcon: {
    color: "grey",
    marginRight: 6,
    paddingLeft: 12,
    paddingTop: 3,
  },
}

export default withStyles(styles)(BehaviourTooltipView)
