import React from 'react'
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import moment from 'moment';
import { AuditRecord } from '../../../../api/AuditLogsApi';

interface AuditLogRowProps
{
  row: AuditRecord;
}

export const AuditLogRow = ({ row }: AuditLogRowProps) =>
{
  return(
    <TableRow
      hover
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {`${moment(row.eventTimestamp).calendar()} - (${moment(row.eventTimestamp).fromNow()})`}
      </TableCell>
      <TableCell style={{ maxWidth: 500, wordWrap: 'break-word' }}>{row.description}</TableCell>
      <TableCell align="right">{row.householdFriendlyName}</TableCell>
      <TableCell align="right">{row.isAdmin ? "Yes" : "No"}</TableCell>
      <TableCell align="right">{row && row.userDetails ? `${row.userDetails.email} (${row.userDetails.givenName} ${row.userDetails.surname})` : "Unknown"}</TableCell>
    </TableRow>
  )
}