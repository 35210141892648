import React, { useState } from 'react'
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button'
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import Badge from '@material-ui/core/Badge'
import Tooltip from '@material-ui/core/Tooltip';
import { Theme, withStyles, createStyles } from '@material-ui/core/styles';
import { colors } from '../../../../redux'
import { behaviour } from '../../../../redux'
import { Household } from './HouseholdListTable';
import { BEHAVIOUR_STATUS, BehaviourClassification, BehaviourClassificationFriendly } from '@intelicare/shared/enums';
import InteliIcon from '../../../../components/InteliIcon';
import { TILE_TYPES } from '../enums';

import moment from 'moment'
import { TableCellItem } from './TableCellItem';

const { colorForStatusValue } = colors;
const { getColoredClassificationIcon } = behaviour;

const TIMEOUT_DURATION = 500;

const UNKNOWN = "Unknown"

const {
  alert,
  amber,
  okay,
  disable,
} = colors

const CorrectBehaviourClassification = {
  ACTIVITY: 'activity',
  MEAL_PREP: 'mealPrep',
  CLIMATE: 'climate',
  SLEEP: 'sleep',
  DOMESTIC: 'domesticActivity',
  PRESENCE: 'presence',
}


interface HouseholdListItemProps {
  household: Household;
  onShowSnack: (tileType: TILE_TYPES, showSnack: boolean, household: Household | null, icon: { name: string; family: string; color: any; } | null, bc: any, bcFriendly: any, classification: any, healthMetricsStatus: string) => void;
  fetchActivityfeedV2: (householdId: string, dateStart: string, dateEnd: string) => void;
  activityStreamEventsError: string;
  isGettingActivityEvents: boolean;
  activityStreamEvents: [];
  viewHousehold: (householdId: string, sectionId: string) => void;
  useHouseholdv2: boolean;
  isDeveloper: boolean;
  isSupport: boolean;
  isDemoUser: boolean;
  getHousehold: (householdId: string) => void;
}

export const HouseholdListItem = ({
  household,
  onShowSnack,
  activityStreamEventsError,
  isGettingActivityEvents,
  activityStreamEvents,
  viewHousehold,
  useHouseholdv2,
  getHousehold,
  fetchActivityfeedV2,
}: HouseholdListItemProps) => {

  const [timeoutId, setTimeoutId] = useState(0);

  const getInitials = (friendlyName: string) => {
    try {
      const initial1 = friendlyName.split(" ")[0][0].toUpperCase();
      const initial2 = friendlyName.split(" ")[1][0].toUpperCase();
      return initial1 + initial2;
    }
    catch (error) {
      return "IC"
    }
  }

  const getStatusColor = () => {
    let statusColor = disable // triagePriority === 0
    if (household.triagePriority === 1) statusColor = okay
    if (household.triagePriority === 2) statusColor = amber
    if (household.triagePriority === 3) statusColor = alert
    return statusColor;
  }

  const getAvatar = () => {
    if (!household.avatar) return ""
    if (household.avatar === "BAD_DATA") return ""
    return household.avatar
  }

  const getEvents = () => {
    fetchActivityfeedV2(
      household.householdId,
      moment().subtract(24, "hours").toISOString(),
      moment().toISOString()
    );
  };

  // Private functions
  const onTileHover = (classification: any, tileType: TILE_TYPES) => {


    const id = setTimeout(() => {
      if ((!household.data) || (!household.data.isFetching && !household.data.behaviours)) getHousehold(household.householdId)
      getEvents()


      const statusColor = colorForStatusValue(BEHAVIOUR_STATUS.UNKNOWN)
      // Callback to show the snackbar
      // @ts-ignore
      const icon = getColoredClassificationIcon(BehaviourClassification[classification], statusColor)

      onShowSnack(tileType, true, household, icon, BehaviourClassification, BehaviourClassificationFriendly, classification, getHealthMetricsStatus())
    }, TIMEOUT_DURATION);

    setTimeoutId(id as unknown as number)
  }


  const onTileHoverOut = () => {
    clearTimeout(timeoutId);
    // @ts-ignore
    onShowSnack("", false, null, null, null, null, null)
  }

  const getHealthMetricsStatus = () => {
    let healthMetricsStatus = BEHAVIOUR_STATUS.UNKNOWN
    if (!household.healthMetricsEnabled) {
      healthMetricsStatus = BEHAVIOUR_STATUS.UNKNOWN
    } else if (household.healthMetricsRollup) {
      for (const metric of Object.values(household.healthMetricsRollup)) {
        if (metric.status === BEHAVIOUR_STATUS.WARNING) {
          healthMetricsStatus = BEHAVIOUR_STATUS.WARNING
          break
        } else if (metric.status === BEHAVIOUR_STATUS.NORMAL) {
          healthMetricsStatus = BEHAVIOUR_STATUS.NORMAL
        }
      }
    }

    return healthMetricsStatus

  }

  const getNotificationDotValue = () => {
    let notifications = 0;
    let alerts = 0;

    if (household.notificationCount > 0) notifications = household.notificationCount
    if (household.alertCount > 0) alerts = household.alertCount;

    return notifications + alerts;
  }

  const NotificationBadge = withStyles((theme: Theme) =>
    createStyles({
      badge: {
        backgroundColor: getStatusColor(),
        color: 'white',
      },
    }),
  )(Badge);


  const AvatarStylesBadge = withStyles((theme: Theme) =>
    createStyles({
      badge: {
        backgroundColor: getStatusColor(),
        color: getStatusColor(),
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          animation: '$ripple 1.2s infinite ease-in-out',
          border: '1px solid currentColor',
          content: '""',
        },
      },
      '@keyframes ripple': {
        '0%': {
          transform: 'scale(.8)',
          opacity: 1,
        },
        '100%': {
          transform: 'scale(2.4)',
          opacity: 0,
        },
      },
    }),
  )(Badge);

  return (
    <TableRow hover key={household.householdId} onClick={() => viewHousehold(household.householdId, "")}>

      <Tooltip title={`Household timezone is ${household.timezone}`}>

        <TableCell component="th" scope="row" style={styles.householdNameFont}>
          <div style={styles.householdNameCell}>

            <NotificationBadge
              badgeContent={getNotificationDotValue()}
              overlap="circular"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {getAvatar() !== "" ?
                <Avatar style={styles.avatar} src={household.avatar} /> :
                <Avatar style={{ ...styles.avatar, backgroundColor: "#63318B" }}>{getInitials(household && household.friendlyName ? household.friendlyName : "")}</Avatar>}
            </NotificationBadge>
            {household.friendlyName}
          </div>

        </TableCell>

      </Tooltip>


      {Object.keys(CorrectBehaviourClassification).map((behaviour, key) => {

        return (
          <TableCellItem
            key={key}
            household={household}
            viewHousehold={viewHousehold}
            onTileHover={(behaviour: string, rollupType: TILE_TYPES) => onTileHover(behaviour, rollupType)}
            onTileHoverOut={onTileHoverOut}
            behaviour={behaviour}
          />
        )
      })}


      {/* Health Metrics */}
      <TableCell style={styles.tableCell}>
        <Grid container justifyContent="center" >
          <InteliIcon style={styles.icon} name="favorite" family="material" color={colorForStatusValue(getHealthMetricsStatus()) || BEHAVIOUR_STATUS.UNKNOWN} />
          <Button
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              viewHousehold(household.householdId, "healthMetrics")
            }}
            onMouseOver={() => onTileHover("HEALTH_METRICS", TILE_TYPES.HEALTH_METRICS)}
            onMouseLeave={onTileHoverOut}
            style={{ color: colorForStatusValue(getHealthMetricsStatus()) || BEHAVIOUR_STATUS.UNKNOWN, ...styles.button }}>
            {getHealthMetricsStatus()}
          </Button>
        </Grid>
      </TableCell>

      {/* System Health */}
      <TableCell align="center" style={styles.tableCell} >
        <Grid container justify="center">
          <InteliIcon style={styles.icon} name="cloud" family="material" color={colorForStatusValue(household.systemHealthStatus) || BEHAVIOUR_STATUS.UNKNOWN} />
          <Button
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              viewHousehold(household.householdId, "system")
            }}
            onMouseOver={() => onTileHover(behaviour, TILE_TYPES.SYSTEM_HEALTH)}
            onMouseLeave={onTileHoverOut}
            style={{ color: colorForStatusValue(household.systemHealthStatus) || BEHAVIOUR_STATUS.UNKNOWN, ...styles.button }}>
            {household.systemHealthStatus || UNKNOWN}
          </Button>
        </Grid>
      </TableCell>

    </TableRow>
  )
}

const styles = {
  householdNameCell: {
    flexDirection: "row" as "row",
    flex: 1,
    display: "flex" as "flex",
    alignItems: 'center',
  },
  avatar: {
    marginRight: 5,
  },
  pill: {
    color: "white",
    borderRadius: 6,
    padding: 3,
    width: 80,
  },
  icon: {
  },
  button: {
    width: 90,
  },
  tableCell: {
    padding: "10px",
  },
  householdNameFont: {
    padding: "10px 10px 10px 16px",
  },
}