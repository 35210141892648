import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux'

// Shared Store
import sharedStore from '../../../../../redux'

// Dialog components
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'

// CSV Export libs
import { CSVLink } from "react-csv"

// Tabs
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

// Table component
import SensorReportTable from '../../../../../components/SensorReportTable'

// Graph
import SensorStateGraph from '../../../../../components/Charts/SensorStateGraph'

// hooks
import useFilter from './hooks/useFilter'
import convertCSV from './hooks/convertCSV'

// Access the call API saga and endpoints
const { callAPI } = sharedStore.userStore
const { DATA_SENSOR_EVENT } = sharedStore.apiEndpoints

let handlerId

// Startup auto refresh
const autoUpdate = (getEvents, householdId) => {

  getEvents({ householdId: householdId, limit: "limit=100" })

  handlerId = setInterval(() => {
    getEvents({ householdId: householdId, limit: "limit=100" })
  }, 15 * 1000)
}

// Cancel the auto update and clear the redux data
const cancelUpdate = (clearEvents, setActiveTab) => {
  clearInterval(handlerId)
  setActiveTab(0)
  clearEvents()
}

const SensorReportModal = ({ open, handleClose, getEvents, clearEvents, household, sensorEvents, isFetching, sensorEventsError }) => {

  SensorReportModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    getEvents: PropTypes.func,
    clearEvents: PropTypes.func,
    household: PropTypes.object,
    sensorEvents: PropTypes.array,
    isFetching: PropTypes.bool,
    sensorEventsError: PropTypes.object,
  }

  // Search filter hook
  const [filteredEvents, setTerm] = useFilter(sensorEvents)
  const [activeTab, setActiveTab] = useState(0)


  return (
    <Dialog
      onEnter={() => autoUpdate(getEvents, household.householdId)}
      onExiting={() => cancelUpdate(clearEvents, setActiveTab)}
      maxWidth="xl"
      fullWidth
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="sensor-report-modal"
      aria-describedby="sensor-events-list"
    >
      <DialogTitle id="alert-dialog-slide-title">{"Sensor Events List"}</DialogTitle>
      <TextField style={{ padding: 12 }} onChange={(e) => setTerm(e.target.value.toLowerCase())} variant="outlined" placeholder="Search"></TextField>

      <DialogContent>

        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(event, newValue) => setActiveTab(newValue)}
          aria-label="Table or Chart">
          <Tab label="Table" />
          <Tab label="Graph" />
        </Tabs>

        {activeTab === 0 ?
          (!isFetching || (sensorEvents && sensorEvents.length > 0)) ?
            <SensorReportTable sensorEvents={filteredEvents} />
            : <div style={{ textAlign: "center" }}><CircularProgress /></div> :
          <SensorStateGraph filteredEvents={filteredEvents} />
        }

      </DialogContent>

      <DialogActions style={{ margin: 10 }}>
        {sensorEvents && sensorEvents.length > 0 &&
          <CSVLink
            filename={`InteliCare-Sensor-Events-Stream-HHID-${household.householdId}.csv`}
            data={convertCSV(sensorEvents)}
            target="_blank">
            <Button style={{ textDecoration: "none" }}>Export to CSV</Button>
          </CSVLink>}
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    getEvents: (params) => dispatch(callAPI(DATA_SENSOR_EVENT(params))),
    clearEvents: () => dispatch({ type: "CLEAR_SENSOR_EVENTS" }),
  }
}

const mapStateToProps = state => {
  return {
    sensorEvents: state.householdsV2.sensorEvents,
    isFetching: state.householdsV2.isFetching,
    sensorEventsError: state.householdsV2.sensorEventsError,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SensorReportModal)