import React, { Component } from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'

import { colors } from '../../../../redux'
const {
  okay,
  alert,
} = colors

export class ActionedIcon extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isActioned: true,
    }
  }

  render() {
    const {
      classes,
    } = this.props

    const {
      isActioned,
    } = this.state

    return (
      <Tooltip
        title={
          <React.Fragment>
            {!isActioned && <Typography>Requires Action</Typography>}
            {isActioned && <Typography>Actioned</Typography>}
          </React.Fragment>
        }
      >
        <div className={classes.Action}>
          {isActioned && <CheckCircleIcon className={classes.ActionPerformed} />}
          {!isActioned && <ErrorIcon className={classes.RequiresAction} />}
        </div>
      </Tooltip >
    )
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  }
}

const styles = theme => ({
  Action: {
    marginRight: 16,
    alignContent: 'center',
  },
  RequiresAction: {
    color: alert,
    // color: theme.palette.alert,
  },
  ActionPerformed: {
    color: okay,
    // color: theme.palette.okay,
  },
})

export default withStyles(styles)(ActionedIcon)
