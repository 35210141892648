import './Fallback.scss'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'


export default class Fallback extends Component {
  static propTypes = {
    targetFriendlyName: PropTypes.string,
    isDeveloper: PropTypes.bool,
    error: PropTypes.object,
    errorInfo: PropTypes.object,
  }

  render() {
    const { targetFriendlyName, isDeveloper, error, errorInfo } = this.props
    return (
      <div style={styles.container} className="fallback-container">
        <div className="fallback-fill">
          <div className="fallback-content">
            <span>
              {targetFriendlyName &&
                <span>Unable to load {targetFriendlyName}</span>
              }
              {!targetFriendlyName &&
                <Typography variant="h5">An error has occurred!</Typography>
              }
            </span>
            {isDeveloper &&
              <Typography component="body2">
                {JSON.stringify(errorInfo, null, 4)}
                {JSON.stringify(error, null, 4)}
              </Typography>
            }
          </div>
        </div>
      </div>
    )
  }
}


const styles = {
  container: {
    paddingTop: 50,
  },
}