import React from 'react'
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import InteliIcon from '../../../../components/InteliIcon';
import { colors } from '../../../../redux'
import { behaviour } from '../../../../redux'
import { Household } from './HouseholdListTable';
import { BEHAVIOUR_STATUS, BehaviourClassification } from '@intelicare/shared/enums';
import { TILE_TYPES } from '../enums';
const { colorForStatusValue } = colors;
const { getColoredClassificationIcon } = behaviour;

interface TableCellItemProps {
  viewHousehold: (householdId: string, behaviour: string) => void;
  onTileHover: (behaviour: string, rollupType: TILE_TYPES) => void;
  household: Household;
  behaviour: string;
  onTileHoverOut: () => void;
}

export const TableCellItem = ({ viewHousehold, onTileHover, household, behaviour, onTileHoverOut }: TableCellItemProps) => {

  //@ts-ignore
  const behaviourStatus = household && household.behaviourRollup && household.behaviourRollup[behaviour] || BEHAVIOUR_STATUS.UNKNOWN
  const color = colorForStatusValue(behaviourStatus) || BEHAVIOUR_STATUS.UNKNOWN
  //@ts-ignore
  const icon = getColoredClassificationIcon(BehaviourClassification[behaviour], color)

  return (
    <TableCell align="center" style={styles.tableCell}>
      <Grid container justifyContent="center">
        <InteliIcon name={icon.name} family="material" color={color} />
        <Button
          size="small"
          onClick={(e) => {
            e.stopPropagation()
            viewHousehold(household.householdId, behaviour)
          }}
          onMouseOver={() => onTileHover(behaviour, TILE_TYPES.BEHAVIOUR)}
          onMouseLeave={onTileHoverOut}
          style={{ color: color, ...styles.button }}>
          {behaviourStatus || "Unknown"}
        </Button>
      </Grid>
    </TableCell>
  )
}

const styles = {
  button: {
    width: 90,

  },
  tableCell: {
    padding: "10px",
  },
}