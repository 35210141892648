import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import ResellerIcon from "@mui/icons-material/SupervisedUserCircle"
import { Reseller } from '../../../../model/Reseller'
import Alert from '@material-ui/lab/Alert'

import "./css/AddResellerModal.css"

class AddResellerModal extends Component {

  state = {
    address: "",
    name: "",
    email: "",
    abn: "",
    contactNumber: "",
    createButtonText: "Create",
    cancelButtonText: "Cancel",
    showNameError: false,
    showEmailError: false,
    showContactNumberError: false,
    showAddressError: false,
    showAbnError: false,
  }

  handleCreateReseller = () => {

    const { createReseller } = this.props

    if (this.validateForm())
    {
      createReseller(new Reseller(this.state.name, this.state.address, this.state.contactNumber, this.state.email, this.state.abn))
    }
  }

  handleOnClose = () => {
    const { onClose, clearCreateReseller } = this.props
    clearCreateReseller()
    this.setState({ showAbnError: false, showAddressError: false, showEmailError: false, showNameError: false, showContactNumberError: false })
    onClose()
  }

  validateForm = () => {
    if (!this.state.address) this.setState({ showAddressError: true })
    if (!this.state.contactNumber) this.setState({ showContactNumberError: true })
    if (!this.state.email) this.setState({ showEmailError: true })
    if (!this.state.name) this.setState({ showNameError: true })
    if (!this.state.abn) this.setState({ showAbnError: true })


    if (this.state.name && this.state.address && this.state.contactNumber && this.state.email && this.state.abn) {
      this.setState({ showAddressError: false, showEmailError: false, showNameError: false, showContactNumberError: false, showAbnError: false })
      return true
    }
    return false
  }

  render() {

    const { open, onClose, createResellerResult, isCreatingReseller, createResellerError, clearCreateReseller, refreshList } = this.props

    return (
      <Dialog
        onExited={() => clearCreateReseller()}
        onExiting={() => createResellerResult && refreshList()}
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={() => this.handleOnClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"><ResellerIcon style={{ position: "relative", top: 4, paddingRight: 10 }} fontSize="medium" />Create Reseller</DialogTitle>
        <DialogContent style={{ paddingTop: 16, paddingBottom: 25 }}>
          <DialogContentText id="alert-dialog-description" style={{ paddingBottom: 20 }}>
            Enter the reseller details below. Once the reseller is created you can assign households to them.
          </DialogContentText>

          <Grid container spacing={3}>

            <Grid item xs={6}>
              <TextField error={this.state.showNameError} onChange={(e) => this.setState({ name: e.target.value })} placeholder={"Company Name"} fullWidth variant="outlined" id="reseller-name" label="Reseller Name" />
            </Grid>

            <Grid item xs={6}>
              <TextField error={this.state.showEmailError} onChange={(e) => this.setState({ email: e.target.value })} fullWidth placeholder={"reception@company.com.au"} variant="outlined" id="reseller-email" label="Email Address" />
            </Grid>

            <Grid item xs={6}>
              <TextField error={this.state.showContactNumberError} onChange={(e) => this.setState({ contactNumber: e.target.value })} fullWidth placeholder={"(08) XXXX-XXXX"} variant="outlined" id="reseller-contact" label="Contact Number" />
            </Grid>

            <Grid item xs={6}>
              <TextField
                type="number"
                error={this.state.showAbnError}
                onChange={(e) => this.setState({ abn: e.target.value })}
                fullWidth
                placeholder={"Business ABN"}
                variant="outlined"
                id="reseller-abn"
                label="ABN" />
            </Grid>

            <Grid item xs={12}>
              <TextField error={this.state.showAddressError} onChange={(e) => this.setState({ address: e.target.value })} fullWidth placeholder="123 Main Street Perth 6000 Western Australia" variant="outlined" id="reseller-address" label="Address" />
            </Grid>

          </Grid>

          {createResellerResult && createResellerResult.resellerId && <Alert style={{ marginTop: 12 }} severity="success">Successfully created the partner!</Alert>}
          {createResellerError && <Alert style={{ marginTop: 12 }} severity="error">{`Error adding the partner. The partner ABN or name may already exist`}</Alert>}


        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {createResellerResult ? "Close" : this.state.cancelButtonText}
          </Button>
          <Button onClick={() => this.handleCreateReseller()} color="primary" autoFocus>
            {isCreatingReseller ? "Creating..." : this.state.createButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

AddResellerModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  createReseller: PropTypes.func,
  isCreatingReseller: PropTypes.bool,
  createResellerError: PropTypes.object || PropTypes.string,
  createResellerResult: PropTypes.object || PropTypes.string,
  clearCreateReseller: PropTypes.func,
  refreshList: PropTypes.func,
}

export default AddResellerModal

