import React from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import UserIcon from '@mui/icons-material/Person'
import RouterIcon from '@mui/icons-material/Router';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import Security from '@mui/icons-material/Lock';

interface TabViewProps
{
  handleTabChange: (i: number) => number;
  currentTabIndex: number;
}

export const TabView = ({ handleTabChange, currentTabIndex }: TabViewProps) =>
{
  return(
    <Tabs
      indicatorColor="secondary"
      textColor="secondary"
      value={currentTabIndex} onChange={(e, i) => handleTabChange(i)} aria-label="Admin Tabs View">
      <Tab label="User Tools" icon={<UserIcon />} />
      <Tab label="Audit Logs" icon={<Security />} />
      <Tab label="Sensor Configuration" icon={<SettingsRemoteIcon />} />
      <Tab label="Gateway Provisioning" icon={<RouterIcon />} />
    </Tabs>
  )
}