import moment from 'moment-timezone'
import { getSleepTimes } from '../../domains/households/householdsSelector'

export const colors = ["#D2233B", "#F4763B", "#94CC79", "#005879", "#34115A"]

const _getYScale = (data, size, d3) => {
  // const info = data.info

  const activityByDateList = data.data

  const sensorKeys = {}

  const maxCount = activityByDateList.reduce((sum, activityByDate) => {
    let result = sum
    for (let activityKey in activityByDate) {
      if (activityKey !== "timestamp") {
        sensorKeys[activityKey] = ''
        if (activityByDate[activityKey] > result) {
          result = activityByDate[activityKey]
        }
      }
    }
    return result
  }, 0)

  const sensorCount = Object.keys(sensorKeys).length

  const sectionHeight = (size.height - 10) / sensorCount

  let scale = d3.scaleLinear()
    .domain([0, maxCount])
    .range([0, sectionHeight])

  return { scale, sectionHeight, sensors: Object.keys(sensorKeys) }
}

const _getTimescale = (data, size, d3, tz) => {
  const info = data.info

  const startTime = moment.unix(info.starttime / 1000).tz(tz)
  const endTime = moment.unix(info.endtime / 1000).tz(tz)

  //create a scale of type time (maps from time value to drawing)
  const timescale = d3.scaleTime()
    .domain([startTime, endTime])
    .range([0, size.width - 40])

  return timescale
}

export const getInfo = (data, size, d3, tz) => {
  let timescale = _getTimescale(data, size, d3, tz)
  let yscale = _getYScale(data, size, d3)

  return {
    timescale: timescale,
    yscale: yscale,
  }
}

export const getNightbars = (timescale, size, d3, household) => {
  let nightticks = timescale.ticks(d3.timeHour.every(24))

  let result = nightticks.map((t) => {
    const { sleepStart, sleepEnd } = getSleepTimes(household, t)
    return {
      x: timescale(sleepStart),
      y: 0,
      width: timescale(sleepEnd) - timescale(sleepStart),
      height: size.height,
    }
  })

  return result
}

// eslint-disable-next-line no-unused-vars
export const getAxisData = (data, size, d3) => {
  const info = data.info

  var startTime = moment(info.starttime).fromNow()

  let max = data.data.map((datum) => datum.value).sort().pop()

  return {
    x: [startTime, "now"],
    y: [0, max],
  }
}
