import ConsoleRoot from './ConsoleRoot/container/ConsoleRoot'
import GatewayFirmware from './GatewayFirmware'
import RemoteCommands from './RemoteCommands'

// Sync route definition
export default {
  path: '/developer',
  component: ConsoleRoot,
  title: 'Developer Console',
  routes: [
    GatewayFirmware,
    RemoteCommands,
  ],
}

