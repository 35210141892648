import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, Switch } from "react-router-dom";
import CustomRouter, { history } from "../hoc/CustomRouter";
import { Provider } from "react-redux";
import { withErrorHandler } from "../hoc/withErrorHandler";
import AppErrorOverlay from "../components/AppErrorOverlay";

import { MuiThemeProvider } from "@material-ui/core/styles";

import AppWrapper from "./AppWrapper";
import { PageProvider } from "../context/PageContext";
import { DataProvider } from "../context/DataContext";
import getTheme from "../themes";
import sharedStore from "../redux";
import BrandLoader from "../components/BrandLoader";

// AUTH-SETUP //
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { config, authRequest } from "../msal/config";

import AuthProvider, { handlePasswordReset } from "./AuthProvider";

const pca = new PublicClientApplication(config);

function scrollToTop() {
  window.scrollTo(0, 0);
  if ("scrollRestoration" in history) {
    history.scrollRestoration = "manual";
  }
}

history.listen((location, action) => {
  if (window.sessionStorage) {
    if (!location.pathname.includes("/login")) {
      window.sessionStorage.setItem(LAST_PATH, location.pathname);
    }
  }
  scrollToTop();
});

const LAST_PATH = sharedStore.locationConstants.LAST_PATH;

class AppContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      themeName: undefined,
      isAuthenticated: false,
    };
  }

  static propTypes = {
    store: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { store } = this.props;
    // check for changes on theme
    // set theme name to re-render
    store.subscribe(() => {
      const themeName = "pro";
      if (themeName !== this.state.themeName)
        this.setState((state) => ({ ...state, themeName }));
    });
  }

  render() {
    const { store } = this.props;
    const { isAuthenticated } = this.state;

    const theme = getTheme(this.state.themeName);


    return (
      <MsalProvider instance={pca}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
          errorComponent={AppErrorOverlay}
          loadingComponent={BrandLoader}
        >
          <Provider store={store}>
            <MuiThemeProvider theme={theme}>
              <PageProvider>
                <DataProvider>
                  {/* Handles storing the token in Redux */}
                  <AuthProvider
                    isAuthenticated={(authState) =>
                      this.setState({ isAuthenticated: authState })
                    }
                  />
                  <CustomRouter history={history}>
                    <Switch>
                      {isAuthenticated && (
                        <PrivateRoute
                          path="/"
                          component={AppWrapper}
                          store={store}
                        />
                      )}
                      <Route path="*" component={BrandLoader} />
                      {handlePasswordReset()}
                    </Switch>
                  </CustomRouter>
                </DataProvider>
              </PageProvider>
            </MuiThemeProvider>
          </Provider>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    );
  }
}

function PrivateRoute({ component: Component, ...rest }) {
  var householdId;
  return (
    <Route
      {...rest}
      render={(props) => {
        const { location } = props;
        var urlState = new URLSearchParams(window.location.search).get("state");
        if (
          urlState &&
          (urlState.startsWith("FitbitId-") || urlState.startsWith("AlexaId-"))
        ) {
          let redirectState = "";
          var code = new URLSearchParams(window.location.search).get("code");
          window.history.replaceState({}, document.timeline, "/?code=" + code);
          if (urlState.startsWith("FitbitId-")) {
            householdId = urlState.substr(9);
            redirectState = "Fitbit";
          } else if (urlState.startsWith("AlexaId-")) {
            householdId = urlState.substr(8);
            redirectState = "Alexa";
          } else {
            householdId = "";
          }
          return (
            <Redirect
              to={{
                pathname: "/households/" + householdId,
                search: "?redirectFrom=" + redirectState,
                state: { from: props.location }, // eslint-disable-line
              }}
            />
          );
        }
        if (window.sessionStorage) {
          // For some reason occasionally the auth process returns a url containing B2C tokens in the state - ignore this
          if (
            !location.pathname.includes("/login") &&
            !location.pathname.includes("/state")
          ) {
            window.sessionStorage.setItem(LAST_PATH, location.pathname);
          }
        }

        return <Component {...props} />;
      }}
    />
  );
}

PrivateRoute.propTypes = {
  location: PropTypes.any,
  component: PropTypes.any,
};

export default withErrorHandler(AppErrorOverlay)(AppContainer);
