import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import amber from '@material-ui/core/colors/amber'
import {
  Snackbar,
  SnackbarContent,
  Button,
} from '@material-ui/core'
import WarningIcon from '@mui/icons-material/Warning'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
})

class RefreshSnackbar extends React.Component {


  state = {
    open: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open)
      this.setState(state => ({ ...state, open: this.props.open }))
  }

  handleClick = () => {
    const { purgeHouseholds } = this.props
    purgeHouseholds()
  };

  render() {
    const { classes } = this.props
    const { open } = this.state

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
      // onClose={this.handleClose}
      >
        <SnackbarContent
          // className={clsx(classes[variant], className)}
          classes={{ root: classes.root }}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              <WarningIcon className={clsx(classes.icon)} />
              This list is no longer current as some households have been updated.
            </span>
          }
          action={[
            <Button key="refresh" style={{ color: 'white' }} size="small" onClick={this.handleClick}>
              REFRESH
            </Button>,
          ]}
        />
      </Snackbar>
    )
  }
}

RefreshSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  purgeHouseholds: PropTypes.func.isRequired,
}

export default withStyles(styles)(RefreshSnackbar)
