
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import { getInitials } from '../../../../Organisations/component/Modals/utils/getInitials'
import moment from 'moment'
interface AvatarSectionProps
{
  friendlyName: string;
  lastUpdated: string;
}

export const AvatarSection = ({ friendlyName, lastUpdated }: AvatarSectionProps) =>
{
  return(
    <div style={styles.container}>
      <Avatar style={styles.avatar}>{getInitials(friendlyName)}</Avatar>
      <Typography variant="subtitle2" >{friendlyName}</Typography>
      <Typography variant="caption" color="textSecondary">{lastUpdated && moment(lastUpdated).fromNow()}</Typography>
    </div>
  )
}

const styles = {
  avatar: {
    width: 60,
    height: 60,
    fontSize: 24,
    backgroundColor: "#1ABC9C" ,
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    textAlign: 'center' as 'center',
  },
}