import Typography from "@material-ui/core/Typography"
import Icon from "@material-ui/core/Icon"

export const NoZones = () =>
{
  return(
    <div style={styles.container}>

      <Icon fontSize="large" color="primary">home</Icon>
      <Typography color="textSecondary" variant="subtitle2">No Zones Found</Typography>

    </div>
  )
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column" as 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}