import { Typography } from '@material-ui/core'
import React from 'react'
import InteliIcon from '../../../../components/InteliIcon'
import { colorForStatusValue } from '../../../../redux/shared/util/colors'
import { HEALTH_METRIC_TYPES } from '../../../Household/components/cards/enums'
import moment from 'moment'

type Props = {
  metricFriendlyName: string,
  metricStatus: string,
  metricTimestamp: string,
}

export const HealthMetricsInfoView = (props: Props) => {
  const metricData =  Object.values(HEALTH_METRIC_TYPES).find(metric => metric.friendlyName === props.metricFriendlyName)
  const statusColor = colorForStatusValue(props.metricStatus)
  const styles = {
    statusBadge: {
      fontSize: '0.8em',
      fontWeight: 'bold',
      paddingLeft: 5,
      paddingRight: 5,
      marginRight: 3,
      marginLeft: 3,
      display: 'inline-block',
      borderRadius: 3,
      color: 'white',
      backgroundColor: statusColor,
      flex: 0.8,
      textAlign: 'center',
    },
  }

  return (
    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', height: 60 }}>
      <div style={{ flex: 1, justifyContent: 'center' }}>
        {metricData &&
          <InteliIcon
            size={40}
            name={metricData.icon}
            family={metricData.family}
            color={statusColor}
          />
        }
      </div>

      <div style={{ flex: 2, flexDirection: 'column' }}>
        <Typography variant='body1'>
          {props.metricFriendlyName}
        </Typography>
        <Typography variant='caption'>
          {moment(props.metricTimestamp).fromNow()}
        </Typography>
      </div>

      {/* @ts-ignore */}
      <Typography style={styles.statusBadge}>{props.metricStatus}</Typography>
    </div>
  )
}