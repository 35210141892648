import { PaniEvent } from "@intelicare/shared/interfaces/pani/Pani"
import { useState, useEffect } from "react"
import { PaniApi } from "../../../../../../../api/PaniApi"
import moment from 'moment'

type ShowerEventsHook = [ boolean, PaniEvent[]]

export const useShowerEvents = (token: string, principal: string, paniId: string | undefined, householdId: string): ShowerEventsHook =>
{
  const [ graphData, setGraphData ] = useState<PaniEvent[]>([])
  const [ isLoading, setIsLoading ] = useState(false)

  const getEvents = async () =>
  {
    setIsLoading(true)
    const paniService = new PaniApi(token, principal)
    if(paniId)
    {
      const response = await paniService.getEventsByHouseholdId(householdId, paniId, moment().subtract(30, 'days').toISOString())
      if(response && response.events) setGraphData(response.events)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    getEvents()
  }, [paniId])


  return [isLoading, graphData]
}