import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

class MenuLinkItem extends Component {
  static propTypes = {
    to: PropTypes.string,
    leftIcon: PropTypes.any,
    children: PropTypes.any,
    classes: PropTypes.any,
    selected: PropTypes.bool,
  }
  render() {
    const {
      to,
      leftIcon,
      children,
      selected,
    } = this.props
    return (
      <Link to={to} style={{ textDecoration: 'none' }}>
        <MenuItem selected={selected}>
          {leftIcon &&
            <ListItemIcon>
              {leftIcon}
            </ListItemIcon>
          }
          <Typography variant='subtitle1'>
            {children}
          </Typography>
        </MenuItem>
      </Link>
    )
  }
}

const styles = theme => ({
  primary: {
    // ...theme.mainMenu.text,
    // color: 'black',
  },
})

export default withStyles(styles)(MenuLinkItem)
