export const portalStyles = theme => ({
  title: {
    fontSize: '24px',
    color: theme.palette.primary.contrastText,
  },
  icon: {
    paddingTop: '1.5px',
  },
  closeIcon: {
    color: theme.palette.primary.contrastText,
  },
})