import React, { useState } from 'react'
import { ISensor } from '@intelicare/shared/interfaces/household/ISensor';
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SensorIcon from '@mui/icons-material/Sensors'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch } from 'react-redux'
import { ConfirmDelete } from '../../../ConfirmDelete';
import { removeSensorFromRoom } from '../../../../redux/domains/zones/actionCreators';
import { Room } from '@intelicare/shared/interfaces/gateway/Gateway';
interface SensorItemProps
{
  sensor: ISensor;
  room: Room;
  zoneId: string;
  householdId: string;
}

export const SensorItem = ({ householdId, sensor, room, zoneId }: SensorItemProps) =>
{
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [showRemoveModal, setShowRemoveModal] = useState(false)

  const handleRemoveSensor = () =>
  {
    setShowRemoveModal(false)
    setAnchorEl(null)

    if(sensor.nodeId)
    {
      dispatch(removeSensorFromRoom(householdId, sensor.nodeId))
    }
  }

  return(
    <Card style={styles.container}>
      <CardHeader
        // @ts-ignore
        title={sensor.friendlyname || sensor.friendlyName}
        subheader={`${sensor.product} - ${sensor.roomname}`}
        avatar={<SensorIcon style={styles.icon}/>}
        action={
          <>
            <IconButton onClick={(e: any) => setAnchorEl(e.currentTarget)} aria-label="settings">
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={anchorEl ? true : false}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}>
              <MenuItem onClick={() => {}}>Edit</MenuItem>
              <MenuItem onClick={() => setShowRemoveModal(true)}>Remove</MenuItem>
            </Menu>
          </>
        }
      />

      <ConfirmDelete
        content="Do you want to remove this sensor from this room ? This will remove the sensor from the gateway. You can assign the sensor to another room if you want to move it."
        open={showRemoveModal}
        onConfirmAction={handleRemoveSensor}
        title="Confirm remove sensor"
        handleClose={() => setShowRemoveModal(false)} />
    </Card>
  )
}

const styles = {
  container: {
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'left' as 'left',
  },
  icon: {
    color: 'grey',
  },
}