import { SensorApi } from '../../../../../api/SensorApi'
import { useState, useEffect } from 'react'
import { SensorConfiguration } from '../../../../../model/SensorConfiguration'

interface UseSensorsProps
{
  token: string
}

type UseSensorsResponse = [boolean, SensorConfiguration[], () => void ]

export const useSensors = ({ token }: UseSensorsProps): UseSensorsResponse =>
{

  const [ sensorsList, setSensorsList ] = useState<SensorConfiguration[]>([])
  const [ isGettingSensorList, setIsFetching ] = useState(false)

  useEffect(() => {
    getSensors()
  }, [])

  const getSensors = async () =>
  {
    // Run API call to get the current sensors
    const sensorApi = new SensorApi(token);
    setIsFetching(true)
    const sensorsList = await sensorApi.getSensors()
    if(sensorsList) setSensorsList(sensorsList)
    setIsFetching(false)
  }

  return [ isGettingSensorList, sensorsList, getSensors ]

}