export default theme => ({
  root: {
    marginBottom: 20,
  },
  headerRoot: {
    background: 'white',
  },
  headerTitle: {
    color: 'black',
  },
  headerSubheader: {
    color: 'black',
  },
  action: {},
  expandedIcon: {
    fontSize: 200,
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.short,
    }),
  },
  expandOpen: {
    fontSize: 200,
    transform: 'rotate(180deg)',
  },
  expandIcon: {
    backgroundColor: '#F0F0F0',
    borderRadius: '50%',
  },
})
