import _ from 'lodash'
import access from 'safe-access'
import moment from 'moment'
import Guid from 'guid'

const householdColumns = [
  { label: 'Image', value: 'avatar', type: 'image', sort: 1 },
  { label: 'Name', value: 'friendlyName', type: 'string', sort: 2 },
  { label: 'Actions', value: 'Household', type: 'actions', sort: 3 },
  { label: 'Activity', value: 'activity', type: 'behaviours', sort: 4 },
  { label: 'Climate', value: 'climate', type: 'behaviours', sort: 5 },
  { label: 'Domestic', value: 'domesticActivity', type: 'behaviours', sort: 6 },
  { label: 'Meals', value: 'mealPrep', type: 'behaviours', sort: 7 },
  { label: 'Sleep', value: 'sleep', type: 'behaviours', sort: 8 },
  { label: 'Presence', value: 'presence', type: 'behaviours', sort: 9 },
  { label: 'System', value: 'systemHealth', type: 'systemHealth', sort: 10 },
  { label: 'Social', value: 'social', type: 'behaviours', sort: 11 },
]

export const HOUSEHOLD_AUTHORIZE_BEGIN = 'HOUSEHOLD_AUTHORIZE_BEGIN'
export const HOUSEHOLD_AUTHORIZE_SUCCESS = 'HOUSEHOLD_AUTHORIZE_SUCCESS'
export const HOUSEHOLD_AUTHORIZE_ERROR = 'HOUSEHOLD_AUTHORIZE_ERROR'

export const HOUSEHOLD_CREATE_BEGIN = 'HOUSEHOLD_CREATE_BEGIN'
export const HOUSEHOLD_CREATE_SUCCESS = 'HOUSEHOLD_CREATE_SUCCESS'
export const HOUSEHOLD_CREATE_ERROR = 'HOUSEHOLD_CREATE_ERROR'

export const DELETE_HOUSEHOLD_BY_GATEWAY_ID_BEGIN = 'DELETE_HOUSEHOLD_BY_GATEWAY_ID_BEGIN'
export const DELETE_HOUSEHOLD_BY_GATEWAY_ID_SUCCESS = 'DELETE_HOUSEHOLD_BY_GATEWAY_ID_SUCCESS'
export const DELETE_HOUSEHOLD_BY_GATEWAY_ID_ERROR = 'DELETE_HOUSEHOLD_BY_GATEWAY_ID_ERROR'
export const CLEAR_DELETE_HOUSEHOLD_BY_GATEWAY_ID = 'CLEAR_DELETE_HOUSEHOLD_BY_GATEWAY_ID'

export const HOUSEHOLDS_SENSORS_BEGIN = 'HOUSEHOLDS_SENSORS_BEGIN'
export const HOUSEHOLDS_SENSORS_SUCCESS = 'HOUSEHOLDS_SENSORS_SUCCESS'
export const HOUSEHOLDS_SENSORS_ERROR = 'HOUSEHOLDS_SENSORS_ERROR'

export const REFRESH_HOUSEHOLDS = 'REFRESH_HOUSEHOLDS'

export const GATEWAY_ADD_SENSOR_WAIT_BEGIN = 'GATEWAY_ADD_SENSOR_WAIT_BEGIN'
export const GATEWAY_ADD_SENSOR_WAIT_SUCCESS = 'GATEWAY_ADD_SENSOR_WAIT_SUCCESS'
export const GATEWAY_ADD_SENSOR_WAIT_ERROR = 'GATEWAY_ADD_SENSOR_WAIT_ERROR'

export const GATEWAY_ADD_SENSOR_POLL_BEGIN = 'GATEWAY_ADD_SENSOR_POLL_BEGIN'
export const GATEWAY_ADD_SENSOR_POLL_SUCCESS = 'GATEWAY_ADD_SENSOR_POLL_SUCCESS'
export const GATEWAY_ADD_SENSOR_POLL_ERROR = 'GATEWAY_ADD_SENSOR_POLL_ERROR'

export const GATEWAY_REMOTE_SERVICE_BEGIN = 'GATEWAY_REMOTE_SERVICE_BEGIN'
export const GATEWAY_REMOTE_SERVICE_SUCCESS = 'GATEWAY_REMOTE_SERVICE_SUCCESS'
export const GATEWAY_REMOTE_SERVICE_ERROR = 'GATEWAY_REMOTE_SERVICE_ERROR'
export const CLEAR_GATEWAY_REMOTE_SERVICE = 'CLEAR_GATEWAY_REMOTE_SERVICE'

export const GATEWAY_FIRMWARE_DATA_BEGIN = 'GATEWAY_FIRMWARE_DATA_BEGIN'
export const GATEWAY_FIRMWARE_DATA_SUCCESS = 'GATEWAY_FIRMWARE_DATA_SUCCESS'
export const GATEWAY_FIRMWARE_DATA_ERROR = 'GATEWAY_FIRMWARE_DATA_ERROR'

export const GATEWAY_FIRMWARE_CHECK_BEGIN = 'GATEWAY_FIRMWARE_CHECK_BEGIN'
export const GATEWAY_FIRMWARE_CHECK_SUCCESS = 'GATEWAY_FIRMWARE_CHECK_SUCCESS'
export const GATEWAY_FIRMWARE_CHECK_ERROR = 'GATEWAY_FIRMWARE_CHECK_ERROR'

export const GATEWAY_FIRMWARE_LOGFILE_BEGIN = 'GATEWAY_FIRMWARE_LOGFILE_BEGIN'
export const GATEWAY_FIRMWARE_LOGFILE_SUCCESS = 'GATEWAY_FIRMWARE_LOGFILE_SUCCESS'
export const GATEWAY_FIRMWARE_LOGFILE_ERROR = 'GATEWAY_FIRMWARE_LOGFILE_ERROR'

export const GATEWAY_FIRMWARE_LOGFILE_LIST_BEGIN = 'GATEWAY_FIRMWARE_LOGFILE_LIST_BEGIN'
export const GATEWAY_FIRMWARE_LOGFILE_LIST_SUCCESS = 'GATEWAY_FIRMWARE_LOGFILE_LIST_SUCCESS'
export const GATEWAY_FIRMWARE_LOGFILE_LIST_ERROR = 'GATEWAY_FIRMWARE_LOGFILE_LIST_ERROR'

export const GATEWAY_FIRMWARE_UPDATE_BEGIN = 'GATEWAY_FIRMWARE_UPDATE_BEGIN'
export const GATEWAY_FIRMWARE_UPDATE_SUCCESS = 'GATEWAY_FIRMWARE_UPDATE_SUCCESS'
export const GATEWAY_FIRMWARE_UPDATE_ERROR = 'GATEWAY_FIRMWARE_UPDATE_ERROR'

export const GATEWAY_FIRMWARE_GET_JOB_ID_BEGIN = 'GATEWAY_FIRMWARE_GET_JOB_ID_BEGIN'
export const GATEWAY_FIRMWARE_GET_JOB_ID_SUCCESS = 'GATEWAY_FIRMWARE_GET_JOB_ID_SUCCESS'
export const GATEWAY_FIRMWARE_GET_JOB_ID_ERROR = 'GATEWAY_FIRMWARE_GET_JOB_ID_ERROR'

export const VIEW_HOUSEHOLD_INFORMATION = 'VIEW_HOUSEHOLD_INFORMATION' // Kicks off household saga
export const VIEW_HOUSEHOLD_INFORMATION_DONE = 'VIEW_HOUSEHOLD_INFORMATION_DONE' //indicates household saga is complete

export const VIEW_HOUSEHOLD = 'VIEW_HOUSEHOLD'
export const VIEW_HOUSEHOLD_BY_ID = 'VIEW_HOUSEHOLD_BY_ID'
export const UPDATE_SELECTED_HOUSEHOLD_COLUMNS = 'UPDATE_SELECTED_HOUSEHOLD_COLUMNS'

export const VIEW_BEHAVIOUR_TYPE = 'VIEW_BEHAVIOUR_TYPE'
export const VIEW_BEHAVIOUR_CLASSIFICATION = 'VIEW_BEHAVIOUR_CLASSIFICATION'


export const VIEW_ACTION = 'VIEW_ACTION'
export const RESOLVE_ACTION = 'RESOLVE_ACTION'
export const CLOSE_ACTION = 'CLOSE_ACTION'
export const CREATE_ACTION = 'CREATE_ACTION'

export const VIEW_MESSAGE = 'VIEW_MESSAGE'
export const CLOSE_MESSAGE = 'CLOSE_MESSAGE'

export const CREATE_NOTE_START = 'CREATE_NOTE_START'
export const CREATE_NOTE_REQUEST = 'CREATE_NOTE_REQUEST'
export const CREATE_NOTE_END = 'CREATE_NOTE_END'
export const DELETE_NOTE = 'DELETE_NOTE'

// Used to trigger saga to check refresh logic
export const REQUEST_HOUSEHOLD_DATA = 'REQUEST_HOUSEHOLD_DATA'

export const HOUSEHOLDGATEWAY_BEGIN = 'HOUSEHOLDGATEWAY_BEGIN'
export const HOUSEHOLDGATEWAY_SUCCESS = 'HOUSEHOLDGATEWAY_SUCCESS'
export const HOUSEHOLDGATEWAY_ERROR = 'HOUSEHOLDSGATEWAY_ERROR'

export const HOUSEHOLD_LIST_BEGIN = 'HOUSEHOLD_LIST_BEGIN'
export const HOUSEHOLD_LIST_SUCCESS = 'HOUSEHOLD_LIST_SUCCESS'
export const HOUSEHOLD_LIST_ERROR = 'HOUSEHOLD_LIST_ERROR'

export const HOUSEHOLD_DATA_BEGIN = 'HOUSEHOLD_DATA_BEGIN'
export const HOUSEHOLD_DATA_SUCCESS = 'HOUSEHOLD_DATA_SUCCESS'
export const HOUSEHOLD_DATA_ERROR = 'HOUSEHOLD_DATA_ERROR'

export const HOUSEHOLD_AVATARS_BEGIN = 'HOUSEHOLD_AVATARS_BEGIN'
export const HOUSEHOLD_AVATARS_SUCCESS = 'HOUSEHOLD_AVATARS_SUCCESS'
export const HOUSEHOLD_AVATARS_ERROR = 'HOUSEHOLD_AVATARS_ERROR'

export const UPDATE_AVATAR = 'UPDATE_AVATAR'

export const HOUSEHOLD_CARER_LIST_BEGIN = 'HOUSEHOLD_CARER_LIST_BEGIN'
export const HOUSEHOLD_CARER_LIST_SUCCESS = 'HOUSEHOLD_CARER_LIST_SUCCESS'
export const HOUSEHOLD_CARER_LIST_ERROR = 'HOUSEHOLD_CARER_LIST_ERROR'

export const HOUSEHOLD_CARER_ADD_BEGIN = 'HOUSEHOLD_CARER_ADD_BEGIN'
export const HOUSEHOLD_CARER_ADD_SUCCESS = 'HOUSEHOLD_CARER_ADD_SUCCESS'
export const HOUSEHOLD_CARER_ADD_ERROR = 'HOUSEHOLD_CARER_ADD_ERROR'

export const HOUSEHOLD_CARER_REMOVE_BEGIN = 'HOUSEHOLD_CARER_REMOVE_BEGIN'
export const HOUSEHOLD_CARER_REMOVE_SUCCESS = 'HOUSEHOLD_CARER_REMOVE_SUCCESS'
export const HOUSEHOLD_CARER_REMOVE_ERROR = 'HOUSEHOLD_CARER_REMOVE_ERROR'

export const HOUSEHOLDS_BILLING_PAYMENT_SOURCE_BEGIN = 'HOUSEHOLDS_BILLING_PAYMENT_SOURCE_BEGIN'
export const HOUSEHOLDS_BILLING_PAYMENT_SOURCE_SUCCESS = 'HOUSEHOLDS_BILLING_PAYMENT_SOURCE_SUCCESS'
export const HOUSEHOLDS_BILLING_PAYMENT_SOURCE_ERROR = 'HOUSEHOLDS_BILLING_PAYMENT_SOURCE_ERROR'

export const HOUSEHOLD_USER_BEGIN = 'HOUSEHOLD_USER_BEGIN'
export const HOUSEHOLD_USER_SUCCESS = 'HOUSEHOLD_USER_SUCCESS'
export const HOUSEHOLD_USER_ERROR = 'HOUSEHOLD_USER_ERROR'

export const SLEEP_GRAPH_DATA_BEGIN = 'SLEEP_GRAPH_DATA_BEGIN'
export const SLEEP_GRAPH_DATA_SUCCESS = 'SLEEP_GRAPH_DATA_SUCCESS'
export const SLEEP_GRAPH_DATA_ERROR = 'SLEEP_GRAPH_DATA_ERROR'

export const HOUSEHOLD_SETTING_BULK_BEGIN = 'HOUSEHOLD_SETTING_BULK_BEGIN'
export const HOUSEHOLD_SETTING_BULK_ERROR = 'HOUSEHOLD_SETTING_BULK_ERROR'
export const HOUSEHOLD_SETTING_BULK_SUCCESS = 'HOUSEHOLD_SETTING_BULK_SUCCESS'


//#region Helper functions
const removeAction = (households, action) => {
  if (action === undefined) {
    return households
  }
  return _.map(households, (household) => {
    let actionIndex = _.findIndex(household['actions'], { id: action.id })

    if (actionIndex !== -1) {
      household['actions'].splice(actionIndex, 1)
    }
    return household
  })
}

// Updates an item if exists otherwise creates it
const appendNote = (households, householdDst, noteText) => {
  return _.map(households, (household) => {
    if (household.gatewayId !== householdDst.gatewayId) {
      return household
    }

    let newNotes = _.concat({
      text: noteText,
      pinned: true,
      id: Guid.raw(),
    }, household.notes)

    return {
      ...household,
      notes: newNotes,
    }
  })
}

const removeNote = (households, note) => {
  return _.map(households, (household) => {
    let newNotes = _.reject(household.notes, { id: note.id })

    return {
      ...household,
      notes: newNotes,
    }
  })
}


const addActionToHousehold = (households, action) => {
  return _.map(households, (household) => {
    if (household.gatewayId === action.gatewayid) {
      household.actions = _.concat(household.actions, {
        id: action.id || new Date().getTime(),
        gatewayid: action.gatewayid,
        text: action.text,
        name: household.friendlyName,
        avatar: household.avatar,
        timestamp: new Date().getTime(),
      })
    }
    return household
  })
}

// Behaviours
export function viewBehaviourType(type) {
  return {
    type: VIEW_BEHAVIOUR_TYPE,
    behaviourType: type,
  }
}

// Behaviours
export function viewBehaviourClassification(classification) {
  return {
    type: VIEW_BEHAVIOUR_CLASSIFICATION,
    behaviourClassification: classification,
  }
}

// Actions
export function viewHouseholdInformation(household) {
  return {
    type: VIEW_HOUSEHOLD_INFORMATION,
    household: household,
  }
}

export function finishedLoadingHouseholdView() {
  return {
    type: VIEW_HOUSEHOLD_INFORMATION_DONE,
  }
}

export function viewHousehold(household) {
  return {
    type: VIEW_HOUSEHOLD,
    household: household,
  }
}

export function viewHouseholdByGatewayId(gatewayId) {
  return {
    type: VIEW_HOUSEHOLD_BY_ID,
    gatewayId: gatewayId,
  }
}

export function updateSelectedHouseholdColumns(newColumns) {
  return {
    type: UPDATE_SELECTED_HOUSEHOLD_COLUMNS,
    newColumns: newColumns,
  }
}

export function viewAction(action) {
  return {
    type: VIEW_ACTION,
    action: action,
  }
}

export function closeAction() {
  return {
    type: CLOSE_ACTION,
  }
}

export function createAction(action) {
  return {
    type: CREATE_ACTION,
    action: action,
  }
}

export function resolveAction(action, resolution) {
  return {
    type: RESOLVE_ACTION,
    action: action,
    resolution: resolution,
  }
}

// Message UI State
export function viewMessage(message) {
  return {
    type: VIEW_MESSAGE,
    message: message,
  }
}

export function closeMessage() {
  return {
    type: CLOSE_MESSAGE,
  }
}

// Notes
export function createNoteStart() {
  return {
    type: CREATE_NOTE_START,
  }
}

export function createNoteRequest(household, note) {
  return {
    type: CREATE_NOTE_REQUEST,
    household: household,
    note: note,
  }
}

export function createNoteEnd() {
  return {
    type: CREATE_NOTE_END,
  }
}

export function deleteNote(note) {
  return {
    type: DELETE_NOTE,
    note,
  }
}

export function requestHouseholdData(householdId) {
  return {
    type: REQUEST_HOUSEHOLD_DATA,
    householdId,
  }
}

export function updateAvatar(householdId, avatar) {
  return {
    type: UPDATE_AVATAR,
    id: householdId,
    avatar: avatar,
  }
}


export function refreshHouseholds() {
  return {
    type: REFRESH_HOUSEHOLDS,
  }
}

const initHousehold = (household) => {
  household.behaviours = household.behaviours || []
  household.sleepingStart = household.sleepingStart || 0
  household.sleepingFinish = household.sleepingFinish || 0
  return household
}

const mapHouseholdList = (householdResponse) => {
  return _.map(access(householdResponse, 'documents'), (document) => {
    return _.omit(initHousehold(document), 'links')
  })
}

const addSensorsToHousehold = (households, data) => {
  if (!data) return households
  data.sensors.map(sensor => { sensor.key = sensor.id })
  return _.map(households, (household) => {
    return household.id !== data.householdId ? household : Object.assign({},
      household, { sensors: data.sensors })
  })
}

const addBillingToHousehold = (households, data) => {
  if (!data) return households
  return _.map(households, (household) => {
    if (household.id !== data.householdId)
      return household
    else
      return {
        ...household,
        billing: {
          ...household.billing,
          ...data.billing,
        },
      }
  })
}

const addNomBillerToHousehold = (households, data) => {
  if (!data) return households
  return _.map(households, (household) => {
    if (household.id !== data.householdId)
      return household
    else
      return {
        ...household,
        billing: {
          ...household.billing,
          nominatedBiller: {
            givenName: data.user.givenName,
            surname: data.user.surname,
          },
        },
      }
  })
}

const mapHouseholdData = (households, householdDataResponse) => {
  const data = access(householdDataResponse, 'document')
  return _.map(households, (household) => {
    return !data || household.id !== data.id ? household : Object.assign({},
      household, data, { lastFetched: moment() })
  })
}

const mapAvatarsToHousehold = (households, data, params) => {
  if (params.method === 'POST') return households
  return _.map(households, (household) => {
    if (!data[household.id]) return household
    return {
      ...household,
      avatar: data[household.id].avatar,
    }
  })
}

const updateHouseholdGateway = (households, id, gateway) => {
  return _.map(households, (household) => {
    if (household.gatewayId !== id) {
      return household
    } else {
      return Object.assign({}, household, { gateway: gateway })
    }
  })
}

const updateHouseholdCarers = (households, action) => {
  return households.map(h => {
    if (h.id === action.params.householdId) {
      let household = Object.assign({}, h, { carers: action.data })
      return household
    }
    return h
  })
}

const removeCarer = (households, action) => {
  return households.map(h => {
    if (h.id === action.params.householdId) {
      let household = Object.assign({}, h)
      _.remove(household.carers, c => c.objectId == action.params.userId)
      return household
    }
    return h
  })
}

function addSleepData(households, action) {
  return households.map(h => {
    if (h.id === action.params.householdId) {
      return { ...h, sleepData: action.data }
    }
    return h
  })
}

//#endregion Helper functions

const initialState = {
  deleteStatus: {
    isDeleting: false,
    success: "",
    error: "",
  },
  authorized: { isFetching: false },
  created: { isFetching: false },
  addedCarer: { isFetching: false },
  addSensor: { isFetching: false },
  pollAddSensor: { isFetching: false },
  sensors: { isFetching: false, value: [] },
  gatewayDirectMethod: { isFetching: false },
  gatewayRemoteService: {
    isFetching: false,
    response: undefined,
  },
  isFetchingHouseholds: false,
  households: [],
  householdData: {
    isFetching: false,
    error: undefined,
    householdId: undefined,
    isFetchingAvatar: undefined,
    fetchingAvatarError: undefined,
    fetchingHouseholdCarers: false,
    removingHouseholdCarer: false,
    addingHouseholdCarer: false,
  },
  behaviourType: undefined,
  behaviourClassification: undefined,
  status: 'ic-okay',
  allColumns: householdColumns,
  selectedColumns: _(householdColumns).take(10).value(),
  openAction: undefined,
  openMessage: undefined,
  sensor: {
    fetchingSensorForId: undefined,
    fetchingSensorError: undefined,
  },
  gatewayFirmware: {
    isFetching: false,
    gateways: [],
    jobIds: [],
  },
  gatewayFirmwareLogFile: {
    isFetching: false,
  },
  notes: {
    open: false,
  },
  billing: {
    paymentSource: {
      isFetching: false,
    },
    nominatedBiller: {
      isFetching: false,
    },
  },
  avatars: {
    isFetching: false,
  },
  settings: {
    isSaving: false,
    error: undefined,
  },
  activeHouseholdId: undefined,
}

export default function householdReducer(state = initialState, action) {

  switch (action.type) {

    case HOUSEHOLD_AUTHORIZE_BEGIN:
      return {
        ...state,
        authorized: {
          isFetching: true,
        },
      }

    case HOUSEHOLD_AUTHORIZE_SUCCESS:
      return {
        ...state,
        authorized: {
          isFetching: false,
          response: action.data.response,
        },
      }

    case HOUSEHOLD_AUTHORIZE_ERROR:
      return {
        ...state,
        authorized: {
          isFetching: false,
          error: action.error,
        },
      }

    case HOUSEHOLD_CREATE_BEGIN:
      return {
        ...state,
        created: {
          isFetching: true,
        },
      }

    case HOUSEHOLD_CREATE_SUCCESS:
      return {
        ...state,
        created: {
          isFetching: false,
          response: action.data.response,
          householdId: action.data.documentId,
        },
      }

    case HOUSEHOLD_CREATE_ERROR:
      return {
        ...state,
        created: {
          isFetching: false,
          error: action.error,
        },
      }


    case DELETE_HOUSEHOLD_BY_GATEWAY_ID_BEGIN:
      return {
        ...state,
        deleteStatus: {
          isDeleting: true,
          error: "",
          success: "",
        },
      }

    case DELETE_HOUSEHOLD_BY_GATEWAY_ID_SUCCESS:
      return {
        ...state,
        deleteStatus: {
          isFetching: false,
          error: "",
          success: action.data,
        },
      }

    case DELETE_HOUSEHOLD_BY_GATEWAY_ID_ERROR:
      return {
        ...state,
        deleteStatus: {
          isFetching: false,
          error: action.error,
        },
      }

    case CLEAR_DELETE_HOUSEHOLD_BY_GATEWAY_ID:
      return {
        ...state,
        deleteStatus: {
          isFetching: false,
          error: "",
          success: "",
        },
      }


    case HOUSEHOLDS_SENSORS_BEGIN:
      return {
        ...state,
        sensors: {
          isFetching: true,
        },
      }

    case HOUSEHOLDS_SENSORS_SUCCESS:
      return {
        ...state,
        households: addSensorsToHousehold(state.households, action.data),
        sensors: {
          isFetching: false,
        },
      }

    case HOUSEHOLDS_SENSORS_ERROR:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          isFetching: false,
          error: action.error,
        },
      }

    case HOUSEHOLDS_BILLING_PAYMENT_SOURCE_BEGIN:
      return {
        ...state,
        billing: {
          ...state.billing,
          paymentSource: {
            isFetching: true,
          },
        },
      }

    case HOUSEHOLDS_BILLING_PAYMENT_SOURCE_SUCCESS:
      return {
        ...state,
        households: addBillingToHousehold(state.households, action.data),
        billing: {
          ...state.billing,
          paymentSource: {
            isFetching: false,
          },
        },
      }

    case HOUSEHOLDS_BILLING_PAYMENT_SOURCE_ERROR:
      return {
        ...state,
        billing: {
          ...state.billing,
          paymentSource: {
            isFetching: false,
            error: action.error,
          },
        },
      }

    case HOUSEHOLD_USER_BEGIN:
      return {
        ...state,
        billing: {
          ...state.billing,
          nominatedBiller: {
            isFetching: true,
          },
        },
      }

    case HOUSEHOLD_USER_SUCCESS:
      return {
        ...state,
        households: addNomBillerToHousehold(state.households, action.data),
        billing: {
          ...state.billing,
          nominatedBiller: {
            isFetching: false,
          },
        },
      }

    case HOUSEHOLD_USER_ERROR:
      return {
        ...state,
        billing: {
          ...state.billing,
          nominatedBiller: {
            isFetching: false,
            error: action.error,
          },
        },
      }

    case GATEWAY_ADD_SENSOR_WAIT_BEGIN:
      return {
        ...state,
        addSensor: {
          isFetching: true,
        },
      }

    case GATEWAY_ADD_SENSOR_WAIT_SUCCESS:
      return {
        ...state,
        addSensor: {
          isFetching: false,
          response: action.data,
        },
      }

    case GATEWAY_ADD_SENSOR_WAIT_ERROR:
      return {
        ...state,
        addSensor: {
          isFetching: false,
          error: action.error,
        },
      }

    case GATEWAY_ADD_SENSOR_POLL_BEGIN:
      return {
        ...state,
        pollAddSensor: {
          isFetching: true,
        },
      }

    case GATEWAY_ADD_SENSOR_POLL_SUCCESS:
      return {
        ...state,
        pollAddSensor: {
          isFetching: false,
          response: action.data,
        },
      }

    case GATEWAY_ADD_SENSOR_POLL_ERROR:
      return {
        ...state,
        pollAddSensor: {
          isFetching: false,
          error: action.error,
        },
      }

    case GATEWAY_REMOTE_SERVICE_BEGIN:
      return {
        ...state,
        gatewayRemoteService: {
          response: undefined,
          isFetching: true,
          error: undefined,
        },
      }

    case GATEWAY_REMOTE_SERVICE_SUCCESS:
      return {
        ...state,
        gatewayRemoteService: {
          isFetching: false,
          response: action.data,
        },
      }

    case GATEWAY_REMOTE_SERVICE_ERROR:
      return {
        ...state,
        gatewayRemoteService: {
          isFetching: false,
          error: action.error,
        },
      }

    case CLEAR_GATEWAY_REMOTE_SERVICE:
      return {
        ...state,
        gatewayRemoteService: {
          isFetching: false,
          error: "",
          response: "",
        },
      }

    case GATEWAY_FIRMWARE_DATA_BEGIN:
      return {
        ...state,
        gatewayFirmware: {
          ...state.gatewayFirmware,
          isFetching: true,
          gateways: [],
        },
      }

    case GATEWAY_FIRMWARE_DATA_SUCCESS:
      return {
        ...state,
        gatewayFirmware: {
          ...state.gatewayFirmware,
          isFetching: false,
          gateways: action.data,
        },
      }

    case GATEWAY_FIRMWARE_DATA_ERROR:
      return {
        ...state,
        gatewayFirmware: {
          ...state.gatewayFirmware,
          isFetching: false,
          gateways: [],
        },
      }

    case GATEWAY_FIRMWARE_CHECK_BEGIN:
      return {
        ...state,
        gatewayFirmware: {
          isFetching: true,
        },
      }

    case GATEWAY_FIRMWARE_CHECK_SUCCESS:
      return {
        ...state,
        gatewayFirmware: {
          isFetching: false,
          firmwareStatus: action.data,
        },
      }

    case GATEWAY_FIRMWARE_CHECK_ERROR:
      return {
        ...state,
        gatewayFirmware: {
          isFetching: false,
          firmwareStatusError: action.error,
        },
      }

    case GATEWAY_FIRMWARE_LOGFILE_BEGIN:
      return {
        ...state,
        gatewayFirmwareLogFile: {
          ...state.gatewayFirmwareLogFile,
          logfileData: undefined,
          isFetching: true,
        },
      }

    case GATEWAY_FIRMWARE_LOGFILE_SUCCESS:
      return {
        ...state,
        gatewayFirmwareLogFile: {
          ...state.gatewayFirmwareLogFile,
          logfileData: action.data,
          isFetching: false,
        },
      }

    case GATEWAY_FIRMWARE_LOGFILE_ERROR:
      return {
        ...state,
        gatewayFirmwareLogFile: {
          ...state.gatewayFirmwareLogFile,
          logfileData: undefined,
          isFetching: false,
        },
      }

    case GATEWAY_FIRMWARE_LOGFILE_LIST_BEGIN:
      return {
        ...state,
        gatewayFirmwareLogFile: {
          logfileDataList: undefined,
          isFetching: true,
        },
      }

    case GATEWAY_FIRMWARE_LOGFILE_LIST_SUCCESS:
      return {
        ...state,
        gatewayFirmwareLogFile: {
          logfileDataList: action.data,
          isFetchingList: false,
        },
      }

    case GATEWAY_FIRMWARE_LOGFILE_LIST_ERROR:
      return {
        ...state,
        gatewayFirmwareLogFile: {
          logfileDataList: undefined,
          isFetching: false,
        },
      }


    case GATEWAY_FIRMWARE_UPDATE_BEGIN:
      return {
        ...state,
        gatewayFirmware: {
          ...state.gatewayFirmware,
          isUpdating: true,
        },
      }

    case GATEWAY_FIRMWARE_UPDATE_SUCCESS:
      return {
        ...state,
        gatewayFirmware: {
          ...state.gatewayFirmware,
          jobIds: state.gatewayFirmware.jobIds.concat({ jobId: action.data.jobId, timestamp: new Date() }),
          isUpdating: false,
        },
      }

    case GATEWAY_FIRMWARE_UPDATE_ERROR:
      return {
        ...state,
        gatewayFirmware: {
          ...state.gatewayFirmware,
          isUpdating: false,
        },
      }

    case GATEWAY_FIRMWARE_GET_JOB_ID_BEGIN:
      return {
        ...state,
        isGettingJobStatus: true,
      }

    case GATEWAY_FIRMWARE_GET_JOB_ID_SUCCESS:
      return {
        ...state,
        isGettingJobStatus: false,
        gatewayFirmwareJobStatus: action.data,
      }

    case GATEWAY_FIRMWARE_GET_JOB_ID_ERROR:
      return {
        ...state,
        isGettingJobStatus: false,
      }

    case HOUSEHOLD_LIST_BEGIN:
      return {
        ...state,
        isFetchingHouseholds: true,
      }

    case HOUSEHOLD_LIST_SUCCESS:
      return {
        ...state,
        isFetchingHouseholds: false,
        households: mapHouseholdList(action.data),
        lastFetched: moment(),
        fetchingHouseholdsError: undefined,
      }

    case HOUSEHOLD_LIST_ERROR:
      return {
        ...state,
        isFetchingHouseholds: false,
        fetchingHouseholdsError: action.error,
      }

    case HOUSEHOLD_DATA_BEGIN:
      return {
        ...state,
        householdData: {
          ...state.householdData,
          isFetching: true,
          householdId: action.params.householdId,
        },
      }

    case HOUSEHOLD_DATA_SUCCESS:
      return {
        ...state,
        households: mapHouseholdData(state.households, action.data),
        householdData: {
          ...state.householdData,
          isFetching: false,
        },
      }

    case HOUSEHOLD_DATA_ERROR:
      return {
        ...state,
        householdData: {
          ...state.householdData,
          isFetching: false,
          error: action.error,
        },
      }

    case VIEW_HOUSEHOLD:
      return {
        ...state,
        activeHouseholdId: action.household.id,
      }

    case VIEW_HOUSEHOLD_INFORMATION:
      return {
        ...state,
        householdData: {
          ...state.householdData,
          householdId: action.household.id,
          isLoading: true,
        },
      }

    case VIEW_HOUSEHOLD_INFORMATION_DONE:
      return {
        ...state,
        householdData: {
          ...state.householdData,
          isLoading: false,
        },
      }

    case UPDATE_SELECTED_HOUSEHOLD_COLUMNS:
      return {
        ...state,
        selectedColumns: action.newColumns,
      }

    case VIEW_BEHAVIOUR_TYPE:
      return {
        ...state,
        behaviourType: action.behaviourType,
      }

    case VIEW_BEHAVIOUR_CLASSIFICATION:
      return {
        ...state,
        behaviourClassification: action.behaviourClassification,
      }


    case VIEW_ACTION:
      return {
        ...state,
        openAction: action.action,
      }

    case RESOLVE_ACTION:
      return {
        ...state,
        openAction: undefined,
        households: removeAction(state.households, action.action),
      }

    case CLOSE_ACTION:
      return {
        ...state,
        openAction: undefined,
      }

    case CREATE_ACTION:
      return {
        ...state,
        households: addActionToHousehold(state.households, action.action),
      }

    case HOUSEHOLDGATEWAY_BEGIN:
      return {
        ...state,
        sensor: {
          fetchingSensorForId: action.params.gatewayId,
          isFetching: true,
        },
      }

    case HOUSEHOLDGATEWAY_SUCCESS:
      return {
        ...state,
        households: updateHouseholdGateway(state.households, action.params.gatewayId, action.data),
        sensor: {
          ...state.sensor,
          isFetching: false,
        },
      }

    case HOUSEHOLDGATEWAY_ERROR:
      return {
        ...state,
        sensor: {
          ...state.sensor,
          isFetching: false,
          fetchingSensorError: action.error, // TODO assign to household
        },
      }

    case VIEW_MESSAGE:
      return {
        ...state,
        openMessage: action.message,
      }

    case CLOSE_MESSAGE:
      return {
        ...state,
        openMessage: undefined,
      }

    case CREATE_NOTE_START:
      return {
        ...state,
        notes: {
          open: true,
        },
      }

    case CREATE_NOTE_REQUEST:
      return {
        ...state,
        households: appendNote(state.households, action.household, action.note),
        notes: {
          open: false,
        },
      }

    case CREATE_NOTE_END:
      return {
        ...state,
        notes: {
          open: false,
        },
      }

    case DELETE_NOTE:
      return {
        ...state,
        households: removeNote(state.households, action.note),
      }

    case HOUSEHOLD_AVATARS_BEGIN:
      return {
        ...state,
        avatars: {
          isFetching: true,
        },
      }

    case HOUSEHOLD_AVATARS_SUCCESS:
      return {
        ...state,
        avatars: {
          isFetching: false,
        },
        households: mapAvatarsToHousehold(state.households, action.data.data, action.params),
      }

    case HOUSEHOLD_AVATARS_ERROR:
      return {
        ...state,
        avatars: {
          isFetching: false,
          error: action.error,
        },
      }



    case HOUSEHOLD_CARER_LIST_BEGIN:
      return {
        ...state,
        householdData: { ...state.householdData, fetchingHouseholdCarers: true },
      }

    case HOUSEHOLD_CARER_LIST_SUCCESS:
      return {
        ...state,
        households: updateHouseholdCarers(state.households, action),
        householdData: { ...state.householdData, fetchingHouseholdCarers: false, error: undefined },
      }

    case HOUSEHOLD_CARER_LIST_ERROR:
      return {
        ...state,
        householdData: { ...state.householdData, fetchingHouseholdCarers: false, error: action.error },
      }

    case HOUSEHOLD_CARER_ADD_BEGIN:
      return {
        ...state,
        householdData: {
          ...state.householdData,
          addingHouseholdCarer: true,
        },
        addedCarer: { isFetching: true },
      }

    case HOUSEHOLD_CARER_ADD_SUCCESS:
      return {
        ...state,

        householdData: { ...state.householdData, addingHouseholdCarer: false, error: undefined },
        addedCarer: {
          isFetching: false,
          response: action.data,
        },
      }

    case HOUSEHOLD_CARER_ADD_ERROR:
      return {
        ...state,
        householdData: { ...state.householdData, addingHouseholdCarer: false, error: action.error },
        addedCarer: {
          isFetching: false,
          response: action.error,
        },
      }

    case HOUSEHOLD_CARER_REMOVE_BEGIN:
      return {
        ...state,
        householdData: { ...state.householdData, removingHouseholdCarer: true },
      }

    case HOUSEHOLD_CARER_REMOVE_SUCCESS:
      return {
        ...state,
        households: removeCarer(state.households, action),
        householdData: { ...state.householdData, removingHouseholdCarer: false, error: undefined },
      }

    case HOUSEHOLD_CARER_REMOVE_ERROR:
      return {
        ...state,
        householdData: { ...state.householdData, removingHouseholdCarer: false, error: action.error },
      }

    case SLEEP_GRAPH_DATA_BEGIN:
      return {
        ...state,
        householdData: {
          ...state.householdData,
          fetchingSleepData: true,
        },
      }

    case SLEEP_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        households: addSleepData(state.households, action),
        householdData: {
          ...state.householdData,
          fetchingSleepData: false,
        },
      }

    case SLEEP_GRAPH_DATA_ERROR:
      return {
        ...state,
        householdData: {
          ...state.householdData,
          fetchingSleepData: false,
        },
      }

    case HOUSEHOLD_SETTING_BULK_BEGIN:
      return {
        ...state,
        settings: {
          ...state.settings,
          isSaving: true,
          success: undefined,
          error: undefined,
        },
      }

    case HOUSEHOLD_SETTING_BULK_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          isSaving: false,
          success: true,
          error: undefined,
        },
      }

    case HOUSEHOLD_SETTING_BULK_ERROR:
      return {
        ...state,
        settings: {
          ...state.settings,
          isSaving: false,
          success: false,
          error: action.error,
        },
      }

    default:
      return state
  }
}
