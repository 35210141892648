import { connect } from 'react-redux'
import { history } from '../../hoc/CustomRouter'
import AppWrapperView from './AppWrapperView'

import sharedStore from '../../redux'
import { callAPI } from '../../redux/domains/user/userStore'

const closeMessage = sharedStore.householdsStore.closeMessage
const closeAction = sharedStore.householdsStore.closeAction
const createAction = sharedStore.householdsStore.createAction
const createNoteRequest = sharedStore.householdsStore.createNoteRequest
const createNoteEnd = sharedStore.householdsStore.createNoteEnd
const resolveAction = sharedStore.householdsStore.resolveAction

const getActiveHousehold = sharedStore.householdsSelector.getActiveHousehold
const setAlertsMinimised = sharedStore.notificationsStore.setAlertsMinimised

const areAlertsMinimised = sharedStore.notificationsSelector.areAlertsMinimised
const { triggerViewReport } = sharedStore.reportStore

const { NOTIFICATIONS_MANAGER_SEND } = sharedStore.apiEndpoints

const mapDispatchToProps = (dispatch) => {
  return {
    resolveMessage: (notification) => {
      dispatch(callAPI(NOTIFICATIONS_MANAGER_SEND(notification)))
    },
    createAction: (action) => {
      let newAction = Object.assign({}, action, { status: 'ACTIVE' })
      dispatch(createAction(newAction))
    },
    resolveAction: (action) => {
      let resolvedAction = Object.assign({}, action, { status: 'RESOLVED' })
      dispatch(resolveAction(resolvedAction))
    },
    closeAction: () => {
      dispatch(closeAction())
    },
    closeMessage: () => {
      dispatch(closeMessage())
    },
    createNote: (household, note) => {
      dispatch(createNoteRequest(household, note))
    },
    closeNote: () => {
      dispatch(createNoteEnd())
    },
    setAlertsMinimised: (minimised) => {
      dispatch(setAlertsMinimised(minimised))
    },
    goToHousehold: (householdId, sectionId) => {
      history.push(`/households/${householdId}${sectionId ? `#${sectionId}` : ''}`)
    },
    closeReport: () => dispatch(triggerViewReport(false)),
    viewReport: () => dispatch(triggerViewReport(true)),
    clearSnack: () => dispatch({ type: "NOTIFICATIONS_MANAGER_SEND_CLEAR_SNACK" }),
  }
}

const mapStateToProps = (state, ownProps) => ({
  activeHousehold: getActiveHousehold(state),
  isLoggedIn: state.user.isAuthenticated || false,
  isDeveloper: state.user.isDeveloper || false,
  isSupport: state.user.isSupport || false,
  isDemoUser: state.user.isDemoUser || false,
  inteliCareProUser: state.user.inteliCareProUser || false,
  openMessage: state.households.openMessage,
  messageIsOpen: state.households.openMessage !== undefined,
  isFetchingHouseholds: state.households.isFetchingHouseholds,
  uri: state.reports.uri,
  // alerts
  activeAlerts: state.notifications.activeAlerts,
  minimisedAlerts: areAlertsMinimised(state),
  reportInfo: state.reports,
  showMessageSentSnack: state.notifications.showMessageSentSnack,
  snackType: state.notifications.snackType,
})


export default connect(mapStateToProps, mapDispatchToProps)(AppWrapperView)
