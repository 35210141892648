import moment from 'moment'

export const HOUSEHOLD_DATA_BED_BEGIN = 'HOUSEHOLD_DATA_BED_BEGIN'
export const HOUSEHOLD_DATA_BED_SUCCESS = 'HOUSEHOLD_DATA_BED_SUCCESS'
export const HOUSEHOLD_DATA_BED_ERROR = 'HOUSEHOLD_DATA_BED_ERROR'
export const HOUSEHOLD_DATA_BED_CLEAR = 'HOUSEHOLD_DATA_BED_CLEAR'

export const ACTIVITYGRAPH_BEGIN = 'ACTIVITYGRAPH_BEGIN'
export const ACTIVITYGRAPH_SUCCESS = 'ACTIVITYGRAPH_SUCCESS'
export const ACTIVITYGRAPH_ERROR = 'ACTIVITYGRAPH_ERROR'

export const ACTIVITY_GRAPH_WITH_ZONES_BEGIN = 'ACTIVITY_GRAPH_WITH_ZONES_BEGIN'
export const ACTIVITY_GRAPH_WITH_ZONES_SUCCESS = 'ACTIVITY_GRAPH_WITH_ZONES_SUCCESS'
export const ACTIVITY_GRAPH_WITH_ZONES_ERROR = 'ACTIVITY_GRAPH_WITH_ZONES_ERROR'

export const OVERALLACTIVITYGRAPH_BEGIN = 'OVERALLACTIVITYGRAPH_BEGIN'
export const OVERALLACTIVITYGRAPH_SUCCESS = 'OVERALLACTIVITYGRAPH_SUCCESS'
export const OVERALLACTIVITYGRAPH_ERROR = 'OVERALLACTIVITYGRAPH_ERROR'

export const AVERAGEACTIVITY_BEGIN = 'AVERAGEACTIVITY_BEGIN'
export const AVERAGEACTIVITY_SUCCESS = 'AVERAGEACTIVITY_SUCCESS'
export const AVERAGEACTIVITY_ERROR = 'AVERAGEACTIVITY_ERROR'

// const initialGraphData = {
//   bedSensorGraph:
//   {
//     sensorId: {
//       fetching: false,
//       error: false,
//       data: undefined,
//     }
//   },
//   mealGraph: {
//     fetching: false,
//     error: false,
//     data: undefined,
//   },
// }

// const updateHouseholdGraphsData = (households, householdId, graphType, update) => {

//   const handleAppend = (household, update) => {
//     if (update.append) {
//       return [
//         ...household.graphData[graphType].data,
//         ...update.data,
//       ]
//     } else {
//       return [...update.data]
//     }
//   }

//   return households.map(h => {
//     if (h.householdId === householdId) {
//       if (h.graphData && h.graphData[graphType]) {
//         return {
//           ...h,
//           graphData: {
//             ...h.graphData,
//             [graphType]: {
//               ...h.graphData[graphType],
//               ...update,
//               data: handleAppend(h, update),
//             },
//           },
//         }
//       } else {
//         return {
//           ...h,
//           graphData: {
//             ...h.graphData,
//             [graphType]: {
//               ...update,
//             },
//           },
//         }
//       }
//     } else {
//       return {
//         ...h,
//       }
//     }
//   })
// }

const updateHouseholdbedSensorData = (households, householdId, sensorId, update) => {

  const handleAppend = (household, update) => {
    if (update.append) {
      return [
        ...household.graphData.bedSensorGraph[sensorId].data,
        ...update.data,
      ]
    } else if (update.error) {
      return []
    } else {
      return [...update.data]
    }
  }

  return households.map(h => {
    if (h.householdId === householdId) {
      if (h.graphData && h.graphData.bedSensorGraph && h.graphData.bedSensorGraph[sensorId]) {
        return {
          ...h,
          graphData: {
            ...h.graphData,
            bedSensorGraph: {
              ...h.graphData.bedSensorGraph,
              [sensorId]: {
                ...h.graphData.bedSensorGraph[sensorId],
                ...update,
                data: handleAppend(h, update),
              },
            },
          },
        }
      } else {
        if (h.graphData && h.graphData.bedSensorGraph) {
          return {
            ...h,
            graphData: {
              ...h.graphData,
              bedSensorGraph: {
                ...h.graphData.bedSensorGraph,
                [sensorId]: {
                  ...update,
                },
              },
            },
          }
        } else {
          return {
            ...h,
            graphData: {
              ...h.graphData,
              bedSensorGraph: {
                [sensorId]: {
                  ...update,
                },
              },
            },
          }
        }
      }
    } else {
      return {
        ...h,
      }
    }
  })
}

const updateOverallActivityData = (households, householdId, data) => {
  return households.map(h => {
    if (h.householdId === householdId) {
      return {
        ...h,
        graphData: {
          ...h.graphData,
          overallActivityGraph: {
            isFetching: data.fetching || false,
            error: data.error || undefined,
            data: data.data || [],
          },
        },
      }
    } else {
      return {
        ...h,
      }
    }
  })
}

const updateActivityData = (households, householdId, data) => {
  return households.map(h => {
    if (h.householdId === householdId) {
      return {
        ...h,
        graphData: {
          ...h.graphData,
          activityGraph: {
            isFetching: data.fetching || false,
            error: data.error || undefined,
            data: data.data || [],
          },
        },
      }
    } else {
      return {
        ...h,
      }
    }
  })
}

const updateAverageActivityData = (households, householdId, data) => {
  return households.map(h => {
    if (h.householdId === householdId) {
      return {
        ...h,
        graphData: {
          ...h.graphData,
          averageActivityGraph: {
            isFetching: data.fetching || false,
            error: data.error || undefined,
            data: data.data || [],
          },
        },
      }
    } else {
      return {
        ...h,
      }
    }
  })
}

export default function graphsV2Reducer(households, action) {
  switch (action.type) {
    case HOUSEHOLD_DATA_BED_BEGIN:
      return updateHouseholdbedSensorData(
        households,
        action.params.householdId,
        action.params.sensorId,
        {
          fetching: true,
          error: undefined,
        },
      )

    case HOUSEHOLD_DATA_BED_SUCCESS:
      return updateHouseholdbedSensorData(
        households,
        action.params.householdId,
        action.params.sensorId,
        {
          fetching: false,
          error: undefined,
          data: action.data.data.map(d => ({ ...d, timestamp: moment(d.timestamp).valueOf() })),
          append: action.params.append,
        })

    case HOUSEHOLD_DATA_BED_ERROR:
      return updateHouseholdbedSensorData(
        households,
        action.params.householdId,
        action.params.sensorId,
        {
          fetching: false,
          error: action.error,
        })

    case HOUSEHOLD_DATA_BED_CLEAR:
      return updateHouseholdbedSensorData(
        households,
        action.params.householdId,
        action.params.sensorId,
        {
          fetching: false,
          error: undefined,
          data: undefined,
        })

    case OVERALLACTIVITYGRAPH_BEGIN:
      return updateOverallActivityData(
        households,
        action.params.householdId,
        {
          fetching: true,
          error: undefined,
        })

    case OVERALLACTIVITYGRAPH_SUCCESS:
      return updateOverallActivityData(
        households,
        action.params.householdId,
        {
          fetching: false,
          error: undefined,
          data: action.data,
        })

    case OVERALLACTIVITYGRAPH_ERROR:
      return updateOverallActivityData(
        households,
        action.params.householdId,
        {
          fetching: false,
          error: action.error,
        })

    case ACTIVITYGRAPH_BEGIN:
      return updateActivityData(
        households,
        action.params.householdId,
        {
          fetching: true,
          error: undefined,
        })


    case ACTIVITYGRAPH_SUCCESS:
      return updateActivityData(
        households,
        action.params.householdId,
        {
          fetching: false,
          error: undefined,
          data: action.data,
        })

    case ACTIVITYGRAPH_ERROR:
      return updateActivityData(
        households,
        action.params.householdId,
        {
          fetching: false,
          error: action.error,
        })

    case ACTIVITY_GRAPH_WITH_ZONES_BEGIN:
      return updateActivityData(
        households,
        action.params.householdId,
        {
          fetching: true,
          error: undefined,
        })


    case ACTIVITY_GRAPH_WITH_ZONES_SUCCESS:
      return updateActivityData(
        households,
        action.params.householdId,
        {
          fetching: false,
          error: undefined,
          data: action.data,
        })

    case ACTIVITY_GRAPH_WITH_ZONES_ERROR:
      return updateActivityData(
        households,
        action.params.householdId,
        {
          fetching: false,
          data: [],
          error: action.error,
        })

    case AVERAGEACTIVITY_BEGIN:
      return updateAverageActivityData(
        households,
        action.params.householdId,
        {
          fetching: true,
          error: undefined,
        })

    case AVERAGEACTIVITY_SUCCESS:
      return updateAverageActivityData(
        households,
        action.params.householdId,
        {
          fetching: false,
          error: undefined,
          data: action.data,
        })

    case AVERAGEACTIVITY_ERROR:
      return updateAverageActivityData(
        households,
        action.params.householdId,
        {
          fetching: false,
          error: action.error,
        })

    default:
      return households
  }

}