// {label: "Okay", color: "#8AC56D", x: 15}
const formatChart = (values = []) => {
  const chartData = [["Status", "Count"]]
  let hasData = false

  try {
    values.map(item => {

      if (item.x > 0) {
        hasData = true
      }

      chartData.push([item.label, item.x])
    })

    if (hasData) {
      return chartData
    }
    else {
      chartData.push(["Unknown", 100])
      return chartData
    }


  }
  catch (error) {
    return []
  }
}

export default formatChart