import React from 'react'
import PropTypes from 'prop-types'
import { b2cPolicies } from '../../../msal/config'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import './AppStoreOverlayView.scss'

export class AppStoreOverlayView extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}

  }

  onLogout() {
    sessionStorage.clear()
    localStorage.clear()
    window.location.href = b2cPolicies.authorities.logout.authority
  }

  render() {
    return (
      <div className='parentContainer' style={{ display: 'none' }}>
        <div className='contentContainer'>

          <div className='contentLeftColumn'>
            <div className='textContainer'>
              <Typography variant='h2'>Download our app</Typography>
              <Typography variant="subtitle1">
                It looks like you are an InteliCare@Home customer.
                Please download our mobile app from the store links below.
              </Typography>
            </div>
            <div className='badgeGroupContainer'>
              <div className='badgeContainer'>
                <a href='https://play.google.com/store/apps/details?id=co.intelicare&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                  <img alt="Google Play Badge" src={require('../../../static/google-play-badge.png')} className='googleBadge' />
                </a>
              </div>
              <div className='badgeContainer'>
                <a href="https://itunes.apple.com/au/app/intelicare/id1181728420?mt=8">
                  <img alt="Apple Badge" src={require('../../../static/apple-badge.svg')} className='appleBadge' />
                </a>
              </div>
            </div>
            <div className='textContainer supportMessageText'>
              <Typography variant="body1">
                If you are a InteliCare Pro customer and seeing this message by mistake,<br />
                please contact InteliCare support on <a href='tel:1300001145'>1300 001 145</a><br />
                or via email <a href='mailto:support@intelicare.com.au'>support@intelicare.com.au</a>
              </Typography>
              <p>
                <Button color="primary" variant="contained" onClick={() => this.onLogout()} >Alternatively click here to logout</Button>
              </p>
            </div>
          </div>

          <div className='contentRightColumn'>
            <img alt="InteliCare App Screenshot" src={require('../../../static/app-screenshot.png')} className='screenshot' />
          </div>

        </div>
      </div>
    )
  }
}

AppStoreOverlayView.propTypes = {
  onLogout: PropTypes.func,
}

export default AppStoreOverlayView
