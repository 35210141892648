import React, { useState } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from "@material-ui/core/DialogTitle";
import { RowItem } from "./FirmwareListTable";
import { callAPI } from "../../../../../redux/domains/user/userStore";
import { CLEAR_DELETE_HOUSEHOLD_BY_GATEWAY_ID } from "../../../../../redux/domains/households/householdsStore";
import { apiEndpoints } from "../../../../../redux";
import Alert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";
import { DeleteResultItem } from './DeleteResultItem'
import { Builder } from '../../../../../api/ApiBuilder'

const { DELETE_HOUSEHOLD_BY_GATEWAY_ID } = apiEndpoints;

interface ConfirmDeleteHouseholdProps {
  open: boolean;
  handleClose(): void;
  row: RowItem;
  deleteHouseholdByGatewayId(gatewayId: string, householdId: string): void;
  clearModalResults(householdId?: string): void;
  token: string;
}

const ConfirmDeleteHousehold = ({ open, handleClose, row, deleteHouseholdByGatewayId, clearModalResults, token }: ConfirmDeleteHouseholdProps) => {
  const [householdName, setHouseholdName] = useState("");
  const [isDeleting, setDeleting ] = useState(false);
  const [deleteResults, setDeleteResults ] = useState([]);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteError, setDeleteError ] = useState("");

  const isInputDisabled = (): boolean => {
    if (row.friendlyName === householdName && row.householdId && row.gatewayId) return false;
    return true;
  };

  const handleDeleteHousehold = async () =>
  {
    if(row.gatewayId && row.householdId)
    {
      setDeleting(true)
      const apiBuilder = new Builder(true);
      const requestBody = {
        gatewayId: row.gatewayId,
        householdId: row.householdId,
      }

      const response = await apiBuilder.call("DELETE", `/api/data/household`, requestBody, token);
      setDeleting(false);

      if(response && response.data && response.data.result && response.data.result.length > 0)
      {
        setDeleteSuccess(true)
        setDeleteResults(response.data.result)
      }
      else
      {
        setDeleteError("Error calling the delete household API")
      }
    }
  }

  return (
    <Dialog onExit={() => clearModalResults()} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Confirm Delete Household</DialogTitle>
      <DialogContent>
        <DialogContentText>{`This is will delete the household ${row.friendlyName} and the associated gateway ${row.gatewayId} `}</DialogContentText>
        <TextField onChange={(e) => setHouseholdName(e.target.value)} autoFocus margin="dense" id="name" label="Enter the household's name to confirm" type="text" fullWidth />
      </DialogContent>

      <div style={styles.errorContainer}>
        {deleteError && (
          <Alert variant="filled" severity="error">
            {deleteError}
          </Alert>
        )}

        {deleteResults.map((item: {[key: string]: any}, key) => {

          if(Object.keys(item)[0] === "deleteStripeResult") return
          const title = Object.keys(item)[0];
          const result = item[title]
          const severityType = result.includes("Success") ? "success" : "error"
          return <DeleteResultItem key={key} title={title} result={result} severity={severityType}/>
        })}
      </div>

      <DialogActions>
        {deleteSuccess && <Button onClick={() => clearModalResults(row.householdId)}>
          <Link style={styles.linkStyle} to="/households">
                  Go to households list
          </Link>
        </Button>}
        <Button onClick={handleClose} color="primary">
          {deleteSuccess ? "Close" : "Cancel"}
        </Button>
        {!deleteSuccess && (
          <Button onClick={handleDeleteHousehold} disabled={isInputDisabled()} color="primary">
            {isDeleting ? <CircularProgress size={24} color="secondary"/> : "Confirm"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: any) => {
  return {
    token: state.user.token,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    deleteHouseholdByGatewayId: (gatewayId: string, householdId: string) => dispatch(callAPI(DELETE_HOUSEHOLD_BY_GATEWAY_ID(gatewayId, householdId))),
    clearModalResults: (householdId: string) => dispatch({ type: CLEAR_DELETE_HOUSEHOLD_BY_GATEWAY_ID, householdId: householdId }),
  };
};

const styles = {
  errorContainer: {
    margin: 16,
  },
  linkStyle: {
    paddingLeft: 4,
    textDecoration: "none",
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDeleteHousehold);
