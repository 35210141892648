import { connect } from 'react-redux'
import HouseholdListView from '../components/HouseholdListView'
import { history } from '../../../hoc/CustomRouter'
import { compose } from 'redux'
import { withErrorHandler, Fallback } from '../../../hoc/withErrorHandler'
import sharedStore from '../../../redux'


const {
  getHouseholds,
  householdListHasMore,
} = sharedStore.householdsV2Selector
const callAPI = sharedStore.userStore.callAPI
const {
  HOUSEHOLD_V2_LIST_PAGE,
  HOUSEHOLD_V2_GET,
  HOUSEHOLDS_STATUS,
  HOUSEHOLD_V2_ACTIVITYSTREAM_PREVIEW,
} = sharedStore.apiEndpoints
const purgeHouseholds = sharedStore.householdsV2Store.purgeHouseholds

const mapDispatchToProps = (dispatch) => {
  return {
    getHouseholdListNextPage: (pagePosition, searchQuery, order, householdStatus, systemHealthStatus, triagePriority) => {
      dispatch(callAPI(HOUSEHOLD_V2_LIST_PAGE({
        pageSize: 20,
        pagePosition,
        searchQuery: searchQuery || '*',
        order,
        householdStatus,
        systemHealthStatus,
        triagePriority,
      })))
    },
    viewHousehold: (householdId, sectionId) => {
      history.push(`/households/${householdId}${sectionId ? `#${sectionId}` : ''}`)
    },
    purgeHouseholds: () => {
      dispatch(purgeHouseholds())
    },
    getHousehold: (householdId) => {
      dispatch(callAPI(HOUSEHOLD_V2_GET({
        householdId,
      })))
    },
    fetchStatusSummary: (householdId) => {
      dispatch(callAPI(HOUSEHOLDS_STATUS({ householdId })))
    },
    fetchActivityfeedV2: (householdId, start, end) => {
      if (!householdId) return
      dispatch(callAPI(HOUSEHOLD_V2_ACTIVITYSTREAM_PREVIEW({ householdId, start, end })))
    },
  }
}

const mapStateToProps = (state) => ({
  households: getHouseholds(state),
  hasMore: householdListHasMore(state),
  useHouseholdv2: state.featurePreviews.useHouseholdv2Api,
  isDeveloper: state.user.isDeveloper,
  isSupport: state.user.isSupport,
  isDemoUser: state.user.isDemoUser,
  lastSearchQuery: state.householdsV2.lastSearchQuery,
  activityStreamEventsError: state.householdsV2.activityStreamEventsError,
  isGettingActivityEvents: state.householdsV2.isGettingActivityEvents,
  activityStreamEvents: state.householdsV2.activityStreamEvents,
  useNewLook: state.householdsV2.useNewLook,
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorHandler(Fallback)
)(HouseholdListView)
