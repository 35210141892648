import React from 'react'
import { OverviewCard } from './OverviewCard'
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { Organisation } from '../../../../api/OrganisationApi';

interface OverviewProps
{
  organisations: Organisation[];
  organisationDetails: Organisation;
}


export const Overview = ({ organisations, organisationDetails } : OverviewProps) =>
{

  const getUsersCount = () =>
  {
    let count = 0;
    for(const org of organisations)
    {
      if(org.parent === organisationDetails.id || organisationDetails.id === 'intelicare')
      {
        count = count + Object.keys(org.access).length
      }
    }
    return count
  }

  const getRolesCount = () =>
  {
    let count = 0;
    for(const org of organisations)
    {
      if(org.parent === organisationDetails.id || organisationDetails.id === 'intelicare')
      {
        Object.keys(org.access).map(userId => {
          const roleCount = org.access[userId].length
          count = count + roleCount;
        })
      }
    }
    return count
  }

  const getLocationsCount = () =>
  {
    return organisations.filter(org => org.type !== 'household' && (org.parent === organisationDetails.id || organisationDetails.id === 'intelicare')).length
  }

  const getHouseholdsCount = () =>
  {
    return organisations.filter(org => org.type === 'household' && (org.parent === organisationDetails.id || organisationDetails.id === 'intelicare')).length
  }
  const getItems = () =>
  {


    const items = [
      {
        title: "Users",
        description: "Active users",
        value: getUsersCount(),
        color: "#26A69A" },
      {
        title: "Roles",
        description: "Current assigned",
        value: getRolesCount(),
        color: "#42A5F5" },
      {
        title: "Locations",
        description: "Sub locations",
        value: getLocationsCount(),
        color: "#673AB7" },
      {
        title: "Households",
        description: "Total households",
        value: getHouseholdsCount(),
        color: "#E91E63",
      },
    ]

    return items;
  }
  return(
    <div>
      <Typography style={styles.title} variant="h5" color="textSecondary">Overview</Typography>
      <Grid container spacing={3}>
        {getItems().map((item, key) => <OverviewCard key={key} title={item.title} description={item.description} value={item.value} color={item.color} />)}
      </Grid>

    </div>

  )
}

const styles =
{
  container: {

  },
  title: {
    paddingTop: 4,
    paddingBottom: 8,
  },
}
