import { makeStyles, Mark, Slider, Tooltip } from '@material-ui/core';
import React from 'react';
import { brandPink } from '../../themes/proTheme';

interface TimeoutSliderProps {
  roomType: string;
  initialValue: number;
  onSliderChange: (value: number | number[], stepSize: number, min: number, max: number) => void;
  enabled: () => boolean;
  currValue: number;
  isContinuousTimeout: boolean;
}

const TimeoutSlider = ({
  roomType, enabled, onSliderChange, initialValue, currValue, isContinuousTimeout,
}: TimeoutSliderProps) => {
  let min = 30;
  let max = 480;
  let step = 15;
  let sliderMarks: Mark[] = [
    { value: 30, label: "30m" },
    { value: 120, label: "2h" },
    { value: 240, label: "4h" },
    { value: 360, label: "6h" },
    { value: 480, label: "8h" },
  ];

  const valueLabelFormat = (value: number) => {
    const minutes = value % 60;
    const hours = (value - minutes) / 60;
    return `${hours}h ${minutes}m`;
  };

  if (roomType.toLowerCase() === "bathroom") {
    min = 15;
    max = 120;
    step = 5;
    sliderMarks = [
      { value: 15, label: "15m" },
      { value: 30, label: "30m" },
      { value: 60, label: "1h" },
      { value: 90, label: "1h 30m" },
      { value: 120, label: "2h" },
    ];
  }

  return <Slider
    disabled={!enabled()}
    valueLabelDisplay="auto"
    step={step}
    marks={sliderMarks}
    min={min}
    max={max}
    color={"secondary"}
    onChange={(e, v) => { onSliderChange(v, step, min, max) }}
    valueLabelFormat={valueLabelFormat}
    ValueLabelComponent={valueLabel}
    defaultValue={getInitialValue(initialValue, roomType.toLowerCase(), isContinuousTimeout)}
    value={currValue}
  />
}

const buildStylesToolTip = makeStyles({
  tooltip: {
    backgroundColor: brandPink.main,
    fontSize: 12,
  },
  arrow: {
    color: brandPink.main,
  },
})

interface ValueLabelProps {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

const valueLabel = ({ children, open, value }: ValueLabelProps) => {
  const classes = buildStylesToolTip();
  return <Tooltip
    classes={classes}
    open={open}
    enterTouchDelay={0}
    placement="top"
    title={value}
    arrow
  >
    {children}
  </Tooltip>;
}


const getInitialValue = (propsInitValue: number, roomType: string, isContinuous: boolean): number => {
  const defaultTimeouts = new Map<string, number>();
  let unknown: number;
  if (isContinuous) {
    defaultTimeouts.set("bathroom", 60).set("bedroom", 360).set("kitchen", 120).set("lounge", 240);
    unknown = 180;
  } else {
    defaultTimeouts.set("bathroom", 30).set("bedroom", 240).set("kitchen", 60).set("lounge", 180);
    unknown = 120;
  }
  return propsInitValue || defaultTimeouts.get(roomType) || unknown;
}

export default TimeoutSlider;