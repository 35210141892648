import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Modals
import AddResellerModal from '../../Modals/AddResellerModal'
import ViewResellerModal from '../../Modals/ViewResellerModal'

// Search bar
import ActionBar from './ActionBar'

// Table
import ResellerTable from '../../Tables/Resellers/ResellerTable'

// Footer
import PaginationFooter from '../../PaginationFooter'

// Material UI components
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@mui/icons-material/Search'

// Pagination constants
const ITEMS_PER_PAGE = 20
const SEARCH_QUERY_LIMIT = 2000

class ResellerTab extends Component {

  state = {
    query: "",
    limit: ITEMS_PER_PAGE,
    offset: 0,
    showAddResellerModal: false,
    showViewResellerModal: false,
    currentReseller: {},
    currentTab: 0,
  }

  getMore = () => {
    this.setState({ limit: ITEMS_PER_PAGE, offset: this.state.offset + ITEMS_PER_PAGE }, () => this.refreshList())
  }

  getLess = () => {
    if (this.state.offset > 0) this.setState({ limit: ITEMS_PER_PAGE, offset: this.state.offset - ITEMS_PER_PAGE }, () => this.refreshList())
  }

  componentDidMount() {
    const { getResellers } = this.props
    const { query, limit, offset } = this.state
    getResellers({ q: query, limit: limit, offset: offset })
  }

  refreshList = () => {
    const { query, limit, offset } = this.state
    const { getResellers } = this.props
    getResellers({ q: query, limit: limit, offset: offset })
  }

  // If the query is null then we reset the pagination state
  handleOnSearch = (query) => {
    const { getResellers } = this.props

    if (query && query.length >= 3) {
      getResellers({ q: query, limit: SEARCH_QUERY_LIMIT, offset: 0 })
    }
    else if (query.length === 0) { // Get all
      getResellers({ q: query, limit: ITEMS_PER_PAGE, offset: this.state.offset })
    }

  }


  render() {

    const {
      resellers,
      isFetching,
      isCreatingReseller,
      createReseller,
      createResellerResult,
      createResellerError,
      clearCreateReseller,
      getResellers,
    } = this.props

    return (
      <div>
        {/* Action bar search */}
        <ActionBar onAddButtonClicked={() => this.setState({ showAddResellerModal: true })} onTextChange={(query) => this.handleOnSearch(query)} />

        {isFetching ? <Grid container justifyContent="center"><CircularProgress style={styles.progressSpinner} /></Grid> :
          resellers && resellers.length > 0 &&
          <div>
            {/* Main Table List */}
            <ResellerTable onTableItemClick={(reseller) => this.setState({ showViewResellerModal: true, currentReseller: reseller })} resellers={resellers} />
          </div>
        }

        {/* No Data View (Nothing found etc..) */}
        {!isFetching && resellers && resellers.length === 0 &&
          <Grid style={{ padding: 200 }} container justifyContent="center">
            <SearchIcon style={{ width: 30, height: 30, paddingTop: 5 }} />
            <Typography variant="h4" component="body2">No Resellers Found</Typography>
          </Grid>
        }

        {/* Pagination */}
        {!isFetching &&
          <PaginationFooter
            onHomeClick={() => getResellers({ q: this.state.query, limit: ITEMS_PER_PAGE, offset: 0 })}
            getMore={() => this.getMore()}
            getLess={() => this.getLess()} />}

        <AddResellerModal
          refreshList={() => this.refreshList()}
          clearCreateReseller={clearCreateReseller}
          createResellerError={createResellerError}
          createResellerResult={createResellerResult}
          isCreatingReseller={isCreatingReseller}
          createReseller={createReseller}
          open={this.state.showAddResellerModal}
          onClose={() => this.setState({ showAddResellerModal: false })} />

        <ViewResellerModal
          refreshList={() => this.refreshList()}
          currentReseller={this.state.currentReseller}
          open={this.state.showViewResellerModal}
          onClose={() => this.setState({ showViewResellerModal: false })} />
      </div>
    )
  }
}

ResellerTab.propTypes = {
  getResellers: PropTypes.func,
  createReseller: PropTypes.func,
  resellers: PropTypes.array,
  isFetching: PropTypes.bool,
  isCreatingReseller: PropTypes.bool,
  createResellerError: PropTypes.string,
  createResellerResult: PropTypes.object,
  clearCreateReseller: PropTypes.func,
  households: PropTypes.array,
  householdsGetError: PropTypes.object,
  isGettingHouseholdList: PropTypes.bool,
}

const styles = {
  progressSpinner: {
    marginTop: 100,
  },
}

export default ResellerTab