
import { history } from '../../../hoc/CustomRouter'

const SYSTEM_HEALTH = "systemHealth"
const HOUSEHOLD_HEALTH = "householdHealth"

const translateSystemHealth = (term) => {
  switch (term) {
    case "Okay (Normal)":
      return "NORMAL"
    case "Watch (Warning)":
      return "WARNING"
    case "Offline (Alert)":
      return "ALERT"
    default:
      return ""
  }
}

const translateHouseholdHealth = (term) => {
  switch (term) {
    case "Okay":
      return "1"
    case "Watch":
      return "2"
    case "Alert":
      return "3"
    default:
      return ""
  }
}

const formatQueryHelperText = (setSearchQueryHelperText, type, filterTerm) => {
  if (type === SYSTEM_HEALTH && filterTerm) return setSearchQueryHelperText(`Showing System's with '${filterTerm}' status`)
  if (type === HOUSEHOLD_HEALTH && filterTerm) return setSearchQueryHelperText(`Showing Household with '${filterTerm}' status`)
  return setSearchQueryHelperText("Search a household")

}

const searchHelper = (type, filterTerm, getHouseholdListNextPage, setSearchQueryHelperText) => {

  if (type === SYSTEM_HEALTH) {

    if (filterTerm === "Inactive (Unmonitored)") {
      getHouseholdListNextPage(0, "*", "triage", "unmonitored", "")
    } else if (filterTerm === "Away (away)") {
      getHouseholdListNextPage(0, "*", "triage", "away", "")
    } else {
      getHouseholdListNextPage(0, "*", "triage", "monitored", translateSystemHealth(filterTerm), "")
    }
  }
  else if (type === HOUSEHOLD_HEALTH) {
    getHouseholdListNextPage(0, "*", "triage", "monitored", "", translateHouseholdHealth(filterTerm))
  }

  formatQueryHelperText(setSearchQueryHelperText, type, filterTerm)


  history.push("/households")
}

export default searchHelper