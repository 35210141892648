export enum SettingsActions
{
  UPDATE_ZONE_BEHAVIOUR_SETTING_BEGIN = "UPDATE_ZONE_BEHAVIOUR_SETTING_BEGIN",
  UPDATE_ZONE_BEHAVIOUR_SETTING_SUCCESS = "UPDATE_ZONE_BEHAVIOUR_SETTING_SUCCESS",
  UPDATE_ZONE_BEHAVIOUR_SETTING_ERROR = "UPDATE_ZONE_BEHAVIOUR_SETTING_ERROR",
  GET_BEHAVIOURS_BY_ZONE_BEGIN = "GET_BEHAVIOURS_BY_ZONE_BEGIN",
  GET_BEHAVIOURS_BY_ZONE_SUCCESS = "GET_BEHAVIOURS_BY_ZONE_SUCCESS",
  GET_BEHAVIOURS_BY_ZONE_ERROR = "GET_BEHAVIOURS_BY_ZONE_ERROR",
  GET_BEHAVIOURS_BEGIN = "GET_BEHAVIOURS_BEGIN",
  GET_BEHAVIOURS_SUCCESS = "GET_BEHAVIOURS_SUCCESS",
  GET_BEHAVIOURS_ERROR = "GET_BEHAVIOURS_ERROR",
  CLEAR_SETTINGS_ACTIONS = "CLEAR_SETTINGS_ACTIONS"
}