import React from "react";

import Grid from "@material-ui/core/Grid";
import { HouseholdHealthRollup } from "./interface";

import { StatsItem } from './StatsItem'
import { calculateDayStatistics, calculateRangeStatistics, getLatestTotal } from "./StatsHelper";

interface StatsViewProps {
  householdsHealthRollup: HouseholdHealthRollup[];
}

export interface Diffs
{
  Alert: number;
  Normal: number;
  Warning: number;
  Unmonitored: number;
  Inactive: number;
  Away: number;
}



export const StatsView = ({ householdsHealthRollup }: StatsViewProps) => {
  const dayStats = calculateDayStatistics(householdsHealthRollup);
  const weekStats = calculateRangeStatistics(householdsHealthRollup, 7, 14);
  const monthStats = calculateRangeStatistics(householdsHealthRollup, 30, 60);
  const totalStats = getLatestTotal(householdsHealthRollup)
  return (
    <Grid container direction="row">
      <StatsItem title="Changes since yesterday" stats={dayStats} showPlus/>
      <StatsItem title="This time vs this time last week" stats={weekStats} showPlus/>
      <StatsItem title="This time vs this time last month" stats={monthStats} showPlus/>
      <StatsItem title="Running totals" stats={totalStats} showPlus={false}/>
    </Grid>

  );
};


export const styles = {
  trendingIcon: { paddingTop: 5, paddingRight: 6 },
  cardContainer: {
    padding: 16,
    margin: 16,
    width: 300,
  },
};
