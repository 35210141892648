import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

export const GettingHealthMetrics = () =>
{
  return(<div style={{ textAlign: 'center' }}>
    <CircularProgress color="secondary"/>
    <Typography color="textSecondary">Getting Health Data</Typography>
  </div>)
}