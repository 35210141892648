import { ApplicationInsights } from '@microsoft/applicationinsights-web'

export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
  },
})

appInsights.loadAppInsights()
appInsights.context.telemetryTrace.name = "web-portal"
