import { Builder } from "./ApiBuilder";

export interface BehavioursGraphData
{
  data: IBehaviourItem[];
}

export interface IBehaviourItem {
  type: BehaviourType;
  friendlyName: BehaviourTypeFriendlyName;
  graphData: GraphItem[];
}

export type BehaviourType =
  | "arise"
  | "sleep"
  | "domesticActivity"
  | "mealPrep"
  | "nighttimeActivity"
  | "generalActivity"
  | "climate"
  | "outings"
  | "bathVisit";

export type BehaviourTypeFriendlyName =
  | "arise"
  | "sleep"
  | "domesticActivity"
  | "mealPrep"
  | "nightTimeActivity"
  | "generalActivity"
  | "Climate"
  | "Outings"
  | "Bathroom Visits";


export interface GraphItem {
  actual: number;
  timestamp: string;
  average: number;
  stdDev: number;
  high: number;
  low: number;
}

export class BehavioursGraphApi extends Builder
{
  token: string;
  constructor(token: string)
  {
    super(true);
    this.token = token
  }

  getDataForHousehold = async (householdId: string, range: number, order: "ASC" | "DESC" = "ASC"): Promise<BehavioursGraphData | undefined> =>
  {
    const response = await this.call("GET", `/api/report-graph?householdId=${householdId}&timeRange=${range}&order=${order}`, null, this.token)
    if(response) return response.data;
  };
}