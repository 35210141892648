// Generic add modal
import { useState } from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface EditModalProps
{
  title: string;
  open: boolean;
  close: () => void;
  inputData: string;
  contentText: string;
  inputBoxDescription: string;
  confirmAction: (data: string) => void;
}

export const EditModal = ({ title, open, close, confirmAction, contentText, inputBoxDescription, inputData }: EditModalProps) =>
{
  const [ data, setData ] = useState(inputData)
  return(
    <Dialog open={open} onClose={close}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {contentText}
        </DialogContentText>
        <TextField
          value={data}
          onChange={(e) => setData(e.target.value)}
          autoFocus
          label={inputBoxDescription}
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button onClick={() => confirmAction(data)}>Update</Button>
      </DialogActions>
    </Dialog>
  )
}