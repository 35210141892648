import React from 'react'
import Card from '@material-ui/core/Card'
//@ts-ignore
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { IGsmRunStatistics } from '../../../../../api/GsmStatsApi';
import moment from 'moment'
interface HistoryChartProps
{
  gsmRunStatistics: IGsmRunStatistics[]
}

export const HistoryChart = ({ gsmRunStatistics }: HistoryChartProps) =>
{

  const formatStats = (gsmRunStatistics: IGsmRunStatistics[] | undefined) =>
  {
    const chartData = []
    try
    {
      if(gsmRunStatistics)
      {
        for(const stat of gsmRunStatistics)
        {
          chartData.push({
            date: stat.runStart,
            notificationsCount: stat.notificationsProcessed,
            runDuration: stat.runDuration,
          })
        }
      }
    }
    catch(error)
    {
      return []
    }

    return chartData.reverse();
  }
  return(
    <Card style={styles.container}>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart width={"100%"} height={300} data={formatStats(gsmRunStatistics)}>
          <XAxis dataKey="date" tickFormatter={(date: string) => moment(date).fromNow()}/>
          <YAxis />
          <Line dot={false} name="Notifications sent" type="monotone" dataKey="notificationsCount" stroke="#8884d8" strokeWidth={2} />
          <Line dot={false} name="Run minutes" type="monotone" dataKey="runDuration" stroke="#EC407A" strokeWidth={2} />
          <Tooltip labelFormatter={(date: string) => `${moment(date).format("LLLZ")} (${moment(date).fromNow()})`}/>
          <Legend />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  )
}

const styles = {
  container: {
    width: "100%",
    height: 300,
    marginTop: 12,
    marginRight: 12,
    padding: 12,
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
  },
}