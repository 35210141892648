import React, { Component } from 'react'
import PropTypes from 'prop-types'
import access from 'safe-access'
import clsx from 'clsx'

import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { enums } from '@intelicare/shared'
import { behaviour, colors } from '../../../../../redux'

import OverallActivityGraph from '../../../../../components/OverallActivityGraph'
import ActivityGraph from "../../../../../components/ActivityGraph"
import ActivityGraphStacked from "../../../../../components/ActivityGraphStacked"
import InteliIcon from '../../../../../components/InteliIcon'
import ActivityStream from '../../../../../components/ActivityStream'
import { getLastMotionEvent } from './util'
import styles from './ActivityViewStyles'
import DailyActivityGraph from '../../../../../components/DailyActivityGraph'
import { MutliActivityView } from '../../../../../components/MultiActivityGraph/MultiActivityGraphView'

const colorForStatusValue = colors.colorForStatusValue
const getColoredClassificationIcon = behaviour.getColoredClassificationIcon

const {
  BEHAVIOUR_STATUS,
  BehaviourClassification,
} = enums

class ActivityView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded: false,
      selectedActivity: 0,
    }

    this.handleTabClick = this.handleTabClick.bind(this)
  }

  handleExpandClick = () => {
    this.setState(state => ({ ...state, expanded: !state.expanded }))
  }

  handleTabClick(event, value) {
    this.setState(state => ({ ...state, selectedActivity: value }))
  }

  render() {
    const {
      householdId,
      classes,
      householdV2,
      sensors,
      useNewLook,
      isDeveloper,
      hasZones,
    } = this.props

    let icon
    const status = access(householdV2, 'behaviourRollup.ACTIVITY')
    const statusColor = colorForStatusValue(status || BEHAVIOUR_STATUS.UNKNOWN)
    icon = getColoredClassificationIcon(
      BehaviourClassification.ACTIVITY,
      statusColor
    )

    const {
      selectedActivity,
    } = this.state

    const useGeneralActivity2 = access(householdV2, 'data.features.enableGeneralActivity2')

    return (
      <Card className={classes.root} automation_attribute="activityCard" >
        {!useNewLook && <CardHeader automation_attribute="activityHeader"
          title={<Typography variant="h5">Activity</Typography>}
          subheader={<Typography color="textSecondary" variant="body1">General activity and movement around the home</Typography>}
          action={
            !useNewLook && <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: this.state.expanded,
              })}
              onClick={this.handleExpandClick}
            >
              <ExpandMoreIcon
                automation_attribute="activityExpandArrow"
                fontSize='large'
                color='primary'
                className={classes.expandIcon}
              />
            </IconButton>
          }
          avatar={
            <InteliIcon
              size={48}
              name={icon.name}
              family={icon.family}
              color={icon.color}
            />
          }
        >
        </CardHeader>}

        <div automation_attribute="activityContent">
          {!useNewLook && <CardContent>
            {!useNewLook && <ActivityStream eventData={getLastMotionEvent(sensors)} householdId={householdId} />}
          </CardContent>}

          <Collapse in={useNewLook ? true : this.state.expanded} timeout={{ enter: 1, exit: 1 }} unmountOnExit>
            <CardContent>
              <Grid container direction="column" automation_attribute="activityGraphs">

                <Grid item style={{ paddingBottom: 16 }}>
                  <Typography variant="h6">Activity Breakdown</Typography>
                  <Tabs onChange={this.handleTabClick} value={selectedActivity}>
                    <Tab label='Activity Breakdown' />
                    { !hasZones && <Tab label='Activity Stacked' /> }
                  </Tabs>
                  {selectedActivity === 0 && hasZones ? <MutliActivityView selectedActivity={selectedActivity} type="stacked" householdId={householdId} household={householdV2.data} /> : <ActivityGraph householdId={householdId} /> }
                  {selectedActivity === 1 && <ActivityGraphStacked householdId={householdId} /> }
                </Grid>
                <Grid item style={{ paddingBottom: 16 }}>
                  <Typography variant="h6">Overall Activity</Typography>
                  <OverallActivityGraph householdId={householdId} />
                  <Typography variant="body1">
                  </Typography>
                </Grid>
                {useGeneralActivity2 && isDeveloper &&
                  <Grid item>
                    <Typography variant='h6'>Daily Activity Level</Typography>
                    <DailyActivityGraph householdData={householdV2.data} />
                  </Grid>
                }
              </Grid>
            </CardContent>
          </Collapse>

        </div>
      </Card>
    )
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    token: PropTypes.string,
    principal: PropTypes.string,
    householdId: PropTypes.string,
    household: PropTypes.object,
    householdV2: PropTypes.object,
    tiles: PropTypes.func,
    isDeveloper: PropTypes.bool,
    isSupport: PropTypes.bool,
    isBetaUser: PropTypes.bool,
    id: PropTypes.string,
    sensors: PropTypes.array,
    useNewLook: PropTypes.bool,
    hasZones: PropTypes.bool,
  }
}

export default withStyles(styles)(ActivityView)
