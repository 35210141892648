import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";

// Dialog components
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

// Model
import { Pendant } from "../../../../../model/Pendant";
import modelEV07 from "../../../../../static/pendants/EV-07_170x170.png";
import modelEV04 from "../../../../../static/pendants/EV-04_170x170.png";
import modelDeveloper from "../../../../../static/apple-icon-precomposed.png";

// Icons
import { StylesProvider, Typography } from "@material-ui/core";

// Redux
import { callAPI } from "../../../../../redux/domains/user/userStore";
import { apiEndpoints } from "../../../../../redux";
import { Dispatch } from "redux";
import { isTextValid } from "../../../../../helpers/textValidation";
const { ADD_GUARDIAN_AI } = apiEndpoints;

interface AddGuardianAIModalProps {
  open: boolean;
  handleClose(): void;
  householdId: string;
  friendlyName: string;
  addGuardian(householdId: string, guardianDeviceId: string, roomName: string, deviceName: string): void;
  clearAddGuardian(): void;
  addGuardianResult: any;
  addGuardianError: { status: number; message: string };
  isAddingGuardian: boolean;
}

const AddGuardianAIModal = (props: AddGuardianAIModalProps) => {
  const [nameError, setNameError] = useState(false)
  const [roomError, setRoomError] = useState(false)
  const [deviceIdError, setDeviceIdError] = useState(false)
  const [guardianName, setGuardianName] = useState("")
  const [roomName, setRoomName] = useState("")
  const [deviceId, setDeviceId] = useState("")
  const { open, handleClose, friendlyName, clearAddGuardian, addGuardian, addGuardianError, addGuardianResult, isAddingGuardian, householdId } = props

  const onClose = () => {
    setNameError(false)
    setRoomError(false)
    setDeviceIdError(false)
    setGuardianName("")
    setRoomName("")
    setDeviceId("")
    clearAddGuardian()
    handleClose()
  }

  const isDeviceIdValid = (deviceId: string) => {
    return (/^AU-[0-9]{8}$/).test(deviceId)
  }

  const validateInputs = () => {
    let error = false
    if (!isTextValid(guardianName)) {
      setNameError(true)
      error = true
    }
    if (!isTextValid(roomName)) {
      setRoomError(true)
      error = true
    }
    if (!isDeviceIdValid(deviceId)) {
      setDeviceIdError(true)
      error = true
    }
    return error
  }

  const onAdd = () => {
    setNameError(false)
    setRoomError(false)
    setDeviceIdError(false)

    if (!validateInputs()) {
      addGuardian(householdId, deviceId, roomName, guardianName)
    }
  }

  return (
    <Dialog
      onExit={() => onClose()}
      maxWidth="md"
      fullWidth
      open={open}
      onClose={() => onClose()}
      aria-labelledby="add-guardian"
      aria-describedby="add-guardian"
    >
      <DialogTitle id="alert-dialog-slide-title">
          Add Guardian AI to {friendlyName}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Typography variant="body2" style={styles.textStyle}>
              Give the Guardian AI a friendly name (no special characters)
          </Typography>
          <TextField
            error={nameError}
            helperText={
              nameError && "Please enter a valid Guardian AI name"
            }
            onChange={(e) => setGuardianName(e.target.value)}
            style={styles.inputStyle}
            variant="outlined"
            label="Guardian AI Name"
            placeholder="E.G. (Bedroom Guardian)"
          />

          <Typography variant="body2" style={styles.textStyle}>
              Add the Guardian AI room name (no special characters)
          </Typography>
          <TextField
            error={roomError}
            helperText={
              roomError && "Please add a valid room name"
            }
            onChange={(e) => setRoomName(e.target.value)}
            style={styles.inputStyle}
            variant="outlined"
            label="Room Name"
            placeholder="Bedroom"
          />

          <Typography variant="body2" style={styles.textStyle}>
              Add the Guardian AI device ID
          </Typography>
          <TextField
            error={deviceIdError}
            helperText={
              deviceIdError && "Please add a valid device Id"
            }
            onChange={(e) => setDeviceId(e.target.value)}
            style={styles.inputStyle}
            variant="outlined"
            label="Device ID"
            placeholder="AU-XXXXXXXX"
          />
        </Grid>

        {addGuardianError && (
          <Alert variant="filled" style={styles.inputStyle} severity="error">
            {"Guardian AI failed to add"}
          </Alert>
        )}

        {addGuardianResult && (
          <Alert
            variant="filled"
            style={styles.inputStyle}
            severity="success"
          >
              Successfully added the Guardian AI to the household
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
            Close
        </Button>
        <Button onClick={onAdd} color="primary">
          {isAddingGuardian ? "Adding...." : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  inputStyle: {
    margin: 12,
  },
  textStyle: {
    paddingLeft: 12,
  },
};

const mapStateToProps = (state: any) => {
  return {
    isAddingGuardian: state.householdsV2.isAddingGuardian,
    addGuardianResult: state.householdsV2.addGuardianResult,
    addGuardianError: state.householdsV2.addGuardianError,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addGuardian: (householdId: string, guardianDeviceId: string, roomName: string, deviceName: string) =>
      dispatch(callAPI(ADD_GUARDIAN_AI({
        householdId,
        guardianDeviceId,
        roomName,
        deviceName,
      }))),
    clearAddGuardian: () => dispatch({ type: "CLEAR_ADD_GUARDIAN_AI" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGuardianAIModal);
