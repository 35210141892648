import access from 'safe-access'
import moment from 'moment'
import {
  call,
  put,
  select,
  take,
} from 'redux-saga/effects'

import {
  getHeaders,
} from '../util/network'

import {
  getTokenExpirationDate,
  isRefreshing,
} from '../../domains/user/userSelector'

let Analytics
if (process.env.REACT_APP_APP_RESOURCE === 'NATIVE') {
  Analytics = require('appcenter-analytics')
}

const TOKEN_TIMEOUT_IN_SECONDS = 30

const API_PREFIX = `${process.env.REACT_APP_API_URL_V3}/api/`

export const beginPrefix = (endpoint) => `${endpoint.prefix}_BEGIN`
export const errorPrefix = (endpoint) => `${endpoint.prefix}_ERROR`
export const successPrefix = (endpoint) => `${endpoint.prefix}_SUCCESS`

const { getMessageFunc } = require('@intelicare/shared-messages')

const getMessage = getMessageFunc()

function trackEvent(event, properties) {
  if (process.env.REACT_APP_APP_RESOURCE === 'NATIVE' && Analytics) {
    try {
      Analytics.trackEvent(event, properties)
    } catch (error) {
      // console.log(error)
    }
  }
}

export const tokenIsValid = (tokenExpirationDate) => {
  const differenceInTime = moment(tokenExpirationDate * 1000).diff(moment(), 'seconds')
  const isValid = differenceInTime > TOKEN_TIMEOUT_IN_SECONDS
  return isValid
  // return false
}

/*
  Verifies that the user is logged in. The underlying Adal library will handle
  refreshing the token if possible and return those details.

  If not we catch the promise exception and log the user out.
*/

// eslint-disable-next-line no-unused-vars
function* _ensureAuthenticated(endpoint) {
  try {
    const refreshing = yield select(isRefreshing)
    if (refreshing) yield take('REFRESH_TOKEN_SUCCESS')
    const tokenExpirationDate = yield select(getTokenExpirationDate)
    const isValid = tokenIsValid(tokenExpirationDate)
    if (!isValid) {
      console.log("Token has expired, refreshing...")
      yield put({ type: "REFRESH_TOKEN_BEGIN" })
      yield take('REFRESH_TOKEN_SUCCESS')
    }
    return true
  } catch (err) {
    yield put({ type: "LOGOUT_START" })
    return false
  }
}

function* _getAPIHeaders(defaultHeaders) {
  if (defaultHeaders) {
    return defaultHeaders
  }

  let state = yield select((state) => state)

  let headers = getHeaders(state)

  return headers
}

function generateAPIMetadata(options = {}) {
  return {
    ...options,
    headers: new Headers({
      ...options.headers,
      InteliCareAppChannel: process.env.REACT_APP_CHANNEL,
      InteliCareAppVersion: process.env.REACT_APP_VERSION_NUMBER,
    }),
  }
}

async function parseResponse(response, options) {
  if (!response.bodyUsed) {
    try {
      if (options.responseType === null) {
        return
      }
      if (options.responseType === 'blob') {
        return await response.clone().blob()
      }
      const contentType = getHeaderValue(response.headers, 'content-type')
      if (contentType && contentType.startsWith('application/json')) {
        return await response.clone().json()
      }
      const contentLength = getHeaderValue(response.headers, 'content-length')
      if (contentLength === '0') {
        return
      }
      return await response.clone().text()
    } catch (ex) {
      return await response.clone().text()
    }
  }
  return Promise.resolve()
}

async function _callAPIEndpoint(path, options = {}) {

  const optionsWithMeta = generateAPIMetadata(options)
  const response = await fetch(`${API_PREFIX}${path}`, optionsWithMeta)
  const parsedResponse = await parseResponse(response, options)
  if (!response.ok) {
    trackEvent('API Error', { path, status: response.status, statusText: response.statusText })
    let error = new Error()
    error.status = access(response, 'status')
    error.message = access(response, 'statusText')
    if (!error.message) {
      const msg = getMessage('E10024')
      error.message = `${msg.intelicareCode}: ${msg.userMessage}`
    }
    throw error
  }
  return { parsedResponse, response, status: response.status }
}






function getHeaderValue(headers, key) {
  if (headers.map) {
    return headers.map[key]
  }
  return headers.get(key)
}

function _callAPIEndpoints(paths, options) {
  let promises = paths.map((path) => {
    return _callAPIEndpoint(path, options)
  })

  return Promise.all(promises).then((result) => {
    // If only one url provide response
    if (result && result.length === 1) {
      return result[0]
    } else {
      return result
    }
  })
}

/**
 * The default method for calling our API.
 * It has a number of steps;
 * 1) Ensures we are authenicated.
 * 2) Dispatches the begin action for this endpoint with the parameters provided.
 * This allows you to display a loader for example.
 * 3) Calls the API endpoints with the parameters provided.
 * 4) If successful, dispatches a success action with the response / responses.
 * 5) If unsuccessful, dispatches an error action with the error.
**/
export function* callAPISaga(endpoint, defaultHeaders) {

  let result
  let apiCallResult = {}

  try {
    yield put({
      type: beginPrefix(endpoint),
      params: endpoint.params,
    })

    let authenticated = yield _ensureAuthenticated(endpoint)

    // User will be logged out so end the saga.
    if (!authenticated) {
      return
    }

    let headers = yield _getAPIHeaders(defaultHeaders)

    if (endpoint.params && endpoint.params.method) { //POST, PUT, DELETE
      if (!endpoint.params.contentType) {
        headers['Content-Type'] = 'application/json'
      } else {
        headers['Content-Type'] = endpoint.params.contentType
      }
      apiCallResult = yield call(_callAPIEndpoints, endpoint.urls, {
        headers: headers,
        method: endpoint.params.method,
        body: endpoint.params.body,
        responseType: endpoint.params.responseType,
      })
    } else { //GET
      apiCallResult = yield call(_callAPIEndpoints, endpoint.urls, { headers: headers })

    }
    result = {
      type: successPrefix(endpoint),
      data: apiCallResult.parsedResponse,
      response: apiCallResult.response,
      status: apiCallResult.status,
      params: endpoint.params,
    }
  } catch (err) {
    // console.log(`${err.message} ${err.stack}`)
    // console.log(inspect(err))
    result = {
      type: errorPrefix(endpoint),
      error: err,
      params: endpoint.params,
    }
  }
  yield put(result)
  return result
}

/**
 * This method should not be used except in exceptional cases.
 * You should verify or be certain that the token is valid before calling this function
 * as it does not include token refresh logic.
**/
export const callAPIDirect = function (path, options) {
  return _callAPIEndpoint(path, options)
}

