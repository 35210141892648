import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { WashingCard } from '../cards/Domestic/WashingCard'
import { ClimateCard } from '../cards/Domestic/ClimateCard'
import { ShowerCard } from '../cards/Domestic/ShowerCard'

import { BEHAVIOUR_TYPES } from '@intelicare/shared/enums'
import { ShowerUsageCard } from '../cards/Domestic/ShowerUsageCard'

import { PersonalRoutineCard } from '../cards/Domestic/PersonalRoutineCard'
import { ISensor } from '@intelicare/shared/interfaces/household/ISensor'

interface DomesticTabProps
{
  isDeveloper: boolean;
  isSupport: boolean;
  householdId: string;
  activeV2Household: any;
  useNewLook: boolean;
  sensors: ISensor[];
}

export const DomesticTab = ({ isDeveloper, isSupport, householdId, activeV2Household, useNewLook, sensors }: DomesticTabProps) =>
{
  const washing = activeV2Household.data.behaviours.find((behaviour: any) => behaviour.type === BEHAVIOUR_TYPES.WASHING)
  const shower = activeV2Household.data.behaviours.find((behaviour: any) => behaviour.type === BEHAVIOUR_TYPES.SHOWER_USE)
  const climate = activeV2Household.data.behaviours.find((behaviour: any) => behaviour.type === BEHAVIOUR_TYPES.CLIMATE)

  return(
    <div style={styles.container}>
      <Grid container direction="row" spacing={2}>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>

          <WashingCard washingBehaviour={washing} />

        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>

          <ShowerCard showerBehaviour={shower} />

        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>

          <ClimateCard climateBehaviour={climate} />

        </Grid>

      </Grid>



      <Grid container spacing={2} direction="column">

        <Grid item xs={12}>
          <ShowerUsageCard householdId={householdId} sensors={sensors}/>
        </Grid>

        <Grid item xs={12}>
          <PersonalRoutineCard sensors={sensors} />
        </Grid>

      </Grid>


    </div>
  )
}

const styles = {
  container: {
    paddingTop: 6,
    overflow: "hidden",
  },
}