import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, withStyles } from "@material-ui/core";
import { compose } from "redux";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Styles from "../HouseholdSettingsStyles";
import { PortalOverlayStyles } from "../../../components/PortalOverlay";
import access from "safe-access";
import InteliIcon from "../../../components/InteliIcon";
import sharedStore from "../../../redux";

const callAPI = sharedStore.userStore.callAPI;
const { HOUSEHOLD_SETTING_BULK } = sharedStore.apiEndpoints;
const {
  updateBehaviourSetting,
} = sharedStore.householdsV2Store
const getActiveHouseholdV2 =
  sharedStore.householdsV2Selector.getActiveHousehold;

// const AlexaClientId = "amzn1.application-oa2-client.b76f0efd32034b379f3ae997673777d4"; // Sample Intelicare Skills
const AlexaClientId = "amzn1.application-oa2-client.34eb2de6665a4a02af0bd9b1a4185285"; // Actual Intelicare Skills
//ie. AlexaRedirectUrl = "https://app.dev.intelicare.io",also with test and prod,
//this need to be add in Alexa skill's Security Profile Management-> web settings -> Allowed Return URLs
const AlexaRedirectUrl = window.location.origin
class AlexaSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: {},
    };
  }

  componentDidMount() {
    // console.log("hello");
  }

  // componentDidUpdate() {}

  getAlexaAuthUrl = () => {
    const AlexaState = "AlexaId-" + this.props.activeHouseholdId;
    // LWA fallback URL
    const AlexaAuthUrl = "https://www.amazon.com/ap/oa?client_id=" + AlexaClientId +
      "&scope=profile&response_type=code&redirect_uri=" + AlexaRedirectUrl + "&state=" + AlexaState;
    return AlexaAuthUrl;
  };

  doAlexaLogin = () => {
    if (!this.props.isSyncedWithAlexa) {
      // First time syncing with Alexa, send them to login/authorisation page
      window.location = this.getAlexaAuthUrl();
    }
  };

  render() {
    const {
      classes,
      isSyncedWithAlexa,
      isDeveloper,
      isSupport,
      isDemoUser,
      activeHouseholdId,
    } = this.props;

    return (
      <div className={classes.OverlayMain}>
        <div className={classes.OverlaySection}>
          <Typography variant="h5" style={{ textAlign: "left", paddingLeft: 20, paddingBottom: 10 }} >Instructions</Typography>
          <Typography style={{ textAlign: "justify", paddingLeft: 20, paddingBottom: 10 }}
            variant="body1">
            Amazon Alexa, also known as Alexa, is a virtual assistant AI
            technology developer by Amazon. This syncs enables users to link
            their Alexa user identity with Intelicare identity, so user could
            activated assistant request through Intelicare skill in Alexa when they
            are unable to get to a phone or activate a duress pendant .
          </Typography>

          <hr style={{ marginTop: 20 }} />
          <Typography variant="h5" style={{ textAlign: "left", paddingLeft: 20, paddingBottom: 10 }} >Sync Settings</Typography>
          <Grid container justifyContent="center" align="center" direction="column" spacing={24}>
            <Grid container direction='row' item>
              <Grid item sm={4} md={3} lg={2} direction="row">
                <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-evenly' }}>
                  <InteliIcon
                    name={'Alexa-icon'}
                    family={'custom'}
                    color={'#00B0B9'} />
                  <Typography variant="h6">Setup Alexa:</Typography>
                </div>
              </Grid>
              <Grid item sm={4} md={3} lg={2}>
                <Typography variant="h6"><i>{isSyncedWithAlexa ? 'Synced' : 'Not Synced'}</i></Typography>
              </Grid>
              <Grid item sm={4} md={3} lg={2}>
                <Button variant="contained" onClick={this.doAlexaLogin} disabled={isSyncedWithAlexa}>Login</Button>
              </Grid>
              {(isDeveloper || isSupport || isDemoUser) && (
                <Grid item sm={4} md={3} lg={2}>
                  <Button variant="contained" onClick={() => this.props.unSyncAlexa(activeHouseholdId)} disabled={!isSyncedWithAlexa}> Unsync </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

AlexaSettings.propTypes = {
  close: PropTypes.func,
  activeHouseholdV2: PropTypes.object,
  activeHouseholdId: PropTypes.string,
  updateSettings: PropTypes.func,
  classes: PropTypes.object,
  isSyncedWithAlexa: PropTypes.bool,
  isDeveloper: PropTypes.bool,
  isSupport: PropTypes.bool,
  isDemoUser: PropTypes.bool,
  unSyncAlexa: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    activeHouseholdV2: getActiveHouseholdV2(state),
    activeHouseholdId: state.householdsV2.activeHouseholdId,
    isSyncedWithAlexa: access(getActiveHouseholdV2(state), 'data.alexaData.synced'),
    isDeveloper: state.user.isDeveloper,
    isSupport: state.user.isSupport,
    isDemoUser: state.user.isDemoUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    unSyncAlexa: (householdId) => {
      const params = {
        householdId,
        settings: {
          "alexaData.synced": false,
        },
      };
      dispatch(callAPI(HOUSEHOLD_SETTING_BULK(params))) // Update household doc
      dispatch(updateBehaviourSetting(householdId, "alexaData.synced", false))
    },
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Styles),
  withStyles(PortalOverlayStyles)
)(AlexaSettings);
