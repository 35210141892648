import React, { useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import InputAdornment from '@material-ui/core/InputAdornment';
import { IBehaviourData } from "@intelicare/shared/interfaces/behaviours/Behaviour"

interface WashingBehaviour extends IBehaviourData {
  timeLimitDays: number;
}

type ConfigureWashingAlertModalProps = {
  open: boolean;
  handleClose: () => void;
  behaviour: WashingBehaviour;
  onUpdate: (behaviour: WashingBehaviour) => void;
}

export const ConfigureWashingAlertModal = ({ open, handleClose, behaviour, onUpdate }: ConfigureWashingAlertModalProps) => {

  const [warnLimit, setWarnLimit] = useState(String(behaviour.timeLimitDays))
  const [errMsg, setErrMsg] = useState("")

  const validateLimit = (value: string) => {
    return /^[0-9]*$/.test(value)
  }

  const resetFields = () => {
    setErrMsg("")
  }

  const hasBeenModified = () => {
    return warnLimit !== String(behaviour.timeLimitDays)
  }

  const validateInputs = () => {
    if (!warnLimit) {
      setErrMsg("Field is empty")
      return false
    }

    if (Number(warnLimit) < 1) {
      setErrMsg("Limit must be more than 1 day")
      return false
    }

    setErrMsg("")
    return true
  }

  const onSave = () => {
    if (validateInputs()) {
      onUpdate({ ...behaviour, timeLimitDays: Number(warnLimit) })
    }
  }

  return (
    <Dialog fullWidth maxWidth="md" onEnter={() => resetFields()} open={open} onClose={handleClose}>
      <DialogTitle>
        <NotificationsIcon style={{ position: "relative", top: 5, right: 5 }} />
        Configure Washing Alerting
      </DialogTitle>

      <DialogContent style={styles.container}>
        <DialogContentText variant='h3'>Washing Warning</DialogContentText>
        {/* @ts-ignore */}
        <Grid style={styles.inputRow}>
          <DialogContentText>
            Number of days with no washing activity before sending a WARNING notification
          </DialogContentText>
          <TextField
            style={styles.inputField}
            value={warnLimit}
            variant="outlined"
            onChange={(e) => {
              const value = e.target.value
              if (validateLimit(value)) {
                setWarnLimit(value)
              }
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">Days</InputAdornment>,
            }}
          />
        </Grid>

        {errMsg && <Alert severity="error">{errMsg}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button disabled={!hasBeenModified()} onClick={resetFields} color="primary">Reset</Button>
        <Button onClick={handleClose} color="primary">Close</Button>
        <Button disabled={!hasBeenModified()} onClick={onSave} color="primary" autoFocus>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = {
  container: {
    padding: 25,
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputField: {
    width: 90,
    paddingLeft: 50,
    paddingBottom: 10,
  },
};
