import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Moment
import moment from 'moment'

// Material UI Components
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'
import BatteryItem from './BatteryItem'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

// Material UI Icons
import ClockIcon from '@mui/icons-material/AccessTime'
import RouterIcon from '@mui/icons-material/Router'
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote'

// Redux
import sharedStore from '../../../../redux'

import { GET_HOUSEHOLD_FROM_GATEWAY_ID } from "../../../../redux/shared/services/APIEndpoints"

const callAPI = sharedStore.userStore.callAPI

const ListItem = ({ batteryReport, onActionClick, householdFriendlyName, getHouseholdNameFromGatewayId, isGettingHouseholdFriendlyName }) => {

  ListItem.propTypes = {
    batteryReport: PropTypes.object,
    onActionClick: PropTypes.func,
    householdFriendlyName: PropTypes.string,
    getHouseholdNameFromGatewayId: PropTypes.func,
    isGettingHouseholdFriendlyName: PropTypes.bool,
  }

  return (
    <Card style={styles.container}>
      <CardContent>
        <Tooltip
          onOpen={() => getHouseholdNameFromGatewayId({ queryString: `getFriendlyName=${batteryReport.gatewayId}` })}
          title={
            <React.Fragment>
              <Typography color="inherit">{isGettingHouseholdFriendlyName ? "Getting Friendly Name..." : `Friendly Name: ${householdFriendlyName}`}</Typography>
            </React.Fragment>
          }
          TransitionComponent={Zoom}>

          <Typography color="textSecondary" gutterBottom>
            <RouterIcon style={{ position: "relative", top: 4 }} /> {batteryReport.gatewayId}

            <SettingsRemoteIcon style={{ position: "relative", top: 4, paddingLeft: 10 }} /> {batteryReport.sensors.length}
          </Typography>
        </Tooltip>

        <Typography variant="h5" component="h2">

        </Typography>

        <Grid style={{ marginTop: 30 }} direction="row" justifyContent="space-around" container>

          {batteryReport.sensors && batteryReport.sensors.length > 0 && batteryReport.sensors
            .map((sensor, key) => {
              return <Grid key={key} direction="row" item>
                <BatteryItem sensor={sensor} />
              </Grid>
            })

          }

        </Grid>

      </CardContent>
      <CardActions>
        <Button onClick={() => onActionClick(batteryReport.gatewayId)}>show all for this gateway</Button>
        <div style={{ flex: 1 }}></div>
        <Typography color="textSecondary">
          <ClockIcon style={{ position: "relative", top: 7 }} /> {batteryReport.timestamp ? moment(batteryReport.timestamp).fromNow() : "Unknown"}
        </Typography>
      </CardActions>
    </Card >
  )
}

const styles = {
  container: {
    margin: 12,
  },
}

const mapDispatchToProps = (dispatch) => {
  return {
    getHouseholdNameFromGatewayId: (params) => dispatch(callAPI(GET_HOUSEHOLD_FROM_GATEWAY_ID(params))),
  }
}

const mapStateToProps = (state) => ({
  isGettingHouseholdFriendlyName: state.householdsV2.isGettingHouseholdFriendlyName,
  householdFriendlyName: state.householdsV2.householdFriendlyName,
  householdFriendlyNameError: state.householdsV2.householdFriendlyNameError,
})

export default connect(mapStateToProps, mapDispatchToProps)(ListItem)