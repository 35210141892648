import React from 'react'

import { IBehaviourItem } from "../../../../../api/BehavioursGraphApi";
import Typography from "@material-ui/core/Typography";

import Grid from '@material-ui/core/Grid'
import { Line } from 'react-chartjs-2'

import { chartAdapter } from './adapter/chartAdapter';
interface BehaviourItemProps {
  behaviourItem?: IBehaviourItem;
  height: number;
}


export const BehaviourItemV2 = ({ behaviourItem }: BehaviourItemProps) =>
{

  return(
    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
      <div style={styles.itemContainer}>

        <Typography align="center" color="textSecondary" variant="h6">
          {behaviourItem && behaviourItem.friendlyName}
        </Typography>

        <Line
          options={{
            animation: {
              duration: 0,
            },
          }}
          // @ts-ignore
          data={chartAdapter(behaviourItem)}></Line>


      </div>


    </Grid>
  )
}

const styles = {
  itemContainer: {
    margin: 20,
  },
  noData: {
    aligContent: 'center',
    justifyContent: 'center',
    height: 300,
    textAlign: 'center' as 'center',
  },
}