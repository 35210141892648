
import React from 'react'
import { Diffs, styles } from './StatsView'
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

interface StatsItemProps
{
  stats: Diffs | undefined;
  title: string;
  showPlus: boolean;
}

export const StatsItem = ({ stats, title, showPlus }: StatsItemProps) =>
{
  return(
    <Card style={styles.cardContainer}>
      <Grid container item direction="column">
        <Grid container direction="row">

          <TrendingUpIcon style={styles.trendingIcon} />
          <Typography variant="h6" color="textPrimary">
            {title}
          </Typography>
        </Grid>

        {stats &&
      Object.keys(stats).map((item, key) => {
        return (
          <Grid key={key} item>
            <Typography color="textSecondary" variant="subtitle2">
              {`${item}: ${
                //@ts-ignore
                stats[item] > 0 && showPlus ? "+" + stats[item] : stats[item]
              }`}
            </Typography>
          </Grid>
        );
      })}

      </Grid>
    </Card>
  )
}
