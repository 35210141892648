import React, { Component } from "react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";

// Dialog components
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";

// Model
import { Pendant } from "../../../../../model/Pendant";
import modelEV07 from "../../../../../static/pendants/EV-07_170x170.png";
import modelEV04 from "../../../../../static/pendants/EV-04_170x170.png";
import modelNHA from "../../../../../static/pendants/nha.png";
import modelDeveloper from "../../../../../static/apple-icon-precomposed.png";
import appleWatch from '../../../../../static/pendants/apple_watch.jpeg';

// Redux
import { callAPI } from "../../../../../redux/domains/user/userStore";
import { apiEndpoints } from "../../../../../redux";
import { Dispatch } from "redux";
const { ADD_PENDANT } = apiEndpoints;

interface AddPendantModalProps {
  open: boolean;
  handleClose(): void;
  householdId: string;
  friendlyName: string;
  addPendant(householdId: string, pendant: Pendant): void;
  clearAddPendant(): void;
  addPendantResult: any;
  addPendantError: { status: number; message: string };
  isAddingPendant: boolean;
}

class AddPendantModal extends Component<AddPendantModalProps> {
  state = {
    error: true,
    smsNumber: "",
    friendlyName: "",
    product: "",
    showNameError: false,
    showNumberError: false,
  };

  _validateInput = (): boolean => {
    const { friendlyName, smsNumber } = this.state;
    let error: boolean = false;

    if (!friendlyName) {
      this.setState({ showNameError: true });
      error = true;
    }

    if (!smsNumber || smsNumber.length !== 12 || !smsNumber.startsWith("+")) {
      this.setState({ showNumberError: true });
      error = true;
    }

    return error;
  };

  _addPendant = () => {
    const { friendlyName, smsNumber, product } = this.state;
    const { addPendant, householdId } = this.props;
    const pendant = new Pendant(uuidv4(), friendlyName, smsNumber, product);

    if (!this._validateInput()) {
      addPendant(householdId, pendant);
      this.setState({ showNameError: false, showNumberError: false });
    }
  };

  render() {
    const {
      open,
      handleClose,
      friendlyName,
      clearAddPendant,
      isAddingPendant,
      addPendantResult,
      addPendantError,
    } = this.props;

    return (
      <Dialog
        onExit={() => clearAddPendant()}
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="add-pendant"
        aria-describedby="add-pendant"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Add Pendant to {friendlyName}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            <Typography variant="body2" style={styles.textStyle}>
              Give the pendant a friendly name
            </Typography>
            <TextField
              error={this.state.showNameError}
              helperText={
                this.state.showNameError && "Please give the pendant a name"
              }
              onChange={(e) => this.setState({ friendlyName: e.target.value })}
              style={styles.inputStyle}
              variant="outlined"
              label="Pendant Name"
              placeholder="E.G. (Pat's Pendant)"
            ></TextField>

            <Typography variant="body2" style={styles.textStyle}>
              Add the Pendant's SIM card mobile number
            </Typography>
            <TextField
              error={this.state.showNumberError}
              helperText={
                this.state.showNumberError &&
                "Please add a mobile number starting with the international dial code prefix"
              }
              onChange={(e) => this.setState({ smsNumber: e.target.value })}
              style={styles.inputStyle}
              variant="outlined"
              label="Pendant Number"
              placeholder="+61418XXXXXX"
            ></TextField>

            <Typography variant="body2" style={styles.textStyle}>
              Let us know the pendant's model and product information
            </Typography>

            <FormControl variant="outlined" style={styles.inputStyle}>
              <InputLabel id="demo-simple-select-outlined-label">
                Model
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                onChange={(e) => this.setState({ product: e.target.value })}
                label="Model"
                SelectDisplayProps={{ style: styles.pendantSettledStyle }}
              >
                <MenuItem value={"EV-04"} style={styles.inputStyle}>
                  <img
                    src={modelEV04}
                    alt="EV-04"
                    style={styles.pendantImgStyle}
                  />
                  <Typography>EV-04</Typography>
                </MenuItem>

                <MenuItem value={"EV-07"} style={styles.inputStyle}>
                  <img
                    src={modelEV07}
                    alt="EV-07"
                    style={styles.pendantImgStyle}
                  />
                  <Typography>EV-07</Typography>
                </MenuItem>

                <MenuItem value={"NHA"} style={styles.inputStyle}>
                  <img
                    src={modelNHA}
                    alt="NHA"
                    style={styles.pendantImgStyle}
                  />
                  <Typography>NHA Life Alarm</Typography>
                </MenuItem>

                <MenuItem
                  value={"Developer Test Model"}
                  style={styles.inputStyle}
                >
                  <img
                    src={modelDeveloper}
                    alt="Developer Test Model"
                    style={styles.pendantImgStyle}
                  />
                  <Typography>Developer Test Model</Typography>
                </MenuItem>

                <MenuItem
                  value={"Apple Watch"}
                  style={styles.inputStyle}
                >
                  <img
                    src={appleWatch}
                    alt="Apple Watch"
                    style={styles.pendantImgStyle}
                  />
                  <Typography>Apple Watch</Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {addPendantError && (
            <Alert variant="filled" style={styles.inputStyle} severity="error">
              {addPendantError.message === "Conflict"
                ? "Unable to add the pendant because the mobile number already exists on another household"
                : addPendantError.message}
            </Alert>
          )}

          {addPendantResult && (
            <Alert
              variant="filled"
              style={styles.inputStyle}
              severity="success"
            >
              Successfully added the pendant to the household
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={this._addPendant} color="primary">
            {isAddingPendant ? "Adding...." : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = {
  inputStyle: {
    margin: 12,
  },
  textStyle: {
    paddingLeft: 12,
  },
  pendantImgStyle: {
    height: "70px",
    width: "70px",
    margin: 12,
  },
  pendantSettledStyle: {
    display: "flex",
    flexDirection: "row" as "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
};

const mapStateToProps = (state: any) => {
  return {
    isAddingPendant: state.householdsV2.isAddingPendant,
    addPendantResult: state.householdsV2.addPendantResult,
    addPendantError: state.householdsV2.addPendantError,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addPendant: (householdId: string, params: Pendant) =>
      dispatch(callAPI(ADD_PENDANT(householdId, params))),
    clearAddPendant: () => dispatch({ type: "CLEAR_ADD_PENDANT" }),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(AddPendantModal);
