
import {
  all,
  takeEvery,
} from 'redux-saga/effects'

import {
  // REQUEST_HOUSEHOLD_DATA,
  REFRESH_HOUSEHOLDS,
} from './householdsStore'

import {
  // HOUSEHOLD_DATA,
  // HOUSEHOLD_LIST,
  HOUSEHOLD_AVATARS,
} from '../../shared/services/APIEndpoints'

import {
  callAPISaga,
} from '../../shared/services/WebAPI'

// function* refreshHousehold(action) {
//   // yield callAPISaga(HOUSEHOLD_DATA({ householdId: action.householdId }))
// }

function* refreshHouseholds() {
  yield all([
    // callAPISaga(HOUSEHOLD_LIST()),
    callAPISaga(HOUSEHOLD_AVATARS()),
  ])
}

/*
  Starts refreshHousehold on each dispatched `REQUEST_HOUSEHOLD_DATA` action.
*/
function* householdDataSaga() {
  // yield takeEvery(REQUEST_HOUSEHOLD_DATA, refreshHousehold)
  yield takeEvery(REFRESH_HOUSEHOLDS, refreshHouseholds)
}

export default householdDataSaga
