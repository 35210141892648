import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Select, withStyles, MenuItem, InputLabel, Tooltip, IconButton, Icon } from '@material-ui/core'
import SyncIcon from "@mui/icons-material/Sync";
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LoginIcon from '@mui/icons-material/Login';
import { Skeleton } from '@material-ui/lab';
import { compose } from 'redux'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import FormButtons from '../../FormButtons'
import ThresholdTextField from './ThresholdTextField'
import Styles from '../../HouseholdSettingsStyles'
import { PortalOverlayStyles } from '../../../../components/PortalOverlay'
import access from 'safe-access'
import InteliIcon from '../../../../components/InteliIcon'
import sharedStore from '../../../../redux'
import { CELSIUS, FAHRENHEIT } from '../../../../helpers/healthMetrics'
import { getActiveHouseholdSettings } from '../BasePageConnector'
import { GET_ADD_DEVICE_URL, GET_HEALTH_METRICS_DEVICES } from '../../../../redux/shared/services/APIEndpoints'
import { HealthMetricsDevices } from './HealthMetricsDevices'
import { AddDeviceDialog } from './AddDeviceDialog';

const callAPI = sharedStore.userStore.callAPI
const {
  HOUSEHOLD_SETTING_BULK,
} = sharedStore.apiEndpoints
const {
  updateHealthMetricsSettings,
  updateBehaviourSetting,
} = sharedStore.householdsV2Store
const getActiveHouseholdV2 = sharedStore.householdsV2Selector.getActiveHousehold

const BLOOD_PRESSURE = "BLOOD_PRESSURE"
const BLOOD_PRESSURE_SYSTOLIC = "BLOOD_PRESSURE_SYSTOLIC"
const BLOOD_PRESSURE_DIASTOLIC = "BLOOD_PRESSURE_DIASTOLIC"

class HealthMetricSettings extends Component {

  constructor(props) {
    super(props)

    this.state = {
      bpUpperError: false,
      bpLowerError: false,
      initialSettings: {},
      settings: {},
      temperatureUnits: "",
      originalTemperatureUnits: "",
      errorMetric: "",
      openLoginDialog: false,
    }
    this.handleInputChange = this.handleThresholdInputChange.bind(this)
    this.handleSyncChange = this.handleSyncChange.bind(this)
    this.onSave = this.onSave.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  initSettings() { // Initialise settings state
    const temperatureMetric = this.props.householdDocHealthMetrics.find(metric => metric.metricName === "TEMPERATURE")
    const temperatureUnits = (temperatureMetric && temperatureMetric.units) ? temperatureMetric.units : CELSIUS
    this.setState({ temperatureUnits: temperatureUnits, originalTemperatureUnits: temperatureUnits })

    this.props.healthMetricsSummary && this.props.healthMetricsSummary.forEach(metric => {
      const upper = metric.upperThreshold
      const lower = metric.lowerThreshold

      const stateMetric = { // Save the metric thresholds object
        friendlyName: metric.friendlyName,
        valueType: metric.valueType,
        syncedWith: metric.syncedWith,
        upperThreshold: metric.metricType !== BLOOD_PRESSURE
          ? (upper !== null ? upper : "") // convert null threshold to empty string
          : (upper.join('/') == '/' ? "" : upper.join('/')), // convert blood pressure [systolic, diastolic] threshold format to 'systolic/diastolic'
        lowerThreshold: metric.metricType !== BLOOD_PRESSURE
          ? (lower !== null ? lower : "")
          : (lower.join('/') == '/' ? "" : lower.join('/')),
      }

      this.setState(prevState => ({
        initialSettings: { // The initial state
          ...prevState.initialSettings,
          [metric.metricType]: stateMetric,
        },
        settings: { // The thresholds state which will be updated
          ...prevState.settings,
          [metric.metricType]: stateMetric,
        },
      }))
    })
  }

  componentDidMount() {
    this.props.getPersonaDevices(this.props.activeHouseholdId)
    this.initSettings()
  }

  componentWillUnmount() {
    this.props.clearPersonaDevices()
  }

  componentDidUpdate(lastProps) {
    const { isSaving } = this.props
    if (lastProps.isSaving === true && isSaving === false) {
      this.initSettings() // Initialise after save
    }
  }

  handleThresholdInputChange(event, isUpper) { // Update state based on threshold textfield change
    const key = event.target.name
    const value = event.target.value
    if ((isNaN(value) || value.includes('-') || value.includes('e')) && key !== BLOOD_PRESSURE) {
      return
    }

    this.setState(prevState => ({
      settings: {
        ...prevState.settings,
        [key]: {
          ...prevState.settings[key],
          [isUpper ? "upperThreshold" : "lowerThreshold"]: value,
        },
      },
    }))
  }

  handleSyncChange(event) { // Update state based on syncedWith dropdown menu change
    const key = event.target.name
    const value = event.target.value
    this.setState(prevState => ({
      settings: {
        ...prevState.settings,
        [key]: {
          ...prevState.settings[key],
          syncedWith: value,
        },
      },
    }))
  }

  handleTemperatureUnitsChange(units) {
    if (this.state.temperatureUnits !== units) {
      this.setState({ temperatureUnits: units })
      let upper, lower
      if (units === CELSIUS) {
        upper = this.convertToCelsius(this.state.settings.TEMPERATURE.upperThreshold)
        lower = this.convertToCelsius(this.state.settings.TEMPERATURE.lowerThreshold)
      } else if (units === FAHRENHEIT) {
        upper = this.convertToFahrenheit(this.state.settings.TEMPERATURE.upperThreshold)
        lower = this.convertToFahrenheit(this.state.settings.TEMPERATURE.lowerThreshold)
      }

      this.setState(prevState => ({
        settings: {
          ...prevState.settings,
          "TEMPERATURE": {
            ...prevState.settings["TEMPERATURE"],
            upperThreshold: upper,
            lowerThreshold: lower,
          },
        },
      }))
    }
  }

  isBloodPressureThresholdValid(threshold) { // Should be in the form <systolic>/<diastolic> or empty
    return threshold === "" || new RegExp('^[0-9]+/[0-9]+$').test(threshold)
  }

  areBothBloodPressureThresholdsValid() {
    const upper = this.state.settings[BLOOD_PRESSURE].upperThreshold
    const lower = this.state.settings[BLOOD_PRESSURE].lowerThreshold
    return this.isBloodPressureThresholdValid(upper) && this.isBloodPressureThresholdValid(lower)
  }

  handleReset() { // Reset settings to initial state
    this.setState({
      settings: JSON.parse(JSON.stringify(this.state.initialSettings)), // Deep copy
      temperatureUnits: this.state.originalTemperatureUnits,
    })
  }

  // Format correctly for API
  addFormattedSettings(thresholds, metricName, upper, lower, syncedWith) {
    const upperThresholdKey = 'healthMetrics.' + metricName + '.upperThreshold'
    const lowerThresholdKey = 'healthMetrics.' + metricName + '.lowerThreshold'
    const syncedWithKey = 'healthMetrics.' + metricName + '.syncedWith'
    thresholds[upperThresholdKey] = (upper === null || upper === "") ? null : Number(upper)
    thresholds[lowerThresholdKey] = (lower === null || lower === "") ? null : Number(lower)
    thresholds[syncedWithKey] = syncedWith
  }

  convertToCelsius(value) {
    if (!value && value !== 0) {
      return value
    }
    return Math.round(((value-32)/1.8)*100)/100 // Round to 2 decimal places
  }

  convertToFahrenheit(value) {
    if (!value && value !== 0) {
      return value
    }
    return Math.round((value * 1.8 + 32)*10)/10 // Round to 1 decimal place
  }

  convertTemperature(value) {
    if (value === -1 || this.state.temperatureUnits === this.state.originalTemperatureUnits) return value

    if (this.state.temperatureUnits === CELSIUS) {
      return this.convertToCelsius(value)
    } else if (this.state.temperatureUnits === FAHRENHEIT) {
      return this.convertToFahrenheit(value)
    }
  }

  getTemperatureUnits() {
    return this.state.temperatureUnits === FAHRENHEIT ? "°F" : "°C"
  }

  checkUpperAboveLower() {
    for (const metric in this.state.settings) {
      const values = this.state.settings[metric]
      if (metric === BLOOD_PRESSURE) {
        if (values.upperThreshold && values.lowerThreshold) {
          const [systolicUpper, diastolicUpper] = values.upperThreshold.split('/')
          const [systolicLower, diastolicLower] = values.lowerThreshold.split('/')
          if ((systolicUpper && systolicLower && Number(systolicUpper) < Number(systolicLower)) ||
            (diastolicUpper && diastolicLower && Number(diastolicUpper) < Number(diastolicLower))) {
            this.setState({ errorMetric: metric })
            return true
          }
        }
      } else if (values.upperThreshold && values.lowerThreshold && Number(values.upperThreshold) < Number(values.lowerThreshold)) {
        this.setState({ errorMetric: metric })
        return true
      }
    }
    return false
  }

  onSave() {
    this.setState({ bpUpperError: false })
    this.setState({ bpLowerError: false })
    this.setState({ errorMetric: "" })

    if (!this.isBloodPressureThresholdValid(this.state.settings[BLOOD_PRESSURE].upperThreshold)) {
      this.setState({ bpUpperError: true })
      return
    }
    if (!this.isBloodPressureThresholdValid(this.state.settings[BLOOD_PRESSURE].lowerThreshold)) {
      this.setState({ bpLowerError: true })
      return
    }
    if (this.checkUpperAboveLower()) {
      return
    }

    const settings = {
      'healthMetrics.TEMPERATURE.units': this.state.temperatureUnits,
    }

    let bpUpperArr
    let bpLowerArr
    Object.keys(this.state.settings).forEach(key => {
      const metric = this.state.settings[key]
      if (key === BLOOD_PRESSURE) { // Split BP into systolic and diastolic
        bpUpperArr = !metric.upperThreshold ? [null, null] : metric.upperThreshold.split('/')
        bpLowerArr = !metric.lowerThreshold ? [null, null] : metric.lowerThreshold.split('/')

        this.addFormattedSettings(settings, BLOOD_PRESSURE_SYSTOLIC, bpUpperArr[0], bpLowerArr[0], metric.syncedWith)
        this.addFormattedSettings(settings, BLOOD_PRESSURE_DIASTOLIC, bpUpperArr[1], bpLowerArr[1], metric.syncedWith)
      } else if (key === "TEMPERATURE") {
        let upper = metric.upperThreshold
        let lower = metric.lowerThreshold
        if (this.state.temperatureUnits === FAHRENHEIT) {
          upper = this.convertToCelsius(upper)
          lower = this.convertToCelsius(lower)
        }
        this.addFormattedSettings(settings, key, upper, lower, metric.syncedWith)
      } else {
        this.addFormattedSettings(settings, key, metric.upperThreshold, metric.lowerThreshold, metric.syncedWith)
      }
    })

    this.props.updateSettings({ // Update household doc
      householdId: this.props.activeHouseholdId,
      settings: settings,
    })

    const updatedSummary = JSON.parse(JSON.stringify(this.props.healthMetricsSummary)) // Deep copy
    // Update settings in the health metrics summary
    updatedSummary.forEach(element => {
      const upper = this.state.settings[element.metricType].upperThreshold
      const lower = this.state.settings[element.metricType].lowerThreshold
      element.upperThreshold = element.metricType === BLOOD_PRESSURE ? bpUpperArr
        : upper ? Number(upper) : null
      element.lowerThreshold = element.metricType === BLOOD_PRESSURE ? bpLowerArr
        : lower ? Number(lower) : null

      element.syncedWith = this.state.settings[element.metricType].syncedWith
      if (element.metricType === "TEMPERATURE") {
        element.metricValue = this.convertTemperature(element.metricValue)
        element.valueType = this.getTemperatureUnits()
      }
    })
    this.props.updateLatestMetrics(updatedSummary) // Update health metrics summary

  }

  getFitbitAuthUrl = () =>
  {
    const encodedUrl = encodeURIComponent(window.location.origin + '/')
    return "https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=22C7S6&redirect_uri=" + encodedUrl +
      "&scope=activity%20heartrate%20location%20nutrition%20profile%20settings%20sleep%20social%20weight&expires_in=604800&prompt=login&state=FitbitId-"
      + this.props.activeHouseholdId
  }

  doFitbitLogin = () =>
  {
    if (!this.props.isSyncedWithFitbit) {
      // First time syncing with Fitbit, send them to login/authorisation page
      window.location = this.getFitbitAuthUrl()
    }
  }

  onAdd = () => {
    const { getAddDeviceUrl, activeHouseholdId } = this.props
    this.setState({ openLoginDialog: true })
    getAddDeviceUrl(activeHouseholdId)
  }


  renderPersonaDevices = () => {
    const { isGettingDevices, healthMetricsDevices, getAddDeviceUrl, activeHouseholdId } = this.props

    if (isGettingDevices) {
      return (
        <Grid style={{ paddingLeft: 10 }}>
          <Skeleton variant="rect" width={400} height={30} animation="wave"/>
          <br />
          <Skeleton variant="rect" width={400} height={30} animation="wave"/>
        </Grid>
      )
    } else {
      return (
        <HealthMetricsDevices devices={healthMetricsDevices} />
      )
    }
  }

  getDeviceSyncMenuItems = (metric) => {

    const items = []
    const deviceTypes = []

    items.push(<MenuItem value="">Manual Input</MenuItem>)
    if (this.props.isSyncedWithFitbit)
    {
      // Fitbit integration only supports the following items: Weight, BMI, Resting Heart Rate, and Activity
      if(metric.friendlyName === "Weight" || metric.friendlyName === "BMI" || metric.friendlyName === "Resting Heart Rate" || metric.friendlyName === "Activity")
      {
        items.push(<MenuItem value='FITBIT'>FITBIT</MenuItem>)
        deviceTypes.push('FITBIT')
      }

    }
    if (this.props.healthMetricsDevices) {
      this.props.healthMetricsDevices.forEach(d => {
        if (!deviceTypes.includes(d.deviceType)) {
          items.push(<MenuItem value={d.deviceType}>{d.deviceType}</MenuItem>)
          deviceTypes.push(d.deviceType)
        }
      })
    }

    return items
  }

  render() {
    const {
      classes,
      close,
      isSaving,
      saveError,
      isSyncedWithFitbit,
      isDeveloper,
      isSupport,
      isDemoUser,
      activeHouseholdId,
      activeHouseholdV2,
      activeHouseholdSettings,
      getPersonaDevices,
      hasPersonaMetrics,
      toggleAdditionalDevices,
      addDeviceUrl,
      isGettingUrl,
      healthMetricsDevices,
    } = this.props

    return (
      <div className={classes.OverlayMain}>
        <div className={classes.OverlaySection}>
          <Typography variant="h5" style={{ textAlign: "left", paddingLeft: 20, paddingBottom: 10 }} >Instructions</Typography>
          <Typography style={{ textAlign: "justify", paddingLeft: 20, paddingBottom: 10 }}
            variant="body1" color="textSecondary">
            Setting the warning thresholds for health metrics which will turn the related icon amber to alert you if the value is outside the
            specified limits (leaving the box empty will remove the previously set threshold).
            Modifying the sync settings will also allow you to sync certain metrics with various third party health metric products or services.
          </Typography>


          <Typography variant="h5" style={{ textAlign: "left", paddingLeft: 20, paddingBottom: 10 }} >Sync Settings</Typography>

          <Card style={styles.cardContainer}>

            {/* Fitbit */}
            <Grid container direction='row' item justifyContent="flex-start" spacing={2}>
              <Grid item direction="row" >
                <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-evenly' }}>
                  <InteliIcon
                    style={{ paddingRight: 6 }}
                    name={'fitbit-icon'}
                    family={'custom'}
                    color={'#00B0B9'} />
                  <Typography variant="h6">Setup Fitbit:</Typography>
                </div>
              </Grid>

              <Grid item>
                <Typography variant="h6"><i>{isSyncedWithFitbit ? 'Synced' : 'Not Synced'}</i></Typography>
              </Grid>

              <Grid item style={{ flex: 1 }}></Grid>

              <Grid item sm={4} md={3} lg={2}>
                {
                  isSyncedWithFitbit ?
                    <Button startIcon={<SyncDisabledIcon />} variant="contained" color='primary' onClick={() => this.props.unSyncFitbit(activeHouseholdId)} disabled={!isSyncedWithFitbit}>Unsync</Button>
                    :
                    <Button startIcon={<LoginIcon />} variant="contained" color='primary' onClick={this.doFitbitLogin} disabled={isSyncedWithFitbit}>Login</Button>
                }
              </Grid>
            </Grid>

          </Card>

          {/* Persona */}
          <Card style={styles.cardContainer}>
            <Grid container justifyContent="center" align="center" direction="column" spacing={24}>

              <Grid container direction="column" item style={{ alignItems: 'flex-start' }}>
                {(isDeveloper || isSupport || isDemoUser) &&
              <Grid container flexDirection="row" style={{ paddingTop: 8 }}>
                <Icon style={{ alignSelf: 'center' }}>upgrade</Icon>
                <Typography style={{ paddingLeft: 20, paddingRight: 20 }} variant="h6">Upgrade Health Metrics (Support use only):</Typography>
                <div style={{ flex: 1 }}></div>
                {hasPersonaMetrics ?
                  <Button startIcon={<SyncDisabledIcon />} variant="contained" color='primary' onClick={() => toggleAdditionalDevices(activeHouseholdId, false)}>Disable Additional Devices</Button> :
                  <Button startIcon={<SyncIcon />} variant="contained" color='primary' onClick={() => toggleAdditionalDevices(activeHouseholdId, true)}>Enable Additional Devices</Button>
                }
              </Grid>}
                {hasPersonaMetrics ?
                  [<Grid key="title" container direction="row" item style={{ alignItems: 'center' }}>
                    <Typography variant="h5" style={{ textAlign: "left", paddingLeft: 20, paddingBottom: 20, paddingTop: 20 }} >Other Synced Devices</Typography>
                    {/* Refresh */}
                    <Tooltip
                      title={
                        <p style={{ alignContent: "center", textAlign: "center", whiteSpace: "pre-line" }}>
                          Refresh Devices
                        </p>
                      }>
                      <IconButton
                        onClick={() => {
                          getPersonaDevices(activeHouseholdId)
                        }}
                        style={{ height: 50, alignSelf: "center" }}
                      >
                        <SyncIcon
                          fontSize="large"
                          color={"primary"}
                          style={{
                            backgroundColor: "#F0F0F0",
                            borderRadius: "50%",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    {/* Add */}
                    <Tooltip
                      title={
                        <p style={{ alignContent: "center", textAlign: "center", whiteSpace: "pre-line" }}>
                          Add new device
                        </p>
                      }>
                      <IconButton
                        onClick={this.onAdd}
                        style={{ height: 50, alignSelf: "center" }}
                      >
                        <AddCircleIcon
                          fontSize="large"
                          color={"primary"}
                          style={{
                            backgroundColor: "#F0F0F0",
                            borderRadius: "50%",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>,
                  this.renderPersonaDevices(),
                  ] : []
                }
              </Grid>
            </Grid>
          </Card>



          {/* Metric Units */}
          <Typography variant="h5" style={{ textAlign: "left", paddingLeft: 20, paddingBottom: 5, paddingTop: 10 }} >Units</Typography>
          <Card style={styles.cardContainer}>

            <Grid container justifyContent="center" align="center" direction="column" spacing={24}>
              <Grid container direction='row' item>
                <Grid item sm={4} md={3} lg={3} direction="row">
                  <Typography variant="h6">Body Temperature:</Typography>
                </Grid>
                <Grid item sm={4} md={3} lg={3}>
                  <Select
                    style={{ width: "60%" }}
                    value={this.state.temperatureUnits}
                    onChange={(event) => this.handleTemperatureUnitsChange(event.target.value)}
                  >
                    <MenuItem value={CELSIUS}>{CELSIUS}</MenuItem>
                    <MenuItem value={FAHRENHEIT}>{FAHRENHEIT}</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </Card>




          <Typography variant="h5" style={{ textAlign: "left", paddingLeft: 20, paddingBottom: 5, paddingTop: 10 }} >Metric Thresholds</Typography>

          <Card style={styles.cardContainer}>

            <Grid container justify="center" align="center" direction="column" spacing={24}>

              {this.state.settings && Object.keys(this.state.settings).map(key => {
                const isBloodPressure = key === BLOOD_PRESSURE
                const metric = this.state.settings[key]

                return <Grid key={key+".thresholds"} container direction="row" item xs={12}>
                  <Grid item sm={12} md={12} lg={3}>
                    <Typography variant="h6" style={{ paddingTop: 12 }} >{metric.friendlyName}</Typography>
                  </Grid>
                  <Grid item sm={12} md={12} lg={3}>
                    <ThresholdTextField name={key} isBloodPressure={isBloodPressure} metric={metric}
                      handler={e => this.handleThresholdInputChange(e, true)} isUpper={true}
                      upperLowerError={this.state.errorMetric === key}
                      bpError={isBloodPressure && this.state.bpUpperError}
                      temperatureUnits={key === "TEMPERATURE" ? this.getTemperatureUnits() : null}/>
                  </Grid>
                  <Grid item sm={12} md={12} lg={3}>
                    <ThresholdTextField name={key} isBloodPressure={isBloodPressure} metric={metric}
                      handler={e => this.handleThresholdInputChange(e, false)} isUpper={false}
                      upperLowerError={false}
                      bpError={isBloodPressure && this.state.bpLowerError}
                      temperatureUnits={key === "TEMPERATURE" ? this.getTemperatureUnits() : null}/>
                  </Grid>
                  <Grid item sm={12} md={12} lg={3}>
                    <InputLabel shrink id={key+'.syncLabel'}
                      style={{ textAlign: "left", paddingLeft: "30%" }}>Sync With</InputLabel>
                    <Select
                      labelId={key+'.syncLabel'}
                      style={{ width: "60%" }}
                      value={metric.syncedWith}
                      name={key}
                      onChange={this.handleSyncChange}
                      displayEmpty
                    >
                      {this.getDeviceSyncMenuItems(metric)}
                    </Select>
                  </Grid>
                </Grid>
              })}
            </Grid>

          </Card>

        </div>
        <div className={classes.OverlayFooter}>
          <FormButtons close={close} handleReset={this.handleReset} handleSubmit={this.onSave} activeHousehold={activeHouseholdV2}
            isSubmitting={isSaving} isSaving={isSaving} saveError={saveError} activeHouseholdSettings={activeHouseholdSettings}
            dirty={JSON.stringify(this.state.initialSettings) !== JSON.stringify(this.state.settings)
              || this.state.temperatureUnits !== this.state.originalTemperatureUnits}/>
        </div>

        <AddDeviceDialog open={this.state.openLoginDialog} getPersonaDevices={() => getPersonaDevices(activeHouseholdId)} onClose={() => this.setState({ openLoginDialog: false })}
          url={addDeviceUrl} loading={isGettingUrl} />
      </div>
    )
  }
}

HealthMetricSettings.propTypes = {
  healthMetricsSummary: PropTypes.array,
  close: PropTypes.func,
  activeHouseholdV2: PropTypes.object,
  activeHouseholdId: PropTypes.string,
  updateSettings: PropTypes.func,
  updateLatestMetrics: PropTypes.func,
  isSaving: PropTypes.bool,
  saveError: PropTypes.object,
  classes: PropTypes.object,
  isSyncedWithFitbit: PropTypes.bool,
  isDeveloper: PropTypes.bool,
  isSupport: PropTypes.bool,
  isDemoUser: PropTypes.bool,
  unSyncFitbit: PropTypes.func,
  householdDocHealthMetrics: PropTypes.array,
  activeHouseholdSettings: PropTypes.object,
  getPersonaDevices: PropTypes.func,
  clearPersonaDevices: PropTypes.func,
  getAddDeviceUrl: PropTypes.func,
  healthMetricsDevices: PropTypes.array,
  isGettingDevices: PropTypes.bool,
  hasPersonaMetrics: PropTypes.bool,
  toggleAdditionalDevices: PropTypes.func,
  addDeviceUrl: PropTypes.string,
  isGettingUrl: PropTypes.bool,
}

const mapStateToProps = state =>
{
  return {
    activeHouseholdV2: getActiveHouseholdV2(state),
    activeHouseholdId: state.householdsV2.activeHouseholdId,
    healthMetricsSummary: state.healthMetrics.summary,
    isSaving: state.households.settings.isSaving,
    saveError: state.households.settings.error,
    isSyncedWithFitbit: access(getActiveHouseholdV2(state), 'data.fitbitData.synced'),
    isDeveloper: state.user.isDeveloper,
    isSupport: state.user.isSupport,
    isDemoUser: state.user.isDemoUser,
    householdDocHealthMetrics: access(getActiveHouseholdV2(state), 'data.healthMetrics'),
    activeHouseholdSettings: getActiveHouseholdSettings(state),
    healthMetricsDevices: state.healthMetrics.devices,
    isGettingDevices: state.healthMetrics.isGettingDevices,
    hasPersonaMetrics: access(getActiveHouseholdV2(state), 'data.features.enablePersonaHealthMetrics'),
    addDeviceUrl: state.healthMetrics.addDeviceUrl,
    isGettingUrl: state.healthMetrics.isGettingUrl,
  }
}

const mapDispatchToProps = dispatch =>
{
  return {
    updateSettings: (params) => {
      dispatch(callAPI(HOUSEHOLD_SETTING_BULK(params))) // Update household doc
      dispatch(updateHealthMetricsSettings(params))
    },
    updateLatestMetrics: (summaryItems) => dispatch({
      type: "UPDATE_HEALTH_SUMMARY",
      data: {
        summary: summaryItems,
        updatedMetric: {},
      },
    }),
    unSyncFitbit: (householdId) => {
      const params = {
        householdId,
        settings: {
          "fitbitData.synced": false,
        },
      }
      dispatch(callAPI(HOUSEHOLD_SETTING_BULK(params))) // Update household doc
      dispatch(updateBehaviourSetting(householdId, "fitbitData.synced", false))
    },
    getPersonaDevices: (householdId) => {
      dispatch(callAPI(GET_HEALTH_METRICS_DEVICES(householdId)))
    },
    clearPersonaDevices: () => {
      dispatch({
        type: "CLEAR_GET_HEALTH_METRICS_DEVICES",
      })
      dispatch({
        type: "CLEAR_GET_ADD_DEVICE_URL",
      })
    },
    toggleAdditionalDevices: (householdId, value) => {
      const params = {
        householdId,
        settings: {
          "features.enablePersonaHealthMetrics": value,
        },
      }
      dispatch(callAPI(HOUSEHOLD_SETTING_BULK(params))) // Update household doc
      dispatch(updateBehaviourSetting(householdId, "features.enablePersonaHealthMetrics", value))
    },
    getAddDeviceUrl: (householdId) => {
      dispatch(callAPI(GET_ADD_DEVICE_URL(householdId)))
    },
  }
}

const styles = {
  cardContainer:
    {
      padding: 12,
      margin: 12,
    },
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Styles),
  withStyles(PortalOverlayStyles)
)(HealthMetricSettings)
