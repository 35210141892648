import React from 'react'
import Card from '@material-ui/core/Card'
import Avatar from '@material-ui/core/Avatar'
import CardHeader from '@material-ui/core/CardHeader'
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import { PresenceBehaviour } from './util/getPresenceBehaviour';
import { getOutingStats } from './util/getOutingStats'

interface WeekTrendsProps
{
  presence: PresenceBehaviour
}

export const WeekTrends = ({ presence } : WeekTrendsProps) =>
{
  const outings = getOutingStats(presence)
  return(
    <Card style={styles.cardContainer}>
      <CardHeader
        titleTypographyProps={{ variant:'h6' }}

        avatar={
          <Avatar style={{ ...styles.avatar, backgroundColor: outings ? outings.color: "grey" }}>
            <NaturePeopleIcon fontSize="large" />
          </Avatar>
        }

        title={`${outings ? outings.current + " " : ""}Weekly outings`}
        subheader={outings ? outings.message : "None detected"}
      />
    </Card>
  )
}

const styles = {
  cardContainer: {
    padding: 12,
    flex: 1,
    borderRadius: 6,
    marginBottom: 12,
  },
  avatar: {
    width: 100,
    height: 100,
  },
  icon: {
    width: 100,
    height: 100,
  },
}