import React from 'react'
import Skeleton from 'react-loading-skeleton'
import Grid from '@material-ui/core/Grid'

export const LoadingSkeleton = () =>
{
  return(
    <>

      <Skeleton style={styles.item} height={50} />

      <Grid container spacing={3}>
        <Grid item xs={12} lg={4} md={12} xl={4} sm={12}>
          <Skeleton style={styles.item} height={150} />
        </Grid>

        <Grid item xs={12} lg={4} md={12} xl={4} sm={12}>
          <Skeleton style={styles.item} height={150} />
        </Grid>

        <Grid item xs={12} lg={4} md={12} xl={4} sm={12}>
          <Skeleton style={styles.item} height={150} />
        </Grid>

      </Grid>

      <Grid container spacing={3}>

        <Grid item xs={12} lg={6} md={12} xl={6} sm={12}>

          <Skeleton style={styles.item} height={300} />

        </Grid>

        <Grid item xs={12} lg={6} md={12} xl={6} sm={12}>

          <Skeleton style={styles.item} height={300} />

        </Grid>


      </Grid>

      <Skeleton style={styles.item} count={10} height={50} />


    </>
  )
}

const styles = {
  item: {
    margin: 12,
  },
}