import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

interface ConfirmEnableDialogProps
{
  open: boolean;
  handleClose: () => void;
  handleConfirmed: () => void;
}

export const ConfirmEnableDialog = ({ open, handleClose, handleConfirmed }: ConfirmEnableDialogProps) =>
{
  return(
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Enable Monitoring Service"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
      Please confirm with Sales before enabling this service.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmed} color="primary" autoFocus>
    Confirmed
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
    Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}