import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import access from "safe-access"
import {
  Grid,
  Typography,
  Tooltip,
} from '@material-ui/core'
import Guage from '../../../../components/Guage'

import InteliIcon from '../../../../components/InteliIcon/index'
import sharedStore from '../../../../redux'

const { BehaviourType } = sharedStore.behaviour

class NightGuages extends Component {
  constructor(props) {
    super(props)

    this.state = {
      bedSd: 0,
      bedLast: moment(),
      bedNormal: moment(),
      activitySd: 0,
      activityLast: 0,
      activityNormal: 0,
      bathSd: 0,
      bathLast: 0,
      bathNormal: 0,
      ariseSd: 0,
      ariseLast: moment(),
      ariseNormal: moment(),
      sleepSd: 0,
      sleepLast: 0,
      sleepNormal: 0,
      lastUpdatedBathVisit: "",
    }
  }

  componentDidMount() {
    this.setStats();
  }

  componentDidUpdate(lastProps) {
    if (this.props !== lastProps) {
      this.setStats()
    }
  }

  setStats = () => {
    const { behaviours, timezone: tz } = this.props
    const now = moment().tz(tz)
    const sleepBehaviour = behaviours.find(b => b.type === BehaviourType.SLEEP)
    const ariseBehaviour = behaviours.find(b => b.type === BehaviourType.ARISE)
    const nightBehaviour = behaviours.find(b => b.type === BehaviourType.NIGHT_TIME_ACTIVITY)
    const nightBathBehaviour = behaviours.find(b => b.type === BehaviourType.BATHROOM_VISIT)

    const activityNormal = Number(access(nightBehaviour, 'statistics.average')) || 0
    const activityLast = Number(access(nightBehaviour, 'lastValue')) || 0
    const activitySd = Number(access(nightBehaviour, 'statistics.stdDev')) || 0

    const ariseNormal = moment(access(ariseBehaviour, 'statistics.avgArise')).tz(tz).date(now.date())
    const ariseLast = moment(access(ariseBehaviour, 'lastArise')).tz(tz).date(now.date())
    const ariseSd = Number(access(ariseBehaviour, 'statistics.results.standardDeviation')) || 0

    const sleepNormal = Number(access(sleepBehaviour, 'statistics.mean')) / 60
    const sleepLast = Number(access(sleepBehaviour, 'statistics.lastValue')) / 60
    const sleepSd = Number(access(sleepBehaviour, 'statistics.standardDeviation')) / 60

    const bathNormal = Number(access(nightBathBehaviour, 'sleepTime.dayStat.average')) || 0
    const bathLast = Number(access(nightBathBehaviour, 'sleepTime.dayStat.previous.count')) || 0
    const bathSd = Number(access(nightBathBehaviour, 'sleepTime.dayStat.stdDev')) || 0
    const lastUpdatedBathVisit = access(nightBathBehaviour, 'sleepTime.dayStat.previous.start');

    const bedNormal = moment(access(sleepBehaviour, 'sleepTimeStatistics.averageValue')).tz(tz)
    const bedNormalDayDiff = now.diff(bedNormal, 'days')
    bedNormal.add(bedNormalDayDiff, 'days')
    const bedLast = moment(access(sleepBehaviour, 'sleepTimeStatistics.lastValue')).tz(tz)
    const bedLastDayDiff = now.diff(bedLast, 'days')
    bedLast.add(bedLastDayDiff, 'days')
    const bedSd = access(sleepBehaviour, 'sleepTimeStatistics.standardDeviation') || 0

    const lastUpdated = access(sleepBehaviour, 'lastUpdated') || ""

    this.setState({
      bedSd,
      bedLast,
      bedNormal,
      activitySd,
      activityLast,
      activityNormal,
      bathSd,
      bathLast,
      bathNormal,
      ariseSd,
      ariseLast,
      ariseNormal,
      sleepSd,
      sleepLast,
      sleepNormal,
      lastUpdated,
      lastUpdatedBathVisit,
    })
  }

  render() {
    const {
      bedSd,
      bedLast,
      bedNormal,
      activitySd,
      activityLast,
      activityNormal,
      bathSd,
      bathLast,
      bathNormal,
      ariseSd,
      ariseLast,
      ariseNormal,
      sleepSd,
      sleepLast,
      sleepNormal,
      lastUpdated,
      lastUpdatedBathVisit,
    } = this.state

    return (
      <Grid container >
        <Grid container direction="row" justify='space-evenly'>
          <Grid item>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Typography variant='subtitle1'>
                Bed Time
              </Typography>
            </div>
            <Guage width={150} height={50}
              min={-2 * bedSd}
              max={2 * bedSd}
              value={bedLast.diff(bedNormal, 'minutes', true)}
              labelLow='Early'
              labelHigh='Late' />
            <div style={{ textAlign: 'center' }}>
              <div>
                <Typography variant='body1'>
                  Last Night: {bedLast.format('h:mma')}
                </Typography>
              </div>
              <div>
                <Typography variant='body1'>
                  Normal: {bedNormal.format('h:mma')}
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item>
            <div style={{ textAlign: 'center' }}>
              <Typography variant='subtitle1'>
                Night time Activity
              </Typography>
            </div>
            <Guage width={150} height={50}
              min={activityNormal - 2 * activitySd} max={activityNormal + 2 * activitySd} value={activityLast} />
            <div style={{ textAlign: 'center' }}>
              <div>
                <Typography variant='body1'>
                  Last Night: {activityLast.toFixed(0)}
                </Typography>
              </div>
              <div>
                <Typography variant='body1'>
                  Normal: {activityNormal.toFixed(0)}
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Typography variant='subtitle1'>
                Bathroom Visits
              </Typography>
            </div>
            <Guage width={150} height={50} min={bathNormal - 2 * bathSd} max={bathNormal + 2 * bathSd} value={bathLast} />
            <div style={{ textAlign: 'center' }}>
              <div>
                <Tooltip title={`Last updated: ${moment(lastUpdatedBathVisit).format("LLLLZ")} - ${moment(lastUpdatedBathVisit).fromNow()}`}>
                  <Typography variant='body1'>
                  Last Night: {bathLast}
                  </Typography>
                </Tooltip>
              </div>
              <div>
                <Typography variant='body1'>
                  Normal: {bathNormal}
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item>
            <div style={{ textAlign: 'center' }}>
              <Typography variant='subtitle1'>
                Arise Time
              </Typography>
            </div>
            <Guage width={150} height={50}
              min={-2 * ariseSd}
              max={2 * ariseSd}
              value={ariseLast.diff(ariseNormal, 'minutes', true)}
              labelLow='Early'
              labelHigh='Late' />
            <div style={{ textAlign: 'center' }}>
              <div>
                <Typography variant='body1'>
                  Last Arise: {ariseLast.format("h:mma")}
                </Typography>
              </div>
              <div>
                <Typography variant='body1'>
                  Normal: {ariseNormal.format("h:mma")}
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Typography variant='subtitle1'>
                Sleep Amount
              </Typography>
            </div>
            <Guage width={150} height={50} min={sleepNormal - 2 * sleepSd} max={sleepNormal + 2 * sleepSd} value={sleepLast} />
            <div style={{ textAlign: 'center' }}>
              <div>
                <Typography variant='body1'>
                  Last Night: {sleepLast.toFixed(1)}h
                </Typography>
              </div>
              <div>
                <Typography variant='body1'>
                  Normal: {sleepNormal.toFixed(1)}h
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid style={{ paddingTop: 12 }} container justify="flex-end" direction="row">
          <InteliIcon style={{ paddingRight: 4 }} color={"rgba(0, 0, 0, 0.54)"} name="timer" size={24} />
          <Typography style={{ color: "rgba(0, 0, 0, 0.54)" }}>Last updated {moment(lastUpdated).fromNow()}</Typography>
        </Grid>
      </Grid>
    )
  }

  static propTypes = {
    behaviours: PropTypes.array,
    timezone: PropTypes.string,
  }
}

export default NightGuages
