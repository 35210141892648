import { Builder } from "./ApiBuilder";

export interface AuditRecord
{
  eventTimestamp: string;
  userId: string;
  description: string;
  householdId: string;
  isAdmin: boolean,
  householdFriendlyName: string;
  userDetails: {
      givenName: string;
      surname: string;
      email: string;
  };
  id: string;
}

export class AuditLogsApi extends Builder
{
  constructor(public token: string)
  {
    super(true);
  }

  get = async (query: string, order: "ASC" | "DESC", offset: number, limit: number): Promise<AuditRecord[]> =>
  {
    try
    {
      const response = await this.call(`GET`, `/api/system/management?auditLogs=true&query=${query}&order=${order}&offset=${offset}&limit=${limit}`, null, this.token);
      console.log("Got response", response)
      if(response && response.data) return response.data
      return []
    }
    catch(error)
    {
      console.log("E", error)
      return []
    }

  }
}