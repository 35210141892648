import React from 'react'
import DocumentTitle from 'react-document-title'
import ContentStrapline from '../../../components/ContentStrapline'


import Title from "../../../components/Title"
import { withErrorHandler, Fallback } from '../../../hoc/withErrorHandler'
import { appInsights } from '../../../api/AppInsights'
import { TabView } from './TabView'

// Tabs
import { UserConfigurationView } from './UserConfigurationView';
import SensorConfigurationView from './SensorConfigurationTabView';
import GatewayList from './GatewayProvisioning'
import AuditLogsView from './AuditLogsView'
export class AdminView extends React.Component {
  static friendlyName = "Admin"
  static pageName = "Admin"

  state = {
    currentTabIndex: 0,
  }

  componentDidMount()
  {
    appInsights.trackPageView({ name: "Admin View" })
  }

  render() {

    const { currentTabIndex } = this.state;
    return (<div className='admin'>
      <DocumentTitle title='Admin' />

      <ContentStrapline
        title='InteliCare Admin'
        status={'ic-okay'}
      />
      <Title title="InteliCare Admin" />
      <div style={styles.container} className='col-xs-12 col-main-content content--container-content'>

        <TabView currentTabIndex={currentTabIndex} handleTabChange={(i) => this.setState({ currentTabIndex: i })}/>

        {currentTabIndex === 0 && <UserConfigurationView />}
        {currentTabIndex === 1 && <AuditLogsView />}
        {currentTabIndex === 2 && <SensorConfigurationView />}
        {currentTabIndex === 3 && <GatewayList />}

      </div>

    </div>
    )
  }
}

const styles = {
  container: {
    margin: 12,
    paddingTop: 12,
  },
}
export default withErrorHandler(Fallback)(AdminView)
