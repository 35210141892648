import React from "react";
import PropTypes from "prop-types";

/* Custom SVG Icons */

const CustomIcon = ({ name, onClick, style }) => {
  CustomIcon.propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.any,
  };

  switch (name) {
    case "sink":
      return (
        <span
          style={{ ...style, fontSize: "" }}
          className="community-icons-container"
        >
          <svg onClick={onClick} height="48" viewBox="0 -32 512 512" width="48">
            <path
              fill={style.color || "#000000"}
              d="m44.109375 205.921875c9.429687 84.105469 78.550781 150.417969 163.890625 155.457031v87.378906h96v-87.152343c87.628906-2.6875 159.277344-69.941407 168.890625-155.683594zm0 0"
            />
            <path
              fill={style.color || "#000000"}
              d="m512 175.921875v-29.722656h-115.46875v-30.902344h-30v30.902344h-95.03125v-116.199219h48.660156v24.851562h-10.660156v30h51.03125v-30h-10.371094v-54.851562h-108.660156v146.199219h-58.167969v-52.070313h-75.976562v52.070313h-107.355469v29.722656zm0 0"
            />
          </svg>
        </span>
      );
    case "sink-small":
      return (
        <span style={style} className="community-icons-container">
          <svg onClick={onClick} height="24" viewBox="0 -56 512 480" width="24">
            <path
              fill={style.color || "#000000"}
              d="m44.109375 205.921875c9.429687 84.105469 78.550781 150.417969 163.890625 155.457031v87.378906h96v-87.152343c87.628906-2.6875 159.277344-69.941407 168.890625-155.683594zm0 0"
            />
            <path
              fill={style.color || "#000000"}
              d="m512 175.921875v-29.722656h-115.46875v-30.902344h-30v30.902344h-95.03125v-116.199219h48.660156v24.851562h-10.660156v30h51.03125v-30h-10.371094v-54.851562h-108.660156v146.199219h-58.167969v-52.070313h-75.976562v52.070313h-107.355469v29.722656zm0 0"
            />
          </svg>
        </span>
      );
    case "bed-unoccupied":
      return (
        <span style={style} className="community-icons-container">
          <svg onClick={onClick} height="48" viewBox="0 0 640 512" width="56">
            <path
              fill={style.color || "#000000"}
              d="M 64,288 V 80 C 64,71.16 56.84,64 48,64 H 16 C 7.16,64 0,71.16 0,80 v 352 c 0,8.84 7.16,16 16,16 h 32 c 8.84,0 16,-7.16 16,-16 v -48 h 512 v 48 c 0,8.84 7.16,16 16,16 h 32 c 8.84,0 15.9218,-7.16035 16,-16 l 1.27119,-143.69491 C 278.78986,287.48465 387.48149,289.18959 64,288 Z"
            />
          </svg>
        </span>
      );
    case "lungs":
      return (
        <span style={style} className="">
          <svg
            onClick={onClick}
            height="42"
            viewBox="48 -16 512 512"
            width="58"
          >
            <path
              fill={style.color || "#000000"}
              d="M636.11 390.15C614.44 308.85 580.07 231 534.1 159.13 511.98 124.56 498.03 96 454.05 96 415.36 96 384 125.42 384 161.71v60.11l-32.88-21.92a15.996 15.996 0 0 1-7.12-13.31V16c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v170.59c0 5.35-2.67 10.34-7.12 13.31L256 221.82v-60.11C256 125.42 224.64 96 185.95 96c-43.98 0-57.93 28.56-80.05 63.13C59.93 231 25.56 308.85 3.89 390.15 1.3 399.84 0 409.79 0 419.78c0 61.23 62.48 105.44 125.24 88.62l59.5-15.95c42.18-11.3 71.26-47.47 71.26-88.62v-87.49l-85.84 57.23a7.992 7.992 0 0 1-11.09-2.22l-8.88-13.31a7.992 7.992 0 0 1 2.22-11.09L320 235.23l167.59 111.72a7.994 7.994 0 0 1 2.22 11.09l-8.88 13.31a7.994 7.994 0 0 1-11.09 2.22L384 316.34v87.49c0 41.15 29.08 77.31 71.26 88.62l59.5 15.95C577.52 525.22 640 481.01 640 419.78c0-9.99-1.3-19.94-3.89-29.63z"
            />
          </svg>
        </span>
      );
    case "blood-pressure":
      return (
        <span style={style} className="">
          <svg
            onClick={onClick}
            width="45"
            height="45"
            viewBox="0 0 2000.000000 1000.000000"
          >
            <g
              transform="translate(0.000000,1676.000000) scale(0.100000,-0.100000)"
              fill={style.color || "#000000"}
              stroke="none"
            >
              <path
                d="M6365 15349 c-639 -53 -1265 -246 -1801 -555 -1106 -637 -1870 -1708 -2108 -2954 -53 -278 -70 -467 -70 -810 0 -379 20 -614 85 -970 143 -782 464 -1603 936 -2392 l64 -108 1592 0 1592 0 23 43 c13 23 53 96 89 162 36 66 78
143 93 170 15 28 93 169 173 315 79 146 156 285 169 310 14 25 69 125 123 224
54 98 111 194 127 212 16 18 57 47 90 64 82 40 180 42 260 6 90 -42 52 7 490
-636 406 -598 411 -605 419 -584 5 10 101 381 214 824 114 443 224 873 245
955 21 83 102 398 180 700 78 303 156 609 175 680 18 72 61 238 95 370 80 313
86 331 131 392 29 38 55 60 103 83 59 29 71 32 143 28 89 -4 147 -27 205 -82
57 -54 82 -116 117 -286 17 -85 55 -274 85 -420 30 -146 109 -530 176 -855 67
-324 152 -743 191 -930 38 -187 110 -536 159 -775 49 -239 123 -599 164 -800
41 -201 109 -531 151 -735 42 -203 99 -484 128 -623 33 -161 54 -247 59 -237
5 8 31 79 57 157 27 79 74 215 104 303 31 88 87 252 125 365 39 113 123 358
187 545 149 433 342 998 406 1184 66 195 101 242 213 292 58 25 160 28 224 5
95 -34 72 -4 585 -766 98 -146 238 -354 310 -462 l132 -197 1619 2 1618 2 45
73 c149 246 351 632 477 915 549 1226 703 2455 441 3514 -123 495 -311 926
-590 1348 -572 867 -1427 1495 -2422 1781 -557 160 -1204 206 -1718 123 -885
-144 -1738 -624 -2476 -1394 -164 -172 -297 -329 -432 -509 -49 -67 -91 -123
-94 -126 -2 -3 -9 3 -14 12 -5 9 -57 79 -117 156 -250 326 -485 573 -827 871
-349 304 -833 599 -1260 769 -304 121 -538 184 -865 233 -152 23 -612 34 -795
18z"
              />
              <path
                d="M9877 9897 c-42 -166 -111 -432 -152 -592 -41 -159 -116 -450 -166
-645 -49 -195 -105 -413 -124 -485 -72 -277 -119 -460 -186 -725 -134 -527
-134 -526 -210 -595 -93 -84 -223 -105 -329 -53 -89 44 -101 59 -474 613 -59
88 -144 214 -190 280 -45 66 -140 205 -210 308 -70 104 -130 186 -133 184 -5
-6 -111 -198 -350 -637 -287 -527 -284 -522 -378 -573 l-40 -22 -1529 -5
-1528 -5 48 -65 c302 -409 660 -850 904 -1115 464 -503 836 -872 1360 -1349
165 -150 463 -407 710 -612 929 -769 1983 -1532 3187 -2308 l153 -98 82 53
c436 278 1054 693 1417 949 73 52 152 108 175 124 505 352 1459 1094 1956
1522 78 67 212 184 255 223 28 26 69 61 90 80 281 243 907 849 1186 1146 295
315 530 584 764 875 136 170 407 526 425 561 10 18 -13 19 -1512 19 -967 0
-1536 4 -1558 10 -45 13 -110 56 -142 95 -14 17 -99 140 -190 275 -90 135
-229 341 -308 458 -78 116 -146 212 -149 212 -3 0 -61 -161 -128 -358 -66
-196 -203 -595 -303 -887 -100 -291 -188 -548 -195 -570 -7 -22 -57 -166 -110
-320 -53 -154 -103 -298 -110 -320 -50 -155 -238 -693 -255 -732 -28 -63 -91
-126 -154 -154 -78 -35 -183 -32 -260 6 -32 16 -73 47 -91 68 -63 73 -52 32
-195 727 -32 157 -118 573 -190 925 -72 352 -146 714 -165 805 -19 91 -70 341
-114 555 -186 909 -334 1626 -381 1855 -28 135 -67 324 -86 420 -20 96 -36
176 -37 177 -1 1 -37 -134 -80 -300z"
              />
            </g>
          </svg>
        </span>
      );
    case "weight":
      return (
        <span style={style} className="">
          <svg
            onClick={onClick}
            viewBox="0 0 460 400"
            height="42"
            fill={style.color || "#000000"}
            width="45"
            version="1.1"
          >
            <g id="g10">
              <g id="g8">
                <path
                  id="path2"
                  d="M279.27,392.188h-30.51V112.579h128.58l-54.464,86.423c-2.762,4.382-1.448,10.176,2.935,12.937    c1.554,0.98,3.282,1.445,4.992,1.445c3.115,0,6.161-1.551,7.944-4.378l47.235-74.954l47.233,74.954    c2.763,4.382,8.557,5.696,12.938,2.933c4.382-2.761,5.694-8.555,2.933-12.937l-54.462-86.423h0.688    c8.029,0,14.539-6.51,14.539-14.539c0-8.028-6.509-14.538-14.539-14.538H288.356c-6.04-8.786-14.954-15.438-25.39-18.596    C261.475,48.005,247.289,34.75,230,34.75c-17.29,0-31.475,13.255-32.967,30.156c-10.436,3.158-19.35,9.81-25.389,18.596H64.688    c-8.03,0-14.54,6.51-14.54,14.538c0,8.029,6.509,14.539,14.54,14.539h0.688l-54.464,86.423    c-2.762,4.382-1.448,10.176,2.934,12.937c1.554,0.98,3.283,1.445,4.992,1.445c3.114,0,6.162-1.551,7.945-4.378l47.235-74.954    l47.234,74.954c2.762,4.382,8.555,5.696,12.937,2.933c4.383-2.761,5.696-8.555,2.935-12.937l-54.463-86.423h128.58v279.607    h-30.509c-18.259,0-33.062,14.804-33.062,33.063h164.661C312.331,406.99,297.528,392.188,279.27,392.188z"
                />
                <path
                  id="path4"
                  d="M143.035,223.359H5c-2.761,0-5,2.238-5,5c0,40.813,33.204,74.017,74.018,74.017c40.813,0,74.018-33.204,74.018-74.017    C148.035,225.598,145.796,223.359,143.035,223.359z"
                />
                <path
                  id="path6"
                  d="M455,223.359H316.965c-2.761,0-5,2.238-5,5c0,40.813,33.204,74.017,74.019,74.017c40.813,0,74.017-33.204,74.017-74.017    C460,225.598,457.761,223.359,455,223.359z"
                />
              </g>
            </g>
            <g id="g12" />
            <g id="g14" />
            <g id="g16" />
            <g id="g18" />
            <g id="g20" />
            <g id="g22" />
            <g id="g24" />
            <g id="g26" />
            <g id="g28" />
            <g id="g30" />
            <g id="g32" />
            <g id="g34" />
            <g id="g36" />
            <g id="g38" />
            <g id="g40" />
          </svg>
        </span>
      );
    case "blood-oxygenation":
      return (
        <span style={style} className="">
          <svg
            onClick={onClick}
            width="44"
            height="44"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill={style.color || "#000000"}
              stroke="none"
            >
              <path
                d="M2230 5104 c-996 -127 -1833 -835 -2123 -1794 -78 -256 -107 -462
-107 -750 0 -491 116 -914 363 -1325 404 -672 1099 -1123 1877 -1220 162 -20
478 -20 640 0 1001 124 1842 831 2133 1795 78 256 107 462 107 750 0 491 -116
914 -363 1325 -404 672 -1099 1123 -1877 1220 -153 19 -501 18 -650 -1z m475
-1305 c338 -41 597 -221 743 -514 129 -259 158 -603 76 -915 -31 -119 -117
-294 -187 -380 -190 -233 -494 -355 -835 -337 -132 8 -258 33 -352 71 -323
131 -530 428 -579 831 -46 378 53 729 272 961 213 226 516 325 862 283z m1156
-1775 c176 -52 213 -274 79 -469 -19 -28 -79 -96 -133 -152 l-100 -103 174 -2
174 -3 3 -67 3 -68 -291 0 -290 0 0 71 0 71 128 127 c225 223 290 361 202 435
-57 47 -176 40 -268 -16 -18 -11 -34 -19 -36 -17 -2 2 -6 36 -10 76 l-6 72 57
21 c113 42 223 51 314 24z"
              />
              <path
                d="M2401 3444 c-186 -50 -341 -211 -409 -424 -31 -97 -42 -172 -42 -291
0 -374 178 -647 466 -715 83 -19 262 -14 337 10 152 49 268 150 340 298 70
143 82 202 82 408 0 153 -3 185 -23 254 -71 245 -242 422 -450 465 -73 15
-236 13 -301 -5z"
              />
            </g>
          </svg>
        </span>
      );
    case "blood-glucose":
      return (
        <span style={style} className="">
          <svg
            onClick={onClick}
            width="45"
            height="45"
            viewBox="0 0 981.000000 981.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,981.000000) scale(0.100000,-0.100000)"
              fill={style.color || "#000000"}
              stroke="none"
            >
              <path
                d="M4540 9799 c-771 -55 -1443 -234 -2090 -555 -1111 -551 -1920 -1444
-2270 -2504 -73 -221 -132 -495 -162 -755 -16 -143 -16 -597 0 -740 126 -1091
687 -2045 1622 -2762 104 -80 332 -235 423 -287 l47 -28 0 -982 c0 -1100 -3
-1054 69 -1119 21 -19 55 -40 76 -47 46 -15 128 -7 164 16 14 10 415 333 890
718 l863 701 62 -1 c33 0 148 -8 255 -17 1063 -92 2208 159 3121 683 397 227
695 454 1011 770 188 188 301 319 444 515 691 950 906 2104 595 3202 -273 966
-951 1825 -1907 2415 -681 421 -1463 678 -2323 763 -185 19 -714 27 -890 14z
m840 -419 c660 -66 1260 -235 1803 -507 1079 -540 1852 -1443 2107 -2463 76
-304 94 -461 94 -795 0 -334 -18 -490 -94 -795 -286 -1147 -1224 -2137 -2499
-2639 -790 -311 -1653 -417 -2524 -311 -167 20 -210 15 -267 -28 -19 -15 -358
-289 -753 -609 -394 -321 -719 -583 -722 -583 -3 0 -5 377 -5 838 0 793 -1
839 -19 877 -24 51 -58 82 -151 135 -219 126 -485 317 -690 497 -832 730
-1279 1705 -1247 2718 16 481 120 896 337 1340 381 781 1059 1429 1935 1851
534 257 1159 426 1750 474 72 5 150 12 175 14 85 7 665 -3 770 -14z"
              />
              <path
                d="M4853 8631 c-41 -10 -57 -21 -181 -120 -455 -362 -952 -857 -1293
-1286 -530 -666 -821 -1262 -915 -1870 -20 -134 -23 -447 -5 -585 83 -647 404
-1230 894 -1626 631 -509 1447 -674 2232 -453 386 109 757 329 1050 623 602
604 850 1458 659 2266 -129 547 -437 1114 -931 1717 -321 392 -761 829 -1188
1178 -71 59 -148 117 -170 130 -46 27 -106 37 -152 26z m238 -624 c602 -519
1109 -1099 1428 -1632 327 -546 466 -1043 422 -1505 -70 -722 -501 -1347
-1147 -1661 -843 -411 -1853 -195 -2462 526 -392 465 -561 1108 -442 1683 116
564 460 1168 1030 1812 216 244 613 629 879 854 103 87 103 87 125 68 11 -10
87 -75 167 -145z"
              />
            </g>
          </svg>
        </span>
      );
    case "respiration":
      return (
        <span style={style} className="">
          <svg
            onClick={onClick}
            width="50"
            height="42"
            viewBox="0 0 452.000000 452.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,452.000000) scale(0.100000,-0.100000)"
              fill={style.color || "#000000"}
              stroke="none"
            >
              <path
                d="M1200 4145 c-343 -50 -616 -184 -819 -401 -172 -184 -285 -415 -348
-713 -26 -119 -27 -142 -28 -396 0 -243 2 -281 23 -383 38 -187 89 -308 231
-546 159 -268 191 -435 191 -1008 l0 -289 25 -24 c35 -36 102 -35 136 1 l24
26 -1 371 c0 460 -18 600 -104 814 -33 82 -45 105 -170 320 -89 152 -131 269
-154 424 -21 138 -21 420 -1 554 86 575 404 935 923 1045 196 42 543 38 767
-9 275 -58 506 -191 652 -375 154 -195 232 -435 249 -759 l7 -128 224 -281
c123 -154 223 -287 221 -295 -2 -9 -76 -60 -166 -113 -90 -53 -169 -106 -177
-117 -11 -15 -14 -87 -17 -334 -3 -297 -5 -317 -26 -369 -50 -125 -143 -205
-273 -234 -121 -28 -263 -18 -617 45 -270 48 -264 56 -260 -306 3 -253 3 -254
27 -279 34 -36 101 -37 136 -1 25 24 25 26 25 216 l0 191 23 -5 c117 -29 416
-67 525 -67 247 0 465 115 560 297 62 118 72 184 72 477 l0 261 127 75 c70 41
140 85 155 99 63 52 93 167 66 246 -9 24 -97 144 -218 295 -112 140 -207 260
-212 267 -5 6 -14 71 -19 144 -52 735 -471 1171 -1214 1264 -143 18 -444 18
-565 0z"
              />
              <path
                d="M4047 2530 c-137 -23 -257 -113 -257 -193 0 -18 11 -39 29 -58 40
-40 93 -40 141 0 104 85 213 86 303 2 50 -46 70 -96 65 -167 -5 -85 -41 -137
-123 -179 l-59 -30 -339 -5 -339 -5 -19 -24 c-21 -26 -25 -81 -8 -113 23 -43
60 -48 366 -48 158 0 317 5 353 11 142 22 264 108 323 228 30 62 32 72 32 176
0 102 -2 115 -31 175 -58 122 -172 208 -305 230 -35 5 -68 9 -74 9 -5 -1 -32
-5 -58 -9z"
              />
              <path
                d="M3559 1527 c-44 -34 -51 -102 -15 -144 l24 -28 164 -6 c89 -3 175
-10 190 -17 46 -19 106 -81 124 -127 35 -94 -6 -210 -91 -261 -85 -49 -174
-42 -255 22 -53 42 -81 50 -121 34 -39 -17 -59 -46 -59 -89 0 -49 84 -130 166
-160 208 -78 426 4 521 196 32 65 37 86 41 163 7 146 -47 263 -161 346 -93 69
-158 84 -351 84 -111 -1 -166 -4 -177 -13z"
              />
            </g>
          </svg>
        </span>
      );
    case "fitbit-icon":
      return (
        <span style={style} className="">
          <svg
            onClick={onClick}
            width="30"
            height="30"
            viewBox="0 0 64 64"
            preserveAspectRatio="xMidYMid meet"
          >
            <g fill={style.color || "#666666"}>
              <path d="M35.46 4.865c0 2.595-2.162 4.757-4.757 4.757s-4.757-2.162-4.757-4.757S28.108.108 30.702.108 35.46 2.27 35.46 4.865zm-4.757 8.65a4.89 4.89 0 0 0-4.973 4.973c0 2.81 2.162 4.973 4.973 4.973s4.973-2.162 4.973-4.973-2.162-4.973-4.973-4.973zm0 13.405c-2.8 0-5.2 2.378-5.2 5.2s2.378 5.2 5.2 5.2 5.2-2.378 5.2-5.2-2.378-5.2-5.2-5.2zm0 13.622a4.89 4.89 0 0 0-4.973 4.973c0 2.81 2.162 4.973 4.973 4.973s4.973-2.162 4.973-4.973c0-2.595-2.162-4.973-4.973-4.973zm0 13.838c-2.595 0-4.757 2.162-4.757 4.757s2.162 4.757 4.757 4.757 4.757-2.162 4.757-4.757-2.162-4.757-4.757-4.757zm13.2-41.513c-3.027 0-5.622 2.595-5.622 5.622s2.595 5.622 5.622 5.622 5.622-2.595 5.622-5.622-2.595-5.622-5.622-5.622zm0 13.405c-3.243 0-5.838 2.595-5.838 5.838s2.595 5.838 5.838 5.838 5.838-2.595 5.838-5.838-2.595-5.838-5.838-5.838zm0 13.622c-3.027 0-5.622 2.595-5.622 5.622s2.595 5.622 5.622 5.622 5.622-2.595 5.622-5.622-2.595-5.622-5.622-5.622zm13.622-14.27c-3.46 0-6.486 2.8-6.486 6.486 0 3.46 2.8 6.486 6.486 6.486S64 35.784 64 32.108c-.216-3.676-3.027-6.486-6.486-6.486zM17.3 14.164a4.34 4.34 0 0 0-4.324 4.324 4.34 4.34 0 0 0 4.324 4.324 4.34 4.34 0 0 0 4.324-4.324 4.34 4.34 0 0 0-4.324-4.324zm0 13.405c-2.595 0-4.54 1.946-4.54 4.54s1.946 4.54 4.54 4.54 4.54-1.946 4.54-4.54-1.946-4.54-4.54-4.54zm0 13.622a4.34 4.34 0 0 0-4.324 4.324 4.34 4.34 0 0 0 4.324 4.324 4.34 4.34 0 0 0 4.324-4.324 4.34 4.34 0 0 0-4.324-4.324zM3.892 28.216C1.73 28.216 0 29.946 0 32.108S1.73 36 3.892 36s3.892-1.73 3.892-3.892-1.73-3.892-3.892-3.892z" />
            </g>
          </svg>
        </span>
      );
    case "thermometer":
      return (
        <span style={style} className="">
          <svg
            onClick={onClick}
            width="45"
            height="40"
            viewBox="0 0 505.394 505.395"
            preserveAspectRatio="xMidYMid meet"
          >
            <path
              fill={style.color || "#000000"}
              d="M267.681,349.456l-6.47-2.092V105.862c0-5.225-4.77-9.448-10.66-9.448s-10.651,4.217-10.651,9.448v242.484l-5.925,2.482    c-18.22,7.589-29.991,25.238-29.991,44.957c0,26.87,21.849,48.717,48.71,48.717c26.862,0,48.713-21.841,48.713-48.717    C301.408,374.601,287.849,355.982,267.681,349.456z"
            />
            <path
              fill={style.color || "#000000"}
              d="M307.496,300.846v-243C307.496,25.948,281.547,0,249.65,0c-31.897,0-57.846,25.948-57.846,57.846v246.783    c-30.136,20.073-48.71,53.989-48.71,91.156c0,60.443,49.171,109.609,109.6,109.609c60.434,0,109.606-49.166,109.606-109.609    C362.294,355.935,340.986,320.115,307.496,300.846z M252.694,474.953c-43.713,0-79.157-35.446-79.157-79.168    c0-32.911,20.114-61.141,48.713-73.069V57.846c0-15.12,12.262-27.399,27.399-27.399c15.134,0,27.399,12.28,27.399,27.399v98.949    h-8.375v12.188h8.375v31.209h-8.375v12.176h8.375v34.247h-8.375v12.176h8.375v61.697c31.788,10.29,54.799,40.086,54.799,75.297    C331.848,439.501,296.414,474.953,252.694,474.953z"
            />
          </svg>
        </span>
      );
    case "Alexa-icon":
      return (
        <span style={style} className="">
          <svg
            onClick={onClick}
            width="30"
            height="30"
            viewBox="-25.98705 -43.33325 225.2211 259.9995"
            fill="#5FCAF4"
            fillRule="evenodd"
            preserveAspectRatio="xMidYMid meet"
          >
            <g fill={style.color || "#5FCAF4"}>
              <path
                d="M86.623 0C38.783 0 0 38.803 0 86.667c0 43.947 32.694 80.248 75.074 85.898v-17.463c0-4.925-3.104-9.35-7.77-10.917-24.452-8.218-41.945-31.601-41.3-58.98.784-33.332 27.922-59.538 61.244-59.202 33.2.336 60.012 27.367 60.012 60.664 0 .759-.019 1.514-.047 2.265l-.018.507a63.499 63.499 0 01-.13
              2.086c-.016.22-.038.44-.058.66a56.912 56.912 0 01-.355 3.067c-.033.246-.067.493-.104.738-7.424 48.927-71.118 76.426-71.467 76.577 3.777.502 7.629.766 11.542.766 47.84 0 86.624-38.801 86.624-86.666C173.247 38.803 134.464 0 86.623 0"
              />
            </g>
          </svg>
        </span>
      );
    default:
      throw Error(`Unhandled custom icon '${name}'`);
  }
};

export default CustomIcon;
