import React from 'react'
import Activity from '../cards/Activity'

interface ActivityTabProps
{
  isDeveloper: boolean;
  isSupport: boolean;
  householdId: string;
  activeV2Household: any;
  useNewLook: boolean;
  hasZones: boolean;
}

export const ActivityTab = ({ isDeveloper, isSupport, householdId, activeV2Household, useNewLook, hasZones }: ActivityTabProps) =>
{
  return(
    <div>
      <Activity
        hasZones={hasZones}
        id="activity"
        aria-label="activity"
        isDeveloper={isDeveloper}
        isSupport={isSupport}
        householdId={householdId}
        household={activeV2Household.data}
        householdV2={activeV2Household}
        useNewLook={useNewLook}
      />
    </div>
  )
}