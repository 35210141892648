export default theme => ({
  SettingsHeader: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 16,
  },
  SettingsTitle: {
    flexGrow: 1,
  },
  label: {
    paddingTop: '1rem',
    paddingRight: '1rem,',
    textAlign: 'center',
  },
  modified: {
    paddingTop: '1rem',
    display: 'inline-flex',
    alignItems: 'center',
    height: '100%',
  },
  textInput: {
    width: '95%',
  },
  photoUploader: {
    width: '95%',
  },

})
