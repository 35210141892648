import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import InteliIcon from '../../../../../../components/InteliIcon'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { colors } from '../../../../../../redux'

import moment from 'moment-timezone'
import { connect } from 'react-redux'
import { Tooltip } from '@material-ui/core'

const colorForStatusValue = colors.colorForStatusValue
const NO_DATA = "No Data"

class MetricItem extends Component {

  state = {
    tooltipIsActive: false,
  }

  // Helper to format the metric type
  // If the metric is -1 then there is no metric data
  // If the value is type array then attempt to join the blood pressure data into a nice format
  // Otherwise just return the value with units
  formatMetric(valueType, metricValue, doubleMetric)
  {
    try
    {
      if(metricValue === -1) return NO_DATA
      if (valueType === 'mmHg' || doubleMetric) {
        // Blood Pressure, Weight and Heart rate have 2 values
        if (metricValue[0] === -1 && metricValue[1] === -1) return NO_DATA
        let value1 = metricValue[0] === -1 ? '-' : metricValue[0]
        const value2 = metricValue[1] === -1 ? '-' : metricValue[1]
        if (doubleMetric) {
          value1 = String(value1) + " " + valueType[0]
          valueType = valueType[1]
        }
        const values = [value1, value2]
        return `${values.join('/')} ${valueType}`
      }
      return `${metricValue} ${valueType}`
    }
    catch(error)
    {
      return metricValue
    }
  }

  render() {
    const { friendlyName, metricValue, icon, timestamp, family, valueType, status, message, metricType, doubleMetric } = this.props.data
    return (
      <Grid item style={{ flex: 1 }}>
        <div style={styles.container}>
          <Typography>{friendlyName === "Average Heart Rate" ? "Heart Rate" : friendlyName}</Typography>
          <Tooltip title={<p style={{ alignContent: "center", textAlign: "center", whiteSpace: 'pre-line' }}>
            {Array.isArray(message) ? message.join('\n') : message}</p>}>
            <IconButton
              onClick={() => this.props.onItemClick(this.props.data)}>
              <InteliIcon
                style={{
                  paddingBottom: friendlyName === "Activity" || friendlyName === "Average Heart Rate" ? 10 : 0,
                }}
                size={50}
                name={icon}
                family={family}
                color={colorForStatusValue(status)}>
              </InteliIcon>
            </IconButton>
          </Tooltip>

          <div>
            <IconButton onClick={() => this.props.onItemClick(this.props.data)}>
              <AddCircleIcon color="textSecondary" />
            </IconButton>
          </div>

          {this.formatMetric(valueType, metricValue, doubleMetric) !== NO_DATA ? <div>
            {metricType === "HEART_RATE" &&
              <Typography color="textSecondary" variant="caption">Average/Resting</Typography>
            }
            {metricType === "WEIGHT" &&
              <Typography color="textSecondary" variant="caption">Weight/BMI</Typography>
            }
            <Typography color="textPrimary" variant="body2">{this.formatMetric(valueType, metricValue, doubleMetric)}</Typography>
            <Typography color="textSecondary" variant="caption">{timestamp && moment(timestamp).fromNow()}</Typography>
          </div>:
            <Typography color="textPrimary" variant="body2">---</Typography>
          }

        </div>
      </Grid>
    )
  }
}

MetricItem.propTypes = {
  data: PropTypes.object,
  onItemClick: PropTypes.func,
  showMetricThresholdsModal: PropTypes.func,
  householdV2: PropTypes.object,
  temperatureUnits: PropTypes.string,
}

const styles = {
  container: {
    textAlign: "center",
  },
  icon: {
    padding: 6,
  },
}
const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(MetricItem)