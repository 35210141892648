import React from 'react'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux'

// Shared Store
import sharedStore from '../../../../../redux'


// Dialog components
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'


// Icons
import BatteryReportIcon from '@mui/icons-material/BatteryFull'

// Chart
import { BatteryGraph2 } from '../../../../../components/Charts/BatteryGraph2'

// Helper to format the graph data
import { formatBatteryReport2 } from './helper/formatBatteryReport2'

const { callAPI } = sharedStore.userStore

const { GATEWAY_BATTERY_REPORT } = sharedStore.apiEndpoints

const BatteryReportModal = ({ open, handleClose, getReport, batteryReportData, activeGatewayId, clearGraph, isFetching }) => {

  BatteryReportModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    getReport: PropTypes.func,
    batteryReportData: PropTypes.array,
    activeGatewayId: PropTypes.string,
    clearGraph: PropTypes.func,
    isFetching: PropTypes.bool,
  }

  return (
    <Dialog
      onEntering={() => getReport({ gatewayId: `gatewayId=${activeGatewayId}&limit=365` })}
      onExit={() => clearGraph()}
      maxWidth="xl"
      fullWidth
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="sensor-battery-report"
      aria-describedby="sensor-battery-report-line-chart"
    >
      <DialogTitle id="alert-dialog-slide-title">{"Battery Report"}</DialogTitle>
      <DialogContent>

        {isFetching && <Grid justify="center" style={{ padding: 100 }}>
          <CircularProgress />
        </Grid>}

        {!isFetching && batteryReportData && batteryReportData.length > 0 ? <BatteryGraph2 formattedData={formatBatteryReport2(batteryReportData)} /> :
          <div style={{ padding: 20, textAlign: "center" }}>
            <BatteryReportIcon color="secondary" style={{ textAlign: "center" }} />
            <DialogContentText >
              No Sensor Data Found
            </DialogContentText>
          </div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    getReport: (params) => dispatch(callAPI(GATEWAY_BATTERY_REPORT(params))),
    clearGraph: () => dispatch({ type: "CLEAR_BATTERY_REPORT" }),
  }
}

const mapStateToProps = state => {
  return {
    batteryReportData: state.graphs.graphs.batteryReportData,
    activeGatewayId: state.householdsV2.activeGatewayId,
    isFetching: state.graphs.isFetching,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BatteryReportModal)