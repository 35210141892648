import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import moment from 'moment'

const TIME_SCALE_VALUES = {
  LAST_HOUR: "Last Hour",
  LAST_6_HOURS: "Last 6 Hours",
  LAST_24_HOURS: "Last 24 Hours",
  LAST_7_DAYS: "Last 7 Days",
  LAST_30_DAYS: "Last 30 Days",
  PAST_6_MONTHS: "6 Months",
  MOMENT_FORMAT: "YYYY-MM-DD hh:mm:ss A",
}

const TimeScaleChooser = ({ onItemClick, selectedValue = TIME_SCALE_VALUES.LAST_24_HOURS }) => {

  TimeScaleChooser.propTypes = {
    onItemClick: PropTypes.func,
    selectedValue: PropTypes.string,
    handleItemSelect: PropTypes.func,
  }

  return (
    <ButtonGroup variant="outlined" size="small" color="primary">
      <Button
        onClick={(e) => onItemClick(moment.utc().subtract().subtract(1, "hour").format(TIME_SCALE_VALUES.MOMENT_FORMAT), "hh:mm:ss A", TIME_SCALE_VALUES.LAST_HOUR)}
        variant={selectedValue === TIME_SCALE_VALUES.LAST_HOUR ? "contained" : "outlined"}>{TIME_SCALE_VALUES.LAST_HOUR}</Button>
      <Button
        onClick={(e) => onItemClick(moment.utc().subtract().subtract(6, "hour").format(TIME_SCALE_VALUES.MOMENT_FORMAT), "hh:mm A", TIME_SCALE_VALUES.LAST_6_HOURS)}
        variant={selectedValue === TIME_SCALE_VALUES.LAST_6_HOURS ? "contained" : "outlined"}>{TIME_SCALE_VALUES.LAST_6_HOURS}</Button>
      <Button
        onClick={(e) => onItemClick(moment.utc().subtract(24, "hours").format(TIME_SCALE_VALUES.MOMENT_FORMAT), "hh:mm A", TIME_SCALE_VALUES.LAST_24_HOURS)}
        variant={selectedValue === TIME_SCALE_VALUES.LAST_24_HOURS ? "contained" : "outlined"}>{TIME_SCALE_VALUES.LAST_24_HOURS}</Button>
      <Button
        onClick={(e) => onItemClick(moment.utc().subtract(7, "days").format(TIME_SCALE_VALUES.MOMENT_FORMAT), "dddd hh:mm A", TIME_SCALE_VALUES.LAST_7_DAYS)}
        variant={selectedValue === TIME_SCALE_VALUES.LAST_7_DAYS ? "contained" : "outlined"}>{TIME_SCALE_VALUES.LAST_7_DAYS}</Button>
      <Button
        onClick={(e) => onItemClick(moment.utc().subtract(30, "days").format(TIME_SCALE_VALUES.MOMENT_FORMAT), "Do hh:mm A", TIME_SCALE_VALUES.LAST_30_DAYS)}
        variant={selectedValue === TIME_SCALE_VALUES.LAST_30_DAYS ? "contained" : "outlined"}>{TIME_SCALE_VALUES.LAST_30_DAYS}</Button>
      <Button
        onClick={(e) => onItemClick(moment.utc().subtract(180, "days").format(TIME_SCALE_VALUES.MOMENT_FORMAT), "DD/MM", TIME_SCALE_VALUES.PAST_6_MONTHS)}
        variant={selectedValue === TIME_SCALE_VALUES.PAST_6_MONTHS ? "contained" : "outlined"}>{TIME_SCALE_VALUES.PAST_6_MONTHS}</Button>
    </ButtonGroup>)
}

export default TimeScaleChooser