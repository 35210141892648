/* eslint-disable no-case-declarations */
import moment from 'moment'
import { setStatusAndMessage } from './helpers/metricStatus'

export const GET_HEALTH_METRICS_BEGIN = "GET_HEALTH_METRICS_BEGIN"
export const GET_HEALTH_METRICS_SUCCESS = "GET_HEALTH_METRICS_SUCCESS"
export const GET_HEALTH_METRICS_ERROR = "GET_HEALTH_METRICS_ERROR"
export const CLEAR_GET_HEALTH_METRICS = "CLEAR_GET_HEALTH_METRICS"

export const GET_HEALTH_METRICS_TYPES_BEGIN = "GET_HEALTH_METRICS_TYPES_BEGIN"
export const GET_HEALTH_METRICS_TYPES_SUCCESS= "GET_HEALTH_METRICS_TYPES_SUCCESS"
export const GET_HEALTH_METRICS_TYPES_ERROR = "GET_HEALTH_METRICS_TYPES_ERROR"

export const GET_HEALTH_METRICS_CUSTOM_BEGIN = "GET_HEALTH_METRICS_CUSTOM_BEGIN"
export const GET_HEALTH_METRICS_CUSTOM_SUCCESS= "GET_HEALTH_METRICS_CUSTOM_SUCCESS"
export const GET_HEALTH_METRICS_CUSTOM_ERROR = "GET_HEALTH_METRICS_CUSTOM_ERROR"
export const CLEAR_GET_HEALTH_METRICS_CUSTOM = "CLEAR_GET_HEALTH_METRICS_CUSTOM"


export const ADD_HEALTH_METRIC_BEGIN = "ADD_HEALTH_METRIC_BEGIN"
export const ADD_HEALTH_METRIC_SUCCESS = "ADD_HEALTH_METRIC_SUCCESS"
export const ADD_HEALTH_METRIC_ERROR = "ADD_HEALTH_METRIC_ERROR"
export const CLEAR_ADD_HEALTH_METRIC = "CLEAR_ADD_HEALTH_METRIC"

export const GET_HEALTH_SUMMARY_BEGIN = "GET_HEALTH_SUMMARY_BEGIN"
export const GET_HEALTH_SUMMARY_SUCCESS = "GET_HEALTH_SUMMARY_SUCCESS"
export const GET_HEALTH_SUMMARY_ERROR = "GET_HEALTH_SUMMARY_ERROR"
export const UPDATE_HEALTH_SUMMARY = "UPDATE_HEALTH_SUMMARY"
export const CLEAR_GET_HEALTH_SUMMARY= "CLEAR_GET_HEALTH_SUMMARY"

export const SYNC_FITBIT_DATA_BEGIN = "SYNC_FITBIT_DATA_BEGIN"
export const SYNC_FITBIT_DATA_SUCCESS = "SYNC_FITBIT_DATA_SUCCESS"
export const SYNC_FITBIT_DATA_ERROR = "SYNC_FITBIT_DATA_ERROR"
export const CLEAR_SYNC_FITBIT_DATA = "CLEAR_SYNC_FITBIT_DATA"

export const FITBIT_INITIAL_SYNC_BEGIN = "FITBIT_INITIAL_SYNC_BEGIN"
export const FITBIT_INITIAL_SYNC_SUCCESS = "FITBIT_INITIAL_SYNC_SUCCESS"
export const FITBIT_INITIAL_SYNC_ERROR = "FITBIT_INITIAL_SYNC_ERROR"
export const CLEAR_FITBIT_INITIAL_SYNC = "CLEAR_FITBIT_INITIAL_SYNC"

export const GET_HEALTH_METRICS_DEVICES_BEGIN = "GET_HEALTH_METRICS_DEVICES_BEGIN"
export const GET_HEALTH_METRICS_DEVICES_SUCCESS = "GET_HEALTH_METRICS_DEVICES_SUCCESS"
export const GET_HEALTH_METRICS_DEVICES_ERROR= "GET_HEALTH_METRICS_DEVICES_ERROR"
export const CLEAR_GET_HEALTH_METRICS_DEVICES = "CLEAR_GET_HEALTH_METRICS_DEVICES"

export const GET_ADD_DEVICE_URL_BEGIN = "GET_ADD_DEVICE_URL_BEGIN"
export const GET_ADD_DEVICE_URL_SUCCESS = "GET_ADD_DEVICE_URL_SUCCESS"
export const GET_ADD_DEVICE_URL_ERROR= "GET_ADD_DEVICE_URL_ERROR"
export const CLEAR_GET_ADD_DEVICE_URL = "CLEAR_GET_ADD_DEVICE_URL"

export const SYNC_PERSONA_DATA_BEGIN = "SYNC_PERSONA_DATA_BEGIN"
export const SYNC_PERSONA_DATA_SUCCESS = "SYNC_PERSONA_DATA_SUCCESS"
export const SYNC_PERSONA_DATA_ERROR = "SYNC_PERSONA_DATA_ERROR"
export const CLEAR_SYNC_PERSONA_DATA = "CLEAR_SYNC_PERSONA_DATA"


const initialState = {
  addError: { message: "" },
}

const reducer = (state = initialState, action) =>
{

  switch(action.type)
  {
    case ADD_HEALTH_METRIC_BEGIN:
      return {
        ...state,
        isAddingHealthMetric: true,
      }

    case ADD_HEALTH_METRIC_SUCCESS:
      return {
        ...state,
        isAddingHealthMetric: false,
        addResult: action.data,
        addError: { message: "" },
      }

    case ADD_HEALTH_METRIC_ERROR:
      return {
        ...state,
        isAddingHealthMetric: false,
        addError: { message: action.error.message },
        addResult: "",
      }

    case CLEAR_ADD_HEALTH_METRIC:
      return {
        ...state,
        isAddingHealthMetric: false,
        addError: { message: "" },
        addResult: "",
      }

    case GET_HEALTH_METRICS_BEGIN:
      return {
        ...state,
        isGettingGraphs: true,
      }

    case GET_HEALTH_METRICS_TYPES_BEGIN:
      return {
        ...state,
        isGettingTypes: true,
        healthMetricTypes: [],
      }

    case GET_HEALTH_METRICS_TYPES_SUCCESS:
      return {
        ...state,
        isGettingTypes: false,
        healthMetricTypes: [ ...action.data, "Select a metric"],
      }

    case GET_HEALTH_METRICS_TYPES_ERROR:
      return {
        ...state,
        isGettingTypes: false,
        healthMetricTypes: [],
      }

    case GET_HEALTH_METRICS_CUSTOM_BEGIN:
      return {
        ...state,
        isGettingCustomTypeGraph: true,
        customGraph: [],
      }

    case GET_HEALTH_METRICS_CUSTOM_SUCCESS:
      return {
        ...state,
        isGettingCustomTypeGraph: false,
        customGraph: action.data,
      }

    case GET_HEALTH_METRICS_CUSTOM_ERROR:
      return {
        ...state,
        isGettingCustomTypeGraph: false,
        customGraph: [],
      }

    case CLEAR_GET_HEALTH_METRICS_CUSTOM:
      return {
        ...state,
        customGraph: [],
        healthMetricTypes: [],
      }

    case GET_HEALTH_METRICS_SUCCESS:
      const metricKey = typeof(action.data[0][1]) === "string" ? action.data[0][1] : action.data[0][1].label
      return {
        ...state,
        isGettingGraphs: false,
        [metricKey]: action.data, // Dynamically populate the state based on the metric type
      }

    case GET_HEALTH_METRICS_ERROR:
      return {
        ...state,
        isGettingGraphs: false,
        getHealthMetricsError: action.error,
      }

    case CLEAR_GET_HEALTH_METRICS:
      return {
        ...state,
        isGettingGraphs: false,
        graphs: {},
        getHealthMetricsError: '',
      }

    case GET_HEALTH_METRICS_DEVICES_BEGIN:
      return {
        ...state,
        isGettingDevices: true,
        getHealthMetricsDevicesError: '',
      }

    case GET_HEALTH_METRICS_DEVICES_SUCCESS:
      return {
        ...state,
        isGettingDevices: false,
        devices: action.data,
      }

    case GET_HEALTH_METRICS_DEVICES_ERROR:
      return {
        ...state,
        isGettingDevices: false,
        getHealthMetricsDevicesError: action.error,
        devices: [],
      }

    case CLEAR_GET_HEALTH_METRICS_DEVICES:
      return {
        ...state,
        isGettingDevices: false,
        getHealthMetricsDevicesError: '',
        devices: [],
      }

    case GET_ADD_DEVICE_URL_BEGIN:
      return {
        ...state,
        isGettingUrl: true,
        getDeviceUrlError: '',
      }

    case GET_ADD_DEVICE_URL_SUCCESS:
      return {
        ...state,
        isGettingUrl: false,
        addDeviceUrl: action.data,
      }

    case GET_ADD_DEVICE_URL_ERROR:
      return {
        ...state,
        isGettingUrl: false,
        getDeviceUrlError: action.error,
        addDeviceUrl: "",
      }

    case CLEAR_GET_ADD_DEVICE_URL:
      return {
        ...state,
        isGettingUrl: false,
        getDeviceUrlError: '',
        addDeviceUrl: "",
      }

    case GET_HEALTH_SUMMARY_BEGIN:
      return {
        ...state,
        isGettingSummary: true,
      }

    case GET_HEALTH_SUMMARY_SUCCESS:
      return {
        ...state,
        isGettingSummary: false,
        summary: action.data,
      }

    case GET_HEALTH_SUMMARY_ERROR:
      return {
        ...state,
        isGettingSummary: false,
        summary: [],
        summaryError: action.error,
      }

    case UPDATE_HEALTH_SUMMARY:
      return {
        ...state,
        summary: action.data.summary.map(summaryItem => {
          if(summaryItem.metricType === action.data.updatedMetric.metricType)
          {
            action.data.updatedMetric.timestamp = moment.utc(action.data.updatedMetric.timestamp).local()
            return setStatusAndMessage(action.data.updatedMetric)
          }
          return setStatusAndMessage(summaryItem)
        }),
      }

    case CLEAR_GET_HEALTH_SUMMARY:
      return {
        ...state,
        isGettingSummary: false,
        summary: [],
        summaryError: "",
      }

    case FITBIT_INITIAL_SYNC_BEGIN:
      return {
        ...state,
        isSyncingInitialFitbit: true,
      }

    case FITBIT_INITIAL_SYNC_SUCCESS:
      return {
        ...state,
        isSyncingInitialFitbit: false,
        syncFitbitResult: action.data,
        syncFitbitError: { message: "" },
      }

    case FITBIT_INITIAL_SYNC_ERROR:
      return {
        ...state,
        isSyncingInitialFitbit: false,
        syncFitbitResult: "",
        syncFitbitError: { message: action.error.message },
      }

    case SYNC_FITBIT_DATA_BEGIN:
      return {
        ...state,
        isSyncingFitbitData: true,
      }

    case SYNC_FITBIT_DATA_SUCCESS:
      return {
        ...state,
        isSyncingFitbitData: false,
        syncFitbitResult: action.data,
        syncFitbitError: { message: "" },
      }

    case SYNC_FITBIT_DATA_ERROR:
      return {
        ...state,
        isSyncingFitbitData: false,
        syncFitbitResult: "",
        syncFitbitError: { message: action.error.message },
      }

    case CLEAR_SYNC_FITBIT_DATA:
      return {
        ...state,
        isSyncingInitialFitbit: false,
        isSyncingFitbitData: false,
        syncFitbitResult: "",
        syncFitbitError: { message: "" },
      }

    case SYNC_PERSONA_DATA_BEGIN:
      return {
        ...state,
        isSyncingPersonaData: true,
      }

    case SYNC_PERSONA_DATA_SUCCESS:
      return {
        ...state,
        isSyncingPersonaData: false,
        syncPersonaResult: action.data,
        syncPersonaError: { message: "" },
      }

    case SYNC_PERSONA_DATA_ERROR:
      return {
        ...state,
        isSyncingPersonaData: false,
        syncPersonaResult: "",
        syncPersonaError: { message: action.error.message },
      }

    case CLEAR_SYNC_PERSONA_DATA:
      return {
        ...state,
        isSyncingPersonaData: false,
        syncPersonaResult: "",
        syncPersonaError: { message: "" },
      }

    default:
      return state
  }
}

export default reducer