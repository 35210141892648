import React from 'react'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import { ChartTypes } from './AllMetrics'

interface GraphTypesProps
{
  onItemClick: (item: ChartTypes) => void;
  selectedItem: ChartTypes;
}

export const GraphTypes = ({ onItemClick, selectedItem }: GraphTypesProps) =>
{
  return(
    <div>
      <Tooltip title="Bar Chart"><IconButton onClick={() => onItemClick("bar")}><Icon color={selectedItem === 'bar' ? 'secondary' : "inherit"}>assessment</Icon></IconButton></Tooltip>
      <Tooltip title="Area Chart"><IconButton onClick={() => onItemClick("area")}><Icon color={selectedItem === 'area' ? 'secondary' : "inherit"}>area_chart</Icon></IconButton></Tooltip>
      <Tooltip title="Line Chart"><IconButton onClick={() => onItemClick("line")}><Icon color={selectedItem === 'line' ? 'secondary' : "inherit"}>show_chart</Icon></IconButton></Tooltip>
      <Tooltip title="Radial Bar Chart"><IconButton onClick={() => onItemClick("radialBar")}><Icon color={selectedItem === 'radialBar' ? 'secondary' : "inherit"}>donut_small</Icon></IconButton></Tooltip>
    </div>
  )
}