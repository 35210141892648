import React from 'react'
import Alert from '@material-ui/lab/Alert'

interface IDeleteResultItem
{
  title: string;
  result: string;
  severity: "error" | "success"
}

export const DeleteResultItem = ({ title, result, severity }: IDeleteResultItem) =>
{
  return(
    <div style={styles.container}>
      <Alert variant="filled" severity={severity}>
        {title + " - " + result}
      </Alert>
    </div>
  )
}

const styles = {
  container: {
    margin: 12,
  },
}