import { connect } from 'react-redux'

import HouseholdHealthTab from './SystemHealthTab'
import redux  from '../../../../../redux'
const { apiEndpoints, userStore } = redux
const { callAPI } = userStore
const { GET_HOUSEHOLD_HEALTH_ROLLUP } = apiEndpoints;

const mapStateToProps = ({ system }: any) =>
{
  return{
    householdsHealthRollup: system.householdsHealthRollup,
    householdsHealthRollupError: system.householdsHealthRollup,
    isGettingHouseholdsHealth: system.isGettingHouseholdsHealth,
  }
}

const mapDispatchToProps = (dispatch: any) =>
{
  return{
    getRollup: () => dispatch(callAPI(GET_HOUSEHOLD_HEALTH_ROLLUP())),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HouseholdHealthTab)