export class Pendant
{
  deviceid: string;
  id: string;
  type: string;
  friendlyName: string;
  guivisible: boolean;
  smsNumber: string;
  passcode: string;
  manufacturer: string;
  product: string;
  constructor(id: string, friendlyName: string, smsNumber: string, product: string){


    this.deviceid = `pendant_${smsNumber}`;
    this.id = id;
    this.type = SensorType.pendant;
    this.friendlyName = friendlyName;
    this.smsNumber = smsNumber;
    this.guivisible = true;
    this.passcode = "";
    this.manufacturer = "Generic";
    this.product = product;
  }
}

enum SensorType {
  pendant = "pendant"
}