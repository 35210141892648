import React, { useState } from 'react'

// Material UI components
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import Typography from '@mui/material/Typography';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip'
import Avatar from '@material-ui/core/Avatar';

// MUI icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButtom from '@material-ui/core/IconButton';

// Types
import { ZoneApiResponseItem } from '@intelicare/shared/interfaces/household/Zone'

// Custom components
import { RoomItem } from './RoomItem';

// Modals
import { EditModal } from './modals/EditModal';
import { AddRoomModal } from './modals/AddRoomModal';
import { ConfirmDelete } from '../../../ConfirmDelete';

// Redux
import { useDispatch } from 'react-redux';
import { addRoomToZone, removeZoneBegin, updateZoneBegin } from '../../../../redux/domains/zones/actionCreators';
import { getInitials } from '../../../../routes/Organisations/component/Modals/utils/getInitials';
import { purple } from '@mui/material/colors';
interface ZoneItemProps
{
  zone: ZoneApiResponseItem;
  householdId: string;
  roomsWithoutZones?: string[];
  expanded: boolean;
  onChange: (e: any, expanded: boolean) => void;
}

export const ZoneItem = ({ zone, householdId, roomsWithoutZones, expanded, onChange }: ZoneItemProps) =>
{
  const [ openEditModal, setOpenEditModal ] = useState(false)
  const [ openAddRoomModal, setOpenAddRoomModal ] = useState(false)
  const [ openConfirmRemoveZoneModal, setOpenRemoveZoneModal ] = useState(false)
  const [ roomExpanded, setRoomExpanded ] = useState<string | boolean>(false)


  const dispatch = useDispatch()

  const renameZone = (friendlyName: string) =>
  {
    setOpenEditModal(false)
    dispatch(updateZoneBegin(householdId, { ...zone, friendlyName: friendlyName }))
  }

  const removeZone = () =>
  {
    setOpenRemoveZoneModal(false)
    dispatch(removeZoneBegin(householdId, zone.zoneId))
  }

  const addRoom = (roomname: string) =>
  {
    setOpenAddRoomModal(false)
    // Will not use roomid or sensors array on backend
    const room = { roomname: roomname, roomid: 0, zoneId: zone.zoneId, sensors: [] }
    dispatch(addRoomToZone(householdId, zone.zoneId, room))
  }

  return(
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header" >
        <div style={styles.rowGroup}>
          <Avatar style={styles.avatar} >{getInitials(zone.friendlyName)}</Avatar>
          <Typography style={styles.headingText}>{zone.friendlyName}</Typography>
        </div>

      </AccordionSummary>

      <AccordionDetails>

        {zone && zone.rooms && zone.rooms.map((room, i) => <RoomItem expanded={roomExpanded} onChange={(e, isExpanded) => setRoomExpanded(isExpanded ? room.roomname : false)} householdId={householdId} zone={zone} room={room} key={i} />)}

      </AccordionDetails>


      <AccordionActions>
        <Typography justifySelf="flex-end" variant="caption" color="textSecondary">Zone Actions</Typography>
        <Tooltip title="Add a new room"><IconButtom onClick={() => setOpenAddRoomModal(true)}><Icon>add</Icon></IconButtom></Tooltip>
        <Tooltip title="Edit this zone"><IconButtom onClick={() => setOpenEditModal(true)}><Icon>edit</Icon></IconButtom></Tooltip>
        <Tooltip title="Remove this zone"><IconButtom onClick={() => setOpenRemoveZoneModal(true)}><Icon>delete</Icon></IconButtom></Tooltip>
      </AccordionActions>

      {/* EDIT ZONE MODAL */}
      <EditModal
        confirmAction={(friendlyName) => renameZone(friendlyName)}
        open={openEditModal}
        close={() => setOpenEditModal(false)}
        title="Rename zone"
        inputBoxDescription="Zone name"
        inputData={zone.friendlyName}
        contentText={`Change the name of this zone`}
      />

      {/* ADD ROOM MODAL */}
      <AddRoomModal
        dropDownItems={roomsWithoutZones}
        confirmAction={(roomname) => addRoom(roomname)}
        open={openAddRoomModal}
        close={() => setOpenAddRoomModal(false)}
        title="Add room"
        inputBoxDescription="Room name"
        contentText={`Add a new room to the zone ${zone.friendlyName}`}
      />

      {/* CONFIRM DELETE ZONE */}
      <ConfirmDelete
        onConfirmAction={removeZone}
        content={`Do you want to remove the zone ${zone.friendlyName} ?`}
        open={openConfirmRemoveZoneModal}
        handleClose={() => setOpenRemoveZoneModal(false)}
        title="Confirm remove zone"
      />

    </Accordion>
  )
}

const styles = {
  rowGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: purple[500],
  },
  icon: {
    color: 'grey',
  },
  headingText: {
    paddingTop: 2,
    paddingLeft: 6,
  },
}