import React from 'react'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'

interface ErrorProps
{
  error: string;
}

export const ErrorView = ({ error }: ErrorProps) =>
{
  return(
    <div style={styles.container}>
      <Icon fontSize="large" style={styles.icon}>sentiment_very_dissatisfied</Icon>
      <Typography color="textSecondary" variant="h3"> { error || "Looks like there was a bit of an issue" }</Typography>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as 'column',
    height: "80vh",
  },
  icon: {
    color: 'grey',
  },

}