import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import InteliIcon from '../../../../components/InteliIcon'
import { TILE_TYPES } from '../enums'

import styles from './SystemHealthStyles'

import { SENSOR_STATUS } from '@intelicare/shared/enums'
import { colors } from '../../../../redux'
const { colorForStatusValue } = colors

const TIMEOUT_DURATION = 500

const FRIENDLY_STATUS = {
  NORMAL: "Normal",
  WARNING: "Warning",
  ALERT: "Alert",
  UNKNOWN: "Unknown",
  LEARNING: "Learning",
}

class SystemHealthTileView extends Component {
  constructor(props) {
    super(props)
    this.tileId = `tile-${uuidv4()}`
  }

  timeout = null

  // Private functions
  _onTileHover = () => {
    this.timeOut = setTimeout(() => {
      const { household, getHousehold, onShowSnack } = this.props
      if ((!household.data) ||
        (!household.data.isFetching && !household.data.sensors)) getHousehold(household.householdId)

      onShowSnack(TILE_TYPES.SYSTEM_HEALTH, true, household, null, null, null, null)
    }, TIMEOUT_DURATION)
  }

  _onTileHoverOut = () => {
    clearTimeout(this.timeOut)
    const { onShowSnack } = this.props
    onShowSnack("", false, null, null, null, null, null)
  }

  render() {
    const {
      classes,
      status,
      onClick,
    } = this.props

    const statusColor = colorForStatusValue(status || SENSOR_STATUS.UNKNOWN)

    return (
      <Grid
        automation_attribute="SYSTEM_HEALTH_ROLLUP"
        id={this.tileId}
        item
        xs
        className={classes.TileContainer}
        onClick={onClick}
        onMouseOver={this._onTileHover}
        onMouseLeave={this._onTileHoverOut}>
        <InteliIcon name='clipboard-pulse' family='community' color={statusColor} size={32} />
        <div className={classes.LabelContainer}>
          <Typography gutterBottom style={{ color: statusColor }}>System Health</Typography>
          <Typography gutterBottom style={{ color: statusColor }}>{FRIENDLY_STATUS[status] || 'Unknown'}</Typography>
        </div>
      </Grid>
    )
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    status: PropTypes.string,
    household: PropTypes.object,
    onClick: PropTypes.func,
    householdId: PropTypes.string,
    getHousehold: PropTypes.func,
    onShowSnack: PropTypes.func,
  }
}

export default withStyles(styles)(SystemHealthTileView)
