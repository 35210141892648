import { useState, useEffect } from 'react'
import { FilteredIotEvents } from '@intelicare/shared/interfaces/iot/';
import { IotEventApi } from '../../../../../../../api/IotEventApi';
type HookReturnType = [boolean, FilteredIotEvents[]]

export const useIotEvents = (token: string, householdId: string, gatewayId: string, timeRange: string): HookReturnType =>
{

  const [ iotEventData, setIotEvents ] = useState<FilteredIotEvents[]>([]);
  const [ isLoading, setIsLoading ] = useState(false);
  const iotEventApi = new IotEventApi(token);

  const getIotEvents = async () =>
  {
    setIsLoading(true)
    const iotEvents = await iotEventApi.getIotEventsBySensorId(householdId, gatewayId, timeRange)
    if(iotEvents) setIotEvents(iotEvents)
    setIsLoading(false)

    return [ isLoading, iotEventData ]
  }

  useEffect(() => {
    getIotEvents()
  }, [])

  return [ isLoading, iotEventData ]

}
