import React from 'react'
import NotificationsView from '../cards/NotificationsView'

interface NotificationsTabProps
{
  useNewLook: boolean;
  clearNotificationsForHousehold: () => void;
  householdId: string;
  activeV2Household: string;
  isGettingNotificationsForHousehold: boolean;
  notifications: [];
  getNotifications: () => void;
  viewMessage: () => void;
  messageResolving: boolean;
  onSelect: () => void;
}

export const NotificationsTab = ({
  useNewLook,
  isGettingNotificationsForHousehold,
  clearNotificationsForHousehold,
  householdId,
  notifications,
  getNotifications,
  viewMessage,
  onSelect,
  messageResolving }: NotificationsTabProps) =>
{
  return(
    <div>
      <NotificationsView
        useNewLook={useNewLook}
        isGettingNotificationsForHousehold={isGettingNotificationsForHousehold}
        clearNotificationsForHousehold={clearNotificationsForHousehold}
        id="notifications"
        aria-label="notifications"
        householdId={householdId}
        notifications={notifications}
        getNotifications={getNotifications}
        onSelect={onSelect}
        messageResolving={messageResolving}
      />
    </div>
  )
}