import { useState } from 'react'
import { RoomZoneItem, ZoneApiResponseItem } from '@intelicare/shared/interfaces/household/Zone'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SensorItem } from './SensorItem'
import Icon from '@material-ui/core/Icon';
import IconButtom from '@material-ui/core/IconButton';
import AccordionActions from '@mui/material/AccordionActions';
import Tooltip from '@material-ui/core/Tooltip'
import { ConfirmDelete } from '../../../ConfirmDelete';
import { EditModal } from './modals/EditModal';
import { useDispatch } from 'react-redux'
import { addSensorToRoom, removeRoomFromZoneBegin, updateRoomName } from '../../../../redux/domains/zones/actionCreators';
import { AddSensorModal } from './modals/AddSensorModal';
import { ISensor } from '@intelicare/shared/interfaces/household/ISensor';
import { Room } from '@intelicare/shared/interfaces/gateway/Gateway';
interface RoomItemProps
{
  room: RoomZoneItem;
  zone: ZoneApiResponseItem;
  householdId: string;
  expanded: string | boolean;
  onChange: (e: any, isExpanded: boolean) => void
}

export const RoomItem = ({ room, zone, householdId, expanded, onChange }: RoomItemProps) =>
{
  const [ openConfirmRemoveRoomModal, setOpenConfirmRemoveRoomModal ] = useState(false)
  const [ openEditRoomNameModal, setOpenEditRoomNameModal ] = useState(false)
  const [ openAddSensorModal, setOpenAddSensorModal ] = useState(false)

  const dispatch = useDispatch()
  const removeRoom = () =>
  {
    setOpenConfirmRemoveRoomModal(false)
    dispatch(removeRoomFromZoneBegin(householdId, zone.zoneId, room.roomid))
  }

  const renameRoom = (room: Room) =>
  {
    setOpenEditRoomNameModal(false)
    dispatch(updateRoomName(householdId, room))
  }

  const handleAddSensor = (sensor: ISensor) =>
  {
    setOpenAddSensorModal(false)
    if(sensor.nodeId) dispatch(addSensorToRoom(householdId, sensor.nodeId, room.roomid))
  }

  return(
    <Accordion expanded={expanded === room.roomname} onChange={onChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <div style={styles.rowGroup}>
          <Icon color="secondary">sensor_door</Icon>
          <Typography style={styles.headingText}>{room.roomname}</Typography>
        </div>
      </AccordionSummary>

      <AccordionActions>
        <Typography justifySelf="flex-end" variant="caption" color="textSecondary">Room Actions</Typography>
        <Tooltip title="Add a sensor to this room"><IconButtom onClick={() => setOpenAddSensorModal(true)}><Icon>add</Icon></IconButtom></Tooltip>
        <Tooltip title="Edit this room"><IconButtom onClick={() => setOpenEditRoomNameModal(true)}><Icon>edit</Icon></IconButtom></Tooltip>
        <Tooltip title="Remove this room"><IconButtom onClick={() => setOpenConfirmRemoveRoomModal(true)}><Icon>delete</Icon></IconButtom></Tooltip>
      </AccordionActions>

      <AccordionDetails>
        {room && room.sensors && room.sensors.filter(sensor => sensor.product).map((sensor, i: number) => <SensorItem householdId={householdId} room={room} zoneId={zone.zoneId} sensor={sensor} key={i} />)}
      </AccordionDetails>

      <ConfirmDelete
        title="Confirm remove room"
        content={`Do you want to remove the room ${room.roomname} from the zone ${zone.friendlyName}?`}
        onConfirmAction={removeRoom}
        open={openConfirmRemoveRoomModal}
        handleClose={() => setOpenConfirmRemoveRoomModal(false)}
      />

      <EditModal
        confirmAction={(friendlyName) => renameRoom({ ...room, roomname: friendlyName })}
        open={openEditRoomNameModal}
        close={() => setOpenEditRoomNameModal(false)}
        title="Rename room"
        inputBoxDescription="Room name"
        inputData={room.roomname}
        contentText={`Change the name of this room`}
      />

      <AddSensorModal
        onSensorAdd={(sensor) => handleAddSensor(sensor)}
        open={openAddSensorModal}
        close={() => setOpenAddSensorModal(false)}
      />


    </Accordion>
  )
}

const styles = {
  rowGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: 'grey',
  },
  headingText: {
    paddingTop: 2,
    paddingLeft: 6,
  },
}