import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import { DOMESTIC_COLOURS } from './utils/colors'
import Cloud from '@mui/icons-material/Cloud'
import OkayIcon from '@mui/icons-material/WbSunny'
import { InsightNotEnabled } from './InsightNotEnabled'

interface ClimateCardProps
{
  climateBehaviour: any;
}

export const ClimateCard = ({ climateBehaviour }: ClimateCardProps) =>
{
  return(
    climateBehaviour.enabled ?  <Card style={styles.cardContainer}>
      <CardHeader
        titleTypographyProps={{ variant:'h6' }}
        avatar={
          <Avatar style={{ ...styles.avatar, backgroundColor: climateBehaviour.status.value === "NORMAL" ? DOMESTIC_COLOURS.NORMAL : DOMESTIC_COLOURS.WARNING }}>
            {climateBehaviour.status.msg === "Okay" ? <OkayIcon fontSize="large"/> : <Cloud fontSize="large" /> }
          </Avatar>
        }

        title="Climate"
        subheader={climateBehaviour.status.msg === "Okay" ? "All climate sensors are within normal limits" : "Looks like there might be a temperature issue"}
      />
    </Card> : <InsightNotEnabled title="Climate" />
  )
}

const styles = {
  cardContainer: {
    padding: 12,
    flex: 1,
    borderRadius: 6,
    marginBottom: 12,
  },
  avatar: {
    width: 100,
    height: 100,
  },
  icon: {
    width: 100,
    height: 100,
  },
}