import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment-timezone'
import access from 'safe-access'

import { withStyles, withTheme } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import GridListTile from '@material-ui/core/GridListTile'
import Chip from '@material-ui/core/Chip'
import RefreshIcon from '@mui/icons-material/Refresh'

import InteliIcon from "../../../components/InteliIcon"

import styles from './SensorStateStyles'

class SensorsStateView extends React.Component {

  static propTypes = {
    fetchSensorsForHousehold: PropTypes.func,
    isFetching: PropTypes.bool,
    sensors: PropTypes.array,
    householdId: PropTypes.string,
    // timezone: PropTypes.string,
    classes: PropTypes.object,
    theme: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      autoRefreshEnabled: true,
    }
  }

  enableRefresh() {
    if (!this.timer) {
      const SENSOR_UPDATE_INTERVAL_IN_MS = 60000
      this.timer = setInterval(() => this.fetchSensors(), SENSOR_UPDATE_INTERVAL_IN_MS)
    }
  }

  disableRefresh() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  }

  componentDidMount() {
    const {
      fetchSensorsForHousehold,
      householdId,
    } = this.props

    this.fetchSensors = () => {
      const now = moment().unix() //UTC seconds since 1/1/1970
      const secondsago = 3600 * 24 //last 6 hours of data
      const timeAgo = now - secondsago //timestamp in seconds
      fetchSensorsForHousehold(householdId, timeAgo)
    }

    this.fetchSensors()
    this.enableRefresh()

  }

  toggleAutoRefresh = (e) => {
    this.setState({ autoRefreshEnabled: !this.state.autoRefreshEnabled })
    if (!this.state.autoRefreshEnabled === false) {
      this.disableRefresh()
    }
    if (!this.state.autoRefreshEnabled === true) {
      this.enableRefresh()
    }
  }

  componentWillUnmount() {
    this.disableRefresh()
  }

  render() {
    const {
      isFetching,
      sensors,
      // timezone,
      classes,
      theme,
    } = this.props

    const {
      autoRefreshEnabled,
    } = this.state
    return (
      <Card className={classes.root} automation_attribute="sensorStatusCard">
        <CardHeader
          automation_attribute="sensorStatusHeader"
          title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h5" style={{ paddingRight: 10 }}>Sensor Status</Typography>
              <Chip
                automation_attribute="autoRefreshButton"
                icon={<RefreshIcon />}
                label={`${autoRefreshEnabled ? "Disable" : "Enable"} auto refresh (60 sec)`}
                onClick={this.toggleAutoRefresh}
                variant={autoRefreshEnabled ? "default" : "outlined"}
              />
            </div>
          }
        />
        <CardContent automation_attribute="sensorStatusContent">
          {
            isFetching &&
            <div className={classes.SensorActivityLoader} style={{ width: '100%', display: 'inline-flex', justifyContent: 'center' }}>
              <CircularProgress color='primary' />
            </div>
          }

          {
            isFetching || sensors.length !== 0 ? null
              : <div className={classes.SensorActivityEmpty}>
                <Typography variant="body1" align="center">No activity recorded</Typography>
              </div>
          }

          {
            !isFetching &&
            <div className={classes.SensorStates}>
              {
                _.map(sensors, (sensor, i) => (
                  <div key={`sensorStateEvent_${i}`} title={sensor.timestamp}>
                    <GridListTile classes={{ tile: classes.gridItem }}>
                      <div className={classes.StatusLabel}>
                        <Typography noWrap>
                          {sensor.friendlyName}
                        </Typography>
                      </div>
                      <div automation_attribute={"sensorstatusIcon_" + sensor.icon.name}>
                        <InteliIcon
                          style={{ justifySelf: 'center' }}
                          name={sensor.icon.name}
                          family={sensor.icon.family}
                          color={theme.palette.info.main}
                          size={50}
                        />
                      </div>
                      <div className={classes.StatusLabel}>
                        <Typography noWrap>{sensor.friendlyTime}</Typography>
                      </div>
                      <Typography> {sensor.value}</Typography>
                    </GridListTile>
                  </div>
                ))
              }
            </div>
          }
        </CardContent>
      </Card>
    )
  }
}

export default withTheme(withStyles(styles)(SensorsStateView))
