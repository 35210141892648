import React, { Component } from 'react'
import PropTypes from 'prop-types'
import access from 'safe-access'
import clsx from 'clsx'

import { withStyles } from '@material-ui/core/styles'

// Material UI Components
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

// Custom components
import NightGraph from '../../../../components/NightGraph'
import RadarGraph from '../../../../components/RadarGraph'
import NightGuages from '../graphs/NightGuages'
import { MultiResidentGuagesSection } from '../graphs/MultiNightGuages/MultiResidentGuagesSection'
import { MultiNightGraphSection } from '../graphs/MultiNightGraphSection'
import BedSensorGraph from '../../../../components/BedSensorGraph'

// Icons
import InteliIcon from '../../../../components/InteliIcon'

// Models
import { enums } from '@intelicare/shared'
import { behaviour, colors } from '../../../../redux'

const {
  BEHAVIOUR_STATUS,
  BehaviourClassification,
} = enums
const colorForStatusValue = colors.colorForStatusValue
const getColoredClassificationIcon = behaviour.getColoredClassificationIcon

class NightView extends Component {
  state = { expanded: false }

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }))
  }

  doesHouseholdHaveRadars = (householdV2) => {
    try {
      const { data } = householdV2

      for (const sensor of data.sensors) {

        if (sensor.type === "radar") return true;
      }

      return false
    }
    catch (error) {
      return false
    }

  }

  componentDidUpdate(lastProps, _lastState) {
  }

  render() {
    const {
      classes,
      timezone,
      hasZones,
      householdV2,
      isDeveloper,
      useNewLook,
    } = this.props

    let icon
    let behaviours
    let bedSensors
    const status = access(householdV2, 'behaviourRollup.SLEEP')
    const statusColor = colorForStatusValue(status || BEHAVIOUR_STATUS.UNKNOWN)
    icon = getColoredClassificationIcon(
      BehaviourClassification.SLEEP,
      statusColor
    )

    behaviours = access(householdV2, 'data.behaviours')
    const sensors = access(householdV2, 'data.sensors')
    if (sensors) {
      bedSensors = sensors.filter(s => s.type === 'bedsensor')
    }

    return (
      <Card className={classes.root} automation_attribute="nightCard">
        <CardHeader automation_attribute="nightHeader"
          classes={{
            root: classes.headerRoot,
            title: classes.headerTitle,
            subheader: classes.headerSubheader,
          }}
          title={!useNewLook && <Typography variant="h5">Sleep and Night-time Activity</Typography>}
          subheader={!useNewLook && <Typography color="textSecondary" variant="body1">Routine night time activity and movement around the home,  lets you know if there are any changes such as restlessness that may indicate a problem</Typography>}
          action={
            !useNewLook && <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: this.state.expanded,
              })}
              onClick={this.handleExpandClick}
            >
              <ExpandMoreIcon
                automation_attribute="nightExpandArrow"
                fontSize='large'
                color='primary'
                style={{ backgroundColor: '#F0F0F0', borderRadius: '50%' }}
              />
            </IconButton>
          }
          avatar={
            !useNewLook && <div>
              <InteliIcon
                size={55}
                name={icon.name}
                family={icon.family}
                color={icon.color}
              />
            </div>
          }
        />
        <div automation_attribute="nightContent">
          <CardContent>
            {hasZones && <MultiResidentGuagesSection timezone={timezone} automation_attribute="nightGauges" /> }
            {!hasZones && behaviours && <NightGuages behaviours={behaviours} timezone={timezone} automation_attribute="nightGauges" />}
          </CardContent>
          <Collapse in={useNewLook || this.state.expanded} timeout="auto" unmountOnExit>
            <CardContent automation_attribute="nightGraphs">
              <Typography variant="h6">Overnight activity graph</Typography>
              <Typography color="textSecondary" variant="body1" style={{ paddingBottom: 16 }}>
                InteliCare builds a profile of routine night time activity and movement around
                the home. It quickly learns the normal levels and lets you know if there are
                any changes such as restlessness that may indicate a problem.
              </Typography>

              { hasZones && <MultiNightGraphSection householdV2={householdV2} /> }
              { !hasZones && <NightGraph household={householdV2} height={300} /> }


            </CardContent>
          </Collapse>

          {/* Radar Sensor Section */}
          {this.doesHouseholdHaveRadars(householdV2) &&
            <Collapse in={useNewLook || this.state.expanded} timeout="auto" unmountOnExit>
              <CardContent>

                <RadarGraph
                  title={"Radar sensor activity graph"}
                  subtitle="The radar shows respiration, heart rate, activity, and target distance over the last 24 hours."
                  height={300} />

              </CardContent>

            </Collapse>
          }
          {isDeveloper && bedSensors.length > 0 &&
            <Collapse in={useNewLook || this.state.expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Typography style={{ padding: 12 }}>Bed Monitor</Typography>
                {bedSensors.map(s => <BedSensorGraph key={s.id} householdV2={householdV2} sensorInfo={s} />)}
              </CardContent>
            </Collapse>
          }
        </div>
      </Card>
    )
  }

  static propTypes = {
    classes: PropTypes.object,
    timezone: PropTypes.string,
    id: PropTypes.string,
    household: PropTypes.object,
    householdV2: PropTypes.object,
    tiles: PropTypes.func,
    isDeveloper: PropTypes.bool,
    useNewLook: PropTypes.bool,
    hasZones: PropTypes.bool,
  }
}

const styles = theme => ({
  root: {
    marginBottom: 20,
  },
  headerRoot: {
    background: 'white',
  },
  headerTitle: {
    color: 'black',
  },
  headerSubheader: {
    color: 'black',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.short,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
})

export default withStyles(styles)(NightView)
