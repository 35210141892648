import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Alert from '@material-ui/lab/Alert'
import CircularProgress from '@material-ui/core/CircularProgress'
import { callAPI } from '../../../../redux/domains/user/userStore'
import { apiEndpoints } from '../../../../redux'

// Material Icons
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import SecurityIcon from '@mui/icons-material/Security'

const TOGGLE_PRO_USER = apiEndpoints.TOGGLE_PRO_USER

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const EnableProUserModal = ({ handleClose, open, result, error, isLoading, toggleProUser, clearState }) =>
{
  EnableProUserModal.propTypes = {
    handleClose: PropTypes.func,
    open: PropTypes.bool,
    result: PropTypes.any,
    error: PropTypes.any,
    isLoading: PropTypes.bool,
    toggleProUser: PropTypes.func,
    clearState: PropTypes.func,
  }

  const handleCloseModal = () =>
  {
    setEmail("")
    clearState()
  }

  const [ getEmail, setEmail ] = useState("")

  return (
    <Dialog
      onExit={() => handleCloseModal()}
      maxWidth="sm"
      fullWidth={true}
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <SecurityIcon style={styles.titleIcon}/>
        Pro User Access</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <TextField
            onChange={(e) => setEmail(e.target.value)}
            style={styles.textField}
            fullWidth
            label="Pro User Email"
            variant="outlined"
            placeholder="user@company.com.au">

          </TextField>
        </DialogContentText>

        {isLoading && <div style={styles.progress}><CircularProgress /></div>}

        {result && <Alert severity="success">{result}</Alert>}
        {error && <Alert severity="error">{error}</Alert>}

      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="">
            Close
        </Button>

        <Button
          startIcon={<ClearIcon />}
          variant="outlined"
          onClick={() => toggleProUser({ email: getEmail, enablePro: "disable" })} color="primary">
          Disable
        </Button>

        <Button
          startIcon={<CheckIcon />}
          variant="contained"
          onClick={() => toggleProUser({ email: getEmail, enablePro: "enable" })}
          color="primary">
          Enable
        </Button>

      </DialogActions>
    </Dialog>
  )
}

const styles = {
  textField: {
    marginTop: 30,
  },
  progress: {
    textAlign: 'center',
  },
  titleIcon: {
    position: "relative",
    top: 5,
    right: 8,
  },
}

const mapStateToProps = state =>
{
  return {
    result: state.system.proUser.result,
    error: state.system.proUser.error,
    isLoading: state.system.proUser.isLoading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleProUser: (params) => dispatch(callAPI(TOGGLE_PRO_USER(params))),
    clearState: () => dispatch({ type: "CLEAR_TOGGLE_PRO_USER" }),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EnableProUserModal)