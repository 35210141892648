import moment from 'moment';
import { ISensor, SENSOR_STATUS_REASONS } from '../Sensor';

const GEN2_SENSOR_DEFAULT_OFFLINE_HOURS = 12

export const getGen2OfflineReason = (sensor: ISensor): ISensor =>
{
  if (moment(sensor.lastSeen).isAfter(moment().subtract(GEN2_SENSOR_DEFAULT_OFFLINE_HOURS, 'hours')))
  {
    sensor.status = 'NORMAL'
    sensor.statusReason = SENSOR_STATUS_REASONS.ok
  }
  else
  {
    sensor.status = 'ALERT'
    sensor.statusReason = SENSOR_STATUS_REASONS.notSeen
  }

  return sensor;
}