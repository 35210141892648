import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../interfaces/reduxState';
import { clearSettingsActions, getBehavioursBegin, getBehavioursByZoneBegin, updateBehaviourBegin } from '../../../../redux/domains/settings/creators';
import { InsightItem } from './InsightItem';
import Avatar from '@material-ui/core/Avatar';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { purple } from '@material-ui/core/colors';
import { getInitials } from '../../../../routes/Organisations/component/Modals/utils/getInitials';
import { CircularProgress } from '@material-ui/core';
import { SnackAlert } from '../../../../routes/Organisations/component/Shared/SnackAlert';
import { getZonesBegin } from '../../../../redux/domains/zones/actionCreators';
import { IBehaviourData } from '@intelicare/shared/interfaces/behaviours/Behaviour';

export const ZoneInsightsView = () =>
{
  const [expanded, setExpanded] = useState<string | false>(false);

  const householdId = useSelector(({ householdsV2 }: ApplicationState) => householdsV2.activeHouseholdId)
  const zones = useSelector(({ settings }: ApplicationState) => settings.zones)
  const isSupport = useSelector(({ user }: ApplicationState) => user.isSupport)
  const isDeveloper = useSelector(({ user }: ApplicationState) => user.isDeveloper)
  const sensors = useSelector(({ householdsV2 }: ApplicationState) => householdsV2.sensorDetails)
  const isGetting = useSelector(({ settings }: ApplicationState) => settings.isGetting);
  const isUpdating = useSelector(({ settings }: ApplicationState) => settings.isUpdating);
  const successResult = useSelector(({ settings }: ApplicationState) => settings.successResult);
  const errorResult = useSelector(({ settings }: ApplicationState) => settings.errorResult);
  const household = useSelector(({ householdsV2 }: ApplicationState) => householdsV2.households.find(h => h.householdId === householdId));
  const behaviours = useSelector(({ settings }: ApplicationState) => settings.behaviours)
  const zonesAndRooms = useSelector(({ zones }: ApplicationState) => zones.zones)

  const hasZones = household?.data?.hasZones

  const dispatch = useDispatch();

  useEffect(() => {
    if(hasZones)
    {
      dispatch(getBehavioursByZoneBegin(householdId, "getAndSortByZone"))
      dispatch(getZonesBegin(householdId)) // Get the zones and rooms for climate
    }
    if(!hasZones) dispatch(getBehavioursBegin(householdId, "getAllBehaviours"))

  }, [])

  return(
    <div style={styles.container}>

      {/* SINGLE USER HOUSEHOLD */}
      { !hasZones && behaviours && behaviours.map(behaviour => {
        return(
          <InsightItem
            zonesAndRooms={zonesAndRooms}
            zoneId={""}
            onUpdate={(behaviour) => dispatch(updateBehaviourBegin(householdId, behaviour))}
            behaviour={behaviour}
            sensors={sensors}
            isSupport={isSupport}
            isDeveloper={isDeveloper} />
        )
      })}
      {isGetting ? <CircularProgress /> :
      // MULTI USER HOUSEHOLD
        hasZones && zones && zones.map(zone => {
          return (<Accordion expanded={expanded === zone.zoneId} onChange={(e, expanded) => setExpanded(expanded ? zone.zoneId : false)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <div style={styles.rowGroup}>
                <Avatar style={styles.avatar} >{getInitials(zone.friendlyName)}</Avatar>
                <Typography style={styles.headingText}>{zone.friendlyName}</Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {
                zone && zone.behaviours && !isGetting && zone.behaviours.map((behaviour: IBehaviourData) => {
                  return(
                    <InsightItem
                      zonesAndRooms={zonesAndRooms}
                      zoneId={zone.zoneId}
                      onUpdate={(behaviour) => dispatch(updateBehaviourBegin(householdId, behaviour))}
                      behaviour={behaviour}
                      sensors={sensors}
                      isSupport={isSupport}
                      isDeveloper={isDeveloper} />
                  )
                })
              }
            </AccordionDetails>
          </Accordion>)

        })}

      {/* SNACKING */}
      <SnackAlert isLoading={isUpdating} open={isUpdating} alertMsg={"Updating behaviour"} color={"info"} onClose={() => dispatch(clearSettingsActions())}/>
      <SnackAlert open={successResult ? true : false} alertMsg={successResult} color={"success"} onClose={() => dispatch(clearSettingsActions())}/>
      <SnackAlert open={errorResult ? true : false} alertMsg={errorResult} color={"error"} onClose={() => dispatch(clearSettingsActions())}/>
      {/* Looks like {this.state.enableBehaviourName} insight is not enabled: Please add the insight to the sensor first. */}
    </div>
  )
}

const styles = {
  container: {
    width: "100%",
    margin: 32,
  },
  rowGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: purple[500],
  },
  icon: {
    color: 'grey',
  },
  headingText: {
    paddingTop: 2,
    paddingLeft: 6,
  },
}
