import _ from 'lodash'

// Reducer functions
import { formatNotifications, updateDocumentTitleWithNotificationsCount } from './reducerFunctions'

// ------------------------------------
// Constants
// ------------------------------------
export const NOTIFICATIONS_BEGIN = 'NOTIFICATIONS_BEGIN'
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS'
export const NOTIFICATIONS_ERROR = 'NOTIFICATIONS_ERROR'

export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
export const RESOLVE_NOTIFICATION = 'RESOLVE_NOTIFICATION'
export const RESOLVE_NOTIFICATION_SENT = 'RESOLVE_NOTIFICATION_SENT'
export const SET_ALERTS_MINIMISED = 'SET_ALERTS_MINIMISED'

export const NOTIFICATIONS_MANAGER_SEND_BEGIN = 'NOTIFICATIONS_MANAGER_SEND_BEGIN'
export const NOTIFICATIONS_MANAGER_SEND_ERROR = 'NOTIFICATIONS_MANAGER_SEND_ERROR'
export const NOTIFICATIONS_MANAGER_SEND_SUCCESS = 'NOTIFICATIONS_MANAGER_SEND_SUCCESS'
export const NOTIFICATIONS_MANAGER_SEND_CLEAR_SNACK = 'NOTIFICATIONS_MANAGER_SEND_CLEAR_SNACK'
export const CLEAR_SEND_ERROR = 'CLEAR_SEND_ERROR'

export const GET_NOTIFICATIONS_HOUSEHOLD_BEGIN = 'GET_NOTIFICATIONS_HOUSEHOLD_BEGIN'
export const GET_NOTIFICATIONS_HOUSEHOLD_SUCCESS = 'GET_NOTIFICATIONS_HOUSEHOLD_SUCCESS'
export const GET_NOTIFICATIONS_HOUSEHOLD_ERROR = 'GET_NOTIFICATIONS_HOUSEHOLD_ERROR'
export const CLEAR_GET_NOTIFICATIONS_HOUSEHOLD = 'CLEAR_GET_NOTIFICATIONS_HOUSEHOLD'

export const GET_ALL_NOTIFICATIONS_BEGIN = 'GET_ALL_NOTIFICATIONS_BEGIN'
export const GET_ALL_NOTIFICATIONS_SUCCESS = 'GET_ALL_NOTIFICATIONS_SUCCESS'
export const GET_ALL_NOTIFICATIONS_ERROR = 'GET_ALL_NOTIFICATIONS_ERROR'

export const GET_ALL_NOTIFICATION_COUNT_BEGIN = 'GET_ALL_NOTIFICATION_COUNT_BEGIN'
export const GET_ALL_NOTIFICATION_COUNT_SUCCESS = 'GET_ALL_NOTIFICATION_COUNT_SUCCESS'
export const GET_ALL_NOTIFICATION_COUNT_ERROR = 'GET_ALL_NOTIFICATION_COUNT_ERROR'

export const GET_ALL_ACTIVE_ALERTS_BEGIN = 'GET_ALL_ACTIVE_ALERTS_BEGIN'
export const GET_ALL_ACTIVE_ALERTS_SUCCESS = 'GET_ALL_ACTIVE_ALERTS_SUCCESS'
export const GET_ALL_ACTIVE_ALERTS_ERROR = 'GET_ALL_ACTIVE_ALERTS_ERROR'

export const EXPORT_NOTIFICATIONS_BEGIN = 'EXPORT_NOTIFICATIONS_BEGIN'
export const EXPORT_NOTIFICATIONS_SUCCESS = 'EXPORT_NOTIFICATIONS_SUCCESS'
export const EXPORT_NOTIFICATIONS_ERROR = 'EXPORT_NOTIFICATIONS_ERROR'
export const CLEAR_EXPORTED_NOTIFICATIONS = 'CLEAR_EXPORTED_NOTIFICATIONS'

export const NEW_ALERT_RECEIVED = 'NEW_ALERT_RECEIVED'
export const ALERT_RESOLVED = 'ALERT_RESOLVED'


// ------------------------------------
// Actions
// ------------------------------------
export const updateNotification = (notification) => {
  return {
    type: UPDATE_NOTIFICATION,
    notification: notification,
  }
}

export const resolveNotification = (notification, resolution) => {
  return {
    type: RESOLVE_NOTIFICATION,
    notification: notification,
    resolution: resolution,
  }
}

export const resolveNotificationCompleted = (notification) => {
  return {
    type: RESOLVE_NOTIFICATION_SENT,
    notification: notification,
  }
}

export const setAlertsMinimised = (minimised) => {
  return {
    type: SET_ALERTS_MINIMISED,
    minimised: minimised,
  }
}

export const clearSendError = () => {
  return {
    type: CLEAR_SEND_ERROR,
  }
}

// ------------------------------------
// Helpers
// ------------------------------------
const addNotification = (notifications, notification) => {
  _.remove(notifications, { id: notification.id })
  const n = _.concat(notifications, notification)
  return n
}

const resolveLocalNotification = (notifications, notification) => {
  var tempNotification = _.find(notifications, { id: notification.id })
  _.remove(notifications, { id: notification.id })
  tempNotification.status = 'UPDATING'
  tempNotification.resolution = notification.resolution
  return _.concat(notifications, tempNotification)
}

export const generateGwNotification = (message, gatewayId, userId, friendlyName) => {
  var notification = {
    "type": "INFO",
    "gatewayId": gatewayId,
    "source": {
      type: 'carer',
      id: userId,
      friendlyName: friendlyName,
    },
    "recipients": [{
      "type": "HOUSEHOLD",
      "message": message,
      "attributes": {
        "color": "#8AC56D",
        "command": "ADD_NOTIFICATION",
        "buttons": [{ "buttonText": "OK", "buttonAction": "OK" }],
        "msgType": "DETAILED",
      },
    }],
  }
  return notification
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isFetching: false,
  notifications: [],
  error: undefined,
  lastFetchedNotifications: undefined,
  minimisedAlerts: true,
  messageSending: false,
  sendError: undefined,
  notificationResolving: false,
  notificationResolvingId: undefined,
  activeAlerts: 0,
  activeAlertNotifications: [],
  showMessageSentSnack: false,
  snackType: "",
}

// Reducer
export default function notificationsReducer(state = initialState, action) {

  switch (action.type) {


    case UPDATE_NOTIFICATION:
      return {
        ...state,
        notifications: addNotification(state.notifications, action.notification),
      }

    case RESOLVE_NOTIFICATION:
      return {
        ...state,
        notificationResolving: true,
      }

    case RESOLVE_NOTIFICATION_SENT:
      return {
        ...state,
        notificationResolving: false,
        notifications: resolveLocalNotification(state.notifications, action.notification),
      }

    case SET_ALERTS_MINIMISED:
      return {
        ...state,
        minimisedAlerts: action.minimised,
      }

    case NOTIFICATIONS_MANAGER_SEND_BEGIN:
      return {
        ...state,
        messageSending: true,
        sendError: undefined,
        showMessageSentSnack: true,
        snackType: "info",
      }

    case NOTIFICATIONS_MANAGER_SEND_ERROR:
      return {
        ...state,
        sendError: action.error,
        messageSending: false,
        showMessageSentSnack: true,
        snackType: "error",
      }

    case NOTIFICATIONS_MANAGER_SEND_SUCCESS:
      return {
        ...state,
        messageSending: false,
        showMessageSentSnack: true,
        snackType: "success",
        minimisedAlerts: true,
        activeAlerts: updateDocumentTitleWithNotificationsCount(action, state, "REMOVE"),
      }

    case NOTIFICATIONS_MANAGER_SEND_CLEAR_SNACK:
      return {
        ...state,
        showMessageSentSnack: false,
        snackType: "success",
      }

    case CLEAR_SEND_ERROR:
      return {
        ...state,
        sendError: undefined,
      }

    case GET_NOTIFICATIONS_HOUSEHOLD_BEGIN:
      return {
        ...state,
        isGettingNotificationsForHousehold: true,
      }

    case GET_NOTIFICATIONS_HOUSEHOLD_SUCCESS:
      return {
        ...state,
        isGettingNotificationsForHousehold: false,
        householdNotifications: action.data,
      }

    case GET_NOTIFICATIONS_HOUSEHOLD_ERROR:
      return {
        ...state,
        isGettingNotificationsForHousehold: false,
        householdNotifications: [],
        householdNotificationsError: action.error,
      }

    case CLEAR_GET_NOTIFICATIONS_HOUSEHOLD:
      return {
        ...state,
        isGettingNotificationsForHousehold: false,
        householdNotifications: [],
        householdNotificationsError: "",
      }

    case GET_ALL_NOTIFICATIONS_BEGIN:
      return {
        ...state,
        isFetching: true,
      }
    case GET_ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        notifications: action.data,
        error: "",
      }
    case GET_ALL_NOTIFICATIONS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }

    case GET_ALL_NOTIFICATION_COUNT_BEGIN:
      return {
        ...state,
        isGettingNotificationsCount: true,
      }
    case GET_ALL_NOTIFICATION_COUNT_SUCCESS:
      return {
        ...state,
        activeAlerts: action.data.notificationCount,
        isGettingNotificationsCount: false,
        minimisedAlerts: action.data.notificationCount > 0 ? false: true,
      }

    case NEW_ALERT_RECEIVED:
      updateDocumentTitleWithNotificationsCount(action, state, "ADD")
      return {
        ...state,
        activeAlerts: state.activeAlerts + 1,
      }
    case ALERT_RESOLVED:
      updateDocumentTitleWithNotificationsCount(action, state, "REMOVE")
      return {
        ...state,
        activeAlerts: state.activeAlerts > 0 ? state.activeAlerts - 1 : 0,
      }
    case GET_ALL_NOTIFICATION_COUNT_ERROR:
      return {
        ...state,
        getActiveAlertsCountError: "",
        isGettingNotificationsCount: false,
      }
    case GET_ALL_ACTIVE_ALERTS_BEGIN:
      return {
        ...state,
        isGettingActiveAlerts: true,
      }
    case GET_ALL_ACTIVE_ALERTS_SUCCESS:
      return {
        ...state,
        isGettingActiveAlerts: false,
        activeAlertNotifications: action.data,
      }
    case GET_ALL_ACTIVE_ALERTS_ERROR:
      return {
        ...state,
        isGettingActiveAlerts: false,
        activeAlertNotificationsError: action.error,
      }
    case EXPORT_NOTIFICATIONS_BEGIN:
      return {
        ...state,
        isExportingNotifications: true,
      }

    case EXPORT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isExportingNotifications: false,
        exportedNotifications: formatNotifications(action.data),
      }
    case EXPORT_NOTIFICATIONS_ERROR:
      return {
        ...state,
        isExportingNotifications: false,
        exportNotificationsError: action.error,
      }
    case CLEAR_EXPORTED_NOTIFICATIONS:
      return {
        ...state,
        isExportingNotifications: false,
        exportedNotifications: [],
        exportNotificationsError: "",
      }
    default:
      return state
  }
}

