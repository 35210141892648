import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../interfaces/reduxState'
import { callAPI } from '../../redux/domains/user/userStore'
import { ACTIVITY_GRAPH_WITH_ZONES, AVERAGEACTIVITY } from '../../redux/shared/services/APIEndpoints'
import { ActivityRowItem } from './ActivityRowItem'
import { IHouseholdDocument } from '@intelicare/shared/interfaces/household/IHouseholdDocument'

import Button from '@material-ui/core/Button'

interface MutliActivityViewProps
{
  householdId: string;
  household: IHouseholdDocument;
}

/**
 *
 * @description Container view for each persons activity
 */

export const MutliActivityView = ({ householdId, household }: MutliActivityViewProps) =>
{
  const graphDataSortedByZone = useSelector(({ graphs }: ApplicationState) => graphs.activity.data)

  const [ timePeriod, setTimePeriod ] = useState(24)
  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(callAPI(ACTIVITY_GRAPH_WITH_ZONES({ timePeriod, householdId } )))


  }, [timePeriod])

  return(
    <div style={styles.container}>

      <div style={styles.buttonGroup} >
        <Button variant={timePeriod === 24 ? "outlined": undefined} onClick={() => setTimePeriod(24)}>24h</Button>
        <Button variant={timePeriod === 168 ? "outlined": undefined} onClick={() => setTimePeriod(168)}>1w</Button>
      </div>

      {/* BREAKDOWN */}
      {graphDataSortedByZone && graphDataSortedByZone.length > 0 && graphDataSortedByZone.map((zone, key) => {
        return(<ActivityRowItem key={key} friendlyName={zone.friendlyName} sensors={zone.sensors} household={household} /> )
      })}

    </div>
  )
}

const styles = {
  container: {
  },
  buttonGroup: {
    display: 'flex',
    margin: 6,
  },
}