import React, { Component } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'


import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

// The remote commands modal
import RemoteCommandsForm from './RemoteCommandsForm'

class RemoteCommandsModal extends Component {

  state = {
    commandData: "",
  }

  handleExecution() {
    this.props.executeService(this.props.gatewayId, this.state.commandData.data)
  }
  render() {
    const { onHide, show, gatewayId, executeService, remoteResult, remoteErrorResult } = this.props

    return (<Dialog
      maxWidth="md"
      onClose={onHide}
      open={show}>
      <DialogTitle>Remote Commands - {gatewayId}</DialogTitle>
      <DialogContent>
        <RemoteCommandsForm
          onCommandChange={(item) => this.setState({ commandData: item.commandData })}
          remoteErrorResult={remoteErrorResult}
          remoteResult={remoteResult}
          executeService={executeService}
          gatewayId={gatewayId} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onHide}>Close</Button>
        <Button color="primary" variant="outlined" onClick={() => this.handleExecution()}>
          {remoteResult.isFetching && <CircularProgress size={24} style={{ marginRight: 3 }} color="primary" />}
          {remoteResult.isFetching ? "Executing..." : "Execute"}</Button>
      </DialogActions>
    </Dialog>)
  }
}

export default RemoteCommandsModal

RemoteCommandsModal.propTypes = {
  show: PropTypes.bool,
  remoteResult: PropTypes.object,
  gatewayId: PropTypes.string,
  executeService: PropTypes.func,
  onHide: PropTypes.func,
  remoteErrorResult: PropTypes.object,
}