import React from 'react'
import DocumentTitle from 'react-document-title'
import { Fragment } from 'react'


import MenuList from '@material-ui/core/MenuList'
import InteliIcon from "../../../../components/InteliIcon"
import Title from '../../../../components/Title'
import './ConsoleRootView.scss'
import MenuLinkItem from '../../../../components/MenuLinkItem'
import sharedStore from '../../../../redux'

export default class ConsoleRootView extends React.Component {
  static pageName = 'developerConsole'
  static friendlyName = 'Developer Console'

  render() {
    return (

      <Fragment>
        <DocumentTitle title='Developer Console' />

        <Title title='Developer Console' />
        <div className='col-xs-12 content--container-content col-main-content'>
          <MenuList autoWidth={true}
            style={{ width: '280px' }}>
            <MenuLinkItem style={styles.menuItemStyle}
              leftIcon={<InteliIcon name='dashboard' family='material' />}
              to='/developer/gatewayfirmware'
            >
              Gateway Firmware
            </MenuLinkItem>
            <MenuLinkItem style={styles.menuItemStyle}
              leftIcon={<InteliIcon name='dashboard' family='material' />}
              to='/developer/remotecommands'>
              Remote Gateway Commands
            </MenuLinkItem>
          </MenuList>
        </div>
      </Fragment>
    )
  }
}

const styles = {
  titleStyle: {
    color: '#e0204d',
  },
  iconLeftStyle: {
    color: 'black',
    fontSize: 22,
  },
  menuItemStyle: {
    color: sharedStore.colors.primaryText,
    fontSize: '14px',
    width: '240px',
  },
}

