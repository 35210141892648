import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'

import sharedStore from '../../redux'
const callAPI = sharedStore.userStore.callAPI

const {

  RESET_BEHAVIOUR,
} = sharedStore.apiEndpoints

const SUCCESS_RESULT = "RESET_BEHAVIOUR_SUCCESS"
const ERROR_RESULT = "RESET_BEHAVIOUR_ERROR"

function ConfirmDialog(props) {
  ConfirmDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    householdId: PropTypes.string,
    behaviour: PropTypes.string,
    isFetching: PropTypes.bool,
    resetBehaviour: PropTypes.func,
    resetBehaviourResult: PropTypes.string,
    resetResult: PropTypes.func,
    zoneId: PropTypes.string,
  }

  function closeAndResetResult() {
    props.handleClose()
    props.resetResult()
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={closeAndResetResult}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Reset Behaviour"}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to reset this behaviour? This cannot be undone later.
          </DialogContentText>
          {
            props.isFetching &&
            <div style={{ textAlign: "center" }}>
              <DialogContentText>Resetting... Please wait</DialogContentText>
              <br />
              <CircularProgress></CircularProgress>
            </div>
          }

          {
            props.resetBehaviourResult === SUCCESS_RESULT &&
            <Alert style={{ marginTop: 12 }} severity="success">The behaviour was successfully reset!</Alert>
          }
          {
            props.resetBehaviourResult === ERROR_RESULT &&
            <Alert style={{ marginTop: 12 }} severity="error">There was an error when attempting to reset the behaviour</Alert>
          }
        </DialogContent>
        <DialogActions>
          {
            !props.resetBehaviourResult &&
            <div>
              <Button onClick={() => { props.resetBehaviour(props.householdId, props.behaviour, props.zoneId) }} color="primary">
                Yes
              </Button>
              <Button onClick={closeAndResetResult} color="primary" autoFocus>
                No
              </Button>
            </div>
          }
          {
            props.resetBehaviourResult &&
            <div>
              <Button onClick={closeAndResetResult} color="primary" autoFocus>
                OK
              </Button>
            </div>
          }
        </DialogActions>


      </Dialog>
    </div>
  )
}
const mapStateToProps = (state) => ({
  householdId: state.householdsV2.activeHouseholdId,
  isFetching: state.householdsV2.isFetching,
  resetBehaviourResult: state.householdsV2.resetBehaviourResult,
})
const mapDispatchToProps = (dispatch) => ({
  resetBehaviour: (householdId, behaviour, zoneId) => {
    dispatch(callAPI(RESET_BEHAVIOUR({ householdId, behaviour, zoneId })))
  },
  resetResult: () => dispatch({ type: "CLEAR_RESET_BEHAVIOUR_RESULT" }),
})
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDialog)