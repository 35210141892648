import { connect } from 'react-redux'
import RadarGraph from '../components/RadarGraph'

import { GET_RADAR_GRAPH, EXPORT_DATA } from '../../../redux/shared/services/APIEndpoints'
import { callAPI } from '../../../redux/domains/user/userStore'

const mapStateToProps = state => {
  return {
    radarGraphData: state.graphs.radarGraphData,
    isFetchingRadarData: state.graphs.isFetchingRadarData,
    radarGraphGetError: state.graphs.radarGraphGetError,
    householdId: state.householdsV2.activeHouseholdId,
    currentSensor: state.graphs.currentSensor,
    otherRadarSensors: state.graphs.otherRadarSensors,
    isPolling: state.exportData.isPolling,
    exportUri: state.exportData.downloadUrl,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getRadarGraph: (params) => dispatch(callAPI(GET_RADAR_GRAPH(params))),
    exportRadarData: (params) => dispatch(callAPI(EXPORT_DATA({
      export: 'export',
      householdId: params.householdId,
      sensorId: params.sensorId,
      startTime: params.startTime,
      endTime: params.endTime,
    }))),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RadarGraph)
