/* eslint-disable */
import React, { Component } from 'react'
import Select from 'react-select'
import * as moment from 'moment-timezone'
import access from 'safe-access'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import { validateText } from '../../../helpers/textValidation'
import FormButtons from '../FormButtons'
import buildComponent from './BasePageConnector'

class ProfileSettings extends Component {

  constructor(props) {
    super(props)

    this.state = {}

    this.renderNewAvatar = this.renderNewAvatar.bind(this)

    this.imageInputRef = React.createRef()
    this.timezonePickerRef = React.createRef()

    this.timezoneOptions = moment.tz.names().map(tz => ({ value: tz, label: tz }))

    this.monitoredOptions = [{ value: "monitored", label: "Monitored" }, { value: "unmonitored", label: "Unmonitored" }, { value: "away", label: "Resident Away" }]
  }

  /**
   * Filter the options that can be visualized and used in the Household Status Select component.
   * @returns An object containing the defaultSelection and filteredOptions to be used in the Household Status Select.
   */
  filterMonitoredOptions() {
    const {householdStatus} = this.props.activeHousehold;
    const { isDeveloper, isSupport } = this.props;
    
    let defaultSelection = '';
    let filteredOptions = [];

    if(!isDeveloper && !isSupport) {
      defaultSelection = householdStatus === "monitored" ? this.monitoredOptions[0] : this.monitoredOptions[1]
      filteredOptions = this.monitoredOptions.filter((el) => {
        return el.value != 'away'
      })
    } else {
      defaultSelection = householdStatus === "monitored" ? this.monitoredOptions[0] : householdStatus === "unmonitored" ? this.monitoredOptions[1] : this.monitoredOptions[2]
      filteredOptions = this.monitoredOptions;
    }
    return { defaultSelection, filteredOptions };
  }

  componentDidUpdate(lastProps) {
    const { isSaving } = this.props;
    if (lastProps.isSaving === true && isSaving === false) {
      this.props.setSubmitting(false);
    }
  }

  renderNewAvatar(event) {
    const { setFieldValue } = this.props;
    let reader = new FileReader()

    reader.onloadend = () => {
      setFieldValue("photo", reader.result)
    }

    reader.readAsDataURL(event.currentTarget.files[0])
  }

  render() {
    const { values, initialValues, classes, handleChange, setFieldValue } = this.props
    const selectedTimezone = this.timezoneOptions.find(tz => tz.value === values.timezone)

    return (
      <div className={classes.OverlayMain}>
        <div className={classes.OverlaySection}>
          <Grid container jjustifyContent="center" align="center" direction="column" spacing={24}>
            {/* Household Name */}
            <Grid container direction="row" item xs={12}>
              <Grid item xs={12} md={12} lg={3}>
                <Typography variant="h6" classes={{ h6: classes.label }}>Household Name</Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={7}>
                <TextField
                  type='text'
                  margin='dense'
                  variant='outlined'
                  className={classes.textInput}
                  id='friendlyName'
                  onChange={e => {
                    e.target.value = validateText(e.target.value) 
                    handleChange(e)
                  }}
                  value={values.friendlyName}
                  helperText="Only letters, numbers and . , ' - _ characters are permitted"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={2}>
                {values.friendlyName !== initialValues.friendlyName &&
                  <Typography variant="caption" classes={{ caption: classes.modified }}>Modified</Typography>
                }
              </Grid>
            </Grid>


            {/* Household status */}
            <Grid container direction="row" item xs={12}>
              <Grid item xs={12} md={12} lg={3}>
                <Typography variant="h6" classes={{ h6: classes.label }}>Household Status</Typography>
              </Grid>
              <Grid automation_attribute="householdStatus" item xs={12} md={12} lg={7}>
                <Typography component='div'>
                  <Select
                    styles={{
                      control: (style) => ({
                        ...style,
                        height: '49px',
                      }),
                      container: (style) => ({
                        ...style,
                        paddingTop: '8px',
                        paddingBottom: '4px',
                      })
                    }}
                    className={classes.textInput}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#c4274e',
                        primary25: 'rgba(0,0,0,0.1)',
                        neutral0: '#fafafa'
                      }
                    })}
                    defaultValue={this.filterMonitoredOptions().defaultSelection}
                    onChange={(newValue) => setFieldValue('householdStatus', newValue.value)}
                    options={this.filterMonitoredOptions().filteredOptions}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={2}>
                {values.householdStatus !== initialValues.householdStatus &&
                  <Typography variant="caption" classes={{ caption: classes.modified }}>Modified</Typography>
                }
              </Grid>
            </Grid>

            {/* Timezone */}
            <Grid container direction="row" item xs={12}>
              <Grid item xs={12} md={12} lg={3}>
                <Typography variant="h6" classes={{ h6: classes.label }}>Timezone</Typography>
              </Grid>
              <Grid automation_attribute="householdTimezone" item xs={12} md={12} lg={7}>
                <Typography component='div'>
                  <Select
                    styles={{
                      control: (style) => ({
                        ...style,
                        height: '49px',
                      }),
                      container: (style) => ({
                        ...style,
                        paddingTop: '8px',
                        paddingBottom: '4px',
                      })
                    }}
                    className={classes.textInput}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#c4274e',
                        primary25: 'rgba(0,0,0,0.1)',
                        neutral0: '#fafafa'
                      }
                    })}
                    value={selectedTimezone}
                    onChange={(newValue) => setFieldValue('timezone', newValue.value)}
                    options={this.timezoneOptions}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={2}>
                {values.timezone !== initialValues.timezone &&
                  <Typography variant="caption" classes={{ caption: classes.modified }}>Modified</Typography>
                }
              </Grid>
            </Grid>

            {/* Profile Picture */}
            <Grid container direction="row" item xs={12}>
              <Grid item xs={12} md={12} lg={3}>
                <Typography variant="h6" classes={{ h6: classes.label }}>Profile Picture</Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={7}>
                <div className={classes.photoUploader}>
                  <Button
                    component='label'
                    label='Select Photo'>
                    Select Photo
                    <input
                      ref={this.imageInputRef}
                      type='file'
                      onChange={this.renderNewAvatar}
                      style={{ display: 'none' }}
                    />
                  </Button>
                  <br />
                  {
                    !values.photo &&
                    <img src={null} width="200px" height="200px" />
                  }
                  {
                    values.photo &&
                    <img src={values.photo} width="200px" height="200px" />
                  }
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={2}>
                {values.photo !== initialValues.photo &&
                  <Typography variant="caption" classes={{ caption: classes.modified }}>Modified</Typography>
                }
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={classes.OverlayFooter}>
          <FormButtons {...this.props} />
        </div>
      </div>
    )
  }
}

export default buildComponent(ProfileSettings, {
  mapPropsToValues: (props) => ({
    friendlyName: props.activeHouseholdSettings.friendlyName,
    photo: props.activeHouseholdSettings.avatar,
    timezone: props.activeHousehold.timezone,
  }),
  handleSubmit: (values, actions) => {
    console.log(`Updating household profile: ${JSON.stringify(values)}`)
    console.log(actions)
    const settings = { ...values, timezone: values.timezone.value }
    delete settings.photo
    actions.props.updateSettings({
      householdId: access(actions, 'props.activeHousehold.householdId'),
      settings: values
    })
    actions.props.updateAvatar({
      householdId: access(actions, 'props.activeHousehold.householdId'),
      image: values.photo
    })
  }
})
