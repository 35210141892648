import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { compose } from 'redux'

import withStyles from '@material-ui/core/styles/withStyles'
import withTheme from '@material-ui/core/styles/withTheme'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import GridListTile from '@material-ui/core/GridListTile'
import Tooltip from '@material-ui/core/Tooltip'
import InteliIcon from "../../../components/InteliIcon"
import { withErrorHandler, Fallback } from '../../../hoc/withErrorHandler'
import { LastMotionView } from './LastMotionView'
import styles from './ActivityStreamStyles'

const MAX_EVENTS_TO_SHOW = 100;

class ActivityStreamView extends React.Component {

  static propTypes = {
    fetchActivityfeed: PropTypes.func,
    fetchActivityfeedV2: PropTypes.func,
    name: PropTypes.string,
    householdId: PropTypes.string,
    classes: PropTypes.object,
    theme: PropTypes.object,
    isDeveloper: PropTypes.bool,
    isSupport: PropTypes.bool,
    activityV1: PropTypes.array,
    isFetchingV1: PropTypes.bool,
    activityV2: PropTypes.any,
    eventData: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    const {
      fetchActivityfeedV2,
      householdId,
    } = this.props

    const UPDATE_INTERVAL_IN_MS = 60000
    this.timer = setInterval(() => this.refreshV2ActivityFeed(), UPDATE_INTERVAL_IN_MS)
    fetchActivityfeedV2(
      householdId,
      moment().subtract(24, 'hours').toISOString(),
      moment().toISOString()
    )
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  }

  refreshV2ActivityFeed = () => {
    const {
      fetchActivityfeedV2,
      householdId,
      activityV2,
    } = this.props

    let startTs = moment().subtract(24, 'hours').toISOString()
    let endTs = moment().toISOString()

    // Fetch only new events
    if (activityV2 && activityV2.end) startTs = activityV2.end

    fetchActivityfeedV2(householdId, startTs, endTs)
  }

  render() {
    const {
      activityV2,
      classes,
      theme,
      isDeveloper,
      isSupport,
      eventData,
    } = this.props

    let activity = []
    let isFetching
    if (activityV2) {
      activity = activityV2.events.slice(0, MAX_EVENTS_TO_SHOW)
      if (!activityV2.events) {
        isFetching = activityV2.isFetching
      }
    }

    return (
      <div className={classes.root}>
        <Typography variant="h6" >Activity Stream</Typography>
        <LastMotionView style={{ paddingBottom: 10 }} eventData={eventData} />
        {
          isFetching &&
          <div className={classes.Loading}>
            <CircularProgress color='primary' />
          </div>
        }
        {
          !isFetching && activity.length !== 0 ? null
            : <div className={classes.Empty}>
              <Typography variant="body1" align="center">No activity recorded</Typography>
            </div>
        }

        {
          !isFetching &&
          <div style={{ display: 'flex', flexDirection: 'row', overflow: 'auto', paddingBottom: '16px' }}>
            {
              _.map(activity, (record, i) => {
                //feature toggle for bed activity
                if ((!isDeveloper && !isSupport) &&
                  (record.name === 'Bed occupied' || record.name === 'Bed unoccupied'))
                  return (<div key={`activityRecord_${i}`}></div>)
                return (
                  <GridListTile key={`activityRecord_${i}`} classes={{ root: classes.StreamItem }}>
                    {record.friendlyName && record.friendlyName !== "Unknown" ? <Typography variant="body2" align="center">{record.friendlyName}</Typography> :
                      <div style={{ height: 20 }}></div>}

                    <div automation_attribute={"activityIcon_" + record.icon.name} className={classes.ActivityIcon}>
                      <InteliIcon
                        name={record.icon.name}
                        family={record.icon.family}
                        color={theme.palette.info.main}
                        size={50}
                      />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <Typography variant="body2" align="center">{record.name}</Typography>
                      <Tooltip title={moment(record.timestamp).fromNow()}>
                        <Typography color="textSecondary" variant="caption" align="center">{record.friendlyTime}</Typography>
                      </Tooltip>
                    </div>
                  </GridListTile>
                )
              })
            }
          </div>
        }

      </div>
    )
  }
}

export default compose(
  withErrorHandler(Fallback),
  withStyles(styles),
  withTheme
)(ActivityStreamView)
