import { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../interfaces/reduxState';
import { history } from '../../../../../hoc/CustomRouter'
import { Organisation } from '../../../../../api/OrganisationApi';

type OrganisationDetailsHook = [Organisation, Organisation[], boolean, string, string, boolean, string, string, boolean, string, string]

export const useOrganisationDetails = (): OrganisationDetailsHook => {
  // Organisation
  const organisationDetails = useSelector(({ organisations }: ApplicationState) => organisations.organisationDetails)
  const organisations = useSelector(({ organisations }: ApplicationState) => organisations.organisations)

  // Household
  const isAddingHouseholdToOrganisation = useSelector(({ organisations }: ApplicationState) => organisations.isAddingHouseholdToOrganisation)
  const successAddingHouseholdToOrganisation = useSelector(({ organisations }: ApplicationState) => organisations.successAddingHouseholdToOrganisation)
  const errorAddingHouseholdToOrganisation = useSelector(({ organisations }: ApplicationState) => organisations.errorAddingHouseholdToOrganisation)

  // Remove user
  const removeUserFromOrganisationError = useSelector(({ organisations }: ApplicationState) => organisations.removeUserFromOrganisationError)
  const isRemovingUserFromOrganisation = useSelector(({ organisations }: ApplicationState) => organisations.isRemovingUserFromOrganisation)
  const removeUserFromOrganisationSuccess = useSelector(({ organisations }: ApplicationState) => organisations.removeUserFromOrganisationSuccess)

  // Delete organisation
  const isDeletingNode = useSelector(({ organisations }: ApplicationState) => organisations.isDeletingNode);
  const deleteNodeError = useSelector(({ organisations }: ApplicationState) => organisations.deleteNodeError);
  const deleteNodeSuccess = useSelector(({ organisations }: ApplicationState) => organisations.deleteNodeSuccess);


  useEffect(() => {
    navigateBackToListIfNull()
  })

  const navigateBackToListIfNull = () => {
    if (!organisationDetails.id) history.push(`/organisations`)
  }


  return [
    organisationDetails,
    organisations,
    isAddingHouseholdToOrganisation,
    successAddingHouseholdToOrganisation,
    errorAddingHouseholdToOrganisation,
    isRemovingUserFromOrganisation,
    removeUserFromOrganisationSuccess,
    removeUserFromOrganisationError,
    isDeletingNode,
    deleteNodeSuccess,
    deleteNodeError,
  ];


}