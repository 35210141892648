/*
 * Used for toggling the save button to disabled
 * E.G disabled={true}
 */
export const isInputValid = (timeConflictError: string, alertWindowStart: string, alertWindowEnd: string, doorInfoWindowStart: string, doorInfoWindowEnd: string): boolean =>
{
  if(timeConflictError) return true;

  // Allow save if at least one of the alert windows is configured
  if(alertWindowStart !== "" && alertWindowEnd !== "") return false
  if(doorInfoWindowStart !== "" && doorInfoWindowEnd !== "" ) return false

  return true

}