export const getInitials = (orgName: string): string =>
{
  try
  {
    if(orgName.split(" ").length === 1) return orgName[0].toUpperCase() + orgName[1].toUpperCase()
    return orgName.split(" ")[0][0].toUpperCase() + orgName.split(" ")[1][0].toUpperCase()
  }
  catch(error)
  {
    return "IC";
  }
}