import React, { Component } from 'react'
import { v4 as uuidv4 } from 'uuid'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import InteliIcon from '../../../../components/InteliIcon'
//@ts-ignore
import { BEHAVIOUR_STATUS } from '@intelicare/shared/enums'
import { TILE_TYPES } from '../enums'
import { colors } from '../../../../redux'
const { colorForStatusValue } = colors

const TIMEOUT_DURATION = 500

const FRIENDLY_STATUS: {[key: string]: string} = {
  NORMAL: "Normal",
  WARNING: "Warning",
  ALERT: "Alert",
  UNKNOWN: "Unknown",
  LEARNING: "Learning",
  DISABLED: "Disabled",
}

type Props = {
  healthMetricsStatus: string,
  onShowSnack: (snackType: string, showSnack: boolean, household: any, icon: any,
    BehaviourClassification: any, BehaviourClassificationFriendly: any, classification: any,
    healthMetricsStatus?: string) => void,
  household: any,
  onClick: () => void,
}

type State = {
  tileId: string,
}

class HealthMetricsTileView extends Component<Props, State> {
  state = {
    tileId: `tile-${uuidv4()}`,
  }
  hoverTimeout: any = null

  // Private functions
  _onTileHover = () => {
    this.hoverTimeout = setTimeout(() => {
      const { onShowSnack, healthMetricsStatus, household } = this.props
      onShowSnack(TILE_TYPES.HEALTH_METRICS, true, household, null, null, null, null, healthMetricsStatus)
    }, TIMEOUT_DURATION)
  }

  _onTileHoverOut = () => {
    clearTimeout(this.hoverTimeout)
    const { onShowSnack } = this.props
    onShowSnack("", false, null, null, null, null, null, BEHAVIOUR_STATUS.UNKNOWN)
  }

  render() {
    const {
      healthMetricsStatus,
      onClick,
    } = this.props

    const statusColor = colorForStatusValue(healthMetricsStatus || BEHAVIOUR_STATUS.UNKNOWN)

    return (
      //@ts-ignore
      <Grid
        automation_attribute="HEALTH_METRICS_ROLLUP"
        id={this.state.tileId}
        item
        xs
        //@ts-ignore
        style={styles.tileContainer}
        onClick={onClick}
        onMouseOver={this._onTileHover}
        onMouseLeave={this._onTileHoverOut}
      >
        <InteliIcon name='favorite' family='material' color={statusColor} size={36} />
        <Typography gutterBottom style={{ color: statusColor }}>Health Metrics</Typography>
        <Typography gutterBottom style={{ color: statusColor }}>{FRIENDLY_STATUS[healthMetricsStatus]}</Typography>
      </Grid>
    )
  }
}

const styles = {
  tileContainer: {
    fontSize: '10px',
    lineHeight: '0.8em',
    paddingTop: '1em',
    paddingBottom: '1em',
    verticalAlign: 'top',
    textAlign: 'center',
  },
}

export default HealthMetricsTileView
