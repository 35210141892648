export const getHeaders = (state) => {
  return {
    "Access-Control-Allow-Origin": "*",
    // "x-ms-client-principal-id": state.user.principal,
    // "x-ms-token-aad-id-token": state.user.token,
    "Authorization": "Bearer " + state.user.token,
  }
}

export const getHeadersForParams = (params) => {
  return {
    "Access-Control-Allow-Origin": "*",
    // "x-ms-client-principal-id": params.principal,
    // "x-ms-token-aad-id-token": params.token,
    "Authorization": "Bearer " + params.token,
  }
}
