import React from 'react'
import PropTypes from 'prop-types'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import LaunchIcon from "@mui/icons-material/Launch"

import ResellerIcon from "@mui/icons-material/SupervisedUserCircle"

const TableItem = ({ reseller, onTableItemClick }) => {

  TableItem.propTypes = {
    reseller: PropTypes.object,
    onTableItemClick: PropTypes.func,
  }

  return (
    <TableRow hover key={reseller.name}>
      <TableCell component="th" scope="row">
        <Grid container >
          <ResellerIcon />
          <div style={{ marginTop: 2, paddingLeft: 6 }}>{reseller.name}</div>
        </Grid>
      </TableCell>
      <TableCell align="right">{reseller.abn}</TableCell>
      <TableCell align="right">{reseller.email}</TableCell>
      <TableCell align="right">{reseller.contactNumber}</TableCell>
      <TableCell align="right">{reseller.address}</TableCell>
      <TableCell align="right">
        <Button startIcon={<LaunchIcon />} onClick={() => onTableItemClick(reseller)} color="secondary" variant="outlined" size="small" icon="edit">View</Button>
      </TableCell>
    </TableRow>
  )
}



export default TableItem