// @ts-nocheck
// import * as MsalX from '../../../node_modules/msal/dist/msal'
import { UserAgentApplication } from 'msal/dist/msal'
// const { UserAgentApplication } = MsalX
import jwtDecode from 'jwt-decode'
import PromiseMemoizer from 'promise-memoize'
import moment from 'moment'

/* eslint-disable no-console */

// https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-v2-protocols-implicit
const CONSUMER_TID = '9188040d-6c67-4c5b-b112-36a304b66dad'

const applicationConfig = {
  clientID: process.env.REACT_APP_ADAL_CLIENT,
  authority: `https://login.microsoftonline.com/tfp/${process.env.REACT_APP_AD_DOMAIN}/${process.env.REACT_APP_ADAL_POLICY}`,
  b2cScopes: [[process.env.REACT_APP_ADAL_CLIENT]],
  passwordResetPolicy: `https://login.microsoftonline.com/tfp/${process.env.REACT_APP_AD_DOMAIN}/${process.env.REACT_APP_ADAL_PASSWORD_RESET_POLICY}`,
}

const inIframe = window.self !== window.top

let errorDescription

// eslint-disable-next-line no-undef,no-unused-vars
const loginObject = new UserAgentApplication(applicationConfig.clientID, applicationConfig.authority, (errorDesc, token, error, tokenType) => {
  if (errorDesc) {
    errorDescription = errorDesc
  } else {
    errorDescription = null
  }
}, {
  // storeAuthStateInCookie: true,
  cacheLocation: 'localStorage',
})

function getCookie(name) {
  let cookies = document.cookie.split('; ').reduce((p, c) => {
    let pair = c.split('=')
    p[pair[0]] = pair[1]
    return p
  }, {})
  return cookies[name]
}

export const login = () => {
  if (errorDescription && errorDescription.indexOf('AADB2C90118') > -1) {
    loginObject.authority = applicationConfig.passwordResetPolicy
  }

  if (errorDescription) {
    // We really should log this to insights.
    console.error(`Login error ${errorDescription}`)
  }

  return new Promise((resolve) => {
    const isIE = /*@cc_on!@*/false || !!document.documentMode
    const isEdge = !isIE && !!window.StyleMedia
    if (isEdge) { //edge browser
      // console.log(`Detected a shitty Edge browser`)
      // console.log(`Cookies: ${document.cookie}`)
      const token = getCookie('stored-token')
      if (!token) {
        // console.log('token unavailable')
        loginObject.loginRedirect(applicationConfig.b2cScopes)
      }
      resolve(token)
    } else { //normal browser
      // console.log(`Fetching token`)
      const token = _validTokenInLocalStorage()
      if (token) {
        // console.log('Using token from local storage')
        resolve(token)
      }
      // console.log('No valid token in local storage, Requesting token refresh')
      loginObject.acquireTokenSilent(applicationConfig.b2cScopes)
        .then(function (accessToken) {
          // console.log(`Got fresh token ${accessToken}`)
          resolve(accessToken)
          // eslint-disable-next-line no-unused-vars
        }, function (error) {
          // console.log(`Error getting token: ${error}`)
          // console.log(`Couldnt get fresh token, redirecting to login`)
          !inIframe && loginObject.loginRedirect(applicationConfig.b2cScopes)
        })
    }
  })
}

const _loginWithoutRedirect = () => {
  return new Promise((resolve, reject) => {
    loginObject.acquireTokenSilent(applicationConfig.b2cScopes)
      .then(function (accessToken) {
        resolve(accessToken)
      }, function (error) {
        reject(error)
      })
  })
}

export const decodeCredentials = (token) => {
  const decodedToken = jwtDecode(token)
  const loginHint = token.preferred_username
  const domainHint = decodedToken.tid === CONSUMER_TID ? 'consumers' : 'organizations'
  const details = Object.assign({}, {
    profile: {
      oid: decodedToken.oid,
    },
    name: decodedToken.name,
    decodedToken: decodedToken,
    azureToken: token,
    loginHint: loginHint,
    domainHint: domainHint,
  })

  return details
}

export const logout = () => {
  return new Promise((resolve) => {
    loginObject.logout()
    document.cookie = 'stored-token=;path=/'
    resolve(true)
  })
}

const _validTokenInLocalStorage = () => {
  // console.log(`Looking in storage for token`)
  const token = window.localStorage.getItem('msal.idtoken')
  // console.log(`Token Result: ${token}`)
  if (!token) return
  try {
    const decodedToken = jwtDecode(token)
    const expires = moment.unix(decodedToken.exp)
    const now = moment()
    const diff = expires.diff(now, 'minutes')
    if (diff > 10) {
      return token
    }
    return token
  } catch (error) {
    // console.log(`Error could not decode token: ${error.toString()}`)
    return undefined
  }
}
export const refreshToken = PromiseMemoizer(_loginWithoutRedirect, { maxAge: process.env.REACT_APP_REFRESH_TIMEOUT })
