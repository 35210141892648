export const EXPORT_DATA_SUCCESS = 'EXPORT_DATA_SUCCESS'
export const EXPORT_RADAR_DATA_POLL = 'EXPORT_RADAR_DATA_POLL'
export const EXPORT_RADAR_DATA_SUCCESS = 'EXPORT_RADAR_DATA_SUCCESS'
export const EXPORT_RADAR_DATA_ERROR = 'EXPORT_RADAR_DATA_ERROR'

const initialState = {
  isPolling: false,
  pollForFilename: null,
  downloadUrl: null,
  pollCount: 0,
}

export default function exportReducer(state = initialState, action) {
  switch(action.type) {
    case EXPORT_DATA_SUCCESS:
      return {
        ...state,
        pollForFilename: action.data.export,
        downloadUrl: null,
      }
    case EXPORT_RADAR_DATA_POLL:
      return {
        ...state,
        isPolling: true,
        pollForFilename: action.filename,
      }
    case EXPORT_RADAR_DATA_SUCCESS:
      return {
        ...state,
        isPolling: false,
        pollForFilename: null,
        downloadUrl: action.uri,
        pollCount: 0,
      }
    case EXPORT_RADAR_DATA_ERROR:
      return {
        ...state,
        isPolling: false,
        pollForFilename: null,
        error: action.error,
        pollCount: 0,
      }
    default:
      return state
  }
}
