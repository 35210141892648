import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from '../../../../../interfaces/reduxState'
import { Household } from '../../../../../redux/domains/householdsV2/householdStoreInterfaces'
import { callAPI } from "../../../../../redux/domains/user/userStore"
import { SLEEP_GRAPH_DATA } from "../../../../../redux/shared/services/APIEndpoints"
import { LoadingRow } from './LoadingRow'
import { RowWithAvatar } from './RowWithAvatar'

interface MultiNightGraphSectionProps
{
  householdV2: Household;
}

export const MultiNightGraphSection = ({ householdV2 }: MultiNightGraphSectionProps) =>
{

  const isFetching = useSelector(({ households }: ApplicationState) => households.householdData.fetchingSleepData)
  const zonesGraphDataByZone = householdV2 && householdV2.sleepData && householdV2.sleepData.data || [];

  const dispatch = useDispatch()

  useEffect(() => {

    dispatch(callAPI(SLEEP_GRAPH_DATA({ householdId: householdV2.householdId })))

  }, [])

  return(
    <div>
      { isFetching ?
        <LoadingRow /> :
        zonesGraphDataByZone.map(data => {

          return(<RowWithAvatar data={data} household={householdV2} isFetching={isFetching} />)

        })
      }
    </div>
  )
}