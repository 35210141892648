/* eslint-disable no-restricted-globals */
import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import './AppErrorOverlayView.scss'


import { b2cPolicies } from '../../../msal/config'

import background from '../../../static/background.jpg'

export class AppErrorOverlayView extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {

    return (
      <div style={styles.container}>
        <Grid container direction="column" justifyContent="center" alignContent="center" alignItems="center">

          <Grid item style={styles.textContainer}>

            <Typography style={{ paddingBottom: 25 ,...styles.textColor }} variant="h3">Looks like something went wrong</Typography>

            <Typography style={styles.textColor} variant="body1">
                Please contact InteliCare support on <a href='tel:1300001145'>1300 001 145</a><br />
                or via email <a href='mailto:support@intelicare.com.au'>support@intelicare.com.au</a>
            </Typography>

            <Typography style={styles.textColor} variant="body1">
              <a onClick={() => {
                window.location.href = b2cPolicies.authorities.logout.authority
              }} href="#" >Alternatively click here to logout</a><br />
                or<br />
              <a href="#" onClick={() => location.reload() }>Click here to refresh the page</a>
            </Typography>

          </Grid>

        </Grid>
      </div>
    )
  }
}

AppErrorOverlayView.propTypes = {
  onLogout: PropTypes.func,
}

const styles = {
  container: {
    backgroundImage: `url(${background})`,
    minHeight: "100vh",
  },
  textColor: {
    color: "white",
    alignSelf: 'center',
    textDecoration: "none",
  },
  textContainer: {
    textAlign: 'center',
    padding: 100,
  },
}

export default AppErrorOverlayView
