import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { timeRanges } from './HealthGraph'

const TimeScaleSelector = ({ onItemClick, currentTimeRange }) =>
{
  TimeScaleSelector.propTypes = {
    onItemClick: PropTypes.func,
    currentTimeRange: PropTypes.number,

  }
  return(
    <Grid container justify="center">

      <ButtonGroup size="small" color="primary" style={styles.buttonGroup}>
        <Button
          onClick={() => onItemClick(timeRanges.TIME_RANGE_1_HOUR)}
          variant={currentTimeRange.value === timeRanges.TIME_RANGE_1_HOUR.value && "contained"}>Last Hour</Button>
        <Button
          onClick={() => onItemClick(timeRanges.TIME_RANGE_24_HOURS)}
          variant={currentTimeRange.value === timeRanges.TIME_RANGE_24_HOURS.value && "contained"}>24 h</Button>
        <Button
          onClick={() => onItemClick(timeRanges.TIME_RANGE_7_DAYS)}
          variant={currentTimeRange.value === timeRanges.TIME_RANGE_7_DAYS.value && "contained"}>7 d</Button>
        <Button
          onClick={() => onItemClick(timeRanges.TIME_RANGE_30_DAYS)}
          variant={currentTimeRange.value === timeRanges.TIME_RANGE_30_DAYS.value && "contained"}>30 d</Button>
        <Button
          onClick={() => onItemClick(timeRanges.TIME_RANGE_6_MONTHS)}
          variant={currentTimeRange.value === timeRanges.TIME_RANGE_6_MONTHS.value && "contained"}>6 m</Button>
      </ButtonGroup>
    </Grid>
  )
}

const styles = {
  buttonGroup: {
    marginTop: 10,
  },
  selectedStyle: {
    fontWeight: 600,
  },
}
export { TimeScaleSelector }