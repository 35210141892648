import React, { useEffect, useState } from 'react'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import { BehavioursGrid } from '../cards/Trends/BehavioursGrid'
import { BehavioursGraphApi, BehavioursGraphData } from "../../../../api/BehavioursGraphApi";
import Skeleton from 'react-loading-skeleton';

import moment from 'moment'

const skeletonGraphs = [1, 2, 3, 4, 5 ,6, 7, 8, 9, 10, 11, 12]
interface TrendsTabProps
{
  token: string;
  householdId: string;
}

export const TrendsTab = ({ token, householdId }: TrendsTabProps) =>
{
  const [ behavioursGraphData, setBehavioursGraphData ] = useState<BehavioursGraphData>({ data: [] })
  const [ isGettingGraphs, setIsGettingGraphs ] = useState(true);
  useEffect(() => {

    getGraphData()

  }, [])

  const getGraphData = async () =>
  {
    // Get the graph data
    const graphApi = new BehavioursGraphApi(token)
    const graphs = await graphApi.getDataForHousehold(householdId, moment().subtract(30, "days").valueOf(), "ASC")
    if(graphs) setBehavioursGraphData(graphs)

    setIsGettingGraphs(false)
  }
  return(<div>
    <Card>
      {isGettingGraphs ? <LoadingGroup /> :
        <BehavioursGrid timestampFormat="M" graphs={behavioursGraphData}/>}
    </Card>
  </div>)
}

const LoadingGroup = () =>
{
  return(
    <div style={styles.loadingContainer}>
      <Grid container spacing={3}>
        {skeletonGraphs.map((i, key) => {
          return (<Grid key={key} item xs={12} md={6} lg={6} xl={6}>
            <Skeleton height={250} style={styles.skeletonItem} />
          </Grid>)
        })}
      </Grid>
    </div>
  )
}

const styles = {
  skeletonItem: {
    margin: 12,
  },
  loadingContainer: {
    marginRight: 28 as number,
  },
}