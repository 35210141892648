import React, { Component } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import TextField from '@material-ui/core/TextField'
import { InstallerStep } from '../../../../model/InstallerStep';

interface InstallerStepsTableProps
{
  installerSteps: InstallerStep[];
  onDelete: (id: number) => void;
  handleTextFieldChange: (stepId: number, columnName: string, value: string) => void
}

export class InstallerStepsTable extends Component<InstallerStepsTableProps>
{
  render()
  {

    const { installerSteps, onDelete, handleTextFieldChange } = this.props;
    return(
      <TableContainer component={Paper}>
        <Table aria-label="parameters table">
          <TableHead>
            <TableRow>
              <TableCell>Step Description</TableCell>
              <TableCell>Image URL</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {installerSteps.map((row) => (
              <TableRow hover key={row.id}>

                <TableCell component="th" scope="row">
                  <TextField fullWidth onChange={(e) => handleTextFieldChange(row.id, "instruction", e.target.value)} value={row.instruction} placeholder="Instruction Step" />
                </TableCell>

                <TableCell >
                  <TextField onChange={(e) => handleTextFieldChange(row.id, "imageUrl", e.target.value)} value={row.imageUrl} placeholder="Step Image URL"/>
                </TableCell>


                <TableCell align="center">
                  <IconButton onClick={() => onDelete(row.id)}><DeleteIcon /></IconButton>
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}