import { connect } from 'react-redux'
import ActivityGraphView from '../components/ActivityGraphView'
import sharedStore from '../../../redux'
const getActivityGraphData = sharedStore.householdsV2Selector.getActivityGraphData
const getActiveHousehold = sharedStore.householdsV2Selector.getActiveHousehold
const callAPI = sharedStore.userStore.callAPI

const ACTIVITYGRAPH = sharedStore.apiEndpoints.ACTIVITYGRAPH


const mapDispatchToProps = (dispatch) => {
  return {
    fetchActivityGraphData: (householdId, period) => {
      dispatch(callAPI(ACTIVITYGRAPH({
        timePeriod: period,
        householdId,
      })))
    },
  }
}

const mapStateToProps = (state) => ({
  data: getActivityGraphData(state).data,
  isFetching: getActivityGraphData(state).isFetching,
  household: getActiveHousehold(state).data,
})

export default connect(mapStateToProps, mapDispatchToProps)(ActivityGraphView)
