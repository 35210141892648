
/**
 * Full spec for default theme properties
 * https://material-ui.com/customization/default-theme/
 */

import { createTheme } from '@material-ui/core/styles'

export const brandPurple = {
  light: '#63318b',
  main: '#592c7d',
  dark: '#4f276f',
  contrastText: '#ffffff',
};

export const brandPink = {
  light: '#F02382',
  main: '#d82075',
  dark: '#c01c68',
  contrastText: '#ffffff',
}

export const alertRed = {
  light: "#F44336",
  main: "#E53935",
  dark: "#D32F2F",
  contrastText: "#ffffff",
};

export const warningOrange = {
  light: "#FF9800",
  main: "#FB8C00",
  dark: "#F57C00",
  contrastText: "#000000",
};

export const okayGreen = {
  light: "#8BC34A",
  main: "#7CB342",
  dark: "#689F38",
  contrastText: "#000000",
};

export const infoBlue = {
  light: "#19b5b8",
  main: "#00adb0",
  dark: "#009c9e",
};


export const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: 'Source Sans Pro, sans-serif',
    // fontFamily: 'Quicksand, sans-serif',
    // fontSize: 14, //default
    h3: {
      fontSize: '24px',
    },
  },
  overrides: {
    // MuiAppBar: {
    //   root: {
    //     height: '64px',
    //   },
    // },
    MuiDialogTitle: {
      root: {
        background: '#592c7d',
        color: '#ffffff',
      },
    },
    MuiGridListTile: {
      root: {
        listStyle: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        color: 'black',
      },
    },
  },
  palette: {
    type: 'light',
    primary: brandPurple,
    secondary: brandPink,
    background: {
      paper: '#ffffff',
    },
    alert: alertRed,
    warning: warningOrange,
    okay: okayGreen,
    info: infoBlue,
  },
  mainMenu: {
    text: {
      fontSize: '14px',
    },
  },
  graphColors: {
    setA: [
      "#18375f",
      "#5f4018",
      "#00adb0",
      "#4e698f",
      "#906f43",
      "#006f6c",
      "#122747",
      "#00433e",
      "#442504",
    ],
    // setB: [ //Colours based on Martian Colour Wheel (http://warrenmars.com/visual_art/theory/colour_wheel/colour_wheel.html)
    //   "#ff0000", // Red
    //   "#00b2b2", // Dark Cyan
    //   "#96cc00", // Celery
    //   "#aa00ff", // Violet
    //   "#ccaa00", // Olive Oil
    //   "#006eff", // Delphinium Blue
    //   "#00b551", // Clover
    //   "#ff00cc", // Shocking Pink
    //   "#0099b8", // Blue Topaz
    //   "#ff8000", // Orange
    //   "#d400ff", // Purple Daisy
    //   "#6cc400", // Basil
    //   "#4c58ff", // Light Blue
    //   "#c4c400", // Wasabi
    //   "#ff00aa", // Dark Pink
    //   "#00b277", // Light Emerald
    //   "#d99800", // Yellow Ochre
    //   "#0077b2", // Dark Azure
    //   "#00bf00", // Green
    //   "#a600a6", // Dark Magenta
    //   "#b6c700", // Green Grape
    //   "#9153ff", // Dark Lavender,
    //   "#00b89f", // Shallow Sea Green
    //   "#ff0073", // Dragon Fruit
    // ],
    setB: [ // Colours based on Material UI (https://materialui.co/colors)
      "#D81B60", // Pink
      "#3949AB", // Indigo
      "#00ACC1", // Cyan
      "#7CB342", // Light Green
      "#FFB300", // Amber
      "#E53935", // Red
      "#8E24AA", // Deep Purple
      "#039BE5", // Light Blue
      "#43A047", // Green
      "#FDD835", // Yellow
      "#F4511E", // Deep Orange
      "#8E24AA", // Purple
      "#1E88E5", // Blue
      "#00897B", // Teal
      "#C0CA33", // Lime
      "#FB8C00", // Orange
    ],
    // setB: [
    //   "#D2233B", // red
    //   "#F4763B", // orange
    //   "#94CC79", // light green
    //   "#005879", // navy
    //   "#34115A", // purple
    //   "#e0ca5d", // yellow
    //   "#3bc0f4", // light blue
    //   "#cc7995", // plum
    //   "#008000", // dark green
    //   "#add8e6", // blue-grey
    //   "#b5651d", // brown
    //   "#b19cd9", // blue-purple
    //   "#98fb98", // mint
    //   "#e9967a", // salmon
    //   "#808000", // olive
    //   "#0000cd", // blue
    //   "#7f0000", // maroon
    //   "#bababa", // grey
    //   "#2e8b57", // sea green
    //   "#ff1493", // pink
    // ],
  },
})
