import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography'
// @ts-ignore
import { BEHAVIOUR_STATUS } from '@intelicare/shared/enums'

import InteliIcon from '../../../../components/InteliIcon'
import { HealthMetricsInfoView } from './HealthMetricsInfoView'

import { colors } from '../../../../redux'
const { colorForStatusValue } = colors

type Props = {
  healthMetricsStatus: string,
  household: any,
}



class HealthMetricsTooltipView extends Component<Props> {

  render() {
    const {
      household,
      healthMetricsStatus,
    } = this.props

    const statusColor = colorForStatusValue(healthMetricsStatus || BEHAVIOUR_STATUS.UNKNOWN)

    return (
      <div style={componentStyles.ToolTip}>
        {/* @ts-ignore */}
        <div style={componentStyles.headingContainer}>
          <InteliIcon name="home" family="awesome" size={24} style={componentStyles.headingIcon}/>
          <Typography variant='h6'>{household.friendlyName}</Typography>
        </div>
        {/* @ts-ignore */}
        <div style={componentStyles.LoadingData}>
          {healthMetricsStatus !== BEHAVIOUR_STATUS.UNKNOWN && healthMetricsStatus !== "DISABLED" && household.healthMetricsRollup &&
            <div style={ componentStyles.CardContent }>
              {this.getHealthMetrics()}
            </div>
          }

          {(healthMetricsStatus === BEHAVIOUR_STATUS.UNKNOWN || healthMetricsStatus === "DISABLED" || !household.healthMetricsRollup) &&
            // @ts-ignore
            <div style={componentStyles.NoData}>
              <div style={componentStyles.NoDataIcon}>
                <InteliIcon
                  name='favorite'
                  family='material'
                  color={statusColor}
                  size={30}
                />
              </div>
              <Typography variant="overline">No data to display</Typography>
            </div>
          }
        </div>
      </div>
    )
  }

  getHealthMetrics() {
    const {
      household,
    } = this.props

    return Object.entries(household.healthMetricsRollup).map(([metric, data]: [string, any]) => {
      return <HealthMetricsInfoView key={metric + "_info"} metricFriendlyName={metric} metricStatus={data.status}
        metricTimestamp={data.timestamp}/>
    })
  }
}

const componentStyles = {
  headingContainer: {
    flexDirection: 'row',
    display: 'flex',
    padding: 6,
  },
  headingIcon: {
    color: "grey",
    marginRight: 6,
    paddingLeft: 12,
    paddingTop: 3,
  },
  ToolTip: {
    width: '310px',
  },
  LoadingData: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  CardContent: {
    paddingTop: 0,
    paddingBottom: 0,
    width: '90%',
    alignItems: 'center',
  },
  NoData: {
    display: 'flex',
    flexDirection: 'row',
  },
  NoDataIcon: {
    marginRight: '12px',
  },
}

export default HealthMetricsTooltipView
