import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Badge from '@material-ui/core/Badge'
import InteliIcon from '../../../../components/InteliIcon'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import sharedStore from '../../../../redux'
const {
  alert,
  amber,
  okay,
} = sharedStore.colors

export class NotificationIcon extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  render() {
    const {
      alertCount,
      notificationCount,
      classes,
      onClick,
    } = this.props

    const notificationTotal = (alertCount + notificationCount)
    const iconColor = (() => {
      if (alertCount > 0) return "error"
      if (notificationCount > 0) return "secondary"
      return "primary"
    })()

    return (
      <div
        style={{ marginRight: 16, alignContent: 'center' }}
        onClick={() => { onClick("notifications") }}
      >
        <Tooltip title="Shows combined alerts and notifications that are active in the last month">
          <Badge
            badgeContent={notificationTotal}
            color={iconColor}
            classes={{
              colorPrimary: classes.colorPrimary,
              colorSecondary: classes.colorSecondary,
              colorError: classes.colorError,
            }}
          >
            <InteliIcon
              name={notificationTotal > 0 ? 'notification_important' : 'notifications'}
              family='material'
              color='black'
            />
          </Badge>
        </Tooltip>

      </div>
    )
  }

}

NotificationIcon.propTypes = {
  alertCount: PropTypes.number,
  notificationCount: PropTypes.number,
  classes: PropTypes.object,
  onClick: PropTypes.func,
}

const styles = theme => ({
  colorPrimary: {
    backgroundColor: okay,
  },
  colorSecondary: {
    backgroundColor: amber,
  },
  colorError: {
    backgroundColor: alert,
  },
})

export default withStyles(styles)(NotificationIcon)
