import { connect } from 'react-redux'
import HealthGraph from './HealthGraph'

import { apiEndpoints } from '../../../../../../redux'
import { callAPI } from '../../../../../../redux/domains/user/userStore'
import access from 'safe-access'

import sharedStore from '../../../../../../redux'
const getActiveHousehold = sharedStore.householdsV2Selector.getActiveHousehold

const { GET_HEALTH_METRICS } = apiEndpoints
const mapStateToProps = state =>
{
  return {
    healthMetrics: state.healthMetrics,
    activeHouseholdId: state.householdsV2.activeHouseholdId,
    isGettingGraphs: access(state, 'healthMetrics.isGettingGraphs'),
    household: getActiveHousehold(state).data,
  }
}

const mapDispatchToProps = dispatch =>
{
  return {
    getGraph: (params) => dispatch(callAPI(GET_HEALTH_METRICS(params))),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HealthGraph)