import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import Title from '../../../components/Title'

import TabHeader from './TabHeader'

import ResellerTabContainer from './TabScreens/ResellersTab/ResellerTabContainer'
import HouseholdTabContainer from './TabScreens/HouseholdsTab/HouseholdTabContainer'

import { appInsights } from "../../../api/AppInsights";


// Tabs
const TAB_INDEX_RESELLERS = 0
const TAB_INDEX_HOUSEHOLDS = 1

class Resellers extends Component {

  state = {
    currentTab: TAB_INDEX_RESELLERS,
  }

  onTabChange = (tabIndex) => {
    this.setState({ currentTab: tabIndex })
  }

  componentDidMount()
  {
    appInsights.trackPageView({ name: "Partners" })
  }

  render() {

    const { currentTab } = this.state

    return (<div className="col-main-content" style={styles.container}>
      <DocumentTitle title='InteliCare Partners' />
      <Title title="Partner Admin"></Title>

      {/* Tab Headers */}
      <TabHeader currentTab={this.state.currentTab} onTabChange={(e, newValue) => this.onTabChange(newValue)} />

      {currentTab === TAB_INDEX_RESELLERS &&
        <ResellerTabContainer />
      }

      {currentTab === TAB_INDEX_HOUSEHOLDS &&
        <HouseholdTabContainer />
      }


    </div>)
  }
}

const styles = {
  container: {
    margin: 25,
  },
}

export default Resellers