import React from 'react'
import { BEHAVIOUR_TYPES } from "@intelicare/shared/enums";
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box';


export const AlertingDescription = {
  [BEHAVIOUR_TYPES.GENERAL_ACTIVITY]: [
    <div key={0}>
      <Typography color="textSecondary" variant="subtitle2" style={{ whiteSpace: 'pre-line', paddingTop: 5 }}>
        Day time activity will be set to <Box fontWeight="bold" display="inline">WARNING</Box> and a notification will be sent out when:{"\n"}
        - day time activity was 50% or more lower than normal yesterday{"\n"}
        - day time activity was lower than normal for 3 out of the last 5 days{"\n"}
        - day time activity was lower than normal for the last 2 days in a row
      </Typography>
      <Typography color="textSecondary" variant="subtitle2" style={{ whiteSpace: 'pre-line', paddingTop: 5 }}>
        Day time activity will be set to <Box fontWeight="bold" display="inline">ALERT</Box> and a notification will be sent out when:{"\n"}
        - day time activity was lower than normal for the last 3 days in a row
      </Typography>
    </div>,
  ],
  [BEHAVIOUR_TYPES.MEAL_PREP]: [
    <div key={0}>
      <Typography color="textSecondary" variant="subtitle2" style={{ whiteSpace: 'pre-line', paddingTop: 5 }}>
        A meal prep <Box fontWeight="bold" display="inline">WARNING</Box> notification will be sent out when:{"\n"}
        - No meal preparation activity has been detected for the pre-configured time limit{"\n"}
        - Meal preparation activity is 50% lower than normal for 2 consecutive days or 3 out of the last 5 days
      </Typography>
      <Typography color="textSecondary" variant="subtitle2" style={{ whiteSpace: 'pre-line', paddingTop: 5 }}>
        A meal prep <Box fontWeight="bold" display="inline">ALERT</Box> notification will be sent out when:{"\n"}
        - Meal preparation activity is 50% lower than normal for 3 consecutive days
      </Typography>
      <Typography color="textSecondary" variant="subtitle2" style={{ whiteSpace: 'pre-line', paddingTop: 5 }}>
        If the notification is acknowledged, the timers used to calculate the triggers are reset
      </Typography>
    </div>,
  ],
  [BEHAVIOUR_TYPES.NIGHT_TIME_ACTIVITY]: [
    <div key={1}>
      <Typography color="textSecondary" variant="subtitle2" style={{ whiteSpace: 'pre-line', paddingTop: 5 }}>
        A night-time activity <Box fontWeight="bold" display="inline">INFO</Box> notification will be sent out when:{"\n"}
        - Night-time activity is above the normal range for a single night
      </Typography>
      <Typography color="textSecondary" variant="subtitle2" style={{ whiteSpace: 'pre-line', paddingTop: 5 }}>
        A night-time activity <Box fontWeight="bold" display="inline">WARNING</Box> notification will be sent out when:{"\n"}
        - Night-time activity is above the normal range for 2 consecutive nights or 3 out of the last 5 nights
      </Typography><Typography color="textSecondary" variant="subtitle2" style={{ whiteSpace: 'pre-line', paddingTop: 5 }}>
        A night-time activity <Box fontWeight="bold" display="inline">ALERT</Box> notification will be sent out when:{"\n"}
        - Night-time activity is above the normal range for 3 consecutive nights
      </Typography>
      <Typography color="textSecondary" variant="subtitle2" style={{ whiteSpace: 'pre-line', paddingTop: 5 }}>
        If the notification is acknowledged, the timers used to calculate the triggers are reset
      </Typography>
    </div>,
  ],
  [BEHAVIOUR_TYPES.BATHROOM_VISIT]: [
    <div key={2}>
      <Typography color="textSecondary" variant="subtitle2" style={{ whiteSpace: 'pre-line', paddingTop: 5 }}>
        A bathroom activity <Box fontWeight="bold" display="inline">INFO</Box> notification will be sent out when:{"\n"}
        - Bathroom activity for last night is higher than normal
      </Typography>
      <Typography color="textSecondary" variant="subtitle2" style={{ whiteSpace: 'pre-line', paddingTop: 5 }}>
        A bathroom activity <Box fontWeight="bold" display="inline">WARNING</Box> notification will be sent out when:{"\n"}
        - Bathroom activity is higher than normal for 2 consecutive nights or 3 out of the last 5 night
      </Typography>
      <Typography color="textSecondary" variant="subtitle2" style={{ whiteSpace: 'pre-line', paddingTop: 5 }}>
        If the notification is acknowledged, the 5 day counter will be set to 0
      </Typography>
    </div>,
  ],
}
