import React, { Component } from 'react'
import PropTypes from 'prop-types'
import access from 'safe-access'

import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'

import InteliIcon from '../../../../components/InteliIcon'

import styles from './SystemHealthStyles'

import { SENSOR_STATUS } from '@intelicare/shared/enums'
import { helper, colors } from '../../../../redux'

const { friendlyDateTime } = helper
const { colorForStatusValue } = colors

const SENSOR_STATUS_REASONS = {
  'ok': 'Okay',
  'notSeen': 'No data from sensor for a long time',
  'lowBattery': 'Sensor has low battery',
  'neverSeen': 'Sensor has never checked in',
}

class SensorInfoView extends Component {

  render() {
    const {
      timezone,
      classes,
    } = this.props

    let {
      data,
    } = this.props

    const statusColor = colorForStatusValue(access(data, 'status'))

    if (!data.status) data.status = SENSOR_STATUS.UNKNOWN
    if (data.status === SENSOR_STATUS.UNKNOWN)
      data.statusReason = SENSOR_STATUS_REASONS.neverSeen

    return (
      <div className={classes.SensorInfo}>
        <div className={classes.SensorInfoHeader}>
          <InteliIcon
            name='clipboard-pulse'
            family='community'
            color={statusColor}
            size={30}
          />
          <Typography>{data.friendlyName}</Typography>
        </div>
        <div className={classes.SensorInfoBody}>
          {data.lastSeen &&
            <Typography color='textSecondary'>{'Last seen: ' + friendlyDateTime(data.lastSeen, timezone)} </Typography>
          }
          <Typography gutterBottom classes={{ gutterBottom: classes.extraGutter }}>
            <span style={{ backgroundColor: statusColor }} className={classes.StatusBadge}>{data.status} </span>
            {SENSOR_STATUS_REASONS[data.statusReason] || data.statusReason}
          </Typography>
        </div>
      </div>
    )
  }

  static propTypes = {
    data: PropTypes.any.isRequired,
    timezone: PropTypes.string.isRequired,
    classes: PropTypes.any.isRequired,
  }
}

export default withStyles(styles)(SensorInfoView)
