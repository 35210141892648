import React, { Component } from 'react'
import PropTypes from 'prop-types'

const DataContext = React.createContext()

export class DataProvider extends Component {
  constructor(props) {
    super(props)
    const setData = (data) => {
      this.setState({ ...this.state, refs: { ...this.state.refs, ...data } })
    }
    this.state = {
      setData,
      refs: {},
    }
  }
  render() {
    const { children } = this.props
    return (
      <DataContext.Provider value={this.state}>
        {children}
      </DataContext.Provider>
    )
  }
}

DataProvider.propTypes = {
  children: PropTypes.any,
}

export const DataConsumer = DataContext.Consumer
