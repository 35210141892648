import React, { useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { purple } from '@material-ui/core/colors';
import { getInitials } from '../utils/getInitials';
import { AssignedRolesList } from './AssignedRolesList';
import { RolesListModal } from '../RolesListModal';
import { useDispatch, useSelector } from 'react-redux';
import { addRoleBegin, addRoleClear, removeRoleBegin, removeRoleClear } from '../../../../../redux/domains/organisations/actionCreators';
import { ApplicationState } from '../../../../../interfaces/reduxState';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SnackAlert } from '../../Shared/SnackAlert';
import { ConfirmDelete } from '../../../../../components/ConfirmDelete';
import { UserDetails } from '../../../../../api/OrganisationApi';

interface EditUserRoleModalProps
{
  handleClose: () => void;
  open: boolean;
  parentOuId: string;
  userId: string;
  user: UserDetails;
  currentRoles: string[];
}

export const EditUserRoleModal = ({ handleClose, open, parentOuId, currentRoles, userId, user }: EditUserRoleModalProps) =>
{
  const [ roles, setRoles ] = useState<string[]>(currentRoles)
  const [ showRolesListModal, setShowRolesListModal ] = useState(false);
  const [ showConfirmRemoveRoleModal, setShowConfirmRemoveRoleModal ] = useState(false);
  const [ currentRole, setCurrentRole ] = useState("");

  const isFetching = useSelector(({ organisations }: ApplicationState) => organisations.isFetching )

  const addRoleSuccess = useSelector(({ organisations }: ApplicationState) => organisations.addRoleSuccess )
  const addRoleError = useSelector(({ organisations }: ApplicationState) => organisations.addRoleError )

  const removeRoleSuccess = useSelector(({ organisations }: ApplicationState) => organisations.removeRoleSuccess )
  const removeRoleError = useSelector(({ organisations }: ApplicationState) => organisations.removeRoleError )

  const dispatch = useDispatch();

  const handleExit = () =>
  {
    dispatch(removeRoleClear())
    dispatch(addRoleClear())
  }

  const handleRoleSelect = (role: string) =>
  {
    setRoles(roles.concat(role))
    setShowRolesListModal(false)
  }

  const handleRoleRemoveSelection = (role: string) =>
  {
    setCurrentRole(role)
    setShowConfirmRemoveRoleModal(true)
  }

  const handleRemoveRole = () =>
  {
    // Hide the confirm modal
    setShowConfirmRemoveRoleModal(false)
    // Filter in UI
    setRoles(roles.filter(r => r !== currentRole))
    // dispatch remove role
    dispatch(removeRoleBegin(parentOuId, userId, [currentRole]))
  }

  const handleSave = () => dispatch(addRoleBegin(parentOuId, userId, roles))

  return(
    <Dialog
      TransitionProps={{
        onExit: handleExit,
      }}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}>
      <DialogTitle>Edit user roles assignment for this household</DialogTitle>
      <DialogContent>

        {/* User email input  */}
        <Grid container direction="row" alignItems="center" spacing={3}>

          <Grid item>
            <IconButton>
              <Avatar style={styles.avatar}>{getInitials(user ? user.firstName + " " + user.lastName : "")}</Avatar>
            </IconButton>
          </Grid>

          <Grid item style={{ flex: 1 }}>
            <TextField
              value={user && user.email}
              autoFocus
              id="Email"
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
            />
          </Grid>

        </Grid>


        <AssignedRolesList
          onAssignRole={() => setShowRolesListModal(true)}
          onItemRemove={(role) => handleRoleRemoveSelection(role)}
          roles={roles}
          // @ts-ignore
          selectedItem={"something"} />


        {/* Assign Roles Modal */}
        <RolesListModal
          onRoleSelect={(role) => handleRoleSelect(role)}
          handleClose={() => setShowRolesListModal(false)}
          open={showRolesListModal}
        />

        <ConfirmDelete
          onConfirmAction={() => handleRemoveRole()}
          content="Are you sure you want to remove this role?"
          title="Remove Role"
          open={showConfirmRemoveRoleModal}
          handleClose={() => setShowConfirmRemoveRoleModal(false)}
        />

        <SnackAlert open={addRoleSuccess ? true : false } onClose={() => dispatch(addRoleClear())} alertMsg={addRoleSuccess} color="success" />
        <SnackAlert open={addRoleError ? true : false } onClose={() => dispatch(addRoleClear())} alertMsg={addRoleError} color="error" />

        <SnackAlert open={removeRoleSuccess ? true : false } onClose={() => dispatch(removeRoleClear())} alertMsg={removeRoleSuccess} color="success" />
        <SnackAlert open={removeRoleError ? true : false } onClose={() => dispatch(removeRoleClear())} alertMsg={removeRoleError} color="error" />


      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button endIcon={isFetching && <CircularProgress size={16} />} onClick={handleSave}>{isFetching ? `Updating...` : "Update"}</Button>
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  avatar: {
    width: 100,
    height: 100,
    backgroundColor: purple[500],
    fontSize: 32,
  },
}