import React, { useState } from 'react';
// @ts-ignore
import DocumentTitle from 'react-document-title'

// Custom components
import Icon from "@material-ui/core/Icon";
import Title from '../../../../components/Title/Title'
import { Overview } from './Overview'
import { TabView } from './TabView'
import { UsersList } from '../OrganisationUsersList'
import { HouseholdList } from '../OrganisationHouseholdList'
import { ZoomFabs } from './ZoomFabs'
import { CreateOrganisationModal } from '../Modals/CreateEditOrganisation/CreateOrganisationModal'
import { CreateUserModal } from '../Modals/CreateEditUserModal/CreateUserModal'
import { EditUserModal } from '../Modals/CreateEditUserModal/EditUserModal'
import ToolbarButton from "../../../../components/ToolbarButton";
import { HouseholdListModal } from '../Modals/HouseholdListModal';
import { OrgStructureList } from '../OrganisationStructureList';

import { useDispatch } from 'react-redux';
import { Organisation } from '../../../../api/OrganisationApi';
import { addHouseholdToOrganisationBegin, addHouseholdToOrganisationClear, deleteOrganisationBegin, deleteOrganisationClear, removeUserFromOrganisationClear } from '../../../../redux/domains/organisations/actionCreators';
import { SnackAlert } from '../Shared/SnackAlert';
import { EditOrganisationModal } from '../Modals/CreateEditOrganisation/EditOrganisationModal';
import { useOrganisationDetails } from './hooks/useOrganisationDetails';
import { UserRoleAssignModal } from '../Modals/CreateEditUserModal/UserRoleAssignModal';
import { ConfirmDelete } from '../../../../components/ConfirmDelete';
// Used on handleRedirect
import { history } from '../../../../hoc/CustomRouter';

export const OrganisationDetailsView = () => {
  const [tab, setTab] = useState(0);
  const [openHouseholdModal, setOpenHouseholdModal] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openOrganisationModal, setOpenOrganisationModal] = useState(false);
  const [openEditOrganisationModal, setOpenEditOrganisationModal] = useState(false);
  const [openUserRoleModal, setOpenUserRoleModal] = useState(false);
  const [currentSelectedNode, setCurrentSelectedNode] = useState("")
  const [openConfirmDeleteAccessGroupModal, setOpenConfirmDeleteAcessGroupModal] = useState(false);

  const [editingUser, setEditingUser] = useState("");
  const [showConfirmRemoveHouseholdModal, setShowConfirmRemoveHouseholdModal] = useState(false);

  const [
    organisationDetails,
    organisations,
    isAddingHouseholdToOrganisation,
    successAddingHouseholdToOrganisation,
    errorAddingHouseholdToOrganisation,
    isRemovingUserFromOrganisation,
    removeUserFromOrganisationSuccess,
    removeUserFromOrganisationError,
    isDeleteNode,
    deleteNodeSuccess,
    deleteNodeError,
  ] = useOrganisationDetails();

  const dispatch = useDispatch();

  const handleOpenModal = (index: number, payload: any) => {
    if (index === 0) return setOpenOrganisationModal(true);
    if (index === 1) return setOpenCreateUserModal(true);
    if (index === 2) return setOpenHouseholdModal(true);
    if (index === 3) return setOpenEditOrganisationModal(true);
    if (index === 4) {
      setEditingUser(payload);
      setOpenUserModal(true);
      return;
    }
  }

  const handleAddHousehold = (household: Organisation) => {
    dispatch(addHouseholdToOrganisationBegin(currentSelectedNode || organisationDetails.id, household))
    setOpenHouseholdModal(false)
  }

  const handleAddFacility = (ouId: string) => {
    setCurrentSelectedNode(ouId)
    setOpenOrganisationModal(true)
  }

  const handleAddHouseholdAtNode = (ouId: string) => {
    setCurrentSelectedNode(ouId)
    setOpenHouseholdModal(true)
  }

  const handleAddUser = (ouId: string) => {
    console.log("openUserRoleModal", ouId)
    setOpenUserRoleModal(true);
    setCurrentSelectedNode(ouId);
  }

  const handleDeleteNode = (ouId: string, organisation?: Organisation) => {

    setCurrentSelectedNode(ouId);
    if (organisation && organisation.type === 'household') return setShowConfirmRemoveHouseholdModal(true)
    return setOpenConfirmDeleteAcessGroupModal(true);
  }

  /**
   * Redirects to a specific endpoint.
   * @param endpoint The endpoint to redirect to.
   */
  const handleRedirect = (endpoint: string): void => {
    if (endpoint) history.push(endpoint);
  }

  return (
    <div style={styles.container}>
      <DocumentTitle title={organisationDetails.friendlyName} />
      <Title
        title={organisationDetails.friendlyName}
        pageButtons={
          <ToolbarButton
            action={() => handleOpenModal(3, null)}
            label="Settings"
            icon={<Icon>edit</Icon>}
          />
        } />

      <Overview organisations={organisations} organisationDetails={organisationDetails} />

      <TabView currentTab={tab} onChange={(i: number) => setTab(i)} />

      {tab === 0 &&
        <OrgStructureList
          parentId={organisationDetails.id}
          organisations={organisations}
          addHousehold={(ouId) => handleAddHouseholdAtNode(ouId)}
          addFacility={(ouId) => handleAddFacility(ouId)}
          addUser={(ouId) => handleAddUser(ouId)}
          deleteNode={(ouId) => handleDeleteNode(ouId)}
        />}


      {tab === 1 && <UsersList users={organisationDetails.access} onUserClick={(userId) => handleOpenModal(4, userId)} />}
      {tab === 2 && <HouseholdList households={organisations} onHouseholdRemove={(household) => handleDeleteNode(household.id, household)} />}
      {/* 
      <ZoomFabs
        currentTab={tab}
        onFabClick={(index: number) => handleOpenModal(index, null)} /> */}

      {/* <CreateOrganisationModal
        parentOuId={currentSelectedNode || organisationDetails.id}
        open={openOrganisationModal}
        handleClose={() => setOpenOrganisationModal(false)} /> */}

      <UserRoleAssignModal
        open={openUserRoleModal}
        parentOuId={currentSelectedNode || organisationDetails.id}
        handleClose={() => setOpenUserRoleModal(false)}
      />

      <EditOrganisationModal
        organisationDetails={organisationDetails}
        open={openEditOrganisationModal}
        handleClose={() => setOpenEditOrganisationModal(false)} />

      <EditUserModal
        organisationDetails={organisationDetails}
        userId={editingUser}
        open={openUserModal}
        handleClose={() => setOpenUserModal(false)} />

      <CreateUserModal
        organisationDetails={organisationDetails}
        open={openCreateUserModal}
        handleClose={() => setOpenCreateUserModal(false)} />

      <HouseholdListModal
        title="Assign household to this organisation"
        onHouseholdSelect={household => handleAddHousehold(household)}
        open={openHouseholdModal}
        handleClose={() => setOpenHouseholdModal(false)} />

      <ConfirmDelete
        onConfirmAction={() => {
          dispatch(deleteOrganisationBegin(currentSelectedNode));
          setOpenConfirmDeleteAcessGroupModal(false);
        }}
        title="Confirm Remove Household"
        content="Do you want to remove this household from this organisation?"
        open={showConfirmRemoveHouseholdModal}
        handleClose={() => setShowConfirmRemoveHouseholdModal(false)}
      />

      <ConfirmDelete
        onConfirmAction={() => {
          dispatch(deleteOrganisationBegin(currentSelectedNode));
          setOpenConfirmDeleteAcessGroupModal(false);

          // If the node being deleted is equals to the parent id then redirects.
          // That means, we are not delting a child.
          if (currentSelectedNode === organisationDetails.id) handleRedirect("/organisations");
        }}
        title="Confirm Remove Access group"
        content="Do you want to remove this Access group?"
        open={openConfirmDeleteAccessGroupModal}
        handleClose={() => setOpenConfirmDeleteAcessGroupModal(false)}
      />

      <SnackAlert color="info" open={isAddingHouseholdToOrganisation} onClose={() => dispatch(addHouseholdToOrganisationClear())} alertMsg="Adding household to organisation" />
      <SnackAlert color="success" open={successAddingHouseholdToOrganisation !== "" ? true : false} onClose={() => dispatch(addHouseholdToOrganisationClear())} alertMsg={successAddingHouseholdToOrganisation} />
      <SnackAlert color="error" open={errorAddingHouseholdToOrganisation !== "" ? true : false} onClose={() => dispatch(addHouseholdToOrganisationClear())} alertMsg={errorAddingHouseholdToOrganisation} />

      <SnackAlert color="info" open={isRemovingUserFromOrganisation} onClose={() => dispatch(removeUserFromOrganisationClear())} alertMsg="Removing user from organisation" />
      <SnackAlert color="success" open={removeUserFromOrganisationSuccess !== "" ? true : false} onClose={() => dispatch(removeUserFromOrganisationClear())} alertMsg={removeUserFromOrganisationSuccess} />
      <SnackAlert color="error" open={removeUserFromOrganisationError !== "" ? true : false} onClose={() => dispatch(removeUserFromOrganisationClear())} alertMsg={removeUserFromOrganisationError} />

      <SnackAlert color="info" open={isDeleteNode} onClose={() => dispatch(deleteOrganisationClear())} alertMsg="Removing organisation" />
      <SnackAlert color="success" open={deleteNodeSuccess !== "" ? true : false} onClose={() => dispatch(deleteOrganisationClear())} alertMsg={deleteNodeSuccess} />
      <SnackAlert color="error" open={deleteNodeError !== "" ? true : false} onClose={() => dispatch(deleteOrganisationClear())} alertMsg={deleteNodeError} />

    </div>
  )
}


const styles = {
  container: {
    marginLeft: 12,
    marginRight: 12,
    paddingTop: 12,
  },
}