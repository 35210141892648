import React from 'react'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment'
import Button from '@material-ui/core/Button'
import { ProvisioningGateway } from '../../../../api/GatewayApi';
import { getPillColor } from './util/getPillColor';
interface RowItemProps
{
  row: ProvisioningGateway;
  onDelete: (id: string) => void;
}

export const RowItem = ({ row, onDelete }: RowItemProps) =>
{

  const getExpiresTime = (checkinTime: string, ttlSeconds: string) =>
  {
    return moment(Number(checkinTime) * 1000).add(Number(ttlSeconds) * 1000).fromNow()
  }

  return(
    <TableRow hover key={row.id}>
      <TableCell component="th" scope="row">
        {row.gatewayid}
      </TableCell>
      <TableCell align="right">{row.provisioning.localipaddress}</TableCell>
      <TableCell align="right">{row.provisioning.version}</TableCell>
      <TableCell align="right"><TimeStampPill text={row._ts} color={getPillColor(row._ts)}/></TableCell>
      <TableCell align="right">{`Expires ${getExpiresTime(row._ts, row.ttl)}`}</TableCell>
      <TableCell align="right"><Button onClick={() => onDelete(row.id)} size="small" variant="outlined">Remove</Button></TableCell>
    </TableRow>
  )
}

interface TimeStampPillProps
{
  text: string;
  color: string;
}

const TimeStampPill = ({ text, color }: TimeStampPillProps) =>
{
  return(
    <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
      <p style={{ ...styles.pill, backgroundColor: color }}>
        { moment(Number(text) * 1000).fromNow() }
      </p>
    </div>

  )
}

const styles = {
  pill: {
    borderRadius: 12,
    padding: 6,
    width: 100,
    color: 'white',
    textAlign: 'center' as 'center',
  },
}