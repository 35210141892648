import React from 'react'
import PropTypes from 'prop-types'
import DocumentTitle from 'react-document-title'
import { Select, MenuItem, Button, CircularProgress } from '@material-ui/core'
import Title from '../../../../components/Title'
import ContentStrapline from '../../../../components/ContentStrapline'

const operations = [
  {
    label: 'Open Remote Admin',
    data: { service: 'remoteAdmin', data: { command: 'open' } },
  },
  {
    label: 'Close Remote Admin',
    data: { service: 'remoteAdmin', data: { command: 'close' } },
  },
  {
    label: 'Reboot',
    data: { service: 'remoteAdmin', data: { command: 'reboot' } },
  },
]

export default class RemoteCommandsView extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedGateway: "None",
      selectedOperation: "None",
    }

    this.handleGatewaySelection = this.handleGatewaySelection.bind(this)
    this.handleOperationSelection = this.handleOperationSelection.bind(this)
    this.handleExecution = this.handleExecution.bind(this)
  }

  componentDidMount() {
    const {
      fetchFirmwareData,
    } = this.props
    fetchFirmwareData()
  }

  handleGatewaySelection(evt) {
    this.setState(state => ({ ...state, selectedGateway: evt.target.value }))
  }

  handleOperationSelection(evt) {
    this.setState(state => ({ ...state, selectedOperation: evt.target.value }))
  }

  handleExecution(evt, blah) {
    const { selectedGateway, selectedOperation } = this.state
    const opIdx = operations.findIndex(o => o.label === selectedOperation)
    this.props.executeService(selectedGateway, operations[opIdx].data)
  }

  getOperationData(selectedOperation) {
    const opIdx = operations.findIndex(o => o.label === selectedOperation)
    if (opIdx === -1) return "None"
    return JSON.stringify(operations[opIdx].data)
  }

  render() {
    const { isFetchingGateways, gateways, remoteResult } = this.props
    const { selectedGateway, selectedOperation } = this.state

    const operationData = this.getOperationData(selectedOperation)

    return (
      <div className='households'>
        <DocumentTitle title='Developer Console' />

        <ContentStrapline
          title='Developer Console'
        />
        <Title title="Remote Commands" />
        <div style={styles.menuContainer}>
          <div style={styles.gatewayContainer}>
            Select Gateway
            <Select value={selectedGateway} onChange={this.handleGatewaySelection}>
              {
                isFetchingGateways &&
                <MenuItem key={1} value="Fetching gateways..."></MenuItem>
              }
              {
                !isFetchingGateways &&
                gateways &&
                gateways.map(h => {
                  return (
                    <MenuItem key={h.gatewayId} value={h.gatewayId}>{h.friendlyName}</MenuItem>
                  )
                })
              }
            </Select>
            <span style={styles.dataStyle}>{selectedGateway}</span>
          </div>
          <div style={styles.operationContainer}>
            Select Operation
            <Select value={selectedOperation} onChange={this.handleOperationSelection}>
              {
                operations.map((op, i) => (
                  <MenuItem key={`operation_${i}`} value={op.label}>{op.label}</MenuItem>
                ))
              }
            </Select>
            <span style={styles.dataStyle}>{operationData}</span>
          </div>
          <div style={styles.buttonContainer}>
            <Button onClick={this.handleExecution}>Execute</Button>
            {remoteResult.isFetching && <CircularProgress />}
          </div>
        </div>
        <div style={styles.responseContainer}>
          <div style={styles.responseBox}>
            Response
            <textarea style={styles.output} value={JSON.stringify(remoteResult.response, null, 4)} />
          </div>
          {remoteResult.error &&
            <div style={styles.responseBox}>
              Error Information
              <textarea style={styles.output} value={JSON.stringify(remoteResult.error, null, 4)} />
            </div>
          }
        </div>
      </div>
    )
  }
}

RemoteCommandsView.propTypes = {
  gateways: PropTypes.array,
  isFetchingGateways: PropTypes.bool,
  executeService: PropTypes.func,
  remoteResult: PropTypes.object,
  fetchFirmwareData: PropTypes.func,
}

const styles = {
  menuContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: "20px",
  },
  gatewayContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px',
    width: '400px',
  },
  operationContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px',
    width: '400px',
  },
  responseBox: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px',
    width: '100%',
  },
  responseContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: "20px",
  },
  buttonContainer: {
    marginLeft: '10px',
  },
  output: {
    width: '90%',
    height: '400px',
  },
  dataStyle: {
    margin: '10px',
    color: 'limegreen',
  },
}
