import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension';
import createSagaMiddleware from 'redux-saga'

import thunk from 'redux-thunk'
import { history } from '../hoc/CustomRouter'
import reducers from './reducers'
import sagas from './sagas'
import sharedStore from '../redux'

const updateLocation = sharedStore.locationStore.updateLocation

let sagaMiddleware = createSagaMiddleware()


export default (initialState = {}) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [thunk, sagaMiddleware]
  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================



  const store = createStore(
    reducers,
    initialState,
    composeWithDevTools(
      applyMiddleware(...middleware),
    )
  )

  store.asyncReducers = {}

  // To unsubscribe, invoke `store.unsubscribeHistory()` anytime
  store.unsubscribeHistory = history.listen(updateLocation(store))

  sagaMiddleware.run(sagas)

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default
      store.replaceReducer(reducers(store.asyncReducers))
    })
  }

  return store

}
