import ViewNotificationView from './ViewNotificationView'

import { connect } from 'react-redux'
import { history } from '../../hoc/CustomRouter'
import access from 'safe-access'
import { householdsV2Selector } from '../../redux'
const { getHouseholds } = householdsV2Selector


const mapDispatchToProps = () => {
  return {
    goToHousehold: (householdId) => {
      history.push(`/households/${householdId}`)
    },
  }
}

const mapStateToProps = (state) => ({
  isDeveloper: state.user.isDeveloper,
  isSupport: state.user.isSupport,
  path: access(state, 'location.pathname') ? access(state, 'location.pathname') : "/",
  getHousehold: (householdId) => {
    const households = getHouseholds(state)
    return households.find(h => h.householdId === householdId)
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewNotificationView)
