import React from 'react';
import { ListItem, ListItemText, Switch, Grid, Typography } from '@material-ui/core';
import TimeoutSlider from './TimeoutSlider';

const warningText = "If the incident timeout is set too low, you may receive premature alerts!";

interface IncidentDetectionConfig {
  discontinuousMotionEnabled: boolean;
  continuousMotionEnabled: boolean;
  discontinuousMotionTimeout: number;
  continuousMotionTimeout: number;
}

interface IncidentDetectionListItemProps {
  motionSensor: any;
  onListItemChange: (sensorId: string, incidentDetection: IncidentDetectionConfig) => void;
}

export default class IncidentDetectionListItem extends React.Component<IncidentDetectionListItemProps> {
  state = {
    discontinuousMotionEnabled: this.props.motionSensor.incidentDetection.discontinuousMotionEnabled,
    continuousMotionEnabled: this.props.motionSensor.incidentDetection.continuousMotionEnabled,
    discontinuousMotionTimeout: this.props.motionSensor.incidentDetection.discontinuousMotionTimeout,
    continuousMotionTimeout: this.props.motionSensor.incidentDetection.continuousMotionTimeout,
    secondaryText: "",
  };

  render() {
    const { motionSensor } = this.props;
    const { incidentDetection } = motionSensor;
    return <ListItem><Grid container spacing={3} alignItems={"center"}>
      <Grid item xs={4}>
        <ListItemText
          primary={motionSensor.friendlyname}
          secondary={this.state.secondaryText}
        />
      </Grid>
      <Grid item xs={8} justifyContent="center" alignItems="center">
        <Grid container item direction="row" alignItems="center">
          <Grid item>
            <Switch onChange={this.onDiscontinuousSwitchChange} defaultChecked={this.state.discontinuousMotionEnabled}/>
          </Grid>
          <Grid item style={{ flex: 1 }}>
            <Typography align={"center"}>Timeout</Typography>
            <TimeoutSlider
              onSliderChange={this.onStandardSliderChange}
              enabled={() => this.state.discontinuousMotionEnabled}
              roomType={motionSensor.roomtype}
              initialValue={incidentDetection.discontinuousMotionTimeout}
              currValue={this.state.discontinuousMotionTimeout}
              isContinuousTimeout={false}
            />
          </Grid>
        </Grid>
        <Grid container item direction="row" alignItems="center">
          <Grid item>
            <Switch onChange={this.onContinuousSwitchChange} defaultChecked={this.state.continuousMotionEnabled}/>
          </Grid>
          <Grid item style={{ flex: 1 }}>
            <Typography align={"center"}>Continuous Timeout</Typography>
            <TimeoutSlider
              onSliderChange={this.onContinuousSliderChange}
              enabled={() => this.state.continuousMotionEnabled}
              roomType={motionSensor.roomtype}
              initialValue={incidentDetection.continuousMotionTimeout}
              currValue={this.state.continuousMotionTimeout}
              isContinuousTimeout={true}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid></ListItem>
  }

  onStandardSliderChange = (value: number | number[], stepSize: number, min: number, max: number) => {
    this.onSliderChange(value, false, stepSize, min, max);
  }

  onContinuousSliderChange = (value: number | number[], stepSize: number, min: number, max: number) => {
    this.onSliderChange(value, true, stepSize, min, max);
  }

  onSliderChange = (value: number | number[], isContinuous: boolean, stepSize: number, min: number, max: number) => {
    if (value instanceof Array) return;
    this.setState({ secondaryText: "" }, () => this.update());
    if (isContinuous) {
      if (value > (min + stepSize))
        this.setState({ continuousMotionTimeout: value }, () => this.update());
      else this.setState({ continuousMotionTimeout: min + stepSize }, () => this.update());
      if (value <= this.state.discontinuousMotionTimeout)
        this.setState({ discontinuousMotionTimeout: value - stepSize }, () => this.update());
    } else {
      if (value < (max - stepSize))
        this.setState({ discontinuousMotionTimeout: value }, () => this.update());
      else this.setState({ discontinuousMotionTimeout: max - stepSize }, () => this.update());
      if (this.state.discontinuousMotionTimeout >= this.state.continuousMotionTimeout)
        this.setState({ continuousMotionTimeout: value + stepSize }, () => this.update());
    }
    if (this.state.discontinuousMotionTimeout < 60)
      this.setState({ secondaryText: warningText }, () => this.update());
  }

  onDiscontinuousSwitchChange = (event: any, checked: boolean) => {
    this.setState({ discontinuousMotionEnabled: checked }, () => this.update());
  }

  onContinuousSwitchChange = (event: any, checked: boolean) => {
    this.setState({ continuousMotionEnabled: checked }, () => this.update());
  }

  update = () => {
    const { onListItemChange, motionSensor } = this.props;
    onListItemChange(motionSensor.id, {
      continuousMotionEnabled: this.state.continuousMotionEnabled,
      discontinuousMotionEnabled: this.state.discontinuousMotionEnabled,
      continuousMotionTimeout: this.state.continuousMotionTimeout,
      discontinuousMotionTimeout: this.state.discontinuousMotionTimeout,
    });
  }
}