import * as React from 'react'
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Icon from '@mui/material/Icon';
import Card from '@mui/material/Card';
import { getBackgroundFromStatus, getIconForType, getValue } from './helper/formatters';
import InteliIcon from '../../../components/InteliIcon';


const ALERT_BORDER_COLOR = "#ef5350";
const ICON_COLOR = "#9e9e9e";

interface HouseholdStatusItemProps
{
  showAlert: boolean;
  statusTitle: string;
  description: string;
  value: string | number | JSX.Element;
  type: string;
}

export const HouseholdStatusItem = ({ showAlert, statusTitle, description, value, type }: HouseholdStatusItemProps) =>
{
  return(
    <Grid item xs={4}>
      <Card style={styles.cardContainer}>
        <div style={showAlert ? styles.alertStyles : styles.defaultRow}>
          {type === "respiration" ?
            <InteliIcon style={{ marginTop: 16, marginRight: 8 }} name="lungs" family="custom" color={getBackgroundFromStatus(type, value)} />
            :
            <Icon style={{ ...styles.titleIcon, color: getBackgroundFromStatus(type, value) }}>{getIconForType(type)}</Icon> }
          <div style={styles.textContainer}>
            <div style={styles.titleRow}>
              <Typography variant="body2" color="textPrimary" component="p">
                {statusTitle} - {getValue(value, type)}
              </Typography>
            </div>
          </div>

          <div style={styles.description}>
            <Typography variant="caption" color="textSecondary" component="p" style={styles.description}>
              {description}
            </Typography>
          </div>


        </div>


      </Card>
    </Grid>
  )
}

const styles = {
  description: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  titleIcon: {
    paddingRight: 8,
    fontSize: 64,
  },
  titleRow: {
    flexDirection: 'row' as 'row',
    flex: 1,
    display: 'flex' as 'flex',
  },
  textContainer: {
    display: "flex" as 'flex',
    flexDirection: "column" as 'column',
    justifyContent: 'center' as 'center',
  },
  defaultRow: {
    display: "flex",
    flexDirection: "row" as "row",
    alignItems: 'center',
  },
  alertStyles: {
    borderColor: ALERT_BORDER_COLOR,
    padding: 3,
    borderStyle: "solid",
    borderRadius: 10,
    borderWidth: 1,
    display: "flex",
    flexDirection: "row" as "row",
    alignItems: 'center',
  },
  iconStyle: { color: ICON_COLOR, paddingTop: 6, paddingRight: 6 },
  avatar: {
    marginRight: 6,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center' as 'center',
    width: 65,
    height: 65,
  },
  cardContainer: {
    padding: 16,
  },
}