
import {
  takeEvery,
  put,
  all,
  takeLatest,
} from 'redux-saga/effects'

import {
  HOUSEHOLD_V2_LIST_PAGE_SUCCESS,
  FETCH_HOUSEHOLD,
  VIEW_HOUSEHOLD,
  USE_NEW_LOOK,
} from './householdsStore'

import * as localForage from 'localforage'


import {
  HOUSEHOLD_AVATARS,
  HOUSEHOLD_V2_GET,
  ACTIVITYGRAPH,
  OVERALLACTIVITYGRAPH,
  AVERAGEACTIVITY,
  HOUSEHOLD_GRAPHS,
  HOUSEHOLD_V2_LIST_PAGE,
} from '../../shared/services/APIEndpoints'

import {
  callAPISaga,
} from '../../shared/services/WebAPI'


function* fetchAvatars(action) {
  try {
    const householdsWithAvatar = action.data
      .filter(o => o.hasAvatar === true)
      .reduce((a, o) => {
        if (!a) a = []
        a.push(o.householdId)
        return a
      }, [])
    if (!householdsWithAvatar) return

    const avatarsToFetch = filterCachedAvatars(householdsWithAvatar)
    if (avatarsToFetch.length > 0) {
      yield callAPISaga(HOUSEHOLD_AVATARS({
        method: 'POST',
        body: { households: avatarsToFetch },
      }))
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

function filterCachedAvatars(households) {
  return households.filter((id) => {
    if (process.env.REACT_APP_APP_RESOURCE !== 'WEB') return true
    if (!sessionStorage.getItem(`avatar_${id}`)) return true
  })
}

function* refreshHouseholds()
{
  yield callAPISaga(HOUSEHOLD_V2_LIST_PAGE({ pageSize: 20, pagePosition: 0, searchQuery: "*", order: "triage", systemHealthStatus: "", triagePriority: "", householdStatus: "monitored" }))
}

function* fetchHouseholdSaga(action) {
  yield put({ type: VIEW_HOUSEHOLD, household: { id: action.householdId } })
  yield callAPISaga(HOUSEHOLD_V2_GET({
    householdId: action.householdId,
  }))

  yield all([
    callAPISaga(HOUSEHOLD_GRAPHS({
      householdId: action.householdId,
    })),
    callAPISaga(ACTIVITYGRAPH({
      householdId: action.householdId,
      timePeriod: 24,
    })),
    callAPISaga(OVERALLACTIVITYGRAPH({
      householdId: action.householdId,
      timePeriod: 168,
    })),
    callAPISaga(AVERAGEACTIVITY({
      householdId: action.householdId,
      timePeriod: 168,
    })),
  ])
}

function* setUseNewLook(action)
{
  try
  {
    yield localForage.setItem(USE_NEW_LOOK, action.params.value)
  }
  catch(error)
  {
    //
  }
}

/*
  Starts fetchAvatars on each dispatched `HOUSEHOLD_V2_LIST_PAGE_SUCCESS` action.
*/
function* householdV2Saga() {
  yield takeEvery(HOUSEHOLD_V2_LIST_PAGE_SUCCESS, fetchAvatars)
  yield takeEvery(FETCH_HOUSEHOLD, fetchHouseholdSaga)
  yield takeLatest(USE_NEW_LOOK, setUseNewLook)
}

export default householdV2Saga
