import React from 'react'
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon'
import Slider from '@mui/material/Slider';
import { purple } from '@mui/material/colors';
interface AlertSliderProps
{
  onValueChange: (value: number | number[]) => void;
  values: number[];
  title: string;
  icon: string;
  max: number;
  type: "heartrate" | "activity" | "respiration";
  label: "bpm" | "rpm" | "/4"
}



export const AlertSlider = ({ onValueChange, values, title, icon, max, type, label }: AlertSliderProps) =>
{
  const marks = [{ value: 0, label: `0${label}` }, { value: max, label: `${max}${label}` }]
  const valuetext = (value: number) =>
  {
    if(type === "heartrate") return `${value}bpm`;
    if(type === "activity") return `${value}/4`;
    if(type === "respiration") return `${value}/rpm`;
    return ""
  }

  return(
    <Card style={styles.cardContainer}>

      <div style={styles.headingRowContainer}>
        <Icon style={styles.icon}>{icon}</Icon> <Typography variant="h6">{title}</Typography>
      </div>

      <Slider
        style={{ color: purple[500] }}
        disableSwap
        marks={marks}
        valueLabelDisplay="on"
        max={max}
        getAriaValueText={valuetext}
        value={values}
        onChange={(e: any, newValue: number | number[]) => onValueChange(newValue)}
      />
    </Card>
  )
}


const styles = {
  headingRowContainer: {
    flexDirection: "row" as 'row',
    display: 'flex',
    alignItems: 'center',
    color: 'grey',
    marginBottom: 28,
  },
  cardContainer: {
    marginTop: 12,
    marginBottom: 12,
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 28,
    paddingRight: 28,
  },
  icon: {
    paddingRight: 6,
  },
}