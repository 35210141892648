/* eslint-disable */
import React, { Component } from 'react'
import { Grid, Typography, TextField } from '@material-ui/core'
import access from 'safe-access'
import buildComponent from './BasePageConnector'
import FormButtons from '../FormButtons'

class FamilyResponsePlanSettings extends Component {
  constructor(props) {
    super(props)
  }

  componentDidUpdate(lastProps) {
    const { isSaving } = this.props;
    if (lastProps.isSaving === true && isSaving === false) {
      this.props.setSubmitting(false);
    }
  }

  render() {
    const {
      values,
      initialValues,
      classes,
      setFieldValue,
      handleChange,
    } = this.props;

    return (
      <div className={classes.OverlayMain}>
        <div className={classes.OverlaySection}>
          <Grid container justifyContent="center" align="center" direction="column" spacing={24}>
            <Grid container direction="row" item xs={12}>
              <Grid item sm={12} md={12} lg={3}>
                <Typography variant="h6" classes={{ h6: classes.label }}>Contact Name</Typography>
              </Grid>
              <Grid item sm={12} md={12} lg={7}>
                <TextField
                  type='text'
                  margin='dense'
                  variant='outlined'
                  className={classes.textInput}
                  id="primaryContact_name"
                  onChange={handleChange}
                  value={values.primaryContact_name} />
              </Grid>
              <Grid item sm={12} md={12} lg={2}>
                {values.primaryContact_name !== initialValues.primaryContact_name &&
                  <Typography variant="caption" classes={{ caption: classes.modified }}>Modified</Typography>
                }
              </Grid>
            </Grid>

            <Grid container direction="row" item xs={12}>
              <Grid item sm={12} md={12} lg={3}>
                <Typography variant="h6" classes={{ h6: classes.label }}>Contact Number</Typography>
              </Grid>
              <Grid item sm={12} md={12} lg={7}>
                <TextField
                  type='text'
                  variant='outlined'
                  margin='dense'
                  className={classes.textInput}
                  id="primaryContact_phone"
                  onChange={handleChange}
                  value={values.primaryContact_phone} />
              </Grid>
              <Grid item sm={12} md={12} lg={2}>
                {values.primaryContact_phone !== initialValues.primaryContact_phone &&
                  <Typography variant="caption" classes={{ caption: classes.modified }}>Modified</Typography>
                }
              </Grid>
            </Grid>

            <Grid container direction="row" item xs={12}>
              <Grid item sm={12} md={12} lg={3}>
                <Typography variant="h6" classes={{ h6: classes.label }}>Contact Address</Typography>
              </Grid>
              <Grid item sm={12} md={12} lg={7}>
                <TextField
                  type='text'
                  variant='outlined'
                  margin='dense'
                  className={classes.textInput}
                  id="primaryContact_address"
                  onChange={handleChange}
                  type="text"
                  value={values.primaryContact_address} />
              </Grid>
              <Grid item sm={12} md={12} lg={2}>
                {values.primaryContact_address !== initialValues.primaryContact_address &&
                  <Typography variant="caption" classes={{ caption: classes.modified }}>Modified</Typography>
                }
              </Grid>
            </Grid>
            <Grid container direction="row" item xs={12}>
              <Grid item sm={12} md={12} lg={3}>
                <Typography variant="h6" classes={{ h6: classes.label }}>Response Plan</Typography>
              </Grid>
              <Grid item sm={12} md={12} lg={7}>
                <TextField
                  type='text'
                  variant='outlined'
                  margin='dense'
                  multiline
                  rows="10"
                  className={classes.textInput}
                  id="responsePlan"
                  onChange={handleChange}
                  value={values.responsePlan} />
              </Grid>
              <Grid item sm={12} md={12} lg={2}>
                {values.responsePlan !== initialValues.responsePlan &&
                  <Typography variant="caption" classes={{ caption: classes.modified }}>Modified</Typography>
                }
              </Grid>

            </Grid>

          </Grid>
        </div>
        <div className={classes.OverlayFooter}>
          <FormButtons {...this.props} />
        </div>
      </div>
    )
  }
}

export default buildComponent(FamilyResponsePlanSettings, {
  mapPropsToValues: (props) => ({
    responsePlan: props.activeHousehold.data.responsePlan,
    primaryContact_name: access(props, 'activeHousehold.data.primaryContact.name'),
    primaryContact_phone: access(props, 'activeHousehold.data.primaryContact.phone'),
    primaryContact_address: access(props, 'activeHousehold.data.primaryContact.address'),
  }),
  handleSubmit: (values, actions) => {
    actions.props.updateSettings({
      householdId: access(actions, 'props.activeHousehold.householdId'),
      settings: Object.keys(values).reduce((prev, key) => {
        let properKey = key.replace('_', '.');
        prev[properKey] = values[key];
        return prev;
      }, {})
    })
  },
})
