export default (theme) => ({
  ToolTip: {
    width: '320px',
  },
  margin: {
    margin: theme.spacing(),
  },
  sensorCard: {
    marginTop: '0.5em',
  },
  sensorCardContent: {
    padding: '0.5rem',
    paddingTop: 0,
    paddingBottom: 0,
    '&:last-child': {
      padding: '0.5rem',
      paddingTop: 0,
      paddingBottom: '0.5rem',
    },
  },
  behaviourCard: {
    paddingTop: 0,
  },
  NoData: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  NoDataIcon: {
    marginRight: '12px',
  },
  LoadingData: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  StatusBadge: {
    fontSize: '0.8em',
    fontWeight: 'bold',
    paddingLeft: 5,
    paddingRight: 5,
    marginRight: 3,
    marginLeft: 3,
    display: 'inline-block',
    borderRadius: 3,
    color: 'white',
  },
  ExtraGutter: {
    marginBottom: '1rem',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  StatusLabels: {
    maxWidth: '310px',
  },
})
