import React, { memo } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
} from "react-simple-maps";

import Card from '@material-ui/core/Card'

import moment from 'moment-timezone'

const timezonesUrl = "https://raw.githubusercontent.com/frankzickert/world-clock/master/src/timezones.json"

interface MapChartProps
{
  setTooltipContent: (value: string) => void;
  timezones: string[] | undefined;
}

const MapChart = ({ setTooltipContent, timezones }: MapChartProps) => {


  const shouldHighlightTimezone = (tzOffset: string) =>
  {
    if(timezones)
    {
      for(const timezone of timezones)
      {
        try
        {
          // If the topoJson tzOffset matches any of the supplied current timezone then return true
          if(tzOffset === moment.tz(timezone).format("Z").substring(0, 3).replace("0" , ""))
          {
            return true;
          }
        }
        catch(error)
        {
          //
        }
      }
      return false;
    }

  }

  return (
    <Card style={styles.container}>
      <ComposableMap data-tip="" style={{ height: `100%`, width: `100%` }}  projectionConfig={{ scale: 225 }}>
        <Geographies geography={timezonesUrl}>
          {({ geographies }) =>
            geographies.map(geo => {
              return(<Geography
                key={geo.rsmKey}
                geography={geo}
                onMouseEnter={() => {
                  const { name } = geo.properties;
                  setTooltipContent("Timezone: " + name);
                }}
                onMouseLeave={() => {
                  setTooltipContent("");
                }}
                style={{
                  default: {
                    stroke: "grey",
                    strokeWidth: 1,
                    fill: shouldHighlightTimezone(geo.properties.name) ? "#AB47BC" : "white",
                    outline: "none",
                  },
                  hover: {
                    fill: "#EC407A",
                    outline: "none",
                  },
                  pressed: {
                    fill: "#EC407A",
                    outline: "none",
                  },

                }}
              />)
            })
          }
        </Geographies>
      </ComposableMap>
    </Card>
  );
};

const styles = {
  container: {
    marginTop: 12,
    marginLeft: 12,
    padding: 12,
    height: 300,
  },
}

export default memo(MapChart);
