import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InteliIcon from "../../InteliIcon";

interface LastMotionViewProps {
  eventData: {
    friendlyName: string;
    friendlyTime: string;
  };
}

export const LastMotionView = ({
  eventData,
}: LastMotionViewProps): JSX.Element => {
  const { friendlyName, friendlyTime } = eventData;
  return friendlyName && friendlyName ? (
    <Grid style={styles.container} container>
      <InteliIcon style={styles.icon} size={18} name={"timer"} />
      <Typography color="textSecondary">
        Last motion event was in the {friendlyName.toLowerCase()} {friendlyTime}
      </Typography>
    </Grid>
  ) : (
    <div></div>
  );
};

const styles = {
  container: {
    paddingBottom: 10,
  },
  icon: {
    alignSelf: "center",
    paddingRight: 4,
  },
};
