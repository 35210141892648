import { AlertBehaviour } from "../../../../interfaces/AlertBehaviour";
import { BEHAVIOUR_TYPES } from '@intelicare/shared/enums'
import { ZoneApiResponseItem } from "@intelicare/shared/interfaces/household/Zone";
import { ISensor } from "@intelicare/shared/interfaces/household/ISensor";
import { ClimateSensorList } from "../../../../components/ConfigureClimateAlertModal/Helpers/utils";

export const updateHouseholdsListOnSensorAdd = (state: any, sensor: any) =>
{
  const activeHouseholdId = state.activeHouseholdId;

  const updatedHouseholdsList = state.households.map((household: any) => {

    if(household.householdId === activeHouseholdId)
    {
      // Add the sensor to the array
      household.data.sensors.push(sensor)
      return household;
    }
    return household
  })

  return updatedHouseholdsList;
}

export const updateHouseholdsListOnPendantChange = (state: any, sensor: any) =>
{
  const activeHouseholdId = state.activeHouseholdId;

  const updatedHouseholdsList = state.households.map((household: any) => {

    if(household.householdId === activeHouseholdId)
    {
      // Update
      household.data.sensors.map((existingSensor: any, key: number) => {
        if(existingSensor.id === sensor.id)
        {
          household.data.sensors.splice(key, 1)
          household.data.sensors.push(key, sensor)
        }

        return existingSensor
      })

      return household;
    }
    return household
  })

  return updatedHouseholdsList;
}

export const updateHouseholdsListOnPendantDelete = (state: any, sensorId: string) =>
{
  const activeHouseholdId = state.activeHouseholdId;

  const updatedHouseholdsList = state.households.map((household: any) => {

    if(household.householdId === activeHouseholdId)
    {
      // Update
      household.data.sensors.map((existingSensor: any, key: number) => {
        if(existingSensor.id === sensorId)
        {
          household.data.sensors.splice(key, 1)
        }

        return existingSensor
      })

      return household;
    }
    return household
  })

  return updatedHouseholdsList;
}

// Update a given households behaviour with the behaviour object supplied
export const updateBehaviour = (state: any, householdId: string, updatedBehaviour: any) =>
{
  try
  {
    return state.households.map((household: any) => {
      if(household.householdId === householdId)
      {
        household.data.behaviours.map((behaviour: any) => {
          if(behaviour.type === updatedBehaviour.type) Object.assign(behaviour, updatedBehaviour)
          return behaviour;
        })
      }
      return household;
    })
  }
  catch(error)
  {
    return state.households;
  }
}

export const getAlertBehaviours = (state: any, householdId: string): AlertBehaviour[] =>
{
  try
  {
    const currentHousehold = state.householdsV2.households.filter((household: any): boolean => household.householdId === householdId)
    const alertBehaviours = currentHousehold[0].data.behaviours.filter((behaviour: any) => behaviour.type === BEHAVIOUR_TYPES.DOOR_ALERT || behaviour.type === BEHAVIOUR_TYPES.DOOR_INFO_MESSAGE)
    return alertBehaviours;
  }
  catch(error)
  {
    return []
  }

}

export const getBehaviourWithType = (state: any, householdId: string, behaviourType: string) => {
  try
  {
    const currentHousehold = state.householdsV2.households.find((household: any): boolean => household.householdId === householdId)
    return currentHousehold.data.behaviours.find((behaviour: any) => behaviour.type === behaviourType)
  }
  catch(error)
  {
    return []
  }
}

export const getClimateSensorList = (state: any, householdId: string, sensorType: string) =>{
  try
  {
    const currentHousehold = state.householdsV2.households.find((household: any): boolean => household.householdId === householdId)
    const climateSensorList = currentHousehold.data.sensors.filter((sensor: any) => sensor.type === sensorType)
    const reduceClimateSensorList=climateSensorList.map((s:any)=>  ({
      id:s.id,
      friendlyName:s.friendlyName,
      roomName:s.roomName||s.roomname,

    }))
    return reduceClimateSensorList;
  }
  catch(error)
  {
    return []
  }
}

export const getClimateSensorListByZone = (sensorType: string, zoneId: string, zonesAndRooms: ZoneApiResponseItem[]) =>{
  try
  {
    // @ts-ignore
    let sensorsList = []
    // Get the zone at hand
    const zone = zonesAndRooms && zonesAndRooms.find(z => z.zoneId === zoneId);

    // Check that the zone was found and has rooms
    if(zone && zone.rooms)
    {
      // Loop through the rooms
      for(const room of zone.rooms)
      {
        // If we get a zoneId match of the room filter out the climate sensors
        if(room.zoneId === zoneId)
        {
          const sensors = room.sensors.filter(sensor => sensor.type === sensorType)
          // @ts-ignore
          sensorsList= sensorsList.concat(sensors)
        }
      }
    }

    return sensorsList as unknown as ClimateSensorList[]
  }
  catch(error)
  {
    return []
  }
}



export const updateHasZoneStateForHousehold = (state: any, action: any) =>
{
  return state.households.map((household: any) => { // Toggle the household state
    if(household.householdId === action.data.householdId)
    {
      household.data.hasZones = action.data.enabled;
    }
    return household
  })
}