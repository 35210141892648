import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

interface OverviewCardProps
{
  title: string;
  description: string;
  value: string | number;
  color: string;
}

export const OverviewCard = ({ title, description, value, color }: OverviewCardProps) =>
{
  return(
    <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
      <Card style={styles.cardContainer}>
        <CardHeader
          titleTypographyProps={{ variant:'h6' }}
          avatar={
            <Avatar style={{ ...styles.avatar, backgroundColor: color }}>
              <Typography variant="h4">{value}</Typography>
            </Avatar>
          }

          title={title}
          subheader={description}
        />
      </Card>
    </Grid>
  )
}

const styles = {
  cardContainer: {
    padding: 12,
    flex: 1,
    borderRadius: 6,
    marginBottom: 12,
  },
  avatar: {
    width: 100,
    height: 100,
  },
  icon: {
    width: 100,
    height: 100,
  },
}