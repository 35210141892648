import React, { useState } from 'react'
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import { SearchBar } from '../../Shared/SearchBar';
import { blue } from '@mui/material/colors';
import { Organisation } from '../../../../../api/OrganisationApi';

import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../../interfaces/reduxState';
import { callAPI } from '../../../../../redux/domains/user/userStore';
import { HOUSEHOLD_V2_LIST_PAGE } from '../../../../../redux/shared/services/APIEndpoints';
import Skeleton from 'react-loading-skeleton';
import { NothingFound } from './NothingFound';

let timeoutId = 0;
interface HouseholdListModalProps
{
  onHouseholdSelect: (household: Organisation) => void;
  handleClose: () => void;
  open: boolean;
  title: string
}

export const HouseholdListModal = ({ onHouseholdSelect, handleClose, open, title }: HouseholdListModalProps) =>
{
  const [ query, setQuery ] = useState("")
  const [ typing, setTyping ] = useState(false)
  const [ searching, setSearching ] = useState(false)


  const dispatch = useDispatch()
  const households = useSelector(({ organisations }: ApplicationState) => organisations.householdsSearchResults)
  const isFetching = useSelector(({ householdsV2 }: ApplicationState) => householdsV2.isFetching)

  const handleSearch = (searchQuery: string) =>
  {
    setQuery(searchQuery)
    setTyping(true)
    clearTimeout(timeoutId)

    // when we start typing - start the timeout function
    timeoutId = setTimeout(() => {

      setTyping(false)
      initSearch(searchQuery)

    }, 1000) as any

  }

  const initSearch = (searchQuery: string) =>
  {
    dispatch(callAPI(HOUSEHOLD_V2_LIST_PAGE({
      pageSize: 20,
      pagePosition: 0,
      searchQuery: searchQuery + "*" || '*',
      order: "triage",
      householdStatus: "monitored",
      systemHealthStatus: "",
      triagePriority: "",
    })))
  }

  return(
    <Dialog
      TransitionProps={
        {
          onEntering: () => initSearch(""),
        }
      }
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={open} >
      <DialogTitle>{title}</DialogTitle>
      <div style={{ margin: 16 }}>
        <SearchBar title="Search households" onTextChange={(e) => handleSearch(e)} onClear={() => dispatch(() => handleSearch(""))} value={query} />
      </div>
      <List sx={{ pt: 0, height: 400, overflow: "scroll" }}>
        {!isFetching && households && households.length > 0 ? households.map((household) => (
          <ListItem button onClick={() => onHouseholdSelect(household)} key={household.id}>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: blue[300] }}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={household.friendlyName} />
          </ListItem>
        )) : isFetching ? <Skeleton count={20} style={styles.skeleton}/> : <NothingFound />}
      </List>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  skeleton: {
    marginTop: 6,
    marginBottom: 6,
    marginLeft: 12,
    marginRight: 12,
  },

}