import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import AddIcon from '@mui/icons-material/AddCircle'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import EnableProUserModal from './EnableProUserModal'


const EnableProUserCard = () =>
{

  const [ showModal, setShowModal ] = useState(false)
  return (
    <Card style={styles.container}>
      <CardContent>
        <Typography  color="textPrimary" gutterBottom>
        Enable Pro User
        </Typography>
        <Typography variant="h5" component="h2">

        </Typography>

        <Typography color="textSecondary" variant="body2" component="p">
          Promote existing user to a pro account. This will allow them to access the InteliCare Pro Dashboard
          <br />

        </Typography>
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => setShowModal(true)} size="small">Add</Button>
      </CardActions>

      <EnableProUserModal open={showModal} handleClose={() => setShowModal(false)} />
    </Card>
  )
}

const styles = {
  container: {
    margin: 12,
    paddingTop: 12,
  },
}

export default EnableProUserCard