// Generic confirm action dialog - mainly used for delete actions
import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface ConfirmDeleteProps
{
  open: boolean;
  handleClose: () => void;
  content: string;
  title: string;
  onConfirmAction: () => void;
}

export const ConfirmDelete = ({ open, handleClose, content, title, onConfirmAction }: ConfirmDeleteProps) =>
{
  return(
    <Dialog
      open={open}
      onClose={handleClose} >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={onConfirmAction} autoFocus>Remove</Button>
      </DialogActions>
    </Dialog>
  )
}

