import { connect } from 'react-redux'
import ActivityStreamView from '../component/ActivityStreamView'
import sharedStore from '../../../redux'
const getActivityData = sharedStore.householdsV2Selector.getActivityData
const callAPI = sharedStore.userStore.callAPI
const HOUSEHOLD_V2_ACTIVITYSTREAM = sharedStore.apiEndpoints.HOUSEHOLD_V2_ACTIVITYSTREAM

const mapDispatchToProps = (dispatch) => {
  return {
    fetchActivityfeedV2: (householdId, start, end) => {
      if (!householdId) return
      dispatch(callAPI(HOUSEHOLD_V2_ACTIVITYSTREAM({ householdId, start, end })))
    },
  }
}

const mapStateToProps = (state) => ({
  activityV2: getActivityData(state),
  isDeveloper: state.user.isDeveloper || false,
  isSupport: state.user.isSupport || false,
})

export default connect(mapStateToProps, mapDispatchToProps)(ActivityStreamView)
