import React from 'react'
import Card from '@material-ui/core/Card'
//@ts-ignore
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LineChart, Line, AreaChart, Area, RadialBarChart, RadialBar } from 'recharts';
import { ChartTypes } from './AllMetrics';
import moment from 'moment';

interface MetricGraphProps
{
  graphType: ChartTypes;
  data: any[];
  height: number
}

export const MetricGraph = ({ data, graphType, height }: MetricGraphProps) =>
{
  return(
    <Card style={styles.cardContainer} elevation={2}>
      <div style={styles.container}>
        {graphType === 'bar' && <ResponsiveContainer width="100%" height={height}>

          <BarChart width="100%" height="100%" data={data}>
            <Bar dataKey="metricValue" fill="#8884d8" />
            <XAxis dataKey="timestamp" tickFormatter={(timestamp: string) => moment(timestamp).fromNow()}/>
            <Tooltip labelFormatter={(date: string) => `${moment(date).format("LLLZ")} (${moment(date).fromNow()})`}/>
            <Legend formatter={(label: any) => "Metric Value"} />
          </BarChart>
        </ResponsiveContainer> }

        {graphType === 'area' && <ResponsiveContainer width="100%" height={height}>
          <AreaChart
            width="100%" height="100%"
            data={data} >
            <XAxis dataKey="timestamp" tickFormatter={(timestamp: string) => moment(timestamp).fromNow()}/>
            <YAxis />
            <Tooltip labelFormatter={(date: string) => `${moment(date).format("LLLZ")} (${moment(date).fromNow()})`}/>
            <Legend formatter={(label: any) => "Metric Value"}/>
            <Area type="monotone" dataKey="metricValue" stroke="#8884d8" fill="#8884d8" />
          </AreaChart>
        </ResponsiveContainer> }

        {graphType === 'line' && <ResponsiveContainer width="100%" height={height} >
          <LineChart
            width="100%" height="100%"
            data={data}
          >
            <XAxis dataKey="timestamp" tickFormatter={(timestamp: string) => moment(timestamp).fromNow()}/>
            <YAxis />
            <Tooltip labelFormatter={(date: string) => `${moment(date).format("LLLZ")} (${moment(date).fromNow()})`}/>
            <Legend formatter={(label: any) => "Metric Value"}/>
            <Line type="monotone" dataKey="metricValue" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer> }

        {graphType === 'radialBar' && <ResponsiveContainer width="100%" height={height}>
          <RadialBarChart cx="50%" cy="50%" innerRadius="10%" outerRadius="80%" barSize={10} data={data}>
            <RadialBar
              minAngle={15}
              label={{ position: 'insideStart', fill: '#fff' }}
              background
              clockWise
              dataKey="metricValue"
            />
            <Legend formatter={(label: any) => "Metric Value"} iconSize={10} layout="vertical" verticalAlign="middle" wrapperStyle={styles.radial} />
            <Tooltip labelFormatter={(date: string) => `${moment(date).format("LLLZ")} (${moment(date).fromNow()})`}/>
          </RadialBarChart>
        </ResponsiveContainer> }

      </div>
    </Card>
  )
}
const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
  },
  cardContainer: {
    padding: 12,
    margin: 24,
  },
  radial: {
    top: '50%',
    right: 0,
    transform: 'translate(0, -50%)',
    lineHeight: '24px',
  },
}