/**
 * HOUSEHOLDS V2 STORE
 */
import _ from 'lodash'
import { enums } from '@intelicare/shared'
import graphsV2Reducer from './graphsV2Store'
import { updateHouseholdsListOnSensorAdd, updateHouseholdsListOnPendantChange, updateHouseholdsListOnPendantDelete, updateBehaviour, updateHasZoneStateForHousehold } from './helpers'
import moment from 'moment'
import {
  alert,
  amber,
  okay,
  disable,
} from '../../shared/util/colors'
import { ZoneActions } from '../zones/actions'
const { BehaviourClassification } = enums


// ------------------------------------
// Constants
// ------------------------------------
export const HOUSEHOLD_V2_LIST_PAGE_BEGIN = 'HOUSEHOLD_V2_LIST_PAGE_BEGIN'
export const HOUSEHOLD_V2_LIST_PAGE_SUCCESS = 'HOUSEHOLD_V2_LIST_PAGE_SUCCESS'
export const HOUSEHOLD_V2_LIST_PAGE_ERROR = 'HOUSEHOLD_V2_LIST_PAGE_ERROR'

export const HOUSEHOLD_V2_GET_BEGIN = 'HOUSEHOLD_V2_GET_BEGIN'
export const HOUSEHOLD_V2_GET_SUCCESS = 'HOUSEHOLD_V2_GET_SUCCESS'
export const HOUSEHOLD_V2_GET_ERROR = 'HOUSEHOLD_V2_GET_ERROR'
export const HOUSEHOLDS_V2_PURGE = 'HOUSEHOLDS_V2_PURGE'
export const HOUSEHOLDS_V2_CLEAR = 'HOUSEHOLDS_V2_CLEAR'

export const HOUSEHOLD_SET_STALE = 'HOUSEHOLD_SET_STALE'

export const HOUSEHOLD_AVATARS_BEGIN = 'HOUSEHOLD_AVATARS_BEGIN'
export const HOUSEHOLD_AVATARS_SUCCESS = 'HOUSEHOLD_AVATARS_SUCCESS'
export const HOUSEHOLD_AVATARS_ERROR = 'HOUSEHOLD_AVATARS_ERROR'

export const HOUSEHOLD_V2_ACTIVITYSTREAM_BEGIN = 'HOUSEHOLD_V2_ACTIVITYSTREAM_BEGIN'
export const HOUSEHOLD_V2_ACTIVITYSTREAM_SUCCESS = 'HOUSEHOLD_V2_ACTIVITYSTREAM_SUCCESS'
export const HOUSEHOLD_V2_ACTIVITYSTREAM_ERROR = 'HOUSEHOLD_V2_ACTIVITYSTREAM_ERROR'

export const HOUSEHOLD_V2_ACTIVITYSTREAM_PREVIEW_BEGIN = 'HOUSEHOLD_V2_ACTIVITYSTREAM_PREVIEW_BEGIN'
export const HOUSEHOLD_V2_ACTIVITYSTREAM_PREVIEW_SUCCESS = 'HOUSEHOLD_V2_ACTIVITYSTREAM_PREVIEW_SUCCESS'
export const HOUSEHOLD_V2_ACTIVITYSTREAM_PREVIEW_ERROR = 'HOUSEHOLD_V2_ACTIVITYSTREAM_PREVIEW_ERROR'

export const HOUSEHOLD_V2_SENSORS_STATE_BEGIN = 'HOUSEHOLD_V2_SENSORS_STATE_BEGIN'
export const HOUSEHOLD_V2_SENSORS_STATE_SUCCESS = 'HOUSEHOLD_V2_SENSORS_STATE_SUCCESS'
export const HOUSEHOLD_V2_SENSORS_STATE_ERROR = 'HOUSEHOLD_V2_SENSORS_STATE_ERROR'

export const HOUSEHOLDS_STATUS_BEGIN = 'HOUSEHOLDS_STATUS_BEGIN'
export const HOUSEHOLDS_STATUS_SUCCESS = 'HOUSEHOLDS_STATUS_SUCCESS'
export const HOUSEHOLDS_STATUS_ERROR = 'HOUSEHOLDS_STATUS_ERROR'

export const SLEEP_GRAPH_DATA_BEGIN = 'SLEEP_GRAPH_DATA_BEGIN'
export const SLEEP_GRAPH_DATA_SUCCESS = 'SLEEP_GRAPH_DATA_SUCCESS'
export const SLEEP_GRAPH_DATA_ERROR = 'SLEEP_GRAPH_DATA_ERROR'

export const HOUSEHOLD_CARER_LIST_BEGIN = 'HOUSEHOLD_CARER_LIST_BEGIN'
export const HOUSEHOLD_CARER_LIST_SUCCESS = 'HOUSEHOLD_CARER_LIST_SUCCESS'
export const HOUSEHOLD_CARER_LIST_ERROR = 'HOUSEHOLD_CARER_LIST_ERROR'

export const HOUSEHOLD_CARER_ADD_BEGIN = 'HOUSEHOLD_CARER_ADD_BEGIN'
export const HOUSEHOLD_CARER_ADD_SUCCESS = 'HOUSEHOLD_CARER_ADD_SUCCESS'
export const HOUSEHOLD_CARER_ADD_ERROR = 'HOUSEHOLD_CARER_ADD_ERROR'

export const HOUSEHOLD_CARER_REMOVE_BEGIN = 'HOUSEHOLD_CARER_REMOVE_BEGIN'
export const HOUSEHOLD_CARER_REMOVE_SUCCESS = 'HOUSEHOLD_CARER_REMOVE_SUCCESS'
export const HOUSEHOLD_CARER_REMOVE_ERROR = 'HOUSEHOLD_CARER_REMOVE_ERROR'

export const UPDATE_HEALTH_METRICS_SETTINGS = 'UPDATE_HEALTH_METRICS_SETTINGS'
export const UPDATE_BEHAVIOUR_SETTING = 'UPDATE_BEHAVIOUR_SETTING'
export const UPDATE_AVATAR = 'UPDATE_AVATAR'

// This is an action so that the household list and search bar
// components can comunicate with the header search icon
export const TOGGLE_HOUSEHOLD_LIST_SEARCH_BAR = 'TOGGLE_HOUSEHOLD_LIST_SEARCH_BAR'

export const VIEW_HOUSEHOLD = 'VIEW_HOUSEHOLD'
export const FETCH_HOUSEHOLD = 'FETCH_HOUSEHOLD'

export const HOUSEHOLDS_SENSORS_BEGIN = 'HOUSEHOLDS_SENSORS_BEGIN'
export const HOUSEHOLDS_SENSORS_SUCCESS = 'HOUSEHOLDS_SENSORS_SUCCESS'
export const HOUSEHOLDS_SENSORS_ERROR = 'HOUSEHOLDS_SENSORS_ERROR'
export const UPDATE_HOUSEHOLD_SENSOR = 'UPDATE_HOUSEHOLD_SENSOR'

export const HOUSEHOLDS_SENSORS_DELETE_BEGIN = 'HOUSEHOLDS_SENSORS_DELETE_BEGIN'
export const HOUSEHOLDS_SENSORS_DELETE_SUCCESS = 'HOUSEHOLDS_SENSORS_DELETE_SUCCESS'
export const HOUSEHOLDS_SENSORS_DELETE_ERROR = 'HOUSEHOLDS_SENSORS_DELETE_ERROR'
export const HOUSEHOLDS_SENSORS_RESET_MODAL = 'HOUSEHOLDS_SENSORS_RESET_MODAL'


export const CONFIGURE_SENSOR_BEGIN = 'CONFIGURE_SENSOR_BEGIN'
export const CONFIGURE_SENSOR_SUCCESS = 'CONFIGURE_SENSOR_SUCCESS'
export const CONFIGURE_SENSOR_ERROR = 'CONFIGURE_SENSOR_ERROR'

export const RESET_BEHAVIOUR_BEGIN = 'RESET_BEHAVIOUR_BEGIN'
export const RESET_BEHAVIOUR_SUCCESS = 'RESET_BEHAVIOUR_SUCCESS'
export const RESET_BEHAVIOUR_ERROR = 'RESET_BEHAVIOUR_ERROR'
export const CLEAR_RESET_BEHAVIOUR_RESULT = 'CLEAR_RESET_BEHAVIOUR_RESULT'

export const DATA_SENSOR_EVENT_BEGIN = 'DATA_SENSOR_EVENT_BEGIN'
export const DATA_SENSOR_EVENT_SUCCESS = 'DATA_SENSOR_EVENT_SUCCESS'
export const DATA_SENSOR_EVENT_ERROR = 'DATA_SENSOR_EVENT_ERROR'
export const CLEAR_SENSOR_EVENTS = 'CLEAR_SENSOR_EVENTS'

export const SET_SEARCH_QUERY_PLACEHOLDER_TEXT = "SET_SEARCH_QUERY_PLACEHOLDER_TEXT"

// Reseller actions

export const GET_RESELLERS_BEGIN = 'GET_RESELLERS_BEGIN'
export const GET_RESELLERS_SUCCESS = 'GET_RESELLERS_SUCCESS'
export const GET_RESELLERS_ERROR = 'GET_RESELLERS_ERROR'

export const UPDATE_RESELLER_BEGIN = 'UPDATE_RESELLER_BEGIN'
export const UPDATE_RESELLER_SUCCESS = 'UPDATE_RESELLER_SUCCESS'
export const UPDATE_RESELLER_ERROR = 'UPDATE_RESELLER_ERROR'
export const CLEAR_UPDATE_RESELLER = 'CLEAR_UPDATE_RESELLER'


export const CREATE_RESELLER_BEGIN = 'CREATE_RESELLER_BEGIN'
export const CREATE_RESELLER_SUCCESS = 'CREATE_RESELLER_SUCCESS'
export const CREATE_RESELLER_ERROR = 'CREATE_RESELLER_ERROR'
export const CLEAR_CREATE_RESELLER = 'CLEAR_CREATE_RESELLER'

export const GET_HOUSEHOLDS_BY_RESELLER_ID_BEGIN = 'GET_HOUSEHOLDS_BY_RESELLER_ID_BEGIN'
export const GET_HOUSEHOLDS_BY_RESELLER_ID_SUCCESS = 'GET_HOUSEHOLDS_BY_RESELLER_ID_SUCCESS'
export const GET_HOUSEHOLDS_BY_RESELLER_ID_ERROR = 'GET_HOUSEHOLDS_BY_RESELLER_ID_ERROR'

export const GET_HOUSEHOLDS_BEGIN = 'GET_HOUSEHOLDS_BEGIN'
export const GET_HOUSEHOLDS_SUCCESS = 'GET_HOUSEHOLDS_SUCCESS'
export const GET_HOUSEHOLDS_ERROR = 'GET_HOUSEHOLDS_ERROR'

export const LINK_HOUSEHOLD_TO_RESELLER_BEGIN = "LINK_HOUSEHOLD_TO_RESELLER_BEGIN"
export const LINK_HOUSEHOLD_TO_RESELLER_SUCCESS = "LINK_HOUSEHOLD_TO_RESELLER_SUCCESS"
export const LINK_HOUSEHOLD_TO_RESELLER_ERROR = "LINK_HOUSEHOLD_TO_RESELLER_ERROR"
export const CLEAR_LINK_HOUSEHOLD_TO_RESELLER = "CLEAR_LINK_HOUSEHOLD_TO_RESELLER"

export const DELETE_RESELLER_BEGIN = 'DELETE_RESELLER_BEGIN'
export const DELETE_RESELLER_SUCCESS = 'DELETE_RESELLER_SUCCESS'
export const DELETE_RESELLER_ERROR = 'DELETE_RESELLER_ERROR'
export const CLEAR_DELETE_RESELLER = "CLEAR_DELETE_RESELLER"

export const SET_HOUSEHOLD_BILLING_START_DATE_BEGIN = 'SET_HOUSEHOLD_BILLING_START_DATE_BEGIN'
export const SET_HOUSEHOLD_BILLING_START_DATE_SUCCESS = 'SET_HOUSEHOLD_BILLING_START_DATE_SUCCESS'
export const SET_HOUSEHOLD_BILLING_START_DATE_ERROR = 'SET_HOUSEHOLD_BILLING_START_DATE_ERROR'
export const CLEAR_SET_HOUSEHOLD_BILLING_START_DATE = "CLEAR_SET_HOUSEHOLD_BILLING_START_DATE"

export const GET_HOUSEHOLD_FROM_GATEWAY_ID_BEGIN = 'GET_HOUSEHOLD_FROM_GATEWAY_ID_BEGIN'
export const GET_HOUSEHOLD_FROM_GATEWAY_ID_SUCCESS = 'GET_HOUSEHOLD_FROM_GATEWAY_ID_SUCCESS'
export const GET_HOUSEHOLD_FROM_GATEWAY_ID_ERROR = 'GET_HOUSEHOLD_FROM_GATEWAY_ID_ERROR'
export const CLEAR_GET_HOUSEHOLD_FROM_GATEWAY_ID = "CLEAR_GET_HOUSEHOLD_FROM_GATEWAY_ID"

export const LINK_INVOICE_TO_HOUSEHOLD_BEGIN = "LINK_INVOICE_TO_HOUSEHOLD_BEGIN"
export const LINK_INVOICE_TO_HOUSEHOLD_SUCCESS = "LINK_INVOICE_TO_HOUSEHOLD_SUCCESS"
export const LINK_INVOICE_TO_HOUSEHOLD_ERROR = "LINK_INVOICE_TO_HOUSEHOLD_ERROR"
export const CLEAR_LINK_INVOICE_TO_HOUSEHOLD = "CLEAR_LINK_INVOICE_TO_HOUSEHOLD"

export const ADD_PENDANT_BEGIN = "ADD_PENDANT_BEGIN"
export const ADD_PENDANT_SUCCESS = "ADD_PENDANT_SUCCESS"
export const ADD_PENDANT_ERROR = "ADD_PENDANT_ERROR"
export const CLEAR_ADD_PENDANT = "CLEAR_ADD_PENDANT"

export const UPDATE_PENDANT_BEGIN = "UPDATE_PENDANT_BEGIN"
export const UPDATE_PENDANT_SUCCESS = "UPDATE_PENDANT_SUCCESS"
export const UPDATE_PENDANT_ERROR = "UPDATE_PENDANT_ERROR"

export const REMOVE_PENDANT_BEGIN = "REMOVE_PENDANT_BEGIN"
export const REMOVE_PENDANT_SUCCESS = "REMOVE_PENDANT_SUCCESS"
export const REMOVE_PENDANT_ERROR = "REMOVE_PENDANT_ERROR"

export const ADD_GUARDIAN_AI_BEGIN = "ADD_GUARDIAN_AI_BEGIN"
export const ADD_GUARDIAN_AI_SUCCESS = "ADD_GUARDIAN_AI_SUCCESS"
export const ADD_GUARDIAN_AI_ERROR = "ADD_GUARDIAN_AI_ERROR"
export const CLEAR_ADD_GUARDIAN_AI = "CLEAR_ADD_GUARDIAN_AI"

export const UPDATE_BEHAVIOUR = "UPDATE_BEHAVIOUR";

export const CLEAR_DELETE_HOUSEHOLD_BY_GATEWAY_ID = "CLEAR_DELETE_HOUSEHOLD_BY_GATEWAY_ID"

export const USE_NEW_LOOK = "USE_NEW_LOOK";

export const GET_HOUSEHOLD_ACCESS_AUDIT_LOGS_BEGIN = "GET_HOUSEHOLD_ACCESS_AUDIT_LOGS_BEGIN"
export const GET_HOUSEHOLD_ACCESS_AUDIT_LOGS_SUCCESS = "GET_HOUSEHOLD_ACCESS_AUDIT_LOGS_SUCCESS"
export const GET_HOUSEHOLD_ACCESS_AUDIT_LOGS_ERROR = "GET_HOUSEHOLD_ACCESS_AUDIT_LOGS_ERROR"

export const UPDATE_INCIDENT_DETECTION_BEGIN = "UPDATE_INCIDENT_DETECTION_BEGIN";
export const UPDATE_INCIDENT_DETECTION_SUCCESS = "UPDATE_INCIDENT_DETECTION_SUCCESS";
export const UPDATE_INCIDENT_DETECTION_ERROR = "UPDATE_INCIDENT_DETECTION_ERROR";
export const UPDATE_INCIDENT_DETECTION_CLEAR = "UPDATE_INCIDENT_DETECTION_CLEAR";


// ------------------------------------
// Actions
// ------------------------------------
export function purgeHouseholds() {
  return {
    type: HOUSEHOLDS_V2_PURGE,
  }
}

export function clearHouseholdList() {
  return {
    type: HOUSEHOLDS_V2_CLEAR,
  }
}

export function fetchHousehold(householdId) {
  return {
    type: FETCH_HOUSEHOLD,
    householdId: householdId,
  }
}

export function viewHousehold(householdId) {
  return {
    type: VIEW_HOUSEHOLD,
    household: { id: householdId },
  }
}

export function updateAvatar(householdId, avatar) {
  return {
    type: UPDATE_AVATAR,
    id: householdId,
    avatar: avatar,
  }
}

export function ACTION_SET_SEARCH_QUERY_PLACEHOLDER_TEXT(text) {
  return {
    type: SET_SEARCH_QUERY_PLACEHOLDER_TEXT,
    value: text,
  }
}

export function updateHealthMetricsSettings(params) {
  return {
    type: UPDATE_HEALTH_METRICS_SETTINGS,
    householdId: params.householdId,
    settings: params.settings,
  }
}

export function updateBehaviourSetting(householdId, setting, value) {
  return {
    type: UPDATE_BEHAVIOUR_SETTING,
    id: householdId,
    setting: setting,
    value: value,
  }
}

function setHouseholdStale(households, event) {
  return households.map(household => {
    if (household.householdId === event.id) {
      return {
        ...household,
        stale: event.stale,
        data: {
          stale: event.stale,
          ...household.data,
        },
      }
    }
    return household
  })
}

function addHouseholdData(households, data) {
  // this handles houses that are accessed with a deep url
  // or otherwise not yet returned by the household list api
  if (!(households.find(household => household.householdId === data.id))) {
    households.push({
      householdId: data.id,
    })
  }
  return households.map(household => {
    if (household.householdId === data.id) {
      delete household.data
      return {
        ...household,
        data: {
          ...data,
          hasZones: data.zones && data.zones.length > 0 ? true : false,
          lastFetched: moment().toISOString(),
        },
      }
    }
    return household
  })
}

export function updateHouseholdSensor(householdId, sensor) {
  return {
    type: UPDATE_HOUSEHOLD_SENSOR,
    sensor: sensor,
    householdId: householdId,
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  households: [],
  isFetching: false,
  hasMore: true,
  lastSearchQuery: undefined,
  activeHouseholdId: undefined,
  showHouseholdListSearchBar: false,
  sensorDetails: [],
  sensorDetailsFetching: false,
  firmwareLoading: false,
  queryHelperText: "Search a household...",
  useNewLook: false,
  householdAuditRecords: [],
  isGettingAuditRecords: false,
}

/**
 * household object within household array data structure
 * {
 *    householdId: string,
 *    friendlyName: string,
 *    hasAvatar: bool,
 *    behaviourRollup: string,
 *    systemHealthState: string,
 *    notificationCount: number,
 *    alertCount: number,
 *    triage: number,
 *    avatar: blob,
 *    stale: bool,
 *    data: {
 *      isFetching,
 *      <household doc/model contents>
 *    },
 *    notifications: {
 *      isFetching,
 *    },
 *    activity: {
 *      isFetching,
 *      events [{
 *        timestamp: string,
 *        activityType: string
 *      }],
 *    },
 *    sensorsState: {
 *      isFetching: boolean,
 *      states: [{
 *        id: string,
 *        timestamp: string,
 *        value: string
 *      }]
 *    },
 *    carers: {
 *      data: [{
 *        email: string,
 *        name: string,
 *        objectId: string,
 *      }],
 *      fetchingCarers: boolean,
 *      addingCarer: boolean,
 *      removingCarer: boolean,
 *    }
 * }
 *
 *
 */

/* Sensor Details objects
 *    sensorDetails: [
*      {
*        id: string,
*        name: string,
*        manufacturer: string,
*        product: string,
*        behaviour: string,
*        roomName: string,
*        roomType: string,
*        type: string,
*        nodeId: number,
*        subSensors: [{
*          //as above
*        }]
*      }
*    ],
*    sensorDetailsFetching: boolean,
*/


export default function householdsStoreReducer(state = initialState, action) {

  switch (action.type) {
    case ZoneActions.UPDATE_HAS_ZONES_STATE:
      return {
        ...state,
        households: updateHasZoneStateForHousehold(state, action),
      }
    case USE_NEW_LOOK:
      return {
        ...state,
        useNewLook: action.params.value,
      }
    case HOUSEHOLDS_STATUS_SUCCESS:
      return {
        ...state,
        statusSummary: action.data,
      }
    case HOUSEHOLD_V2_LIST_PAGE_BEGIN:
      return {
        ...state,
        isFetching: true,
      }

    case HOUSEHOLD_V2_LIST_PAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        households: mapHouseholdList(state.households, action.data),
        hasMore: householdListHasMore(action.data, action.params),
        lastSearchQuery: getCleanSearchQuery(action.params, state.lastSearchQuery),
      }

    case HOUSEHOLD_V2_LIST_PAGE_ERROR:
      return {
        ...state,
        isFetching: false,
        // fetchingHouseholdsError: action.error,
      }

    case HOUSEHOLD_SET_STALE:
      return {
        ...state,
        households: setHouseholdStale(state.households, action.event),
      }

    case VIEW_HOUSEHOLD:
      return {
        ...state,
        activeHouseholdId: action.household.id,
      }

    case HOUSEHOLD_V2_GET_BEGIN: {
      return {
        ...state,
        households: addHouseholdData(state.households, { isFetching: true, id: action.params.householdId }),
      }
    }

    case HOUSEHOLD_V2_GET_SUCCESS:
      return {
        ...state,
        households: addHouseholdData(state.households, { ...action.data, isFetching: false }),
        activeGatewayId: action.data.gatewayId,
      }

    case HOUSEHOLD_V2_GET_ERROR: {
      return {
        ...state,
        households: addHouseholdData(state.households, { isFetching: false, id: action.params.householdId }),
      }
    }

    case HOUSEHOLDS_V2_PURGE:
      return {
        ...state,
        households: [],
        hasMore: true,
      }

    case HOUSEHOLDS_V2_CLEAR:
      return {
        ...state,
        households: [],
        hasMore: false,
      }

    case SET_SEARCH_QUERY_PLACEHOLDER_TEXT:
      return {
        ...state,
        queryHelperText: action.value,
      }

    case HOUSEHOLD_AVATARS_BEGIN:
      return {
        ...state,
      }

    case HOUSEHOLD_AVATARS_SUCCESS:
      return {
        ...state,
        households: mapFetchedAvatars(state.households, action.data.data, action.params),
      }

    case HOUSEHOLD_AVATARS_ERROR:
      return {
        ...state,
      }

    case HOUSEHOLD_V2_ACTIVITYSTREAM_BEGIN:
      return {
        ...state,
        households: mapActivityStream(state.households, action.data, { isFetching: true, ...action.params }),
        isGettingActivityStream: true,
      }

    case HOUSEHOLD_V2_ACTIVITYSTREAM_SUCCESS:
      return {
        ...state,
        households: mapActivityStream(state.households, action.data, { isFetching: false, ...action.params }),
        isGettingActivityStream: false,
      }

    case HOUSEHOLD_V2_ACTIVITYSTREAM_ERROR:
      return {
        ...state,
        households: mapActivityStream(state.households, action.data, { isFetching: false, ...action.params }),
        isGettingActivityStream: false,
      }

    case HOUSEHOLD_V2_ACTIVITYSTREAM_PREVIEW_BEGIN:
      return {
        ...state,
        activityStreamEvents: [],
        isGettingActivityEvents: true,
      }

    case HOUSEHOLD_V2_ACTIVITYSTREAM_PREVIEW_SUCCESS:
      return {
        ...state,
        activityStreamEvents: action.data,
        isGettingActivityEvents: false,
      }

    case HOUSEHOLD_V2_ACTIVITYSTREAM_PREVIEW_ERROR:
      return {
        ...state,
        activityStreamEventsError: action.error,
        isGettingActivityEvents: false,
      }

    case HOUSEHOLD_V2_SENSORS_STATE_BEGIN:
      return {
        ...state,
        households: mapSensorsState(state.households, action.data, { isFetching: true, ...action.params }),
      }

    case HOUSEHOLD_V2_SENSORS_STATE_SUCCESS:
      return {
        ...state,
        households: mapSensorsState(state.households, action.data, { isFetching: false, ...action.params }),
      }

    case HOUSEHOLD_V2_SENSORS_STATE_ERROR:
      return {
        ...state,
        households: mapSensorsState(state.households, action.data, { isFetching: false, ...action.params }),
      }

    case TOGGLE_HOUSEHOLD_LIST_SEARCH_BAR:
      return {
        ...state,
        showHouseholdListSearchBar: !state.showHouseholdListSearchBar,
      }

    case SLEEP_GRAPH_DATA_BEGIN:
      return {
        ...state,
        households: addSleepData(state.households, action, { isFetching: true, ...action.params }),
      }

    case SLEEP_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        households: addSleepData(state.households, action, { isFetching: false, ...action.params }),
      }

    case SLEEP_GRAPH_DATA_ERROR:
      return {
        ...state,
        households: addSleepData(state.households, action, { isFetching: false, ...action.params }),
      }

    case HOUSEHOLD_CARER_LIST_BEGIN:
      return {
        ...state,
        households: updateHouseholdCarers(state.households, action, { fetchingCarers: true }),
      }

    case HOUSEHOLD_CARER_LIST_SUCCESS:
      return {
        ...state,
        households: updateHouseholdCarers(state.households, action, { fetchingCarers: false }),
      }

    case HOUSEHOLD_CARER_LIST_ERROR:
      return {
        ...state,
        households: updateHouseholdCarers(state.households, action, { fetchingCarers: false }),
      }

    case HOUSEHOLD_CARER_ADD_BEGIN:
      return {
        ...state,
        households: updateHouseholdCarers(state.households, action, { addingCarer: true }),
      }

    case HOUSEHOLD_CARER_ADD_SUCCESS:
      return {
        ...state,
        households: updateHouseholdCarers(state.households, action, { addingCarer: false }),
      }

    case HOUSEHOLD_CARER_ADD_ERROR:
      return {
        ...state,
        households: updateHouseholdCarers(state.households, action, { addingCarer: false }),
      }

    case HOUSEHOLD_CARER_REMOVE_BEGIN:
      return {
        ...state,
        households: updateHouseholdCarers(state.households, action, { removingCarer: true }),
      }

    case HOUSEHOLD_CARER_REMOVE_SUCCESS:
      return {
        ...state,
        households: updateHouseholdCarers(state.households, action, { removingCarer: false }),
      }

    case HOUSEHOLD_CARER_REMOVE_ERROR:
      return {
        ...state,
        households: updateHouseholdCarers(state.households, action, { removingCarer: false }),
      }

    case UPDATE_HEALTH_METRICS_SETTINGS:
      return {
        ...state,
        households: updateHouseholdHealthMetricsSettings(state.households, action),
      }

    case UPDATE_BEHAVIOUR_SETTING:
      return {
        ...state,
        households: updateHouseholdBehaviourSetting(state.households, action),
      }

    case HOUSEHOLDS_SENSORS_BEGIN:
      return updateSensorDetails(state, action, {
        sensorDetails: [],
        sensorDetailsFetching: true,
      })


    case HOUSEHOLDS_SENSORS_SUCCESS:
      return updateSensorDetails(state, action, {
        sensorDetails: action.data && action.data.sensors,
        sensorDetailsFetching: false,
      })

    case HOUSEHOLDS_SENSORS_ERROR:
      return {
        ...state,
        sensorDetails: [{ name: 'Error' }],
        sensorDetailsFetching: false,
      }

    case HOUSEHOLDS_SENSORS_DELETE_BEGIN:
      return {
        ...state,
        isDeletingSensor: true,
        isDeletedOK: false,
      }

    case HOUSEHOLDS_SENSORS_DELETE_SUCCESS:
      return {
        ...state,
        isDeletingSensor: false,
        deleted: true,
        sensorDeleteResult: action.data.result,
      }

    case HOUSEHOLDS_SENSORS_DELETE_ERROR:
      return {
        ...state,
        isDeletingSensor: false,
        sensorDeleteError: "Error removing the sensor from IotHub, perhaps the gateway isn't online.",
      }

    case HOUSEHOLDS_SENSORS_RESET_MODAL:
      return {
        ...state,
        isDeletingSensor: false,
        deleted: false,
        sensorDeleteError: undefined,
        sensorDeleteResult: undefined,
      }

    case UPDATE_HOUSEHOLD_SENSOR:
      return {
        ...state,
        sensorDetails: state.sensorDetails.map(sd => {
          if (sd.id !== action.sensor.id) return sd
          return {
            ...sd,
            ...action.sensor,
          }
        }),
      }

    case CONFIGURE_SENSOR_BEGIN:
      return {
        ...state,
        sensorConfiguring: true,
        sensorConfigurationError: undefined,
      }

    case CONFIGURE_SENSOR_ERROR:
      return {
        ...state,
        sensorConfiguring: false,
        sensorConfigurationError: action.error,
      }

    case CONFIGURE_SENSOR_SUCCESS:
      return {
        ...state,
        sensorConfiguring: false,
      }

    case UPDATE_AVATAR:
      return {
        ...state,
        households: updateHouseholdAvatar(state.households, action),
      }

    case RESET_BEHAVIOUR_BEGIN:
      return {
        ...state,
        isFetching: true,
      }

    case RESET_BEHAVIOUR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        resetBehaviourResult: "RESET_BEHAVIOUR_SUCCESS",
      }

    case RESET_BEHAVIOUR_ERROR:
      return {
        ...state,
        isFetching: false,
        resetBehaviourResult: "RESET_BEHAVIOUR_ERROR",
      }

    case CLEAR_RESET_BEHAVIOUR_RESULT:
      return {
        ...state,
        isFetching: false,
        resetBehaviourResult: null,
      }

    case DATA_SENSOR_EVENT_BEGIN:
      return {
        ...state,
        isFetching: true,
      }

    case DATA_SENSOR_EVENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        sensorEvents: action.data,
      }

    case DATA_SENSOR_EVENT_ERROR:
      return {
        ...state,
        isFetching: false,
        sensorEventsError: action.error,
      }

    case CLEAR_SENSOR_EVENTS:
      return {
        ...state,
        sensorEvents: [],
        sensorEventsError: {},
      }

    case GET_RESELLERS_BEGIN:
      return {
        ...state,
        isFetching: true,
      }

    case GET_RESELLERS_SUCCESS:
      return {
        ...state,
        resellers: action.data.resellers,
        isFetching: false,
      }

    case GET_RESELLERS_ERROR:
      return {
        ...state,
        resellersGetError: action.error,
      }

    case CREATE_RESELLER_BEGIN:
      return {
        ...state,
        isCreatingReseller: true,
      }

    case CREATE_RESELLER_SUCCESS:
      return {
        ...state,
        isCreatingReseller: false,
        createResellerResult: action.data,
        createResellerError: "",
      }

    case CREATE_RESELLER_ERROR:
      return {
        ...state,
        isCreatingReseller: false,
        createResellerError: action.error,
        createResellerResult: "",
      }

    case CLEAR_CREATE_RESELLER:
      return {
        ...state,
        isCreatingReseller: false,
        createResellerError: "",
        createResellerResult: "",
      }

    case GET_HOUSEHOLDS_BY_RESELLER_ID_BEGIN:
      return {
        ...state,
        isGettingHouseholds: true,
      }

    case GET_HOUSEHOLDS_BY_RESELLER_ID_SUCCESS:
      return {
        ...state,
        isGettingHouseholds: false,
        resellerHouseholds: action.data.households,
      }

    case GET_HOUSEHOLDS_BY_RESELLER_ID_ERROR:
      return {
        ...state,
        isGettingHouseholds: false,
        resellerHouseholdsGetError: action.error,
      }

    case UPDATE_RESELLER_BEGIN:
      return {
        ...state,
        isUpdatingReseller: true,
      }

    case UPDATE_RESELLER_SUCCESS:
      return {
        ...state,
        isUpdatingReseller: false,
        updateResellerSuccess: action.data,
        updateResellerError: "",
        resellers: state.resellers.map(reseller => {
          if (reseller.id === action.data.id) return action.data
          return reseller
        }),
      }

    case UPDATE_RESELLER_ERROR:
      return {
        ...state,
        isUpdatingReseller: false,
        updateResellerSuccess: "",
        updateResellerError: action.error,
      }

    case CLEAR_UPDATE_RESELLER:
      return {
        ...state,
        isUpdatingReseller: false,
        updateResellerError: "",
        updateResellerSuccess: "",
      }

    case GET_HOUSEHOLDS_BEGIN:
      return {
        ...state,
        isGettingAllHouseholds: true,
      }

    case GET_HOUSEHOLDS_SUCCESS:
      return {
        ...state,
        isGettingAllHouseholds: false,
        householdsList: action.data.households,
      }

    case GET_HOUSEHOLDS_ERROR:
      return {
        ...state,
        isGettingAllHouseholds: false,
        householdsGetError: action.error,
      }

    case LINK_HOUSEHOLD_TO_RESELLER_BEGIN:
      return {
        ...state,
        isLinkingHousehold: true,
      }

    case LINK_HOUSEHOLD_TO_RESELLER_SUCCESS: // Also used to unlink a household by writing 'None' to the resellerId
      return {
        ...state,
        isLinkingHousehold: false,
        linkHouseholdResult: action.data,
        // If the resellerId returns as None then we have unlinked so filter the item from the resellerHouseholds state array
        resellerHouseholds: action.data.result.resellerId === "None" ? state.resellerHouseholds.filter(household => household.id !== action.data.result.id) :
          // Otherwise it's a success linking so concat the result onto the array
          state.resellerHouseholds.concat(action.data.result),
        // Do the same for the households list - updates the resellerId to be None (which is what the API response is)
        householdsList: action.data.result.resellerId === "None" ? state.householdsList.map(household => {
          if (household.id === action.data.result.id) {
            household.resellerId = "None"
            return household
          }
          return household
        }) : state.householdsList,
      }
    case LINK_HOUSEHOLD_TO_RESELLER_ERROR:
      return {
        ...state,
        isLinkingHousehold: false,
        linkHouseholdError: action.error,
      }

    case CLEAR_LINK_HOUSEHOLD_TO_RESELLER:
      return {
        ...state,
        isLinkingHousehold: false,
        linkHouseholdError: "",
        linkHouseholdResult: "",
      }

    case DELETE_RESELLER_BEGIN:
      return {
        ...state,
        isDeletingReseller: true,
      }

    case DELETE_RESELLER_SUCCESS:
      return {
        ...state,
        isDeletingReseller: false,
        deleteResellerResult: action.data,
        resellers: state.resellers.filter(reseller => action.data.result !== reseller.id), // Return all the resellers that don't match the deleted ID
      }

    case DELETE_RESELLER_ERROR:
      return {
        ...state,
        isDeletingReseller: false,
        deleteResellerError: action.error,
      }

    case CLEAR_DELETE_RESELLER:
      return {
        ...state,
        isDeletingReseller: false,
        deleteResellerError: "",
        deleteResellerResult: "",
      }

    case SET_HOUSEHOLD_BILLING_START_DATE_BEGIN:
      return {
        ...state,
        isSettingBillingDate: true,
      }

    case SET_HOUSEHOLD_BILLING_START_DATE_SUCCESS:
      return {
        ...state,
        isSettingBillingDate: false,
        setBillingDateError: "",
        setBillingDateSuccess: action.data,
        householdsList: state.householdsList && state.householdsList.map(household => {
          if (household.id === action.data.id) {
            return action.data
          }
          return household
        }),
        resellerHouseholds: state.resellerHouseholds && state.resellerHouseholds.map(household => {
          if (household.id === action.data.id) {
            return action.data
          }
          return household
        }),
      }

    case SET_HOUSEHOLD_BILLING_START_DATE_ERROR:
      return {
        ...state,
        isSettingBillingDate: false,
        setBillingDateError: action.error,
        setBillingDateSuccess: "",
      }

    case CLEAR_SET_HOUSEHOLD_BILLING_START_DATE:
      return {
        ...state,
        isSettingBillingDate: false,
        setBillingDateError: "",
        setBillingDateSuccess: "",
      }

    case GET_HOUSEHOLD_FROM_GATEWAY_ID_BEGIN:
      return {
        ...state,
        isGettingHouseholdFriendlyName: true,
      }

    case GET_HOUSEHOLD_FROM_GATEWAY_ID_SUCCESS:
      return {
        ...state,
        isGettingHouseholdFriendlyName: false,
        householdFriendlyName: action.data[0].friendlyName,
      }

    case GET_HOUSEHOLD_FROM_GATEWAY_ID_ERROR:
      return {
        ...state,
        isGettingHouseholdFriendlyName: false,
        householdFriendlyName: "",
        householdFriendlyNameError: action.error,
      }

    case CLEAR_GET_HOUSEHOLD_FROM_GATEWAY_ID:
      return {
        ...state,
        isGettingHouseholdFriendlyName: false,
        householdFriendlyName: "",
        householdFriendlyNameError: "",
      }

    case LINK_INVOICE_TO_HOUSEHOLD_BEGIN:
      return {
        ...state,
        isLinkingInvoice: true,
      }

    case LINK_INVOICE_TO_HOUSEHOLD_SUCCESS:
      return {
        ...state,
        isLinkingInvoice: false,
        linkInvoiceSuccess: action.data,
        linkInvoiceError: "",
        householdsList: state.householdsList && state.householdsList.map(household => {
          if (household.id === action.data.id) {
            return action.data
          }
          return household
        }),
        resellerHouseholds: state.resellerHouseholds && state.resellerHouseholds.map(household => {
          if (household.id === action.data.id) {
            return action.data
          }
          return household
        }),
      }

    case LINK_INVOICE_TO_HOUSEHOLD_ERROR:
      return {
        ...state,
        isLinkingInvoice: false,
        linkInvoiceSuccess: "",
        linkInvoiceError: action.error,
      }

    case CLEAR_LINK_INVOICE_TO_HOUSEHOLD:
      return {
        ...state,
        isLinkingInvoice: false,
        linkInvoiceSuccess: "",
        linkInvoiceError: "",
      }

    case ADD_PENDANT_BEGIN:
      return {
        ...state,
        isAddingPendant: true,
      }

    case ADD_PENDANT_SUCCESS:
      return {
        ...state,
        isAddingPendant: false,
        addPendantResult: action.data,
        households: updateHouseholdsListOnSensorAdd(state, action.data),
      }

    case ADD_PENDANT_ERROR:
      return {
        ...state,
        isAddingPendant: false,
        addPendantError: action.error,
      }

    case UPDATE_PENDANT_BEGIN:
      return {
        ...state,
        isAddingPendant: true,
      }

    case UPDATE_PENDANT_SUCCESS:
      return {
        ...state,
        isAddingPendant: false,
        addPendantResult: action.data,
        households: updateHouseholdsListOnPendantChange(state, action.data),
      }

    case UPDATE_PENDANT_ERROR:
      return {
        ...state,
        isAddingPendant: false,
        addPendantError: action.error,
      }

    case CLEAR_ADD_PENDANT:
      return {
        ...state,
        isAddingPendant: false,
        addPendantResult: "",
        addPendantError: "",
      }
    case REMOVE_PENDANT_BEGIN:
      return {
        ...state,
        isDeletingSensor: true,
        isDeletedOK: false,
      }
    case REMOVE_PENDANT_SUCCESS:
      return {
        ...state,
        isDeletingSensor: false,
        deleted: true,
        sensorDeleteResult: action.data.id && "Pendant has been deleted",
        households: updateHouseholdsListOnPendantDelete(state, action.data.id),
      }
    case REMOVE_PENDANT_ERROR:
      return {
        ...state,
        isDeletingSensor: false,
        sensorDeleteError: "Error removing the pendant",
      }

    case UPDATE_BEHAVIOUR:
      return {
        ...state,
        households: updateBehaviour(state, action.params.householdId, action.params.behaviour),
      }

    // Filter out the deleted gateway from the households V2 list
    case CLEAR_DELETE_HOUSEHOLD_BY_GATEWAY_ID:
      return {
        ...state,
        households: state.households.filter(household => household.householdId !== action.householdId),
      }

    case ADD_GUARDIAN_AI_BEGIN:
      return {
        ...state,
        isAddingGuardian: true,
      }

    case ADD_GUARDIAN_AI_SUCCESS:
      return {
        ...state,
        isAddingGuardian: false,
        addGuardianResult: action.data,
        households: updateHouseholdsListOnSensorAdd(state, action.data),
      }

    case ADD_GUARDIAN_AI_ERROR:
      return {
        ...state,
        isAddingGuardian: false,
        addGuardianError: action.error,
      }

    case CLEAR_ADD_GUARDIAN_AI:
      return {
        ...state,
        isAddingGuardian: false,
        addGuardianError: "",
        addGuardianResult: "",
      }

    case GET_HOUSEHOLD_ACCESS_AUDIT_LOGS_BEGIN:
      return {
        ...state,
        isGettingAuditRecords: true,
      }

    case GET_HOUSEHOLD_ACCESS_AUDIT_LOGS_SUCCESS:
      return {
        ...state,
        isGettingAuditRecords: false,
        householdAuditRecords: action.data,
      }

    case GET_HOUSEHOLD_ACCESS_AUDIT_LOGS_ERROR:
      return {
        ...state,
        isGettingAuditRecords: false,
      }

    case UPDATE_INCIDENT_DETECTION_BEGIN:
      return {
        ...state,
        isUpdatingIncidentDetection: true,
        updateIncidentDetectionSuccess: false,
        updateIncidentDetectionFailed: false,
      };

    case UPDATE_INCIDENT_DETECTION_SUCCESS:
      return {
        ...state,
        isUpdatingIncidentDetection: false,
        updateIncidentDetectionSuccess: action.data,
      };

    case UPDATE_INCIDENT_DETECTION_ERROR:
      return {
        ...state,
        isUpdatingIncidentDetection: false,
        updateIncidentDetectionFailed: action.data,
      };

    case UPDATE_INCIDENT_DETECTION_CLEAR:
      return {
        ...state,
        isUpdatingIncidentDetection: false,
        updateIncidentDetectionSuccess: false,
        updateIncidentDetectionFailed: false,
      };

    default:
      return {
        ...state,
        households: graphsV2Reducer(state.households, action),
      }
  }
}

const mapActivityStream = (households, data, params) => {
  // this handles houses that are accessed with a deep url
  // or otherwise not yet returned by the household list api
  if (!(households.find(household => household.householdId === params.householdId))) {
    households.push({
      householdId: params.householdId,
    })
  }

  return households.map(household => {
    if (household.householdId !== params.householdId) return household
    const updatedHousehold = { ...household }
    if (!updatedHousehold.activity) {
      updatedHousehold.activity = { events: [] }
    }
    updatedHousehold.activity.isFetching = params.isFetching
    /**
     * Allow the event window to expand either end without
     * losing the window start and finish already called.
     * This is to help with fetching new data every few minutes,
     * while also being able to go back further in time.
     */
    // if (!household.activity.start ||
    if (moment(updatedHousehold.activity.start).isBefore(moment(params.start)))
      updatedHousehold.activity.start = params.start
    // if (!household.activity.end ||
    if (moment(updatedHousehold.activity.end).isAfter(moment(params.end)))
      updatedHousehold.activity.end = params.end

    if (data) {
      if (data.error) {
        // eslint-disable-next-line no-console
        console.error(`Error getting activity stream: ${data.error}`)
        updatedHousehold.activity.error = data.error
      } else {
        if (Array.isArray(data) && data.length > 0)
          updatedHousehold.activity.events = updatedHousehold.activity.events.concat(data)
      }
    }
    return updatedHousehold
  })
}

const mapSensorsState = (households, data, params) => {
  // this handles houses that are accessed with a deep url
  // or otherwise not yet returned by the household list api
  if (!(households.find(household => household.householdId === params.householdId))) {
    households.push({
      householdId: params.householdId,
    })
  }

  return households.map(household => {
    if (household.householdId !== params.householdId) return household
    const updatedHousehold = { ...household }
    if (!updatedHousehold.sensorsState) {
      updatedHousehold.sensorsState = { states: [] }
    }
    updatedHousehold.sensorsState.isFetching = params.isFetching


    if (data) {
      if (data.error) {
        // eslint-disable-next-line no-console
        console.error(`Error getting sensors state: ${data.error}`)
        updatedHousehold.sensorsState.error = data.error
      } else {
        if (Array.isArray(data) && data.length > 0)
          updatedHousehold.sensorsState.states = data
      }
    }
    return updatedHousehold
  })
}

// eslint-disable-next-line no-unused-vars
const mapFetchedAvatars = (households, data, params) => {
  return _.map(households, (household) => {
    if (!data[household.householdId]) return household

    if (data[household.householdId].error) {
      if (process.env.REACT_APP_APP_RESOURCE === 'WEB') {
        // eslint-disable-next-line no-console
        console.error(`Bad avatar for ${household.householdId}. Error: ${data[household.householdId].error}`)
      }
      return {
        ...household,
        avatar: "BAD_DATA",
      }
    }
    if (process.env.REACT_APP_APP_RESOURCE === 'WEB') {
      sessionStorage.setItem(
        `avatar_${household.householdId}`,
        data[household.householdId].avatar
      )
    }
    return {
      ...household,
      avatar: data[household.householdId].avatar,
    }
  })
}

const getCachedAvatar = (householdId) => {
  if (process.env.REACT_APP_APP_RESOURCE === 'WEB') {
    if (sessionStorage.getItem(`avatar_${householdId}`)) {
      return sessionStorage.getItem(`avatar_${householdId}`)
    }
  }
}


const mapHouseholdList = (households, data) => {

  // special case for when a household is loaded from the household page
  //const filteredHouseholds = (households.length === 1 && data.length > 0 && !(households[0].data && data[0].householdId == households[0].data.householdId) ? [] : households)

  const filteredHouseholds = (households.length === 1 && data.length > 0 && !(households[0].householdId && data[0].householdId === households[0].householdId) ? [] : households)
  let combinedHouseholds = [...filteredHouseholds]

  const mapDataToHouseholdIndex = data.reduce((acc, curr) => {
    acc[curr.householdId] = filteredHouseholds.findIndex(household => household.householdId === curr.householdId)
    return acc
  }, {})
  for (let household of data) {
    const { householdId } = household
    household.statusColor = disable // triagePriority === 0
    if (household.triagePriority === 1)
      household.statusColor = okay
    if (household.triagePriority === 2)
      household.statusColor = amber
    if (household.triagePriority === 3)
      household.statusColor = alert

    const householdIndex = mapDataToHouseholdIndex[householdId]
    household.behaviourRollup =
        remapBehaviourRollup(household.behaviourRollup)

    if (householdIndex === -1) {
      combinedHouseholds.push(household)
    } else {
      const avatar = getCachedAvatar(household.householdId)
      if (avatar) household.avatar = avatar
      const currHousehold = combinedHouseholds[householdIndex]
      combinedHouseholds[householdIndex] = { ...currHousehold, ...household }
    }
  }
  return combinedHouseholds


}

const remapBehaviourRollup = (behaviourRollup) => {
  const classifications = Object.keys(BehaviourClassification).reduce((p, k) => {
    // behaviourRollup uses lowercase keys, convert to proper keys and get values
    p[k] = null
    if (behaviourRollup)
      p[k] = behaviourRollup[BehaviourClassification[k]]
    return p
  }, {})
  return classifications
}

const householdListHasMore = (data, params) => {
  if (params && params.triagePriority !== undefined && params.systemHealthStatus !== undefined) return false;
  if (params && params.pageSize && data && data.length < params.pageSize && !params.dontSaveQuery) return false
  return true
}

const getCleanSearchQuery = (params, lastSearchQuery) => {
  if (!params.searchQuery || params.dontSaveQuery) return lastSearchQuery
  const cleanQuery = params.searchQuery.replace(/\*$/, '')
  return cleanQuery
}

const addSleepData = (households, action, params) => {
  return households.map(h => {
    if (h.householdId !== params.householdId) return h

    h.sleepData = {
      isFetching: params.isFetching,
      data: action.data,
    }

    return h
  })
}

const updateHouseholdCarers = (households, action, params) => {
  return households.map(h => {
    if (h.householdId === action.params.householdId) {
      let household = {
        ...h,
        carers: {
          data: action.data,
          error: action.error,
          ...params,
        },
      }
      return household
    }
    return h
  })
}

const updateHouseholdHealthMetricsSettings = (households, data) => {
  //console.log(data)
  return _.map(households, (h) => {
    if (h.householdId !== data.householdId) {
      return h
    }

    let household = { ...h }
    for (const setting in data.settings) {
      if (setting.match(/^healthMetrics\..*/)) {
        if (!household.data.healthMetrics) {
          household.data.healthMetrics = []
        }
        const settingSplit = setting.split('.')
        const metricName = settingSplit[1]
        let metric = household.data.healthMetrics.find(function (element) {
          return element.metricName == metricName
        })

        if (!metric) {
          metric = {
            lowerThreshold: null,
            upperThreshold: null,
            metricName: metricName,
            syncedWith: "",
            units: "",
          }
          household.data.healthMetrics.push(metric)
        }

        const settingName = settingSplit[2]
        if (settingName === 'lowerThreshold') {
          metric.lowerThreshold = data.settings[setting]
        }
        else if (settingName === 'upperThreshold') {
          metric.upperThreshold = data.settings[setting]
        }
        else if (settingName === 'syncedWith') {
          metric.syncedWith = data.settings[setting]
        }
        else if (settingName === 'units') {
          metric.units = data.settings[setting]
        }
      }
    }
    return household
  })
}

/**
 * Adds properties to be updates in the state.
 */
const updateHouseholdBehaviourSetting = (households, data) => {
  return _.map(households, (h) => {
    if (h.householdId !== data.id) {
      return h
    }
    let household = { ...h }
    if (data.setting.match(/^timezone$/)) {
      household.data.timezone = data.value
      household.timezone = data.value
      return household
    }

    if (data.setting.match(/^householdStatus$/)) {
      household.data.householdStatus = data.value
      household.householdStatus = data.value
      return household
    }

    if (data.setting.match(/^responsePlan$/)) {
      household.data.responsePlan = data.value
      return household
    }

    if (data.setting.match(/^primaryContact.name$/)) {
      if (!household.data.primaryContact) {
        household.data.primaryContact = {}
      }
      household.data.primaryContact.name = data.value
      return household
    }

    if (data.setting.match(/^primaryContact.phone$/)) {
      if (!household.data.primaryContact) {
        household.data.primaryContact = {}
      }
      household.data.primaryContact.phone = data.value
      return household
    }

    if (data.setting.match(/^primaryContact.address$/)) {
      if (!household.data.primaryContact) {
        household.data.primaryContact = {}
      }
      household.data.primaryContact.address = data.value
      return household
    }

    if (data.setting === 'sleepingStart') {
      if (!household.data) {
        household.data = {}
      }
      household.data.sleepingStart = data.value
      return household
    }
    if (data.setting === 'sleepingFinish') {
      if (!household.data) {
        household.data = {}
      }
      household.data.sleepingFinish = data.value
      return household
    }
    if (data.setting === 'monitorService') {
      household.data.monitorService = data.value || {}
    }
    if (data.setting.match(/^fitbitData\..*$/)) {
      if (!household.data.fitbitData) {
        household.data.fitbitData = {}
      }
      if (data.setting.match(/^fitbitData.synced$/)) {
        household.data.fitbitData.synced = data.value
      } else if (data.setting.match(/^fitbitData.lastSynced$/)) {
        household.data.fitbitData.lastSynced = data.value
      }
    }

    if (data.setting.match(/^personaSync.lastSynced$/)) {
      if (!household.data.personaSync) {
        household.data.personaSync = {}
      }
      household.data.personaSync.lastSynced = data.value
    }

    if (data.setting.match(/^alexaData.synced$/)) {
      if (!household.data.alexaData) {
        household.data.alexaData = {}
      }
      household.data.alexaData.synced = data.value
    }
    if (data.setting.match(/^features\..*$/)) {
      if (!household.data.features) {
        household.data.features = {}
      }
      if (data.setting.match(/^features.enableHealthMetrics$/)) {
        household.data.features.enableHealthMetrics = data.value
      } else if (data.setting.match(/^features.enablePersonaHealthMetrics$/)) {
        household.data.features.enablePersonaHealthMetrics = data.value
      }
    }

    if (data.setting.match(/^friendlyName$/)) {
      household.friendlyName = data.value
      household.data.friendlyName = data.value
    } else {
      let behaviourIndex = _.findIndex(household.data.behaviours, { 'name': data.setting.split('.')[1] })
      if (behaviourIndex === -1) {
        return household
      }
      if (data.setting.match(/^behaviours\..*\.enabled$/)) {
        household.data.behaviours[behaviourIndex].enabled = data.value
      } else if (data.setting.match(/^behaviours\.mealPrep01\.alertLimitInHours$/)) {
        const mealPrepBehaviour = household.data.behaviours.find(b => b.name === 'mealPrep01')
        mealPrepBehaviour.alertLimitInHours = data.value
      } else if (data.setting.match(/^behaviours\.mealPrep01\.warnLimitInHours$/)) {
        const mealPrepBehaviour = household.data.behaviours.find(b => b.name === 'mealPrep01')
        mealPrepBehaviour.warnLimitInHours = data.value
      } else if (data.setting.match(/^behaviours\.washing01\.warnLimitInDays$/)) {
        const washingBehaviour = household.data.behaviours.find(b => b.name === 'washing01')
        washingBehaviour.timeLimitDays = data.value
      } else if (data.setting.match(/^behaviours\.socialIsolation01\.warnLimitInDays$/)) {
        const socialIsolationBehaviour = household.data.behaviours.find(b => b.name === 'socialIsolation01')
        if (isNaN(+data.value)) throw Error("social isolation alert limit should be a number");
        socialIsolationBehaviour.timeLimitDays = data.value
      } else if (data.setting.match(/^behaviours\.climate01\.temperatureUnits$/)) {
        const climateBehaviour = household.data.behaviours.find(b => b.name === 'climate01')
        climateBehaviour.temperatureUnits = data.value
      } else if (data.setting.match(/^behaviours\.climate01\..+\..+$/)) {
        if (isNaN(+data.value)) throw Error("Climate alert limit should be a number");
        const behaviour = household.data.behaviours[behaviourIndex];
        const room = data.setting.split('.')[2];
        const tempAttributes = data.setting.split('.')[3];
        const attributes = ['lowerClimateLimit', 'upperClimateLimit', 'roomEnabled'];
        if (!attributes.includes(tempAttributes)) { throw Error("Temperature Attributes should be either lower or upper, or enable"); }
        if (!behaviour.thresholdSetting) {
          behaviour.thresholdSetting = {};
        }
        if (!behaviour.thresholdSetting[room]) {
          behaviour.thresholdSetting[room] = {};
        }
        behaviour.thresholdSetting[room][tempAttributes] = Number(data.value);
      } else if (household.data.behaviours[behaviourIndex].userConfiguration &&
        household.data.behaviours[behaviourIndex].userConfiguration[data.setting.split('.')[2]] !== -1) {
        household.data.behaviours[behaviourIndex].userConfiguration[data.setting.split('.')[2]].value = data.value
      }
      household.data.behaviours = [...household.data.behaviours]
    }

    return household
  })
}

function updateSensorDetails(state, action, newState) {
  if (action.params.method === 'PUT') {
    return state
  }
  return {
    ...state,
    ...newState,
  }
}

const updateHouseholdAvatar = (households, data) => {
  return _.map(households, (household) => {
    if (household.householdId !== data.id) {
      return household
    }

    sessionStorage.setItem(
      `avatar_${household.householdId}`,
      data.avatar
    )

    return {
      ...household,
      avatar: data.avatar,
    }
  })
}