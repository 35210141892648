import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// @ts-ignore
import InfiniteScroll from 'react-infinite-scroller'

import { HouseholdListItem } from './HouseholdListItem'

interface HouseholdListTableProps {
  households: Household[];
  onShowSnack: () => void;
  fetchActivityfeedV2: () => void;
  activityStreamEventsError: string;
  isGettingActivityEvents: boolean;
  activityStreamEvents: [];
  loadMore: () => void;
  viewHousehold: () => void;
  useHouseholdv2: boolean;
  isDeveloper: boolean;
  isSupport: boolean;
  isDemoUser: boolean;
  getHousehold: () => void;
  hasMore: boolean;
  loader: JSX.Element;
}

export interface Household {
  alertCount: 1,
  behaviourRollup: {
    MEAL_PREP: string;
    SLEEP: string;
    ACTIVITY: string;
    CLIMATE: string;
    PRESENCE: string;
    DOMESTIC: string;
    DOOR_ALERT: string;
    DOOR_INFO_MESSAGE: string
  };
  friendlyName: string;
  hasAvatar: boolean;
  householdId: string;
  notificationCount: number;
  systemHealthStatus: string;
  timezone: string;
  triagePriority: number;
  householdStatus: string;
  healthMetricsRollup: { [key: string]: { status: string } };
  healthMetricsEnabled: boolean;
  avatar: string;
  data: {
    isFetching: boolean,
    behaviours: any[]
  }
}

export const HouseholdListTable = ({
  households,
  onShowSnack,
  fetchActivityfeedV2,
  activityStreamEventsError,
  isGettingActivityEvents,
  viewHousehold,
  activityStreamEvents,
  useHouseholdv2,
  isDeveloper,
  isSupport,
  isDemoUser,
  getHousehold,
  loadMore,
  hasMore,
  loader,
}: HouseholdListTableProps) => {

  const householdListItems = households.map((household) => {
    if (household.householdId) {
      return (
        <HouseholdListItem
          key={household.householdId}
          onShowSnack={onShowSnack}
          fetchActivityfeedV2={fetchActivityfeedV2}
          activityStreamEventsError={activityStreamEventsError}
          isGettingActivityEvents={isGettingActivityEvents}
          activityStreamEvents={activityStreamEvents}
          household={household}
          viewHousehold={viewHousehold}
          useHouseholdv2={useHouseholdv2}
          isDeveloper={isDeveloper}
          isSupport={isSupport}
          isDemoUser={isDemoUser}
          getHousehold={getHousehold} />)
    }
  })

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={loadMore}
      hasMore={hasMore}
      loader={loader}>
      <TableContainer component={Paper}>
        <Table aria-label="Household List Table" >
          <TableHead>
            <TableRow >
              <TableCell style={{ padding: "10px 10px 10px 16px" }} > Household</TableCell>
              <TableCell align="center" style={styles.tableHeadCell}>Activity</TableCell>
              <TableCell align="center" style={styles.tableHeadCell}>Meal Preparation</TableCell>
              <TableCell align="center" style={styles.tableHeadCell}>Climate</TableCell>
              <TableCell align="center" style={styles.tableHeadCell}>Sleep</TableCell>
              <TableCell align="center" style={styles.tableHeadCell}>Domestic Activity</TableCell>
              <TableCell align="center" style={styles.tableHeadCell}>Presence</TableCell>
              <TableCell align="center" style={styles.tableHeadCell}>Health Metrics</TableCell>
              <TableCell align="center" style={styles.tableHeadCell}>System Health</TableCell>
            </TableRow>
          </TableHead>
          <TableBody >
            {householdListItems && householdListItems.length > 0 && householdListItems}
          </TableBody>
        </Table>
      </TableContainer>
    </InfiniteScroll >
  )
}

const styles = {
  tableHeadCell: {
    padding: "10px",
  },
}