import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { HouseholdCard } from "./HouseholdCard";
import Skeleton from 'react-loading-skeleton';
import { appInsights } from "../../../api/AppInsights";
import Title from "../../../components/Title";

const skeletonItems = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
export interface Household {
  householdId: string;
  friendlyName: string;
  data: {
    sensors: Sensor[];
  };
}

interface Sensor {
  friendlyName: string;
  lastSeen: string;
  type: string;
  roomType: string;
  roomName: string;
}

interface RealtimeStatusProps {
  households: Household[];
  token: string;
  isFetching: boolean;
  purgeList(): void;
  getHouseholdListNextPage(pagePosition: number, searchQuery: string, order: string, householdStatus: string, systemHealthStatus: string, triagePriority: string): void;
}

class RealtimeStatus extends Component<RealtimeStatusProps> {
  state = {
    updateSpeed: 90000,
    query: "",
  };

  componentDidMount() {
    appInsights.trackPageView({ name: "Real Time Status" });
  }

  handleUpdateSpeed = (updateSpeed: string | unknown) => {
    this.setState({ updateSpeed: Number(updateSpeed) });
  };

  handleSearch = (query: string) => {
    const { getHouseholdListNextPage, purgeList } = this.props;

    this.setState({ query: query });

    if (query.length >= 3) {
      purgeList();
      getHouseholdListNextPage(0, query, "triage", "monitored", "", "");
    }
  };

  clearSearch = () => {
    const { getHouseholdListNextPage, purgeList } = this.props;

    this.setState({ query: "" });
    purgeList();
    getHouseholdListNextPage(0, "", "triage", "monitored", "", "");
  };

  render() {
    const { households, token, isFetching } = this.props;
    const { updateSpeed, query } = this.state;

    return (
      <div style={styles.container}>
        <Title title="Realtime Status" />
        <div style={styles.menuContainer}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={() => this.clearSearch()}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => this.handleSearch(e.target.value)}
                variant="outlined"
                value={query}
                fullWidth
                placeholder="Search households"
              />
            </Grid>
            <Grid item xs={4}>
              <Select fullWidth onChange={(e) => this.handleUpdateSpeed(e.target.value)} value={updateSpeed} variant="outlined" placeholder="Search">
                <MenuItem value={15000}>Update every 15 seconds</MenuItem>
                <MenuItem value={60000}>Update every 60 seconds</MenuItem>
                <MenuItem value={90000}>Update every 90 seconds</MenuItem>
                <MenuItem value={300000}>Update every 5 minutes</MenuItem>
                <MenuItem value={1800000}>Update every half hour</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </div>

        <Grid container>
          {!isFetching ? (
            households.map((household, key) => {
              return <HouseholdCard updateSpeed={updateSpeed} token={token} key={key} household={household} />;
            })
          ) : (
            <Grid container>
              {skeletonItems.map((item, key) => {
                return <Grid key={key} item xs={12} sm={12} md={12} lg={6} xl={4} style={styles.placeholderItem}>
                  <Card style={{ padding: 12 }}>
                    <Skeleton circle={true} height={40} width={40} style={{ margin: 4 }}/>
                    <Skeleton />
                    <Skeleton height={46} style={{ marginTop: 12 }}/>
                  </Card>
                </Grid>
              })}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

const styles = {
  container: {
    backgroundColor: "#FAFAFA",
  },
  menuContainer: {
    margin: 12,
    paddingTop: 12,
  },
  placeholderItem: {
    padding: 12,
  },
};

export default RealtimeStatus;
