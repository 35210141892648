// Generic add modal
import { useState } from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useValidation } from './hooks/useValidation';

interface AddZoneModalProps
{
  title: string;
  open: boolean;
  close: () => void;
  contentText: string;
  inputBoxDescription: string;
  confirmAction: (data: string) => void;
}



export const AddZoneModal = ({ title, open, close, confirmAction, contentText, inputBoxDescription }: AddZoneModalProps) =>
{
  const [ handleTextChange, error, data, setData ] = useValidation();

  return(
    <Dialog open={open} onClose={close} TransitionProps={{ onEntering: () => setData("") }} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {contentText}
        </DialogContentText>

        <TextField
          value={data}
          onChange={handleTextChange}
          autoFocus
          error={error ? true : false}
          label={error ? error : inputBoxDescription}
          fullWidth
          variant="standard"
        />


      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button onClick={() => confirmAction(data)} disabled={error ? true : false }>Save</Button>
      </DialogActions>
    </Dialog>
  )
}