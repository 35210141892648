import React, { Component } from 'react'

// Styles
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './SystemViewStyles'

// Custom components
import Title from '../../../components/Title'
import DocumentTitle from 'react-document-title'

// Material UI components
import { Tab, Tabs } from '@material-ui/core'

// Icons
import BatteryFull from '@mui/icons-material/BatteryFull'
import AssessmentIcon from '@mui/icons-material/Assessment';
import HouseholdHealthIcon from '@mui/icons-material/Home'
import SystemHealthIcon from '@mui/icons-material/Router'
import NotificationImportant from '@mui/icons-material/NotificationImportant'

// Tab Screens
import BatteryReportTab from './Tabs/BatteryReportTab'
import BatteryReportGraphTab from './Tabs/BatteryReportGraphTab'
import HouseholdHealthTab from './Tabs/HouseholdHealthTab/Container'
import SystemHealthTab from './Tabs/SystemHealthTab/Container'
import GsmStatisticsTab from './Tabs/GsmStatisticsTab'

import { appInsights } from '../../../api/AppInsights'
const PAGE_NAME = 'systemView'
const PAGE_TITLE = 'InteliCare System Health'

const SYSTEM_HEALTH_TAB = 0;
const HOUSEHOLD_HEALTH_TAB = 1;
const GSM_STATISTICS_TAB = 2
const BATTERY_REPORT_TAB = 3
const BATTERY_REPORT_GRAPH_TAB = 4


class SystemView extends Component {
  static pageName = PAGE_NAME
  static friendlyName = PAGE_TITLE

  state = {
    currentTab: 0,
  }

  componentDidMount()
  {
    appInsights.trackPageView({ name: "System Health" })
  }


  render() {
    return (
      <div style={{ padding: 20 }}>
        <DocumentTitle title='System Health' />
        <Title title='InteliCare System Health' />

        <Tabs
          style={{ paddingBottom: 16 }}
          indicatorColor="secondary"
          textColor="secondary"
          onChange={(e, index) => this.setState({ currentTab: index })}
          value={this.state.currentTab}>
          <Tab icon={<SystemHealthIcon />} label="System Health"></Tab>
          <Tab icon={<HouseholdHealthIcon />} label="Household Health"></Tab>
          <Tab icon={<NotificationImportant />} label="GSM Statistics"></Tab>
          <Tab icon={<BatteryFull />} label="Battery Reports"></Tab>
          <Tab icon={<AssessmentIcon />} label="Battery Graphs"></Tab>
        </Tabs>

        {this.state.currentTab === SYSTEM_HEALTH_TAB && <SystemHealthTab />}
        {this.state.currentTab === HOUSEHOLD_HEALTH_TAB && <HouseholdHealthTab />}
        {this.state.currentTab === GSM_STATISTICS_TAB && <GsmStatisticsTab />}
        {this.state.currentTab === BATTERY_REPORT_TAB && <BatteryReportTab />}
        {this.state.currentTab === BATTERY_REPORT_GRAPH_TAB && <BatteryReportGraphTab />}




      </div>
    )
  }
}

export default withStyles(styles)(SystemView)
