import React from "react";
import Grid from '@material-ui/core/Grid'
import { Component } from "react";
import { BehaviourItem } from "./BehaviourItem";
import { BehaviourItemV2 } from "./BehaviourItemV2";

import { BehavioursGraphData } from "../../../../../api/BehavioursGraphApi";

interface BehavioursProps {
  graphs: BehavioursGraphData;
  timestampFormat: "W" | "M";
}
export class BehavioursGrid extends Component<BehavioursProps> {

  render() {
    const { graphs, timestampFormat } = this.props;

    return (
      <Grid container>

        {graphs && graphs.data && graphs.data.map((item, key) => {

          if(item && item.graphData && item.graphData.length > 0)
          {
            return <BehaviourItemV2
              height={350}
              key={key}
              behaviourItem={item} />
          }
        })}
      </Grid>
    );
  }
}

