// TODO: Merge styles using components/Utils/CombineStyles and styles/defaultStyles
export default theme => {
  return {
    menuText: theme.mainMenu.text,
    title: {
      fontSize: '24px',
      color: theme.palette.primary.contrastText,
    },
    icon: {
      paddingTop: '1.5px',
    },
    closeIcon: {
      color: theme.palette.primary.contrastText,
    },
    label: {
      paddingTop: '1rem',
    },
    modified: {
      paddingTop: '1rem',
      fontSize: '1.5em',
      // padding: '0.5em',
      display: 'inline-flex',
      height: '100%',
      // alignItems: 'center',
      // justifyItems: 'center',
    },
    textInput: {
      width: '95%',
    },
    photoUploader: {
      width: '95%',
    },
    card: {
      minWidth: 450,
      maxWidth: 450,
    },
    cardActions: {
      alignItems: 'right',
    },
    portalList: {
      // maxHeight: '100%',
      overflow: 'auto',
      width: '100%',
    },
    listItem: {
      padding: '8px',
      borderRadius: '5px',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      width: '95%',
    },
    confirmDialog: {
      zIndex: 4000,
    },
    colors: {
      colorPrimary: '#c4274e',
    },
    fillCell: {
      width: '100%',
    },
    focusedEmail: {
      '&$cssFocused $notchedOutline': {
        border: '1px solid lightgrey',
      },
      width: '95%',
    },
    cssFocused: {},
    notchedOutline: {},
    emailError: {
      '&$cssFocused $notchedOutline': {
        border: '2px solid red',
      },
    },
  }
}
