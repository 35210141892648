import React from 'react'
import PropTypes from 'prop-types'
import { Chart } from 'react-google-charts'
import CircularProgress from '@material-ui/core/CircularProgress'

const BatteryGraph = (props) => {

  BatteryGraph.propTypes = {
    data: PropTypes.array,
  }


  return (
    <Chart
      width={'auto'}
      height={'600px'}
      chartType="Line"
      loader={<div style={{ textAlign: "center" }}><CircularProgress /></div>}
      data={props.data}
      options={{
        chart: {
          title: 'Sensor Battery Levels',
          subtitle: 'Shows sensor battery levels over the year',

        },
      }}
      rootProps={{ 'data-testid': '3' }}
    />
  )
}

export default BatteryGraph