import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, TextField, Grid } from '@material-ui/core'
import AddIcon from '@mui/icons-material/AddCircle'


class ActionBar extends Component {
  render() {
    const { onAddButtonClicked, onTextChange } = this.props
    return (
      <div style={styles.container}>
        <Grid container>
          <TextField
            onChange={(e) => onTextChange(e.target.value)}
            placeholder="Search for a partner"
            style={styles.searchInput}
            label="Search a partner"
            variant="outlined">
          </TextField>
          <Button
            color="primary"
            onClick={onAddButtonClicked}
            size="large"
            style={styles.addButton}
            startIcon={<AddIcon />}
            variant="contained">Add</Button>
        </Grid>
      </div>
    )
  }
}

ActionBar.propTypes = {
  onTextChange: PropTypes.func,
  onAddButtonClicked: PropTypes.func,
}

const styles = {
  container: {
    paddingTop: 25,
    alignContent: "flex-end",
  },
  searchInput: {
    flex: 1,
  },
  addButton: {
    marginLeft: 30,
    backgroundColor: "#E91E63",
  },
}

export default ActionBar