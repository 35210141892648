import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

// Material UI components
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import Alert from '@material-ui/lab/Alert'

import { HealthMetric } from '../../../../../../model/HealthMetric'
import { FAHRENHEIT } from '../../../../../../helpers/healthMetrics'


// Time format that is required for the TextField Input
const MOMENT_TIMESTAMP_VALUE_FORMAT = "YYYY-MM-DDTHH:mm"
// Database timestamp format
const DB_FORMAT_TIMESTAMP = "YYYY-MM-DD hh:mm A"

const now = moment().format(MOMENT_TIMESTAMP_VALUE_FORMAT)


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const AddMetricModal = ({ handleClose, open, metricData, addMetric, addError, addResult, activeHouseholdId,
  clearAddMetric, isAddingHealthMetric, onUpdateList, temperatureUnits }) => {

  AddMetricModal.propTypes = {
    handleClose: PropTypes.func,
    open: PropTypes.bool,
    valueType: PropTypes.string,
    metricData: PropTypes.object,
    addMetric: PropTypes.func,
    addError: PropTypes.string,
    activeHouseholdId: PropTypes.string,
    clearAddMetric: PropTypes.func,
    addResult: PropTypes.any,
    isAddingHealthMetric: PropTypes.bool,
    onUpdateList: PropTypes.func,
    temperatureUnits: PropTypes.string,
  }

  const { metricType, valueType, doubleMetric } = metricData
  const [value, setValue] = useState("")
  const [value2, setValue2] = useState("") // For Resting Heart Rate
  const [timestamp, setTimestamp] = useState(now)
  const [isInputError, setInputError] = useState(false)
  const isBloodPressure = metricType === "BLOOD_PRESSURE"
  const isHeartRate = metricType === "HEART_RATE"

  useEffect(() => {
  })

  const closePopup = () => {
    setInputError(false)
    handleClose()
  }

  const handleInputChange = (evt, first) => {
    const value = evt.target.value
    if (value.length <= 8 && (isBloodPressure || isValidInput(value))) {
      if (first) {
        setValue(value)
      } else {
        setValue2(value)
      }
    }
  }

  const handleInputChange2 = (evt) => {
    const value = evt.target.value
    if (isBloodPressure || isValidInput(value)) {
      setValue2(value)
    }
  }

  const isValidInput = (inputValue) => {
    if (inputValue === "") {
      return true
    } else if (isBloodPressure) {
      const bpReg = new RegExp('^[0-9]+/[0-9]+$') // Should be of the form 120/80
      return bpReg.test(inputValue)
    } else {
      return !isNaN(inputValue) && !inputValue.includes('-') && !inputValue.includes('e') && inputValue[0] != '0' // Should be a number
    }
  }

  const convertToCelsius = (value) => {
    if (!value && value !== 0) {
      return ""
    }
    return Math.round(((value-32)/1.8)*100)/100 // Round to 2 decimal places
  }

  const save = () => {
    if((value || value2) && isValidInput(value) && (isValidInput(value2)))
    {
      const healthMetric = new HealthMetric(
        metricType,
        value,
        moment(timestamp).utc().format(DB_FORMAT_TIMESTAMP),
        activeHouseholdId,
        "manualInput")

      closePopup()
      if (value) {
        const metricCopy = JSON.parse(JSON.stringify(healthMetric))
        if (healthMetric.metricType === "TEMPERATURE" && temperatureUnits === FAHRENHEIT) {
          healthMetric.metricValue = convertToCelsius(healthMetric.metricValue)
        }
        addMetric(healthMetric)

        // Close if there are no errors
        clearAddMetric()

        if (isBloodPressure) {
          metricCopy.metricValue = metricCopy.metricValue.split('/')
        }
        onUpdateList(metricCopy)
      }
      if (value2) { // Only used for RESTING_HEART_RATE and BMI
        const metric2 = Object.assign({}, healthMetric)
        metric2.metricType = isHeartRate ? "RESTING_HEART_RATE" : "BMI"
        metric2.metricValue = value2
        addMetric(metric2)
        clearAddMetric()
        onUpdateList(metric2)
      }
    }
    else
    {
      setInputError(true)
    }
  }

  const cleanUp = () => {
    clearAddMetric()
    setValue("")
    setValue2("")
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onEntering={() => setTimestamp(moment().format(MOMENT_TIMESTAMP_VALUE_FORMAT))}
      onExiting={() => cleanUp()}
      onClose={closePopup}
    >
      <DialogTitle id="add-health-metric-dialog-slide-title">{`Add ${isHeartRate ? "Heart Rate" : metricData.friendlyName}`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="add-health-metric-dialog-slide-description">
          Manually add a health metric for {isHeartRate ? "Heart Rate" : metricData.friendlyName}
        </DialogContentText>

        <Grid container spacing={3} direction="column">
          <Grid item>
            <TextField
              autoFocus
              InputProps={{
                endAdornment: <InputAdornment position="end">{doubleMetric ? valueType[0] : valueType}</InputAdornment>,
              }}
              value={value}
              onChange={(e) => handleInputChange(e, true)}
              style={styles.input}
              label={metricData.friendlyName}
              placeholder="Input a value"
              variant="outlined"
              helperText={isBloodPressure ? "Please input a value in the form 120/80" : ""}></TextField>
          </Grid>
          {doubleMetric &&
            <Grid item>
              <TextField
                InputProps={{
                  endAdornment: <InputAdornment position="end">{valueType[1]}</InputAdornment>,
                }}
                value={value2}
                onChange={(e) => handleInputChange(e, false)}
                style={styles.input}
                label={isHeartRate ? "Resting Heart Rate" : "BMI"}
                placeholder="Input a value"
                variant="outlined"></TextField>
            </Grid>
          }
          <Grid item>
            <TextField
              value={timestamp}
              onChange={(e) => {
                const newTimestamp = e.target.value
                if (!moment(newTimestamp).isAfter(moment.now())) {
                  setTimestamp(newTimestamp)
                }
              }}
              style={styles.input}
              label="Reading Time"
              type="datetime-local"
              variant="outlined"></TextField>
          </Grid>
        </Grid>
        {addError && <Alert style={styles.alert} severity="error">{addError}</Alert>}
        {isInputError && <Alert style={styles.alert} severity="error">Invalid input value</Alert>}
      </DialogContent>
      <DialogActions>
        <Button onClick={closePopup} color="primary">
          {addResult && !isAddingHealthMetric ? "Close" : "Cancel"}
        </Button>
        <Button onClick={() => save()} color="primary">
          {isAddingHealthMetric ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  input: {
    width: "100%",
  },
  alert: {
    marginTop: 6,
  },
}

export default AddMetricModal