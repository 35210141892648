import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
} from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'

import _ from 'lodash'
import moment from "moment-timezone"
import access from 'safe-access'

import * as sharedStore from '../../redux'
import './MultiActivityGraphViewBreakdown.scss'
import * as d3 from 'd3'
const GraphService = sharedStore.activityService
const nightime = sharedStore.colors.nightTime



export const DEFAULT_HEIGHT = 100

class MultiActivityGraphView extends Component {
  constructor(props) {
    super(props)
    this.activityGraphRef = React.createRef()
    this.state = {
      colors: d3.scaleOrdinal(props.theme.graphColors.setB),
      width: window.innerWidth - 200,
      height: DEFAULT_HEIGHT,
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this._resizeDebounce = _.debounce(this.updateWindowDimensions, 150)
  }

  componentDidMount() {
    const { width } = this.state
    const { data } = this.props
    window.addEventListener('resize', this._resizeDebounce)
    this.updateGraph(data, width)

  }

  componentDidUpdate(lastProps, lastState) {
    const { width } = this.state
    const { data } = this.props
    if (data !== lastProps.data || width !== lastState.width) {
      this.updateGraph(this.props.data, this.state.width)
    }
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this._resizeDebounce)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth - 200 })
  }


  drawNightBars(svg, nightbars) {
    nightbars.forEach((n) => {
      svg.append("rect")
        .attr("x", n.x)
        .attr("y", 0)
        .attr("width", n.width)
        .attr("height", DEFAULT_HEIGHT - 20)
        .attr("fill", nightime)
    })
  }

  updateGraph = (data, width = this.state.width) => {
    const { household } = this.props
    const { timezone } = household
    let size = {
      width: width,
      height: DEFAULT_HEIGHT,
    }
    d3.select(this.activityGraphRef.current)
      .select("svg")
      .remove()
    //d3 get div and add svg element of given size
    var svg = d3.select(this.activityGraphRef.current)
      .append("svg")
      .attr("viewBox", "0 0 " + width + " " + DEFAULT_HEIGHT)
      // .attr("width", width)
      .attr("width", "100%")
      .attr("height", DEFAULT_HEIGHT)

    if (data == null || data.info === undefined || data.length === 0) {
      svg.append("text")
        .text("No activity recorded for this period")
        .attr("x", (width) / 2)
        .attr("y", DEFAULT_HEIGHT / 2 + 5)
        .attr("text-anchor", "middle")
        .attr("font-size", 24)
      return
    }

    var { sensors, buckets, starttime, endtime } = data.info

    const maxCount = data.data.reduce((p, cv) => {
      for (let key in cv) {
        if (key !== "timestamp") {
          if (p[key] === undefined) {
            p[key] = cv[key]
          }
          if (cv[key] > p[key]) {
            p[key] = cv[key]
          }
        }
      }
      return p
    }, {})

    sensors = Object.keys(maxCount)

    var startTime
    var endTime
    if (timezone) {
      startTime = moment(starttime).tz(timezone)
      endTime = moment(endtime).tz(timezone)
    } else {
      startTime = moment(starttime)
      endTime = moment(endtime)
    }
    //create a scale of type time (maps from time value to drawing)
    var timescale = d3.scaleTime()
      .domain([startTime, endTime])
      .range([20, width - 20])

    var barWidth = (width - 20) / buckets

    var nightbars = GraphService.getNightbars(timescale, size, d3, household)

    this.drawNightBars(svg, nightbars)

    const maxSum = sensors.reduce((p, c) => p + maxCount[c], 0)
    const sliceHeight = (DEFAULT_HEIGHT - 40) / maxSum
    const sliceRatios = sensors.reduce((p, s) => {
      p[s] = {
        height: sliceHeight,
        start: p.sum + sliceHeight * maxCount[s] * 0.5,
      }
      p.sum += maxCount[s] * sliceHeight + (20 / maxSum)
      return p
    }, { sum: 5 })

    var friendlyNameFilterRegex = /[\s,'"]/g

    _.each(sensors, s => {
      svg.append("g")
        .attr("id", "series_" + s.replace(friendlyNameFilterRegex, "_"))
        .attr("fill", d3.color(this.state.colors(s)).brighter(0.3))
        .attr("stroke", this.state.colors(s))
        .attr("stroke-width", 2)
    })

    _.each(data.data, (datapoint) => {
      _.each(datapoint, (value, key) => {
        if (key !== "timestamp") {
          svg.select("#series_" + key.replace(friendlyNameFilterRegex, "_"))
            .append("rect")
            .attr("x", () => {
              var xpos
              if (!timezone) {
                xpos = timescale(moment(datapoint.timestamp))
              } else {
                xpos = timescale(moment(datapoint.timestamp).tz(timezone))
              }
              return xpos + 3
            })
            .attr("y", () => {
              return sliceRatios[key].start - (sliceRatios[key].height * value * 0.5)
            })
            .attr("width", () => {
              return barWidth
            })
            .attr("height", () => {
              return sliceRatios[key].height * value
            })
            .attr("rx", 3)
            .attr("ry", 3)
            .append("title")
            .text(() => {
              if (!timezone) {
                return `${moment(datapoint.timestamp).format('ddd h:mma')} ${value}`
              } else {
                let timezoneAbbr = moment().tz(timezone).zoneAbbr()
                return `${moment(datapoint.timestamp).tz(timezone).format('ddd h:mma')} ${timezoneAbbr} ${value}`
              }
            })
        }
      })
    })

    //setup the x axis
    var xaxis = d3.axisBottom()
      .scale(timescale).tickFormat(d => {
        if (timezone) {
          return moment(d).tz(timezone).format('ddd h A')
        } else {
          return moment(d).format('ddd h A')
        }
      })

    //draw the x axis
    svg.append("g")
      .attr("transform", "translate(0, " + (DEFAULT_HEIGHT - 20) + ")")
      .call(xaxis)
  }

  render() {
    const sensors = access(this.props, 'data.info.sensors')
    return (
      <div style={styles.graphContainer}>
        <div className='ActivityGraph' ref={this.activityGraphRef} />

        <div style={styles.legendContainer}>{ this.props.data && sensors && sensors.map(s => <Legend key={s} label={s} color={this.state.colors(s)} />) }</div>

      </div>
    )
  }
}

MultiActivityGraphView.propTypes = {
  data: PropTypes.any,
  household: PropTypes.object,
  theme: PropTypes.object,
}

export default withTheme(MultiActivityGraphView)

const Legend = (props) => (
  <span className={'graphLegendItem'}>
    <Typography variant="caption" color='textSecondary'>
      <span className='graphLegendIndicator' style={{ backgroundColor: props.color }}></span>
      &nbsp;{props.label}
    </Typography>
  </span>
)

const styles = {
  graphContainer: {
    display: 'flex',
  },
  legendContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 300,
  },
}