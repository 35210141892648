import { connect } from 'react-redux';

import { ShowerAlertsModal } from '../component/ShowerAlertsModalView';

const mapState = (state: any) =>
{
  return{
    token: state.user.token,
    sensors: state.householdsV2.sensorDetails,
    householdId: state.householdsV2.activeHouseholdId,
  }
}


const mapDispatch = (dispatch: any) =>
{
  return{

  }
}


export default connect(mapState, mapDispatch)(ShowerAlertsModal)