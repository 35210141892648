import { put, select, takeLatest, takeEvery } from "redux-saga/effects"
import { OrganisationApi } from "../../../api/OrganisationApi"
import { HOUSEHOLD_V2_LIST_PAGE_SUCCESS } from "../householdsV2/householdsStore"
import {
  listOrganisationsSuccess,
  listOrganisationsError,
  addHouseholdToOrganisationSuccess,
  addHouseholdToOrganisationError,
  addUserToOrganisationSuccess,
  addUserToOrganisationError,
  removeUserFromOrganisationSuccess,
  removeUserFromOrganisationError,
  storeHouseholdSearchResultAction,
  getUserOrgDetailsSuccess,
  getUserOrgDetailsError,
  addRoleSuccess,
  addRoleError,
  addOrganisationSuccess,
  addOrganisationError,
  listOrganisationsBegin,
  deleteOrganisationSuccess,
  deleteOrganisationError,
  removeRoleSuccess,
  removeRoleError,
  getUserDetailsSuccess,
  getUserDetailsError,
  getUserDetailsForCacheSuccess,
  getUserDetailsForCacheError,
} from "./actionCreators"
import {
  ADD_HOUSEHOLD_TO_ORGANISATION_BEGIN,
  ADD_ORGANISATION_BEGIN,
  ADD_ROLE_BEGIN,
  ADD_USER_TO_ORGANISATION_BEGIN,
  DELETE_ORGANISATION_BEGIN,
  GET_USER_DETAILS_BEGIN,
  GET_USER_DETAILS_FOR_CACHE_BEGIN,
  GET_USER_ORG_DETAILS_BEGIN,
  LIST_ORGANISATIONS_BEGIN,
  REMOVE_ROLE_BEGIN,
  REMOVE_USER_FROM_ORGANISATION_BEGIN,
} from "./actions"


export function* listOrganisations(): any {
  try {
    const state = yield select((state) => state)
    const orgApi = new OrganisationApi(state.user.token)
    const organisations = yield orgApi.listOrganisations()
    yield put(listOrganisationsSuccess(organisations))
  }
  catch (error) {
    yield put(listOrganisationsError(error))
  }
}

export function* addHouseholdToOrganisation(action: any): any {
  try {
    const state = yield select((state) => state)
    const orgApi = new OrganisationApi(state.user.token)
    const result = yield orgApi.addHousehold(action.data.ouId, action.data.household)
    yield put(addHouseholdToOrganisationSuccess(result))
    yield put(listOrganisationsBegin())

  }
  catch (error) {
    yield put(addHouseholdToOrganisationError(error))
  }
}


export function* addUserToOrganisation(action: any): any {
  try {
    const state = yield select((state) => state)
    const orgApi = new OrganisationApi(state.user.token)
    const result = yield orgApi.addUser(action.data.ouId, action.data.user)
    yield put(addUserToOrganisationSuccess(result))
    yield put(listOrganisationsBegin())
  }
  catch (error) {
    yield put(addUserToOrganisationError(error))
  }
}


export function* removeUserFromOrganisation(action: any): any {
  try {
    const state = yield select((state) => state)
    const orgApi = new OrganisationApi(state.user.token)
    const result = yield orgApi.removeUser(action.data.ouId, action.data.userId)
    yield put(removeUserFromOrganisationSuccess(result))
    yield put(listOrganisationsBegin())
  }
  catch (error) {
    yield put(removeUserFromOrganisationError(error))
  }
}

export function* getUserOrgDetails(action: any): any {
  try {
    const state = yield select((state) => state)
    const orgApi = new OrganisationApi(state.user.token)
    const result = yield orgApi.getUser(action.data.userId)
    yield put(getUserOrgDetailsSuccess(result))
  }
  catch (error) {
    yield put(getUserOrgDetailsError(error))
  }
}

export function* getUserDetails(action: any): any {
  try {
    const state = yield select((state) => state)
    const orgApi = new OrganisationApi(state.user.token)
    const result = yield orgApi.getUserDetails(action.data.userId)
    yield put(getUserDetailsSuccess(result))
  }
  catch (error) {
    yield put(getUserDetailsError(error))
  }
}

export function* getUserDetailsForCache(action: any): any {
  try {
    const state = yield select((state) => state)
    const orgApi = new OrganisationApi(state.user.token)
    const result = yield orgApi.getUserDetails(action.data.userId)
    yield put(getUserDetailsForCacheSuccess(result))
  }
  catch (error) {
    yield put(getUserDetailsForCacheError(error))
  }
}



export function* addRole(action: any): any {
  try {
    const state = yield select((state) => state)
    const orgApi = new OrganisationApi(state.user.token)
    const result = yield orgApi.addRole(action.data.ouId, action.data.userId, action.data.roles)
    yield put(addRoleSuccess(result))
    yield put(listOrganisationsBegin())
  }
  catch (error) {
    yield put(addRoleError(error))
  }
}

export function* removeRole(action: any): any {
  try {
    const state = yield select((state) => state)
    const orgApi = new OrganisationApi(state.user.token)
    const result = yield orgApi.removeRole(action.data.ouId, action.data.userId, action.data.roles)
    yield put(removeRoleSuccess(result))
    yield put(listOrganisationsBegin())
  }
  catch (error) {
    yield put(removeRoleError(error))
  }
}


export function* addOrganisation(action: any): any {
  try {
    const state = yield select((state) => state)
    const orgApi = new OrganisationApi(state.user.token)
    const result = yield orgApi.addOrganisation(action.data.ouId, action.data.friendlyName)
    yield put(addOrganisationSuccess(result))
    yield put(listOrganisationsBegin())
  }
  catch (error) {
    yield put(addOrganisationError(error))
  }
}

export function* deleteOrganisation(action: any): any {
  try {
    const state = yield select((state) => state)
    const orgApi = new OrganisationApi(state.user.token)
    const result = yield orgApi.deleteNode(action.data.ouId)
    console.log("RESULT", result);
    yield put(deleteOrganisationSuccess(result))
    yield put(listOrganisationsBegin())
  }
  catch (error) {
    yield put(deleteOrganisationError(error))
  }
}

export function* storeHouseholdsSearchResult(action: any) {
  yield put(storeHouseholdSearchResultAction(action))
}



export function* organisationSagas() {
  yield takeLatest(LIST_ORGANISATIONS_BEGIN, listOrganisations)
  yield takeLatest(ADD_HOUSEHOLD_TO_ORGANISATION_BEGIN, addHouseholdToOrganisation)
  yield takeLatest(ADD_USER_TO_ORGANISATION_BEGIN, addUserToOrganisation)
  yield takeLatest(REMOVE_USER_FROM_ORGANISATION_BEGIN, removeUserFromOrganisation)
  yield takeLatest(GET_USER_ORG_DETAILS_BEGIN, getUserOrgDetails)
  yield takeLatest(HOUSEHOLD_V2_LIST_PAGE_SUCCESS, storeHouseholdsSearchResult)
  yield takeLatest(ADD_ROLE_BEGIN, addRole);
  yield takeLatest(ADD_ORGANISATION_BEGIN, addOrganisation);
  yield takeLatest(REMOVE_ROLE_BEGIN, removeRole);
  yield takeLatest(DELETE_ORGANISATION_BEGIN, deleteOrganisation);
  yield takeLatest(GET_USER_DETAILS_BEGIN, getUserDetails)
  yield takeEvery(GET_USER_DETAILS_FOR_CACHE_BEGIN, getUserDetailsForCache)

}
