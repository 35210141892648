import {
  put,
  takeEvery,
} from 'redux-saga/effects'
import {
  EXPORT_DATA_SUCCESS,
  EXPORT_RADAR_DATA_POLL,
  EXPORT_RADAR_DATA_SUCCESS,
  EXPORT_RADAR_DATA_ERROR,
} from './exportStore'
import {  EXPORT_DATA } from '../../shared/services/APIEndpoints'
import { callAPISaga } from '../../shared/services/WebAPI'
import ResponseCodes from '@intelicare/shared/ResponseCodes'

const MAX_POLL_ATTEMPTS = 30

const delay = (ms) => new Promise(res => setTimeout(res, ms))

function* getFilename(action) {
  // console.log('EXPORT_DATA_SUCCESS action' + JSON.stringify(action))
  if (action.data.export) {
    yield put({ type: EXPORT_RADAR_DATA_POLL, pollCount: 0, filename: action.data.export, householdId: action.params.householdId })
  }
}

function* exportDataPoll(action) {
  if (action.pollCount > MAX_POLL_ATTEMPTS) {
    yield put({ type: EXPORT_RADAR_DATA_ERROR, error: 'Timed out' })
    return
  }
  // console.log(`POLLING action ` + JSON.stringify(action))
  const result = yield callAPISaga(EXPORT_DATA({ export: action.filename, householdId: action.householdId }))
  // console.log(`POLLING result ` + JSON.stringify(result))
  if (result.status === ResponseCodes.OK) {
    const responseBody = JSON.parse(result.data)
    yield put({ type: EXPORT_RADAR_DATA_SUCCESS, uri: responseBody.uri })
  } else if (result.status === ResponseCodes.ACCEPTED) {
    yield delay(1000)
    yield put({ type: EXPORT_RADAR_DATA_POLL, pollCount: action.pollCount + 1, filename: action.filename, householdId: action.householdId })
  } else {
    yield put({ type: EXPORT_RADAR_DATA_ERROR, error: 'Cant download' })
  }
}

function* exportDataSaga() {
  yield takeEvery(EXPORT_DATA_SUCCESS, getFilename)
  yield takeEvery(EXPORT_RADAR_DATA_POLL, exportDataPoll)
}

export default exportDataSaga
