import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
// @ts-ignore
import access from "safe-access"
import {
  Grid,
  Typography,
  Tooltip,
  Card,
} from '@material-ui/core'
import { FlatGuage }  from '../../../../../components/FlatGuage'

import sharedStore from '../../../../../redux'
import { getInitials } from '../../../../Organisations/component/Modals/utils/getInitials'
import { AvatarSection } from './AvatarSection'

const { BehaviourType } = sharedStore.behaviour

const GUAGE_WIDTH = 100
const SECTION_TITLE = "subtitle2"
const SECTION_SUB_TEXT = "caption"

interface MultiNightGuagesProps
{
  behaviours: any[];
  timezone: string;
  friendlyName: string;
}

export class MultiNightGuagesView extends Component<MultiNightGuagesProps> {

  state = {
    bedSd: 0,
    bedLast: moment(),
    bedNormal: moment(),
    activitySd: 0,
    activityLast: 0,
    activityNormal: 0,
    bathSd: 0,
    bathLast: 0,
    bathNormal: 0,
    ariseSd: 0,
    ariseLast: moment(),
    ariseNormal: moment(),
    sleepSd: 0,
    sleepLast: 0,
    sleepNormal: 0,
    lastUpdated: "",
    lastUpdatedBathVisit: "",
  }

  componentDidMount() {
    this.setStats();
  }

  componentDidUpdate(lastProps: MultiNightGuagesProps) {
    if (this.props !== lastProps) {
      this.setStats()
    }
  }

  setStats = () => {
    const { behaviours, timezone: tz } = this.props
    const now = moment().tz(tz)
    const sleepBehaviour = behaviours.find(b => b.type === BehaviourType.SLEEP)
    const ariseBehaviour = behaviours.find(b => b.type === BehaviourType.ARISE)
    const nightBehaviour = behaviours.find(b => b.type === BehaviourType.NIGHT_TIME_ACTIVITY)
    const nightBathBehaviour = behaviours.find(b => b.type === BehaviourType.BATHROOM_VISIT)

    const activityNormal = Number(access(nightBehaviour, 'statistics.average')) || 0
    const activityLast = Number(access(nightBehaviour, 'lastValue')) || 0
    const activitySd = Number(access(nightBehaviour, 'statistics.stdDev')) || 0

    const ariseNormal = moment(access(ariseBehaviour, 'statistics.avgArise')).tz(tz).date(now.date())
    const ariseLast = moment(access(ariseBehaviour, 'lastArise')).tz(tz).date(now.date())
    const ariseSd = Number(access(ariseBehaviour, 'statistics.results.standardDeviation')) || 0

    const sleepNormal = Number(access(sleepBehaviour, 'statistics.mean')) / 60
    const sleepLast = Number(access(sleepBehaviour, 'statistics.lastValue')) / 60
    const sleepSd = Number(access(sleepBehaviour, 'statistics.standardDeviation')) / 60

    const bathNormal = Number(access(nightBathBehaviour, 'sleepTime.dayStat.average')) || 0
    const bathLast = Number(access(nightBathBehaviour, 'sleepTime.dayStat.previous.count')) || 0
    const bathSd = Number(access(nightBathBehaviour, 'sleepTime.dayStat.stdDev')) || 0
    const lastUpdatedBathVisit = access(nightBathBehaviour, 'sleepTime.dayStat.previous.start');

    const bedNormal = moment(access(sleepBehaviour, 'sleepTimeStatistics.averageValue')).tz(tz)
    const bedNormalDayDiff = now.diff(bedNormal, 'days')
    bedNormal.add(bedNormalDayDiff, 'days')
    const bedLast = moment(access(sleepBehaviour, 'sleepTimeStatistics.lastValue')).tz(tz)
    const bedLastDayDiff = now.diff(bedLast, 'days')
    bedLast.add(bedLastDayDiff, 'days')
    const bedSd = access(sleepBehaviour, 'sleepTimeStatistics.standardDeviation') || 0

    const lastUpdated = access(sleepBehaviour, 'lastUpdated') || ""

    this.setState({
      bedSd,
      bedLast,
      bedNormal,
      activitySd,
      activityLast,
      activityNormal,
      bathSd,
      bathLast,
      bathNormal,
      ariseSd,
      ariseLast,
      ariseNormal,
      sleepSd,
      sleepLast,
      sleepNormal,
      lastUpdated,
      lastUpdatedBathVisit,
    })
  }

  render() {
    const {
      bedSd,
      bedLast,
      bedNormal,
      activitySd,
      activityLast,
      activityNormal,
      bathSd,
      bathLast,
      bathNormal,
      ariseSd,
      ariseLast,
      ariseNormal,
      sleepSd,
      sleepLast,
      sleepNormal,
      lastUpdated,
      lastUpdatedBathVisit,
    } = this.state

    const { friendlyName } = this.props

    return (
      <div>
        <Card style={styles.cardContainer}>
          <Grid container direction="row" justify='space-evenly' alignItems="center">
            <Grid item>
              <AvatarSection friendlyName={friendlyName} lastUpdated={lastUpdated} />
            </Grid>
            <Grid item>
              <div style={styles.guageContainer}>
                <Typography variant={SECTION_TITLE}>
                Bed Time
                </Typography>
              </div>
              {/* BED TIME */}
              <div style={styles.guageContainer}>
                <FlatGuage
                  width={GUAGE_WIDTH}
                  min={-2 * bedSd}
                  max={2 * bedSd}
                  value={bedLast.diff(bedNormal, 'minutes', true)}
                  labelLow='Early'
                  labelHigh='Late' />
              </div>

              <div style={styles.guageContainer}>
                <div>
                  <Typography variant={SECTION_SUB_TEXT} color="textSecondary">
                  Last Night: {bedLast.format('h:mma')}
                  </Typography>
                </div>
                <div>
                  <Typography variant={SECTION_SUB_TEXT} color="textSecondary">
                  Normal: {bedNormal.format('h:mma')}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div style={styles.guageContainer}>
                <Typography variant={SECTION_TITLE}>
                Night Activity
                </Typography>
              </div>
              {/* NIGHT TIME ACTIVITY */}
              <div style={styles.guageContainer}>
                <FlatGuage
                  width={GUAGE_WIDTH}
                  min={activityNormal - 2 * activitySd}
                  max={activityNormal + 2 * activitySd}
                  value={activityLast} />
              </div>
              <div style={styles.guageContainer}>
                <div>
                  <Typography variant={SECTION_SUB_TEXT} color="textSecondary">
                  Last Night: {activityLast.toFixed(0)}
                  </Typography>
                </div>
                <div>
                  <Typography variant={SECTION_SUB_TEXT} color="textSecondary">
                  Normal: {activityNormal.toFixed(0)}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div style={styles.guageContainer}>
                <Typography variant={SECTION_TITLE}>
                Bathroom Visits
                </Typography>
              </div>

              {/* BATHROOM VISITS */}
              <div style={styles.guageContainer}>
                <FlatGuage width={GUAGE_WIDTH} min={bathNormal - 2 * bathSd} max={bathNormal + 2 * bathSd} value={bathLast} />
              </div>
              <div style={{ textAlign: 'center' }}>
                <div>
                  <Tooltip title={`Last updated: ${moment(lastUpdatedBathVisit).format("LLLLZ")} - ${moment(lastUpdatedBathVisit).fromNow()}`}>
                    <Typography variant={SECTION_SUB_TEXT} color="textSecondary">
                  Last Night: {bathLast}
                    </Typography>
                  </Tooltip>
                </div>
                <div>
                  <Typography variant={SECTION_SUB_TEXT} color="textSecondary">
                  Normal: {bathNormal}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div style={styles.guageContainer}>
                <Typography variant={SECTION_TITLE}>
                Arise Time
                </Typography>
              </div>
              {/* ARISE */}
              <div style={styles.guageContainer}>
                <FlatGuage
                  width={GUAGE_WIDTH}
                  min={-2 * ariseSd}
                  max={2 * ariseSd}
                  value={ariseLast.diff(ariseNormal, 'minutes', true)}
                  labelLow='Early'
                  labelHigh='Late' />
              </div>
              <div style={styles.guageContainer}>
                <div>
                  <Typography variant={SECTION_SUB_TEXT} color="textSecondary">
                  Last Arise: {ariseLast.format("h:mma")}
                  </Typography>
                </div>
                <div>
                  <Typography variant={SECTION_SUB_TEXT} color="textSecondary">
                  Normal: {ariseNormal.format("h:mma")}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div style={styles.guageContainer}>
                <Typography variant={SECTION_TITLE}>
                Sleep Amount
                </Typography>
              </div>
              {/* SLEEP AMOUNT */}
              <div style={styles.guageContainer}>
                <FlatGuage width={GUAGE_WIDTH} min={sleepNormal - 2 * sleepSd} max={sleepNormal + 2 * sleepSd} value={sleepLast} />
              </div>
              <div style={styles.guageContainer}>
                <div>
                  <Typography variant={SECTION_SUB_TEXT} color="textSecondary">
                  Last Night: {sleepLast.toFixed(1)}h
                  </Typography>
                </div>
                <div>
                  <Typography variant={SECTION_SUB_TEXT} color="textSecondary">
                  Normal: {sleepNormal.toFixed(1)}h
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>

        </Card>
      </div>
    )
  }

  static propTypes = {
    behaviours: PropTypes.array,
    timezone: PropTypes.string,
  }
}


const styles = {
  guageContainer: {
    textAlign: 'center' as 'center',
  },

  cardContainer: {
    margin: 12,
    padding: 6,
  },
}