import React from 'react'
import Typography from '@mui/material/Typography';
import NoResultsIcon from '@mui/icons-material/Lock'
export const NoResults = () =>
{
  return(
    <div style={styles.container}>

      <NoResultsIcon style={styles.icon} />
      <Typography color="textSecondary">Nothing found</Typography>

    </div>
  )
}

const styles = {
  container: {
    textAlign: 'center' as 'center',
    padding: 200,
  },
  icon: {
    fontSize: 64, color: 'grey',
  },
}