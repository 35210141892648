import React, { Component } from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'

import BehaviourRollup from './BehaviourRollup'
import SystemHealth from './SystemHealth'
import HouseholdInfo from './HouseholdInfo'

import { enums } from '@intelicare/shared'
import HealthMetricsTileView from './HealthMetrics/HealthMetricsTileView'
import { appInsights } from '../../../api/AppInsights'


const {
  BehaviourClassification,
  BEHAVIOUR_STATUS,
} = enums

const CorrectBehaviourClassification = {
  ACTIVITY: 'activity',
  MEAL_PREP: 'mealPrep',
  CLIMATE: 'climate',
  SLEEP: 'sleep',
  DOMESTIC: 'domesticActivity',
  PRESENCE: 'presence',
}

export class HouseholdRow extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  goToHousehold(sectionId) {
    const {
      household,
      viewHousehold,
    } = this.props

    appInsights.trackPageView({ name: household.friendlyName })
    viewHousehold(household.householdId, sectionId)

  }

  render() {
    const {
      household,
      isDeveloper,
      isSupport,
      isDemoUser,
      onShowSnack,
    } = this.props

    const behaviourTiles = Object.keys(CorrectBehaviourClassification).map(bc => {
      return (
        <BehaviourRollup
          onShowSnack={onShowSnack}
          isDeveloper={isDeveloper}
          fetchActivityfeedV2={this.props.fetchActivityfeedV2}
          activityStreamEventsError={this.props.activityStreamEventsError}
          isGettingActivityEvents={this.props.isGettingActivityEvents}
          activityStreamEvents={this.props.activityStreamEvents}
          key={bc}
          classification={bc}
          status={household.behaviourRollup ? household.behaviourRollup[bc] : BEHAVIOUR_STATUS.UNKNOWN}
          household={household}
          onClick={() => {
            this.goToHousehold(BehaviourClassification[bc])
          }}
          householdId={household.householdId}
          getHousehold={this.props.getHousehold}
        />
      )
    })

    let healthMetricsStatus = BEHAVIOUR_STATUS.UNKNOWN
    if (!household.healthMetricsEnabled) {
      healthMetricsStatus = "DISABLED"
    } else if (household.healthMetricsRollup) {
      for (const metric of Object.values(household.healthMetricsRollup)) {
        if (metric.status === BEHAVIOUR_STATUS.WARNING) {
          healthMetricsStatus = BEHAVIOUR_STATUS.WARNING
          break
        } else if (metric.status === BEHAVIOUR_STATUS.NORMAL) {
          healthMetricsStatus = BEHAVIOUR_STATUS.NORMAL
        }
      }
    }

    return (
      <Grid
        automation_attribute="householdRow" // Used for tests
        container
        direction="row"
        wrap="nowrap"
        // zeroMinWidth
        style={{ maxHeight: 110, marginTop: 5, marginBottom: 5 }}>
        <Grid container item xs={3}>
          <HouseholdInfo
            household={household}
            toggleFavourite={() => { }}
            isDeveloper={isDeveloper}
            viewHousehold={(sectionId) => { this.goToHousehold(sectionId) }}
          />
        </Grid>
        <Grid container item xs direction="row">
          {behaviourTiles}
          <HealthMetricsTileView
            onShowSnack={onShowSnack}
            healthMetricsStatus = {healthMetricsStatus}
            household={household}
            onClick={() => {
              this.goToHousehold('healthMetrics')
            }}
          />
          <SystemHealth
            onShowSnack={onShowSnack}
            status={household.systemHealthStatus}
            household={household}
            onClick={() => {
              this.goToHousehold('system')
            }}
            householdId={household.householdId}
            getHousehold={this.props.getHousehold}
          />
        </Grid>
      </Grid>
    )
  }

}

HouseholdRow.propTypes = {
  household: PropTypes.object,
  classes: PropTypes.object,
  viewHousehold: PropTypes.func,
  useHouseholdv2: PropTypes.bool,
  isDeveloper: PropTypes.bool,
  isSupport: PropTypes.bool,
  isDemoUser: PropTypes.bool,
  getHousehold: PropTypes.func,
  fetchActivityfeedV2: PropTypes.func,
  activityStreamEventsError: PropTypes.any,
  isGettingActivityEvents: PropTypes.bool,
  activityStreamEvents: PropTypes.array,
  onShowSnack: PropTypes.func,
}

const styles = theme => ({
})

export default withStyles(styles)(HouseholdRow)
