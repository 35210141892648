import { CircularProgress, Typography, Grid } from '@material-ui/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React from 'react'

interface AddDeviceDialogProps {
  open: boolean;
  onClose: () => void;
  url: string;
  loading: boolean;
  getPersonaDevices: () => void;
}

export const AddDeviceDialog = (props: AddDeviceDialogProps) => {
  const { open, onClose, url, loading } = props;

  return(
    <Dialog
      TransitionProps={{
        onExiting: props.getPersonaDevices,
      }}
      maxWidth='lg'
      open={open}
    >
      <DialogTitle>Add New Device</DialogTitle>
      <DialogContent>
        <Grid style={{ display: 'flex', justifyContent: 'center' }}>
          {loading ?
            <CircularProgress /> :
            url ?
              <iframe height="650px" width="800px"
                src={url}/> :
              <Typography>Error loading page</Typography>
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}