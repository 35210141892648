import React from 'react'
import PropTypes from 'prop-types'
import DocumentTitle from 'react-document-title'
import moment from 'moment'
import access from 'safe-access'

import withStyles from '@material-ui/core/styles/withStyles'


import Title from '../../../components/Title'

import NotificationsTableV2 from '../../../components/NotificationsTableV2'

import { appInsights } from "../../../api/AppInsights";

import './NotificationsView.css'

const styles = {
  dateFilter: {
    color: 'white',
    backgroundColor: "#00adb0",
    borderRadius: 4,
    flexDirection: "row",
    paddingLeft: 16,
    marginLeft: 5,
    marginRight: 10,
  },
  tableContainer: {
    paddingTop: 16,
    margin: 16,
  },
}

const PAGE_NAME = 'notificationsView'

export class NotificationsView extends React.Component {
  static pageName = PAGE_NAME
  static friendlyName = 'Notifications View'

  state = {
    data: {},
    startDownload: false,
    currentDateFilter: "",
  }

  componentDidMount()
  {
    appInsights.trackPageView({ name: "Notifications" })
  }

  exportToCSV() {
    if (!access(this.props, 'data.notifications.current.getResolvedState')) {
      return
    }
    const sortedData = this.props.data.notifications.current.getResolvedState().sortedData

    const data = sortedData.map(notification => {
      const d = notification._original || {}
      return {
        'id': d.id,
        'household_id': d.householdId,
        'household': d.household,
        'type': d.severity,
        'status': d.status,
        'description': d.text,
        'time': d.friendlyTime,
        'timestamp': (d.timestamp ? moment(d.timestamp).toISOString() : undefined),
        'source': access(d, 'source.friendlyName'),
        'resolution_time': access(d, 'resolution.timestamp'),
        'resolution_status': access(d, 'resolution.type'),
        'resolution_message': access(d, 'resolution.message'),
      }
    })
    this.setState({ data, startDownload: true })
  }

  closeDownload()
  {
    this.setState({ ...this.state, startDownload: false })
  }

  handleDateRangeFilterChange = (range) =>
  {
    const { getNotifications } = this.props
    this.setState({ currentDateFilter: range }, () => getNotifications({ timeLimit: moment().subtract(2, "months").unix() }))
  }

  render() {
    const {
      notifications,
      viewMessage,
      isFetching,
      getNotifications,
      clearNotificationsForHousehold,
      messageResolving,
    } = this.props

    return (
      <div className='notifications' style={{ width: '100%', height: '100%' }}>
        <DocumentTitle title='Notifications' />

        <Title title="Notifications"
          pageButtons={[

          ]}
        />
        <div style={styles.tableContainer}>
          <NotificationsTableV2
            showHouseholdName={true}
            isGettingNotificationsForHousehold={isFetching}
            clearNotificationsForHousehold={clearNotificationsForHousehold}
            getNotifications={getNotifications}
            notifications={notifications}
            onSelect={viewMessage}
            messageResolving={messageResolving}
          />
        </div>
      </div>
    )
  }
}



NotificationsView.propTypes = {
  notifications: PropTypes.array,
  viewMessage: PropTypes.func,
  status: PropTypes.string,
  households: PropTypes.array,
  data: PropTypes.any,
  refreshNotifications: PropTypes.func,
  isDeveloper: PropTypes.bool,
  isFetching: PropTypes.bool,
  getNotifications: PropTypes.func,
  clearNotificationsForHousehold: PropTypes.func,
  messageResolving: PropTypes.bool,
}

export default withStyles(styles)(NotificationsView)
