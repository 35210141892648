import { createSelector } from 'reselect'
import _ from 'lodash'
import access from 'safe-access'
import {
  augmentMessage,
  friendlyMessageTime,
  friendlyDateTime,
  deriveSensorDataForHousehold,
  deriveSensorDataForGateway,
} from '../../shared/selectors/helper'

import {
  okay,
  amber,
  alert,
  colorForStatus,
} from '../../shared/util/colors'

import {
  okayStatus,
  amberStatus,
  alertStatus,
} from '../../shared/util/status'

import {
  Status,
  getStatusColor,
  getIcon,
} from '../../shared/util/behaviour'

import { getFriendlyTime } from '../../shared/util/toolbelt'

import moment from 'moment-timezone'
import { enums } from '@intelicare/shared'
const { BEHAVIOUR_TYPES } = enums

export const getHouseholds = (state) => state.households.households
export const getHouseholdsLastFetched = (state) => state.households.lastFetched
export const getNotifications = (state) => state.notifications.notifications
export const getBehaviourClassification = (state) => state.households.behaviourClassification
export const getCurrentHouseholdData = (state) => state.households.householdData
export const fetchingHouseholdCarers = (state) => state.households.householdData.fetchingHouseholdCarers
export const getActiveHouseholdId = (state) => state.households.activeHouseholdId
export const getState = (state) => state

const TEMP_WARN_THRESHOLD = 32

const anyActiveNotificationAnAlert = (household, notifications) => {
  return _.some(notifications, (n) => household.gatewayId === n.gatewayId && n.status === "ACTIVE" && n.type === "ALERT")
}

const getHouseholdStatus = (household, notifications) => {
  const isAnyActiveNotificationAnAlert = anyActiveNotificationAnAlert(household, notifications)
  const activeBehaviours = _(household.behaviours).filter({ enabled: true }).value()
  const isAnyBehaviourInAlert = activeBehaviours.some(behaviour => behaviour.status && behaviour.status.value === Status.ALERT)
  const isAnyBehaviourInWarning = activeBehaviours.some(behaviour => behaviour.status && behaviour.status.value === Status.WARNING)
  if (isAnyActiveNotificationAnAlert || isAnyBehaviourInAlert) {
    return alertStatus
  } else if (isAnyBehaviourInWarning) {
    return amberStatus
  } else {
    return okayStatus
  }
}

const getNumberOfActiveNotifications = (household, notifications) => {
  return _.filter(notifications, (n) => household.gatewayId === n.gatewayId && n.status !== "ACKNOWLEDGED" && n.type === "NOTIFICATION").length
}

const getNumberOfActiveAlerts = (household, notifications) => {
  return _.filter(notifications, (n) => household.gatewayId === n.gatewayId && n.status !== "ACKNOWLEDGED" && n.type === "ALERT").length
}

const deriveHouseholdData = (household, notifications) => {
  let status = getHouseholdStatus(household, notifications)
  let numOfActiveAlerts = getNumberOfActiveAlerts(household, notifications)
  let numOfActiveNotifications = getNumberOfActiveNotifications(household, notifications)
  let oldestAlert = _(notifications)
    .filter({ gatewayId: household.gatewayId })
    .sortBy(['timestamp'])
    .first()
  return Object.assign(
    {},
    household,
    {
      status: status,
      statusColor: colorForStatus(status),
      pinnedNotes: _.filter(access(household, 'notes'), { pinned: true }),
      alertTimestamp: oldestAlert && oldestAlert.timestamp,
      numOfActiveAlerts: numOfActiveAlerts,
      numOfActiveNotifications: numOfActiveNotifications,
    }
  )
}

export const getActiveHousehold = createSelector(
  [getHouseholds, getNotifications, getActiveHouseholdId],
  (households, notifications, activeHouseholdId) => {
    if (households.length === 1) {
      return households[0]
    } else if (!activeHouseholdId) {
      return households[0]
    } else {
      let active = households.find(household => household.id === activeHouseholdId)
      return active ? deriveHouseholdData(active, notifications) : active
    }
  }
)

export function getHouseholdIdForGatewayId(households, gatewayId) {
  const household = _.filter(households, (h) => h.gatewayId === gatewayId)
  if (household && household.length === 1) {
    return household[0].id
  }
  return undefined
}

export function getSleepTimes(household, timestamp) {
  const { timezone: tz } = household
  let lastSleep
  let lastArise
  const sleepBehaviour = household.behaviours.find(b => b.type === BEHAVIOUR_TYPES.SLEEP)
  const ariseBehaviour = household.behaviours.find(b => b.type === BEHAVIOUR_TYPES.ARISE)

  const lastSleepIso = access(sleepBehaviour, 'sleepTimeStatistics.lastValue')
  const lastAriseIso = access(ariseBehaviour, 'lastArise')
  if (lastSleepIso) lastSleep = moment(lastSleepIso).tz(tz)
  if (lastAriseIso) lastArise = moment(lastAriseIso).tz(tz)

  return {
    sleepStart: getSleepStart(household, timestamp, sleepBehaviour),
    sleepEnd: getSleepEnd(household, timestamp, ariseBehaviour),
    lastSleep,
    lastArise,
  }
}


export function getSleepTimesForZone(household, zoneId, timestamp) {
  const { timezone: tz } = household
  let lastSleep
  let lastArise
  const sleepBehaviour = household.behaviours.find(b => b.type === BEHAVIOUR_TYPES.SLEEP && b.zoneId === zoneId)
  const ariseBehaviour = household.behaviours.find(b => b.type === BEHAVIOUR_TYPES.ARISE && b.zoneId === zoneId)

  const lastSleepIso = access(sleepBehaviour, 'sleepTimeStatistics.lastValue')
  const lastAriseIso = access(ariseBehaviour, 'lastArise')
  if (lastSleepIso) lastSleep = moment(lastSleepIso).tz(tz)
  if (lastAriseIso) lastArise = moment(lastAriseIso).tz(tz)

  return {
    sleepStart: getSleepStart(household, timestamp, sleepBehaviour),
    sleepEnd: getSleepEnd(household, timestamp, ariseBehaviour),
    lastSleep,
    lastArise,
  }
}

function getSleepStart(household, timestamp, sleepBehaviour) {
  const { timezone: tz } = household
  const avgSleepIso = access(sleepBehaviour, 'sleepTimeStatistics.averageValue')
  let sleepStart
  if (avgSleepIso) {
    const avgSleep = moment.utc(avgSleepIso).tz(tz)
    let currAvgSleep = moment(timestamp).tz(tz)
    if (avgSleep.hour() > 12) {
      currAvgSleep.subtract(1, 'day')
    }
    currAvgSleep.hour(avgSleep.hour()).minute(avgSleep.minute())
    sleepStart = currAvgSleep
  } else {
    const todayMidday = moment(timestamp).tz(tz).hour(12).startOf('hour')
    const yesterdayMidday = todayMidday.clone().subtract(23, 'hours')
    sleepStart = yesterdayMidday.clone().utc().hour(household.sleepingStart).tz(tz)
  }
  return sleepStart
}

function getSleepEnd(household, timestamp, ariseBehaviour) {
  const { timezone: tz } = household
  const avgSleepEndIso = access(ariseBehaviour, 'statistics.avgArise')
  let sleepEnd
  if (avgSleepEndIso) {
    const avgSleepEnd = moment(avgSleepEndIso).tz(tz)
    sleepEnd = moment(timestamp).tz(tz).hour(avgSleepEnd.hour()).minute(avgSleepEnd.minute())
  } else {
    const todayMidday = moment(timestamp).tz(tz).hour(12).startOf('hour')
    const yesterdayMidday = todayMidday.clone().subtract(23, 'hours')
    sleepEnd = yesterdayMidday.clone().utc().hour(household.sleepingFinish).tz(tz)
  }
  return sleepEnd
}

export const isFetchingHouseholdData = createSelector([getActiveHousehold, getCurrentHouseholdData], (activeHousehold, householdData) => {
  if (activeHousehold) return false
  return activeHousehold.id === householdData.householdId && householdData.isFetching
})

export const getActiveHouseholdBehaviours = createSelector(
  [getActiveHousehold, getBehaviourClassification],
  (activeHousehold, classification) => {
    let behaviours = access(activeHousehold, 'behaviours')
    behaviours = _.map(behaviours, b => {
      return Object.assign({}, b, {
        avatar: activeHousehold.avatar,
        friendlyTime: friendlyDateTime(b.lastUpdated, activeHousehold.timezone),
        icon: getIcon(b),
      })
    })
    return behaviours.filter((behaviour) => {
      return behaviour.classification === classification &&
        behaviour.enabled === true
    })
  }
)

export const getActiveHouseholdLastFetched = createSelector(
  [getActiveHousehold, getHouseholdsLastFetched],
  (activeHousehold, householdsLastFetched) => {
    return activeHousehold.lastFetched || householdsLastFetched
  }
)

export const getActiveHouseholdSettings = createSelector(
  [getActiveHousehold],
  (activeHousehold) => {
    let settings = {}
    settings.gatewayId = access(activeHousehold, 'gatewayId')
    settings.id = access(activeHousehold, 'id')
    settings.friendlyName = access(activeHousehold, 'friendlyName')
    settings.avatar = access(activeHousehold, 'avatar')
    settings.behaviours = access(activeHousehold, 'behaviours')
    settings.sleep = {
      sleepingStart: access(activeHousehold, 'sleepingStart'),
      sleepingFinish: access(activeHousehold, 'sleepingFinish'),
    }
    return settings
  }
)

/* Household Sensors for settings view */

export const getFeatureToggles = createSelector(
  [getActiveHousehold],
  (activeHousehold) => access(activeHousehold, 'features')
)

export const getActiveHouseholdSensors = createSelector(
  [getActiveHousehold], activeHousehold => deriveSensorDataForHousehold(access(activeHousehold, 'sensors'))
)
/* Behaviours */
export const getBehaviourSensors = createSelector(
  [getActiveHousehold, getActiveHouseholdBehaviours],
  (household, behaviours) => {
    if (!behaviours[0]) return []
    const behaviour = behaviours[0]
    if (!behaviour.sensors) return []
    return behaviour.sensors.map(sensor => {
      return {
        ...sensor,
        friendlyTime: friendlyDateTime(access(sensor, 'latestReading.timestamp'), household.timezone),
      }
    })
  }
)

export const updateBehaviour = (household, behaviourType) => {
  household.behaviours = household.behaviours || []
  const behaviour = household.behaviours.find(b => b.type === behaviourType)
  if (!behaviour || !behaviour.sensors) return household
  behaviour.sensors = behaviour.sensors.map(sensor => {
    let friendlyTime = getFriendlyTime(
      household.timezone, access(sensor, 'latestReading.timestamp')
    )
    return { ...sensor, friendlyTime }
  })
  return household
}

export const getBehaviourIcon = createSelector(
  [getActiveHouseholdBehaviours],
  (behaviours) => {
    const behaviour = behaviours.length > 0 ? behaviours[0] : {}
    return getIcon(behaviour)
  }
)

// Sets the colour for a household's behaviour instance
export const getBehaviourStatusDetails = createSelector(
  [getActiveHouseholdBehaviours],
  (householdBehaviours) => {
    const behaviours = householdBehaviours
    _.each(behaviours, (behaviour) => {
      behaviour.icon.color = getStatusColor(behaviour.status.value)
    })
    return behaviours
  }
)

export const getActiveHouseholdClimate = createSelector(
  [getActiveHouseholdBehaviours, getActiveHousehold],
  (householdBehaviours, household) => {
    const climate = householdBehaviours.find((f) => f.type === 'climate')
    if (climate === undefined) return {}
    _.each(climate.sensors, (sensor) => {
      if (!sensor.friendlyname) sensor.friendlyname = 'Sensor name missing'
      if (sensor.latestReading) {
        let timestamp = sensor.latestReading.timestamp
        if (timestamp[timestamp.length - 1] !== 'Z') {
          timestamp = timestamp + 'Z'
        }
        sensor.friendlyTime = friendlyDateTime(timestamp, household.timezone)
        sensor.value = sensor.latestReading.state + '°C'
      } else {
        sensor.value = '---'
        sensor.friendlyTime = 'No reading available'
      }
      if (sensor.status) {
        var status = sensor.status.value
        sensor.color = status === Status.WARNING ? amber : okay
      } else {
        sensor.color = okay
      }
    })
    return climate
  }
)

export const getBehaviourSettings = createSelector(
  [getActiveHousehold],
  (activeHousehold) => {
    let behaviours = access(activeHousehold, 'behaviours')
    let behavioursRemapped = behaviours.map((behaviour) => {
      switch (behaviour.enabled) {
        case 'true':
          behaviour.enabled = true
          break
        case 'false':
          behaviour.enabled = false
          break

        default:
          break
      }

      let configItems = []
      _.forEach(behaviour.userConfiguration, (item, key) => {
        let min
        let max
        if (item.range) {
          let range = item.range.match(/^(\d*)\-(\d*)$/) // eslint-disable-line no-useless-escape
          min = Number.parseInt(range[1])
          max = Number.parseInt(range[2])
        } else {
          min = 0
          max = 0
        }

        configItems.push(
          {
            name: key,
            settingPath: `behaviours.${behaviour.name}.${key}`,
            type: item.type,
            label: item.uiLabel,
            value: item.value,
            isRequired: item.mandatory,
            min: min,
            max: max,
            options: item.options || [],
            maximumLength: item.maximumLength || 0,
          }
        )
      })

      return {
        friendlyName: behaviour.friendlyName,
        name: behaviour.name,
        enabled: behaviour.enabled,
        userConfiguration: configItems,
        type: behaviour.type,
      }
    })

    return behavioursRemapped
  }
)

export const getActiveHouseholdContactNumber = createSelector(
  [getActiveHousehold],
  (activeHousehold) => {
    return access(activeHousehold, 'contactNumber')
  }
)

export const getActiveHouseholdAlerts = createSelector(
  [getActiveHousehold, getNotifications],
  (activeHousehold, notifications) => {
    const result = activeHousehold && _(notifications)
      .filter({ type: "ALERT", status: "ACTIVE" })
      .filter((n) => n.gatewayId === activeHousehold.gatewayId)
      .map((message) => augmentMessage(message, activeHousehold))
      .value()

    return result
  }
)

export const getActiveHouseholdMessages = createSelector(
  [getActiveHousehold, getNotifications],
  (activeHousehold, notifications) => {
    const result = activeHousehold && _(notifications)
      .filter({ status: "ACTIVE" })
      .filter((n) => n.gatewayId === activeHousehold.gatewayId)
      .map((message) => augmentMessage(message, activeHousehold))
      .value()

    return result
  }
)

export const hasActiveHouseholdAlerts = createSelector(
  [getActiveHouseholdAlerts],
  (activeHouseholdAlerts) => {
    return activeHouseholdAlerts && activeHouseholdAlerts.length > 0
  }
)

export const getActiveHouseholdTimeline = createSelector(
  [getActiveHousehold],
  (activeHousehold) => {
    if (_.isEmpty(activeHousehold)) {
      return []
    }
    var existing = access(activeHousehold, 'messages') || []
    var result = _.concat(existing, activeHousehold.actions)

    // TODO need to sort
    return result
  }
)

export const getSensorBarData = createSelector(
  [getActiveHousehold],
  (activeHousehold) => {
    let sensors
    if (_.isEmpty(access(activeHousehold, 'id'))) {
      return []
    }

    const statusCalculator = (sensor) => {
      switch (sensor.type) {
        case 'temperature':
          return sensor.last_state > TEMP_WARN_THRESHOLD ? amberStatus : okayStatus
        default:
          return okayStatus
      }
    }
    sensors = deriveSensorDataForGateway(activeHousehold.gateway, statusCalculator, activeHousehold)

    sensors = sensors.sort(function (a, b) {
      var timestampA = a.timestamp.toUpperCase() // ignore upper and lowercase
      var timestampB = b.timestamp.toUpperCase() // ignore upper and lowercase
      if (timestampA < timestampB) {
        return 1
      }
      if (timestampA > timestampB) {
        return -1
      }
      // names must be equal
      return 0
    })
    sensors = sensors.map(sensor => {
      let friendlyTime
      // Shouldn't use moment() or fromNow() because selectors should be
      // pure functions (the same input should always result in same output)
      if (sensor.timestamp && moment(sensor.timestamp) > moment()) {
        friendlyTime = 'Just now'
        if (moment(sensor.timestamp).diff(moment(), 'seconds') > 30) {
          if (process.env.REACT_APP_APP_RESOURCE === 'WEB') {
            const { AppInsights } = require('applicationinsights-js')
            AppInsights.trackEvent("SensorTimestampOutOfSyncError", {
              now: moment().toISOString(),
              sensor: JSON.stringify(sensor),
            })
          }
        }
      } else {
        friendlyTime = moment(sensor.timestamp).tz(activeHousehold.timezone).fromNow()
      }
      if (!sensor.timestamp) friendlyTime = 'More than 2 weeks ago'
      return { ...sensor, friendlyTime }
    })
    return sensors
  }
)

const householdSort = (householda, householdb) => {
  // Sort by alert status first
  if (householda.status === alertStatus && householdb.status !== alertStatus) {
    return -1
  }
  if (householda.status !== alertStatus && householdb.status === alertStatus) {
    return 1
  }

  // Then sort by warning status
  if (householda.status === amberStatus && householdb.status !== amberStatus) {
    return -1
  }
  if (householda.status !== amberStatus && householdb.status === amberStatus) {
    return 1
  }

  // Then sort by active alert count
  if (householda.numOfActiveAlerts > householdb.numOfActiveAlerts) {
    return -1
  }
  if (householda.numOfActiveAlerts < householdb.numOfActiveAlerts) {
    return 1
  }

  // Sort last by friendly name
  if (householda.friendlyName < householdb.friendlyName) {
    return -1
  }
  if (householda.friendlyName > householdb.friendlyName) {
    return 1
  }
  if (householda.friendlyName === householdb.friendlyName) {
    return 0
  }
}

export const derivedHouseholdList = createSelector(
  [getHouseholds, getNotifications],
  (households, notifications) => {
    var result = households.map((h) => deriveHouseholdData(h, notifications))


    result.sort(householdSort)

    return result
  }
)

export const getAllActions = createSelector(
  [getHouseholds],
  (households) => {
    var result = _(households)
      .map((household) => household.actions)
      .flatten()
      .reject((val) => !val)
      .map(action => Object.assign({}, action, { friendlyTime: friendlyMessageTime(action.timestamp) }))
      .sortBy(['timestamp'])
      .reverse()
      .value()

    return result
  }
)

export const householdSummaryStatus = createSelector(
  [getHouseholds, getNotifications],
  (households, notifications) => {
    var hasAlert = _.some(households, (c) => anyActiveNotificationAnAlert(c, notifications))
    return hasAlert ? alertStatus : okayStatus
  }
)

export const householdGraphStatus = createSelector(
  [derivedHouseholdList],
  (derivedHouseholds) => {
    let result = _(derivedHouseholds)
      .map((c) => {
        return c.status
      })
      .reduce((sum, val) => {
        switch (val) {
          case alertStatus:
            sum[alertStatus] = sum[alertStatus] + 1
            break
          case amberStatus:
            sum[amberStatus] = sum[amberStatus] + 1
            break
          default:
            sum[okayStatus] = sum[okayStatus] + 1
        }
        return sum
      }, { [okayStatus]: 0, [alertStatus]: 0, [amberStatus]: 0 })

    return {
      label: 'Household Health',
      values: [
        { x: result[okayStatus], label: 'Okay', color: okay },
        { x: result[amberStatus], label: 'Watch', color: amber },
        { x: result[alertStatus], label: 'Alert', color: alert },
      ],
    }
  }
)
