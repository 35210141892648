import { connect } from 'react-redux'

import { GsmStatisticsTab } from './GsmStatisticsTab'

const mapStateToProps = (state: any) =>
{
  return {
    token: state.user.token,
    principal: state.user.principal,
  }
}

export default connect(mapStateToProps, null)(GsmStatisticsTab);