import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import getIcon from './getIcon';

interface ActiveFilterProps {
  type: string;
  status: string;
}

export default function (props: ActiveFilterProps) {
  return <Grid container alignContent="center">
    <Grid item><Typography>Active filter: </Typography></Grid>
    {getFilter(props)}
  </Grid>;
}

function getFilter({ status, type }: ActiveFilterProps) {
  const style = { paddingTop: 3, paddingLeft: 2 };
  const icons: any[] = [];
  let activeStatuses = [status];
  let activeTypes = [type];
  if (status === "") activeStatuses = ["ACTIVE", "PENDING", "ACKNOWLEDGED"];
  if (type === "") activeTypes = ["ALERT", "NOTIFICATION", "INFO"];
  activeStatuses.forEach(status => activeTypes.forEach(type => {
    icons.push(<Grid item><div style={style}>{getIcon(type, status, "small")}</div></Grid>);
  }));
  if (icons.length < 1) icons.push(<Typography>invalid</Typography>);
  return icons;
}