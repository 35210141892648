import { ResponseCodes } from '@intelicare/shared'

// ------------------------------------
// Constants
// ------------------------------------
// export const GENERATE_REPORT = 'GENERATE_REPORT'
export const GET_REPORT_LOOP_BEGIN = 'GET_REPORT_LOOP_BEGIN'
export const GET_REPORT_LOOP_SUCCESS = 'GET_REPORT_LOOP_SUCCESS'
export const GET_REPORT_LOOP_ERROR = 'GET_REPORT_LOOP_ERROR'
export const GET_REPORT_BEGIN = 'GET_REPORT_BEGIN'
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS'
export const GET_REPORT_ERROR = 'GET_REPORT_ERROR'
export const TRIGGER_VIEW_REPORT = 'TRIGGER_VIEW_REPORT'

// ------------------------------------
// Actions
// ------------------------------------
export const getHouseholdReport = (householdId) => {
  return {
    type: GET_REPORT_LOOP_BEGIN,
    householdId: householdId,
  }
}

export const triggerViewReport = (bool) => {
  return {
    type: TRIGGER_VIEW_REPORT,
    value: bool,
  }
}

// ------------------------------------
// Helpers
// ------------------------------------
const convertReportData = (data) => {
  const isIE = /*@cc_on!@*/false || !!document.documentMode
  const isEdge = !isIE && !!window.StyleMedia
  if (isIE || isEdge) {
    // const blobObj = new Blob(data, { type: 'application/pdf' })
    // return msSaveOrOpenBlob(blobObj)
    return data
  } else {
    return window.URL.createObjectURL(data)
  }
}

function getReport(action) {
  if (action.status === ResponseCodes.OK) {
    return convertReportData(action.data)
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  report: undefined,
  fetchingReport: false,
  error: undefined,
  householdId: undefined,
  viewReport: false,
  status: undefined,
}

export default function reportReducer(state = initialState, action) {

  switch (action.type) {
    case GET_REPORT_LOOP_BEGIN:
      return {
        ...state,
        fetchingReport: true,
        report: undefined,
        error: undefined,
        householdId: action.householdId,
        viewReport: false,
      }

    case GET_REPORT_LOOP_SUCCESS:
      return {
        ...state,
        fetchingReport: false,
        reportId: action.reportId,
        uri: action.uri,
      }

    case GET_REPORT_LOOP_ERROR:
      return {
        ...state,
        householdId: undefined,
        error: action.error,
        fetchingReport: false,
      }

    case GET_REPORT_BEGIN:
      return {
        ...state,
      }

    case GET_REPORT_SUCCESS:
      return {
        ...state,
        uri: action.uri,
        report: getReport(action),
      }

    case GET_REPORT_ERROR:
      return {
        ...state,
        householdId: undefined,
        error: action.error,
      }

    case TRIGGER_VIEW_REPORT:
      return {
        ...state,
        viewReport: action.value,
      }

    default:
      return state
  }
}
