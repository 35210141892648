import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { brandPink, infoBlue } from '../../themes/proTheme';


// Increment pointer config //
const INCREMENT_WIDTH = 2
const INCREMENT_HEIGHT = 4
const MAX_VALUE = 22


function PositionLabelLow() {
  return 'translate(3, 10) scale(0.18)'
}

function PositionLabelNormal() {
  return 'translate(11.5, 10) scale(0.18)'
}

function PositionLabelHigh() {
  return 'translate(20, 10) scale(0.18)'
}

interface FlatGuageProps
{
  width: number;
  max: number;
  min: number;
  value: number
  labelLow?: string;
  labelNormal?: string;
  labelHigh?: string;
}

export class FlatGuage extends Component<FlatGuageProps> {

  static propTypes = {
    classes: PropTypes.object,
    width: PropTypes.number,
    labelLow: PropTypes.string,
    labelNormal: PropTypes.string,
    labelHigh: PropTypes.string,
    value: PropTypes.number,
    max: PropTypes.number,
    min: PropTypes.number,
  }

  render() {
    const { width, max, min, value, labelLow, labelNormal, labelHigh } = this.props
    const height = (width / 2) - 7
    const scale = (width || 150) / 25.0
    const valueScaled = ((value || 0.5) - (min || 0)) / ((max || 1) - (min || 0))
    const lowerColour = brandPink.main;
    const middleColour = infoBlue.main;
    const higherColour = brandPink.main;


    return (
      <div style={{ width: width, height: height }} >
        <svg width={width} height={height}>
          <g transform={`scale(${scale}, ${scale})`}>
            <rect x={0} width={INCREMENT_WIDTH} height={INCREMENT_HEIGHT} fill={lowerColour} />
            <rect x={3} width={INCREMENT_WIDTH} height={INCREMENT_HEIGHT} fill={lowerColour} />
            <rect x={6} width={INCREMENT_WIDTH} height={INCREMENT_HEIGHT} fill={middleColour} />
            <rect x={9} width={INCREMENT_WIDTH} height={INCREMENT_HEIGHT} fill={middleColour} />
            <rect x={12} width={INCREMENT_WIDTH} height={INCREMENT_HEIGHT} fill={middleColour} />
            <rect x={15} width={INCREMENT_WIDTH} height={INCREMENT_HEIGHT} fill={middleColour} />
            <rect x={18} width={INCREMENT_WIDTH} height={INCREMENT_HEIGHT} fill={higherColour} />
            <rect x={21} width={INCREMENT_WIDTH} height={INCREMENT_HEIGHT} fill={higherColour} />
            <rect fill={'#212121'} width={1} height={6} y={0} x={valueScaled * MAX_VALUE} />
            <text style={styles.text} fill={lowerColour} textAnchor='middle' transform={PositionLabelLow()}>{labelLow || 'Low'}</text>
            <text style={styles.text} fill={middleColour} textAnchor='middle' transform={PositionLabelNormal()}>{labelNormal || 'Normal'}</text>
            <text style={styles.text} fill={higherColour} textAnchor='middle' transform={PositionLabelHigh()}>{labelHigh || 'High'}</text>
          </g>
        </svg>
      </div >
    )
  }
}

const styles = {
  text: {
    fontFamily:  "Roboto, sans-serif",
  },
}
