export enum VITAL_TYPES
{
  HEART_RATE = 'heartrate',
  ACTIVITY = 'activitylevel',
  RESPIRATION = 'respiration',
  DISTANCE = 'distance'
}

export enum STATUS_COLORS
{
  OK = "#2ECC71",
  WARNING = "#E74C3C",
  NORMAL = "#3498DB",
  UNKNOWN = "#34495E",
  CHECK = "#F39C12"
}

export enum HEAR_RATE_LEVELS
{
  LOW = 40,
  HIGH = 120
}

export enum RESPIRATION_LEVELS
{
  LOW = 12,
  HIGH = 25
}

export enum ACTIVITY_LEVELS
{
  LOW = 2,
  HIGH = 3
}
