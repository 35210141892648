import * as React from 'react'
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import { SensorListItem } from './SensorListItem'
import { IHouseholdPaniSensor } from '@intelicare/shared/interfaces/pani/Pani';

interface ShowerAlertsModalProps
{
  open: boolean;
  handleClose: () => void;
  householdId: string;
  sensors: IHouseholdPaniSensor[];
  token: string;
}

export const ShowerAlertsModal = ({ sensors, handleClose, open, householdId, token }: ShowerAlertsModalProps) =>
{
  // Filter down to pani sensors
  const paniSensors = sensors.filter(s => s.type === "pani");

  return(
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={open}>
      <DialogTitle>Configure Shower Alerts</DialogTitle>

      <DialogContent>

        {paniSensors && paniSensors.length > 0 ?
          paniSensors.map((sensor, key) => <SensorListItem token={token} householdId={householdId} key={key} sensor={sensor} /> )
          : <NoSensors />}

      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

const NoSensors = () =>
{
  return(
    <div style={styles.noDataView}>
      <Icon style={styles.icon}>shower</Icon>
      <Typography color="textSecondary" style={{ paddingBottom: 3 }}>No Pani water flow sensors in this household</Typography>
    </div>
  )
}

const styles = {
  noDataView: {
    textAlign: 'center' as 'center',
  },
  icon: {
    fontSize: 64,
    color: 'grey',
  },
}