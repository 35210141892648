import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { purple } from '@material-ui/core/colors';
import { getInitials } from '../utils/getInitials';
import { AssignedHouseholdList } from './AssignedHouseholdList';
import { AssignedRolesList } from './AssignedRolesList';
import { RolesListModal } from '../RolesListModal';
import { HouseholdListModal } from '../HouseholdListModal';
import { addRoleBegin, addRoleClear, addUserToOrganisationClear, getUserDetailsBegin, getUserOrgDetailsBegin, getUserOrgDetailsClear, removeUserFromOrganisationBegin } from '../../../../../redux/domains/organisations/actionCreators';
import { Organisation } from '../../../../../api/OrganisationApi';
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../../interfaces/reduxState';
import { SnackAlert } from '../../Shared/SnackAlert';
import { ConfirmDelete } from '../../../../../components/ConfirmDelete';

interface EditUserModalProps {
  handleClose: () => void;
  open: boolean;
  userId?: string;
  organisationDetails: Organisation;
}

export const EditUserModal = ({ handleClose, open, userId, organisationDetails }: EditUserModalProps) => {
  const [currentHousehold, setCurrentHousehold] = useState<Organisation>()
  const [showConfirmRemoveHousehold, setShowConfirmRemoveHousehold] = useState(false)
  const [showConfirmRemoveRole, setShowConfirmRemoveRole] = useState(false)
  const [showRolesListModal, setShowRolesListModal] = useState(false)
  const [showConfirmDeleteUserModal, setShowConfirmDeleteUserModal] = useState(false)
  const [showHouseholdSelectModal, setShowHouseholdSelectModal] = useState(false)

  const [assignedHouseholds, setAssignedHouseholds] = useState<Organisation[]>([])
  const [selectedRole, setSelectedRole] = useState("")


  const dispatch = useDispatch();
  const addRoleSuccess = useSelector(({ organisations }: ApplicationState) => organisations.addRoleSuccess)
  const isFetching = useSelector(({ organisations }: ApplicationState) => organisations.isFetching)
  const addRoleError = useSelector(({ organisations }: ApplicationState) => organisations.addRoleError)

  const isGettingUserDetails = useSelector(({ organisations }: ApplicationState) => organisations.isGettingUserDetails)
  const userDetails = useSelector(({ organisations }: ApplicationState) => organisations.userDetails)

  const handleHouseholdSelect = (household: Organisation) => {
    if (userId) setCurrentHousehold(household)
  }

  const handleRoleSelect = (role: string) => {
    setSelectedRole(role)
    setShowConfirmRemoveRole(true)
  }

  const handleExit = () => {
    setCurrentHousehold(undefined)
    dispatch(addUserToOrganisationClear())
    dispatch(getUserOrgDetailsClear())
  }

  const handleRemoveUserFromOrganisation = () => {
    handleClose()
    dispatch(removeUserFromOrganisationBegin(organisationDetails.id, userId))
  }

  const handleAddUserToHousehold = (household: Organisation) => {
    setShowHouseholdSelectModal(false)
    setAssignedHouseholds(assignedHouseholds.concat(household))
  }

  const handleRemoveUserFromHousehold = (household: Organisation | undefined) => {
    setShowConfirmRemoveHousehold(false)
    if (currentHousehold && household && userId) {
      setAssignedHouseholds(assignedHouseholds.filter(h => h.id !== household.id))
      dispatch(removeUserFromOrganisationBegin(currentHousehold.id, userId))
    }
  }

  const handleAssignRole = (role: string) => {
    setShowRolesListModal(false)
    if (currentHousehold && userId) {
      const updatedCurrentHousehold = { ...currentHousehold, access: { [userId]: currentHousehold.access[userId] ? currentHousehold.access[userId].concat(role) : [role] } }
      setCurrentHousehold(updatedCurrentHousehold)
    }
  }

  const handleRemoveRole = (role: string) => {
    setShowConfirmRemoveRole(false)
    if (currentHousehold && userId) {
      const updatedCurrentHousehold = { ...currentHousehold, access: { [userId]: currentHousehold.access[userId].filter(r => r !== role) } }
      setCurrentHousehold(updatedCurrentHousehold)
    }
  }

  const handleUpdateUser = () => {
    if (currentHousehold && userId) {
      dispatch(addRoleBegin(currentHousehold && currentHousehold.id, userId, currentHousehold.access[userId]))
    }

  }

  useEffect(() => {

    // Populate the users assigned households
    setAssignedHouseholds(userDetails.assignedHouseholds)

  }, [userDetails])

  const handleEnter = () => {
    if (userId) {
      dispatch(getUserOrgDetailsBegin(userId))
      dispatch(getUserDetailsBegin(userId))
    }

  }


  return (
    <Dialog
      TransitionProps={{
        onExit: handleExit,
        onEntering: handleEnter,
      }}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}>
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>

        {/* User email input  */}
        <Grid container direction="row" alignItems="center" spacing={3}>

          <Grid item>
            <IconButton>
              {userDetails.isGettingUser ?
                <Skeleton circle width={100} height={100} />
                :
                <Avatar style={{ width: 100, height: 100, backgroundColor: purple[500], fontSize: 32 }}>
                  {getInitials(userDetails.user ? userDetails.user.firstName + " " + userDetails.user.lastName : "")}
                </Avatar>
              }
            </IconButton>
          </Grid>

          <Grid item style={{ flex: 1 }}>

            {userDetails.isGettingUser ? <Skeleton /> : <Typography variant="h4" >{userDetails.user ? userDetails.user.email : "Invalid User"}</Typography>}
            {userDetails.isGettingUser ? <Skeleton width={500} /> : <Typography variant="subtitle1" color="textSecondary">{organisationDetails.friendlyName}</Typography>}


          </Grid>

        </Grid>

        {/* Household and user lists */}
        <Grid container direction="row" justifyContent="space-evenly">


          <Grid item xs={6}>
            <AssignedHouseholdList
              isGettingUserDetails={isGettingUserDetails}
              onAssignHousehold={() => setShowHouseholdSelectModal(true)}
              onItemRemove={() => setShowConfirmRemoveHousehold(true)}
              selectedItem={currentHousehold}
              households={assignedHouseholds}
              onItemClick={(household) => handleHouseholdSelect(household)} />
          </Grid>


          <Grid item xs={6}>
            <AssignedRolesList
              onAssignRole={() => setShowRolesListModal(true)}
              onItemRemove={(role) => handleRoleSelect(role)}
              roles={currentHousehold && userId ? currentHousehold.access[userId] : []} // array of roles for a given household
              selectedItem={currentHousehold} />

          </Grid>

        </Grid>

        {/* Confirm Remove Household */}
        <ConfirmDelete
          handleClose={() => setShowConfirmRemoveHousehold(false)}
          onConfirmAction={() => handleRemoveUserFromHousehold(currentHousehold)}
          open={showConfirmRemoveHousehold}
          title="Remove household access"
          content="Do you want to remove this users access to this household?" />

        {/* Confirm Remove Role */}
        <ConfirmDelete
          handleClose={() => setShowConfirmRemoveRole(false)}
          onConfirmAction={() => handleRemoveRole(selectedRole)}
          open={showConfirmRemoveRole}
          title="Remove role"
          content="Do you want to remove this role for this household for this user?" />

        {/* Confirm Remove User */}
        <ConfirmDelete
          handleClose={() => setShowConfirmDeleteUserModal(false)}
          onConfirmAction={handleRemoveUserFromOrganisation}
          open={showConfirmDeleteUserModal}
          title="Delete user"
          content="Do you want to remove this user from this organisation?" />

        {/* Assign Roles Modal */}
        <RolesListModal
          onRoleSelect={(role) => handleAssignRole(role)}
          handleClose={() => setShowRolesListModal(false)}
          open={showRolesListModal}
        />

        {/* Assign User Households Modal */}
        <HouseholdListModal
          title="Assign household access to user"
          onHouseholdSelect={(household) => handleAddUserToHousehold(household)} // TODO add API call
          handleClose={() => setShowHouseholdSelectModal(false)}
          open={showHouseholdSelectModal}
        />

        <SnackAlert color="info" open={isFetching} onClose={() => dispatch(addRoleClear())} alertMsg={"Adding user to organisation"} />
        <SnackAlert color="success" open={addRoleSuccess ? true : false} onClose={() => dispatch(addRoleClear())} alertMsg={addRoleSuccess} />
        <SnackAlert color="error" open={addRoleError ? true : false} onClose={() => dispatch(addRoleClear())} alertMsg={addRoleError} />


      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={() => setShowConfirmDeleteUserModal(true)}>Delete User</Button>
        <Button onClick={handleUpdateUser}>{currentHousehold ? `Update role access on ${currentHousehold.friendlyName}` : "Update User"}</Button>
      </DialogActions>
    </Dialog>
  )
}

