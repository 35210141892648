import { IBehaviourData } from "@intelicare/shared/interfaces/behaviours/Behaviour"

interface RoomClimateLimits {
  lowerClimateLimit?: number;
  upperClimateLimit?: number;
  roomEnabled?:number;
}
export interface ThresholdSetting {
  [key: string]: RoomClimateLimits
}
export interface ClimateBehaviour extends IBehaviourData {
  lowerTemperatureLimit: number;
  upperTemperatureLimit: number;
  thresholdSetting:ThresholdSetting;
  temperatureUnits:string;
}
export interface ClimateSensorList {
  friendlyName: string;
  roomName?: string;
  roomname?: string;
  id:string;
}
export function categorizedByRoomname(climateSensors: ClimateSensorList[]): string[] {
  /*
    Categorized rooms by roomname;
    Check each room contains lower and higher(LH) temperature
      - if any room does not contains lower and higher temperature field , then call ui-api to add LH temperature field to household doc;
  */

  const categorizedByRoomname = climateSensors.reduce(function (obj: {[key: string]: any}, v,i) {
    const roomName = v.roomname || v.roomName || "Other";
    if (!obj[roomName]) obj[roomName] = [];
    const simpleSensor = { ...v }
    delete simpleSensor.roomName
    obj[roomName].push(simpleSensor);
    return obj;
  }, {});
  const roomName = Object.keys(categorizedByRoomname);
  return roomName;
}

export function assignFakeThreholdSettingsToBehaviour(climateBehaviour:ClimateBehaviour,roomName: string[]):void{
  /*Manually add objects to behaviour for testing , this does not exist at household doc yet, need api*/
  const ThresholdSetting: ThresholdSetting = {};
  roomName.forEach((value) => {
    let hasEnable = Number(0);
    let lowerValue = Number(climateBehaviour.lowerTemperatureLimit);
    let upperValue = Number(climateBehaviour.upperTemperatureLimit);
    if(climateBehaviour.thresholdSetting){
      const setting = climateBehaviour.thresholdSetting[value];
      if(setting){
        lowerValue=(setting.lowerClimateLimit)?setting.lowerClimateLimit:lowerValue;
        upperValue=(setting.upperClimateLimit)?setting.upperClimateLimit:upperValue;
        hasEnable = (setting.roomEnabled)?setting.roomEnabled:hasEnable;
      }
    }
    ThresholdSetting[value] = {
      "roomEnabled": hasEnable,
      "lowerClimateLimit": lowerValue,
      "upperClimateLimit": upperValue,
    }
  });

  Object.assign(climateBehaviour,{ "thresholdSetting":ThresholdSetting });
  Object.assign(climateBehaviour,{ "temperatureUnits": climateBehaviour.temperatureUnits ? climateBehaviour.temperatureUnits : "°C" });

  // console.log(`Temperary climateBehaviour: ${JSON.stringify(climateBehaviour)}`);
}