import React, { useState } from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import HomeIcon from '@mui/icons-material/Home';
import RouterIcon from '@mui/icons-material/Router'
import { HouseholdApi } from '../../../../../api/HouseholdApi';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReactJson from 'searchable-react-json-view'

import { useEffect } from 'react';
import { Typography } from '@material-ui/core';

interface JsonBoxModalProps
{
  handleClose: () => void;
  open: boolean;
  title: string;
  data: string;
  token: string;
  householdId: string;
  gatewayId: string;
}

export const JsonBoxModal = ({ handleClose, open, title, data, token, householdId, gatewayId }: JsonBoxModalProps) =>
{
  const hhs = new HouseholdApi(token);

  const [ currentTabIndex, setTabIndex ] = useState(0)
  const [ householdDoc, setHouseholdDoc ] = useState<string | undefined>(undefined)
  const [ gatewayDoc, setGatewayDoc ] = useState<string | undefined>(undefined)
  const [ isLoading, setIsLoading ] = useState(false)


  useEffect(() => {

    getHousehold()
    getGateway()

  }, [])

  const getHousehold = async () =>
  {

    try
    {
      setIsLoading(true)
      const household = await hhs.getHousehold(householdId)
      setHouseholdDoc(household)
      setIsLoading(false)
    }
    catch(error)
    {
      setIsLoading(false)
    }
  }

  const getGateway = async () =>
  {
    try
    {
      setIsLoading(true)
      const gateway = await hhs.getGateway(gatewayId)
      setGatewayDoc(gateway)
      setIsLoading(false)
    }
    catch(error)
    {
      setIsLoading(false)
    }
  }


  return(
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent>

        <Tabs value={currentTabIndex} onChange={(e, i) => setTabIndex(i)} aria-label="tabs">
          <Tab label="Household" icon={<HomeIcon />} />
          <Tab label="Gateway" icon={<RouterIcon />} />
        </Tabs>

        <DialogContentText id="alert-dialog-slide-description">
          {currentTabIndex === 0 ? <Item data={householdDoc} isLoading={isLoading}/> : <Item  data={gatewayDoc} isLoading={isLoading}/>}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
            Close
        </Button>
      </DialogActions>

    </Dialog>
  )
}
interface ItemProps
{
  data: any;
  isLoading: boolean;
}

const Item = ({ data, isLoading }: ItemProps) =>
{
  const DEBOUNCE_TIMEOUT = 1000
  const [ isTyping, setIsTyping ] = useState(false)
  const [ query, setQuery ] = useState("")
  const [ timeoutId, setTimeoutId ] = useState();

  const handleSearch = (q: string) =>
  {

    if(isTyping) clearTimeout(timeoutId)
    setIsTyping(true)

    const id = setTimeout(() => {
      setIsTyping(false)
      setQuery(q)
    }, DEBOUNCE_TIMEOUT)

    //@ts-ignore
    setTimeoutId(id)

  }

  return (
    <>
      <TextField placeholder="Search" label="Search" style={styles.search} variant="outlined" fullWidth onChange={(e) => handleSearch(e.target.value)}/>

      {data && !isLoading ?
        <ReactJson
          highlightSearch={query}
          iconStyle="circle"
          collapsed={2}
          theme="summerfruit"
          name={false}
          collapseStringsAfterLength={100}
          src={data}/> :
        <div style={styles.loading}><CircularProgress /> </div>}
    </>
  )
}

const mapStateToProps = (state: any) =>
{
  return {
    token: state.user.token,
    householdId: state.households.activeHouseholdId,
    gatewayId: state.householdsV2.activeGatewayId,
  }
}

const mapDispatchToProps = (dispatch: any) =>
{
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(JsonBoxModal)

const styles = {
  jsonView: {
    borderRadius: 12,
    margin: 6,
    padding: 6,
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    display: 'flex',
  },
  search: {
    marginTop: 12,
    marginBottom: 12,
  },
}