import { v4 as uuid } from 'uuid';

export interface ISubSensor
{
  id: string;
  deviceId: string;
  type: string;
  behaviour?: string;
}


export class SubSensor implements ISubSensor
{
  id: string;
  deviceId: string;
  type: string;
  behaviour: string;

  constructor(id: string, deviceId: string, type: string, behaviour: string)
  {
    this.id = id || uuid();
    this.deviceId = deviceId;
    this.type = type;
    this.behaviour = behaviour;
  }
}


