import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextField, Grid } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment';

import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/GetApp'
import SnackBar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { CSVDownload } from 'react-csv'

// API
import { Builder } from '../../../../../api/ApiBuilder'

class ActionBar extends Component {

  state = {
    isFilterSet: false,
    currentItem: "",
    exportedData: undefined,
    showDownloadingSnack: false,
    snackMessage: "",
    snackType: "",

  }

  handleFilterItem = (e) => {
    const { onSelectItem } = this.props
    this.setState({ isFilterSet: true, currentItem: e.target.value })
    // Call back to the parent for filtering
    onSelectItem(e.target.value)
  }

  handleClearFilter = () => {
    const { getHouseholds, cancelFilter, sortDirection, sortByValue } = this.props

    getHouseholds({ offset: 0, limit: 20, q: "", sortDirection: sortDirection, sortByValue: sortByValue, householdStatus: "all" })

    this.setState({ isFilterSet: false, currentItem: "" })

    cancelFilter()

  }

  exportData = async () =>
  {
    const { token } = this.props
    try
    {
      this.setState({ showDownloadingSnack: true, snackMessage: "Exporting data...", snackType: "info" })
      const api = new Builder(true);
      const results = await api.call("GET", `/api/data/reseller?type=households&dataExport=true`, null, token)

      if(results.data && results.data.length > 0)
      {
        this.setState({ showDownloadingSnack: true, snackMessage: `Done, got ${results.data.length} records`, exportedData: results.data, snackType: "success" })
      }
      else
      {
        this.setState({ showDownloadingSnack: true, snackMessage: "No households to get", snackType: "warning" })
      }

    }
    catch(error)
    {
      console.error(error)
      this.setState({ showDownloadingSnack: true, snackMessage: "Error exporting reports", snackType: "error" })

    }


  }

  render() {
    const { onTextChange, resellers } = this.props
    const { exportedData, showDownloadingSnack, snackMessage, snackType } = this.state;
    return (
      <div style={styles.container}>
        <Grid container>
          <TextField
            onChange={(e) => onTextChange(e.target.value)}
            placeholder="Search by friendly name or gatewayId"
            style={styles.searchInput}
            label="Search a household"
            variant="outlined"
            InputProps={{ endAdornment: <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => this.handleClearFilter()}
                edge="end" >
                <ClearIcon />
              </IconButton>
            </InputAdornment> }}>
          </TextField>

          <TextField
            value={this.state.currentItem}
            select
            onChange={(e) => this.handleFilterItem(e)}
            placeholder="Select Partner"
            style={styles.searchInput}
            label="Filter by partner"
            variant="outlined">
            {resellers && resellers.length > 0 && resellers
              .sort((resellerA, resellerB) => {
                if(resellerA.name < resellerB.name) return -1
                if(resellerB.name > resellerA.name) return 1
              })
              .map((reseller, key) => <MenuItem key={key} value={reseller.id}>{reseller.name}</MenuItem>)}
          </TextField>

          <IconButton onClick={() => this.exportData()}>
            <DownloadIcon />
          </IconButton>

          {this.state.isFilterSet &&
            <IconButton onClick={() => this.handleClearFilter()}>
              <ClearIcon />
            </IconButton>}

          {/* When this shows it will download the CSV */}
          {exportedData && <CSVDownload data={exportedData}/> }

        </Grid>

        { showDownloadingSnack && <SnackBar
          open={showDownloadingSnack}
          autoHideDuration={10000}
          action={<>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => this.setState({ showDownloadingSnack: false })}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>}
          onClose={() => this.setState({ showDownloadingSnack: false })}>
          <Alert variant="filled" severity={snackType}>{snackMessage}</Alert>
        </SnackBar>}

      </div>
    )
  }
}

ActionBar.propTypes = {
  onTextChange: PropTypes.func,
  onAddButtonClicked: PropTypes.func,
  resellers: PropTypes.array,
  onSelectItem: PropTypes.func,
  getHouseholds: PropTypes.func,
  cancelFilter: PropTypes.func,
  sortDirection: PropTypes.string,
  sortByValue: PropTypes.string,
  households: PropTypes.array,
  token: PropTypes.string,
}

const styles = {
  container: {
    paddingTop: 25,
    alignContent: "flex-end",
  },
  searchInput: {
    flex: 1,
    marginRight: 12,
  },
  addButton: {
    marginLeft: 30,
  },
}

export default ActionBar