import { createSelector } from 'reselect'

import _ from 'lodash'
import access from 'safe-access'

import {
  okay,
  amber,
  alert,
} from '../util/colors'

import {
  derivedHouseholdList,
} from '../../domains/households/householdsSelector'

import {
  convertGatewayDetails,
} from '../../domains/system/systemSelector'

import {
  getActiveHousehold as getActiveHouseholdV2,
  isHouseholdFetching,
} from '../../domains/householdsV2/householdsSelector'

import {
  Classifications,
  FriendlyClassificationStatusLabel,
  getColoredClassificationIcon,
  getStatusColor,
} from '../util/behaviour'



/*
  A private function for converting new classification rollup values into icons for tile selectors.
*/
const _getTile = (isFetchingHousehold, type, status) => {
  const color = getStatusColor(status)
  return {
    isFetching: isFetchingHousehold,
    color: color,
    value: FriendlyClassificationStatusLabel[status] || 'Checking...',
    icon: getColoredClassificationIcon(type, color),
  }
}

const _getHomeAwayStatus = (isFetchingHousehold, type, behaviours) => {

  const IS_HOME = "The resident is home."
  const IS_AWAY = "The resident is not home."

  let presenceKey = ""
  let status = "Checking..."

  try
  {
    // Attempt to find the presence array item
    behaviours.map((behaviour, key) => {
      if(behaviour.type === "presence")
      {
        presenceKey = key
      }
    })

    const msg = behaviours[presenceKey].status.msg

    switch(msg)
    {
      case IS_HOME:
        status = "Home"
        break
      case IS_AWAY:
        status = "Away"
        break
      default:
        status = "Okay"
        break
    }

    // Return a color value from the status
    const color = getStatusColor(status)

    return {
      isFetching: isFetchingHousehold,
      color: color,
      value: status,
      icon: getColoredClassificationIcon(type, color),
    }
  }
  catch(error)
  {
    const color = getStatusColor("WARNING")
    return {
      isFetching: isFetchingHousehold,
      color: color,
      value: "Unknown",
      icon: getColoredClassificationIcon(type, color),
    }
  }



}

/*
  A selector that creates an array that pairs households and gateways, such that the output is:
    [{ household: <household data>, gateway: <gateway data> }, ...]
*/
export const householdAndGatewayJoin = createSelector(
  [derivedHouseholdList, convertGatewayDetails],
  (households, gateways) => {

    let enrichedHouseholds = []

    _(households).each((household) => enrichedHouseholds.push({ household: household, gateway: _.find(gateways, (gateway) => household.gatewayId === gateway.key) }))

    return enrichedHouseholds
  }
)

/*
 Selector used by native-ui. See refactor instructions above.
*/
export const getActivityTile = createSelector(
  [isHouseholdFetching, getActiveHouseholdV2],
  (isFetching, activeHousehold) => {
    return _getTile(isFetching, Classifications.ACTIVITY,
      access(activeHousehold, 'behaviourRollup.ACTIVITY'))
  }
)

/*
 Selector used by native-ui. See refactor instructions above.
*/
export const getClimateTile = createSelector(
  [isHouseholdFetching, getActiveHouseholdV2],
  (isFetching, activeHousehold) => {
    return _getTile(isFetching, Classifications.CLIMATE,
      access(activeHousehold, 'behaviourRollup.CLIMATE'))
  }
)

/*
 Selector used by native-ui. See refactor instructions above.
*/
export const getMealTile = createSelector(
  [isHouseholdFetching, getActiveHouseholdV2],
  (isFetching, activeHousehold) => {
    return _getTile(isFetching, Classifications.MEAL_PREP,
      access(activeHousehold, 'behaviourRollup.MEAL_PREP'))
  }
)

/*
 Selector used by native-ui. See refactor instructions above.
*/
export const getPresenceTile = createSelector(
  [isHouseholdFetching, getActiveHouseholdV2],
  (isFetching, activeHousehold) => {

    return _getHomeAwayStatus(isFetching, Classifications.PRESENCE,
      access(activeHousehold, 'data.behaviours'))
  }
)

/*
 Selector used by native-ui. See refactor instructions above.
*/
export const getDomesticTile = createSelector(
  [isHouseholdFetching, getActiveHouseholdV2],
  (isFetching, activeHousehold) => {
    return _getTile(isFetching, Classifications.DOMESTIC,
      access(activeHousehold, 'behaviourRollup.DOMESTIC'))
  }
)

/*
 Selector used by native-ui. See refactor instructions above.
*/
export const getSleepTile = createSelector(
  [isHouseholdFetching, getActiveHouseholdV2],
  (isFetching, activeHousehold) => {
    return _getTile(isFetching, Classifications.SLEEP,
      access(activeHousehold, 'behaviourRollup.SLEEP'))
  }
)

/*
 Selector used by native-ui.
*/
export const getSystemHealthTile = createSelector(
  [isHouseholdFetching, getActiveHouseholdV2],
  (isFetching, activeHousehold) => {
    // if (isFetching) {
    //   return {
    //     isFetching: isFetching,
    //     color: okay,
    //   }
    // }

    let status = access(activeHousehold, 'systemHealthStatus')

    switch (status) {
      case 'NORMAL':
      case 'OKAY':
        return {
          isFetching: isFetching,
          color: okay,
          value: 'Okay',
        }
      case 'WARNING':
        return {
          isFetching: isFetching,
          color: amber,
          value: 'Warning',
        }
      case 'ALERT':
        return {
          isFetching: isFetching,
          color: alert,
          value: 'Alert',
        }
      case 'UNKNOWN':
        return {
          isFetching: isFetching,
          color: '#000000',
          value: 'Unknown',
        }
      default:
        return {
          isFetching: isFetching,
          color: "#000000",
          value: 'Checking...',
        }
    }
  }
)
