import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

// Accordian
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import moment from "moment";
import { callAPI } from "../../../../redux/domains/user/userStore";
import { apiEndpoints } from "../../../../redux";
const GATEWAY_FIRMWARE_GET_JOB_ID = apiEndpoints.GATEWAY_FIRMWARE_GET_JOB_ID;

interface FirmwareUpdateStatusModalProps {
  open: boolean;
  handleClose(): void;
  jobIds: [{ jobId: string; timestamp: Date }];
  isGettingJobStatus: boolean;
  getJob(jobId: string): void;
  gatewayFirmwareJobStatus: JobStatus;
}

interface JobStatus {
  jobId: string;
  queryCondition: string;
  createdTime: string;
  startTime: string;
  endTime: string;
  maxExecutionTimeInSeconds: number;
  type: string;
  cloudToDeviceMethod: {
    methodName: string;
    payload: {
      fwPackageUri: string;
    };
    responseTimeoutInSeconds: number;
    connectTimeoutInSeconds: number;
  };
  status: string;
  deviceJobStatistics: {
    deviceCount: number;
    failedCount: number;
    succeededCount: number;
    runningCount: number;
    pendingCount: number;
  };
}

class FirmwareUpdateStatusModal extends Component<FirmwareUpdateStatusModalProps> {
  state = {
    expanded: "",
  };

  handleChange = (panel: string) => (
    event: any,
    isExpanded: boolean
  ) => {
    if (isExpanded) {
      // TODO get firmware by job Id
      const { getJob } = this.props;
      getJob(panel);
    }
    this.setState({ expanded: isExpanded ? panel : false });
  };

  render() {
    const {
      handleClose,
      open,
      jobIds,
      isGettingJobStatus,
      gatewayFirmwareJobStatus,
    } = this.props;
    const { expanded } = this.state;

    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Current Firmware Update Jobs"}
        </DialogTitle>
        <DialogContent>
          <div>
            {jobIds && jobIds.length > 0 ? (
              jobIds.map((job) => {
                return (
                  <Accordion
                    key={job.jobId}
                    expanded={expanded === job.jobId}
                    onChange={this.handleChange(job.jobId)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography>Job ID: {job.jobId}</Typography>
                      <Typography>
                        Start Date:
                        {job &&
                          job.timestamp &&
                          moment(job.timestamp).format("lll")}
                      </Typography>
                    </AccordionSummary>
                    {gatewayFirmwareJobStatus && (
                      <AccordionDetails>
                        <div style={{ flexDirection: "row" }}>
                          <Typography variant="h5">
                            Current Status: {gatewayFirmwareJobStatus.status}
                          </Typography>
                          <Typography variant="subtitle2" color="textSecondary">
                            Start Time:{" "}
                            {moment(gatewayFirmwareJobStatus.startTime).format(
                              "lll"
                            )}
                          </Typography>
                          <Typography variant="subtitle2" color="textSecondary">
                            End Time:{" "}
                            {moment(gatewayFirmwareJobStatus.endTime).format(
                              "lll"
                            )}
                          </Typography>

                          <Typography variant="body2" color="textSecondary">
                            Query: {gatewayFirmwareJobStatus.queryCondition}
                          </Typography>

                          {/* Only render the statistics if the job is complete */}
                          {gatewayFirmwareJobStatus.status === "completed" && (
                            <div>
                              <Typography variant="subtitle1">
                                Device Job Statistics
                              </Typography>
                              <Typography color="textSecondary" variant="body2">
                                Device Count:{" "}
                                {
                                  gatewayFirmwareJobStatus.deviceJobStatistics
                                    .deviceCount
                                }{" "}
                              </Typography>
                              <Typography color="textSecondary" variant="body2">
                                Failed Count:{" "}
                                {
                                  gatewayFirmwareJobStatus.deviceJobStatistics
                                    .failedCount
                                }
                              </Typography>
                              <Typography color="textSecondary" variant="body2">
                                Succeded Count:{" "}
                                {
                                  gatewayFirmwareJobStatus.deviceJobStatistics
                                    .succeededCount
                                }
                              </Typography>
                              <Typography color="textSecondary" variant="body2">
                                Running Count:{" "}
                                {
                                  gatewayFirmwareJobStatus.deviceJobStatistics
                                    .runningCount
                                }
                              </Typography>
                              <Typography color="textSecondary" variant="body2">
                                Pending Count:{" "}
                                {
                                  gatewayFirmwareJobStatus.deviceJobStatistics
                                    .pendingCount
                                }
                              </Typography>{" "}
                            </div>
                          )}
                        </div>
                      </AccordionDetails>
                    )}
                  </Accordion>
                );
              })
            ) : (
              <Typography
                style={{ textAlign: "center", padding: 12 }}
                color="textSecondary"
              >
                No firmware update jobs running
              </Typography>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = (state: any) => {
  return {
    jobIds: state.households.gatewayFirmware.jobIds,
    isGettingJobStatus: state.households.isGettingJobStatus,
    gatewayFirmwareJobStatus: state.households.gatewayFirmwareJobStatus,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getJob: (jobId: string) =>
      dispatch(callAPI(GATEWAY_FIRMWARE_GET_JOB_ID(jobId))),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FirmwareUpdateStatusModal);
