import { connect } from 'react-redux'
import ActivityView from './ActivityView'

import sharedStore from '../../../../../redux'
const { getSensorBarDataV2 } = sharedStore.householdsV2Selector


const mapDispatchToProps = (dispatch) => ({})
const mapStateToProps = (state) => ({
  sensors: getSensorBarDataV2(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(ActivityView)
