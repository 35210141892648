import React from 'react'
import SecurityIcon from '@mui/icons-material/Lock'
import Typography from '@mui/material/Typography'
import { Household, Organisation } from '../../../../../api/OrganisationApi'
interface NoRolesViewProps
{
  selectedItem: Organisation | undefined;
}

export const NoRolesView = ({ selectedItem }: NoRolesViewProps) =>
{
  return(
    <div style={styles.container}>
      <SecurityIcon style={styles.icon} />
      <Typography color="textSecondary">{selectedItem ? "No roles assigned for this household" : "Select a household to assign roles"} </Typography>
    </div>
  )
}

const styles = {
  container: {
    height: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: 'grey',
  },
}