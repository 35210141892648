import React from "react";
import { Chart } from "react-google-charts";
import { HouseholdHealthRollup } from "./interface";
import { alertRed, warningOrange, okayGreen, infoBlue } from "../../../../../themes/proTheme";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import { away } from "../../../../../redux/shared/util/colors";

interface householdsHealthRollupProps {
  householdsHealthRollup: HouseholdHealthRollup[];
}

const formatSystemHealthChartData = (rollup: HouseholdHealthRollup[]) => {
  const chartData = [];
  chartData.push(["Day", "NORMAL (Okay)", "WARNING (Watch)", "ALERT (Offline)", "INACTIVE (Never Seen)", "NOT_MONITORED (Inactive)", "AWAY (Away)"]);
  try {
    for (const item of rollup) {
      chartData.push([
        moment(item.timestamp).format("DD/MM/YYYY"),
        item.systemHealth["NORMAL"],
        item.systemHealth["WARNING"],
        item.systemHealth["ALERT"],
        item.systemHealth["INACTIVE"],
        item.systemHealth["NOT_MONITORED"],
        item.systemHealth["AWAY"],
      ]);
    }
    return chartData;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const SystemHealthChart = ({ householdsHealthRollup }: householdsHealthRollupProps) => {
  const loader = <div style={styles.chartLoadingContainer}>
    <CircularProgress color="secondary" />
  </div>;
  const options = {
    colors: [okayGreen.main, warningOrange.main, alertRed.main, "#757575",  infoBlue.main, away],
    chart: {
      title: "System health over the last 6 months",
      subtitle: "in number of units",
    },
  }
  return <Chart
    height={512}
    chartType="Line"
    loader={loader}
    data={formatSystemHealthChartData(householdsHealthRollup)}
    options={options}
    rootProps={{ "data-testid": "3" }}
  />;
};

const styles = {
  chartLoadingContainer: {
    width: "auto",
    height: "600px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
};
