import { connect } from 'react-redux';
import ConfigureIncidentDetectionModal from './ConfigureIncidentDetectionModal';
import { callAPI } from '../../redux/domains/user/userStore';
import { UPDATE_INCIDENT_DETECTION } from '../../redux/shared/services/APIEndpoints';
import { UPDATE_INCIDENT_DETECTION_CLEAR } from '../../redux/domains/householdsV2/householdsStore';

interface ApiBody {
  gatewaySensorPatches?: any[];
  householdSensorPatches?: any[];
}

const mapStateToProps = (state: any) => {
  return {
    motionSensors: getMotionSensors(state),
    isUpdatingIncidentDetection: state.householdsV2.isUpdatingIncidentDetection,
    updateIncidentDetectionSuccess: state.householdsV2.updateIncidentDetectionSuccess,
    updateIncidentDetectionFailed: state.householdsV2.updateIncidentDetectionFailed,
    householdId: state.householdsV2.activeHouseholdId,
    gatewayId: state.householdsV2.activeGatewayId,
    token: state.user.token,
  }
}

function getMotionSensors(state: any): any[] {
  try {
    const { activeHouseholdId, households } = state.householdsV2;
    const household = households.filter((household: any) =>
      household.householdId === activeHouseholdId
    )[0];
    return household.data.sensors.filter((sensor: any) =>
      sensor.type === "motion"
    );
  } catch (error) {
    return [];
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    update: (householdId: string, body: ApiBody) => dispatch(callAPI(UPDATE_INCIDENT_DETECTION(householdId, body))),
    clear: () => dispatch({ type: UPDATE_INCIDENT_DETECTION_CLEAR }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureIncidentDetectionModal);