import React, { Component } from 'react'
import PropTypes from 'prop-types'

import BatteryGraph from '../../../../../components/Charts/BatteryGraph'

const DEFAULT_QUERY_LIMIT = 100

class BatteryReportGraphTab extends Component {

  state = {
    currentFilterItemValue: DEFAULT_QUERY_LIMIT,
    searchTerm: "",
  }

  componentDidMount() {

    const { getAllBatteryLevels } = this.props
    getAllBatteryLevels({ queryString: `limit=${DEFAULT_QUERY_LIMIT}` })
  }

  getAverage = (numberArray) => {

    const batteryLevels = numberArray.map(sensor => sensor.batteryLevel)
    return batteryLevels.reduce((a, b) => a + b) / batteryLevels.length
  }

  formatSensorData = (reports) => {


    const formattedGraph = []
    formattedGraph.push(["Date", "Average Battery Level"])


    try {
      for (const report of reports) {

        const average = this.getAverage(report.sensors)
        formattedGraph.push([report.timestamp, average])
      }
    }
    catch (error) {
      //
    }

    return formattedGraph
  }

  render() {

    const { allGatewaysBatteryReport } = this.props



    return (
      <div style={{ flex: 1 }}>
        <BatteryGraph data={this.formatSensorData(allGatewaysBatteryReport)} />
      </div>
    )
  }
}

BatteryReportGraphTab.propTypes = {
  getAllBatteryLevels: PropTypes.func,
  isFetchingBatteryReport: PropTypes.bool,
  allGatewaysBatteryReport: PropTypes.array,
}

export default BatteryReportGraphTab