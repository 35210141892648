import React from 'react'
import Grid from '@material-ui/core/Grid'
import EnableProUserCard from './EnableProUserCard'

export const UserConfigurationView = () =>
{
  return(
    <Grid container>
      <Grid item>
        <EnableProUserCard />
      </Grid>
      {/* Add more items here */}
    </Grid>
  )
}