import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import access from 'safe-access'
import clsx from 'clsx'

import {
  Card,
  CardHeader,
  IconButton,
  Typography,
  Collapse,
  CardContent,
} from '@material-ui/core'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InteliIcon from '../../../../components/InteliIcon'
import OutingHistory from '../graphs/OutingHistory'
import { enums } from '@intelicare/shared'
import { behaviour, colors } from '../../../../redux'

const colorForStatusValue = colors.colorForStatusValue
const getColoredClassificationIcon = behaviour.getColoredClassificationIcon

const {
  BEHAVIOUR_STATUS,
  BehaviourClassification,
} = enums

class PresenceView extends Component {
  state = { expanded: false }

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }))
  };

  componentDidUpdate(lastProps, _lastState) {
    if (this.props.expanded !== lastProps.expanded) {
      this.setState(state => ({ ...state, expanded: this.props.expanded }))
    }
  }

  render() {
    const {
      classes,
      householdTz,
      householdV2,
      useNewLook,
    } = this.props


    let icon
    let presence
    const status = access(householdV2, 'behaviourRollup.PRESENCE')
    const statusColor = colorForStatusValue(status || BEHAVIOUR_STATUS.UNKNOWN)
    icon = getColoredClassificationIcon(
      BehaviourClassification.PRESENCE,
      statusColor
    )
    const behaviours = access(householdV2, 'data.behaviours')
    if (behaviours) presence = behaviours.find((b) => b.classification === 'presence')

    let data = []
    if (presence) data = presence.notHomeHistory

    return (
      <Card className={classes.root} automation_attribute="presenceCard">
        {!useNewLook && <CardHeader automation_attribute="presenceHeader"
          classes={{
            root: classes.headerRoot,
            title: classes.headerTitle,
            subheader: classes.headerSubheader,
          }}
          title={<Typography variant="h5">Presence</Typography>}
          subheader={<Typography color="textSecondary" variant="body1">Displays the households outings</Typography>}
          action={
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: this.state.expanded,
              })}
              onClick={this.handleExpandClick}
              aria-expanded={this.state.expanded}
              aria-label="Show more"
            >
              <ExpandMoreIcon
                automation_attribute="presenceExpandArrow"
                fontSize='large'
                color='primary'
                style={{ backgroundColor: '#F0F0F0', borderRadius: '50%' }}
              />
            </IconButton>
          }
          avatar={
            <div>
              <InteliIcon
                size={55}
                name={icon.name}
                family={icon.family}
                color={icon.color}
              />
            </div>
          }
        /> }
        <Collapse in={useNewLook || this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent automation_attribute="presenceContent">
            <Typography variant="h6">Outing Duration</Typography>
            <OutingHistory data={data} householdTz={householdTz} />
          </CardContent>
        </Collapse>
      </Card>
    )
  }
}

PresenceView.propTypes = {
  householdTz: PropTypes.string,
  id: PropTypes.string,
  classes: PropTypes.object,
  household: PropTypes.object,
  householdV2: PropTypes.object,
  expanded: PropTypes.bool,
  useNewLook: PropTypes.bool,
}

const styles = theme => ({
  root: {
    marginBottom: 20,
  },
  headerRoot: {
    background: 'white',
  },
  headerTitle: {
    color: 'black',
  },
  headerSubheader: {
    color: 'black',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
})

export default withStyles(styles)(PresenceView)
