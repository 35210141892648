import { connect } from 'react-redux'

import AddMetricModal from './AddMetricModal'

import { ADD_HEALTH_METRIC } from '../../../../../../redux/shared/services/APIEndpoints'
import { callAPI } from '../../../../../../redux/domains/user/userStore'
import access from 'safe-access'

const mapStateToProps = state =>
{
  return {
    addError: access(state, 'healthMetrics.addError.message'),
    addResult: access(state, 'healthMetrics.addResult'),
    isAddingHealthMetric: state.healthMetrics.isAddingHealthMetric,
    activeHouseholdId: state.householdsV2.activeHouseholdId,
  }
}

const mapDispatchToProps = (dispatch) =>
{
  return {
    addMetric: (body) => dispatch(callAPI(ADD_HEALTH_METRIC(body))),
    clearAddMetric: () => dispatch({ type: "CLEAR_ADD_HEALTH_METRIC" }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMetricModal)