import React from 'react'
import Card from '@material-ui/core/Card'
import Avatar from '@material-ui/core/Avatar'
import CardHeader from '@material-ui/core/CardHeader'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import { getSocialIsolationStatus, SocialBehaviour } from './util/getSocialIsolationStatus'

interface SocialIsolationProps {
  socialIsolation: SocialBehaviour
}

export const SocialIsolation = ({ socialIsolation }: SocialIsolationProps) => {
  const status = getSocialIsolationStatus(socialIsolation)
  return (
    <Card style={styles.cardContainer}>
      <CardHeader
        titleTypographyProps={{ variant: 'h6' }}

        avatar={
          <Avatar style={{ ...styles.avatar, backgroundColor: status.color }}>
            <EmojiPeopleIcon fontSize="large" />
          </Avatar>
        }

        title="Social Isolation"
        subheader={status.statusText}
      />
    </Card>
  )
}

const styles = {
  cardContainer: {
    padding: 12,
    flex: 1,
    borderRadius: 6,
    marginBottom: 12,
  },
  avatar: {
    width: 100,
    height: 100,
  },
  icon: {
    width: 100,
    height: 100,
  },
}