import { connect } from 'react-redux'
import { compose } from 'redux'
import { withErrorHandler, Fallback } from '../../../hoc/withErrorHandler'
import SystemView from '../components/SystemView'
import sharedStore from '../../../redux'

const householdSummaryStatus = sharedStore.householdsSelector.householdSummaryStatus


const mapDispatchToProps = (dispatch) => {
  return {
  }
}

const mapStateToProps = (state) => ({
  status: householdSummaryStatus(state),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorHandler(Fallback)
)(SystemView)
