import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

// import NotificationsTable from 'components/NotificationsTable'
import NotificationsTableV2 from '../../../../components/NotificationsTableV2'

class NotificationsView extends Component {
  state = { expanded: false }

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }))
  };


  render() {
    const {
      classes,
      getNotifications,
      notifications,
      householdId,
      isGettingNotificationsForHousehold,
      clearNotificationsForHousehold,
      onSelect,
      messageResolving,
      useNewLook,
    } = this.props

    return (
      <Card className={classes.root} automation_attribute="notificationsCard">
        <CardHeader automation_attribute="notificationsHeader"
          classes={{
            root: classes.headerRoot,
            title: classes.headerTitle,
            subheader: classes.headerSubheader,
          }}
          title={!useNewLook && <Typography variant="h5">Notifications and Alerts</Typography>}
          subheader=""
        />
        <CardContent automation_attribute="notificationsContent">
          <Grid container alignContent="center" direction="row" width={'100%'}>
            <Grid item style={{ width: '100%' }} >
              <NotificationsTableV2
                clearNotificationsForHousehold={clearNotificationsForHousehold}
                isGettingNotificationsForHousehold={isGettingNotificationsForHousehold}
                householdId={householdId}
                getNotifications={getNotifications}
                notifications={notifications}
                onSelect={onSelect}
                messageResolving={messageResolving}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }
}

NotificationsView.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object,
  notifications: PropTypes.array,
  getNotifications: PropTypes.func,
  onSelect: PropTypes.func,
  householdId: PropTypes.string,
  isGettingNotificationsForHousehold: PropTypes.bool,
  clearNotificationsForHousehold: PropTypes.func,
  messageResolving: PropTypes.bool,
  useNewLook: PropTypes.bool,
}

const styles = theme => ({
  root: {
    marginBottom: 20,
  },
  headerRoot: {
    background: 'white',
  },
  headerTitle: {
    color: 'black',
  },
  headerSubheader: {
    color: 'black',
  },
})

export default withStyles(styles)(NotificationsView)
