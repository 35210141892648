import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab';
// Tab icons
import AssessmentIcon from '@mui/icons-material/Assessment';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import HotelIcon from '@mui/icons-material/Hotel';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import HomeIcon from '@mui/icons-material/Home';
import NotificationsIcon from '@mui/icons-material/Notifications';
import WashingIcon from '@mui/icons-material/HomeRounded'
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';
import { colors } from '../../../../redux';
import { BEHAVIOUR_STATUS } from '@intelicare/shared/enums';

const DEFAULT_COLOR = "#8AC56D"
interface HouseholdTabsProps {
  currentTab: number;
  onChangeTab: (index: number) => void;
  behaviourRollup: BehaviourRollup;
  systemHealthStatus: string;
}

interface BehaviourRollup {
  ACTIVITY: string;
  MEAL_PREP: string;
  CLIMATE: string;
  SLEEP: string;
  DOMESTIC: string;
  PRESENCE: string;
  DOOR_ALERT: string;
  DOOR_INFO_MESSAGE: string;
  SYSTEM_HEALTH: string;
}

export enum TAB_NAMES {
  TRENDS, ACTIVITY, HEALTH, SLEEP, MEALS, PRESENCE, DOMESTIC, NOTIFICATIONS, SYSTEMHEALTH,
}

interface TabData {
  icon: JSX.Element;
  color: string;
  label: string;
}

export const HouseholdTabs = ({ currentTab, onChangeTab, behaviourRollup, systemHealthStatus }: HouseholdTabsProps) => {
  const tabData = getTabData(behaviourRollup, systemHealthStatus);
  return <Tabs
    TabIndicatorProps={{ style: { background:'grey' } }}
    style={styles.container}
    indicatorColor="secondary"
    textColor="secondary"
    onChange={(e, index) => onChangeTab(index)}
    value={currentTab}
    variant="scrollable"
    scrollButtons="on"
  >
    {tabData.map(({ icon, color, label }, key) => <Tab
      key={key}
      icon={icon}
      style={{ color, ...styles.tabStyle }}
      label={label}
    />)}
  </Tabs>;
}

function getTabData(behaviourRollup: any, systemHealthStatus: string): TabData[] {
  const colours = new Map<string, string>();
  for (const item of ["ACTIVITY", "SLEEP", "MEAL_PREP", "PRESENCE", "DOMESTIC"])
    colours.set(item, getColour(behaviourRollup[item]));
  colours.set("SYSTEM_HEALTH", getColour(systemHealthStatus));
  return [
    { label: "Trend", color: DEFAULT_COLOR, icon: <AssessmentIcon /> },
    { label: "Activity", color: colours.get("ACTIVITY") || "grey", icon: <DirectionsWalkIcon /> },
    { label: "Health Metrics", color: DEFAULT_COLOR, icon: <FavoriteBorderIcon /> },
    { label: "Sleep", color: colours.get("SLEEP") || "grey", icon: <HotelIcon /> },
    { label: "Meals", color: colours.get("MEAL_PREP") || "grey", icon: <RestaurantIcon /> },
    { label: "Presence", color: colours.get("PRESENCE") || "grey", icon: <HomeIcon /> },
    { label: "Domestic", color: colours.get("DOMESTIC") || "grey", icon: <WashingIcon /> },
    { label: "Notifications", color: DEFAULT_COLOR, icon: <NotificationsIcon /> },
    { label: "System Health", color: colours.get("SYSTEM_HEALTH") || "grey", icon: <SettingsSystemDaydreamIcon /> },
  ];
}

function getColour(value: string): string {
  return colors.colorForStatusValue(value || BEHAVIOUR_STATUS.UNKNOWN);
}

const styles = {
  container: {
    backgroundColor: "white",
    borderRadius: 6,
    marginBottom: 8,
  },
  tabStyle: {
    minWidth: 125,
    paddingLeft: 12,
    paddingRight: 12,
  },
}
