import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MenuList from '@material-ui/core/MenuList'
import PersonIcon from '@mui/icons-material/Person'
import LockIcon from '@mui/icons-material/LockOpen'
import AnnouncementIcon from '@mui/icons-material/Announcement';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import GroupIcon from '@mui/icons-material/Group'
import NotificationsIcon from '@mui/icons-material/Notifications';
import OrganisationsIcon from '@mui/icons-material/Apartment';

import CodeIcon from '@mui/icons-material/Code'

import MenuLinkItem from "../../../../components/MenuLinkItem"

export default class AppMenuView extends Component {
  static friendlyName = 'Menu'
  static propTypes = {
    isDeveloper: PropTypes.bool,
    isSupport: PropTypes.bool,
    location: PropTypes.any,
  }

  render() {
    const {
      isDeveloper,
      isSupport,
      location,
    } = this.props

    return (
      <MenuList>
        <MenuLinkItem
          to={'/households'}
          leftIcon={<PersonIcon />}
          selected={location.pathname === '/households'}
        >
          Households
        </MenuLinkItem>
        {(isDeveloper || isSupport) && <MenuLinkItem
          to={'/status'}
          leftIcon={<AnnouncementIcon />}
          selected={location.pathname === '/status'}
        >
          Realtime Status
        </MenuLinkItem>}

        {(isDeveloper || isSupport) && <MenuLinkItem
          to={'/organisations'}
          leftIcon={<OrganisationsIcon />}
          selected={location.pathname === '/organisations'}
        >
          Organisation
        </MenuLinkItem>}

        <MenuLinkItem
          leftIcon={<NotificationsIcon />}
          to='/notifications'
          selected={location.pathname === '/notifications'}
        >
          Notifications
        </MenuLinkItem>
        <MenuLinkItem
          leftIcon={<LibraryBooksIcon />}
          to='/reports'
          selected={location.pathname === '/reports'}
        >
          Reports
        </MenuLinkItem>
        {isDeveloper &&
          <MenuLinkItem
            leftIcon={<PowerSettingsNewIcon />}
            to='/system'
            selected={location.pathname === '/system'}
          >

            System health
          </MenuLinkItem>
        }
        {(isDeveloper || isSupport) &&
          <MenuLinkItem
            leftIcon={<GroupIcon />}
            to='/partners'
            selected={location.pathname === '/partners'}
          >
            Partners
          </MenuLinkItem>
        }
        {isDeveloper &&
          <MenuLinkItem
            leftIcon={<LockIcon />}
            to='/admin'
            selected={location.pathname === '/admin'}
          >
            Admin
          </MenuLinkItem>
        }
        {isDeveloper &&
          <MenuLinkItem
            leftIcon={<CodeIcon />}
            to='/developer/gatewayfirmware'
            selected={location.pathname === '/developer/gatewayfirmware'}
          >
            Developer Console
          </MenuLinkItem>
        }
      </MenuList>
    )
  }
}
