import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import HouseholdTableItem from './HouseholdTableItem'

class HouseholdsTable extends Component {

  render() {
    // Passing the resellerId will make the table item household's resellerId and check if the resellerId matches and change the button text from link to linked
    const { onTableItemClick, isLinking, resellerId, changeSortOrder, sortDirectionStartDate, sortDirectionEndDate, households, filterByActiveInactive, activeInactive } = this.props
    return (
      <TableContainer style={styles.container} component={Paper}>
        <Table aria-label="households table">
          <TableHead>
            <TableRow >
              <TableCell>Friendly Name</TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={true}
                  direction={activeInactive}
                  onClick={() => filterByActiveInactive("desc")}>
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">Gateway ID</TableCell>

              {/* Subscription start date */}
              <TableCell align="right">
                <TableSortLabel
                  active={true}
                  direction={sortDirectionStartDate}
                  onClick={() => changeSortOrder("billing.billingStartDate")}>
                  Subscription Start Date
                </TableSortLabel>
              </TableCell>

              {/* Subscription end date */}
              <TableCell align="right">
                <TableSortLabel
                  active={true}
                  direction={sortDirectionEndDate}
                  onClick={() => changeSortOrder("billing.billingEndDate")}>
                  Subscription End Date
                </TableSortLabel>
              </TableCell>

              <TableCell align="right">Link Invoice</TableCell>

              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {households && households.length !== 0 && households.map((household, key) => (
              <HouseholdTableItem
                onTableItemClick={onTableItemClick}
                isLinking={isLinking}
                resellerId={resellerId}
                linkButtonText={resellerId === household.resellerId ? "Linked" : "Link"}
                key={key}
                household={household} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

HouseholdsTable.propTypes = {
  households: PropTypes.array,
  onTableItemClick: PropTypes.func,
  resellerId: PropTypes.string,
  isLinking: PropTypes.bool,
  linkButtonText: PropTypes.string,
  changeSortOrder: PropTypes.func,
  sortDirection: PropTypes.string,
  sortByValue: PropTypes.string,
  sortDirectionStartDate: PropTypes.string,
  sortDirectionEndDate: PropTypes.string,
  filterByActiveInactive: PropTypes.func,
  activeInactive: PropTypes.bool,
}

const styles = {
  container: {
    marginTop: 16,
  },
}

export default HouseholdsTable