import { Builder } from "./ApiBuilder";
import { AlertThresholds, ResponseBodySuccess } from '@intelicare/shared/interfaces/household/AlertThresholds'

export class RealtimeAlerting extends Builder
{
  constructor(public token: string)
  {
    super(true)
  }

  getHousehold = async (householdId: string): Promise<ResponseBodySuccess | undefined> =>
  {
    const response = await this.call("GET", `/api/realtime-status?householdId=${householdId}`, null, this.token);
    if(response && response.data) return response.data
  }

  getThresholds = async (householdId: string): Promise<AlertThresholds | undefined> =>
  {
    console.log("Getting thresholds")

    const response = await this.call("GET", `/api/realtime-status?householdId=${householdId}&thresholds=true`, null, this.token);
    if(response && response.data) return response.data
  }

  setThresholds = async (householdId: string, thresholds: AlertThresholds): Promise<AlertThresholds | undefined> =>
  {
    console.log("Setting thresholds", thresholds)
    const response = await this.call("POST", `/api/realtime-status?householdId=${householdId}&thresholds=true`, thresholds, this.token);
    if(response && response.data) return response.data
  }
}