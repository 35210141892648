import { connect } from 'react-redux'
import HouseholdView from '../components/HouseholdView'
import { compose } from 'redux'
import { withErrorHandler, Fallback } from '../../../hoc/withErrorHandler'
import sharedStore from '../../../redux'
import access from 'safe-access'
import { callAPI } from '../../../redux/domains/user/userStore'
import { apiEndpoints } from '../../../redux'
import { getActiveHouseholdDataNode } from '../../../redux/domains/householdsV2/householdsSelector'

const GET_NOTIFICATIONS_HOUSEHOLD = apiEndpoints.GET_NOTIFICATIONS_HOUSEHOLD
const householdSummaryStatus = sharedStore.householdsSelector.householdSummaryStatus
const getActiveHousehold = sharedStore.householdsSelector.getActiveHousehold
const getActiveHouseholdV2 = sharedStore.householdsV2Selector.getActiveHousehold
const isHouseholdFetching = sharedStore.householdsV2Selector.isHouseholdFetching
const getActiveHouseholdAlerts = sharedStore.householdsSelector.getActiveHouseholdAlerts
const getActiveHouseholdTimeline = sharedStore.householdsSelector.getActiveHouseholdTimeline
const createAction = sharedStore.householdsStore.createAction
const createNoteStart = sharedStore.householdsStore.createNoteStart
const deleteNote = sharedStore.householdsStore.deleteNote
const requestHouseholdData = sharedStore.householdsStore.requestHouseholdData
const viewHouseholdInformation = sharedStore.householdsStore.viewHouseholdInformation
const viewMessage = sharedStore.householdsStore.viewMessage
const getFeatureToggles = sharedStore.householdsSelector.getFeatureToggles
const fetchHousehold = sharedStore.householdsV2Store.fetchHousehold


const {
  HOUSEHOLD_V2_LIST_PAGE,ALEXA_INITIAL_SYNC,
  HOUSEHOLD_V2_GET, GET_HEALTH_SUMMARY, FITBIT_INITIAL_SYNC,
  HOUSEHOLDS_SENSORS,
} = sharedStore.apiEndpoints

const mapDispatchToProps = (dispatch) => {
  return {
    getSummary: (params) => dispatch(callAPI(GET_HEALTH_SUMMARY(params))),
    alexaInitialSync: (householdId, alexaAccessCode, redirectUrl) => {
      const body = {
        householdId: householdId,
        accessCode: alexaAccessCode,
        redirectUrl: redirectUrl,
      }
      dispatch(callAPI(ALEXA_INITIAL_SYNC(body)))
    },
    createAction: (action) => {
      dispatch(createAction(action))
    },
    createNoteStart: () => {
      dispatch(createNoteStart())
    },
    deleteNote: (note) => {
      dispatch(deleteNote(note))
    },
    refreshDashboard: (household) => {
      dispatch(viewHouseholdInformation(household))
    },
    requestHouseholdData: (id) => {
      dispatch(requestHouseholdData(id))
    },
    viewMessage: (message) => {
      dispatch(viewMessage(message))
    },
    getHousehold: (householdId) => {
      dispatch(fetchHousehold(householdId))
    },
    getNotifications: (params) => {
      dispatch(callAPI(GET_NOTIFICATIONS_HOUSEHOLD(params)))
    },
    clearNotificationsForHousehold: () => dispatch({ type: "CLEAR_GET_NOTIFICATIONS_HOUSEHOLD" }),
    getHouseholdDetails: (householdId) => {
      // This function is needed as some redux state data is lost when refreshing the page
      // NOTE: this is a bit of a hack and probably needs a full refactor of the redux store
      dispatch(callAPI(HOUSEHOLD_V2_LIST_PAGE({
        pageSize: 5,
        pagePosition: 0,
        searchQuery: householdId.split('-').join(' ') || '*', // Search for householdId
        order: 'triage',
        householdStatus: 'monitored',
        systemHealthStatus: '',
        triagePriority: '',
        dontSaveQuery: true, // Don't save this search query in search bar
      })))
    },
    refreshHousehold: (householdId) => {
      dispatch(callAPI(HOUSEHOLD_V2_GET({ householdId })))
    },
    clearSyncAlexaData: () => {
      dispatch({ type: "CLEAR_SYNC_ALEXA_ACCOUNT" })
    },
    fitbitInitialSync: (householdId, accessCode, redirectUrl) => {
      const body = {
        householdId: householdId,
        accessCode: accessCode,
        redirectUrl: redirectUrl,
      }
      dispatch(callAPI(FITBIT_INITIAL_SYNC(body)))
    },
    clearSyncFitbitData: () => dispatch({ type: "CLEAR_SYNC_FITBIT_DATA" }),
    fetchSensors: (householdId) => dispatch(callAPI(HOUSEHOLDS_SENSORS({ householdId: householdId }))),
  }
}

const mapStateToProps = (state) => ({
  isDeveloper: state.user.isDeveloper,
  isSupport: state.user.isSupport,
  isBetaUser: state.user.isBetaUser,
  isDemoUser: state.user.isDemoUser,
  status: householdSummaryStatus(state),
  activeHousehold: getActiveHousehold(state),
  activeV2Household: getActiveHouseholdV2(state),
  isFetching: isHouseholdFetching(state),
  notifications: state.notifications.householdNotifications,
  actionsAndNotifications: getActiveHouseholdTimeline(state),
  alerts: getActiveHouseholdAlerts(state),
  features: getFeatureToggles(state),
  featurePreviews: state.featurePreviews,
  isGettingNotificationsForHousehold: state.notifications.isGettingNotificationsForHousehold,
  messageResolving: state.notifications.messageSending,
  activeHouseholdId: state.householdsV2.activeHouseholdId,
  alexaSyncError: access(state, 'alexa.syncAlexaError.message'),//pass to household view
  alexaSyncResult: access(state, 'alexa.syncAlexaResult'),
  fitbitSyncError: access(state, 'healthMetrics.syncFitbitError.message'),
  fitbitSyncResult: access(state, 'healthMetrics.syncFitbitResult'),
  isSyncingAlexaData: state.alexa.isSyncingAlexaData,
  useNewLook: state.householdsV2.useNewLook,
  token: state.user.token,
  isSyncingInitialFitbit: state.healthMetrics.isSyncingInitialFitbit,
  sensors: state.householdsV2.sensorDetails,
  hasZones: getActiveHouseholdDataNode(state).hasZones,
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorHandler(Fallback)
)(HouseholdView)
