import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import sharedStore from '../../redux'

const InteliAvatar = ({ src, size = 50, maximise = false }) => (
  <Avatar
    src={(src && src !== 'BAD_DATA') ? src : sharedStore.img.anonymous}
    style={Object.assign({}, styles.avatar, {
      height: (maximise ? 'auto' : size),
      width: (maximise ? '66%' : size),
    })}
  />
)

const styles = {
  avatar: {
    marginBottom: 5,
    marginTop: 5,
    marginRight: 10,
  },
}

InteliAvatar.propTypes = {
  style: PropTypes.number,
  size: PropTypes.number,
  src: PropTypes.string,
  maximise: PropTypes.bool,
}

export default InteliAvatar
