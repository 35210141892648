import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Typography,
  Collapse,
  CardContent,
  CircularProgress,
} from "@material-ui/core";

// Modals
import ConfirmDeleteModal from "./Modals/ConfirmDeleteModal";
import BatteryReportModal from "./Modals/BatteryReportModal";
import SensorReportModal from "./Modals/SensorReportModal";
import AddPendantModal from "./Modals/AddPendantModal";
import UpdatePendantModal from "./Modals/UpdatePendantModal";
import JsonBoxModal from "./Modals/JsonBoxModal";

import BatteryReportIcon from "@mui/icons-material/BatteryFull";
import AddPendantIcon from "@mui/icons-material/SettingsRemote";
import GuardianAiIcon from "@mui/icons-material/Speaker";
import HouseRounded from "@mui/icons-material/HouseRounded";
import ReportIcon from "@mui/icons-material/List";
import Tooltip from "@material-ui/core/Tooltip";

import { SensorsList } from "../list/SensorList";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InteliIcon from "../../../../components/InteliIcon";
import { enums } from "@intelicare/shared";
import { colors } from "../../../../redux";
import AddGuardianAIModal from "./Modals/AddGuardianAIModal";
const colorForStatusValue = colors.colorForStatusValue;

const { SENSOR_STATUS } = enums;

class SystemView extends Component {
  state = {
    expanded: false,
    currentSensor: {},
    showBatteryReportModal: false,
    showSensorEventsModal: false,
    showAddPendantModal: false,
    showUpdatePendantModal: false,
    showGuardianAiModal: false,
    showJsonModalBox: false,
  };

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  getRootSensorsWithZones = () =>
  {
    const { zones, household } = this.props

    const sensors = []

    for(const zone of zones)
    {
      for(const room of zone.rooms)
      {
        for(const sensor of room.sensors)
        {
          sensor.zoneFriendlyName = zone.friendlyName
          sensor.zoneId = zone.zoneId
          if(!sensor.parentid && !sensor.dependencyid)
          {
            // Get the system health for it
            const sensorWithSystemHealth= household.data.sensors.find(sensorWithSystemHealth => sensorWithSystemHealth.id === sensor.id)
            sensors.push({ ...sensorWithSystemHealth, ...sensor })
          }
        }
      }
    }
    return sensors;
  }

  getRootSensors = () =>
  {
    const { household } = this.props;

    const rootSensors = household.data.sensors.filter(
      (sensor) => !sensor.parentId && !sensor.dependencyId
    );
    if (rootSensors.length === 0) {
      return (
        <div>
          <Typography align="center" variant="overline">
            No sensors for household
          </Typography>
        </div>
      );
    }
    return rootSensors
  }

  render() {
    const {
      classes,
      id,
      household,
      isFetching,
      isDeveloper,
      isSupport,
      useNewLook,
      hasZones,
    } = this.props;

    const statusColor = colorForStatusValue(
      (household ? household.systemHealthStatus : undefined) ||
        SENSOR_STATUS.UNKNOWN
    );

    return (
      <Grid id={id} item xs={12}>
        <Card automation_attribute="systemHealthCard">
          <CardHeader
            automation_attribute="systemHealthHeader"
            classes={{
              root: classes.headerRoot,
              title: classes.headerTitle,
              subheader: classes.headerSubheader,
            }}
            title={!useNewLook && <Typography variant="h5">System Health</Typography>}
            subheader={ !useNewLook &&
              <Typography color="textSecondary" variant="body1">
                Status information for household sensors
              </Typography>
            }
            action={
              <div>
                {isDeveloper && (
                  <IconButton>
                    <ReportIcon
                      onClick={() =>
                        this.setState({ showSensorEventsModal: true })
                      }
                    ></ReportIcon>
                  </IconButton>
                )}
                {isDeveloper && (
                  <IconButton>
                    <BatteryReportIcon
                      onClick={() =>
                        this.setState({ showBatteryReportModal: true })
                      }
                    ></BatteryReportIcon>
                  </IconButton>
                )}
                {isDeveloper && (
                  <Tooltip title="Add a pendant to this household">
                    <IconButton>
                      <AddPendantIcon
                        onClick={() =>
                          this.setState({ showAddPendantModal: true })
                        }
                      ></AddPendantIcon>
                    </IconButton>
                  </Tooltip>
                )}
                {isDeveloper && (
                  <Tooltip title="Add a Guardian AI to this household">
                    <IconButton>
                      <GuardianAiIcon
                        onClick={() =>
                          this.setState({ showGuardianAiModal: true })
                        }
                      ></GuardianAiIcon>
                    </IconButton>
                  </Tooltip>
                )}
                {isDeveloper && (
                  <Tooltip title="View the household and gateway document">
                    <IconButton>
                      <HouseRounded
                        onClick={() =>
                          this.setState({ showJsonModalBox: true })
                        }
                      ></HouseRounded>
                    </IconButton>
                  </Tooltip>
                )}

                {!useNewLook && <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: this.state.expanded,
                  })}
                  onClick={this.handleExpandClick}
                  aria-expanded={this.state.expanded}
                  aria-label="Show more"
                >
                  <ExpandMoreIcon
                    automation_attribute="systemHealthExpandArrow"
                    fontSize="large"
                    color="primary"
                    style={{ backgroundColor: "#F0F0F0", borderRadius: "50%" }}
                  />
                </IconButton> }
              </div>
            }
            avatar={
              !useNewLook &&
              <div>
                <InteliIcon
                  size={55}
                  name="clipboard-pulse"
                  family="community"
                  color={statusColor}
                />
              </div>
            }
          />
          <Collapse in={useNewLook || this.state.expanded} timeout="auto" unmountOnExit>
            <CardContent automation_attribute="systemHealthContent">
              {isFetching && (
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    flex: "row",
                    display: "flex",
                  }}
                >
                  <CircularProgress
                    variant="indeterminate"
                    size={30}
                    align="center"
                    style={{ alignSelf: "center" }}
                    color="primary"
                  />
                </div>
              )}
              {!isFetching && (
                <SensorsList
                  hasZones={hasZones}
                  onDeleteModalOpen={(sensor) =>
                    this.setState({
                      showConfirmDeleteModal: true,
                      currentSensor: sensor,
                    })
                  }
                  onUpdatePendantModalOpen={(sensor) =>
                    this.setState({
                      showUpdatePendantModal: true,
                      currentSensor: sensor,
                    })
                  }
                  isDeveloper={isDeveloper}
                  isSupport={isSupport}
                  sensorsList={hasZones ? this.getRootSensorsWithZones() : this.getRootSensors()}
                  household={household}
                  classes={classes}
                />
              )}
            </CardContent>
          </Collapse>

          <ConfirmDeleteModal
            household={household}
            onDeleteCallback={(data) =>
            {
              const updatedSensorData = this.state.sensorsListData.filter((sensor) => data.id !== sensor.id)
              this.setState({ sensorsListData: updatedSensorData })
            }

            }
            sensor={this.state.currentSensor}
            open={this.state.showConfirmDeleteModal}
            handleClose={() =>
              this.setState({
                showConfirmDeleteModal: false,
                currentSensor: {},
              })
            }
          />

          <BatteryReportModal
            open={this.state.showBatteryReportModal}
            handleClose={() => this.setState({ showBatteryReportModal: false })}
          ></BatteryReportModal>

          <SensorReportModal
            household={household}
            open={this.state.showSensorEventsModal}
            handleClose={() => this.setState({ showSensorEventsModal: false })}
          ></SensorReportModal>

          <AddPendantModal
            householdId={household.householdId}
            friendlyName={household.friendlyName}
            open={this.state.showAddPendantModal}
            handleClose={() => this.setState({ showAddPendantModal: false })}
          ></AddPendantModal>

          <UpdatePendantModal
            sensor={this.state.currentSensor}
            householdId={household.householdId}
            friendlyName={household.friendlyName}
            open={this.state.showUpdatePendantModal}
            handleClose={() => this.setState({ showUpdatePendantModal: false })}
          ></UpdatePendantModal>

          <AddGuardianAIModal
            open={this.state.showGuardianAiModal}
            handleClose={() => this.setState({ showGuardianAiModal: false })}
            householdId={household.householdId}
            friendlyName={household.friendlyName}
          />

          {this.state.showJsonModalBox && <JsonBoxModal
            title={household.friendlyName}
            handleClose={() => this.setState({ showJsonModalBox: false })}
            open={this.state.showJsonModalBox}
          /> }
        </Card>
      </Grid>
    );
  }
}

SystemView.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object,
  household: PropTypes.object,
  isFetching: PropTypes.bool,
  expanded: PropTypes.bool,
  isDeveloper: PropTypes.bool,
  isSupport: PropTypes.bool,
  useNewLook: PropTypes.bool,
  zones: PropTypes.array,
  hasZones: PropTypes.bool,
};

export const styles = (theme) => ({
  headerRoot: {
    background: "white",
  },
  headerTitle: {
    color: "black",
  },
  headerSubheader: {
    color: "black",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  StatusBadge: {
    fontWeight: "bold",
    paddingLeft: 5,
    paddingRight: 5,
    marginRight: 3,
    marginLeft: 3,
    display: "inline-block",
    borderRadius: 3,
    color: "white",
  },
});

export default withStyles(styles)(SystemView);
