import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'

export default class ThresholdTextField extends Component {
  render() {
    const { name, isBloodPressure, metric, handler, isUpper, upperLowerError, bpError, temperatureUnits } = this.props
    return (
      <TextField
        style={{ width: "90%", margin: 10 }}
        name={name}
        error={upperLowerError || bpError}
        margin='dense'
        variant='outlined'
        InputProps={{
          endAdornment: <InputAdornment position="end">{temperatureUnits ? temperatureUnits : metric.valueType}</InputAdornment>,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => handler(e, isUpper)}
        value={isUpper ? metric.upperThreshold : metric.lowerThreshold}
        placeholder="None"
        label={isUpper ? "Upper Limit" : "Lower Limit"}
        helperText={upperLowerError ? "Upper limit is less than the lower limit" : isBloodPressure ? "Please input a value in the form 120/80" : ""} />
    )
  }
}

ThresholdTextField.propTypes = {
  name: PropTypes.string,
  isBloodPressure: PropTypes.bool,
  metric: PropTypes.object,
  handler: PropTypes.func,
  isUpper: PropTypes.bool,
  upperLowerError: PropTypes.bool,
  bpError: PropTypes.bool,
  textInputClassName: PropTypes.object,
  temperatureUnits: PropTypes.string,
}