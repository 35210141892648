import { connect } from 'react-redux'
import HouseholdTab from './HouseholdTab'

import redux from '../../../../../redux'

const callAPI = redux.userStore.callAPI

const {
  GET_HOUSEHOLDS,
  GET_HOUSEHOLDS_BY_RESELLER_ID,
} = redux.apiEndpoints

const mapStateToProps = state => {
  return {
    households: state.householdsV2.householdsList,
    resellerHouseholds: state.householdsV2.resellerHouseholds,
    isGettingAllHouseholds: state.householdsV2.isGettingAllHouseholds,
    resellers: state.householdsV2.resellers,
    token: state.user.token,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getHouseholds: (params) => dispatch(callAPI(GET_HOUSEHOLDS(params))),
    getHouseholdsByResellerId: (params) => dispatch(callAPI(GET_HOUSEHOLDS_BY_RESELLER_ID(params))),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HouseholdTab)