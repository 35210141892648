import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'

export const NoNotifications = () =>
{
  NoNotifications.propTypes =
  {
    text: PropTypes.string,
  }

  return (
    <div style={styles.container}>
      <NotificationsNoneIcon style={styles.icon} color="textSecondary" />
      <Typography color="textSecondary" variant="body2">{`Doesn't look like there are any notifications here`}</Typography>
    </div>
  )
}

const styles = {
  container: {
    textAlign: 'center',
  },
}

