import { connect } from 'react-redux'
import { compose } from 'redux'
import { withErrorHandler, Fallback } from '../../../../hoc/withErrorHandler'
import ConsoleRootView from '../components/ConsoleRootView'

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

const mapStateToProps = (state) => ({
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorHandler(Fallback)
)(ConsoleRootView)


