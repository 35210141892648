import React, { Component } from 'react'
import { HouseholdTabs, TAB_NAMES } from './tabs/HouseholdTabs';
import Title from "../../../components/Title";
import Icon from "@material-ui/core/Icon";
import ToolbarButton from "../../../components/ToolbarButton";
import { TrendsTab } from './tabs/TrendsTab';
import { ActivityTab } from './tabs/ActivityTab'
import { HealthMetricsTab } from './tabs/HealthMetricsTab'
import ActivityStream from './list/ActivityStream';

// Tab containers
import { SleepTab } from './tabs/SleepTab';
import { MealTab } from './tabs/MealTab'
import { PresenceTab } from './tabs/PresenceTab';
import { NotificationsTab } from './tabs/NotificationsTab';
import { SystemHealthTab } from './tabs/SystemHealthTab'
import { appInsights } from '../../../api/AppInsights'
import HouseholdSettingsPortal from "../../../components/HouseholdSettingsPortal";
import { DomesticTab } from './tabs/DomesticTab';
import { ISensor } from '@intelicare/shared/interfaces/household/ISensor';


interface HouseholdTabViewProps
{
  useNewLook: boolean;
  householdName: string;
  householdAvatar: string;
  showHouseholdSettingsMenu: boolean;
  isDeveloper: boolean;
  isSupport: boolean;
  householdId: string;
  activeV2Household: any;
  openHealthMetricSettings: () => void;
  timezone: string;
  behaviours: [];
  notifications: [];
  clearNotificationsForHousehold: () => void;
  isGettingNotificationsForHousehold: boolean;
  getNotifications: () => void;
  viewMessage: () => void;
  messageResolving: boolean;
  isFetching: boolean;
  token: string;
  showSettingsPage: string;
  updateShowSettingsMenu: (value: boolean) => void;
  onSelect: () => void;
  sensors: ISensor[];
  fetchSensors: (householdId: string) => void;
  hasZones: boolean;
}
export class HouseholdTabView extends Component<HouseholdTabViewProps>
{

  state = {
    currentTab: 0,
  }

  componentDidMount()
  {
    const { fetchSensors, householdId } = this.props
    appInsights.trackPageView({ name: "Household Details Tab View" })
    this.navigateToTabBasedOnUrl()

    fetchSensors(householdId)
  }

  navigateToTabBasedOnUrl = () =>
  {
    try
    {
      const url = window.location.href.toLowerCase();
      if(url.includes("activity")) return this.setState({ currentTab: TAB_NAMES.ACTIVITY })
      if(url.includes("trends")) return this.setState({ currentTab: TAB_NAMES.TRENDS })
      if(url.includes("healthMetrics")) return this.setState({ currentTab: TAB_NAMES.HEALTH })
      if(url.includes("sleep")) return this.setState({ currentTab: TAB_NAMES.SLEEP })
      if(url.includes("meal_prep")) return this.setState({ currentTab: TAB_NAMES.MEALS })
      if(url.includes("presence")) return this.setState({ currentTab: TAB_NAMES.PRESENCE })
      if(url.includes("notifications")) return this.setState({ currentTab: TAB_NAMES.NOTIFICATIONS })
      if(url.includes("system")) return this.setState({ currentTab: TAB_NAMES.SYSTEMHEALTH })
      if(url.includes("domestic")) return this.setState({ currentTab: TAB_NAMES.DOMESTIC })
    }
    catch(error)
    {
      // Error parsing the URL
    }
  }

  handleTabChange = (i: number) =>
  {
    // TODO: append tab name to URL
    this.setState({ currentTab: i })
  }

  render()
  {
    const {
      householdName,
      householdAvatar,
      isSupport,
      isDeveloper,
      householdId,
      activeV2Household,
      useNewLook,
      openHealthMetricSettings,
      timezone,
      behaviours,
      clearNotificationsForHousehold,
      isGettingNotificationsForHousehold,
      getNotifications,
      viewMessage,
      messageResolving,
      notifications,
      isFetching,
      token,
      showSettingsPage,
      showHouseholdSettingsMenu,
      updateShowSettingsMenu,
      onSelect,
      sensors,
      hasZones,
    } = this.props


    const { currentTab } = this.state;

    return(
      <div style={styles.container}>
        <Title
          title={householdName}
          iconLeft={householdAvatar}
          pageButtons={
            <ToolbarButton
              action={() => updateShowSettingsMenu(true)}
              label="Household Settings"
              icon={<Icon>home</Icon>}
            />
          }
        />
        <div style={styles.columnContainer}>

          <div style={styles.activityStream}>
            <ActivityStream householdId={householdId} />
          </div>

          <div style={styles.tabContainer}>

            <HouseholdTabs
              systemHealthStatus={activeV2Household.systemHealthStatus}
              behaviourRollup={activeV2Household.behaviourRollup}
              currentTab={currentTab}
              onChangeTab={(i: number) => this.handleTabChange(i)}/>

            {currentTab === TAB_NAMES.TRENDS &&
            <TrendsTab householdId={householdId} token={token}/>}

            {currentTab === TAB_NAMES.ACTIVITY &&
            <ActivityTab
              hasZones={hasZones}
              useNewLook={useNewLook}
              isDeveloper={isDeveloper}
              isSupport={isSupport}
              householdId={householdId}
              activeV2Household={activeV2Household}/>}

            {currentTab === TAB_NAMES.HEALTH &&
            <HealthMetricsTab
              useNewLook={useNewLook}
              activeV2Household={activeV2Household}
              openHealthMetricSettings={openHealthMetricSettings}
              isDeveloper={isDeveloper}
              isSupport={isSupport} />}

            {currentTab === TAB_NAMES.SLEEP &&
              <SleepTab
                hasZones={hasZones}
                useNewLook={useNewLook}
                isDeveloper={isDeveloper}
                activeV2Household={activeV2Household}
                timezone={timezone}
              />
            }

            {currentTab === TAB_NAMES.MEALS &&
              <MealTab
                useNewLook={useNewLook}
                activeV2Household={activeV2Household}
                behaviours={behaviours}/>
            }

            {currentTab === TAB_NAMES.PRESENCE &&
            <PresenceTab
              useNewLook={useNewLook}
              activeV2Household={activeV2Household}
            />}

            {currentTab === TAB_NAMES.DOMESTIC &&
            <DomesticTab
              sensors={sensors}
              isSupport={isSupport}
              householdId={householdId}
              isDeveloper={isDeveloper}
              useNewLook={useNewLook}
              activeV2Household={activeV2Household}
            />}

            {currentTab === TAB_NAMES.NOTIFICATIONS &&
            <NotificationsTab
              onSelect={onSelect}
              notifications={notifications}
              householdId={householdId}
              clearNotificationsForHousehold={clearNotificationsForHousehold}
              viewMessage={viewMessage}
              isGettingNotificationsForHousehold={isGettingNotificationsForHousehold}
              messageResolving={messageResolving}
              getNotifications={getNotifications}
              useNewLook={useNewLook}
              activeV2Household={activeV2Household}
            />}

            {currentTab === TAB_NAMES.SYSTEMHEALTH &&
            <SystemHealthTab
              hasZones={hasZones}
              householdId={householdId}
              isFetching={isFetching}
              activeV2Household={activeV2Household}
              isDeveloper={isDeveloper}
              isSupport={isSupport}
              useNewLook={useNewLook}/>}
          </div>

        </div>

        {showHouseholdSettingsMenu && <HouseholdSettingsPortal
          close={() => updateShowSettingsMenu(false)}
          showPage={showSettingsPage}
        />}
      </div>
    )
  }
}

const styles = {
  container: {
    padding: 18,
    backgroundColor: '#EFEFF0',
  },
  columnContainer: {
    display: 'flex' as 'flex',
    flexDirection: 'row' as 'row',
  },
  activityStream: {
    marginRight: 12,
    maxHeight: 1000,
    minWidth: 300,
    maxWidth: 300,
    overflow: 'auto',
    borderRadius: 6,
  },
  tabContainer: {
    overflow: 'auto',
    flex: 1,
  },
}