/* eslint-disable indent */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from "moment"

// Material UI components
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'


class ExportData extends Component {

  state = {
    startDate: getDate(new moment().subtract(24, 'hours').toDate()),
    startTime: getTime(new Date()),
    endDate: getDate(new Date()),
    endTime: getTime(new Date()),
    minuteByMinute: true,
  }

  componentDidUpdate(prevProps) {
    const { showing } = this.props
    if (!prevProps.showing && showing) {
      this.setState({
        ...this.state,
        startDate: getDate(new moment().subtract(24, 'hours').toDate()),
        startTime: getTime(new Date()),
        endDate: getDate(new Date()),
        endTime: getTime(new Date()),
      })
    }

  }

  toggleResolution = () => {
    this.setState(prevState => ({ minuteByMinute: !prevState.minuteByMinute }))
  }

  exportData = () => {
    const { householdId, sensorId } = this.props
    const startTime = moment(`${this.state.startDate}T${this.state.startTime}`).toISOString()
    const endTime = moment(`${this.state.endDate}T${this.state.endTime}`).toISOString()
    if (!householdId || !sensorId) return
    const sensor = sensorId[0].id
    this.props.export({ householdId, sensorId: sensor, startTime, endTime })
  }

  download = () => {
    const { exportUri } = this.props
    window.open(exportUri)
  }

  updateStartDate = (event) => {
    const { value } = event.target
    this.setState((prev) => ({
      ...prev,
      startDate: value,
    }))
  }

  updateStartTime = (event) => {
    const { value } = event.target
    this.setState((prev) => ({
      ...prev,
      startTime: value,
    }))
  }

  updateEndDate = (event) => {
    const { value } = event.target
    this.setState((prev) => ({
      ...prev,
      endDate: value,
    }))
  }

  updateEndTime = (event) => {
    const { value } = event.target
    this.setState((prev) => ({
      ...prev,
      endTime: value,
    }))
  }

  render() {
    const { isPolling, exportUri } = this.props
    return (
      <Card style={{ padding: '24px', marginTop: 12 }}>
        <Typography>Select data export range</Typography>
        <Grid container justify='space-around'>
          <Grid item container sm='4' justify='center'>
            <TextField label='Start Date' type="date" value={this.state.startDate} onChange={this.updateStartDate}></TextField>
            <TextField label='Start Time' type="time" value={this.state.startTime} onChange={this.updateStartTime}></TextField>
          </Grid>
          <Grid item container sm='4' justify='center'>
            <TextField label='End Date' type="date" value={this.state.endDate} onChange={this.updateEndDate}></TextField>
            <TextField label='End Time' type="time" value={this.state.endTime} onChange={this.updateEndTime}></TextField>
          </Grid>
          <Grid item container sm={4} style={{ padding: '8px' }} justify='center'>
            {!isPolling &&
              <Button variant='contained' color='secondary' size='small' onClick={this.exportData} style={{ margin: '8px' }}>Export</Button>
            }
            {isPolling &&
              <CircularProgress />
            }
            {!isPolling && exportUri &&
              <Button variant='contained' color='secondary' size='small' onClick={this.download} style={{ marginTop: '8px', marginBottom: '8px' }}>Download</Button>
            }
          </Grid>
        </Grid>
      </Card>
    )
  }

}

function getDate(isodate) {
  return `${isodate.getFullYear()}-${pad(isodate.getMonth() + 1, '0', 2)}-${pad(isodate.getDate(), '0', 2)}`
}

function getTime(isodate) {
  return `${pad(isodate.getHours(), '0', 2)}:${pad(isodate.getMinutes(), '0', 2)}:${pad(isodate.getSeconds(), '0', 2)}`
}

function pad(value, padchar, desiredLength) {
  const stringValue = value.toString()
  if (stringValue.length < desiredLength) {
    return pad(padchar + stringValue, padchar, desiredLength)
  }
  return stringValue
}

ExportData.propTypes = {
  defaultScale: PropTypes.string,
  export: PropTypes.func,
  householdId: PropTypes.string,
  sensorId: PropTypes.string,
  isPolling: PropTypes.bool,
  exportUri: PropTypes.string,
  showing: PropTypes.bool,
}

export default ExportData
