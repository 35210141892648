import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import InteliIcon from "../../../../../../components/InteliIcon";
import {
  iconForEvent,
  previewNameForActivity,
} from "../../../../../../redux/shared/util/activity";
import moment from "moment";
const MAX_EVENTS_TO_SHOW = 5;

interface ActivityListProps {
  events: Event[];
}

interface Event {
  id: string;
  activityType: string;
  timestamp: string;
}

export const ActivityList = ({ events }: ActivityListProps) => {
  return (
    <List>
      {events && events.length > 0 ? (
        events.slice(0, MAX_EVENTS_TO_SHOW).map((event) => {
          return (
            <ListItem key={event.id}>
              <ListItemAvatar>
                <Avatar style={styles.iconStyle}>
                  <InteliIcon
                    style={styles.iconStyle}
                    family={iconForEvent(event.activityType).family}
                    name={
                      event.activityType === "bathVisit"
                        ? "sink-small"
                        : iconForEvent(event.activityType).name
                    }
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={previewNameForActivity(event)}
                secondary={moment(event.timestamp).format("hh:mm A")}
              />
            </ListItem>
          );
        })
      ) : (
        <div style={styles.noActivity}>
          <InteliIcon
            style={styles.noActivityIcon}
            size={18}
            name="home"
            family="awesome"
          />
          <Typography color="textSecondary" variant="body2">
            No Activity
          </Typography>
        </div>
      )}
    </List>
  );
};

const styles = {
  noActivity: {
    justifyContent: "center",
    display: "flex",
    padding: 12,
  },
  noActivityIcon: {
    color: "grey",
    marginRight: 6,
  },
  iconStyle: {
    backgroundColor: "#8BC34A",
    color: "white",
  },
};
