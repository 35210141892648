export enum ZoneActions
{
  CREATE_ZONE_BEGIN = "CREATE_ZONE_BEGIN",
  CREATE_ZONE_SUCCESS = "CREATE_ZONE_SUCCESS",
  CREATE_ZONE_ERROR = "CREATE_ZONE_ERROR",
  CLEAR_ADD_ZONE_STATUS = "CLEAR_ADD_ZONE_STATUS",
  UPDATE_ZONE_BEGIN = "UPDATE_ZONE_BEGIN",
  UPDATE_ZONE_SUCCESS = "UPDATE_ZONE_SUCCESS",
  UPDATE_ZONE_ERROR = "UPDATE_ZONE_ERROR",
  GET_ZONES_BEGIN = "GET_ZONES_BEGIN",
  GET_ZONES_SUCCESS = "GET_ZONES_SUCCESS",
  GET_ZONES_ERROR = "GET_ZONES_ERROR",
  REMOVE_ZONE_BEGIN = "REMOVE_ZONE_BEGIN",
  REMOVE_ZONE_SUCCESS = "REMOVE_ZONE_SUCCESS",
  REMOVE_ZONE_ERROR = "REMOVE_ZONE_ERROR",
  ADD_ROOM_TO_ZONE = "ADD_ROOM_TO_ZONE",
  REMOVE_ROOM_FROM_ZONE_BEGIN = "REMOVE_ROOM_FROM_ZONE_BEGIN",
  REMOVE_ROOM_FROM_ZONE_SUCCESS = "REMOVE_ROOM_FROM_ZONE_SUCCESS",
  REMOVE_ROOM_FROM_ZONE_ERROR = "REMOVE_ROOM_FROM_ZONE_ERROR",
  CLEAR_REMOVE_ROOM_FROM_ZONE = "CLEAR_REMOVE_ROOM_FROM_ZONE",
  UPDATE_ROOM_NAME = "UPDATE_ROOM_NAME",
  UPDATE_ROOM_NAME_SUCCESS = "UPDATE_ROOM_NAME_SUCCESS",
  ADD_SENSOR_TO_ROOM = "ADD_SENSOR_TO_ROOM",
  ADD_SENSOR_TO_ROOM_SUCCESS = "ADD_SENSOR_TO_ROOM_SUCCESS",
  REMOVE_SENSOR_FROM_ROOM = "REMOVE_SENSOR_FROM_ROOM",
  REMOVE_SENSOR_FROM_ROOM_SUCCESS = "REMOVE_SENSOR_FROM_ROOM_SUCCESS",
  CLEAR_UPDATE_ZONE_STATUS = "CLEAR_UPDATE_ZONE_STATUS",
  CLEAR_REMOVE_ZONE_STATUS = "CLEAR_REMOVE_ZONE_STATUS",
  ADD_ROOM_SUCCESS = "ADD_ROOM_SUCCESS",
  SHOW_SNACK = "SHOW_SNACK",
  HIDE_SNACK = "HIDE_SNACK",
  UPDATE_HAS_ZONES_STATE = "UPDATE_HAS_ZONES_STATE",
  GET_GATEWAY_BEGIN = "GET_GATEWAY_BEGIN",
  GET_GATEWAY_SUCCESS= "GET_GATEWAY_SUCCESS"

}