import { filter } from 'lodash';
import { ISensorConfiguration } from '../../../../../model/SensorConfiguration';

export const useFilter = (query: string, results: ISensorConfiguration[]): ISensorConfiguration[] =>
{
  const q = query.toLowerCase();
  const filtered = results.filter(result => {
    try
    {
      if(result.productName.toLowerCase().includes(q) || result.manufacturer.toLowerCase().includes(q) || result.deviceIds.find(id => id.toLowerCase().includes(q))) return result;
    }
    catch(error)
    {
      //
    }
  })

  return filtered;
}