import React, { Component } from "react";
import { HouseholdHealthChart } from "./HouseholdHealthChart";
import { HouseholdHealthRollup } from "./interface";

interface HouseholdHealthTabProps {
  getRollup(): void;
  householdsHealthRollup: HouseholdHealthRollup[];
  householdsHealthRollupError: string;
  isGettingHouseholdsHealth: boolean;
}

class HouseholdHealthTab extends Component<HouseholdHealthTabProps> {
  componentDidMount() {
    const { getRollup } = this.props;

    getRollup();
  }

  render() {
    const {
      householdsHealthRollup,
    } = this.props;
    return (
      <div>
        <HouseholdHealthChart householdsHealthRollup={householdsHealthRollup} />
      </div>
    );
  }
}

export default HouseholdHealthTab;
