import React from "react";
import RouterIcon from "@mui/icons-material/Router";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const SearchPlaceholder = () => {
  return (
    <Grid
      container
      direction="column"
      style={{ minHeight: "100vh" }}
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <div style={{ textAlign: "center" }}>
          <RouterIcon style={{ color: "grey", fontSize: 150 }} />
          <Typography variant="h3" color="textSecondary">
            It&apos;s pretty quiet here
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export { SearchPlaceholder };
