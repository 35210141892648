import { createTheme } from '@material-ui/core/styles'

/**
 * Full spec for default theme properties
 * https://material-ui.com/customization/default-theme/
 */

export const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: 'Source Sans Pro, sans-serif',
    h3: {
      fontSize: '24px',
    },
  },
  overrides: {
    // MuiAppBar: {
    //   root: {
    //     height: '64px',
    //   },
    // },
    MuiDialogTitle: {
      root: {
        background: '#E0204D',
        color: '#000000',
      },
    },
    MuiGridListTile: {
      root: {
        listStyle: 'none',
      },
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#E0204D',
      contrastText: '#fff',
    },
    secondary: {
      main: '#991b37',
      contrastText: '#fff',
    },
    client: {
      main: '#154F80',
      contrastText: '#fff',
    },
  },
  fontFamily: 'Quicksand, sans-serif',
  mainMenu: {
    text: {
      fontSize: '14px',
    },
  },
  graphColors: {
    setA: [
      "#E0204D",
      "#991b37",
      "#154F80",
    ],
    setB: [
      "#E0204D",
      "#991b37",
      "#154F80",
    ],
  },
})
