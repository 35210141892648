import { SOCIAL_ISOLATION_COLORS } from "./colors"
import { PresenceBehaviour } from "./getPresenceBehaviour"

export const getOutingStats = (presence: PresenceBehaviour) => {

  // Filter out houses without correct presence insight version
  if (!presence.statistics || presence.statistics.average === 0) {
    return
  }


  const stats = presence.statistics
  if (stats.average > stats.current) {
    stats.direction = 'down'
    stats.color = SOCIAL_ISOLATION_COLORS.WARNING
    stats.status = 'WARNING'
    stats.message = 'The outing trend this week is lower than normal.'
  } else {
    stats.direction = 'up'
    stats.color = SOCIAL_ISOLATION_COLORS.NORMAL;
    stats.status = 'NORMAL'
    stats.message = 'The outing trend this week is normal.'
  }
  return stats
}