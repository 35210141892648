export default theme => ({
  Root: {
    paddingLeft: 2,
    borderLeftWidth: 8,
    borderLeftStyle: 'solid',
  },
  Avatar: {
    height: 110,
    width: 110,
    objectFit: 'cover',
  },
  button: {
    marginRight: 16,
    alignContent: 'center',
  },
})
