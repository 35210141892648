import React from "react";
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, Divider, Typography,
} from "@material-ui/core";
import NotificationsIcon from "@mui/icons-material/Notifications";
import IncidentDetectionListItem from "./IncidentDetectionListItem";
import { Skeleton } from "@material-ui/lab";
import { GatewayApi } from '../../api/GatewayApi';
import { SnackAlert } from "../../routes/Organisations/component/Shared/SnackAlert";

const description = "The timeout should be set to the amount of time that the resident could spend in that room before it is concerning enough to warrant a notification.";
const noSensorsMessage = "No valid motion sensors were found!";
const updatingMsg = "Updating Incident Detection settings...";
const updatedMsg = "Successfully updated Incident Detection settings!";
const failedUpdateMsg = "Unsuccessfully updated Incident Detection settings!";
const styles = { container: { padding: 12 }, input: { margin: 12 } };

interface ConfigureIncidentDetectionProps {
  open: boolean;
  handleClose: () => void;
  update: (householdId: string, body: any) => void;
  clear: () => void;
  isUpdatingIncidentDetection: boolean;
  updateIncidentDetectionSuccess: boolean;
  updateIncidentDetectionFailed: boolean;
  householdId: string;
  token: string;
  gatewayId: string;
}

interface MotionSensor {
  incidentDetection: any;
  id: string,
  roomType: string,
}

interface ConfigureIncidentDetectionState {
  isLoading: boolean;
  motionSensors: MotionSensor[];
  sensorUpdates: Map<string, any>;
}

interface IncidentSensorUpdate {
  id: string;
  incidentDetection: any;
}

export default class ConfigureIncidentDetectionModal extends React.Component<ConfigureIncidentDetectionProps> {
  state: ConfigureIncidentDetectionState = {
    isLoading: false,
    motionSensors: [],
    sensorUpdates: new Map(),
  }

  render() {
    const {
      open, handleClose, clear, isUpdatingIncidentDetection, updateIncidentDetectionSuccess, updateIncidentDetectionFailed,
    } = this.props;
    return <Dialog
      onEnter={() => this.setCurrentAlertWindow()}
      open={open}
      onClose={() => {
        handleClose();
      }}
      TransitionProps={{
        onExit: clear,
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="configure-incident-detection-dialog-title">
        <NotificationsIcon style={{ position: "relative", top: 5, right: 5 }} />
        Configure Incident Detection
      </DialogTitle>
      <DialogContent style={styles.container}>
        <DialogContentText align="center" id="alert-dialog-description">
          {description}
        </DialogContentText>
        <Divider />
        <List>{this.renderMotionSensorList()}</List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">Close</Button>
        <Button
          disabled={this.state.isLoading || !this.state.sensorUpdates.size}
          onClick={() => this.onSave()}
          color="primary"
          autoFocus
        >Save</Button>
      </DialogActions>
      <SnackAlert open={isUpdatingIncidentDetection} color="info" onClose={() => {} } alertMsg={updatingMsg} />
      <SnackAlert open={updateIncidentDetectionSuccess} color="success" onClose={() => {} } alertMsg={updatedMsg} />
      <SnackAlert open={updateIncidentDetectionFailed} color="error" onClose={() => {} } alertMsg={failedUpdateMsg} />
    </Dialog>;
  }

  setCurrentAlertWindow = async () => {
    this.setState({ isLoading: true });
    const { token, gatewayId } = this.props;
    const gatewayApi = new GatewayApi(token);
    const gateway = await gatewayApi.getGateway(gatewayId);
    const motionSensors: MotionSensor[] = [];
    for (const room of gateway.rooms) if (room.sensors) for (const sensor of room.sensors)
      if (sensor.type === "motion" && (sensor.behaviour === "None" || !sensor.behaviour))
        motionSensors.push(sensor);
    this.setState({ motionSensors, isLoading: false });
  }

  renderMotionSensorList() {
    // Render a skeleton list until loading is done.
    if (this.state.isLoading) {
      const graveyard = [];
      for (let index = 0; index < 4; index++) {
        graveyard.push(<div><Skeleton height={96} /><Divider /></div>);
      }
      return graveyard;
    }
    // Once sensors are loaded, display them.
    if (this.state.motionSensors.length < 1) {
      return <Typography align="center">{noSensorsMessage}</Typography>
    }
    return this.state.motionSensors.map((motionSensor, key) => {
      if (!motionSensor.incidentDetection) motionSensor.incidentDetection = {};
      return <div key={key}>
        <IncidentDetectionListItem
          motionSensor={motionSensor}
          onListItemChange={this.onListItemChange}
        />
        <Divider />
      </div>;
    });
  }

  onListItemChange = (sensorId: string, incidentDetection: any): void => {
    const newSensorPatches = new Map(this.state.sensorUpdates);
    newSensorPatches.set(sensorId, incidentDetection);
    this.setState({ sensorUpdates: newSensorPatches });
  }

  onSave = () => {
    const { sensorUpdates } = this.state;
    if (sensorUpdates.size < 1) return;
    const { householdId, update } = this.props
    const updateArray: IncidentSensorUpdate[] = [];
    for (const [id, incidentDetection] of sensorUpdates)
      updateArray.push({ id, incidentDetection });
    update(householdId, { gatewaySensorPatches: updateArray });
  }
}