/*
 * metricType - the type of the health metric from HEALTH_METRIC_TYPES
 * metricValue - the reading of the metric in a string form
 * timestamp - a timestamp in the format 'YYYY-MM-DD hh:mm:ss A' (SQL server timestamp format)
 * householdId - the users household ID
 * dataSource - the inputType: manualInput for manual value input
 */

export class HealthMetric {

  constructor(metricType, metricValue, timestamp, householdId, dataSource) {
    this.metricType = metricType
    this.metricValue = metricValue
    this.timestamp = timestamp
    this.householdId = householdId
    this.dataSource = dataSource
  }
}