import React, { useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import { Avatar, CircularProgress } from '@material-ui/core';
import { purple } from '@material-ui/core/colors';
import { getInitials } from '../utils/getInitials';
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../../interfaces/reduxState';
import { addOrganisationBegin, addOrganisationClear } from '../../../../../redux/domains/organisations/actionCreators';
import { SnackAlert } from '../../Shared/SnackAlert';

export interface CreateOrganisationModalProps
{
  handleClose: () => void;
  open: boolean;
  parentOuId: string;
}

export const CreateOrganisationModal = ({ handleClose, open, parentOuId }: CreateOrganisationModalProps) =>
{
  const [ organisationName, setOrganisationName ] = useState("")

  const dispatch = useDispatch()
  const isFetching = useSelector(({ organisations }: ApplicationState) => organisations.isFetching)
  const addOrganisationSuccess = useSelector(({ organisations }: ApplicationState) => organisations.addOrganisationSuccess)
  const addOrganisationError = useSelector(({ organisations }: ApplicationState) => organisations.addOrganisationError)

  const handleOnExit = () =>
  {
    setOrganisationName("")
    dispatch(addOrganisationClear())
  }

  return(
    <Dialog
      data-test="create-organisation-modal"
      TransitionProps={{
        onExit: handleOnExit,
      }}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}>
      <DialogTitle>Create Organisation / Location</DialogTitle>
      <DialogContent>

        <Grid container direction="row" alignItems="center" spacing={3}>

          <Grid item>
            <Avatar style={{ width: 100, height: 100, backgroundColor: purple[500], fontSize: 32 }}>{getInitials(organisationName)}</Avatar>
          </Grid>

          <Grid item style={{ flex: 1 }}>
            <TextField
              data-test="organisation-input-box"
              onChange={(e: any) => setOrganisationName(e.target.value)}
              value={organisationName}
              autoFocus
              id="friendlyName"
              label="Organisation Name"
              type="text"
              fullWidth
              variant="outlined"
            />
          </Grid>


        </Grid>

        <SnackAlert
          onClose={() => {}}
          color='success'
          open={addOrganisationSuccess ? true : false}
          alertMsg={addOrganisationSuccess}
        />

        <SnackAlert
          onClose={() => {}}
          color='error'
          open={addOrganisationError ? true : false}
          alertMsg={addOrganisationError}
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          data-test='create-organisation-button'
          disabled={organisationName.length < 1 ? true : false}
          onClick={() => dispatch(addOrganisationBegin(parentOuId, organisationName))}
          endIcon={isFetching && <CircularProgress size={16} />}>{isFetching ? "Creating..." : `Create`}</Button>
      </DialogActions>
    </Dialog>
  )
}

