import { PaniNotification, PaniNotificationPostBody } from '@intelicare/shared/interfaces/pani/Pani'
import { useState } from 'react'
import { DeleteResult, PaniApi, UpdateNotificationResult } from '../../../api/PaniApi';

type PaniNotificationsHook = [
    boolean, // Is loading
    PaniNotification[], // Notifications array
    () => void, // Get more notifications
    (notification: PaniNotificationPostBody) => void, // Create notification
    string, // Created notification ID
    (notificationId: string, paniId: string, notification: PaniNotificationPostBody) => void, // Update notification
    UpdateNotificationResult | undefined, // updateNotificationResponse
    (notificationId: string, paniId: string) => void, // Remove notification
    DeleteResult | undefined, // delete result
    (thresholdMinutes: number) => void, // create new
    string, // Action
  ];

export const usePaniNotifications = (token: string, householdId: string, paniId: string): PaniNotificationsHook =>
{

  const [ notifications, setNotifications ] = useState<PaniNotification[]>([]);
  const [ createdNotificationId, setCreatedNotificationId ] = useState("");
  const [ updateNotificationResponse, setUpdateNotificationResponse ] = useState<UpdateNotificationResult>();
  const [ deleteResult, setDeleteResult ] = useState<DeleteResult>();
  const [ action, setAction ] = useState("");

  const [ loading, setLoading ] = useState(false);

  const appendNewNotification = (thresholdMinutes: number) =>
  {
    const notification: PaniNotification = {
      id: new Date().getTime().toString(),
      url: "",
      threshold: thresholdMinutes * 60,
      type: "time",
      num_triggers: 0,
      num_failures: 0,
      last_trigger: 0,
      // @ts-ignore
      isNew: true,
    }

    const updated = notifications.concat(notification)
    setNotifications(updated);
  }

  const getNotifications = async () =>
  {
    const paniApi = new PaniApi(token, "")
    setLoading(true);
    setAction("Getting notifications")
    const results = await paniApi.getNotifications(householdId, paniId);
    setNotifications(results.notifications)
    setLoading(false);
  }

  const createNotification = async (notification: PaniNotificationPostBody) =>
  {
    const paniApi = new PaniApi(token, "")
    setLoading(true);
    setAction("Creating notification")
    const response = await paniApi.createNotification(householdId, paniId, notification);
    setCreatedNotificationId(response.id)
    if(response && response.id)
    {
      getNotifications()
    }
    else
    {
      setLoading(false);
    }
  }

  const updateNotification = async (notificationId: string, paniId: string, notification: PaniNotificationPostBody) =>
  {
    const paniApi = new PaniApi(token, "")
    setLoading(true);
    setAction("Updating notification")
    const response = await paniApi.updateNotification(householdId, notificationId, paniId, notification);
    if(response)
    {
      getNotifications()
      setUpdateNotificationResponse(response)
    }
    else
    {
      setLoading(false);
    }
  }

  const removeNotification = async (notificationId: string, paniId: string) =>
  {
    const paniApi = new PaniApi(token, "")
    setLoading(true);
    setAction("Removing notification")
    const response = await paniApi.deleteNotification(householdId, notificationId, paniId);
    if(response)
    {
      getNotifications()
      setDeleteResult(response)
    }
    else
    {
      setLoading(false);
    }
  }

  return [
    loading,
    notifications,
    getNotifications,
    createNotification,
    createdNotificationId,
    updateNotification,
    updateNotificationResponse,
    removeNotification,
    deleteResult,
    appendNewNotification,
    action ]

}