function checkMetricWithinThresholds(metric) {
  if (metric.metricValue == -1) return

  metric.status = 'NORMAL'
  metric.message = metric.friendlyName + " is within the configured limits"
  if (metric.upperThreshold && metric.metricValue > metric.upperThreshold)
  {
    metric.status = 'WARNING'
    metric.message = `${metric.friendlyName} is of value ${metric.metricValue} which is above the expected upper bound of ${metric.upperThreshold}`
  }
  if (metric.lowerThreshold && metric.metricValue < metric.lowerThreshold)
  {
    metric.status = 'WARNING'
    metric.message = `${metric.friendlyName} is of value ${metric.metricValue} which is below the expected lower bound of ${metric.lowerThreshold}`
  }
}

export function setStatusAndMessage(metric) {
  metric.status = ""
  metric.message = "No data for this metric"

  if (metric.metricType === "BLOOD_PRESSURE")
  {
    const bpSys = {
      metricType: "BLOOD_PRESSURE_SYSTOLIC",
      friendlyName: "Systolic Blood Pressure",
      metricValue: metric.metricValue[0],
      lowerThreshold: metric.lowerThreshold[0],
      upperThreshold: metric.upperThreshold[0],
    }
    const bpDia = {
      metricType: "BLOOD_PRESSURE_DIASTOLIC",
      friendlyName: "Diastolic Blood Pressure",
      metricValue: metric.metricValue[1],
      lowerThreshold: metric.lowerThreshold[1],
      upperThreshold: metric.upperThreshold[1],
    }
    checkMetricWithinThresholds(bpSys)
    checkMetricWithinThresholds(bpDia)
    if (bpSys.status || bpDia.status) {
      metric.status = (bpSys.status === 'WARNING'|| bpDia.status === 'WARNING') ? 'WARNING' : (bpSys.status === 'NORMAL'|| bpDia.status === 'NORMAL') ? 'NORMAL' : ''
    }
    if (bpSys.message || bpDia.message) {
      metric.message = [bpSys.message, bpDia.message]
    }
  }
  else
  {
    checkMetricWithinThresholds(metric)
  }
  return metric
}