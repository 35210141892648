export default theme => ({
  TileContainer: {
    fontSize: '10px',
    lineHeight: '0.8em',
    textAlign: 'center',
    paddingTop: '1em',
    paddingBottom: '1em',
    verticalAlign: 'top',
  },
  TileText: {
    // marginBottom: 0,
    // height: '100%',
    // width: ''
    // textAlign: 'center',
  },

})
