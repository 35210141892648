import { connect } from 'react-redux'
import AppHeaderView from './AppHeaderView'
import sharedStore from '../../../redux'
import { callAPI, actionToggleDrawer } from '../../../redux/domains/user/userStore'
import { apiEndpoints } from '../../../redux'

const GET_ALL_NOTIFICATION_COUNT = apiEndpoints.GET_ALL_NOTIFICATION_COUNT
const GET_ALL_ACTIVE_ALERTS = apiEndpoints.GET_ALL_ACTIVE_ALERTS;
const setAlertsMinimised = sharedStore.notificationsStore.setAlertsMinimised
const areAlertsMinimised = sharedStore.notificationsSelector.areAlertsMinimised
const householdSummaryStatus = sharedStore.householdsSelector.householdSummaryStatus


const mapDispatchToProps = (dispatch) => {
  return {

    setAlertsMinimised: (minimised) => {
      dispatch(setAlertsMinimised(minimised))
    },
    getActiveAlerts: (params) => dispatch(callAPI(GET_ALL_NOTIFICATION_COUNT(params))),
    incrementAlertCount: (data) => dispatch({ type: "NEW_ALERT_RECEIVED", data }),
    decrementAlertCount: (data) => dispatch({ type: "ALERT_RESOLVED", data }),
    getAllActiveAlerts: (params) => dispatch(callAPI(GET_ALL_ACTIVE_ALERTS(params))),
    setUseNewLook: (params) => dispatch({ type: "USE_NEW_LOOK", params } ),
    toggleDrawer: () => dispatch(actionToggleDrawer()),
  }
}

const mapStateToProps = (state) => ({
  isFetchingHouseholds: state.households.isFetchingHouseholds,
  activeAlerts: state.notifications.activeAlerts,
  minimisedAlerts: areAlertsMinimised(state),
  setAlertsMinimised: setAlertsMinimised(state),
  status: householdSummaryStatus(state),
  token: state.user.token,
  isDeveloper: state.user.isDeveloper,
  isSupport: state.user.isSupport,
  useNewLook: state.householdsV2.useNewLook,
  isDrawerOpen: state.user.isDrawerOpen,
})

export default connect(mapStateToProps, mapDispatchToProps)(AppHeaderView)
