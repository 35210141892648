import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { SensorConfiguration, ISensorConfiguration } from '../../../../model/SensorConfiguration';
import InteliIcon from '../../../../components/InteliIcon';
interface SensorListProps
{
  handleOpen: (sensorConfiguration: SensorConfiguration) => void;
  sensorsList: ISensorConfiguration[]
}

export const SensorList = ({ handleOpen, sensorsList }: SensorListProps) =>
{
  return(
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Sensor Product Name</TableCell>
            <TableCell align="right">Device IDs</TableCell>
            <TableCell align="right">Manufacturer</TableCell>
            <TableCell align="right">Parameters</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sensorsList.map((row) => (
            <TableRow key={row.id} hover onClick={() => handleOpen(row)}>
              <TableCell component="th" scope="row">
                <div style={styles.iconContainer}>
                  <InteliIcon size={20} name={row.iconImageName} style={styles.iconStyle} family="community" />
                  {row.productName}
                </div>

              </TableCell>
              <TableCell align="right">{`${row.deviceIds[0]} + ${row.deviceIds.length} more`}</TableCell>
              <TableCell align="right">{row.manufacturer}</TableCell>
              <TableCell align="right">{row.parameters.map(p => `${p.name}: ${p.value}, `)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const styles = {
  iconContainer: {
    flexDirection: 'row' as 'row',
    display: 'flex' as 'flex',
    alignContent: 'center',
  },
  iconStyle: {
    color: 'grey',
    paddingRight: 6,
  },
}