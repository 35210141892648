export const COLORS = {
  MATERIAL_GREEN: "#4CAF50",
  MATERIAL_YELLOW: "#FFCA28",
  MATERIAL_BLUE: "#42A5F5",
  MATERIAL_RED: "#E91E63",
  MATERIAL_PURPLE: "#800080",
  MATERIAL_ORANGE: "#FF6500",
}

export const HEALTH_METRIC_TYPES = {
  HEART_RATE: { type: "HEART_RATE", colour: [COLORS.MATERIAL_RED], icon: "favorite", family: "material", valueType: "ABPM", friendlyName: "Average Heart Rate" },
  RESTING_HEART_RATE: { type: "RESTING_HEART_RATE", colour: [COLORS.MATERIAL_PURPLE], icon: "favorite", family: "material", valueType: "BPM", friendlyName: "Resting Heart Rate" },
  BLOOD_PRESSURE: { type: "BLOOD_PRESSURE", colour: [COLORS.MATERIAL_GREEN, COLORS.MATERIAL_BLUE], icon: "blood-pressure", family: "custom", valueType: "mmHg", friendlyName: "Blood Pressure" },
  WEIGHT: { type: "WEIGHT", valueType: "Kg", icon: "weight", family: "custom", colour: [COLORS.MATERIAL_YELLOW], friendlyName: "Weight" },
  BMI: { type: "BMI", valueType: "BMI", icon: "weight", family: "custom", colour: [COLORS.MATERIAL_ORANGE], friendlyName: "BMI" },
  RESPIRATION: { type: "RESPIRATION", valueType: "RPM", icon: "respiration", family: "custom", colour: [COLORS.MATERIAL_BLUE], friendlyName: "Respiration" },
  BLOOD_OXYGENATION: { type: "BLOOD_OXYGENATION", valueType: "%",  icon: "blood-oxygenation", family: "custom", colour: [COLORS.MATERIAL_GREEN], friendlyName: "Blood Oxygenation" },
  BLOOD_GLUCOSE: { type: "BLOOD_GLUCOSE", valueType: "mmol/L", icon: "blood-glucose", family: "custom", colour: [COLORS.MATERIAL_GREEN], friendlyName: "Blood Glucose" },
  LUNG_CAPACITY: { type: "LUNG_CAPACITY", valueType: "TLC", icon: 'lungs', family: "custom", colour: [COLORS.MATERIAL_GREEN], friendlyName: "Lung Capacity" },
  ACTIVITY: { type: "ACTIVITY", icon: "accessibility_new", valueType: "Steps", family: "material", graphType: "Bar", colour: [COLORS.MATERIAL_GREEN], friendlyName: "Activity" },
  TEMPERATURE: { type: "TEMPERATURE", icon: "thermometer", valueType: "°C", family: "custom", colour: [COLORS.MATERIAL_RED], friendlyName: "Body Temperature" },
}
