export default (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
    flexDirection: 'column',
  },
  MenuHeader: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    width: '100%',
  },
  MenuSpacer: {
    flexGrow: 1,
  },
  UserInfoContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  userImage: {
    height: '64px',
  },
  avatar: {
    height: '64px',
    width: '64px',
    borderRadius: 0,
  },
  UserName: {
    flexGrow: 1,
  },
  userName: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    color: '#fff',
    fontSize: '1.5em',
    lineHeight: '1.5em',
  },
  menu: {
    cursor: 'pointer',
  },
})
