import React, { useState } from 'react'
import Typography from "@material-ui/core/Typography"
import Fab from '@material-ui/core/Fab'
import AddIcon from '@mui/icons-material/Add'
import Tooltip from '@material-ui/core/Tooltip'
import { ZoneItem } from './ZoneItem'
import { NoZones } from './NoZones';
import { AddZoneModal } from './modals/AddZoneModal'

import { v4 as uuid } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../interfaces/reduxState'
import { clearAddZoneStatus, clearRemoveZoneStatus, clearUpdateZoneStatus, createZoneBegin, getGatewayBegin, getZonesBegin, hideSnack } from '../../../../redux/domains/zones/actionCreators'
import { useEffect } from 'react'
import { SnackAlert } from '../../../../routes/Organisations/component/Shared/SnackAlert'
import Skeleton from 'react-loading-skeleton'

export const ZoneConfigurationView = () =>
{

  const dispatch = useDispatch()
  const [ openAddZoneModal, setOpenAddZoneModal ] = useState(false)
  const [ expanded, setExpanded ] = useState<string | false >(false)


  const gatewayId = useSelector(({ householdsV2 }: ApplicationState) => householdsV2.activeGatewayId)

  const isGettingZones = useSelector(({ zones }: ApplicationState) => zones.isGettingZones)
  const zones = useSelector(({ zones }: ApplicationState) => zones.zones)

  const isCreatingZone = useSelector(({ zones }: ApplicationState) => zones.isCreatingZone)
  const createSuccess = useSelector(({ zones }: ApplicationState) => zones.createSuccess)
  const createZoneError = useSelector(({ zones }: ApplicationState) => zones.createZoneError)

  const isUpdatingZone = useSelector(({ zones }: ApplicationState) => zones.isUpdatingZone)
  const updateSuccess = useSelector(({ zones }: ApplicationState) => zones.updateSuccess)
  const updateZoneError = useSelector(({ zones }: ApplicationState) => zones.updateZoneError)

  const isDeletingZone = useSelector(({ zones }: ApplicationState) => zones.isDeletingZone)
  const deleteSuccess = useSelector(({ zones }: ApplicationState) => zones.deleteSuccess)
  const deleteZoneError = useSelector(({ zones }: ApplicationState) => zones.deleteZoneError)

  const snackMessage = useSelector(({ zones }: ApplicationState) => zones.snackMessage)
  const showSnack = useSelector(({ zones }: ApplicationState) => zones.showSnack)
  const snackType = useSelector(({ zones }: ApplicationState) => zones.snackType)

  const gateway = useSelector(({ zones }: ApplicationState) => zones.gateway)
  const householdId = useSelector(({ householdsV2 }: ApplicationState) => householdsV2.activeHouseholdId)


  const addZone = (friendlyName: string) =>
  {
    dispatch(createZoneBegin(householdId, { friendlyName: friendlyName, zoneId: uuid(), rooms: [] }))
    setOpenAddZoneModal(false)
  }

  const getRoomsWithoutZones = () =>
  {
    return gateway && gateway.rooms && gateway.rooms
      .map(room => !room.zoneId && room.roomname)
      .filter(g => g)
      .filter(g => g !== 'Undefined')
      .filter(g => g !== "House") as string[]
  }

  useEffect(() => {
    dispatch(getZonesBegin(householdId))
    dispatch(getGatewayBegin(gatewayId))
  }, [])

  return(
    <div style={styles.container} data-test="container-zone-configuration">

      <Typography variant="h5" align="left" color="textPrimary">Zone Configuration</Typography>
      <Typography variant="subtitle1" color="textSecondary" align="left">Multi resident household settings</Typography>

      <div style={styles.zoneContainer}>
        { isGettingZones && <Skeleton style={styles.skeleton} count={5} /> }
        { !isGettingZones && zones && zones.length === 0 && <NoZones />}
        { !isGettingZones && zones && zones.length > 0 && zones.map((zone, i) => {
          return (
            <ZoneItem
              householdId={householdId}
              zone={zone}
              key={i}
              expanded={expanded === zone.zoneId}
              roomsWithoutZones={getRoomsWithoutZones()}
              onChange={(e: any, expanded: boolean) => setExpanded(expanded ? zone.zoneId : false)}/>)

        })}
      </div>

      <div style={styles.buttonRow}>
        <Tooltip title="Add a new zone to this household">
          <Fab color="secondary" aria-label="add" onClick={() => setOpenAddZoneModal(true)}>
            <AddIcon />
          </Fab>
        </Tooltip>

        {openAddZoneModal &&
       <AddZoneModal
         open={openAddZoneModal}
         close={() => setOpenAddZoneModal(false)}
         title="Add Zone"
         contentText="Add a new zone to this household (e.g. a person's name or common area)"
         inputBoxDescription="Zone Name"
         confirmAction={(e) => addZone(e)}
       /> }

        {/* SNACKING */}
        <SnackAlert open={createSuccess ? true : false} alertMsg={createSuccess} color="success" onClose={() => dispatch(clearAddZoneStatus())}/>
        <SnackAlert isLoading={true} open={isCreatingZone ? true : false} alertMsg={"Creating zone"} color="info" onClose={() => dispatch(clearAddZoneStatus())}/>
        <SnackAlert open={createZoneError ? true : false} alertMsg={createZoneError} color="error" onClose={() => dispatch(clearAddZoneStatus())}/>

        <SnackAlert isLoading={true} open={isUpdatingZone ? true : false} alertMsg={"Updating zone"} color="info" onClose={() => dispatch(clearUpdateZoneStatus())}/>
        <SnackAlert open={updateSuccess ? true : false} alertMsg={updateSuccess} color="success" onClose={() => dispatch(clearUpdateZoneStatus())}/>
        <SnackAlert open={updateZoneError ? true : false} alertMsg={updateZoneError} color="error" onClose={() => dispatch(clearUpdateZoneStatus())}/>

        <SnackAlert isLoading={true} open={isDeletingZone ? true : false} alertMsg={"Deleting zone"} color="info" onClose={() => dispatch(clearRemoveZoneStatus())}/>
        <SnackAlert open={deleteSuccess ? true : false} alertMsg={deleteSuccess} color="success" onClose={() => dispatch(clearRemoveZoneStatus())}/>
        <SnackAlert open={deleteZoneError ? true : false} alertMsg={deleteZoneError} color="error" onClose={() => dispatch(clearRemoveZoneStatus())}/>

        <SnackAlert open={showSnack} alertMsg={snackMessage} color={snackType} onClose={() => dispatch((hideSnack()))}/>


      </div>
    </div>
  )
}

const styles = {
  container: {
    margin: 32,
    width: "100%",
  },
  skeleton: {
    height: 50,
    marginTop: 4,
    marginBottom: 4,
  },
  buttonRow: {
    position: "absolute" as "absolute",
    right: 0,
    bottom: 0,
    margin: 34,
  },
  zoneContainer: {
    marginTop: 12,
    overflow: "auto",
  },
}