import {
  takeEvery,
  put,
} from 'redux-saga/effects'

import { PublicClientApplication } from "@azure/msal-browser";

import { config, authRequest } from '../msal/config'

import { appInsights } from '../api/AppInsights'
// This should be the same instance you pass to MsalProvider
const msalInstance = new PublicClientApplication(config);



// eslint-disable-next-line require-yield
export function* refreshToken(action) {
  try {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      /*
        * User is not signed in. Throw error or wait for user to login.
        * Do not attempt to log a user in outside of the context of MsalProvider
        */
    }

    const response = yield msalInstance.acquireTokenSilent({
      scopes: authRequest.b2cScopes,
      account: accounts[0],
    });


    yield put({ type: 'REFRESH_TOKEN_SUCCESS', details: { ...action.details, access_token: response.idToken } })

    appInsights.trackPageView({ name: "Token Refresh Success" })
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    appInsights.trackPageView({ name: "Token Refresh Failure" })
    yield put({ action: 'REFRESH_TOKEN_ERROR' })
  }
}

function* refreshTokenSaga() {
  yield takeEvery("REFRESH_TOKEN_BEGIN", refreshToken)
}

export default refreshTokenSaga
