import React from 'react'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'

interface CardItemProps
{
  title: string;
  subTitle: string;
  stat: string;
  color: string;
  fontSize: number
}


export const CardItem = ({ title, subTitle, stat, color, fontSize }: CardItemProps) =>
{
  return (<Card style={styles.container}>
    <CardHeader
      titleTypographyProps={{ variant:'h6' }}

      avatar={
        <Avatar style={{ ...styles.statItem, backgroundColor: color }}>
          <Typography style={{ fontSize: fontSize }}>{stat}</Typography>
        </Avatar>
      }

      title={title}
      subheader={subTitle}
    />
  </Card>)
}

const styles = {
  container: {
    padding: 16,
  },
  row: {
  },
  statItem: {
    borderRadius: 50,
    width: 100,
    height: 100,
    textAlign: "center" as "center",
  },
}