import { connect } from 'react-redux'
import ContentStraplineView from '../components/ContentStraplineView'
import sharedStore from '../../../redux'
const logoutStart = sharedStore.userStore.logoutStart
const householdSummaryStatus = sharedStore.householdsSelector.householdSummaryStatus


const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => {
      dispatch(logoutStart())
    },
  }
}

const mapStateToProps = (state) => ({
  status: householdSummaryStatus(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContentStraplineView)
