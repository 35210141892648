import React from 'react'
import { Organisation } from '../../../../api/OrganisationApi'
import Avatar from '@material-ui/core/Avatar'
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { purple } from '@mui/material/colors';
import moment from 'moment'
import { history } from '../../../../hoc/CustomRouter'
import { getInitials } from '../Modals/utils/getInitials';
import { useDispatch } from 'react-redux'
import { viewOrganisationDetails } from '../../../../redux/domains/organisations/actionCreators';

interface OrganisationRowProps
{
  organisation: Organisation;
}

export const OrganisationRow = ( { organisation } : OrganisationRowProps) =>
{
  const dispatch = useDispatch()

  const handleItemClick = (org: Organisation) =>
  {
    history.push(`/organisations/${org.id}`)
    dispatch(viewOrganisationDetails(org))
  }

  return(
    <TableRow
      onClick={() => handleItemClick(organisation)}
      hover
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row" >
        <div style={styles.avatarContainer}>
          <Avatar style={styles.avatar}>{getInitials(organisation.friendlyName)}</Avatar>
          <Typography>{organisation.friendlyName}</Typography>
        </div>
      </TableCell>
      <TableCell align="right">{Object.keys(organisation.access).length}</TableCell>
      <TableCell align="right">{organisation.children.length}</TableCell>
      <TableCell align="right">{`${moment(organisation.lastUpdated).fromNow()}`}</TableCell>
    </TableRow>
  )
}

const styles = {
  container: {

  },
  avatar: {
    backgroundColor: purple[500],
    marginRight: 6,
  },
  avatarContainer: {
    flexDirection: "row" as "row",
    display: "flex" as "flex",
    alignItems: 'center',
  },
}