import { ZoneApiResponseItem } from "@intelicare/shared/interfaces/household/Zone"
import { ZoneState } from "../interfaces";

// Reducer helper functions to parse and update the zone state

export const getZone = (zones: ZoneApiResponseItem[], action: any) =>
{
  return zones.find((zone: ZoneApiResponseItem) => zone.zoneId === action.data.zoneId) as ZoneApiResponseItem;
}

export const updateRoomNameAndSensors = (state: ZoneState, action: any): ZoneApiResponseItem[] =>
{
  return state.zones.map(zone => { // Update the room name and sensors
    zone.rooms = zone.rooms
      .map(room => room.roomid === action.data.room.roomid ?
        { ...room, sensors:
          [ ...room.sensors
            .map(sensor => ({ ...sensor, roomname: action.data.room.roomname, roomtype: action.data.room.roomname }))] ,
        roomname: action.data.room.roomname } : room)
    return zone;
  })
}


export const addNewRoom = (state: ZoneState, action: any) =>
{
  return state.zones.map(zone => { // Add the room onto the room array inside the zone
    if(zone.zoneId === action.data.room.zoneId)
    {
      if(zone && zone.rooms)
      {
        zone.rooms.push(action.data.room)
      }
      else
      {
        zone.rooms = []
        zone.rooms.push(action.data.room)
      }
    }
    return zone
  })
}

export const addSensorToRoomInZone = (state: ZoneState, action: any) =>
{
  return state.zones.map(zone => { // Find the roomid and add the new sensors to it
    zone.rooms = zone.rooms.map(room => {
      if(room.roomid === Number(action.data.roomid))
      {
        room.sensors = room.sensors.concat(action.data.sensors)
      }
      return room
    })

    return zone;
  })
}

export const removeSensorFromRoomInZone = (state: ZoneState, action: any) =>
{
  return state.zones.map(zone => { // Filter out nodeids
    zone.rooms = zone.rooms.map(room => {
      room.sensors = room.sensors.filter(sensor => sensor.nodeId !== Number(action.data.nodeid))
      return room;
    })
    return zone;
  })
}

export const removeRoomFromZoneInState = (state: ZoneState, action: any) =>
{
  return state.zones.map(zone => { // map through each zone and filter out the matching roomid
    zone.rooms = zone.rooms.filter(room => room.roomid !== Number(action.data.roomid))
    return zone
  })
}