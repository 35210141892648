import React from 'react'
import MealView from '../cards/Meal'

interface MealTabProps
{
  behaviours: [];
  activeV2Household: any;
  useNewLook: boolean;
}

export const MealTab = ({ behaviours, activeV2Household, useNewLook }: MealTabProps) =>
{
  return(<div>
    <MealView
      useNewLook={useNewLook}
      id="mealPrep"
      aria-label="mealPrep"
      behaviours={behaviours}
      householdV2={activeV2Household}
    />
  </div>)
}