import React, { Component } from "react";
import PropTypes from "prop-types";
import access from "safe-access";

import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

import Title from "../../../components/Title/Title";
import SensorsState from "../../../components/SensorsState";

import Activity from "./cards/Activity";
import PresenceView from "./cards/PresenceView";
import NightView from "./cards/NightView";
import MealView from "./cards/Meal";
import NotificationsView from "./cards/NotificationsView";
import SystemView from "./cards/SystemView";
import ToolbarButton from "../../../components/ToolbarButton";
import HouseholdSettingsPortal from "../../../components/HouseholdSettingsPortal";
import HealthMetrics from "./cards/HealthMetrics";
import HouseholdNotFound from "./HouseholdNotFound";
import { HouseholdTabView } from './HouseholdTabView'
import styles from "./HouseholdViewStyles";

const PAGE_NAME = "householdView";
class HouseholdView extends Component {
  static friendlyName = "Household View";
  static pageName = PAGE_NAME;

  constructor(props) {
    super(props);

    this.state = {
      showHouseholdSettingsMenu: false,
      anchor: undefined,
      showSettingsPage: "",
      useNewLook: true,
    };

    this.hideHouseholdSettingsMenu = this.hideHouseholdSettingsMenu.bind(this);
    this.showHouseholdSettingsMenu = this.showHouseholdSettingsMenu.bind(this);
    this.openHealthMetricSettings = this.openHealthMetricSettings.bind(this);
    this.openAlexaSettings = this.openAlexaSettings.bind(this);
  }

  hideHouseholdSettingsMenu() {
    this.setState({ showHouseholdSettingsMenu: false });
    this.setState({ showSettingsPage: "" });
  }

  showHouseholdSettingsMenu(toggle = true) {
    this.setState({ showHouseholdSettingsMenu: toggle });
  }

  openHealthMetricSettings() {
    this.setState({ showSettingsPage: "healthMetrics" });
    this.showHouseholdSettingsMenu();
  }
  openAlexaSettings() {
    //alexa
    this.setState({ showSettingsPage: "alexa" });
    this.showHouseholdSettingsMenu();
  }

  componentDidMount() {
    const { match, getHousehold, location, getHouseholdDetails, getSummary, fetchSensors, activeHouseholdId } = this.props;

    const requestedHouseholdId = access(match, "params.householdId");
    getHousehold(requestedHouseholdId);
    getHouseholdDetails(requestedHouseholdId);
    getSummary({ householdId: requestedHouseholdId });
    // // Routing for links/page anchors (prop comes from navigation wrappers)
    if (location.hash) {
      this.setState({ anchor: location.hash });
      setTimeout(() => {
        this.scrollIntoView(location.hash);
      }, 500);
    }
    const hasAlexa = window.location.href.indexOf("redirectFrom=Alexa") > -1;
    const hasCode = window.location.href.indexOf("code=") > -1;
    const hasFitbit = window.location.href.indexOf("redirectFrom=Fitbit") > -1;
    if (hasCode && hasAlexa) {
      // After logging into Alexa, they get redirected back with an access code as a url parameter
      const alexaAccessCode = new URLSearchParams(window.location.search).get("code");
      // Remove access code from url params so this doesn't constantly get triggered
      const url = window.location.href;
      window.history.replaceState(
        {},
        document.timeline,
        "/" + url.split(window.location.search)[1]
      );
      // Call API
      this.props.alexaInitialSync(
        requestedHouseholdId,
        alexaAccessCode,
        window.location.origin
      );

      // Open alexa settings
      this.setState({ openSettings: true });
    } else if (hasCode && hasFitbit) {
      // After logging into Fitbit, they get redirected back with an access code as a url parameter
      const accessCode = new URLSearchParams(window.location.search).get("code");
      // Remove access code from url params so this doesn't constantly get triggered
      const url = window.location.href;
      window.history.replaceState(
        {},
        document.timeline,
        "/" + url.split(window.location.search)[1]
      );
      // Call API
      this.props.fitbitInitialSync(
        requestedHouseholdId,
        accessCode,
        window.location.origin + "/"
      );
      // Open health metrics settings
      this.setState({ openSettings: true });
    }

    fetchSensors(activeHouseholdId)

  }

  componentDidUpdate(lastProps, lastState) {
    const { location, clearSyncFitbitData, activeHouseholdId, getHousehold, getSummary, fitbitSyncResult,
      fitbitSyncError, alexaSyncResult, alexaSyncError } = this.props;
    if (!!location.hash && location.hash !== lastState.anchor) {
      this.setState({ anchor: location.hash });
      setTimeout(() => {
        this.scrollIntoView(location.hash);
      }, 500);
    }

    if (
      lastProps.isSyncingInitialFitbit === true &&
      this.props.isSyncingInitialFitbit === false
    ) {
      if (fitbitSyncResult) {
        clearSyncFitbitData();
        getHousehold(activeHouseholdId);
        getSummary({ householdId: activeHouseholdId });
      } else if (fitbitSyncError) {
        console.log("Error syncing fitbit: " + fitbitSyncError);
      }

      if (this.state.openSettings === true) {
        this.openHealthMetricSettings();
        this.setState({ openSettings: false });
      }
    }

    if (
      lastProps.isSyncingAlexaData === true && this.props.isSyncingAlexaData === false) {
      if (alexaSyncResult) {
        this.refreshAlexaSetting();
        this.props.clearSyncAlexaData();
      } else if (alexaSyncError) {
        console.log("Error syncing alexa: " + alexaSyncError);
      }

      if (this.state.openSettings === true) {
        this.openAlexaSettings();
        this.setState({ openSettings: false });
      }
    }
  }

  scrollIntoView(hash) {
    const id = hash.replace("#", "");
    const element = document.getElementById(id);
    if (element) {
      document.getElementById(id).scrollTop += 100;
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  refreshAlexaSetting() {
    this.props.refreshHousehold(this.props.activeHouseholdId); // Update household redux state
  }

  render() {
    const {
      classes,
      getNotifications,
      notifications,
      isGettingNotificationsForHousehold,
      clearNotificationsForHousehold,
      viewMessage,
      isDeveloper,
      isSupport,
      activeV2Household,
      isFetching,
      messageResolving,
      useNewLook,
      token,
      sensors,
      fetchSensors,
      hasZones,
    } = this.props;

    const { showSettingsPage } = this.state

    // Added summary is fetching fix until the other components are made more resilient
    if (
      isFetching ||
      !activeV2Household ||
      (activeV2Household && !activeV2Household.data) ||
      (activeV2Household && activeV2Household.data.isSummaryFetching)
    ) {
      return <Loading />;
    }

    const householdId = access(activeV2Household, "householdId");
    const timezone = access(activeV2Household, "data.timezone");
    const householdName = access(activeV2Household, "friendlyName");
    const householdAvatar = access(activeV2Household, "avatar");
    const behaviours = access(activeV2Household, "data.behaviours") || [];

    /* If the household doesn't have any behaviours data then bad things will happen when we try
     * to render the graphs so check if we have them first
     */
    if (!activeV2Household.data.behaviours) return <HouseholdNotFound />;

    if(useNewLook) return (
      <HouseholdTabView
        hasZones={hasZones}
        token={token}
        isFetching={isFetching}
        useNewLook={useNewLook}
        isDeveloper={isDeveloper}
        isSupport={isSupport}
        activeV2Household={activeV2Household}
        householdId={householdId}
        householdAvatar={householdAvatar}
        householdName={householdName}
        openHealthMetricSettings={this.openHealthMetricSettings}
        timezone={timezone}
        behaviours={behaviours}
        isGettingNotificationsForHousehold={isGettingNotificationsForHousehold}
        clearNotificationsForHousehold={clearNotificationsForHousehold}
        notifications={notifications}
        getNotifications={getNotifications}
        showSettingsPage={showSettingsPage}
        showHouseholdSettingsMenu={this.state.showHouseholdSettingsMenu}
        updateShowSettingsMenu={(value) => this.setState({ showHouseholdSettingsMenu: value })}
        onSelect={viewMessage}
        sensors={sensors}
        fetchSensors={fetchSensors}
      />)

    return (
      <div className={classes.Household}>
        <Title
          title={householdName}
          iconLeft={householdAvatar}
          pageButtons={
            <ToolbarButton
              action={this.showHouseholdSettingsMenu}
              label="Household Settings"
              icon={<Icon>home</Icon>}
            />
          }
        />
        {this.state.showHouseholdSettingsMenu && (
          <HouseholdSettingsPortal
            close={this.hideHouseholdSettingsMenu}
            showPage={this.state.showSettingsPage}
          />
        )}

        <div className={classes.MainContent}>
          <Activity
            id="activity"
            aria-label="activity"
            isDeveloper={isDeveloper}
            isSupport={isSupport}
            householdId={householdId}
            household={activeV2Household.data}
            householdV2={activeV2Household}
          />

          <HealthMetrics
            id="health"
            aria-label="health"
            householdV2={activeV2Household}
            isDeveloper={isDeveloper}
            isSupport={isSupport}
            openHealthMetricSettings={this.openHealthMetricSettings}
          />

          <NightView
            id="sleep"
            aria-label="sleep"
            timezone={timezone}
            householdV2={activeV2Household}
            isDeveloper={isDeveloper}
          />

          <MealView
            id="mealPrep"
            aria-label="mealPrep"
            behaviours={behaviours}
            householdV2={activeV2Household}
          />

          <PresenceView
            id="presence"
            aria-label="presence"
            behaviours={behaviours}
            householdTz={activeV2Household.timezone}
            householdV2={activeV2Household}
          />

          <SensorsState
            id="sensorState"
            aria-label="sensorState"
            householdId={householdId}
          />

          <NotificationsView
            isGettingNotificationsForHousehold={
              isGettingNotificationsForHousehold
            }
            clearNotificationsForHousehold={clearNotificationsForHousehold}
            showHouseholdName={false}
            id="notifications"
            aria-label="notifications"
            householdId={householdId}
            notifications={notifications}
            getNotifications={getNotifications}
            onSelect={viewMessage}
            messageResolving={messageResolving}
          />

          <SystemView
            isDeveloper={isDeveloper}
            isSupport={isSupport}
            id="system"
            aria-label="systemHealth"
            household={activeV2Household}
            isFetching={isFetching}
          />
        </div>
      </div>
    );
  }
}

HouseholdView.propTypes = {
  classes: PropTypes.object.isRequired,
  deleteNote: PropTypes.func.isRequired,
  createNoteStart: PropTypes.func.isRequired,
  refreshDashboard: PropTypes.func.isRequired,
  requestHouseholdData: PropTypes.func.isRequired,
  activeHousehold: PropTypes.object,
  activeV2Household: PropTypes.object,
  actionsAndNotifications: PropTypes.array,
  alerts: PropTypes.array,
  viewMessage: PropTypes.func,
  match: PropTypes.object,
  notifications: PropTypes.array,
  isDeveloper: PropTypes.bool,
  isSupport: PropTypes.bool,
  isBetaUser: PropTypes.bool,
  features: PropTypes.object,
  featurePreviews: PropTypes.any,
  getHousehold: PropTypes.func,
  getHouseholdFromList: PropTypes.func,
  isFetching: PropTypes.bool,
  location: PropTypes.any,
  getNotifications: PropTypes.func,
  isGettingNotificationsForHousehold: PropTypes.bool,
  clearNotificationsForHousehold: PropTypes.func,
  getHouseholdDetails: PropTypes.func,
  messageResolving: PropTypes.bool,
  isDemoUser: PropTypes.bool,
  alexaInitialSync: PropTypes.func,
  activeHouseholdId: PropTypes.string,
  alexaSyncError: PropTypes.string, // come from household container
  alexaSyncResult: PropTypes.any,
  fitbitSyncError: PropTypes.string,
  fitbitSyncResult: PropTypes.object,
  isSyncingAlexaData: PropTypes.bool,
  clearSyncAlexaData: PropTypes.func,
  refreshHousehold: PropTypes.func,
  useNewLook: PropTypes.bool,
  token: PropTypes.string,
  fitbitInitialSync: PropTypes.func,
  getSummary: PropTypes.func,
  clearSyncFitbitData: PropTypes.func,
  isSyncingInitialFitbit: PropTypes.bool,
  fetchSensors: PropTypes.func,
  sensors: PropTypes.array,
  hasZones: PropTypes.bool,
};


const Loading = withStyles(styles)(({ classes }) => (
  <Grid
    container
    justify="center"
    alignItems="center"
    style={{ minHeight: "100vh" }}
  >
    <Grid item>
      <CircularProgress
        className={classes.LoadingIndicator}
        variant="indeterminate"
        color="secondary"
      />
    </Grid>
  </Grid>
));


export default withStyles(styles)(HouseholdView)