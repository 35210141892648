import React from 'react'
import Typography from '@material-ui/core/Typography'
import PersonSearch from '@mui/icons-material/PersonSearch'

export const NoZones = () =>
{
  return(
    <div style={styles.container} data-test="component-no-zones">
      <PersonSearch style={styles.icon} />
      <Typography data-test="component-no-zones-text" variant="h5" color="textSecondary" align="center">No zones for this household</Typography>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: "column" as "column",
    justifyContent: 'center',
    alignItems: 'center',
    height: "50vh",
  },
  icon: {
    width: 64,
    height: 64,
    color: 'grey',
  },
}