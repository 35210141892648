import React from "react";
import PropTypes from "prop-types";

// Material UI components
import IconButton from "@material-ui/core/IconButton";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from 'react-router-dom'
// Icons
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Linkifier from 'react-linkifier';
import moment from "moment";
import getIcon from './getIcon';

// Parse the alert with some error handling
const getAlertDescription = (recipients, trim) => {
  try {
    if (trim) return `${recipients[0].message.substring(0, 50)}...`;
    return recipients[0].message;
  } catch (error) {
    return "";
  }
};

export const TableItem = ({ row, showHouseholdName, onSelect }) => {
  TableItem.propTypes = {
    row: PropTypes.object,
    showHouseholdName: PropTypes.bool,
    onSelect: PropTypes.func,
  };

  const { householdId, householdFriendlyName, timestamp, type, status, recipients } = row;

  return (
    <TableRow hover>
      {/* Conditionally render the active alert */}
      <TableCell component="th" scope="row" align="center">{getIcon(type, status)}</TableCell>
      {showHouseholdName && (
        <TableCell component="th" scope="row" style={styles.textStyle}>
          <Link to={`/households/${householdId}`}>{householdFriendlyName}</Link>
        </TableCell>
      )}

      <Tooltip title={getAlertDescription(row, false)}>
        <TableCell style={styles.textStyle}>
          <Linkifier target="_blank">{getAlertDescription(recipients, false)}</Linkifier>
        </TableCell>
      </Tooltip>

      <Tooltip title={moment(timestamp).fromNow()}>
        <TableCell style={styles.textStyle}>{moment(timestamp).format("lll")}</TableCell>
      </Tooltip>

      <TableCell align="right">
        {status !== "PENDING" && (
          <IconButton onClick={() => onSelect(row)}><MoreVertIcon /></IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

const styles = {
  textStyle: { fontSize: 15, align: "left" },
  textMenuStyle: { fontSize: 14.5, align: "left", padding: "6px 24px 6px 16px" },
};
