import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as moment from 'moment-timezone'
import access from 'safe-access'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import buildComponent from './BasePageConnector'
import FormButtons from '../FormButtons'
import { MenuItem, Select } from '@material-ui/core'
class SleepSettings extends Component {

  componentDidUpdate(lastProps) {
    const { isSaving, setSubmitting } = this.props
    if (lastProps.isSaving === true && isSaving === false) {
      setSubmitting(false)
    }
  }

  render() {
    const { values, initialValues, classes, setFieldValue, activeHousehold } = this.props
    return (
      <div className={classes.OverlayMain}>
        <div className={classes.OverlaySection}>
          <Grid container justifyContent="center" align="center" direction="column" spacing={24}>
            <Grid container direction="row" item xs={12}>
              <Grid item xs={12} md={3} lg={3}>
                <Typography variant="h6" classes={{ h6: classes.label }}>
                  Normal Sleep Time
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <HourPicker
                  id='sleepingStart'
                  hourUtc={values.sleepingStart}
                  timezone={activeHousehold.timezone}
                  onUpdate={(value) => setFieldValue('sleepingStart', value)}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                {values.sleepingStart !== initialValues.sleepingStart &&
                  <Typography variant="caption" classes={{ caption: classes.modified }}>
                    Modified
                  </Typography>
                }
              </Grid>
            </Grid>
            <Grid container direction="row" item xs={12}>
              <Grid item xs={12} md={3} lg={3}>
                <Typography variant="h6" classes={{ h6: classes.label }}>
                  Normal Wake Time
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <HourPicker
                  id='sleepingFinish'
                  hourUtc={values.sleepingFinish}
                  timezone={activeHousehold.timezone}
                  onUpdate={(value) => setFieldValue('sleepingFinish', value)}
                />
              </Grid>

              <Grid item xs={12} md={3} lg={3}>
                {values.sleepingFinish !== initialValues.sleepingFinish &&
                  <Typography variant="caption" classes={{ caption: classes.modified }}>
                    Modified
                  </Typography>
                }
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={classes.OverlayFooter}>
          <FormButtons {...this.props} />
        </div>
      </div>
    )
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    isSaving: PropTypes.bool.isRequired,
    values: PropTypes.any.isRequired,
    initialValues: PropTypes.any.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    setSubmitting: PropTypes.func.isRequired,
    activeHousehold: PropTypes.object.isRequired,
  }
}

export default buildComponent(SleepSettings, {
  mapPropsToValues: (props) => ({
    sleepingStart: access(props, 'activeHousehold.data.sleepingStart'),
    sleepingFinish: access(props, 'activeHousehold.data.sleepingFinish'),
  }),
  handleSubmit: (values, actions) => {
    actions.props.updateSettings({
      householdId: access(actions, 'props.activeHousehold.householdId'),
      settings: values,
    })

  },
})

class HourPicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hour: "",
      amPm: "",
    }
  }

  convertTo12HrTime() {
    const { hourUtc, timezone } = this.props
    const timeHour = moment.utc().hour(hourUtc).tz(timezone).hour()

    const hour = timeHour > 12 ? timeHour - 12 : timeHour == 0 ? 12 : timeHour
    const amPm = timeHour >= 12 ? 'pm' : 'am'
    this.setState({ hour, amPm })
  }

  componentDidMount() {
    this.convertTo12HrTime()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.hourUtc !== this.props.hourUtc) {
      this.convertTo12HrTime()
    }
  }

  updateParentFieldValue() {
    const { onUpdate, timezone } = this.props
    const { hour, amPm } = this.state
    const hour24 = Number(hour)%12 + (amPm === 'pm')*12;
    const utcHour = moment.tz(timezone).hour(hour24).utc().hour()
    onUpdate(utcHour)
  }

  onHourChange(e) {
    this.setState({ hour: e.target.value }, () => this.updateParentFieldValue())
  }
  onAmPmChange(e) {
    this.setState({ amPm: e.target.value }, () => this.updateParentFieldValue())
  }

  numberedMenuItems() {
    const output = []
    for (let i=1; i<=12; i++) {
      output.push(<MenuItem value={i}>{i}</MenuItem>)
    }
    return output
  }

  render() {
    const { hour, amPm } = this.state
    return (
      <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Select
          style={{ margin: 5 }}
          value={hour}
          variant='outlined'
          onChange={(e) => this.onHourChange(e)}
        >
          {this.numberedMenuItems()}
        </Select>
        <Select
          style={{ margin: 5 }}
          value={amPm}
          variant='outlined'
          onChange={(e) => this.onAmPmChange(e)}
        >
          <MenuItem value='am'>AM</MenuItem>
          <MenuItem value='pm'>PM</MenuItem>
        </Select>
      </Grid>
    )
  }

  static propTypes = {
    hourUtc: PropTypes.number,
    timezone: PropTypes.string,
    onUpdate: PropTypes.func,
  }
}
