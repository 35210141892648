import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import RouterIcon from '@mui/icons-material/Router';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { GatewayApi, ProvisioningGateway } from '../../../../api/GatewayApi';
import { RowItem } from './RowItem';
import Skeleton from 'react-loading-skeleton';
import { Typography } from '@material-ui/core';

interface GatewayListProps
{
  token: string;
}


const GatewayList = ({ token }: GatewayListProps) =>
{
  const [gatewayList, setGatewayList] = useState<ProvisioningGateway[]>([])
  const [isFetching, setIsFetching ] = useState(false)
  const [showSnack, setShowSnack] = useState(false);
  const [snackMessage, setSnackMessage ] = useState("")
  const [alertSeverity, setAlertSeverity ] = useState<"success" | "info" | "warning" | "error">("success")

  useEffect(() => {
    getCurrentProvisioningRequests()
  }, [])

  const getCurrentProvisioningRequests = async () =>
  {
    setIsFetching(true)

    const gwApi = new GatewayApi(token)
    const gateways = await gwApi.getProvisioning();
    if(gateways) setGatewayList(gateways)
    setIsFetching(false)
  }

  const handleDeleteProvisioningRequest = async (id: string) =>
  {
    const gwApi = new GatewayApi(token)
    const response = await gwApi.clearProvisioningRequest(id);
    if(response)
    {
      setShowSnack(true);
      setAlertSeverity("success")
      setSnackMessage("Removed provisioning request OK")
      getCurrentProvisioningRequests()
      return;
    }

    setShowSnack(true);
    setAlertSeverity("error")
    setSnackMessage("Error removing provisioning request")

  }


  return(
    <div style={styles.container}>
      {
        isFetching ? <LoadingComponent /> :
          <TableContainer component={Paper}>
            {gatewayList.length > 0 ? <Table stickyHeader aria-label="gateway list">
              <TableHead>
                <TableRow>
                  <TableCell>Gateway ID</TableCell>
                  <TableCell align="right">Local IP Address</TableCell>
                  <TableCell align="right">Version</TableCell>
                  <TableCell align="right">Checkin time</TableCell>
                  <TableCell align="right">TTL</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gatewayList.map((row) => (
                  <RowItem onDelete={(id: string) => handleDeleteProvisioningRequest(id)} key={row.gatewayid} row={row} />
                ))}
              </TableBody>
            </Table> : <NoData getCurrentProvisioningRequests={getCurrentProvisioningRequests}/> }
          </TableContainer> }

      <Snackbar open={showSnack} onClose={() => setShowSnack(false)}>
        <Alert variant="filled" severity={alertSeverity}>{snackMessage}</Alert>
      </Snackbar>
    </div>

  )
}

const LoadingComponent = () =>
{
  return(
    <Skeleton count={10} height={70} style={styles.loader}/>
  )
}

interface NoDataProps
{
  getCurrentProvisioningRequests: () => void;
}

const NoData = ({ getCurrentProvisioningRequests }: NoDataProps) =>
{
  return(<div style={styles.noData}>
    <RouterIcon color="secondary" fontSize="large" />
    <Typography variant="h6" align='center' color="textSecondary">No Current Gateway Provisioning Requests</Typography>
    <Button onClick={() => getCurrentProvisioningRequests()} style={styles.button} color="secondary" variant="outlined">Refresh</Button>
  </div>)
}

const styles = {
  container: {
    marginTop: 12,
  },
  loader: {
    margin: 6,
  },
  noData: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    display: 'flex' as 'flex',
    padding: 100,
    flexDirection: 'column' as 'column',
  },
  button: {
    marginTop: 12,
  },
}

const mapStateToProps = (state: any) =>
{
  return{
    token: state.user.token,
  }
}

const mapDispatchToProps = () =>
{
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GatewayList)