import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import _ from 'lodash'

import { withTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import * as d3 from 'd3'
import './MultiNightGraphItem.scss'
import { getSleepTimesForZone } from '../../redux/domains/households/householdsSelector'


class MultiNightGraphItem extends Component {

  constructor(props) {
    super(props)
    this.nightGraphRef = React.createRef()
    this.state = {
      width: window.innerWidth - 200,
    }

    this.containerRef = React.createRef()

    // color schemes to be used
    this.colorScale = d3.scaleOrdinal(props.theme.graphColors.setB)
    this.setSensorColors() // Ensure sensor colours are kept consistent
    this.colors = ['rgb(31,120,180)', 'rgb(138, 197, 109)', '#b2cbeb', 'rgb(102, 42, 140)']
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this._resizeDebounce = _.debounce(this.updateWindowDimensions, 150)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this._resizeDebounce)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resizeDebounce)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth - 200 })
  }

  setSensorColors() {
    this.props.household.data.sensors.forEach(s => {
      if (s.type === 'motion') this.colorScale(s.id)
    })
  }

  render() {
    const { household, data, isFetching } = this.props

    // Filter down to sensors for the zone
    const sensorInfo = household && household.data && household.data.sensors.filter(sensor => {
      for(const sensorId of data.sensors)
      {
        if(sensor.id === sensorId) return sensor;
      }
    })

    // helper to render a legend item
    function addIndicator(sensor, color, key) {
      return (
        <div key={key}>
          <span className={'NightGraphLabel'}>
            <Typography>
              <span className={"graphLegendIndicator"} style={{ backgroundColor: color }} />
              &nbsp;
              {sensor.friendlyName}
            </Typography>
          </span>
        </div>
      )
    }

    return (
      <div ref={this.containerRef} className='NightGraph'>
        <div ref={this.nightGraphRef}>
        </div>
        {/* Show the graph */}

        {!isFetching && data && this.renderGraph(sensorInfo)}

        {/* Add the legend */}
        <div style={{ flexDirection: "row", display: "flex" }}>
          {sensorInfo.map((sensor, key) => {
            if (sensor.type === "motion") return addIndicator(sensor, this.colorScale(sensor.id), key)
          })}
        </div>
      </div >
    )
  }

  addEvent(svg, data) {
    svg.append('line')
      .attr('x1', data.x1)
      .attr('y1', data.y1)
      .attr('x2', data.x2)
      .attr('y2', data.y2)
      .attr('stroke', this.colors[3])
      .attr('stroke-width', 2)
  }

  addLabel(svg, data) {
    svg.append('text')
      .attr('x', data.x)
      .attr('y', data.y)
      .attr('text-anchor', 'middle')
      .attr('fill', this.colors[3])
      .text(data.label)
  }

  renderGraph(sensorInfo) {
    const { height, household, data } = this.props
    const { width } = this.state
    const { timezone: tz } = household

    if (!data) return

    const sleepTimes = getSleepTimesForZone(household.data)

    d3.select(this.nightGraphRef.current)
      .select('svg')
      .remove()

    const svg = d3.select(this.nightGraphRef.current)
      .append('svg')
      .attr("viewBox", "0 0 " + width + " " + height)
      .attr('width', '100%')
      .attr('height', height)

    const graphStart = sleepTimes.sleepStart.clone().subtract(2, 'hours')
    const graphEnd = sleepTimes.sleepEnd.clone().add(2, 'hours')
    const columnWidth = (width - 40) / (graphEnd.diff(graphStart, 'hours') * 2)

    function getSensorSum(sensors) {
      return Object.keys(sensors).reduce((p, key) => p + sensors[key], 0)
    }

    // calculate drawing limits
    const max = data.dataResult.reduce((p, c) => {
      const momentpos = moment.unix(c.position).tz(tz)
      c.momentpos = momentpos
      if (momentpos && momentpos.isBefore(graphStart) || momentpos && momentpos.isAfter(graphEnd, 'hour')) return p
      const day = getSensorSum(c.sensors)
      let largest = day > c.average ? day : c.average
      return p > largest ? p : largest
    }, 0)

    //setup y-scale
    const yscale = d3.scaleLinear()
      .domain([0, max])
      .range([0, height - 20])

    //setup x-scale
    const xscale = d3.scaleTime()
      .domain([graphStart, graphEnd])
      .range([20, width - 20])

    // calculate the positions for the sleep/wake indicators
    const lastArise = xscale(sleepTimes.lastArise)
    const lastSleep = xscale(sleepTimes.lastSleep)
    const indicatorStart = 20
    const indicatorLength = height - 20

    // convert data into x, y, width, height and other info for each rect
    const rects = data.dataResult.map(hour => {
      const position = moment.unix(hour.position)
      if (position.isBefore(graphStart) || position.isAfter(graphEnd.clone().subtract(1, 'hour'))) return []
      let ystart = height - 20
      return Object.keys(hour.sensors).map(sensorId => {
        ystart -= yscale(hour.sensors[sensorId])
        const result = {
          x: xscale(position) + 3,
          y: ystart,
          width: columnWidth - 6,
          height: yscale(hour.sensors[sensorId]),
          value: hour.sensors[sensorId],
          key: sensorId,
          hour: hour.hour,
          friendlyName: sensorInfo[sensorId] ? sensorInfo[sensorId].name : '',
          color: this.colorScale(sensorId),
        }
        return result
      })
    }).reduce((p, c) => p.concat(c), [])

    const night = {
      x: xscale(sleepTimes.sleepStart),
      y: yscale(0),
      width: xscale(sleepTimes.sleepEnd) - xscale(sleepTimes.sleepStart),
      height: yscale(max),
    }

    svg.append('rect')
      .attr('x', night.x)
      .attr('y', night.y)
      .attr('width', night.width)
      .attr('height', night.height)
      .attr('rx', 3)
      .attr('ry', 3)
      .attr('fill', this.colors[2])

    svg.append('g')
      .selectAll('rect')
      .data(rects)
      .enter()
      .append('rect')
      .attr('x', d => d.x)
      .attr('y', d => d.y)
      .attr('width', d => d.width)
      .attr('height', d => d.height)
      .attr('fill', d => this.colorScale(d.key))
      .attr('rx', 3)
      .attr('ry', 3)
      .append('title')
      .text(d => `${d.friendlyName ? d.friendlyName + ': ' : ''} ${d.value}`)

    const path = d3.line()
      .curve(d3.curveMonotoneX)
      .x(d => xscale(moment.unix(d.position)))
      .y(d => height - 20 - yscale(d.average))

    svg.append('path')
      .attr('d', path(data.dataResult))
      .attr('stroke', '#939598')
      .attr('stroke-width', 2)
      .attr('fill', 'none')

    this.addEvent(svg, { x1: lastSleep, y1: indicatorStart + 15, x2: lastSleep, y2: indicatorLength })
    this.addLabel(svg, { x: lastSleep, y: indicatorStart + 10, label: 'Last Sleep' })

    this.addEvent(svg, { x1: lastArise, y1: indicatorStart + 15, x2: lastArise, y2: indicatorLength, key: 'last_arise' })
    this.addLabel(svg, { x: lastArise, y: indicatorStart + 10, label: 'Last Arise', key: 'last_arise' })

    let axis = d3.axisBottom()
      .scale(xscale)
      .tickFormat(a => {
        return moment(a).tz(tz).format('ha')
      })

    svg.append("g")
      .attr("transform", `translate(0, ${height - 20})`)
      .call(axis)
  }
}

MultiNightGraphItem.propTypes = {
  household: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  theme: PropTypes.object,
  getSleepTimes: PropTypes.func,
  data: PropTypes.array,
  isFetching: PropTypes.bool,
}

export default withTheme(MultiNightGraphItem)
