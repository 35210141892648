import React, { Component } from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { SensorApi } from '../../../../api/SensorApi';

const SNACK_AUTOHIDE_DURATION  = 5000;
interface SensorCommandsModalProps
{
  gatewayId: string;
  open: boolean;
  onClose: () => void;
  token: string;
}

interface WakeUpResult
{
  status: number;
  payload: {
    response_reason: string;
  }
}

export class SensorCommandsModal extends Component<SensorCommandsModalProps>
{
  state = {
    isSendingWakeUp: false,
    isSendingCustomCommand: false,
    wakeUpInterval: 3600,
    nodeId: 0,
    parameter: "",
    value: 0,
    sendCustomCommandResult: "",
    sendWakeupCommandResult: [],
    showWakeUpSnack: false,
  }

  customCommands = [
    { label: "Disable LED when PIR is triggered", parameter: "81" },
    { label: "Group 1 Reports", parameter: "101", value: "241" },
    { label: "Battery Check Interval", parameter: "111", value: "3600" },
    { label: "Wattage change report", parameter: "90", value: "True" },
    { label: "Wattage change report threshhold", parameter: "91", value: "10" },
    { label: "Put the gateway into add mode", parameter: "zwave.add_node", value: "" },
    { label: "Put the gateway into remove mode", parameter: "zwave.remove_node", value: "" },
  ]

  sendWakeUp = async () =>
  {
    const { token, gatewayId } = this.props;
    const { wakeUpInterval } = this.state;

    if(wakeUpInterval)
    {
      const sensorApi = new SensorApi(token);
      this.setState({ isSendingWakeUp: true })
      const result = await sensorApi.setWakeup(gatewayId, wakeUpInterval);
      this.setState({ sendWakeupCommandResult: result, isSendingWakeUp: false, showWakeUpSnack: true })
    }
  };

  sendCustomCommand = async () =>
  {
    const { token, gatewayId } = this.props;
    const { nodeId, parameter, value } = this.state;

    // Add node and remove node mode
    if(parameter === "zwave.add_node")
    {
      const sensorApi = new SensorApi(token);
      this.setState({ isSendingCustomCommand: true })
      const result = await sensorApi.addNode(gatewayId);
      this.setState({ sendCustomCommandResult: result, isSendingCustomCommand: false })
      return
    }

    // Add node and remove node mode
    if(parameter === "zwave.remove_node")
    {
      const sensorApi = new SensorApi(token);
      this.setState({ isSendingCustomCommand: true })
      const result = await sensorApi.removeNode(gatewayId);
      this.setState({ sendCustomCommandResult: result, isSendingCustomCommand: false })
      return
    }

    // Set custom parameter
    if(nodeId && parameter && value)
    {
      const sensorApi = new SensorApi(token);
      this.setState({ isSendingCustomCommand: true })
      const result = await sensorApi.setParameter(gatewayId, nodeId, parameter, value);
      this.setState({ sendCustomCommandResult: result, isSendingCustomCommand: false })
      return
    }


  }

  getFormattedResultMsg = (sendWakeupCommandResult: WakeUpResult[]): number =>
  {
    let successCount = 0;
    for(const resultItem of sendWakeupCommandResult)
    {
      if(resultItem && resultItem.status === 200) successCount++
    }

    return successCount
  }

  handleChangePrefinedCommand = (e: any) =>
  {
    this.setState({ parameter: e.target.value })

    if(e.target.value === "101") this.setState({ value: "241" })
    if(e.target.value === "111") this.setState({ value: "3600" })
    if(e.target.value === "81") this.setState({ value: "Disable LED blinking only when the PIR is triggered" })
    if(e.target.value === "90") this.setState({ value: "True" })
    if(e.target.value === "91") this.setState({ value: "10" })

  }

  render()
  {
    const { open, onClose, gatewayId } = this.props;
    const { wakeUpInterval, isSendingWakeUp, isSendingCustomCommand, nodeId, parameter, value, sendWakeupCommandResult, sendCustomCommandResult, showWakeUpSnack } = this.state

    return(
      <Dialog
        onExit={
          () => this.setState({
            isSendingCustomCommand: false,
            isSendingWakeUp: false,
            wakeUpInterval: 3600,
            nodeId: 0,
            parameter: 0,
            value: 0,
            sendCustomCommandResult: "",
            sendWakeupCommandResult: [],
            showWakeUpSnack: false,
          })}
        open={open}
        onClose={onClose}>
        <DialogTitle>Sensor Commands - {gatewayId} </DialogTitle>
        <DialogContent>

          <Grid container direction="column">

            <Typography style={styles.itemMargin} color="textSecondary">Configure all sensors wake up</Typography>
            <TextField onChange={(e) => this.setState({ wakeUpInterval: e.target.value })} value={wakeUpInterval} style={styles.itemMargin} fullWidth placeholder={"E.G 3600"} label={"Wake up interval"} variant="outlined" inputMode="numeric"></TextField>
            <Button onClick={this.sendWakeUp} style={styles.itemMargin} color="secondary" variant="contained">{isSendingWakeUp ? "Sending..." : "Send"}</Button>

            <Typography style={styles.itemMargin} color="textSecondary">Send a predefined command</Typography>
            <Select onChange={this.handleChangePrefinedCommand} value={parameter} style={styles.itemMargin}  variant="outlined">
              {this.customCommands.map(item => {
                return <MenuItem value={item.parameter}>{item.label}</MenuItem>
              })}
            </Select>

            <Typography style={styles.itemMargin} color="textSecondary">Send a custom command</Typography>

            {/* Hide the custom parameters if it's add or remove node mode */}
            {
              parameter !== "zwave.add_node" && parameter !== "zwave.remove_node" &&
            <>
              <TextField onChange={(e) => this.setState({ nodeId: e.target.value })} value={nodeId} style={styles.itemMargin} fullWidth placeholder={"NodeId"} label={"NodeId"} variant="outlined" inputMode="numeric" />

              {/* Parameter */}
              <TextField onChange={(e) => this.setState({ parameter: e.target.value })} value={parameter} style={styles.itemMargin} placeholder={"Parameter"} label={"Parameter"} variant="outlined" />

              {/* Value */}
              <TextField onChange={(e) => this.setState({ value: e.target.value })} value={value} style={styles.itemMargin}  placeholder={"Value"} label={"Value"} variant="outlined" />
            </>
            }

            <Button onClick={this.sendCustomCommand} style={styles.itemMargin} color="secondary" variant="contained">{isSendingCustomCommand ? "Sending..." : "Send"}</Button>

          </Grid>


        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>

        <Snackbar onClose={() =>{}} autoHideDuration={SNACK_AUTOHIDE_DURATION} open={showWakeUpSnack}>
          <Alert variant="filled" severity={this.getFormattedResultMsg(sendWakeupCommandResult) > 0 ? "success" : "error"}>{`Set wake up of ${wakeUpInterval} to ${this.getFormattedResultMsg(sendWakeupCommandResult)} sensors`}</Alert>
        </Snackbar>

        <Snackbar onClose={() =>{}} autoHideDuration={SNACK_AUTOHIDE_DURATION} open={sendCustomCommandResult ? true : false}>
          <Alert variant="filled" severity="info">{sendCustomCommandResult && "Sent custom parameter command OK"}</Alert>
        </Snackbar>
      </Dialog>
    )
  }
}

const styles = {
  itemMargin: {
    margin: 6,
  },
  rowItem: {
    flexDirection: "row" as "row",
  },
}