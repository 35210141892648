import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import ActivityIcon from '@mui/icons-material/DirectionsWalk'
import Avatar from '@material-ui/core/Avatar'

import { callAPI } from '../../../../../redux/domains/user/userStore'
import { HOUSEHOLD_V2_SENSORS_STATE } from '../../../../../redux/shared/services/APIEndpoints'
import { getSensorBarDataV2 } from '../../../../../redux/domains/householdsV2/householdsSelector'
import { getLastMotionEvent } from '../../cards/Activity/util'
interface LastMotionEventProps
{
  sensors: [],
  householdId: string,
  getSensorsState: (householdId: string) => void
}

interface LastLocation
{
  friendlyName: string;
  friendlyTime: string;
}

const LastMotionEvent = ({ sensors, householdId, getSensorsState }: LastMotionEventProps) =>
{
  useEffect(() => {
    // Getting last motion event
    getSensorsState(householdId)
  }, [])

  // Parse the sensor data
  const lastLocation = getLastMotionEvent(sensors)

  return(
    <Card style={styles.container}>
      <CardHeader
        avatar={<Avatar><ActivityIcon /></Avatar>}
        title={`Last Motion Event: ${lastLocation && lastLocation.friendlyName}`}
        subheader={lastLocation && lastLocation.friendlyTime}>
      </CardHeader>
    </Card>
  )
}

const styles = {
  container: {
    marginBottom: 8,
  },
  iconColor: {

  },
}

const mapStateToProps = (state: any) =>
{
  return {
    sensors: getSensorBarDataV2(state as never),
  }
}

const mapDispatchToProps = (dispatch: any) =>
{
  return {
    getSensorsState: (householdId: string) => dispatch(callAPI(HOUSEHOLD_V2_SENSORS_STATE({ householdId }))),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LastMotionEvent)