import { Configuration } from "@azure/msal-browser";

export const authRequest = {
  clientID: process.env.REACT_APP_ADAL_CLIENT,
  authority: `https://${process.env.REACT_APP_AUTHORITY_DOMAIN}/${process.env.REACT_APP_ENVIRONMENT}intelicare.onmicrosoft.com/B2C_1A_Signup_Signin`,
  b2cScopes: ["offline_access", "openid", "profile", `https://${process.env.REACT_APP_ENVIRONMENT}.intelicare.io/${process.env.REACT_APP_ADAL_CLIENT}/access`],
};

const redirectUrl = window.location.origin;

export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1A_Signup_Signin",
    forgotPassword: "B2C_1_password_reset",
    editProfile: "B2C_1_edit_profile",
  },
  authorities: {
    signUpSignIn: {
      authority:
        `https://${process.env.REACT_APP_AUTHORITY_DOMAIN}/${process.env.REACT_APP_ENVIRONMENT}intelicare.onmicrosoft.com/B2C_1A_Signup_Signin`,
    },
    forgotPassword: {
      authority:
        `https://${process.env.REACT_APP_ENVIRONMENT}intelicare.b2clogin.com/${process.env.REACT_APP_ENVIRONMENT}intelicare.onmicrosoft.com/oauth2/v2.0/authorize?p=${process.env.REACT_APP_ADAL_PASSWORD_RESET_POLICY}&client_id=${process.env.REACT_APP_ADAL_CLIENT}&nonce=defaultNonce&redirect_uri=${redirectUrl}&scope=openid&response_type=code&prompt=login&code_challenge=some`,
    },
    editProfile: {
      authority:
        `https://${process.env.REACT_APP_AUTHORITY_DOMAIN}/${process.env.REACT_APP_ENVIRONMENT}intelicare.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_edit_profile&client_id=${authRequest.clientID}&nonce=defaultNonce&redirect_uri=${redirectUrl}&scope=openid%20https%3A%2F%2F${process.env.REACT_APP_ENVIRONMENT}.intelicare.io%2F0cb4e31a-f427-4cf1-8fbf-8565d6f49ae4%2Faccess&response_type=code&prompt=login&code_challenge=some`,
    },
    logout: {
      authority: `https://${process.env.REACT_APP_AUTHORITY_DOMAIN}/${process.env.REACT_APP_ENVIRONMENT}intelicare.onmicrosoft.com/oauth2/v2.0/logout?p=B2C_1A_Signup_Signin&client_id=${authRequest.clientID}&nonce=defaultNonce&redirect_uri=${redirectUrl}&scope=openid&response_type=code&prompt=login&code_challenge=some`,
    },
    login: {
      authority: `https://${process.env.REACT_APP_AUTHORITY_DOMAIN}/${process.env.REACT_APP_ENVIRONMENT}intelicare.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_Signup_Signin&client_id=${authRequest.clientID}&nonce=defaultNonce&redirect_uri=${redirectUrl}&scope=${authRequest.clientID}&offline_access&response_type=code&prompt=login&code_challenge=some`,
    },
  },
  authorityDomain: process.env.REACT_APP_AUTHORITY_DOMAIN,
};

export const config: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_ADAL_CLIENT || "",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain || ""],
    redirectUri: redirectUrl,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

