import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SensorThresholdsRowItems from './Helpers/SensorThresholdsRowItems'
import {
  ThresholdSetting,
  ClimateBehaviour,
  ClimateSensorList,
  categorizedByRoomname,
  assignFakeThreholdSettingsToBehaviour,
} from './Helpers/utils'

import { useSelector } from 'react-redux';
import { ApplicationState } from "../../interfaces/reduxState";
import { getClimateSensorList, getClimateSensorListByZone } from "../../redux/domains/householdsV2/helpers";
import { ZoneApi } from "../../api/ZoneApi";
import { ZoneApiResponseItem } from "@intelicare/shared/interfaces/household/Zone";

type ConfigureClimateAlertModalProps = {
  open: boolean,
  handleClose: () => void,
  onUpdate: (behaviour: ClimateBehaviour) => void,
  behaviour: ClimateBehaviour;
  zoneId: string;
  zonesAndRooms: ZoneApiResponseItem[];
}

export const ConfigureClimateAlertModal = (props: ConfigureClimateAlertModalProps) => {

  let climateSensorList: ClimateSensorList[] = [];
  const { open, handleClose, behaviour, onUpdate, zoneId, zonesAndRooms } = props

  const householdId = useSelector(({ householdsV2 }: ApplicationState) => householdsV2.activeHouseholdId)

  // If MULTI RESIDENT
  if(zoneId) climateSensorList = getClimateSensorListByZone('temperature', zoneId, zonesAndRooms)
  // If SINGLE USER
  if(!zoneId) climateSensorList = useSelector((state: ApplicationState) => getClimateSensorList(state, householdId, 'temperature')) as ClimateSensorList[]

  const roomName = categorizedByRoomname(climateSensorList);

  assignFakeThreholdSettingsToBehaviour(behaviour, roomName);
  /*
    Get the temperature data of all rooms from household doc, and pass as an object
    Iterate each room to get its temperature value and set state
  */
  const [climateThresholds, setClimateThresholds] = useState(JSON.parse(JSON.stringify(behaviour.thresholdSetting)))
  const [warnClimateUnits, setClimateUnits] = useState(behaviour.temperatureUnits)
  const [errMsg, setErrMsg] = useState("")


  const resetFields = () => {
    setErrMsg("")
  }

  const hasBeenModified = () => {
    return JSON.stringify(behaviour.thresholdSetting) !== JSON.stringify(climateThresholds) || behaviour.temperatureUnits !== warnClimateUnits;
  }

  const validateInputs = () => {

    for (const t in climateThresholds) {
      if (!climateThresholds[t].lowerClimateLimit || !climateThresholds[t].upperClimateLimit) {
        setErrMsg("* Field is empty")
        return false
      }

      if (Number(climateThresholds[t].roomEnabled) === 1) {
        if (warnClimateUnits == "°C") {
          if (Number(climateThresholds[t].lowerClimateLimit < 0) || Number(climateThresholds[t].upperClimateLimit) > 35) {
            setErrMsg("* Thresholds should within 0 °C ~ 35 °C")
            return false
          }
        }

        if (warnClimateUnits == "°F") {
          if (Number(climateThresholds[t].lowerClimateLimit) < 32 || Number(climateThresholds[t].upperClimateLimit) > 95) {
            setErrMsg("* Thresholds should within 32 °F ~ 95 °F")
            return false
          }
        }
      }

      if (isNaN(Number(climateThresholds[t].lowerClimateLimit)) || isNaN(Number(climateThresholds[t].upperClimateLimit))) {
        setErrMsg("* Field must be number")
        return false
      }
      if (Number(climateThresholds[t].lowerClimateLimit) > Number(climateThresholds[t].upperClimateLimit)) {
        setErrMsg("* Upper temperature must be higher than lower temperature")
        return false
      }

      if (Number(climateThresholds[t].lowerClimateLimit) == Number(climateThresholds[t].upperClimateLimit)) {
        setErrMsg("* Upper temperature should not equal to lower temperature")
        return false
      }

    }
    setErrMsg("")
    return true
  }

  const onSave = () => {
    if (validateInputs()) onUpdate({ ...behaviour, thresholdSetting: climateThresholds, temperatureUnits: warnClimateUnits })
  }

  function convertToCelsius(value: number) {
    if (!value && value !== 0) {
      return value
    }
    return Math.round(((value - 32) / 1.8) * 100) / 100 // Round to 2 decimal places
  }

  function convertToFahrenheit(value: number) {
    if (!value && value !== 0) {
      return value
    }
    return Math.round((value * 1.8 + 32) * 10) / 10 // Round to 1 decimal place
  }

  function convertTemperature(thresholds: ThresholdSetting, newUnit: string): ThresholdSetting {
    const convertedSetting: ThresholdSetting = JSON.parse(JSON.stringify(thresholds));
    for (const room of Object.keys(convertedSetting)) {
      if (newUnit === "°F") {
        const lower: number = Number(convertedSetting[room].lowerClimateLimit);
        convertedSetting[room].lowerClimateLimit = lower ? Number(convertToFahrenheit(lower)) : lower;

        const upper: number = Number(convertedSetting[room].upperClimateLimit);
        convertedSetting[room].upperClimateLimit = upper ? Number(convertToFahrenheit(upper)) : upper;
      } else if (newUnit === "°C") {
        const lower: number = Number(convertedSetting[room].lowerClimateLimit);
        convertedSetting[room].lowerClimateLimit = lower ? Number(convertToCelsius(lower)) : lower;

        const upper: number = Number(convertedSetting[room].upperClimateLimit);
        convertedSetting[room].upperClimateLimit = upper ? Number(convertToCelsius(upper)) : upper;
      }
    }
    return convertedSetting
  }

  const handleUnitChange = (newUnit: string) => {
    //update value when unit change
    setClimateUnits(newUnit)
    // convert numbers
    setClimateThresholds(convertTemperature(climateThresholds, newUnit))
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onEnter={() => resetFields()}
      open={open}
      onClose={handleClose}
      aria-labelledby="configure-climate-alert-dialog-title"
      aria-describedby="configure-climate-alert-dialog-description"
    >
      <DialogTitle>
        <NotificationsIcon
          style={{
            position: "relative",
            top: 5,
            right: 5,
          }}
        />
        {"Configure Climate Alerting"}
      </DialogTitle>

      <DialogContent>
        {/* @ts-ignore */}
        <Grid style={styles.container} container spacing={2} >
          <Grid container item style={{ alignItems: 'center', flexDirection: 'column', flex: 1 }} >
            <DialogContentText variant='h5' align='center'  >
              Select Temperature Unit
            </DialogContentText>
            <FormControl variant="outlined">
              <InputLabel id="units-label">Units</InputLabel>
              <Select
                labelId="units-label"
                id="units-id"
                value={warnClimateUnits} //Need to create a variable in advance to store your final Climate units
                onChange={(e) => handleUnitChange(String(e.target.value))} //If event occurs, value pass to setClimateUnits()
                label="Units"
              >
                <MenuItem value={'°C'}>Celsius scale °C</MenuItem>
                <MenuItem value={'°F'}>Fahrenheit scale °F</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item style={{ flex: 2 }}>
            <DialogContentText variant='h5' align='center' >
              Select Temperature Thresholds
            </DialogContentText>
            {Array.isArray(roomName) && roomName.length === 0 && <DialogContentText align='center'>This household does not have a climate sensor yet , add one now to experience this great feature.</DialogContentText>}
            {Object.keys(climateThresholds).map((arrValue, arrIndex) =>
              <SensorThresholdsRowItems
                key={arrIndex}
                warnClimateUnits={warnClimateUnits}
                roomName={arrValue}
                //get value in household doc or fake settings
                defaultWarnLowerLimit={climateThresholds[arrValue].lowerClimateLimit}
                defaultWarnUpperLimit={climateThresholds[arrValue].upperClimateLimit}
                defaultRoomEnabled={climateThresholds[arrValue].roomEnabled}
                //Set state value
                setDefaultLowerWarnLimit={(newValue: string) => {
                  climateThresholds[arrValue].lowerClimateLimit = Number(newValue);
                  setClimateThresholds({ ...climateThresholds });
                }}
                setDefaultUpperWarnLimit={(newValue: string) => {
                  climateThresholds[arrValue].upperClimateLimit = Number(newValue);
                  setClimateThresholds({ ...climateThresholds });
                }}
                setRoomEnabled={(newValue) => {
                  climateThresholds[arrValue].roomEnabled = newValue;
                  setClimateThresholds({ ...climateThresholds });
                }
                }
              />
            )}
          </Grid>
        </Grid>

        {errMsg && <Alert severity="error">{errMsg}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button disabled={!hasBeenModified()} onClick={resetFields} color="primary">
          Reset
        </Button>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button disabled={!hasBeenModified()} onClick={onSave} color="primary" autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = {
  container: {
    padding: 12,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
};
