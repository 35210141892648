import React, { Component } from 'react'
import PropTypes from 'prop-types'
import access from 'safe-access'
import moment from 'moment'
import {
  Grid,
  CircularProgress,
  Button,
} from '@material-ui/core'

import {
  LineChart,
  CartesianGrid,
  Legend,
  Line,
  XAxis,
  YAxis,
} from 'recharts'

export default class BedSensorView extends Component {

  constructor(props) {
    super(props)

    this.updateData = this.updateData.bind(this)
  }

  componentDidMount() {
    const { fetchBedSensorData, householdV2, sensorInfo } = this.props
    const householdId = access(householdV2, 'householdId')
    const sensorId = access(sensorInfo, 'id')
    if (householdId) fetchBedSensorData(householdId, sensorId)
  }

  updateData() {
    const { fetchBedSensorData, householdV2, sensorInfo } = this.props
    const householdId = access(householdV2, 'householdId')
    const sensorId = access(sensorInfo, 'id')
    const bedSensorGraphData = access(householdV2, `graphData.bedSensorGraph.${sensorInfo.id}.data`)
    const lastTimeStamp = bedSensorGraphData[bedSensorGraphData.length - 1].timestamp
    fetchBedSensorData(householdId, sensorId, lastTimeStamp, true)
  }

  getData(timePeriod) {
    const { fetchBedSensorData, householdV2, sensorInfo } = this.props
    const householdId = access(householdV2, 'householdId')
    const sensorId = access(sensorInfo, 'id')
    const lastTimeStamp = moment().subtract(timePeriod, 'minutes').toISOString()
    fetchBedSensorData(householdId, sensorId, lastTimeStamp)
  }

  render() {
    const {
      householdV2,
      sensorInfo,
    } = this.props

    const bedSensorGraphData = access(householdV2, `graphData.bedSensorGraph.${sensorInfo.id}.data`)
    const bedSensorFetching = access(householdV2, `graphData.bedSensorGraph.${sensorInfo.id}.fetching`)

    return (
      <Grid container direction='row' justify='center'>
        {
          bedSensorFetching &&
          <Grid item>
            <CircularProgress />
          </Grid>
        }
        {!bedSensorFetching && bedSensorGraphData &&
          <Grid container direction='row'>
            <Grid>
              <Button onClick={this.updateData}>Update</Button>
              <Button onClick={() => this.getData(30)}>30m</Button>
              <Button onClick={() => this.getData(60)}>1h</Button>
              <Button onClick={() => this.getData(360)}>6h</Button>
              <Button onClick={() => this.getData(1440)}>24h</Button>
            </Grid>
            <LineChart
              width={1200}
              height={400}
              data={bedSensorGraphData}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey='timestamp' scale='time' type='number' domain={['auto', 'auto']}
                tickFormatter={(unixTime) => moment(unixTime).format('h:mma')} />
              <YAxis />
              <YAxis yAxisId='right' orientation='right' domain={[0, 1]} />
              <Legend />
              <Line type="linear" dataKey="heartRateVariability" dot={false} stroke='lightblue' />
              <Line type="linear" dataKey="heartRate" dot={false} />
              <Line type="linear" dataKey="relativeStrokeVolume" dot={false} stroke='green' />
              <Line type="linear" dataKey="respirationRate" dot={false} stroke='orange' />
              <Line yAxisId='right' type="linear" dataKey="confidence" dot={false} stroke='red' />
              <Line yAxisId='right' type="linear" dataKey="occupied" dot={false} stroke='purple' />
            </LineChart>
          </Grid>
        }
      </Grid >
    )
  }

  static propTypes = {
    householdV2: PropTypes.object,
    sensorInfo: PropTypes.object,
    fetchBedSensorData: PropTypes.func,
  }
}
