import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import styles from './PortalOverlayStyles'

class PortalOverlay extends Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.any,
    style: PropTypes.any,
    automation_attribute: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.overlayContainer = document.createElement('div')
    this.overlayContainer.className = this.props.classes.OverlayBackground
  }

  componentDidMount()
  {
    document.body.appendChild(this.overlayContainer)
  }

  componentWillUnmount() {
    document.body.removeChild(this.overlayContainer)
  }

  render() {
    const { classes } = this.props

    return (
      <div id='portalcontainer'>
        {ReactDOM.createPortal(
          <div automation_attribute={this.props.automation_attribute} className={classes.Overlay} style={this.props.style}
            onClick={e => !!e && e.stopPropagation()}>
            {this.props.children}
          </div>,
          this.overlayContainer
        )}
      </div>
    )
  }
}

export default withStyles(styles)(PortalOverlay)

