import React from "react"
import { Route, Redirect, Switch } from "react-router-dom"
import Admin from './Admin'
import Household from './Household'
import HouseholdList from './HouseholdList'
import Notifications from './Notifications'
import Resellers from './Resellers'
import Reports from './Reports'
import SystemHealth from './SystemHealth'
import DeveloperConsole from './DeveloperConsole'
import RealtimeStatus from './RealtimeStatus'
import Organisations from './Organisations'
import OrganisationDetails from './Organisations/component/OrganisationDetails'

import { TransitionGroup, CSSTransition } from "react-transition-group"

const routes = [
  HouseholdList,
  Household,
  Notifications,
  Reports,
  SystemHealth,
  DeveloperConsole,
  Resellers,
  Admin,
  RealtimeStatus,
  Organisations,
  OrganisationDetails,
  {
    path: '/',
    exact: false,
    component: () => <Redirect to='/households' />, // Defaults to this if no matches
  },
]

// wrap <Route> and use this everywhere instead, then when
// sub routes are added to any route it'll work
function RouteWithSubRoutes(route) {
  let jsxArray = []
  if (route.routes && route.routes instanceof Array) {
    jsxArray = route.routes.map((subroute, i) => (
      <RouteWithSubRoutes key={route.depthKey * (10 ^ route.depth) + i + 1} {...subroute} depth={route.depth + 1} depthKey={i + 1} />
    )).reduce((acc, val) => acc.concat(val), [])
  }
  jsxArray.push((
    <Route
      path={route.path}
      exact={route.exact === false ? false : true} // Default to exact unless specified explicitly to false
      key={route.depthKey * (10 ^ route.depth)}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  ))
  return jsxArray
}

function Routes() {
  return (
    <Route render={({ location }) => (
      <TransitionGroup component={null}>
        <CSSTransition
          key={location.pathname}
          exit={false}
          classNames="fade"
          timeout={{ enter: 300, exit: 0 }}>
          <Switch location={location}>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} depth={1} depthKey={i + 1} />
            )).reduce((p, c) => p.concat(c), [])}
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    )} />
  )
}

export default Routes