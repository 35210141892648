import { ISensorConfiguration } from "../model/SensorConfiguration";
import { Builder } from '../api/ApiBuilder';

export interface AddSensorResponse
{
  id?: string;
  error?: string;
}

interface DeleteSensorResponse
{
  result: string;
}

export interface ErrorResponse
{
  error: string;
}

export class SensorApi
{
  builder: Builder;
  token: string;

  constructor(token: string)
  {
    this.builder = new Builder(true);
    this.token = token;
  }

  addSensor = async (sensorConfig: ISensorConfiguration): Promise<AddSensorResponse | undefined> =>
  {
    const response = await this.builder.call("POST", `/api/system/management?addSensor=true`, sensorConfig, this.token);
    if(response) return response.data;
  }

  updateSensor = async (sensorConfig: ISensorConfiguration): Promise<ISensorConfiguration | any | undefined> =>
  {
    const response = await this.builder.call("PATCH", `/api/system/management?updateSensor=true`, sensorConfig, this.token);
    if(response) return response.data;
  }

  getSensors = async (): Promise<ISensorConfiguration[] | undefined>  =>
  {
    const response = await this.builder.call("GET", `/api/system/management?getSensors=true`, null, this.token);
    if(response) return response.data;
  }

  deleteSensor = async (sensorConfigId: string): Promise<DeleteSensorResponse | undefined> =>
  {
    const response = await this.builder.call("DELETE", `/api/system/management?deleteSensor=true&id=${sensorConfigId}`, null, this.token);
    if(response) return response.data;
  }

  setWakeup = async (gatewayId: string, interval: number) =>
  {
    const response = await this.builder.call("POST", `/api/system/management?setWakeup=true&gatewayId=${gatewayId}`, { interval }, this.token);
    if(response) return response.data;
  }

  setParameter = async (gatewayId: string, nodeId: number, parameter: string | number, value: string | number) =>
  {
    const response = await this.builder.call("POST", `/api/system/management?setParameter=true&gatewayId=${gatewayId}`, { nodeId, parameter, value }, this.token);
    if(response) return response.data;
  }

  addNode = async( gatewayId: string) =>
  {
    const response = await this.builder.call("POST", `/api/system/management?addNode=true&gatewayId=${gatewayId}`, null, this.token);
    if(response) return response.data;
  }

  removeNode = async( gatewayId: string) =>
  {
    const response = await this.builder.call("POST", `/api/system/management?removeNode=true&gatewayId=${gatewayId}`, null, this.token);
    if(response) return response.data;
  }

  setLEDMode = async (gatewayId: string, nodeId: number, mode:  "enable" | "PIRdisable" | "alwaysOff") =>
  {
    const response = await this.builder.call("POST", `/api/system/management?setSensorLedMode=true&gatewayId=${gatewayId}`, { nodeId, mode }, this.token);
    if(response) return response.data;
  }

}