import React, { Component, Fragment } from 'react'
import { Button, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'

class ToolbarButton extends Component {

  static propTypes = {
    action: PropTypes.func.isRequired,
    icon: PropTypes.any,
    label: PropTypes.string,
    classes: PropTypes.any,
  }

  render() {
    const {
      action,
      icon,
      label,
      classes,
    } = this.props

    return (
      <Fragment>
        <Button
          color="secondary"
          className={classes.margin}
          variant="contained"
          onClick={action}
        >
          {icon}
          <span className={classes.margin}>
            <Typography
              noWrap
              color="secondary"
              className={classes.headerButton}
            >
              {label}
            </Typography>
          </span>
        </Button>
      </Fragment>
    )
  }
}
const styles = theme => ({
  margin: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  noWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  headerButton: {
    color: 'white',
  },
})

// Container

const mapStateToProps = state => ({})

export default compose(
  connect(mapStateToProps, null),
  withStyles(styles)
)(ToolbarButton)
