import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import AppMenu from '../AppDrawer/AppMenu'
import UserWelcome from '../../../components/UserWelcome'
import AppHeader from '../AppHeader'
import { DrawerHeader } from './DrawerHeader';
import { Drawer } from './Drawer';
import Donut from '../../../components/Donut/container/DonutContainer'
import { actionToggleDrawer } from '../../../redux/domains/user/userStore';
import { ApplicationState } from '../../../interfaces/reduxState';

export const drawerWidth = 240;

export interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface MiniDrawerProps
{
  location: any;
  content: JSX.Element;
}

export default function MiniDrawer({ location, content }: MiniDrawerProps) {
  const theme = useTheme();
  const dispatch = useDispatch()
  const isDrawerOpen = useSelector(({ user }: ApplicationState) => user.isDrawerOpen)

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <AppHeader />

      <Drawer variant="permanent" open={isDrawerOpen}>
        <DrawerHeader>
          <IconButton onClick={() => dispatch(actionToggleDrawer())}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <AppMenu location={location} />
        <Divider />
        {/* DONUTS */}
        { isDrawerOpen &&
          <div style={styles.donutContainer}>
            <Donut
              type="householdHealth"
              arcLabel='Household Status' />
            <Donut
              type="systemHealth"
              arcLabel='System Status' />
          </div>
        }

      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        { content }
      </Box>
    </Box>
  );
}

const styles = {
  donutContainer: {
    flex: 1,
    display: 'flex' as 'flex',
    textAlign: 'center' as 'center',
    justifyContent: 'center' as 'center',
    flexDirection: "column" as "column",
    width: drawerWidth,
    alignItems: 'center',
  },
}