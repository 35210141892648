import { connect } from 'react-redux'
import Donut from '../component/Donut'

import sharedStore from '../../../redux'

// ACTIONS
const { clearHouseholdList, ACTION_SET_SEARCH_QUERY_PLACEHOLDER_TEXT } = sharedStore.householdsV2Store
const { callAPI } = sharedStore.userStore

const systemSummaryData = sharedStore.householdsV2Selector.systemSummaryData
const householdGraphStatus = sharedStore.householdsV2Selector.householdGraphStatus
const inactiveHouseholdCount = sharedStore.householdsV2Selector.inactiveHouseholdCount

const {
  HOUSEHOLD_V2_LIST_PAGE,
  HOUSEHOLDS_STATUS,
} = sharedStore.apiEndpoints

const mapStateToProps = state => {
  return {
    lastSearchQuery: state.householdsV2.lastSearchQuery,
    householdHealth: householdGraphStatus(state),
    systemData: systemSummaryData(state),
    inactiveHouseholdCount: inactiveHouseholdCount(state),
    inteliCareProUser: state.user.inteliCareProUser || false,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getHouseholdListNextPage: (pagePosition, searchQuery, order, householdStatus, systemHealthStatus, triagePriority) => {
      dispatch(callAPI(HOUSEHOLD_V2_LIST_PAGE({
        pageSize: 250,
        pagePosition,
        searchQuery: searchQuery || '*',
        order,
        householdStatus,
        systemHealthStatus,
        triagePriority,
      })))
    },
    clearHouseholdList: () => dispatch(clearHouseholdList()),
    setSearchQueryHelperText: (value) => dispatch(ACTION_SET_SEARCH_QUERY_PLACEHOLDER_TEXT(value)),
    fetchStatusSummary: () => {
      dispatch(callAPI(HOUSEHOLDS_STATUS()))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Donut)