import { FilteredIotEvents } from "@intelicare/shared/interfaces/iot";
import { Builder } from "./ApiBuilder";


export class IotEventApi extends Builder
{
  token: string;
  constructor(token: string)
  {
    super(true);

    this.token = token;
  }

  getIotEventsBySensorId = async (householdId: string, gatewayId: string, timeRange: string): Promise<FilteredIotEvents[] | undefined> =>
  {
    const endpoint = `/api/data/sensor/event/${householdId}?gatewayId=${gatewayId}&timeRange=${timeRange}`

    const response = await this.call("GET", endpoint, null, this.token)
    if(response) return response.data

  }
}