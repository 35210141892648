//@ts-nocheck
import React, { Component } from "react";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import PendantIcon from "@mui/icons-material/SettingsRemote";

import MoreIcon from "@mui/icons-material/MoreVert";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";

import { colors, helper } from "../../../../redux";
import { SensorTypes } from '@intelicare/shared/enums'
const friendlyDateTime = helper.friendlyDateTime;
const colorForStatusValue = colors.colorForStatusValue;

export enum SENSOR_STATUS_REASONS {
  ok = "Okay",
  notSeen = "No data from sensor for a long time",
  lowBattery = "Sensor has low battery",
  gatewayDischarging = "Running on Battery",
  gatewayOffline = "InteliHub is offline",
}

export interface SensorProps {
  classes: any;
  data: ISensor;
  onDeleteModalOpen(sensor: ISensor): void;
  onUpdatePendantModalOpen(sensor: ISensor): void;
  isDeveloper: boolean;
  isSupport: boolean;
  timezone: string;
  id: string;
  hasZones: boolean;
}

export interface ISensor {
  id: string;
  friendlyName: string;
  timezone: string;
  statusReason: SENSOR_STATUS_REASONS;
  roomType: string;
  lastSeen: string;
  type: string;
  batteryLevel?: string;
  status: string;
  hasZones?: boolean;
}

const getSensorStatusReason = (data: ISensor): string => {
  try {
    if (data.statusReason) {
      if (Object.keys(SENSOR_STATUS_REASONS).includes(data.statusReason)) {
        //@ts-ignore
        return SENSOR_STATUS_REASONS[data.statusReason];
      }

      return data.statusReason;
    }
    return "Unknown";
  } catch (error) {
    return "Unknown";
  }
};

export class Sensor extends Component<SensorProps> {
  state = {
    anchorEl: "",
  };

  render() {
    const {
      classes,
      id,
      data,
      timezone,
      onDeleteModalOpen,
      onUpdatePendantModalOpen,
      isDeveloper,
      isSupport,
      hasZones,
    } = this.props;

    return (
      <TableRow key={id}>
        <TableCell align="left">
          <Typography
            style={{ backgroundColor: data.lastSeen ? colorForStatusValue(data.status) : colorForStatusValue("UNKNOWN") }}
            className={classes.StatusBadge}
          >
            {data.lastSeen && data.status || "UNKNOWN"}
          </Typography>
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {data.friendlyName}
        </TableCell>
        {/* MULTI RESIDENT ZONE NAME */}
        { hasZones && <TableCell align="left">{data.zoneFriendlyName}</TableCell> }
        {/* MULTI RESIDENT ROOM NAME */}
        <TableCell align="left">{data.roomType}</TableCell>
        <TableCell align="left">
          {data.lastSeen ? friendlyDateTime(data.lastSeen, timezone): "Never seen"}
        </TableCell>
        <TableCell align="left">
          {data.type === SensorTypes.PANI ? data.battery : data.batteryLevel
            ? `${Number.parseInt(data.batteryLevel)}%`
            : undefined}
        </TableCell>
        <TableCell align="left">{getSensorStatusReason(data)}</TableCell>

        {/* Actions Menu */}
        <TableCell align="left">
          <IconButton
            onClick={(event) =>
              this.setState({ anchorEl: event.currentTarget })
            }
          >
            <MoreIcon />
          </IconButton>
          <Menu
            id={`sensor-menu-${data.id}`}
            // @ts-ignore
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={() => this.setState({ anchorEl: "" })}
          >
            {data.type !== "gateway-sensor" &&
              data.type != "guardian-ai" &&
              (isDeveloper || isSupport) && (
              <MenuItem onClick={() => onDeleteModalOpen(data)}>
                <DeleteIcon color="secondary" style={{ paddingRight: 8 }} />
                  Delete
              </MenuItem>
            )}
            {data.type === "pendant" && (isDeveloper || isSupport) && (
              <MenuItem onClick={() => onUpdatePendantModalOpen(data)}>
                <PendantIcon color="secondary" style={{ paddingRight: 8 }} />
                Update
              </MenuItem>
            )}
          </Menu>
        </TableCell>
      </TableRow>
    );
  }
}
