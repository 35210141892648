import {
  put,
  takeEvery,
} from 'redux-saga/effects'

import {
  USER_INFO_SAVE,
  USER_INFO,
} from '../../shared/services/APIEndpoints'

import {
  callAPISaga,
  errorPrefix,
} from '../../shared/services/WebAPI'

function* saveUserInfo(action) {
  const userProfileEndpoint = USER_INFO_SAVE(action)
  try {
    yield callAPISaga(userProfileEndpoint)
  } catch (e) {
    yield put({ type: errorPrefix(userProfileEndpoint), error: e })
  }
}

function* getUserInfo() {
  yield callAPISaga(USER_INFO())
}

function* userSaga(){
  yield takeEvery("LOGIN_START", getUserInfo)
  yield takeEvery("USER_INFO_SAVE", saveUserInfo)
}

export default userSaga