import moment from 'moment-timezone'

// ------------------------------------
// Constants
// ------------------------------------
export const ACTIVITYGRAPH_BEGIN = 'ACTIVITYGRAPH_BEGIN'
export const ACTIVITYGRAPH_SUCCESS = 'ACTIVITYGRAPH_SUCCESS'
export const ACTIVITYGRAPH_ERROR = 'ACTIVITYGRAPH_ERROR'

export const OVERALLACTIVITYGRAPH_BEGIN = 'OVERALLACTIVITYGRAPH_BEGIN'
export const OVERALLACTIVITYGRAPH_SUCCESS = 'OVERALLACTIVITYGRAPH_SUCCESS'
export const OVERALLACTIVITYGRAPH_ERROR = 'OVERALLACTIVITYGRAPH_ERROR'

export const AVERAGEACTIVITY_BEGIN = 'AVERAGEACTIVITY_BEGIN'
export const AVERAGEACTIVITY_SUCCESS = 'AVERAGEACTIVITY_SUCCESS'
export const AVERAGEACTIVITY_ERROR = 'AVERAGEACTIVITY_ERROR'

export const HOUSEHOLD_GRAPHS_BEGIN = 'HOUSEHOLD_GRAPHS_BEGIN'
export const HOUSEHOLD_GRAPHS_SUCCESS = 'HOUSEHOLD_GRAPHS_SUCCESS'
export const HOUSEHOLD_GRAPHS_ERROR = 'HOUSEHOLD_GRAPHS_ERROR'

export const GATEWAY_BATTERY_REPORT_BEGIN = 'GATEWAY_BATTERY_REPORT_BEGIN'
export const GATEWAY_BATTERY_REPORT_SUCCESS = 'GATEWAY_BATTERY_REPORT_SUCCESS'
export const GATEWAY_BATTERY_REPORT_ERROR = 'GATEWAY_BATTERY_REPORT_ERROR'
export const CLEAR_BATTERY_REPORT = 'CLEAR_BATTERY_REPORT'

export const GATEWAY_BATTERY_REPORT_ALL_BEGIN = "GATEWAY_BATTERY_REPORT_ALL_BEGIN"
export const GATEWAY_BATTERY_REPORT_ALL_SUCCESS = "GATEWAY_BATTERY_REPORT_ALL_SUCCESS"
export const GATEWAY_BATTERY_REPORT_ALL_ERROR = "GATEWAY_BATTERY_REPORT_ALL_ERROR"

export const GET_RADAR_GRAPH_BEGIN = "GET_RADAR_GRAPH_BEGIN"
export const GET_RADAR_GRAPH_SUCCESS = "GET_RADAR_GRAPH_SUCCESS"
export const GET_RADAR_GRAPH_ERROR = "GET_RADAR_GRAPH_ERROR"
export const CLEAR_RADAR_GRAPH = "GET_RADAR_GRAPH_BEGIN"

function parseGraphData(inData) {
  const outData = {}
  for (const key in inData) {
    outData[key] = inData[key]
    outData[key].start = moment(inData[key].start)
    outData[key].end = moment(inData[key].end)
  }
  return outData
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  activity: {
    isFetching: false,
    error: undefined,
    data: [],
  },
  overallActivity: {
    isFetching: false,
    error: undefined,
    data: [],
  },
  averageActivity: {
    isFetching: false,
    error: undefined,
    data: [],
  },
  graphs: {
    isFetching: false,
    error: undefined,
    data: {},
  },
  otherRadarSensors: [],
}

// ------------------------------------
// Reducer
// ------------------------------------
export default function graphsReducer(state = initialState, action) {

  switch (action.type) {
    case ACTIVITYGRAPH_BEGIN:
      return {
        ...state,
        activity: {
          ...state.activity,
          isFetching: true,
        },
      }

    case ACTIVITYGRAPH_SUCCESS:
      return {
        ...state,
        activity: {
          ...state.activity,
          data: action.data,
          isFetching: false,
        },
      }

    case ACTIVITYGRAPH_ERROR:
      return {
        ...state,
        activity: {
          ...state.activity,
          error: action.error,
          isFetching: false,
        },
      }

    case OVERALLACTIVITYGRAPH_BEGIN:
      return {
        ...state,
        overallActivity: {
          ...state.overallActivity,
          isFetching: true,
        },
      }

    case OVERALLACTIVITYGRAPH_SUCCESS:
      return {
        ...state,
        overallActivity: {
          ...state.overallActivity,
          data: action.data,
          isFetching: false,
        },
      }

    case OVERALLACTIVITYGRAPH_ERROR:
      return {
        ...state,
        overallActivity: {
          ...state.overallActivity,
          error: action.error,
          isFetching: false,
        },
      }

    case AVERAGEACTIVITY_BEGIN:
      return {
        ...state,
        averageActivity: {
          ...state.averageActivity,
          isFetching: true,
        },
      }

    case AVERAGEACTIVITY_SUCCESS:
      return {
        ...state,
        averageActivity: {
          ...state.averageActivity,
          isFetching: false,
          data: action.data,
        },
      }

    case AVERAGEACTIVITY_ERROR:
      return {
        ...state,
        averageActivity: {
          ...state.averageActivity,
          isFetching: false,
          error: action.error,
        },
      }

    case HOUSEHOLD_GRAPHS_BEGIN:
      return {
        ...state,
        graphs: {
          ...state.graphs,
          isFetching: true,
        },
      }

    case HOUSEHOLD_GRAPHS_SUCCESS:
      return {
        ...state,
        graphs: {
          ...state.graphs,
          isFetching: false,
          data: parseGraphData(action.data),
        },
      }

    case HOUSEHOLD_GRAPHS_ERROR:
      return {
        ...state,
        graphs: {
          ...state.graphs,
          isFetching: false,
          error: action.error,
        },
      }

    case GATEWAY_BATTERY_REPORT_BEGIN:
      return {
        ...state,
        graphs: {
          ...state.graphs,
          isFetching: true,
        },
      }

    case GATEWAY_BATTERY_REPORT_SUCCESS:
      return {
        ...state,
        graphs: {
          ...state.graphs,
          isFetching: false,
          batteryReportData: action.data,
        },
      }

    case GATEWAY_BATTERY_REPORT_ERROR:
      return {
        ...state,
        graphs: {
          ...state.graphs,
          isFetching: false,
          error: action.error,
        },
      }

    case CLEAR_BATTERY_REPORT:
      return {
        ...state,
        graphs: {
          ...state.graphs,
          isFetching: false,
          error: "",
          batteryReportData: [],
        },
      }

    case GATEWAY_BATTERY_REPORT_ALL_BEGIN:
      return {
        ...state,
        isFetchingBatteryReport: true,
      }

    case GATEWAY_BATTERY_REPORT_ALL_SUCCESS:
      return {
        ...state,
        allGatewayBatteryReport: action.data,
        isFetchingBatteryReport: false,
      }

    case GATEWAY_BATTERY_REPORT_ALL_ERROR:
      return {
        ...state,
        allGatewayBatteryReportError: action.error,
      }
    case GET_RADAR_GRAPH_BEGIN:
      return {
        ...state,
        isFetchingRadarData: true,
      }
    case GET_RADAR_GRAPH_SUCCESS:
      return {
        ...state,
        isFetchingRadarData: false,
        radarGraphData: action.data.results,
        currentSensor: action.data.currentSensor,
        otherRadarSensors: action.data.otherRadarSensors,
        radarGraphGetError: "",
      }
    case GET_RADAR_GRAPH_ERROR:
      return {
        ...state,
        isFetchingRadarData: false,
        radarGraphData: [],
        radarGraphGetError: action.error,
        currentSensor: "",
        otherRadarSensors: [],
      }
    case CLEAR_RADAR_GRAPH:
      return {
        ...state,
        isFetchingRadarData: false,
        radarGraphData: [],
        radarGraphGetError: [],
        currentSensor: [],
        otherRadarSensors: [],
      }
    default:
      return state
  }
}
