export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
  },
  StreamContainer: {
    width: '100%',
    flexWrap: 'nowrap',
  },
  StreamItem: {
    marginLeft: 12,
    marginRight: 12,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  ActivityIcon: {
    display: 'flex',
    justifyContent: 'center',
  },
  Loading: {
    display: 'flex',
    width: '100%',
    // height: '100%',
    // minHeight: '100px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  Empty: {
    display: 'flex',
    // minHeight: '100px',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
})
