import { connect } from 'react-redux'
import { withErrorHandler } from '../../hoc/withErrorHandler'
import { compose } from 'redux'
import HouseholdSettingsPortalView, { ErrorPage } from './HouseholdSettingsPortalView'

import sharedStore from '../../redux'
const callAPI = sharedStore.userStore.callAPI
const {
  getActiveHouseholdId,
} = sharedStore.householdsV2Selector
const {
  HOUSEHOLD_CARER_LIST,
} = sharedStore.apiEndpoints


const mapDispatchToProps = (dispatch) => ({
  getCarers: (householdId) => {
    dispatch(callAPI(HOUSEHOLD_CARER_LIST({ householdId })))
  },
})

const mapStateToProps = (state) => ({
  activeHouseholdId: getActiveHouseholdId(state),
  isDeveloper: state.user.isDeveloper,
  isSupport: state.user.isSupport,
  isDemoUser: state.user.isDemoUser,
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorHandler(ErrorPage)
)(HouseholdSettingsPortalView)
