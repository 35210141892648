import { HouseholdHealthRollup } from "../interface";
import { Diffs } from "../StatsView";

export const calculateDayStatistics = (
  householdsHealthRollup: HouseholdHealthRollup[]
) => {
  try {
    const today = householdsHealthRollup[householdsHealthRollup.length - 1];
    const yesterday = householdsHealthRollup[householdsHealthRollup.length - 2];

    const diffs = {
      Alert: today.systemHealth.ALERT - yesterday.systemHealth.ALERT,
      Normal: today.systemHealth.NORMAL - yesterday.systemHealth.NORMAL,
      Warning: today.systemHealth.WARNING - yesterday.systemHealth.WARNING,
      Unmonitored:
        today.systemHealth.NOT_MONITORED - yesterday.systemHealth.NOT_MONITORED,
      Inactive: today.systemHealth.INACTIVE - yesterday.systemHealth.INACTIVE,
      // Is getting here as undefined
      Away: today.systemHealth.AWAY - yesterday.systemHealth.AWAY,
    };
    return diffs;
  } catch (error) {
    //
  }
};


export const calculateRangeStatistics = (householdsHealthRollup: HouseholdHealthRollup[], range1End: number, range2End: number): Diffs | undefined => {
  try {

    // slice the weeks up
    const thisWeek = householdsHealthRollup.slice(householdsHealthRollup.length - range1End, householdsHealthRollup.length);
    const lastWeek = householdsHealthRollup.slice(householdsHealthRollup.length - range2End, householdsHealthRollup.length - range1End);

    // @ts-ignore
    const alertTotalThisWeek = thisWeek.reduce((a, b) => a =+ Number(b.systemHealth.ALERT), []) as number
    // @ts-ignore
    const normalTotalThisWeek = thisWeek.reduce((a, b) => a =+ Number(b.systemHealth.NORMAL), []) as number
    // @ts-ignore
    const warningTotalThisWeek = thisWeek.reduce((a, b) => a =+ Number(b.systemHealth.WARNING), []) as number
    // @ts-ignore
    const notMonitoredThisWeek = thisWeek.reduce((a, b) => a =+ Number(b.systemHealth.NOT_MONITORED), []) as number
    // @ts-ignore
    const inactiveThisWeek = thisWeek.reduce((a, b) => a =+ Number(b.systemHealth.INACTIVE), []) as number
    // @ts-ignore
    const awayThisWeek = thisWeek.reduce((a, b) => a =+ Number(b.systemHealth.AWAY), []) as number

    // @ts-ignore
    const alertTotalLastWeek = lastWeek.reduce((a, b) => a =+ Number(b.systemHealth.ALERT), []) as number
    // @ts-ignore
    const normalTotalLastWeek = lastWeek.reduce((a, b) => a =+ Number(b.systemHealth.NORMAL), []) as number
    // @ts-ignore
    const warningTotalLastWeek = lastWeek.reduce((a, b) => a =+ Number(b.systemHealth.WARNING), []) as number
    // @ts-ignore
    const notMonitoredLastWeek = lastWeek.reduce((a, b) => a =+ Number(b.systemHealth.NOT_MONITORED), []) as number
    // @ts-ignore
    const inactiveLastWeek = lastWeek.reduce((a, b) => a =+ Number(b.systemHealth.INACTIVE), []) as number
    // @ts-ignore
    const awayLastWeek = lastWeek.reduce((a, b) => a =+ Number(b.systemHealth.AWAY), []) as number

    const diffs = {
      Alert: alertTotalThisWeek - alertTotalLastWeek,
      Normal: normalTotalThisWeek - normalTotalLastWeek,
      Warning: warningTotalThisWeek - warningTotalLastWeek,
      Unmonitored: notMonitoredThisWeek - notMonitoredLastWeek,
      Inactive: inactiveThisWeek - inactiveLastWeek,
      Away: awayThisWeek ? awayThisWeek : 0 - awayLastWeek ? awayLastWeek : 0,
    };
    return diffs;
  } catch (error) {
    //
  }
};

export const getLatestTotal = (
  householdsHealthRollup: HouseholdHealthRollup[]
) => {
  try {
    const today = householdsHealthRollup[householdsHealthRollup.length - 1];
    const totals = {
      Alert: today.systemHealth.ALERT,
      Normal: today.systemHealth.NORMAL,
      Warning: today.systemHealth.WARNING,
      Unmonitored: today.systemHealth.NOT_MONITORED,
      Inactive: today.systemHealth.INACTIVE,
      Away: today.systemHealth.AWAY,
    };
    return totals;
  } catch (error) {
    //
  }
};