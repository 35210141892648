import React from 'react'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ErrorIcon from '@mui/icons-material/Error';
import moment from 'moment'
import { IGsmRunStatistics } from '../../../../../api/GsmStatsApi';
import Card from '@material-ui/core/Card'

interface ErrorListProps
{
  gsmRunStatistics: IGsmRunStatistics[] | undefined
}

export const ErrorList = ({ gsmRunStatistics }: ErrorListProps) =>
{
  return(
    <Card style={styles.container}>
      <List>
        {
          gsmRunStatistics && gsmRunStatistics.length > 0 && gsmRunStatistics.map((stat, key) => {

            return(<ListItem key={key}>
              <ListItemAvatar>
                <Avatar>
                  <ErrorIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={stat && stat.error ? stat.error : "No errors for run"} secondary={moment(stat.runStart).format("LLLLZ")} />
            </ListItem>)

          })
        }


      </List>


    </Card>
  )
}

const styles = {
  container: {
    padding: 12,
  },
}