import Skeleton from '@mui/material/Skeleton'
import Grid from '@mui/material/Grid'

export const LoadingRow = () =>
{
  return(
    <Grid container flexDirection="row" alignItems="center" spacing={3}>
      <Grid item>
        <Skeleton height={100} width={100} animation="wave" variant="circular" />
        <Skeleton width={100} animation="wave" variant="text" />
      </Grid>
      <Grid item flexGrow={1}>
        <Skeleton height={180} width={"100%"} animation="wave" />
      </Grid>
    </Grid>
  )
}
