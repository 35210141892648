import React, { Component } from "react";
import PropTypes from "prop-types";

// Material UI components
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import IconButton from "@material-ui/core/IconButton";

// Custom
import { Loading } from "./Loading";
import { TableItem } from "./TableItem";
import TableActions from "./TableActions";

// Icons
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import Home from "@mui/icons-material/Home";

import moment from "moment";

import { NoNotifications } from "./NoNotifications";
import TypeFilterMenu from "./TypeFilterMenu";
import StatusFilterMenu from './StatusFilterMenu'
import ActiveFilterDisplay from "./ActiveFilterDisplay";

const PAGE_COUNT = 10;
const DEFAULT_PAGE_LIMIT = 10;
const MAX_SEARCH_LIMIT = 100;

class NotificationsTableV2 extends Component {
  state = {
    offset: 0,
    search: "",
    limit: 10,
    order: "DESC",
    timeLimit: moment().subtract(1, "months").unix(),
    type: "",
    status: "",
    monthValue: 1,
    isSearchingFilterEnabled: false,
  };

  componentDidMount() {
    this.getNotificationsWithState();
  }

  componentWillUnmount() {
    const { clearNotificationsForHousehold, showHouseholdName } = this.props;

    if (showHouseholdName === false) clearNotificationsForHousehold();
  }

  componentDidUpdate(lastProps) {
    if (lastProps.messageResolving === true && this.props.messageResolving === false) {
      // Just finished resolving a message - reload notifications
      this.getNotificationsWithState();
    }
  }

  getNotificationsWithState = () => {
    const { getNotifications, householdId } = this.props;
    const { offset, limit, order, timeLimit, type, status, search } = this.state;

    getNotifications({
      householdId: householdId,
      offset: offset,
      limit: limit,
      order: order,
      timeLimit: timeLimit,
      type: type,
      status: status,
      search: search,
    });
  };

  goForward = () => {
    const { offset } = this.state;
    const { notifications } = this.props;
    if (notifications.length === PAGE_COUNT)
      this.setState({ offset: offset + PAGE_COUNT }, () =>
        this.getNotificationsWithState()
      );
  };

  goBack = () => {
    const { offset } = this.state;
    if (offset > 0)
      this.setState({ offset: offset - PAGE_COUNT }, () =>
        this.getNotificationsWithState()
      );
  };

  changeSortOrder = () => {
    const { order } = this.state;
    this.setState({ order: order === "ASC" ? "DESC" : "ASC" }, () =>
      this.getNotificationsWithState()
    );
  };

  sortByType = (value) => {
    this.setState({ type: value }, () => this.getNotificationsWithState());
  };

  sortByStatus = (value) => {
    this.setState({ status: value }, () => this.getNotificationsWithState());
  };

  clearFilters = () => {
    this.setState(
      {
        type: "",
        status: "",
        isSearchingFilterEnabled: false,
        search: "",
        offset: 0,
        timeLimit: "",
        limit: DEFAULT_PAGE_LIMIT,
        order: "DESC",
      },
      () => this.getNotificationsWithState()
    );
  };

  handleDateRangeChange = (monthValue) => {
    // Convert to a unix timestamp
    const unixTimeStamp = moment().subtract(monthValue, "months").unix();
    this.setState({ monthValue: monthValue, timeLimit: unixTimeStamp }, () =>
      this.getNotificationsWithState()
    );
  };

  handleSearch = (search) => {
    this.setState({ search: search });

    if (search && search.length > 2) {
      return this.setState(
        {
          isSearchingFilterEnabled: true,
          offset: 0,
          limit: MAX_SEARCH_LIMIT,
          order: "DESC",
        },
        () => this.getNotificationsWithState()
      );
    }

    if (search.length === 0) {
      return this.setState(
        {
          isSearchingFilterEnabled: false,
          search: "",
          offset: 0,
          limit: DEFAULT_PAGE_LIMIT,
          order: "DESC",
          timeLimit: moment().subtract(2, "weeks").unix(),
        },
        () => this.getNotificationsWithState()
      );
    }
  };

  render() {
    const {
      notifications,
      isGettingNotificationsForHousehold,
      showHouseholdName,
      onSelect,
      householdId,
    } = this.props;
    const { order, type, status, monthValue, search } = this.state;

    return (
      <div>
        {/* Table Actions */}
        <TableActions
          search={search}
          handleSearch={this.handleSearch}
          type={type}
          status={status}
          monthValue={monthValue}
          householdId={householdId}
          handleDateRangeChange={this.handleDateRangeChange}
          getNotificationsWithState={this.getNotificationsWithState}
          clearFilters={this.clearFilters}
        />
        <Grid container alignItems="center" spacing={1} >
          <Grid item><TypeFilterMenu onChangeListener={this.sortByType}/></Grid>
          <Grid item><StatusFilterMenu onChangeListener={this.sortByStatus}/></Grid>
          <Grid item>
            <Button variant="outlined" onClick={this.clearFilters}>Clear filters</Button>
          </Grid>
          <Grid item>
            <ActiveFilterDisplay type={this.state.type} status={this.state.status}/>
          </Grid>
        </Grid>
        <div style={{ paddingBottom: 10 }}></div>

        {/* Render the table container if we have notifications */}
        <TableContainer component={Paper}>
          {/* The main table */}
          <Table size="small" aria-label="notifications table">
            <TableHead><TableRow>
              <TableCell align="center"></TableCell> {/* placeholder cell */}
              {showHouseholdName && (<TableCell style={styles.headStyle}>Household</TableCell>)}
              <TableCell style={styles.headStyle}>Description</TableCell>
              {/* Allow sorting by ascending or descending */}
              <TableCell
                sortDirection={order === "ASC" ? order.toLowerCase() : "DESC".toLowerCase()}
                style={styles.headStyle}
              >
                <TableSortLabel
                  active={true}
                  direction={order === "ASC" ? order.toLowerCase() : "DESC".toLowerCase()}
                  onClick={() => this.changeSortOrder()}
                >
                    Time
                </TableSortLabel>
              </TableCell>
              <TableCell style={styles.headStyle} align="right">Actions</TableCell>
            </TableRow></TableHead>
            <TableBody>
              {!isGettingNotificationsForHousehold &&
                notifications &&
                notifications.length > 0 ? notifications.map((row, key) =>
                  row && (<TableItem
                    onSelect={onSelect} showHouseholdName={showHouseholdName} key={key} row={row}
                  />)
                ) : (
                  <TableCell colSpan={7}>
                    {isGettingNotificationsForHousehold ? (
                      <Loading text="Getting Notifications" />
                    ) : (
                      <NoNotifications />
                    )}
                  </TableCell>
                )}
            </TableBody>
          </Table>
          {/* Pagination */}
          <Grid container justify="center" style={{ padding: 10 }}>
            <Button onClick={() => this.goBack()} startIcon={<ArrowLeftIcon />}>
              Get Less
            </Button>
            <IconButton onClick={() => this.clearFilters()}>
              <Home />
            </IconButton>
            <Button
              onClick={() => this.goForward()}
              endIcon={<ArrowRightIcon />}
            >
              Get More
            </Button>
          </Grid>
        </TableContainer>
      </div>
    );
  }
}

NotificationsTableV2.propTypes = {
  householdId: PropTypes.string,
  getNotifications: PropTypes.func,
  notifications: PropTypes.array,
  isGettingNotificationsForHousehold: PropTypes.bool,
  showHouseholdName: PropTypes.bool,
  clearNotificationsForHousehold: PropTypes.func,
  onSelect: PropTypes.func,
  messageResolving: PropTypes.bool,
};

const styles = {
  actionsStyle: {
    margin: 6,
  },
  headStyle: {
    fontSize: 17,
    padding: "6px 24px 6px 16px",
    align: "left",
  },
  headMenuStyle: {
    fontSize: 17,
    padding: "6px 24px 9px -1px",
    align: "left",
  },
  formControl: {
    margin: 2,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: 2,
  },
};

export default NotificationsTableV2;
