import { createSelector } from 'reselect'

export const getActivityGraph = (state) => state.graphs.activity

export const isFetchingActivityGraphData = createSelector(
  [ getActivityGraph ],
  (activityGraph) => {
    return activityGraph.isFetching
  }
)

export const getActivityGraphData = createSelector(
  [ getActivityGraph ],
  (activityGraph) => {
    return activityGraph.data
  }
)

export const getOverallActivityGraph = (state) => state.graphs.overallActivity

export const isFetchingOverallActivityGraphData = createSelector(
  [ getOverallActivityGraph ],
  (overallActivityGraph) => {
    return overallActivityGraph.isFetching
  }
)

export const getOverallActivityGraphData = createSelector(
  [ getOverallActivityGraph ],
  (overallActivityGraph) => {
    return overallActivityGraph.data
  }
)

export const getAverageActivityData = (state) => state.graphs.averageActivity
