import { Builder } from "./ApiBuilder";

interface SendTestHttpResponse
{
  result: string | undefined;
  error: string | undefined
}

export class NotificationApi extends Builder
{
  constructor(public token: string)
  {
    super(true)
  }

  sendTest = async (householdId: string, severity: string, message: string, sourceType: string, clientNo: string): Promise<SendTestHttpResponse | undefined> =>
  {
    const body =
      {
        householdId: householdId,
        message: message,
        severity: severity,
        sourceType: sourceType,
        clientNo: clientNo,
      }

    const response = await this.call("POST", `/api/system/management?sendTestNotification=true`, body, this.token)
    if(response) return response.data;

  }
}