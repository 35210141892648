import React, { useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import { Avatar } from '@material-ui/core';
import { purple } from '@material-ui/core/colors';
import { getInitials } from '../utils/getInitials';
import { Organisation } from '../../../../../api/OrganisationApi';

interface EditOrganisationModalProps
{
  handleClose: () => void;
  open: boolean;
  organisationDetails?: Organisation;
}

export const EditOrganisationModal = ({ handleClose, open, organisationDetails }: EditOrganisationModalProps) =>
{
  const [ organisationName, setOrganisationName ] = useState(organisationDetails ? organisationDetails.friendlyName : "")
  return(
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}>
      <DialogTitle>Edit Organisation</DialogTitle>
      <DialogContent>

        <Grid container direction="row" alignItems="center" spacing={3}>

          <Grid item>
            <Avatar style={{ width: 100, height: 100, backgroundColor: purple[500], fontSize: 32 }}>{getInitials(organisationName)}</Avatar>
          </Grid>

          <Grid item style={{ flex: 1 }}>
            <TextField
              onChange={(e: any) => setOrganisationName(e.target.value)}
              value={organisationName}
              autoFocus
              id="friendlyName"
              label="Organisation Name"
              type="text"
              fullWidth
              variant="outlined"
            />
          </Grid>


        </Grid>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Delete</Button>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleClose}>Update</Button>
      </DialogActions>
    </Dialog>
  )
}

