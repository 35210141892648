import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import NotificationAdd from '@mui/icons-material/NotificationAdd';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Icon from '@material-ui/core/Icon';

import { IHouseholdPaniSensor } from '@intelicare/shared/interfaces/pani/Pani';
import { usePaniNotifications } from '../hooks/usePaniNotifications';
import { NotificationItem } from './NotificationItem';
import { purple } from '@mui/material/colors';
interface SensorListItemProps
{
  sensor: IHouseholdPaniSensor;
  householdId: string;
  token: string;
}

export const SensorListItem = ({ sensor, token, householdId }: SensorListItemProps) =>
{

  const [
    isLoading,
    notifications,
    getNotifications,
    createNotification,
    createNotificationIdResult,
    updateNotification,
    updateNotificationResponse,
    removeNotification,
    deleteNotificationResult,
    appendNewNotification,
    action,
  ] = usePaniNotifications(token, householdId, sensor.paniId);

  return(
    <Accordion onChange={() => getNotifications()}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">

        <Grid container alignItems='center' spacing={3}>

          <Grid item>

            <Avatar style={{ backgroundColor: purple[500] }}>
              <Icon style={{ color: 'white', padding: 4 }}>shower</Icon>
            </Avatar>

          </Grid>

          <Grid item>
            <Typography style={{ paddingTop: 4 }}>{sensor.friendlyName}</Typography>
          </Grid>


        </Grid>



      </AccordionSummary>
      <AccordionDetails>
        <div style={styles.details}>
          {!isLoading && notifications.length > 0 ? notifications.map((notification, key) =>
            <NotificationItem
            // @ts-ignore
              isNew={notification.isNew ? true : false}
              onCreate={(notification) => createNotification(notification)}
              onUpdate={(updatedNotification, id) => updateNotification(id, sensor.paniId, updatedNotification)}
              onDelete={(id) => removeNotification(id, sensor.paniId )}
              key={key} notification={notification}/>)
          // No notifications
            : !isLoading && notifications.length === 0 ?
              <div style={styles.noDataView}>
                <Typography color="textSecondary" style={{ paddingBottom: 3 }}>No Notifications to see here</Typography>
                <Button startIcon={<NotificationAdd />} onClick={() => appendNewNotification(0)} variant="outlined">Create</Button>
              </div>
            // Is Loading
              : isLoading &&
              <div style={styles.noDataView}>
                <Typography color="textSecondary" style={{ paddingBottom: 3 }}>{action}</Typography>
                <LinearProgress />
              </div>}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}


const styles = {
  noDataView: {
    textAlign: 'center' as 'center',
    flex: 1,
  },
  details: {
    flexDirection: 'column' as 'column',
    flex: 1,
  },
  button: {
    justifyContent: 'center' as 'center',
  },
  icon: {
    color: 'grey',
  },
}