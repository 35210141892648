import { connect } from 'react-redux'
import AppStoreOverlayView from '../components/AppStoreOverlayView'
import sharedStore from '../../../redux'
const logoutStart = sharedStore.userStore.logoutStart


const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => {
      dispatch(logoutStart())
    },
  }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(AppStoreOverlayView)
