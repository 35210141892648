import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import { SearchPlaceholder } from "./SearchPlaceholder";
import { FirmwareRowItem } from "./FirmwareRowItem";
import ConfirmDeleteHousehold from "./ConfirmDeleteHouseholdModal";

import { sortFirmware, sortGatewayStatus, sortHouseholds, sortLastUpdate, sortUpdateStatus } from "./helper/sortHelper";

interface FirmwareListTableProps {
  rows: RowItem[];
  onShowLogs(row: RowItem): void;
  onShowCommands(row: RowItem): void;
  onSelectForFirmwareUpdate(row: RowItem, checked: boolean): void;
  runGatewayUpdate(gatewayId: string): void;
  onShowSensorCommands(row: RowItem): void;
}

export interface RowItem {
  friendlyName: string;
  firmwareStatus: string;
  gatewayId?: string;
  householdId?: string;
  lastFirmwareUpdate: string;
  version: string;
  logFiles: string[];
  connectionState: string;
  lastActivityTime: string;
}

export type sortOrder = "desc" | "asc" | undefined;

interface State {
  rows: RowItem[];
  firmwareSortOrder: sortOrder;
  householdSortOrder: sortOrder;
  updateStatusSortOrder: sortOrder;
  lastUpdateSortOrder: sortOrder;
  gatewayStatus: sortOrder;
  showConfirmDeleteHousehold: boolean;
  currentlyOpenRow: RowItem;
}

export const UPDATE_STATES: any = {
  downloading: "Downloading",
  downloadFailed: "Download Failed",
  downloadComplete: "Download Complete",
  applying: "Applying",
  applyFailed: "Apply Failed",
  applyComplete: "Apply Complete",
  alreadyUpToDate: "Already up to date",
};

class FirmwareListTable extends Component<FirmwareListTableProps, State> {
  constructor(props: FirmwareListTableProps) {
    super(props);
    this.state = {
      firmwareSortOrder: "desc",
      householdSortOrder: "desc",
      updateStatusSortOrder: "desc",
      lastUpdateSortOrder: "desc",
      gatewayStatus: "desc",
      rows: [],
      showConfirmDeleteHousehold: false,
      currentlyOpenRow: {
        friendlyName: "",
        gatewayId: "",
        firmwareStatus: "",
        lastFirmwareUpdate: "",
        version: "",
        logFiles: [],
        connectionState: "",
        lastActivityTime: "",
      },
    };
  }

  sortByFirmwareDate = () => {
    const { rows } = this.props;
    const { firmwareSortOrder } = this.state;

    this.setState({
      rows: sortFirmware(firmwareSortOrder, rows),
      firmwareSortOrder: firmwareSortOrder === "desc" ? "asc" : "desc",
    });
  };

  sortByHousehold = () => {
    const { rows } = this.props;
    const { householdSortOrder } = this.state;

    this.setState({
      rows: sortHouseholds(householdSortOrder, rows),
      householdSortOrder: householdSortOrder === "desc" ? "asc" : "desc",
    });
  };

  sortByUpdateStatus = () => {
    const { rows } = this.props;
    const { updateStatusSortOrder } = this.state;

    this.setState({
      rows: sortUpdateStatus(updateStatusSortOrder, rows),
      updateStatusSortOrder: updateStatusSortOrder === "desc" ? "asc" : "desc",
    });
  };

  sortByLastUpdate = () => {
    const { rows } = this.props;
    const { lastUpdateSortOrder } = this.state;

    this.setState({
      rows: sortLastUpdate(lastUpdateSortOrder, rows),
      lastUpdateSortOrder: lastUpdateSortOrder === "desc" ? "asc" : "desc",
    });
  };

  sortByGatewayStatus = () => {
    const { rows } = this.props;
    const { gatewayStatus } = this.state;
    this.setState({
      rows: sortGatewayStatus(gatewayStatus, rows),
      gatewayStatus: gatewayStatus === "desc" ? "asc" : "desc",
    });
  };

  handleHouseholdDelete = (row: RowItem) => {
    this.setState({ showConfirmDeleteHousehold: true, currentlyOpenRow: row });
  };

  componentDidMount() {
    const { rows } = this.props;
    this.setState({ rows: rows });
  }

  render() {
    const { onShowLogs, onShowCommands, onSelectForFirmwareUpdate, runGatewayUpdate, onShowSensorCommands } = this.props;

    const { rows, firmwareSortOrder, householdSortOrder, updateStatusSortOrder, lastUpdateSortOrder, gatewayStatus, showConfirmDeleteHousehold, currentlyOpenRow } = this.state;
    return (
      <div>
        {rows && rows.length > 0 ? (
          <TableContainer style={styles.container} component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel active={true} direction={householdSortOrder} onClick={this.sortByHousehold}>
                      Household
                    </TableSortLabel>
                  </TableCell>

                  <TableCell align="right">
                    {" "}
                    <TableSortLabel active={true} direction={gatewayStatus} onClick={this.sortByGatewayStatus}>
                      Gateway ID
                    </TableSortLabel>
                  </TableCell>

                  <TableCell align="right">
                    <TableSortLabel active={true} direction={firmwareSortOrder} onClick={this.sortByFirmwareDate}>
                      Firmware Version
                    </TableSortLabel>
                  </TableCell>

                  <TableCell align="right">
                    <TableSortLabel active={true} direction={updateStatusSortOrder} onClick={this.sortByUpdateStatus}>
                      Update Status
                    </TableSortLabel>
                  </TableCell>

                  <TableCell align="right">
                    <TableSortLabel active={true} direction={lastUpdateSortOrder} onClick={this.sortByLastUpdate}>
                      Last Update
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <FirmwareRowItem
                    onShowSensorCommands={onShowSensorCommands}
                    deleteHouseholdAndGatewayConfirm={this.handleHouseholdDelete}
                    runGatewayUpdate={runGatewayUpdate}
                    onSelectForFirmwareUpdate={onSelectForFirmwareUpdate}
                    onShowCommands={onShowCommands}
                    onShowLogs={onShowLogs}
                    key={row.gatewayId}
                    row={row}
                  />
                ))}
              </TableBody>
            </Table>

            <ConfirmDeleteHousehold row={currentlyOpenRow} open={showConfirmDeleteHousehold} handleClose={() => this.setState({ showConfirmDeleteHousehold: false })} />
          </TableContainer>
        ) : (
          <SearchPlaceholder />
        )}
      </div>
    );
  }
}

const styles = {
  container: { margin: 12 },
};

export { FirmwareListTable };
