import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Material UI components
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'

// Icons
import LinkIcon from '@mui/icons-material/Link'
import ResellerIcon from "@mui/icons-material/SupervisedUserCircle"
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'


// Misc
import moment from 'moment'

// Modal
import { Reseller } from '../../../../../model/Reseller'

// Main table
import HouseholdsTable from '../../Tables/Households/HouseholdsTable'

// Modals
import ConfirmDeleteModal from './../ConfirmDeleteModal'
import LinkHouseholdModal from '../LinkHouseholdModal'

import "../css/AddResellerModal.css"


export class ViewResellerModal extends Component {

  state = {
    name: "",
    address: "",
    email: "",
    contactNumber: "",
    abn: "",
    showLinkingModal: false,
    showConfirmDeleteModal: false,
    showNameError: false,
    showEmailError: false,
    showAbnError: false,
    showContactNumberError: false,
    showAddressError: false,
    sortDirection: "desc",
    sortByValue: "_ts",
    sortDirectionStartDate: "desc",
    sortDirectionEndDate: "desc",
    limit: 300,
    offset: 0,
    query: "",
  }

  populateFields = () => {
    const { currentReseller } = this.props

    this.setState({
      name: currentReseller.name,
      address: currentReseller.address,
      email: currentReseller.email,
      contactNumber: currentReseller.contactNumber,
      abn: currentReseller.abn,
    })
  }

  // Populates the fields so we can edit them
  // Gets the households associated with the reseller
  handleOnEnter = () => {
    const { getHouseholdsByResellerId, currentReseller } = this.props
    this.populateFields()
    getHouseholdsByResellerId(
      {
        limit: this.state.limit,
        offset: 0,
        resellerId: currentReseller.id,
        sortDirection: this.state.sortDirection,
        sortByValue: this.state.sortByValue,
      })
  }

  // Clean up the modal onClose
  handleOnClose = () => {
    const { onClose, clearUpdateReseller } = this.props
    this.setState(
      {
        showLinkingView: false,
        showAddressError: false,
        showEmailError: false,
        showNameError: false,
        showContactNumberError: false,
        showAbnError: false,
        sortDirectionEndDate: "desc",
        sortDirectionStartDate: "desc",
        sortDirection: "desc",
        sortByValue: "_ts",
        query: "",
      })
    clearUpdateReseller()
    onClose()
  }

  handleUpdateReseller = () => {

    if (this.validateForm()) {
      const { currentReseller, updateReseller } = this.props
      const resellerObject = new Reseller(this.state.name, this.state.address, this.state.contactNumber, this.state.email, this.state.abn)
      updateReseller(currentReseller.id, resellerObject)
    }

  }

  validateForm = () => {
    if (!this.state.address) this.setState({ showAddressError: true })
    if (!this.state.contactNumber) this.setState({ showContactNumberError: true })
    if (!this.state.email) this.setState({ showEmailError: true })
    if (!this.state.name) this.setState({ showNameError: true })
    if (!this.state.abn) this.setState({ showAbnError: true })

    if (this.state.name && this.state.address && this.state.contactNumber && this.state.email && this.state.abn) {
      this.setState({ showAddressError: false, showEmailError: false, showNameError: false, showContactNumberError: false, showAbnError: false })
      return true
    }
    return false
  }

  handleUpdateSortOrder = (sortByValue) => {

    const { getHouseholdsByResellerId, currentReseller } = this.props
    this.setState({
      sortDirection: this.state.sortDirection === "desc" ? "asc" : "desc",
      sortByValue: sortByValue,
      sortDirectionStartDate: sortByValue === "billing.billingStartDate" ? this.state.sortDirection === "desc" ? "asc" : "desc" : this.state.sortDirection,
      sortDirectionEndDate: sortByValue === "billing.billingEndDate" ? this.state.sortDirection === "desc" ? "asc" : "desc" : this.state.sortDirection,
    }, () => {

      getHouseholdsByResellerId({ resellerId: currentReseller.id, limit: this.state.limit, offset: 0, sortDirection: this.state.sortDirection, sortByValue: sortByValue })
    })

  }

  render() {

    const {
      open,
      currentReseller,
      isGettingHouseholds,
      resellerHouseholds,
      isUpdatingReseller,
      updateResellerError,
      updateResellerSuccess,
      clearUpdateReseller,
      refreshList,
      deleteReseller,
      isDeletingReseller,
      deleteResellerError,
      deleteResellerResult,
      clearDeleteReseller,
    } = this.props

    return (
      <Dialog
        fillWidth
        fullWidth={true}
        onExiting={() => updateResellerSuccess && refreshList()} // Update the list when we are exiting
        onExited={() => clearUpdateReseller()}
        onEnter={() => this.handleOnEnter()}
        maxWidth="lg"
        open={open}
        onClose={() => this.handleOnClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"><ResellerIcon style={{ position: "relative", top: 4, paddingRight: 10 }} fontSize="medium" />{currentReseller.name}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginTop: 20 }} id="alert-dialog-description">
            Creation Date: {currentReseller.createdAt ? moment(currentReseller.createdAt).format("LLL") : "Unknown"}
          </DialogContentText>

          {/* Error feedback */}
          {updateResellerSuccess && updateResellerSuccess.id && <Alert style={{ marginTop: 12, marginBottom: 16 }} severity="success">Successfully updated the partner!</Alert>}
          {updateResellerError && <Alert style={{ marginTop: 12, marginBottom: 16 }} severity="error">{`Error updating the partner. The partner ABN or name may already exist`}</Alert>}

          {/* Reseller input fields */}
          <Grid container spacing={3}>

            <Grid item xs={6}>
              <TextField error={this.state.showNameError} value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} placeholder={"Company Name"} fullWidth variant="outlined" id="reseller-name" label="Reseller Name" />
            </Grid>

            <Grid item xs={6}>
              <TextField error={this.state.showEmailError} value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} fullWidth placeholder={"reception@company.com.au"} variant="outlined" id="reseller-email" label="Email Address" />
            </Grid>

            <Grid item xs={6}>
              <TextField error={this.state.showContactNumberError} value={this.state.contactNumber} onChange={(e) => this.setState({ contactNumber: e.target.value })} fullWidth placeholder={"(08) XXXX-XXXX"} variant="outlined" id="reseller-contact" label="Contact Number" />
            </Grid>

            <Grid item xs={6}>
              <TextField
                type="number"
                error={this.state.showAbnError}
                value={this.state.abn}
                onChange={(e) => this.setState({ abn: e.target.value })}
                fullWidth
                placeholder={"Business ABN"}
                variant="outlined" id="reseller-abn" label="ABN" />
            </Grid>

            <Grid item xs={12}>
              <TextField error={this.state.showAddressError} value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })} fullWidth placeholder="123 Main Street Perth 6000 Western Australia" variant="outlined" id="reseller-address" label="Address" />
            </Grid>

          </Grid>

          {/* Context title */}
          <Grid style={{ marginTop: 30, paddingBottom: 6 }} container >
            {/* Households title */}
            <Typography style={{ paddingTop: 4, flex: 1 }} variant="h3" component="h3">Linked Households ({resellerHouseholds && resellerHouseholds.length})</Typography>

            <Button
              onClick={() => this.setState({ showLinkingModal: true })}
              color="primary"
              style={styles.linkButton}
              variant="contained"
              startIcon={<LinkIcon />}>
              Link a household to this partner
            </Button>

          </Grid>

          {/* Households Table Section */}

          {/* Render a spinner if we are loading */}
          {isGettingHouseholds ? <Grid style={{ paddingTop: 12 }} container justifyContent="center"><CircularProgress /></Grid> :
            !isGettingHouseholds && resellerHouseholds && resellerHouseholds.length > 0 ?

              // The table
              <HouseholdsTable
                sortDirectionStartDate={this.state.sortDirectionStartDate}
                sortDirectionEndDate={this.state.sortDirectionEndDate}
                sortByValue={this.state.sortByValue}
                sortDirection={this.state.sortDirection}
                changeSortOrder={(sortByValue) => this.handleUpdateSortOrder(sortByValue)}
                resellerId={currentReseller.id}
                households={resellerHouseholds || []} /> :

              // If there is no data then render the add button
              <Grid container justifyContent="center" style={{ padding: 30 }}>
                <Typography variant="h3" component="h3">No households assigned to partner</Typography>
              </Grid>
          }

          {/* Modals */}
          <ConfirmDeleteModal
            clearDeleteReseller={clearDeleteReseller}
            deleteResellerError={deleteResellerError}
            isDeletingReseller={isDeletingReseller}
            deleteResellerResult={deleteResellerResult}
            onConfirmAction={() => deleteReseller({ resellerId: currentReseller.id })}
            open={this.state.showConfirmDeleteModal}
            onClose={() => this.setState({ showConfirmDeleteModal: false })} />

          <LinkHouseholdModal
            resellerId={currentReseller.id}
            open={this.state.showLinkingModal}
            onClose={() => this.setState({ showLinkingModal: false })}
          />

        </DialogContent>
        {/* Action buttons */}
        <DialogActions style={{ padding: 22 }}>
          <Button variant="contained" startIcon={<DeleteIcon />} style={{ marginStart: 12, color: "#e74c3c" }} onClick={() => this.setState({ showConfirmDeleteModal: true })} >
            Delete
          </Button>
          <div style={{ flex: '1 0 0' }} />
          <Button variant="outlined" onClick={() => this.handleOnClose()} color="primary">
            Close
          </Button>
          <Button startIcon={<SaveIcon />} onClick={() => this.handleUpdateReseller()} variant="contained" color="primary" autoFocus>
            {isUpdatingReseller ? "Updating..." : "Update"}
          </Button>
        </DialogActions>
      </Dialog >
    )
  }
}

const styles = {
  linkButton: {
    backgroundColor: "#E91E63",
  },
}

ViewResellerModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  currentReseller: PropTypes.object,
  getHouseholdsByResellerId: PropTypes.func,
  isGettingHouseholds: PropTypes.bool,
  resellerHouseholds: PropTypes.array,
  updateReseller: PropTypes.func,
  updateResellerSuccess: PropTypes.object || PropTypes.string,
  updateResellerError: PropTypes.object || PropTypes.string,
  isUpdatingReseller: PropTypes.bool,
  clearUpdateReseller: PropTypes.func,
  refreshList: PropTypes.func,
  getHouseholds: PropTypes.func,
  linkHouseholdToReseller: PropTypes.func,
  deleteReseller: PropTypes.func,
  isDeletingReseller: PropTypes.bool,
  deleteResellerResult: PropTypes.object || PropTypes.string,
  deleteResellerError: PropTypes.object || PropTypes.string,
  clearDeleteReseller: PropTypes.func,
}



