import { Builder } from "./ApiBuilder";
import { IBehaviourData } from '@intelicare/shared/interfaces/behaviours/Behaviour'
export class SettingsApi extends Builder
{
  constructor(public token: string)
  {
    super(true);
  }

  updateBehaviour = async (householdId: string, behaviour: IBehaviourData): Promise<IBehaviourData[] | undefined> =>
  {
    const response = await this.call("POST", `/api/v2/household-settings/${householdId}?updateBehaviours=true`, behaviour, this.token)
    if(response) return response.data
  }

  getBehaviours = async (householdId: string, query: string): Promise<IBehaviourData[] | undefined> =>
  {
    const response = await this.call("GET", `/api/v2/household-settings/${householdId}?${query}=true`, null, this.token)
    if(response) return response.data
  }

  getByZone = async (householdId: string, zoneId: string): Promise<IBehaviourData[] | undefined> =>
  {
    const response = await this.call("GET", `/api/v2/household-settings/${householdId}?getByZone=true&zoneId=${zoneId}`, null, this.token)
    if(response) return response.data
  }
}