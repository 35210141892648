export default theme => ({
  BarSpacing: {
    left: `clamp(200px,20vw,310px)`,
    right: 0,
  },
  FixWidth: {
    width: 'unset',
  },
  ExpandMenu: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    marginRight: 16,
  },
  Avatar: {
    width: 48,
    height: 48,
  },
  Title: {
    marginLeft: '16px',
    fontSize: '24px',
    color: theme.palette.primary.contrastText,
    flexGrow: 1,
  },
  Badge: {
    backgroundColor: 'white',
    color: theme.palette.secondary.main,
    marginTop: '0.25em',
  },
  AlertButton: {
    paddingLeft: '0.25em',
  },
  ContentShift: {
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    left: 0,
  },
})
