import React from 'react'
import Skeleton from '@mui/material/Skeleton';

const items = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
export const LoadingOrgsList = () =>
{
  return (
    <>
      {items.map((item, key) => {
        return(
          <div key={key} style={styles.container}>
            <Skeleton variant="circular" height={60} width={60} style={styles.circle}/>
            <Skeleton variant="rectangular" height={60} style={styles.text}/>
          </div>
        )
      })}
    </>

  )
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    marginTop: 12,
    marginBottom: 12,
  },
  circle: {
    marginRight: 12,
  },
  text: {
    flex: 1,
  },
}