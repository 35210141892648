// /* eslint-disable no-case-declarations */
export const SYNC_ALEXA_ACCOUNT_BEGIN = "SYNC_ALEXA_ACCOUNT_BEGIN"
export const SYNC_ALEXA_ACCOUNT_SUCCESS = "SYNC_ALEXA_ACCOUNT_SUCCESS"
export const SYNC_ALEXA_ACCOUNT_ERROR= "SYNC_ALEXA_ACCOUNT_ERROR"
export const CLEAR_SYNC_ALEXA_ACCOUNT= "CLEAR_SYNC_ALEXA_ACCOUNT"

const initialState = {
  isSyncingAlexaData: false,
  syncAlexaResult: "",
  syncAlexaError: { message: "" },
}

const reducer = (state = initialState, action) =>
{

  switch(action.type)
  {
    case SYNC_ALEXA_ACCOUNT_BEGIN:
      return {
        ...state,
        isSyncingAlexaData: true,
      }

    case SYNC_ALEXA_ACCOUNT_SUCCESS:
      return {
        ...state,
        isSyncingAlexaData: false,
        syncAlexaResult: action.data,
        syncAlexaError: { message: "" },
      }

    case SYNC_ALEXA_ACCOUNT_ERROR:
      return {
        ...state,
        isSyncingAlexaData: false,
        syncAlexaResult: "",
        syncAlexaError: { message: action.error.message },
      }

    case CLEAR_SYNC_ALEXA_ACCOUNT:
      return {
        ...state,
        isSyncingAlexaData: false,
        syncAlexaResult: "",
        syncAlexaError: { message: "" },
      }

    default:
      return state
  }
}

export default reducer