import { connect } from 'react-redux'
import RemoteCommandsView from '../components/RemoteCommandsView'
import { compose } from 'redux'
import { withErrorHandler, Fallback } from '../../../../hoc/withErrorHandler'
import sharedStore from '../../../../redux'

const { callAPI } = sharedStore.userStore
const {
  GATEWAY_REMOTE_SERVICE,
  GATEWAY_FIRMWARE_DATA,
} = sharedStore.apiEndpoints

const mapDispatchToProps = (dispatch) => ({
  executeService: (gatewayId, operation) => {
    dispatch(callAPI(GATEWAY_REMOTE_SERVICE({ gatewayId: gatewayId, body: operation })))
  },
  fetchFirmwareData: () => {
    dispatch(callAPI(GATEWAY_FIRMWARE_DATA()))
  },
})

const mapStateToProps = (state) => ({
  remoteResult: state.households.gatewayRemoteService,
  isFetchingGateways: state.households.gatewayFirmware.isFetching,
  gateways: state.households.gatewayFirmware.gateways,
})


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorHandler(Fallback)
)(RemoteCommandsView)
