import { connect } from 'react-redux'
import NotificationsView from '../components/NotificationsView'
import { compose } from 'redux'
import { withErrorHandler, Fallback } from '../../../hoc/withErrorHandler'
import { withDataGetter } from '../../../hoc/withData'

import sharedStore from '../../../redux'

const householdSummaryStatus = sharedStore.householdsSelector.householdSummaryStatus
const getHouseholds = sharedStore.householdsSelector.getHouseholds
const viewMessage = sharedStore.householdsStore.viewMessage
const GET_ALL_NOTIFICATIONS = sharedStore.apiEndpoints.GET_ALL_NOTIFICATIONS
const callAPI = sharedStore.userStore.callAPI


const mapDispatchToProps = (dispatch) => {
  return {
    viewMessage: (notification) => {
      dispatch(viewMessage(notification))
    },
    getNotifications: (params) => {
      dispatch(callAPI(GET_ALL_NOTIFICATIONS(params)))
    },
    clearNotificationsForHousehold: () => dispatch({ type: "CLEAR_GET_NOTIFICATIONS_HOUSEHOLD" }),
  }
}

const mapStateToProps = (state) => ({
  status: householdSummaryStatus(state),
  notifications: state.notifications.notifications,
  households: getHouseholds(state),
  isDeveloper: state.user.isDeveloper,
  isFetching: state.notifications.isFetching,
  messageResolving: state.notifications.messageSending,
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorHandler(Fallback),
  withDataGetter,
)(NotificationsView)

