import React, { useEffect, useState } from 'react'

// Material UI Components
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Select from '@material-ui/core/Select'
import InputAdornment from '@material-ui/core/InputAdornment'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


// Icons
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import InteliIcon from '../../InteliIcon';

// Redux
import { GET_HOUSEHOLD_ACCESS_AUDIT_LOGS } from '../../../redux/shared/services/APIEndpoints';
import { callAPI } from '../../../redux/domains/user/userStore';
import { connect } from 'react-redux';


// Utils
import { CSVLink } from 'react-csv'
import moment from 'moment'


const DEFAULT_DAYS_TO_FETCH = 30;

interface AuditRecordDocument
{
  id?: string;
  docType: 'audit';
  eventTimestamp: string;
  userId: string;
  userLogin?: string;
  description: string;
  householdId: string;
  householdFriendlyName: string;
  givenName: string; // For export
  surname: string; // For export
  email: string; // For export
  userDetails: {
    givenName: string;
    surname: string;
    email: string;
  };
}

interface AuditRecordProps
{
  auditRecords: AuditRecordDocument[];
  isLoading: boolean;
  householdId: string;
  getAuditRecords: (householdId: string, timeRange: string) => void;
}



const AuditRecords = ({ auditRecords, isLoading, getAuditRecords, householdId }: AuditRecordProps) =>
{

  const [daysToFetch, setDaysToFetch ] = useState(DEFAULT_DAYS_TO_FETCH)

  useEffect(() => {

    // on mount get the last 30 days
    handleGetAuditRecords(daysToFetch)

  }, []);

  const handleGetAuditRecords = (days: number) =>
  {
    // Run the API call
    getAuditRecords(householdId, moment().subtract(days, 'days').toISOString())
    // Update state so the select input changes
    setDaysToFetch(days)
  }

  const formatExportData = (auditRecords: AuditRecordDocument[]) =>
  {
    return auditRecords.map(record => {

      return {
        eventTimestamp: moment(record.eventTimestamp).format("DD/MM/YYYY hh:mmAZ"),
        householdFriendlyName: record.householdFriendlyName,
        email: record.userDetails.email,
        givenName: record.userDetails.givenName,
        surname: record.userDetails.surname,
      }
    })
  }


  return(
    <div style={styles.container}>

      <Select
        fullWidth
        startAdornment={
          <InputAdornment position="start">
            <CSVLink data={formatExportData(auditRecords)} filename={`AuditRecordsExport-${new Date().toISOString()}.csv`} >
              <Tooltip title="Export to CSV">
                <IconButton>
                  <SaveAltIcon style={{ color: 'grey' }}/>
                </IconButton>
              </Tooltip>
            </CSVLink>
          </InputAdornment> }
        onChange={(e) => handleGetAuditRecords(Number(e.target.value))}
        variant="outlined"

        value={daysToFetch}>
        <MenuItem value={DEFAULT_DAYS_TO_FETCH}>Last 30 days</MenuItem>
        <MenuItem value={60}>Last 2 months</MenuItem>
        <MenuItem value={90}>Last 3 months</MenuItem>

      </Select>


      {!isLoading && auditRecords.length > 0 ? <TableContainer component={Paper} style={styles.table}>
        <Table aria-label="audit record table">
          <TableHead>
            <TableRow>
              <TableCell>Household Friendly Name</TableCell>
              <TableCell align="right">Description</TableCell>
              <TableCell align="right">User</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Access Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {auditRecords && auditRecords.length > 0 && auditRecords.map((record) => (
              <TableRow hover key={record.id}>
                <TableCell component="th" scope="row">
                  {record.householdFriendlyName}
                </TableCell>
                <TableCell align="right">{record.description}</TableCell>
                <TableCell align="right">{`${record.userDetails.givenName} ${record.userDetails.surname}`}</TableCell>
                <TableCell align="right">{record.userDetails.email}</TableCell>
                <TableCell align="right">
                  <Tooltip title={moment(record.eventTimestamp).fromNow()}>
                    <div>{moment(record.eventTimestamp).format("DD/MM/YYYY hh:mmAZ")}</div>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )) }
          </TableBody>
        </Table>
      </TableContainer> :
        auditRecords.length === 0 && !isLoading ?

          <div style={styles.noRecords}>
            <InteliIcon name="security" size={64} color="grey" style={styles.icon} />
            <Typography variant="h5" color="textSecondary">No audit records for this household</Typography>
          </div> :

          <div style={styles.loading}>
            <CircularProgress color="secondary" />
          </div> }


    </div>
  )
}

const styles = {
  container: {
    margin: 24,
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
  },
  noRecords: {
    justifyContent: 'center' as 'center',
    alignItems: 'center',
    flex: 1,
    flexDirection: 'column' as 'column',
    display: 'flex' as 'flex',
  },
  icon: {
    margin: 24,
  },
  loading: {
    padding: 100,
  },
  table: {
    marginTop: 12,
  },

}

const mapStateToProps = (state: any) =>
{
  return {
    auditRecords: state.householdsV2.householdAuditRecords,
    isLoading: state.householdsV2.isGettingAuditRecords,
    householdId: state.householdsV2.activeHouseholdId,
  }
}

const mapDispatchToProps = (dispatch: any) =>
{
  return {
    getAuditRecords: (householdId: string, timeRange: string) => dispatch(callAPI(GET_HOUSEHOLD_ACCESS_AUDIT_LOGS(householdId, timeRange))),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditRecords)