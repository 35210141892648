import { ACTIVITY_LEVELS, HEAR_RATE_LEVELS, RESPIRATION_LEVELS, STATUS_COLORS, VITAL_TYPES } from "../enums";

export const convertStrToInt = (value: string, valueToReplace: string): number | string =>
{
  try
  {
    return Number(value.replace(valueToReplace, ""))
  }
  catch(error)
  {
    return value;
  }
}



export const getBackgroundFromStatus = (type: string, value: string | number | JSX.Element) =>
{
  try
  {
    if(type === 'motion' || type === 'door') return STATUS_COLORS.NORMAL
    if(typeof value === "string" && value.includes("No")) return STATUS_COLORS.UNKNOWN

    if(type === VITAL_TYPES.HEART_RATE && typeof value === 'string')
    {
      const heartRate = convertStrToInt(value, 'bpm');
      if(heartRate >= HEAR_RATE_LEVELS.HIGH) return STATUS_COLORS.WARNING
      if(heartRate < HEAR_RATE_LEVELS.LOW) return STATUS_COLORS.WARNING
      return STATUS_COLORS.OK
    }

    if(type === VITAL_TYPES.RESPIRATION && typeof value === 'string')
    {
      const respirationRate = convertStrToInt(value, 'rpm');
      if(respirationRate > RESPIRATION_LEVELS.HIGH) return STATUS_COLORS.WARNING
      if(respirationRate < RESPIRATION_LEVELS.LOW) return STATUS_COLORS.WARNING
      return STATUS_COLORS.OK
    }

    if(type === VITAL_TYPES.ACTIVITY && typeof value === 'string')
    {
      const activityLevel = convertStrToInt(value, '/4');
      if(activityLevel < ACTIVITY_LEVELS.LOW) return STATUS_COLORS.CHECK
      if(activityLevel > ACTIVITY_LEVELS.HIGH) return STATUS_COLORS.CHECK
      return STATUS_COLORS.NORMAL
    }

  }
  catch(error)
  {
    return STATUS_COLORS.UNKNOWN
  }
}

export const getValue = (value: string | number | JSX.Element, type: string) =>
{
  try
  {
    if(type === VITAL_TYPES.ACTIVITY && typeof value === "string")
    {
      const activityLevel = convertStrToInt(value, '/4');
      if(activityLevel === 1) return "LOW"
      if(activityLevel === 2) return "NORMAL"
      if(activityLevel === 1) return "MODERATE"
      if(activityLevel === 1) return "HIGH"
      return value;
    }
    return value;
  }
  catch(e)
  {
    return value;
  }
}

export const getIconForType = (type: string) =>
{
  if(type === "heartrate") return 'favorite'
  if(type === "activitylevel") return 'accessibility_new'
  if(type === "respiration") return 'self_improvement'
  if(type === "distance") return 'social_distance'
  if(type === "motion") return 'directions_walk'
  if(type === "door") return 'sensor_door'

  return 'home'
}