import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Material UI components
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import DialogContentText from '@material-ui/core/DialogContentText'
import Alert from '@material-ui/lab/Alert'


// Redux
import redux from '../../../../redux'

const callAPI = redux.userStore.callAPI

const { LINK_INVOICE_TO_HOUSEHOLD } = redux.apiEndpoints

const LinkInvoiceModal = (
  {
    open,
    onClose,
    householdId,
    householdName,
    linkInvoiceToHousehold,
    clearLinkInvoiceResult,
    linkInvoiceSuccess,
    linkInvoiceError,
    isLinkingInvoice,
    invoice,
  }) => {

  LinkInvoiceModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    householdId: PropTypes.string,
    householdName: PropTypes.string,
    linkInvoiceToHousehold: PropTypes.func,
    clearLinkInvoiceResult: PropTypes.func,
    isLinkingInvoice: PropTypes.bool,
    linkInvoiceError: PropTypes.string || PropTypes.object,
    linkInvoiceSuccess: PropTypes.string || PropTypes.object,
    invoice: PropTypes.string,
  }

  const [invoiceURL, setInvoiceURL] = useState(invoice)

  return (
    <Dialog
      onExit={() => clearLinkInvoiceResult()}
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Link Invoice to Xero"}</DialogTitle>
      <DialogContent>

        <DialogContentText style={{ paddingBottom: 20 }}>
          Please enter the Xero invoice link below to link an invoice to household {householdName}
        </DialogContentText>

        <TextField
          fullWidth
          autoFocus
          placeholder="Invoice URL"
          label="Invoice URL"
          value={invoiceURL}
          onChange={(e) => setInvoiceURL(e.target.value)}
          variant="outlined">
        </TextField>

        {linkInvoiceSuccess && linkInvoiceSuccess.billing.invoice === invoiceURL && <Alert style={{ marginTop: 12, marginBottom: 16 }} severity="success">Successfully added the invoice link</Alert>}
        {linkInvoiceError && <Alert style={{ marginTop: 12, marginBottom: 16 }} severity="error">Error adding the invoice link</Alert>}

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Close
        </Button>
        <Button onClick={() => linkInvoiceToHousehold({ invoice: invoiceURL, householdId: householdId })} color="primary" autoFocus>
          {isLinkingInvoice ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog >
  )
}

const mapStateToProps = state => {
  return {
    isLinkingInvoice: state.householdsV2.isLinkingInvoice,
    linkInvoiceSuccess: state.householdsV2.linkInvoiceSuccess,
    linkInvoiceError: state.householdsV2.linkInvoiceError,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    linkInvoiceToHousehold: (params) => dispatch(callAPI(LINK_INVOICE_TO_HOUSEHOLD(params))),
    clearLinkInvoiceResult: () => dispatch({ type: "CLEAR_LINK_INVOICE_TO_HOUSEHOLD" }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkInvoiceModal)