import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'


function* logoutFromAzureAndSendLogoutAction() {
  try {
    yield call(window.logout)

    yield put({ type: "LOGOUT" })
  } catch (e) {
    // eslint-disable-next-line no-undef,no-console
    process.env.NODE_ENV && console.error(e)
    window.alert('Sorry, failed to log you out. Please try again.')
  }
}

/*
  Starts loginAndGetUsers on each dispatched `LOGOUT_START` action.
  Allows concurrent fetches.
*/
function* loginSaga() {
  yield takeEvery("LOGOUT_START", logoutFromAzureAndSendLogoutAction)
}

export default loginSaga
