import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { SensorParameter } from '../../../../model/SensorParameter'
import AddIcon from '@mui/icons-material/Add'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { SensorModalParametersTable } from './SensorModalParametersTable'
import { SensorConfiguration, ISensorConfiguration } from '../../../../model/SensorConfiguration'
import { SubSensor, ISubSensor } from '../../../../model/SubSensor'
import { InstallerStep } from '../../../../model/InstallerStep'
import { InstallerStepsTable } from './InstallerStepsTable'
import InteliIcon from '../../../../components/InteliIcon'
import { SensorApi } from '../../../../api/SensorApi';
//@ts-ignore
import { Creatable } from 'react-select'
import { SubSensorsTable } from './SubSensorsTable'

const SNACK_AUTO_HIDE_DURATION = 10000
interface SensorModalProps
{
  open: boolean;
  onClose: () => void;
  title: string;
  currentSensor: ISensorConfiguration | undefined;
  token: string;
}

interface SensorModalState
{
  productName: string;
  deviceIds: string[];
  subSensors: ISubSensor[];
  manufacturer: string,
  parameters: SensorParameter[];
  isDeleting: boolean;
  isSaving: boolean;
  installerSteps: InstallerStep[];
  iconImageName: string;
  showSnack: boolean;
  alertSeverity: "error" | "warning" | "info" | "success";
  alertMessage: string;
  id: string;
  _etag: string;
  saved: boolean;
  deleted: boolean;
}

export class SensorModal extends Component<SensorModalProps, SensorModalState>
{
  constructor(props: SensorModalProps)
  {
    super(props)
    this.state = {
      productName: "",
      deviceIds: [],
      manufacturer: "",
      parameters: [],
      installerSteps: [],
      iconImageName: "",
      isSaving: false,
      isDeleting: false,
      showSnack: false,
      alertSeverity: "success",
      alertMessage: "",
      id: "",
      _etag: "",
      saved: false,
      deleted: false,
      subSensors: [],
    }
  }

  handleAddParameter = () =>
  {
    const { parameters } = this.state
    const parameter = new SensorParameter("", "", "", "")
    this.setState({ parameters: [ ...parameters, parameter ] })
  }

  handleDeleteParameter = (id: string) =>
  {
    const { parameters } = this.state
    const updatedParametersList = parameters.filter(p => p.id !== id)
    this.setState({ parameters: updatedParametersList })
  }

  handleChange = (id: string, columnName: string, value: string | number) =>
  {

    const { parameters } = this.state

    const updated = parameters.map(p => {
      if(p.id === id)
      {
        //@ts-ignore
        p[columnName] = value;
      }

      return p;

    })

    this.setState({ parameters: updated })

  }

  handleSave = async () =>
  {
    const { productName, deviceIds, subSensors, manufacturer, parameters, installerSteps, iconImageName, id, _etag } = this.state;
    const { token } = this.props;

    // Update
    if(id && _etag)
    {
      const sensorConfiguration = new SensorConfiguration(_etag, id, iconImageName, productName, deviceIds, subSensors, manufacturer, parameters, installerSteps)
      const sensorApi = new SensorApi(token);
      this.setState({ isSaving: true })
      const addResponse = await sensorApi.updateSensor(sensorConfiguration)
      // Update Sensor Response
      if(addResponse && addResponse.id) return this.setState({ isSaving: false, showSnack: true, alertSeverity: "success", alertMessage: "Success, updated sensor OK" })
      // Error case
      if(addResponse && addResponse.error) return this.setState({ isSaving: false, showSnack: true, alertSeverity: "error", alertMessage: addResponse.error })
    }

    // New sensor
    if(!id && productName && deviceIds && manufacturer)
    {
      const sensorConfiguration = new SensorConfiguration("", "", iconImageName, productName, deviceIds, subSensors, manufacturer, parameters, installerSteps)
      const sensorApi = new SensorApi(token);
      this.setState({ isSaving: true })
      const addResponse = await sensorApi.addSensor(sensorConfiguration)
      // New Sensor
      if(addResponse && addResponse.id) return this.setState({ saved: true, isSaving: false, showSnack: true, alertSeverity: "success", alertMessage: "Success, added new sensor OK" })
      // Error case
      if(addResponse && addResponse.error) return this.setState({ saved: false, isSaving: false, showSnack: true, alertSeverity: "error", alertMessage: addResponse.error })
    }

    // Catch errors
    this.setState({ isSaving: false, showSnack: true, alertSeverity: "error", alertMessage: "Error saving or updating sensor" })
  }

  handleOnEnter = () =>
  {
    const { currentSensor } = this.props

    if(currentSensor)
    {
      this.setState({
        _etag: currentSensor._etag,
        id: currentSensor.id,
        productName: currentSensor.productName,
        manufacturer: currentSensor.manufacturer,
        deviceIds: currentSensor.deviceIds,
        subSensors: currentSensor.subSensors || [],
        parameters: currentSensor.parameters,
        installerSteps: currentSensor.installerSteps,
        iconImageName: currentSensor.iconImageName,
      })
    }
  }

  handleDeleteInstallerStep = (id: number) =>
  {
    const { installerSteps } = this.state
    const updatedInstallerSteps = installerSteps.filter(s => s.id !== id)
    this.setState({ installerSteps: updatedInstallerSteps })
  }

  handleChangeInstallerStep = (stepId: number, columnName: string, value: string) =>
  {
    const { installerSteps } = this.state

    const updated = installerSteps.map(s => {
      if(s.id === stepId)
      {
        //@ts-ignore
        s[columnName] = value;
      }

      return s;

    })

    this.setState({ installerSteps: updated })
  }

  handleAddInstallerStep = () =>
  {
    const { installerSteps } = this.state
    const installerStep = new InstallerStep(installerSteps.length + 1, "", "")
    this.setState({ installerSteps: [ ...installerSteps, installerStep ] })

  }

  handleAddSubSensor = () =>
  {
    const { subSensors } = this.state
    const subSensor = new SubSensor("", "", "", "")
    this.setState({ subSensors: [ ...subSensors, subSensor ] })

  }

  handleIconUpdate = (iconName: string) =>
  {
    this.setState({ iconImageName: iconName })
  }

  handleDeleteSensor = async () =>
  {
    const { id } = this.state;
    const { token } = this.props
    this.setState({ isDeleting: true })
    const sensorApi = new SensorApi(token);
    const deleteResult = await sensorApi.deleteSensor(id);
    if(deleteResult && deleteResult.result) return this.setState({ deleted: true, isDeleting: false, showSnack: true, alertSeverity: "success", alertMessage: deleteResult.result })
    this.setState({ deleted: false, isDeleting: false, showSnack: true, alertSeverity: "error", alertMessage: "Error deleting sensor" })
  }

  handleSubSensorChange = (id: string, columnName: string, value: string) =>
  {
    const { subSensors } = this.state

    const updated = subSensors.map(s => {
      if(s.id === id)
      {
        //@ts-ignore
        s[columnName] = value;
      }

      return s;

    })

    this.setState({ subSensors: updated })
  }

  handleDeleteSubSensor = (id: string) =>
  {
    const { subSensors } = this.state
    const updatedInstallerSteps = subSensors.filter(s => s.id !== id)
    this.setState({ subSensors: updatedInstallerSteps })
  }

  render()
  {
    const { open, onClose, title } = this.props
    const { parameters, subSensors, productName, manufacturer, deviceIds, installerSteps, iconImageName, isSaving, isDeleting, showSnack, alertSeverity, alertMessage, saved, deleted } = this.state

    return(
      <Dialog
        onEnter={this.handleOnEnter}
        fullWidth
        maxWidth="md"
        open={open}
        onExit={() => this.setState(
          { deleted: false,
            saved: false,
            _etag: "",
            id: "",
            iconImageName: "",
            productName: "",
            manufacturer: "",
            deviceIds: [],
            subSensors: [],
            parameters: [],
            installerSteps: [],
            isDeleting: false,
            isSaving: false })}
        onClose={() => onClose()}>


        <DialogTitle>{title}</DialogTitle>

        <DialogContent>

          <div style={styles.iconContainer}>
            { iconImageName && <InteliIcon style={styles.iconStyle} size={128} name={iconImageName} family="community" /> }
          </div>

          <div style={styles.productTextInput}>
            <TextField value={productName} onChange={(e) => this.setState({ productName: e.target.value })} style={styles.textEntry} fullWidth variant="outlined" label="Product Name" placeholder="Product Name"></TextField>
            <TextField value={iconImageName} onChange={(e) => this.handleIconUpdate(e.target.value)} style={styles.textEntry} fullWidth variant="outlined" label="Icon Image" placeholder="Icon Image Name"></TextField>
            <TextField value={manufacturer} onChange={(e) => this.setState({ manufacturer: e.target.value })} style={styles.textEntry} fullWidth variant="outlined" label="Manufacturer" placeholder="Manufacturer"></TextField>

          </div>
          <div style={styles.deviceIds}>

            <div style={{ paddingBottom: 12 }}>
              <Typography color="textSecondary" variant="inherit" >Device IDs</Typography>
              <Creatable
                value={deviceIds && deviceIds.length > 0 ? deviceIds.map(id => { return { label: id, value: id } } ) : []}
                placeholder="Device IDs"
                onChange={(deviceIds: any) => this.setState({ deviceIds: deviceIds.map((item: any) => item.value) })}
                isMulti
                options={deviceIds && deviceIds.length > 0 ? deviceIds.map(id => { return { label: id, value: id } } ) : []}
              />
            </div>
          </div>

          {/* Sub Sensors */}
          <div style={styles.tableContainer}>
            <Typography style={{ ...styles.textEntry, flex: 1 }} color="textSecondary" variant="h6">Sub Sensors</Typography>
            <IconButton onClick={this.handleAddSubSensor} color="secondary" >
              <AddIcon />
            </IconButton>
          </div>

          <SubSensorsTable
            handleTextFieldChange={(id, columnName, value) => this.handleSubSensorChange(id, columnName, value)}
            onDelete={this.handleDeleteSubSensor}
            subSensors={subSensors}
          />


          {/* Configuration Parameters */}
          <div style={styles.tableContainer}>
            <Typography style={{ ...styles.textEntry, flex: 1 }} color="textSecondary" variant="h6">Configuration Parameters</Typography>
            <IconButton onClick={this.handleAddParameter} color="secondary" >
              <AddIcon />
            </IconButton>
          </div>

          <SensorModalParametersTable
            handleTextFieldChange={(id, columnName, value) => this.handleChange(id, columnName, value)}
            onDelete={this.handleDeleteParameter}
            parameters={parameters}/>


          {/* Installer steps */}
          <div style={styles.tableContainer}>
            <Typography style={{ ...styles.textEntry, flex: 1 }} color="textSecondary" variant="h6">Installer Steps</Typography>
            <IconButton onClick={this.handleAddInstallerStep} color="secondary" >
              <AddIcon />
            </IconButton>
          </div>

          <InstallerStepsTable
            handleTextFieldChange={(id, columnName, value) => this.handleChangeInstallerStep(id, columnName, value)}
            onDelete={this.handleDeleteInstallerStep}
            installerSteps={installerSteps}/>

        </DialogContent>

        <DialogActions>
          <Button disabled={isDeleting || deleted} onClick={this.handleDeleteSensor} color="primary">
            {isDeleting ? "Deleting" : "Delete"}
          </Button>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
          <Button disabled={isSaving || saved} onClick={this.handleSave} color="primary">
            {isSaving ? "Saving" : "Save"}
          </Button>
        </DialogActions>

        <Snackbar open={showSnack} onClose={() => this.setState({ showSnack: false })} autoHideDuration={SNACK_AUTO_HIDE_DURATION}>
          <Alert variant="filled" severity={alertSeverity}>{alertMessage}</Alert>
        </Snackbar>

      </Dialog>
    )
  }
}

const styles = {
  tableContainer: {
    flexDirection: 'row' as 'row',
    display: 'flex' as 'flex',
    flex: 1,
    marginTop: 24,
  },
  textEntry: {
    margin: 6,
  },
  productTextInput: {
    flexDirection: 'row' as 'row',
    display: 'flex' as 'flex',
  },
  iconContainer: {
    justifyContent: 'center',
    display: 'flex',
  },
  iconStyle: {
    color: 'grey',
  },
  deviceIds: { fontFamily: "Roboto, sans-serif", padding: 6 },

}
