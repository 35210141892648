/*
 * Created for consistency with the web adal library.
 *
 * We don't use ADAL in the native client as there's no DOM to insert iframe's etc..
 *
*/

import jwtDecode from 'jwt-decode'

export const decodeCredentials = credentials => {
  var decodedAccessToken = jwtDecode(credentials.access_token)

  var details = Object.assign({}, {
    profile: {
      oid: decodedAccessToken.oid,
    },
    name: decodedAccessToken.name,
    decodedToken: decodedAccessToken,
    azureToken: credentials.access_token,
    refreshToken: credentials.refresh_token,
    token: "anyToken",
  })

  return details
}

export const generateLoginUri = () => {
  const refreshScope = `${process.env.REACT_APP_ADAL_CLIENT} offline_access`
  return `https://login.microsoftonline.com/${process.env.REACT_APP_AD_DOMAIN}/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_ADAL_CLIENT}&response_type=code&redirect_uri=${process.env.REACT_APP_AD_REDIRECT_URL}&response_mode=query&scope=${refreshScope}&p=${process.env.REACT_APP_ADAL_POLICY}`
}

export const generateLogoutUri = () => `https://login.microsoftonline.com/${process.env.REACT_APP_AD_DOMAIN}/oauth2/v2.0/logout?p=${process.env.REACT_APP_ADAL_POLICY}&post_logout_redirect_uri=${process.env.REACT_APP_AD_REDIRECT_URL}&client_id=${process.env.REACT_APP_ADAL_CLIENT}`


export const logout = () => {
  return new Promise((resolve, reject) => {
    const url = `https://login.microsoftonline.com/${process.env.REACT_APP_AD_DOMAIN}/oauth2/v2.0/logout?p=${process.env.REACT_APP_ADAL_POLICY}&post_logout_redirect_uri=${process.env.REACT_APP_AD_REDIRECT_URL}&client_id=${process.env.REACT_APP_ADAL_CLIENT}`
    fetch(url)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const refreshToken = (userDetails) => {
  return new Promise((resolve, reject) => {
    const refreshScope = `${process.env.REACT_APP_ADAL_CLIENT} offline_access`

    var data = new FormData()
    data.append("grant_type", 'refresh_token')
    data.append("client_id", process.env.REACT_APP_ADAL_CLIENT)
    data.append("refresh_token", userDetails.refreshToken)
    data.append("redirect_uri", process.env.REACT_APP_AD_REDIRECT_URL)
    data.append("scope", refreshScope)

    const options = {
      method: 'post',
      body: data,
    }

    const url = `https://login.microsoftonline.com/${process.env.REACT_APP_AD_DOMAIN}/oauth2/v2.0/token?p=${process.env.REACT_APP_ADAL_POLICY}`

    fetch(url, options)
      .then(response => {
        var resultJSON = response.json()
        return resultJSON
      })
      .then(result => {
        resolve(result)
      })
      .catch(err => {
        reject(err)
      })
  })
}
