/* eslint-disable indent */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from "moment"

// Material UI components
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'

// Custom components
import { Chart } from "react-google-charts"
import TimeScaleSelector from './TimeScaleSelector'
import SensorSelect from './SensorSelect'
import ExportData from './ExportData'

class RadarGraph extends Component {

  state = {
    timeRange: moment.utc().subtract(24, "hours").format("YYYY-MM-DD hh:mm:ss A"),
    defaultScale: "Last 24 Hours",
    momentFormatType: "hh:mm A",
    showingExport: false,
  }

  componentDidMount() {
    const { getRadarGraph, householdId } = this.props

    getRadarGraph({ householdId: householdId, sensorId: "", timeRange: moment.utc().subtract(24, "hours").format("YYYY-MM-DD hh:mm:ss A") })
  }

  handleGetRadarGraph = (timeRange, momentFormatType, selectedValue) => {
    const { getRadarGraph, householdId, currentSensor } = this.props
    this.setState({ defaultScale: selectedValue, momentFormatType: momentFormatType, timeRange: timeRange })
    getRadarGraph({ householdId: householdId, sensorId: currentSensor[0].id, timeRange: timeRange })
  }

  formatDateOnGraph = (radarGraphData, momentFormatType = this.state.momentFormatType) => {
    return radarGraphData.map((row, index) => {
      if (index === 0) return row // Return the header
      const formattedDate = moment(row[0]).format(momentFormatType)
      if (formattedDate !== "Invalid date") row[0] = formattedDate
      return row
    })
  }

  updateCurrentSensor = (sensor) => {
    const { getRadarGraph, householdId } = this.props
    getRadarGraph({ householdId: householdId, sensorId: sensor.id, timeRange: this.state.timeRange })
  }

  toggleExport = () => {
    this.setState((prevState) => ({ showingExport: !prevState.showingExport }))
  }

  render() {
    const { title, subtitle, radarGraphData, isFetchingRadarData, radarGraphGetError, currentSensor, otherRadarSensors } = this.props
    return (<div>
      {isFetchingRadarData ?
        <div style={{ textAlign: "center", padding: 200 }}><CircularProgress /></div> : !isFetchingRadarData && radarGraphData && radarGraphData.length > 1 ?
          <div><Chart
            width={'auto'}
            height={'400px'}
            chartType="Line"
            loader={<div><CircularProgress /></div>}
            data={radarGraphData && radarGraphData.length > 0 ? this.formatDateOnGraph(radarGraphData) : []}
            options={{
              chart: {
                title,
                subtitle,
              },
              async: true,
              tooltip: { isHtml: true },
              series: {
                // Gives each series an axis name that matches the Y-axis below.
                0: { axis: 'HeartRateAndRespiration' },
                1: { axis: 'HeartRateAndRespiration' },
                2: { axis: 'ActivityAndDistance' },
                3: { axis: 'ActivityAndDistance' },
              },
              axes: {
                // Adds labels to each axis; they don't have to match the axis names.
                y: {
                  HeartRateAndRespiration: { label: 'Heart Rate & Respiration' },
                  ActivityAndDistance: { label: 'Activity & Distance' },
                },
              },
            }}
            rootProps={{ 'data-testid': '3' }}
          />

          </div> : <div style={{ textAlign: "center", padding: 200 }}>
            <Typography variant="h4" componet="h4">{`We couldn't find any radar data for the given time range`}</Typography>
          </div>}

      <Grid container justify='space-between'>
        <Grid item>
          {!isFetchingRadarData && <SensorSelect
            currentItem={currentSensor}
            sensors={otherRadarSensors && currentSensor ? otherRadarSensors.concat(currentSensor) : currentSensor}
            onSelectChange={(sensor) => this.updateCurrentSensor(sensor)} />}
        </Grid>
        <Grid item>
          <TimeScaleSelector
            onItemClick={(timeRange, momentFormatType, selectedValue) => this.handleGetRadarGraph(timeRange, momentFormatType, selectedValue)}
            selectedValue={this.state.defaultScale}
          />
        </Grid>
        <Grid item>
          <Button variant='contained' color='secondary' size='small' disableElevation onClick={this.toggleExport}>Export Data</Button>
        </Grid>
      </Grid>
      <Collapse in={this.state.showingExport}>
        <ExportData
          export={this.props.exportRadarData}
          householdId={this.props.householdId}
          sensorId={this.props.currentSensor}
          isPolling={this.props.isPolling}
          exportUri={this.props.exportUri}
          showing={this.state.showingExport}
          />
      </Collapse>
    </div>)
  }
}

RadarGraph.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  radarGraphData: PropTypes.array,
  isFetchingRadarData: PropTypes.bool,
  radarGraphGetError: PropTypes.string,
  getRadarGraph: PropTypes.func,
  householdId: PropTypes.string,
  currentSensor: PropTypes.object,
  otherRadarSensors: PropTypes.array,
  exportRadarData: PropTypes.func,
  isPolling: PropTypes.bool,
  exportUri: PropTypes.string,
}

export default RadarGraph
