import React from 'react'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'


import PropTypes from 'prop-types'
import Alert from '@material-ui/lab/Alert'
import Typography from '@material-ui/core/Typography'

const operations = [
  {
    label: 'Open Remote Admin',
    data: { service: 'remoteAdmin', data: { command: 'open' } },
  },
  {
    label: 'Close Remote Admin',
    data: { service: 'remoteAdmin', data: { command: 'close' } },
  },
  {
    label: 'Reboot Gateway',
    data: { service: 'remoteAdmin', data: { command: 'reboot' } },
  },
  {
    label: 'Get Disk Usage',
    data: { service: 'remoteAdmin', data: { command: 'get_disk_usage' } },
  },
  {
    label: 'Get Date Time',
    data: { service: 'remoteAdmin', data: { command: 'get_date_time' } },
  },
  {
    label: 'Get WiFi Info',
    data: { service: 'remoteAdmin', data: { command: 'get_wifi_info' } },
  },
  {
    label: 'Get Network Info',
    data: { service: 'remoteAdmin', data: { command: 'get_network_info' } },
  },
  {
    label: 'Restart Doctor',
    data: { service: 'remoteAdmin', data: { command: 'restart_doctor' } },
  },
  {
    label: 'Restart Home Assistant',
    data: { service: 'remoteAdmin', data: { command: 'restart_home_assistant' } },
  },
  {
    label: 'Restart NTP (Resync Date)',
    data: { service: 'remoteAdmin', data: { command: 'restart_ntp' } },
  },
  {
    label: 'Get uptime and load',
    data: { service: 'remoteAdmin', data: { command: 'get_uptime' } },
  },
  {
    label: 'Run speed test',
    data: { service: 'remoteAdmin', data: { command: 'run_speed_test' } },
  },
  {
    label: 'Change firmware version',
    data: { service: 'remoteAdmin', data: { command: 'change_firmware_version', firmwareVersion: "" } },
  },
  {
    label: 'Get public IP',
    data: { service: 'remoteAdmin', data: { command: 'get_public_ip' } },
  },
  {
    label: 'Perform ping test',
    data: { service: 'remoteAdmin', data: { command: 'ping_google_public_dns' } },
  },
  {
    label: 'Get OZW logs',
    data: { service: 'remoteAdmin', data: { command: 'get_ozw_logs' } },
  },
]

export default class RemoteCommandsForm extends React.Component {

  state = {
    commandData: "",
  }


  handleCommandChange = (commandData) =>
  {
    const { onCommandChange } = this.props
    this.setState({ commandData: commandData })
    onCommandChange(commandData)
  }


  render() {

    const { remoteResult, remoteErrorResult } = this.props
    const { commandData } = this.state;

    return (
      <div className='households'>
        <div style={styles.menuContainer}>

          <div style={styles.operationContainer}>
            <Typography>Select Command</Typography>
            <Select fullWidth variant="outlined" onChange={(e) => this.handleCommandChange({ commandData: e.target.value })} >
              {
                operations.map((op, i) => <MenuItem key={`operation_${i}`} value={op}>{op.label}</MenuItem>)
              }
            </Select>

            {/* Only show the input for the firmware view */}
            {commandData && commandData.commandData && commandData.commandData.data && commandData.commandData.data.data.command === "change_firmware_version" &&
            <TextField
              onChange={(e) => this.handleCommandChange({ commandData:
                  { data:
                    { data: {
                      command: "change_firmware_version",
                      firmwareVersion: e.target.value,
                    },
                    service: "remoteAdmin",
                    },
                  label: "Change firmware version",
                  },
              })}
              placeholder="Enter firmware version: E.G YYYY-MM-DD" label="Firmware version">
            </TextField>}
          </div>

        </div>

        {remoteErrorResult && <Alert style={styles.alert} severity="error">{remoteErrorResult.message}</Alert>}
        {remoteResult && remoteResult.response && formatResponseMessage(remoteResult)}

      </div>
    )
  }
}

const formatResponseMessage = (remoteResult) => {
  try {
    if (remoteResult.response.message === "Tunnel successfully closed") return <Alert style={styles.alert} severity="success">{remoteResult.response.message}</Alert>
    if (remoteResult.response.message === "Tunnel successfully opened") return <Alert style={styles.alert} severity="success">{`${remoteResult.response.message} on TCP port: ${remoteResult.response.port}`}</Alert>
    if (remoteResult.response.message === "Host reboot initiated. Host will reboot in 5 seconds") return <Alert style={styles.alert} severity="success">{`Host reboot initiated. Host will reboot in 5 seconds`}</Alert>
    if(remoteResult.response.message != null) return <pre style={styles.commandOutput}>{remoteResult.response.message}</pre>

    return <Alert style={styles.alert} severity="error">{`Unknown response from command`}</Alert>

  }
  catch (error) {
    return <Alert style={styles.alert} severity="error">{`Error parsing response`}</Alert>
  }
}

const styles = {
  spinnerStyle: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: "20px",
  },
  gatewayContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px',
    width: '400px',
  },
  operationContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  responseBox: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px',
    width: '100%',
  },
  responseContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: "20px",
    paddingLeft: "10px",
    fontFamily: "Inconsolata sans-serif",
  },
  buttonContainer: {
    marginLeft: '10px',
  },
  output: {
    width: '90%',
    height: '400px',
  },
  dataStyle: {
    margin: '10px',
    color: 'limegreen',
  },
  alert: {
    marginTop: "12px",
  },
  commandOutput: {
    backgroundColor: "black",
    color: "#00C853",
    borderRadius: 12,
    margin: 6,
    padding: 12,
  },
}

RemoteCommandsForm.propTypes = {
  remoteResult: PropTypes.string,
  executeService: PropTypes.func,
  gatewayId: PropTypes.string,
  remoteErrorResult: PropTypes.object,
  onCommandChange: PropTypes.func,
}