import { connect } from 'react-redux'
import { compose } from 'redux'
import { withErrorHandler, Fallback } from '../../../../hoc/withErrorHandler'

import AppMenuView from './AppMenuView'

const mapDispatchToProps = (dispatch) => ({

})

const mapStateToProps = (state) => ({
  isDeveloper: state.user.isDeveloper || false,
  isSupport: state.user.isSupport || false,
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorHandler(Fallback)
)(AppMenuView)
