import { Component } from 'react'
import { withTitle } from '../../hoc/withTitle'
import PropTypes from 'prop-types'
import { withErrorHandler } from '../../hoc/withErrorHandler'
import Nothing from '../../components/Nothing'

class Title extends Component {

  componentDidMount() {
    this.props.setTitle(this.props.title, { iconLeft: this.props.iconLeft, statusOverride: this.props.statusOverride, pageButtons: this.props.pageButtons })
  }

  componentWillUnmount() {
    this.props.setTitle('', {}) // Remove the object to test error boundaries
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.props.currentTitle !== this.props.title)
  }

  componentDidUpdate() {
    this.props.setTitle(this.props.title, { iconLeft: this.props.iconLeft, statusOverride: this.props.statusOverride, pageButtons: this.props.pageButtons })
  }

  render() {
    return null
  }
}

Title.propTypes = {
  setTitle: PropTypes.func.isRequired,
  title: PropTypes.string,
  currentTitle: PropTypes.string,
  iconLeft: PropTypes.any,
  statusOverride: PropTypes.string,
  pageButtons: PropTypes.any,
}


export default withErrorHandler(Nothing)(withTitle(Title))
