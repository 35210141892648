import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import access from 'safe-access'

import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import InteliAvatar from '../../components/Utils/InteliAvatar'
import InteliIcon from '../../components/InteliIcon'

import styles from './SearchableTableStyles'

import { status } from '../../redux'

const {
  alertStatus,
  amberStatus,
  okayStatus,
} = status

export class SearchableTableRows extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filteredRows: props.rows || [],
      sortedColumns: props.columns,
    }
  }

  componentDidUpdate(lastProps) {
    const {
      searchString,
      rows,
      rowSorting,
      columns,
    } = this.props
    if (searchString !== lastProps.searchString || rows !== lastProps.rows || rowSorting !== lastProps.rowSorting) {
      const defaultRowSort = (row) => row
      var filteredRows = _(rows)
        .filter((row) => {
          return _.some(row, (colValue, colKey) => {
            if (typeof colValue === 'string' && typeof searchString === 'string') {
              return colValue.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 // and column value contains search string
            }
            return false
          })
        })
        .sortBy(rowSorting || defaultRowSort)
        .value()
      this.setState(state => ({ ...state, filteredRows: filteredRows }))
    }

    if (columns !== lastProps.columns) {
      var sortedColumns = _.sortBy(columns || [], 'sort')
      this.setState(state => ({ ...state, sortedColumns: sortedColumns }))
    }
  }

  render() {
    const {
      classes,
      noDataMessage,
    } = this.props

    const { filteredRows, sortedColumns } = this.state

    if (_.isEmpty(filteredRows)) {
      return (
        <div className={{}}>
          <p>{noDataMessage || 'No information available'}</p>
        </div>
      )
    }
    return (
      <Table>
        <TableHead>
          <TableRow>
            {sortedColumns.map((col, i) => {
              return (
                <TableCell key={'header' + col.value}>
                  {col.label}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredRows.map((row, i) => {
            return (
              <TableRow key={row.id}>
                {
                  sortedColumns.map((col, j) => {
                    return (
                      <TableCell key={`Column${i}_${j}`}>
                        {
                          this._renderColumn(classes, row, col, (row.numOfActiveAlerts > 0 ? alertStatus : (row.numOfActiveNotifications > 0 ? amberStatus : okayStatus)))
                        }
                      </TableCell>
                    )
                  })
                }
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }

  _renderColumn = (classes, row, col, status) => {
    let columnValue = access(row, col.value)
    if (col.type === 'actions') {
      return (
        <div>
          <Button onClick={() => this.props.onSelectRow(row)('VIEW')}>
            {`View ${col.value}`}
          </Button>
        </div>
      )
    } else if (col.type === 'image') {
      return (
        <InteliAvatar
          src={columnValue}
          maximise={true}
        />
      )
    } else if (col.type === 'icon') {
      return (
        <InteliIcon
          color={columnValue['color']}
          name={columnValue['name']}
          family={columnValue['family']}
          size={36}
        />
      )
    } else {
      return (
        <span style={status === alertStatus ? { color: 'white', fontSize: 14 } : { fontSize: 14 }}>
          {columnValue}
        </span>
      )
    }
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    status: PropTypes.string,
    searchString: PropTypes.string,
    rows: PropTypes.array,
    columns: PropTypes.array,
    noDataMessage: PropTypes.string,
    onSelectRow: PropTypes.func,
    rowSorting: PropTypes.func,
    selectableRow: PropTypes.bool,
    showNotificationsButton: PropTypes.bool,
    tiles: PropTypes.func,
  }
}


export default withStyles(styles)(SearchableTableRows)
