import { Gateway, Room } from "@intelicare/shared/interfaces/gateway/Gateway";
import { RoomZoneItem, ZoneApiResponseItem } from "@intelicare/shared/interfaces/household/Zone";
import { ZoneActions } from "./actions";
import { ISensor } from '@intelicare/shared/interfaces/household/ISensor';
import { Color } from "@material-ui/lab";

export const updateHasZonesState = (householdId: string, enabled: boolean) =>
{
  return {
    type: ZoneActions.UPDATE_HAS_ZONES_STATE,
    data: { householdId, enabled },
  }
}

export const getGatewayBegin = (gatewayId: string) =>
{
  return {
    type: ZoneActions.GET_GATEWAY_BEGIN,
    data: { gatewayId },
  }
}

export const getGatewaySuccess = (gateway: Gateway) =>
{
  return {
    type: ZoneActions.GET_GATEWAY_SUCCESS,
    data: gateway,
  }
}

export const createZoneBegin = (householdId: string, zone: ZoneApiResponseItem) =>
{
  return {
    type: ZoneActions.CREATE_ZONE_BEGIN,
    data: { householdId, zone },
  }
}

export const createZoneSuccess = (zone: ZoneApiResponseItem) =>
{
  return {
    type: ZoneActions.CREATE_ZONE_SUCCESS,
    data: zone,
  }
}

export const createZoneError = (error: any) =>
{
  return {
    type: ZoneActions.CREATE_ZONE_ERROR,
    error: error,
  }
}

export const clearAddZoneStatus = () =>
{
  return {
    type: ZoneActions.CLEAR_ADD_ZONE_STATUS,
  }
}

export const getZonesBegin = (householdId: string) =>
{
  return {
    type: ZoneActions.GET_ZONES_BEGIN,
    data: { householdId },
  }
}

export const getZonesSuccess = (zones: ZoneApiResponseItem[]) =>
{
  return {
    type: ZoneActions.GET_ZONES_SUCCESS,
    data: zones,
  }
}

export const getZonesError = (error: any) =>
{
  return {
    type: ZoneActions.GET_ZONES_ERROR,
    error: error,
  }
}

export const updateZoneBegin = (householdId: string, zone: ZoneApiResponseItem) =>
{
  return {
    type: ZoneActions.UPDATE_ZONE_BEGIN,
    data: { householdId, zone },
  }
}

export const updateZoneSuccess = (zone: ZoneApiResponseItem) =>
{
  return {
    type: ZoneActions.UPDATE_ZONE_SUCCESS,
    data: zone,
  }
}

export const updateZoneError = (error: any) =>
{
  return {
    type: ZoneActions.UPDATE_ZONE_ERROR,
    error: error,
  }
}

export const removeZoneBegin = (householdId: string, zoneId: string) =>
{
  return {
    type: ZoneActions.REMOVE_ZONE_BEGIN,
    data: { householdId, zoneId },
  }
}

export const removeZoneSuccess = (zoneId: string) =>
{
  return {
    type: ZoneActions.REMOVE_ZONE_SUCCESS,
    data: zoneId,
  }
}

export const removeZoneError = (error: any) =>
{
  return {
    type: ZoneActions.REMOVE_ZONE_ERROR,
    error: error,
  }
}

export const addRoomToZone = (householdId: string, zoneId: string, room: RoomZoneItem) =>
{
  return {
    type: ZoneActions.ADD_ROOM_TO_ZONE,
    data: { householdId, zoneId, room },
  }
}

export const removeRoomFromZoneBegin = (householdId: string, zoneId: string, roomid: number) =>
{
  return {
    type: ZoneActions.REMOVE_ROOM_FROM_ZONE_BEGIN,
    data: { householdId, zoneId, roomid },
  }
}

export const removeRoomFromZoneSuccess = (roomid: any) =>
{
  return {
    type: ZoneActions.REMOVE_ROOM_FROM_ZONE_SUCCESS,
    data: { roomid },
  }
}

export const removeRoomFromZoneError = (action: any) =>
{
  return {
    type: ZoneActions.REMOVE_ROOM_FROM_ZONE_ERROR,
    error: action.error.message,
  }
}

export const clearRemoveRoomFromZoneStatus = () =>
{
  return {
    type: ZoneActions.CLEAR_REMOVE_ROOM_FROM_ZONE,
  }
}

export const updateRoomName = (householdId: string, room: Room) =>
{
  return {
    type: ZoneActions.UPDATE_ROOM_NAME,
    data: { householdId, room },
  }
}

export const updateRoomSuccess = (room: Room) =>
{
  return {
    type: ZoneActions.UPDATE_ROOM_NAME_SUCCESS,
    data: { room },
  }
}

export const addRoomSuccess = (room: Room) =>
{
  return {
    type: ZoneActions.ADD_ROOM_SUCCESS,
    data: { room },
  }
}

export const showSnack = (type: Color, message: string) =>
{
  return {
    type: ZoneActions.SHOW_SNACK,
    data: { type, message },
  }
}

export const hideSnack = () =>
{
  return {
    type: ZoneActions.HIDE_SNACK,
  }
}

export const addSensorToRoom = (householdId: string, nodeid: number, roomid: number) =>
{
  return {
    type: ZoneActions.ADD_SENSOR_TO_ROOM,
    data: { householdId, nodeid, roomid },
  }
}

export const addSensorToRoomSuccess = (sensors: ISensor[], roomid: number) =>
{
  return {
    type: ZoneActions.ADD_SENSOR_TO_ROOM_SUCCESS,
    data: { sensors, roomid },
  }
}

export const removeSensorFromRoom = (householdId: string, nodeid: number) =>
{
  return {
    type: ZoneActions.REMOVE_SENSOR_FROM_ROOM,
    data: { householdId, nodeid },
  }
}

export const removeSensorFromRoomSuccess = (nodeid: number) =>
{
  return {
    type: ZoneActions.REMOVE_SENSOR_FROM_ROOM_SUCCESS,
    data: { nodeid },
  }
}


export const clearRemoveZoneStatus = () =>
{
  return {
    type: ZoneActions.CLEAR_REMOVE_ZONE_STATUS,
  }
}

export const clearUpdateZoneStatus = () =>
{
  return {
    type: ZoneActions.CLEAR_UPDATE_ZONE_STATUS,
  }
}
