import React from 'react'
import PropTypes from 'prop-types'

import Icon from "@material-ui/core/Icon"
import CustomIcon from './CustomIcon'

export const InteliIcon = ({ name, family, color = "inherit", style, size = 24, onClick, animation }) => {

  let styleUpdates = {
    cursor: onClick === undefined ? 'auto' : 'pointer',
    fontSize: size,
    color: color,
  }

  let communityContainerStyles = {
    display: 'inline-block',
    top: 0,
    paddingTop: 0,
  }

  let mergedStyle = Object.assign({}, styleUpdates, style)
  let communityMergedStyle = Object.assign({}, mergedStyle, communityContainerStyles)

  switch (family) {
    case 'custom':
      return <CustomIcon
        name={name}
        onClick={onClick}
        style={communityMergedStyle}
      />
    case "community":
      return <CommunityIcon
        name={name}
        onClick={onClick}
        style={communityMergedStyle}
        animation={animation}
        size={size}
      />
    case "awesome":
      return <FAIcon
        name={name}
        onClick={onClick}
        style={mergedStyle}
        animation={animation}
      />
    default:
      return <MaterialIcon
        name={name}
        onClick={onClick}
        style={mergedStyle}
        animation={animation}
      />
  }
}

InteliIcon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
  style: PropTypes.any,
  family: PropTypes.string,
  animation: PropTypes.string,
}


const communityIcons = {
  'home-automation': "M12,3L2,12H5V20H19V12H22L12,3M12,8.5C14.34,8.5 16.46,9.43 18,10.94L16.8,12.12C15.58,10.91 13.88,10.17 12,10.17C10.12,10.17 8.42,10.91 7.2,12.12L6,10.94C7.54,9.43 9.66,8.5 12,8.5M12,11.83C13.4,11.83 14.67,12.39 15.6,13.3L14.4,14.47C13.79,13.87 12.94,13.5 12,13.5C11.06,13.5 10.21,13.87 9.6,14.47L8.4,13.3C9.33,12.39 10.6,11.83 12,11.83M12,15.17C12.94,15.17 13.7,15.91 13.7,16.83C13.7,17.75 12.94,18.5 12,18.5C11.06,18.5 10.3,17.75 10.3,16.83C10.3,15.91 11.06,15.17 12,15.17Z",
  'scale-bathroom': "M5,2H19A2,2 0 0,1 21,4V20A2,2 0 0,1 19,22H5A2,2 0 0,1 3,20V4A2,2 0 0,1 5,2M12,4A4,4 0 0,0 8,8H11.26L10.85,5.23L12.9,8H16A4,4 0 0,0 12,4M5,10V20H19V10H5Z",
  'clipboard-pulse': "M19,3H14.82C14.4,1.84 13.3,1 12,1C10.7,1 9.6,1.84 9.18,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M12,3A1,1 0 0,1 13,4A1,1 0 0,1 12,5A1,1 0 0,1 11,4A1,1 0 0,1 12,3M5,13.46H7.17L10.5,7.08L11.44,14.05L13.93,10.86L16.53,13.46H19V15H15.89L14.07,13.21L10.38,17.92L9.62,12.15L8.11,15H5V13.46Z",
  'power-socket-au': "M4.22,2A2.22,2.22 0 0,0 2,4.22V19.78C2,21 3,22 4.22,22H19.78A2.22,2.22 0 0,0 22,19.78V4.22C22,3 21,2 19.78,2H4.22M12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4M8.27,7.54L6.27,11L8,12L10,8.54L8.27,7.54M15.73,7.54L14,8.54L16,12L17.73,11L15.73,7.54M11,14V18H13V14H11Z",
  'door': "M8,3C6.89,3 6,3.89 6,5V21H18V5C18,3.89 17.11,3 16,3H8M8,5H16V19H8V5M13,11V13H15V11H13Z",
  'wifi': "M12,21L15.6,16.2C14.6,15.45 13.35,15 12,15C10.65,15 9.4,15.45 8.4,16.2L12,21M12,3C7.95,3 4.21,4.34 1.2,6.6L3,9C5.5,7.12 8.62,6 12,6C15.38,6 18.5,7.12 21,9L22.8,6.6C19.79,4.34 16.05,3 12,3M12,9C9.3,9 6.81,9.89 4.8,11.4L6.6,13.8C8.1,12.67 9.97,12 12,12C14.03,12 15.9,12.67 17.4,13.8L19.2,11.4C17.19,9.89 14.7,9 12,9Z",
  'wifi-motion': "M10,0.2C9,0.2 8.2,1 8.2,2C8.2,3 9,3.8 10,3.8C11,3.8 11.8,3 11.8,2C11.8,1 11,0.2 10,0.2M15.67,1A7.33,7.33 0 0,0 23,8.33V7A6,6 0 0,1 17,1H15.67M18.33,1C18.33,3.58 20.42,5.67 23,5.67V4.33C21.16,4.33 19.67,2.84 19.67,1H18.33M21,1A2,2 0 0,0 23,3V1H21M7.92,4.03C7.75,4.03 7.58,4.06 7.42,4.11L2,5.8V11H3.8V7.33L5.91,6.67L2,22H3.8L6.67,13.89L9,17V22H10.8V15.59L8.31,11.05L9.04,8.18L10.12,10H15V8.2H11.38L9.38,4.87C9.08,4.37 8.54,4.03 7.92,4.03Z",
  "button": "M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z",
}

const CommunityIcon = ({ name, onClick, style, size, animation }) => (
  <span style={style} className={animation}>
    <svg
      name={name}
      onClick={onClick}
      height={size || '100'}
      width={size || '100'}
      viewBox="0 0 24 24">
      <path fill={style.color || "#000000"} d={communityIcons[name]} />
    </svg>
  </span>
)

CommunityIcon.propTypes = {
  size: PropTypes.number,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.any,
  animation: PropTypes.string,
}

const MaterialIcon = ({ name, onClick, style, animation }) => (
  <Icon
    onClick={onClick}
    style={style}
    className={'material-icons' + (animation ? ' ' + animation : '')}>
    {name}
  </Icon>
)

MaterialIcon.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.any,
  animation: PropTypes.string,
}

const FAIcon = ({ name, onClick, style, animation }) => (
  <span>
    <i
      style={style}
      className={`fa fa-${name}` + (animation ? ' ' + animation : '')}
      onClick={onClick}
    >
    </i>
  </span>
)

FAIcon.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.any,
  animation: PropTypes.string,
}

export default InteliIcon
