import moment from 'moment';
interface BatteryReport
{
  id: string;
  timestamp: string;
  docType: "batteryReport";
  gatewayId: string;
  sensors: Sensor[];
}

interface Sensor
{
  batteryLevel: string;
  dependencyId: string;
  deviceId: string;
  friendlyName: string;
  lastSeen: string;
  sensorId: string;
  state: string;
  status: string;
  type: string;
  timestamp: string;
  [key: string]: string;

}

interface FormattedReportItem
{
  timestamp: string;
  friendlyName: string;
  batteryLevel: string;
  [key: string]: string;
}

export const formatBatteryReport2 = (reports: BatteryReport[]): { formatted: FormattedReportItem[], keys: string[] } | [] =>
{
  try
  {
    const formatted: FormattedReportItem[] = []
    const keys = new Set<string>();
    for(const reportItem of reports)
    {
      for(const sensor of reportItem.sensors)
      {
        // add the timestamp of the report to the sensor
        sensor.timestamp = moment(reportItem.timestamp).format("DD/MM/YYYY");
        sensor[sensor.friendlyName] = sensor.batteryLevel;
        keys.add(sensor.friendlyName)
        formatted.push(sensor)
      }
    }
    return { formatted: formatted, keys: Array.from<string>(keys) };
  }
  catch(error)
  {
    return []
  }
}