import React from 'react'
import { ISensor } from '@intelicare/shared/interfaces/household/ISensor'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import PersonalRoutineGraph from './graphs/PersonalRoutineGraph'

interface PersonalRoutineCardProps
{
  sensors: ISensor[];
}


export const PersonalRoutineCard = ({ sensors }: PersonalRoutineCardProps) =>
{
  return (
    <Card style={styles.cardContainer}>

      <Typography style={styles.heading} variant="h6">Personal routine by sensor over the last 7 days</Typography>
      <PersonalRoutineGraph />

    </Card>
  )
}

const styles = {
  cardContainer: {
    height: 400,
    padding: 12,
  },
  heading: {
    paddingBottom: 12,
  },
}