import React, { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import DeleteIcon from '@mui/icons-material/Delete'
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Avatar from '@material-ui/core/Avatar'
import moment from 'moment'
import { purple } from '@mui/material/colors';
import { SearchBar } from '../Shared/SearchBar';
import { getInitials } from '../Modals/utils/getInitials';
import { Organisation } from '../../../../api/OrganisationApi';

interface HouseholdListProps
{
  onHouseholdRemove: (household: Organisation) => void;
  households: Organisation[]
}

export const HouseholdList = ({ onHouseholdRemove, households }: HouseholdListProps) =>
{
  const [ query, setQuery ] = useState("")
  return(
    <div>
      <SearchBar value={query} onTextChange={setQuery} title="Search Households" onClear={() => setQuery("")}/>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Household</TableCell>
              <TableCell align="right">Users</TableCell>
              <TableCell align="right">Created</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {households.filter(h => h.type === 'household' && h.friendlyName.toLowerCase().includes(query.toLowerCase())).map((household) => (
              <TableRow
                hover
                key={household.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" >
                  <div style={styles.avatarContainer}>
                    <Avatar style={styles.avatar}>{getInitials(household.friendlyName)}</Avatar>
                    <Typography>{household.friendlyName}</Typography>
                  </div>

                </TableCell>
                <TableCell align="right">{Object.keys(household.access).length}</TableCell>
                <TableCell align="right">{moment(household.lastUpdated).fromNow()}</TableCell>
                <TableCell align="right" >
                  <IconButton onClick={() => onHouseholdRemove(household)} >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

const styles = {
  avatar: {
    backgroundColor: purple[500],
    marginRight: 6,
  },
  avatarContainer: {
    flexDirection: "row" as "row",
    display: "flex" as "flex",
    alignItems: 'center',
  },
}