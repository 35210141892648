import React from 'react'
import { PageConsumer } from '../context/PageContext'
export const withTitle = (Component) => {
  return class HOC extends React.Component {
    render() {

      return (
        <PageConsumer>
          {({ setTitle }) => (<Component {...this.props} {...this.state} setTitle={setTitle} />)}
        </PageConsumer>
      )
    }
  }
}