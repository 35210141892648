import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Chart } from 'react-google-charts'

// Material UI components
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

import moment from 'moment'

// Custom components
import InteliIcon from '../../../../../../components/InteliIcon'
import { TimeScaleSelector } from './TimeScaleSelector'
import { FAHRENHEIT } from '../../../../../../helpers/healthMetrics'

export const DB_TIMESTAMP_FORMAT = "YYYY-MM-DD hh:mm A"

export const timeRanges = {
  TIME_RANGE_1_HOUR: {
    value: 1,
    momentValue: moment.utc().subtract(1, "hour").format(DB_TIMESTAMP_FORMAT),
    scale: "hh:mm:ss A",
    columnTitle: "Last Hour",
  },
  TIME_RANGE_7_DAYS: {
    value: 7,
    momentValue: moment.utc().subtract(7, "days").format(DB_TIMESTAMP_FORMAT),
    scale: "dddd hh:mm A",
    columnTitle: "Last 7 Days",
  },
  TIME_RANGE_24_HOURS: {
    value: 24,
    momentValue: moment.utc().subtract(24, "hours").format(DB_TIMESTAMP_FORMAT),
    scale: "dddd hh:mm A",
    columnTitle: "Last 24 Hours",
  },
  TIME_RANGE_30_DAYS: {
    value: 30,
    momentValue: moment.utc().subtract(30, "days").format(DB_TIMESTAMP_FORMAT),
    scale: "Do hh:mm A",
    columnTitle: "Last 30 Days",
  },
  TIME_RANGE_6_MONTHS: {
    value: 6,
    momentValue: moment.utc().subtract(6, "months").format(DB_TIMESTAMP_FORMAT),
    scale: "Do hh:mm A",
    columnTitle: "Last 6 Months",
  },
}

class HealthGraph extends Component
{

  state = {
    currentTimeRange: timeRanges.TIME_RANGE_7_DAYS,
  }
  componentDidMount()
  {
    const { currentTimeRange } = this.state
    this.getGraphData(currentTimeRange.momentValue)
  }

  componentDidUpdate(prevProps)
  {
    const { currentTimeRange } = this.state

    // Use JSON.stringify because some metrics use arrays
    if (JSON.stringify(prevProps.latestValue) != JSON.stringify(this.props.latestValue))
    {
      // If a new value was added, redraw graphs
      this.getGraphData(currentTimeRange.momentValue)
    }
  }

  getGraphData = (timeRange) =>
  {
    const { metricType, getGraph, activeHouseholdId } = this.props

    getGraph({
      "householdId": activeHouseholdId,
      "metricType": metricType,
      "graphMode": true,
      "timestamp": timeRange,
    })

  }

  handleTimeRangeChange = (currentTimeRange) =>
  {
    this.setState({ currentTimeRange: currentTimeRange }, () => this.getGraphData(currentTimeRange.momentValue))
  }

  // Activity graph should only show the latest data point for each day
  formatActivityGraphData = (data) => {
    const activityData = []
    let prevTimestamp = ""
    for (let i=0; i<data.length; i++) {
      const row = data[i]
      if (i === 0) {
        row[0] = this.state.currentTimeRange.columnTitle
        activityData.push(row)
      } else{
        const timestamp = row[0]
        const formattedDate = moment(row[0]).fromNow()
        if(formattedDate !== "Invalid date") row[0] = formattedDate
        if (i === 1) {
          activityData.push(row)
        } else {
          if (moment.tz(timestamp, this.props.household.timezone).isSame(moment(prevTimestamp), "day")) {
            activityData[activityData.length-1] = row
          } else {
            activityData.push(row)
          }
        }
        prevTimestamp = timestamp
      }
    }

    let min = null, max = null
    for (const row of activityData) {
      const value = row[1]
      if (min === null || value < min){
        min = value
      }
      if (max === null || value > max) {
        max = value
      }
    }

    return {
      data: activityData,
      min,
      max,
    }
  }

  convertToFahrenheit(value) {
    if (value === null) {
      return null
    }
    return Math.round((value*1.8 + 32)*10)/10 // Round to 1 decimal place
  }

  formatGraphData = (rawGraphData) =>
  {
    const { currentTimeRange } = this.state
    try
    {
      const dataCopy = JSON.parse(JSON.stringify(rawGraphData))
      if (dataCopy[0][1].label === "Activity") return this.formatActivityGraphData(dataCopy)

      let min = null, max = null;
      const data = dataCopy.map((row, key) => {
        if(key === 0)
        {
          row[0] = currentTimeRange.columnTitle
          return row
        }
        // Depending on the scale selected we should format the date axis
        const formattedDate = moment(row[0]).fromNow()
        if(formattedDate !== "Invalid date") row[0] = formattedDate
        for (var i=1; i<row.length; i++) {
          if (dataCopy[0][1].label === "Body Temperature" && this.props.temperatureUnits === FAHRENHEIT) {
            row[i] = this.convertToFahrenheit(row[i])
          }
          const value = row[i]
          if (value === 0 || value) {
            if (min === null || value < min){
              min = value
            }
            if (max === null || value > max) {
              max = value
            }
          }
        }
        return row
      })

      return {
        data,
        min,
        max,
      }
    }
    catch(error)
    {
      console.log(error)
      return
    }
  }


  render()
  {
    const { friendlyName, subtitle, graphType = "LineChart", colour, healthMetrics, doubleMetric } = this.props

    const graphData = this.formatGraphData(healthMetrics[friendlyName === "Blood Pressure" ? "Systolic Blood Pressure" : friendlyName])
    let data, min, max
    if (graphData) {
      data = graphData.data
      min = graphData.min
      max = graphData.max
    }

    const { currentTimeRange } = this.state
    return (
      <Grid item xs={12} lg={3}>
        <Paper style={styles.container}>
          {healthMetrics &&
          healthMetrics[friendlyName === "Blood Pressure" ? "Systolic Blood Pressure" : friendlyName] &&
          healthMetrics[friendlyName === "Blood Pressure" ? "Systolic Blood Pressure" : friendlyName].length > 1 ?
            <Typography>
              <Chart
                width={'auto'}
                height={'250px'}
                chartType={graphType}
                loader={<div><CircularProgress /></div>}
                data={data}
                options={{
                  interpolateNulls: true,
                  theme: 'material',
                  title: friendlyName === "Average Heart Rate" ? "Heart Rate" : friendlyName,
                  legend: friendlyName === 'Blood Pressure' || doubleMetric
                    ? { position: 'bottom', textStyle: { fontSize: 12 } } : { position: 'none' },
                  colors: colour,
                  chartArea: {
                    left: 50,
                    top: 30,
                    width: '90%',
                  },
                  chart: {
                    title: friendlyName,
                  },
                  vAxis: {
                    minValue: Math.max(min-10, 0),
                    maxValue: max + 10,
                  },
                }}
                rootProps={{ 'data-testid': '3' }}
              />
            </Typography>
            : <div style={styles.noDataContainer}>
              <div style={{ paddingTop: 75 }}>
                <InteliIcon color="grey" name="timeline" size={50} />
                <Typography color="textSecondary">{`No health metrics for ${friendlyName === "Average Heart Rate" ? "Heart Rate" : friendlyName}`}</Typography>
              </div>
            </div> }
          <TimeScaleSelector
            currentTimeRange={currentTimeRange}
            onItemClick={(timeRange) => this.handleTimeRangeChange(timeRange)}/>
        </Paper>
      </Grid>
    )
  }
}

const styles = {
  container: {
    padding: 12,
    margin: 12,
  },
  noDataContainer: {
    textAlign: 'center',
    height: "250px",
  },
}

HealthGraph.propTypes = {
  friendlyName: PropTypes.string,
  subtitle: PropTypes.string,
  chartData: PropTypes.array,
  graphType: PropTypes.string,
  colour: PropTypes.array,
  metricType: PropTypes.string,
  getGraph: PropTypes.func,
  activeHouseholdId: PropTypes.string,
  isGettingGraphs: PropTypes.bool,
  healthMetrics: PropTypes.object,
  latestValue: PropTypes.string,
  doubleMetric: PropTypes.bool,
  household: PropTypes.object,
  temperatureUnits: PropTypes.string,
}

export default HealthGraph