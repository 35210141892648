import React from 'react'

import Skeleton from '@mui/material/Skeleton';

export const UserBoxSkeleton = () =>
{
  return(
    <div style={styles.container}>
      <Skeleton variant="circular" height={40} width={40} />
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    margin: 6,
  },
}