// ------------------------------------
// Constants
// ------------------------------------
export const DATAFEED_BEGIN = 'DATAFEED_BEGIN'
export const DATAFEED_SUCCESS = 'DATAFEED_SUCCESS'
export const DATAFEED_ERROR = 'DATAFEED_ERROR'

// ------------------------------------
// Actions
// ------------------------------------

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isFetching: false,
  data: [],
}

export default function datafeedReducer (state = initialState, action) {
  switch (action.type) {
    case DATAFEED_BEGIN: {
      return {
        ...state,
        data: [],
        isFetching: true,
      }
    }
    case DATAFEED_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })
    }
    case DATAFEED_ERROR: {
      return { ...state, isFetching: false }
    }
    default:
      return state
  }
}
