import { connect } from 'react-redux'

import ResellerTab from './ResellerTab'
import redux from '../../../../../redux'

const callAPI = redux.userStore.callAPI

const {
  GET_RESELLERS,
  CREATE_RESELLER,
} = redux.apiEndpoints


const mapStateToProps = (state) => {
  return {
    isFetching: state.householdsV2.isFetching,
    isCreatingReseller: state.householdsV2.isCreatingReseller,
    resellers: state.householdsV2.resellers,
    resellersGetError: state.householdsV2.resellersGetError,
    createResellerResult: state.householdsV2.createResellerResult,
    createResellerError: state.householdsV2.createResellerError,
    households: state.householdsV2.householdsList,
    householdsGetError: state.householdsV2.householdsGetError,
    isGettingHouseholdList: state.householdsV2.isGettingHouseholdList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getResellers: (params) => dispatch(callAPI(GET_RESELLERS(params))),
    createReseller: (params) => dispatch(callAPI(CREATE_RESELLER(params))),
    clearCreateReseller: () => dispatch({ type: "CLEAR_CREATE_RESELLER" }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResellerTab)