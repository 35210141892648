import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'

import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuIcon from '@mui/icons-material/Menu'

import InteliIcon from '../../components/InteliIcon'
import UserProfilePortal from '../../components/UserProfilePortal'
import styles from './UserWelcomeStyles'
import { MsalContext } from "@azure/msal-react";
import { b2cPolicies } from '../../msal/config'
import { UserNameAvatar } from './UserNameAvatar'
import { appInsights } from './../../api/AppInsights'

import { UserBoxSkeleton } from './UserBoxSkeleton'

export class UserWelcomeView extends Component {

  static contextType = MsalContext;

  constructor(props) {
    super(props)

    this.state = {
      modalUserSettingsIsOpen: false,
      anchorEl: null,
      isLoading: false,
    }
  }
  openUserSettingsModal = () => {
    this.setState({ modalUserSettingsIsOpen: true })
  }

  closeUserSettingsModal = (e) => {
    this.setState({ modalUserSettingsIsOpen: false })
    e.stopPropagation()
  }

  togglePreviewFeature = (featureKey) => {
    this.props.toggleFeature(featureKey)
  }

  handleTouchTap = (event) => {
    // This prevents ghost click.
    event.preventDefault()

    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    })
  };

  handleRequestClose = () => {
    this.setState({ anchorEl: null })
  }

  handleEditProfile = () =>
  {
    appInsights.trackPageView({ name: "Edit Profile" })
    this.setState({ modalUserSettingsIsOpen: true, anchorEl: false })
  }

  handleResetPassword = () =>
  {
    this.setState({ isLoading: true }, () =>
    {
      window.location.href = b2cPolicies.authorities.forgotPassword.authority
    })

    appInsights.trackPageView({ name: "Reset Password" })

  }

  handleLogout = () =>
  {
    this.setState({ isLoading: true }, () =>{
      sessionStorage.clear()
      localStorage.clear()
      window.location.href = b2cPolicies.authorities.logout.authority
    })

    appInsights.trackPageView({ name: "Logout" })

  }

  componentDidMount()
  {
    const { getProfile } = this.props
    getProfile()

    this.setState({
      isLoading: false,
    })
  }

  static propTypes = {
    classes: PropTypes.object,
    username: PropTypes.string,
    avatar: PropTypes.any,
    location: PropTypes.string,
    onLogout: PropTypes.func,
    isLoading: PropTypes.bool,
    isDeveloper: PropTypes.bool,
    isSupport: PropTypes.bool,
    featurePreviews: PropTypes.any,
    toggleFeature: PropTypes.func,
    closeDrawer: PropTypes.func.isRequired,
    getProfile: PropTypes.func,
  }

  render() {
    const {
      classes,
      username,
      avatar,
    } = this.props

    const { isLoading, modalUserSettingsIsOpen } = this.state

    return (
      <div className={classes.root}>
        <div className={classes.UserInfoContainer}>
          {modalUserSettingsIsOpen && !this.props.isLoading && (
            <UserProfilePortal close={this.closeUserSettingsModal} />
          )}

          {this.props.isLoading ?
            <UserBoxSkeleton />
            :
            <UserNameAvatar
              username={username}
              avatar={avatar}
              onAvatarClick={(e) => this.setState({ anchorEl: e }) } /> }

        </div>
        <Menu
          className={classes.menu}
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.handleRequestClose}>
          <MenuItem onClick={this.handleEditProfile}>
            <ListItemIcon >
              <InteliIcon family='material' name='account_circle' />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </MenuItem>
          <MenuItem onClick={this.handleResetPassword}>
            <ListItemIcon >
              <InteliIcon family='material' name='rotate_left' />
            </ListItemIcon>
            <ListItemText primary="Reset Password" />
          </MenuItem>
          <MenuItem onClick={this.handleLogout}>
            <ListItemIcon >
              <InteliIcon family='material' name='exit_to_app' />
            </ListItemIcon>
            <ListItemText primary="Logout" />


          </MenuItem>

          {isLoading && <LinearProgress color="secondary" /> }

        </Menu>
      </div >
    )
  }
}



export default withStyles(styles)(UserWelcomeView)
