

import { SOCIAL_ISOLATION_COLORS } from './colors';
import { BEHAVIOUR_TYPES } from '@intelicare/shared/enums';

export interface SocialBehaviour {
  classification: "presence";
  enabled: boolean;
  friendlyName: "Social Isolation";
  id: string;
  timeLimitDays?: number;
  lastUpdated: string;
  name: string;
  lastAcknowledged: string;
  status: {
    msg: string;
    value: string;
  };
  type: "socialIsolation";
}

export const getSocialIsolationStatus = (socialIsolation: SocialBehaviour) => {

  const status = {
    status: 'DISABLED',
    statusText: 'Insight disabled',
    message: '',
    color: '',
  }

  if (socialIsolation && socialIsolation.enabled && socialIsolation.status) {
    status.status = socialIsolation.status.value
    status.message = socialIsolation.status.msg
    if (status.status === 'NORMAL') {
      status.color = SOCIAL_ISOLATION_COLORS.NORMAL
      status.statusText = 'No concern'
    }
    if (status.status === 'WARNING') {
      status.color = SOCIAL_ISOLATION_COLORS.WARNING
      status.statusText = 'Possible concern'
    }
    if (status.status === 'ALERT') {
      status.color = SOCIAL_ISOLATION_COLORS.ALERT
      status.statusText = 'Possible concern'
    }
  }

  return status
}

export const getSocialIsolationBehaviour = (householdV2: any): SocialBehaviour => {
  return householdV2.data.behaviours.find((b: any) => b.type === BEHAVIOUR_TYPES.SOCIAL_ISOLATION && b.classification === BEHAVIOUR_TYPES.PRESENCE)
}
