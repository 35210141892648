import { connect } from 'react-redux'
import GatewayFirmwareView from '../components/GatewayFirmwareView'
import { compose } from 'redux'
import { withErrorHandler, Fallback } from '../../../../hoc/withErrorHandler'
import sharedStore from "../../../../redux"
const callAPI = sharedStore.userStore.callAPI
const GATEWAY_FIRMWARE_DATA = sharedStore.apiEndpoints.GATEWAY_FIRMWARE_DATA
const GATEWAY_FIRMWARE_LOGFILE = sharedStore.apiEndpoints.GATEWAY_FIRMWARE_LOGFILE
const GATEWAY_FIRMWARE_LOGFILE_LIST = sharedStore.apiEndpoints.GATEWAY_FIRMWARE_LOGFILE_LIST
const GATEWAY_FIRMWARE_UPDATE = sharedStore.apiEndpoints.GATEWAY_FIRMWARE_UPDATE
const GATEWAY_REMOTE_SERVICE = sharedStore.apiEndpoints.GATEWAY_REMOTE_SERVICE

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFirmwareData: (e) => {
      dispatch(callAPI(GATEWAY_FIRMWARE_DATA(e)))
    },
    getLogFile: (gatewayId, logFile) => {
      dispatch(callAPI(GATEWAY_FIRMWARE_LOGFILE({
        gatewayId: gatewayId,
        logFile: logFile,
      })))
    },
    getLogFileList: (gatewayId) => {
      dispatch(callAPI(GATEWAY_FIRMWARE_LOGFILE_LIST({
        gatewayId: gatewayId,
      })))
    },
    runGatewayUpdate: (gatewayId) => {
      dispatch(callAPI(GATEWAY_FIRMWARE_UPDATE(
        {
          gatewayId: gatewayId,
        }
      )))
    },
    executeService: (gatewayId, operation) => {
      dispatch(callAPI(GATEWAY_REMOTE_SERVICE({ gatewayId: gatewayId, body: operation })))
    },
    clearRemoteCommandsModal: () => dispatch({ type: "CLEAR_GATEWAY_REMOTE_SERVICE" }),
  }
}

const mapStateToProps = (state) => ({
  remoteResult: state.households.gatewayRemoteService,
  remoteErrorResult: state.households.gatewayRemoteService.error,
  isFetching: state.households.gatewayFirmware.isFetching,
  isUpdating: state.households.gatewayFirmware.isUpdating,
  gatewayFirmware: state.households.gatewayFirmware.gateways,
  logFileResponse: state.households.gatewayFirmwareLogFile,
  token: state.user.token,
})


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withErrorHandler(Fallback)
)(GatewayFirmwareView)

