/* eslint-disable semi */
import React, { Component } from "react";

// Material UI components
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import RouterIcon from "@mui/icons-material/Router";

// Parent Table interfaces
import { RowItem, UPDATE_STATES } from "./FirmwareListTable";

// Moment
import moment from "moment";

enum COLORS {
  OFFLINE = "#ef5350",
  ONLINE = "#4CAF50",
  WATCH = "#FFA726",
  UNKNOWN = "#BDBDBD",
}



interface FirmwareRowItemProps {
  row: RowItem;
  onShowLogs(row: RowItem): void;
  onShowCommands(row: RowItem): void;
  onShowSensorCommands(row: RowItem): void;
  onSelectForFirmwareUpdate(row: RowItem, checked: boolean): void;
  runGatewayUpdate(gatewayId: string): void;
  deleteHouseholdAndGatewayConfirm(row: RowItem): void;
}

class FirmwareRowItem extends Component<FirmwareRowItemProps> {
  state = {
    anchorEl: false,
    firmwareUpdateSuccessSnack: false,
  };

  onRunUpdate = (row: RowItem): void => {
    const { runGatewayUpdate } = this.props;
    this.setState({ firmwareUpdateSuccessSnack: true, anchorEl: "" });
    runGatewayUpdate(`'${row.gatewayId}'`);
  };

  closeSnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ firmwareUpdateSuccessSnack: false });
  };

  getStatus = (connectionState: string) => {
    try {
      if (connectionState === "ALIVE") return COLORS.ONLINE;
      if (connectionState === "WATCH") return COLORS.WATCH;
      if (connectionState === "DEAD") return COLORS.OFFLINE;
      if (connectionState === "UNKNOWN") return COLORS.UNKNOWN;
      return COLORS.UNKNOWN;
    } catch (error) {
      return COLORS.UNKNOWN;
    }
  };

  render() {
    const { row, onShowLogs, onShowCommands, onSelectForFirmwareUpdate, onShowSensorCommands } = this.props;
    const { anchorEl, firmwareUpdateSuccessSnack } = this.state;
    return (
      <TableRow hover key={row.gatewayId}>
        <TableCell component="th" scope="row">
          <Checkbox onChange={(e) => onSelectForFirmwareUpdate(row, e.target.checked)}></Checkbox>
          {row.friendlyName}
        </TableCell>
        <TableCell align="right">
          {
            <Tooltip title={row.connectionState ? row.connectionState : "UNKNOWN"}>
              <RouterIcon
                style={{
                  position: "relative",
                  top: 5,
                  right: 5,
                  color: this.getStatus(row.connectionState),
                }}
              />
            </Tooltip>
          }
          {row.gatewayId}
        </TableCell>
        <TableCell align="right">{row.version}</TableCell>
        <TableCell align="right">{UPDATE_STATES[row.firmwareStatus]}</TableCell>
        <TableCell align="right">
          <Tooltip title={moment(row.lastFirmwareUpdate).fromNow()}>
            <div>{moment(row.lastFirmwareUpdate).format("lll")}</div>
          </Tooltip>
        </TableCell>
        <TableCell align="right">
          <IconButton onClick={(e) => this.setState({ anchorEl: e.currentTarget })}>
            <MoreVertIcon></MoreVertIcon>
          </IconButton>
          {/* Actions */}
          <Menu
            keepMounted
            id={`firmware-update-${row.gatewayId}`}
            open={Boolean(this.state.anchorEl)}
            //@ts-ignore
            anchorEl={anchorEl}
            onClose={() => this.setState({ anchorEl: "" })}
          >
            <MenuItem onClick={() => this.onRunUpdate(row)} value={10}>
              Run Update
            </MenuItem>
            <MenuItem onClick={() => onShowLogs(row)} value={20}>
              Get Logs
            </MenuItem>
            <MenuItem onClick={() => onShowCommands(row)} value={30}>
              Commands
            </MenuItem>
            <MenuItem onClick={() => onShowSensorCommands(row)} value={40}>
              Sensor Configuration
            </MenuItem>
          </Menu>
        </TableCell>

        <Snackbar open={firmwareUpdateSuccessSnack} autoHideDuration={6000} onClose={this.closeSnack}>
          <Alert variant="filled" onClose={this.closeSnack} severity="success">
            Successfully scheduled gateway firmware update for {row.gatewayId}!
          </Alert>
        </Snackbar>
      </TableRow>
    );
  }
}

export { FirmwareRowItem };
