import React from 'react'
import NightView from '../cards/NightView'

interface SleepTabProps
{
  timezone: string;
  activeV2Household: any;
  isDeveloper: boolean;
  useNewLook: boolean;
  hasZones: boolean;
}

export const SleepTab = ({ timezone, activeV2Household, isDeveloper, useNewLook, hasZones }: SleepTabProps) =>
{
  return(
    <div>
      <NightView
        hasZones={hasZones}
        useNewLook={useNewLook}
        id="sleep"
        aria-label="sleep"
        timezone={timezone}
        householdV2={activeV2Household}
        isDeveloper={isDeveloper}
      />
    </div>
  )
}