import React from 'react'
import SecurityIcon from '@mui/icons-material/Lock'
import Typography from '@mui/material/Typography'


export const NoAssignedHouseholdsView = () =>
{
  return(
    <div style={styles.container}>

      <SecurityIcon style={styles.icon} />
      <Typography color="textSecondary">{"No households assigned to this user"} </Typography>

    </div>
  )
}

const styles = {
  container: {
    height: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: 'grey',
  },
}