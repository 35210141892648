import * as React from 'react'
import { Component } from 'react'
import PropTypes from 'prop-types'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Label,
  ResponsiveContainer,
} from 'recharts'
import { withTheme } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/core/styles'

import moment from 'moment-timezone'

const MAX_DATA_POINTS = 14
let colorIndex

class OutingHistory extends Component {

  constructor(props) {
    super(props)
    this.state = {
      width: window.innerWidth - 450,
      height: 300,
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth - 450 })
  }

  convertToGraphData(data, householdTz) {
    if (!householdTz) householdTz = 'GMT'
    let graphData = []
    // Filter incomplete outings
    data = data.filter((o) => (o.start && o.end))
    // Convert outing start and end to moments
    for (let i = 0; i < data.length; i++) {
      data[i] = {
        start: moment(data[i].start).tz(householdTz).local(),
        end: moment(data[i].end).tz(householdTz).local(),
      }
    }

    // Create daily record
    let rollingDate = moment().subtract(14, "days").tz(householdTz).local()
    for (let i = 0; i < (MAX_DATA_POINTS + 1); i++) {
      let dayOutings = {
        id: i,
        date: rollingDate.clone(),
        name: rollingDate.format('DD/MM').toString(),
        outings: [],
        outingTotal: 0,
      }
      graphData.push(dayOutings)
      rollingDate.add(1, "day")
    }

    for (let i = 0; i < data.length; i++) {
      let outingStart = data[i].start
      let outingEnd = data[i].end
      // Outing was same day
      let itemIndex = graphData.findIndex((o) => o.date.isSame(outingStart, 'day'))
      if (itemIndex === -1) continue
      if (outingStart.isSame(outingEnd, 'day')) {
        if (graphData[itemIndex]) graphData[itemIndex].outings.push(moment.duration(outingEnd.diff(outingStart)).asHours())
        continue
      }
      // Outing was overnight or longer
      let endOfDay = outingStart.clone()
      endOfDay.endOf('day')
      if (outingEnd.isAfter(endOfDay, 'minutes')) {
        let outingHours = (outingEnd.diff(endOfDay, 'hours'))
        for (let j = 0; j < (outingHours / 24); j++) {
          if (itemIndex > (graphData.length - 1)) break
          graphData[itemIndex].outings = [24]
          itemIndex++
        }
        if (outingHours > 24) {
          if (graphData[itemIndex] === -1) continue
          let remainder = graphData[itemIndex].date.clone()
          remainder.startOf('day')
        }
      }
    }

    for (let i = 0; i < graphData.length; i++) {
      graphData[i].outingTotal = graphData[i].outings.reduce((a, b) => a + b, 0)
      if (graphData[i].outingTotal > 24) {
        graphData[i].outingTotal = 24
        graphData[i].outings = [24]
      }
    }
    return graphData
  }

  getBars(graphData) {
    let bars = []
    let outingId = 0
    graphData.forEach(day => {
      day.outings.forEach(outing => {
        bars.push(
          <Bar
            key={outingId}
            fill={this.getColor()}
            dataKey={`outings[${outingId}]`}
            stackId={'a'}
            strokeWidth={2}
          />)
        outingId++
      })
    })
    return bars
  }

  getColor() {
    const color = this.props.theme.graphColors.setB[colorIndex]
    colorIndex = (colorIndex + 1) % this.props.theme.graphColors.setB.length
    return color
  }

  render() {
    const {
      data,
      householdTz,
    } = this.props
    colorIndex = 0
    const graphData = this.convertToGraphData(data, householdTz)
    const bars = this.getBars(graphData)

    return (
      <div style={styles.container}>
        <ResponsiveContainer width="99%" height={this.state.height}>
          <BarChart
            width="100%"
            height={this.state.height}
            data={graphData}
            margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis>
              <Label angle={-90} value='Duration (Hours)' position='insideLeft' style={{ textAnchor: 'middle' }} />
            </YAxis>
            {bars}

          </BarChart>

        </ResponsiveContainer>
      </div>
    )
  }
}

OutingHistory.propTypes = {
  data: PropTypes.any,
  householdTz: PropTypes.string,
  theme: PropTypes.object,
}

const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
  },
}

export default withTheme(withStyles({})(OutingHistory))
