//@ts-nocheck
import {
  IHouseholdPaniSensor,
  PaniDevice,
  PaniEventsResponseBody,
  PaniNotificationPostBody,
  PaniNotificationResponseBody,
  PaniNotificationSetupResponseBody,
} from '@intelicare/shared/interfaces/pani/Pani';
import { Builder } from "./ApiBuilder";
/**
 * Description: Helper class for interacting with the Pani
 */

export interface AddDeviceResponse
{
  result: string
  error: string
}

export interface ErrorResponse
{
  error: string;
}

export interface DeleteResult
{
  msg: string
  error: string
}

export interface UpdateNotificationResult
{
  id: string;
  error: string
}

export class PaniApi extends Builder
{
  token: string;
  principalId: string;
  headers: any;

  constructor(token: string, principalId: string)
  {

    super(true)
    this.token = token;
    this.principalId = principalId;

    this.headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "x-ms-client-principal-id": this.principalId,
      Authorization: `Bearer ${this.token}`,
      Accept: "application/json",
    }
  }

  addDeviceToHouseholdId = async (householdId: string, paniId: string, paniDevice: PaniDevice): Promise<AddDeviceResponse> =>
  {
    const endpoint = `/api/pani?addDeviceToHouseholdId=${householdId}&paniId=${paniId}?householdId${this.appendUserAuth(householdId)}`

    try
    {
      const response = await this.call("POST", endpoint, { paniDevice: paniDevice }, this.token)
      if(response && response.data) return response.data;
    }
    catch(error)
    {
      return error.response.data
    }
  }

  updatePaniSensorForHouseholdId = async (householdId: string, paniId: string, paniDevice: PaniDevice): Promise<AddDeviceResponse> =>
  {
    const endpoint = `/api/pani?updatePaniSensorForHouseholdId=${householdId}&paniId=${paniId}${this.appendUserAuth(householdId)}`

    try
    {
      const response = await this.call("PATCH", endpoint, { paniDevice: paniDevice }, this.token)
      if(response && response.data) return response.data;
    }
    catch(error)
    {
      return error.response.data
    }
  }

  getPaniDevicesByHouseholdId = async (householdId: string): Promise<IHouseholdPaniSensor[] | ErrorResponse> =>
  {
    const endpoint = `/api/pani?getPaniDevicesByHouseholdId=${householdId}${this.appendUserAuth(householdId)}`
    try
    {
      const result = await this.call("GET", endpoint, null, this.token )
      if(result && result.data) return result.data;
    }
    catch(error)
    {
      return error.response.data
    }
  }

  getEventsByHouseholdId = async (householdId: string, paniId: string, fromTime: string): Promise<PaniEventsResponseBody> =>
  {
    const endpoint = `/api/pani?getEventsByHouseholdId=${householdId}&paniId=${paniId}&fromTime=${fromTime}${this.appendUserAuth(householdId)}`

    try
    {
      const result = await this.call("GET", endpoint, null, this.token)
      if(result && result.data) return result.data;
    }
    catch(error)
    {
      return error
    }
  }

  getNotifications = async (householdId: string, paniId: string): Promise<PaniNotificationResponseBody> =>
  {
    const endpoint = `/api/pani?getNotifications=${paniId}${this.appendUserAuth(householdId)}`

    try
    {
      const result = await this.call("GET", endpoint, null, this.token)
      if(result && result.data) return result.data;
    }
    catch(error)
    {
      return error.response.data
    }
  }

  createNotification = async (householdId: string, paniId: string, notification: PaniNotificationPostBody): Promise<PaniNotificationSetupResponseBody> =>
  {
    const endpoint = `/api/pani?createNotificationForPaniId=${paniId}${this.appendUserAuth(householdId)}`

    try
    {
      const result = await this.call("POST", endpoint, { notification: notification }, this.token)
      if(result && result.data) return result.data;
    }
    catch(error)
    {
      return error
    }
  }


  removePaniSensorFromHouseholdId = async (householdId: string, paniDeviceId: string) =>
  {
    const endpoint = `/api/pani?removePaniSensorFromHouseholdId=${householdId}&paniId=${paniDeviceId}${this.appendUserAuth(householdId)}`

    try
    {
      const result = await this.call("DELETE", endpoint, null, this.token)
      if(result && result.data) return result.data;
    }
    catch(error)
    {
      return error.response.data
    }
  }

  updateNotification = async (householdId: string, notificationId: string, paniDeviceId: string, notification: PaniNotificationPostBody): Promise<UpdateNotificationResult> =>
  {
    try
    {
      // Delete
      const deleteResult = await this.deleteNotification(householdId, notificationId, paniDeviceId);
      if(deleteResult && deleteResult.msg === "Successfully added / updated")
      {
        // Create a new notification
        const createResult = await this.createNotification(householdId, paniDeviceId, notification)
        return createResult
      }

      return { error: deleteResult.msg }

    }
    catch(error)
    {
      return error.response.data
    }
  }

  deleteNotification = async (householdId: string, notificationId: string, paniDeviceId: string): Promise<DeleteResult> =>
  {
    const endpoint = `/api/pani?deleteNotificationById=${notificationId}&paniId=${paniDeviceId}${this.appendUserAuth(householdId)}`

    try
    {
      const result = await this.call("DELETE", endpoint, null, this.token)
      if(result && result.data) return result.data;
    }
    catch(error)
    {
      return error.response.data
    }
  }

  appendUserAuth = (userRequestedHouseholdId: string): string =>
  {
    return `&userToken=${this.token}&userRequestedHouseholdId=${userRequestedHouseholdId}`
  }

}