import { createSelector } from 'reselect'
import _ from 'lodash'
import access from 'safe-access'
import moment from 'moment-timezone'

import {
  colorForStatus,
  colorForSystemStatus,
} from '../../shared/util/colors'

import {
  alertStatus,
  amberStatus,
  okayStatus,
} from '../../shared/util/status'

import {
  deriveRootSensorDataForGateway,
  dayStr,
  _transformSensor,
} from '../../shared/selectors/helper'

import { getHouseholds } from '../households/householdsSelector'
import { getActiveHousehold } from '../householdsV2/householdsSelector'

const statusFromGwState = (stateCode) => {
  switch (stateCode) {
    case 0:
      return okayStatus
    case 1:
      return amberStatus
    case 2:
      return alertStatus
    default:
      return alertStatus
  }
}

const getGateways = (state) => state.system.gateways

export const convertGatewayDetails = createSelector(
  [getGateways],
  (gateways) => {
    var result =
      _(gateways)
        .reject({ householdStatus: "monitored" })
        .filter(gateway => gateway.monitored !== undefined)
        .map((gateway) => {
          var lastSeenMoment = gateway.lastseen ? moment(gateway.lastseen).tz(moment.tz.guess()) : null
          var color = colorForStatus(statusFromGwState(gateway.state.stateCode))
          return {
            id: gateway.gatewayid,
            key: gateway.gatewayid,
            name: gateway.friendlyname,
            status: gateway.state.stateCode,
            statusText: gateway.state.status,
            icon: {
              name: 'home-automation',
              family: 'community',
              color: color,
            },
            lastseenStr: lastSeenMoment ? `${dayStr(lastSeenMoment)} @ ${lastSeenMoment.format("HH:mm:ss z")}` : "-",
            lastseen: moment(lastSeenMoment),
          }
        })
        .sortBy([(i) => -i.status, (i) => i.name])
        .value()

    return result
  }
)

const getGateway = (state) => state.system.gateway

export const getGatewayTitle = createSelector([getGateway], (gateway) => {
  return access(gateway, "friendlyname") || 'N/A'
})

const _sensorStatusCalculator = (sensor) => {
  const status = access(sensor, 'status')
  if (status === 'okay') {
    return okayStatus
  }
  if (status === 'warn') {
    return amberStatus
  }
  return alertStatus
}

const getGatewayId = createSelector(
  [getGateway],
  (gateway) => access(gateway, 'gatewayid')
)

export const getFeatureToggles = createSelector(
  [getGatewayId, getHouseholds],
  (gatewayId, households) => access(households.find(household => household.gatewayId === gatewayId), 'features')
)

export const convertSensorDetailsV2 = createSelector(
  [getActiveHousehold],
  (household) => {
    const sensors = household.data.sensors
      .filter(sensor => !sensor.parentId)
      .map(sensor => _transformSensor({ sensor: sensor, statusCalculator: _sensorStatusCalculator, household }))

    return sensors
  }
)

export const convertSensorDetails = createSelector(
  [getGateway],
  (gateway) => {
    const statusCalculator = _sensorStatusCalculator
    return deriveRootSensorDataForGateway(gateway, statusCalculator)
  }
)

export const systemSummaryData = createSelector(
  [convertGatewayDetails],
  (gatewayDetails) => {
    let result = _(gatewayDetails)
      .map((g) => g.statusText)
      .groupBy()
      .map((val, key) => {
        return {
          x: val.length,
          label: key,
          color: colorForSystemStatus(key),
        }
      }).value()

    return result
  }

)
