import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withErrorHandler, Fallback } from '../../../hoc/withErrorHandler'
import clsx from 'clsx'
import { PageConsumer } from '../../../context/PageContext'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"
import Badge from "@material-ui/core/Badge"
import Typography from '@material-ui/core/Typography'
import IconButton from "@material-ui/core/IconButton"
import UserWelcome from '../../../components/UserWelcome'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import InteliAvatar from '../../../components/Utils/InteliAvatar'
import InteliIcon from "../../../components/InteliIcon"
import { AppBar } from '../MiniDrawer/AppBar'
import styles from './AppHeaderStyles'
import moment from 'moment'
import MenuIcon from '@mui/icons-material/Menu';

import * as localForage from 'localforage'
import { USE_NEW_LOOK } from '../../../redux/domains/householdsV2/householdsStore'

class AppHeaderView extends Component {

  componentDidMount() {
    const { getActiveAlerts } = this.props
    getActiveAlerts({ type: "ALERT", status: "ACTIVE", limit: 200, offset: 0, order: "DESC", timeLimit: moment().subtract(2, "weeks").unix() })
    this.isNewLookSet()
  }

  isNewLookSet = async () => {
    const { setUseNewLook } = this.props
    try {
      const value = await localForage.getItem(USE_NEW_LOOK) || false
      setUseNewLook({ value: value })
    }
    catch (error) {
      setUseNewLook({ value: false })
    }
  }

  render() {
    const {
      activeAlerts,
      setAlertsMinimised,
      toggleDrawer,
      isDrawerOpen,
      classes,
      setUseNewLook,
      useNewLook,
    } = this.props


    return (
      <PageConsumer>{
        ({ title, iconLeft, pageButtons }) => (
          <AppBar position='fixed'>
            <Toolbar style={{ maxHeight: "64px" }} >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                edge="start"
                sx={{
                  marginRight: '36px',
                  ...(isDrawerOpen && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              {iconLeft &&
                <InteliAvatar src={iconLeft} />
              }

              <Typography className={classes.Title} variant="h5">
                {title}
              </Typography>

              {window.location.href.includes("/households") && <FormControlLabel
                control={<Switch checked={useNewLook} onChange={(e) => setUseNewLook({ value: e.target.checked })} name="newLook" />}
                label="Try the new look"
              />}

              {pageButtons &&
                <Fragment key='headerButtons'>
                  {pageButtons}
                </Fragment>
              }

              <IconButton
                color={activeAlerts ? "secondary" : "primary"}
                onClick={() => { setAlertsMinimised(false) }}>
                <Badge color="secondary" badgeContent={activeAlerts}>
                  <InteliIcon animation={activeAlerts ? 'infinite animated tada slower' : ''} name='notification_important' color='white' family='material' />
                </Badge>
              </IconButton>

              <UserWelcome />

            </Toolbar>



          </AppBar>
        )}
      </PageConsumer>
    )
  }
}

AppHeaderView.propTypes = {
  setAlertsMinimised: PropTypes.func.isRequired,
  activeAlerts: PropTypes.number,
  status: PropTypes.string,
  toggleDrawer: PropTypes.func,
  classes: PropTypes.any,
  getActiveAlerts: PropTypes.func,
  token: PropTypes.string,
  incrementAlertCount: PropTypes.func,
  decrementAlertCount: PropTypes.func,
  getAllActiveAlerts: PropTypes.func,
  setUseNewLook: PropTypes.func,
  useNewLook: PropTypes.bool,
  isDeveloper: PropTypes.bool,
  isSupport: PropTypes.bool,
  isDrawerOpen: PropTypes.bool,
}

export default compose(
  withStyles(styles),
  withErrorHandler(Fallback)
)(AppHeaderView)
