import React from "react";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch'
interface SensorThresholdsFormatProps {
  defaultWarnLowerLimit: string;
  defaultWarnUpperLimit: string;
  defaultRoomEnabled: boolean;
  setDefaultLowerWarnLimit: (limit: string) => void;
  setDefaultUpperWarnLimit: (limit: string) => void;
  setRoomEnabled: (enable: number) => void;
  roomName: string;
  warnClimateUnits: string;
}

class SensorThresholdsRowItems extends React.Component<SensorThresholdsFormatProps> {
  render() {
    const { defaultWarnLowerLimit, defaultWarnUpperLimit, defaultRoomEnabled, warnClimateUnits } = this.props
    return (
      <Grid style={styles.container} container spacing={4} direction="row" >
        <Grid item style={styles.roomNameStyle}>
          <DialogContentText style={styles.inputDescription}>
            {this.props.roomName}
          </DialogContentText>
        </Grid>

        <Grid>
          <Switch
            checked={defaultRoomEnabled}
            onChange={() => this.props.setRoomEnabled(Number(!defaultRoomEnabled))}
          />
        </Grid>

        <Grid item style={styles.gridItemStyle}>
          <TextField
            type="number"
            disabled={!defaultRoomEnabled}
            label="Lower Threshold"
            style={styles.inputField}
            value={String(defaultWarnLowerLimit)}
            variant="outlined"
            onChange={(e) => {
              const value = e.target.value
              this.props.setDefaultLowerWarnLimit(value)
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{warnClimateUnits}</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item style={styles.gridItemStyle}>
          <TextField
            type="number"
            disabled={!defaultRoomEnabled}
            label="Upper Threshold"
            style={styles.inputField}
            value={String(defaultWarnUpperLimit)}
            variant="outlined"
            onChange={(e) => {
              const value = e.target.value
              this.props.setDefaultUpperWarnLimit(value)
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{warnClimateUnits}</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
    )
  }
}

const styles = {
  container: {
    justifyContent: 'center', //along the main axis
    alignItems: 'center',
  },
  roomNameStyle: {
    flex: 3,
    marginLeft: 10,
    width: "100%",
  },
  gridItemStyle: {
    flex: 5,
  },
  inputField: {
    width: "100%",
  },
  inputDescription: {
    paddingTop: 10,
    paddingLeft: 5,
  },
};

export default SensorThresholdsRowItems;