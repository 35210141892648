import React, { Component } from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'

import InteliIcon from '../../../../components/InteliIcon'

import SensorInfoView from './SensorInfoView'
import styles from './SystemHealthStyles'

import { SENSOR_STATUS } from '@intelicare/shared/enums'
import { colors } from '../../../../redux'
import access from 'safe-access'
const { colorForStatusValue } = colors

class SystemTooltipView extends Component {
  static propTypes = {
    household: PropTypes.any.isRequired,
    status: PropTypes.string,
    sensors: PropTypes.any,
    classes: PropTypes.any.isRequired,
  }



  render() {
    const {
      household,
      status,
      classes,
    } = this.props

    const statusColor = colorForStatusValue(status || SENSOR_STATUS.UNKNOWN)

    const hasSystemHealthStatus = (status &&
      status !== SENSOR_STATUS.UNKNOWN &&
      status !== SENSOR_STATUS.DISABLED)

    return (
      <div className={classes.ToolTip}>
        <div style={componentStyles.headingContainer}>
          <InteliIcon name="home" family="awesome" size={24} style={componentStyles.headingIcon}/>
          <Typography variant='h6'>{household.friendlyName}</Typography>
        </div>
        <div className={classes.LoadingData}>
          {hasSystemHealthStatus &&
            access(household, 'household.data.isFetching') &&
            <CardContent>
              <div style={{ justifyContent: 'center', alignItems: 'center', flex: 'row', display: 'flex' }}>
                <CircularProgress
                  variant='indeterminate'
                  size={30}
                  align='center'
                  style={{ alignSelf: 'center' }}
                  color='primary'
                />
              </div>
            </CardContent>
          }

          {hasSystemHealthStatus &&
            !access(household, "household.data.isFetching") &&
            <CardContent classes={{ root: classes.sensorCardContent }}>
              {this.getSensorElements()}
            </CardContent>
          }

          {!hasSystemHealthStatus &&
            <div className={classes.NoData}>
              <div className={classes.NoDataIcon}>
                <InteliIcon
                  name='clipboard-pulse'
                  family='community'
                  color={statusColor}
                  size={30}
                />
              </div>
              <Typography variant="overline">No data to display</Typography>
            </div>
          }
        </div>
      </div>
    )
  }

  getSensorElements() {
    const {
      household,
      sensors,
    } = this.props

    if (!sensors) {
      return (
        <div>
          <h3>{household.friendlyName}</h3>
          <p>No data available</p>
        </div>
      )
    }

    const unhealthySensors = sensors.filter(sensor => {
      return (!sensor.parentId &&
        (sensor.status === SENSOR_STATUS.ALERT ||
          sensor.status === SENSOR_STATUS.WARNING ||
          sensor.status === SENSOR_STATUS.UNKNOWN
        ))
    })

    let sensorElements = []
    unhealthySensors.forEach(sensor => {
      sensorElements.push(<SensorInfoView key={sensor.id} data={sensor} timezone={household.data.timezone} />)
    })

    if (unhealthySensors.length === 0 && sensors.length > 0) {
      return (
        <div>
          <Typography align="center" variant="overline">All sensors okay</Typography>
        </div>
      )
    }

    if (sensors.length === 0) {
      return (
        <div>
          <Typography align="center" variant="overline">No sensors for household</Typography>
        </div>
      )
    }

    return sensorElements
  }
}

const componentStyles = {
  headingContainer: {
    flexDirection: 'row',
    display: 'flex',
    padding: 6,
  },
  headingIcon: {
    color: "grey",
    marginRight: 6,
    paddingLeft: 12,
    paddingTop: 3,
  },
}



export default withStyles(styles)(SystemTooltipView)
