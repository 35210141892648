import React, { Component } from "react";
import { ActivityList } from "./ActivityList";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
interface DoorStatusProps {
  householdId: string;
  activityStreamEventsError: any;
  isGettingActivityEvents: boolean;
  activityStreamEvents: any[];
}

class DoorStatus extends Component<DoorStatusProps> {
  render() {
    const {
      activityStreamEventsError,
      isGettingActivityEvents,
      activityStreamEvents,
    } = this.props;

    return !isGettingActivityEvents ? (
      <div>
        <Typography
          style={styles.headingText}
          variant="body1"
          color="textPrimary"
        >
          Activity Stream
        </Typography>
        <Typography
          style={styles.headingText}
          variant="caption"
          color="textSecondary"
        >
          Recent Events Today
        </Typography>
        <ActivityList events={activityStreamEvents} />
      </div>
    ) : (
      <div style={styles.progress}>
        <CircularProgress size={24} />
        <Typography
          style={styles.progessText}
          variant="body2"
          color="textSecondary"
        >
          Getting Events
        </Typography>
      </div>
    );
  }
}

const styles = {
  progress: {
    justifyContent: "center",
    display: "flex",
    padding: 12,
  },
  progessText: {
    alignSelf: "center",
    marginLeft: 6,
  },
  headingText: {
    marginLeft: 10,
  },
};

export { DoorStatus };
