import React, { Component } from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'

import SearchableTableRows from './SearchableTableRows'
import SearchableTableToolbar from './SearchableTableToolbar'

import styles from './SearchableTableStyles'

export class SearchableTable extends Component {

  constructor(props) {
    super(props)

    this.updateSearchString = this.updateSearchString.bind(this)
  }

  state = {
    searchString: '',
  }

  updateSearchString(searchString) {
    this.setState(state => ({ ...state, searchString: searchString }))
  }

  componentDidMount() {
    if (this.props.fetchSystemData) {
      this.props.fetchSystemData()
    }
  }


  render() {
    const {
      classes,
      allColumns,
      status,
      selectableRow,
      onSelectRow,
      columns,
      rows,
      title,
      updateColumns,
      rowSorting,
      tiles,
      showNotificationsButton,
    } = this.props

    const { searchString, noDataMessage } = this.state

    return (
      <div className={classes.Root}>
        <div className={classes.TableHeader}>
          <div className={classes.TableTitle}>
            <Typography variant='h5'>
              {title}
            </Typography>
          </div>
          <SearchableTableToolbar
            updateSearchString={this.updateSearchString}
            allColumns={allColumns}
            selectedColumns={columns}
            updateColumns={updateColumns} />
        </div>
        <SearchableTableRows status={status}
          noDataMessage={noDataMessage}
          searchString={searchString}
          onSelectRow={onSelectRow}
          selectableRow={selectableRow}
          columns={columns}
          rows={rows}
          rowSorting={rowSorting}
          tiles={tiles}
          showNotificationsButton={showNotificationsButton} />
      </div>
    )
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    updateColumns: PropTypes.func.isRequired,
    allColumns: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    listItems: PropTypes.array,
    title: PropTypes.string,
    status: PropTypes.string,
    onSelectRow: PropTypes.func,
    full: PropTypes.bool,
    noDataMessage: PropTypes.string,
    rows: PropTypes.array,
    rowSorting: PropTypes.func,
    selectableRow: PropTypes.bool,
    tiles: PropTypes.func,
    showNotificationsButton: PropTypes.bool,
    fetchSystemData: PropTypes.func,
  }
}

export default withStyles(styles)(SearchableTable)
