import React from 'react'
import ListItem from '@mui/material/ListItem';
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import HouseholdIcon from '@mui/icons-material/Home'
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { getInitials } from '../utils/getInitials';
import moment from 'moment'
import './list.css'
import { pink } from '@mui/material/colors';
import { NoAssignedHouseholdsView } from './NoAssignedHouseholdsView';
import { Organisation } from '../../../../../api/OrganisationApi';
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography';
interface AssignedHouseholdListProps
{
  households: Organisation[];
  onItemClick: (item: Organisation) => void;
  onItemRemove: (item: Organisation) => void;
  selectedItem: Organisation | undefined;
  onAssignHousehold: () => void;
  isGettingUserDetails: boolean;
}

export const AssignedHouseholdList = ({ households, onItemClick, selectedItem, onItemRemove, onAssignHousehold, isGettingUserDetails }: AssignedHouseholdListProps) =>
{

  return(
    <div style={styles.container}>
      <div style={styles.headingWithIcon}> <HouseholdIcon  style={styles.icon} /> <Typography color='textSecondary'>ASSIGNED HOUSEHOLDS</Typography> </div>
      <List className="scrollBar" style={styles.list}>
        {
          !isGettingUserDetails && households && households.length > 0 ? households.filter(h => h.type === 'household').map((household, key) => {
            return <Card key={key} style={styles.containerCard}>

              <ListItem
                button
                style={{ backgroundColor: selectedItem && selectedItem.id === household.id ? '#EEEEEE' : "white" }} // Set selected item
                onClick={() => onItemClick(household)}
                secondaryAction={
                  <IconButton
                    onClick={() => onItemRemove(household)}
                    edge="end"
                    aria-label="delete" >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar style={styles.avatar}>
                    {getInitials(household.friendlyName)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={household.friendlyName}
                  secondary={moment(household.lastUpdated).fromNow()}
                />
              </ListItem>
            </Card>

          }) : isGettingUserDetails ? <div style={styles.progress}><CircularProgress /></div> : <NoAssignedHouseholdsView />
        }



      </List>
    </div>
  )
}

const styles = {
  containerCard: {
    margin: 8,
  },
  progress: {
    height: 400,
    alignItems: 'center',
    display: "flex",
    justifyContent: 'center',
  },
  avatar: {
    backgroundColor: pink[500],
    justifyContent: 'center',
  },
  list: {
    height: 400,
    overflowY: 'scroll' as 'scroll',
  },
  container: {
    textAlign: 'center' as 'center',
  },
  headingWithIcon: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    color: 'grey',
    marginRight: 6,
  },
}