import { IBehaviourData } from '@intelicare/shared/interfaces/behaviours/Behaviour';
import { SettingsActions } from './actions';

export const getBehavioursByZoneBegin = (householdId: string, query: "getAllBehaviours" | "getAndSortByZone" | "getByZone") =>
{
  return {
    type: SettingsActions.GET_BEHAVIOURS_BY_ZONE_BEGIN,
    data: { householdId, query },
  }
}

export const getBehavioursByZoneSuccess = (behaviours: IBehaviourData[]) =>
{
  return {
    type: SettingsActions.GET_BEHAVIOURS_BY_ZONE_SUCCESS,
    data: behaviours,
  }
}

export const getBehavioursByZoneError = (error: any) =>
{
  return {
    type: SettingsActions.GET_BEHAVIOURS_BY_ZONE_ERROR,
    error: error,
  }
}

export const getBehavioursBegin = (householdId: string, query: "getAllBehaviours" | "getAndSortByZone" | "getByZone") =>
{
  return {
    type: SettingsActions.GET_BEHAVIOURS_BEGIN,
    data: { householdId, query },
  }
}

export const getBehavioursSuccess = (behaviours: IBehaviourData[]) =>
{
  return {
    type: SettingsActions.GET_BEHAVIOURS_SUCCESS,
    data: behaviours,
  }
}

export const getBehavioursError = (error: any) =>
{
  return {
    type: SettingsActions.GET_BEHAVIOURS_ERROR,
    error: error,
  }
}

export const updateBehaviourBegin = (householdId: string, behaviour: IBehaviourData) =>
{
  return {
    type: SettingsActions.UPDATE_ZONE_BEHAVIOUR_SETTING_BEGIN,
    data: { householdId, behaviour },
  }
}

export const updateBehaviourSuccess = (behaviour: IBehaviourData) =>
{
  return {
    type: SettingsActions.UPDATE_ZONE_BEHAVIOUR_SETTING_SUCCESS,
    data: behaviour,
  }
}

export const updateBehaviourError = (error: any) =>
{
  return {
    type: SettingsActions.UPDATE_ZONE_BEHAVIOUR_SETTING_ERROR,
    error: error,
  }
}

export const clearSettingsActions = () =>
{
  return {
    type: SettingsActions.CLEAR_SETTINGS_ACTIONS,
  }
}