import React from 'react'
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import DialogActions from '@mui/material/DialogActions';

import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../interfaces/reduxState';

import { blue } from '@mui/material/colors';
import { accessRoles } from '../../../../../api/OrganisationApi';
interface RolesListModalProps {
  onRoleSelect: (role: string) => void;
  handleClose: () => void;
  open: boolean;

}

export const RolesListModal = ({ onRoleSelect, handleClose, open }: RolesListModalProps) => {
  const hiddenItems = ['Billing Owner', 'Clinical', 'Escalation User'];
  const isDeveloper = useSelector((state: ApplicationState) => state.user.isDeveloper);
  const isSupport = useSelector((state: ApplicationState) => state.user.isSupport);

  const accessRolesCheck = (isDeveloper || isSupport) ? accessRoles : accessRoles.filter(roles => !hiddenItems.includes(roles.name));

  return (
    <Dialog onClose={handleClose} open={open} >
      <DialogTitle>Assign role to user</DialogTitle>
      <List sx={{ pt: 0 }}>
        {accessRolesCheck
          .map((role) => (
            <ListItem button onClick={() => onRoleSelect(role.name)} key={role.name}>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: blue[300] }}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={role.name}
                secondary={role.description} />
            </ListItem>
          ))}

      </List>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
