import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import withStyles from '@material-ui/core/styles/withStyles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import styles from './SearchableTableStyles'

/**
 * Dialogs can be nested. This example opens a Date Picker from within a Dialog.
 */
class ViewTableColumns extends Component {

  constructor(props) {
    super(props)
    this.tableColumnsRef = React.createRef()
    this.state = {
      selected: props.selectedColumns,
    }
  }

  handleRowSelection = (rowValue) => {
    const { allColumns } = this.props
    const { selected } = this.state
    const wasSelected = selected.findIndex(c => c.value === rowValue)
    if (wasSelected === -1) {
      this.setState(state => ({
        ...state,
        selected: [...selected, allColumns.find(c => c.value === rowValue)],
      }))
    } else {
      const selectionUpdate = [...selected]
      selectionUpdate.splice(wasSelected, 1)
      this.setState(state => ({
        ...state,
        selected: [...selectionUpdate],
      }))
    }
  }

  updateAndClose = () => {
    const { updateColumns, close } = this.props
    updateColumns(this.state.selected)
    close()
  }

  render() {

    const {
      close,
      allColumns,
    } = this.props

    const {
      selected,
    } = this.state

    return (
      <div>
        <Dialog
          modal={false}
          open
          onClose={close}>
          <DialogTitle>
            Choose visible table columns
          </DialogTitle>

          <Table ref={this.tableColumnsRef} className={{}}>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Column Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allColumns.map((col) => {
                var isSelected = _.some(selected, { value: col.value })
                return (
                  <TableRow key={col.value} onClick={() => this.handleRowSelection(col.value)}>
                    <TableCell>
                      <Checkbox checked={isSelected} />
                    </TableCell>
                    <TableCell>
                      {col.label}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <DialogActions>
            <Button key={1} primary keyboardFocused onClick={this.updateAndClose}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    updateColumns: PropTypes.func.isRequired,
    close: PropTypes.func,
    allColumns: PropTypes.array,
    selectedColumns: PropTypes.array,
  }
}

export default withStyles(styles)(ViewTableColumns)
