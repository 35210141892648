import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Material UI Icons
import ClearIcon from '@mui/icons-material/Clear'

// Material UI components
import { CircularProgress, Grid, TextField, MenuItem, Select, InputAdornment, IconButton } from '@material-ui/core'

// The card item
import ListItem from '../../BatteryReportList/ListItem'

const DEFAULT_QUERY_LIMIT = 20

class BatteryReportTab extends Component {

  state = {
    currentFilterItemValue: DEFAULT_QUERY_LIMIT,
    searchTerm: "",
  }

  componentDidMount() {

    const { getAllBatteryLevels } = this.props
    getAllBatteryLevels({ queryString: `limit=${DEFAULT_QUERY_LIMIT}` })
  }

  handleOnSearch = (query, limit = DEFAULT_QUERY_LIMIT) => {

    const { getAllBatteryLevels } = this.props

    this.setState({ searchTerm: query, currentFilterItemValue: limit })

    if (query && query.length > 20) return getAllBatteryLevels({ queryString: `limit=${limit}&gatewayId=${query}` })
    if (query.length === 0) return getAllBatteryLevels({ queryString: `limit=${limit}` })

  }

  // Filter by gateway
  onFilterSort = (query) => {
    const { getAllBatteryLevels } = this.props
    getAllBatteryLevels({ queryString: `limit=${100}&gatewayId=${query}&sort=DESC` })
    this.setState({ searchTerm: query, currentFilterItemValue: 100 })
  }

  sortReportsByWorstBatteryLevels = (batteryReports) => {
    try {
      return batteryReports.sort((report1, report2) => this.getAverageLevel(report1) > this.getAverageLevel(report2) ? 1 : -1)
    }
    catch (error) {
      return []
    }
  }

  // Get the average battery level for the day per report item
  getAverageLevel = (report) => {
    const numSensors = report.sensors.length
    let total = 0
    report.sensors.map(sensor => total = total + sensor.batteryLevel)
    return total / numSensors
  }

  render() {

    const { isFetchingBatteryReport, allGatewaysBatteryReport } = this.props

    return (
      <div>
        <Grid style={{ padding: 10 }} container>
          <TextField
            InputProps={{
              endAdornment: (
                this.state.searchTerm ? <InputAdornment position="end">
                  <IconButton onClick={() => this.handleOnSearch("", DEFAULT_QUERY_LIMIT)}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment> : ""
              ),
            }}
            value={this.state.searchTerm}
            style={{ flex: 1, marginRight: 10 }}
            placeholder="Search by gateway ID"
            label="Search by gateway ID"
            onChange={(e) => this.handleOnSearch(e.target.value, DEFAULT_QUERY_LIMIT)}
            variant="outlined"></TextField>

          <Select
            value={this.state.currentFilterItemValue}
            type="select"
            onChange={(e) => this.handleOnSearch("", e.target.value)}
            variant="outlined">
            <MenuItem value={20}>Top 20</MenuItem>
            <MenuItem value={50}>Top 50</MenuItem>
            <MenuItem value={100}>Top 100</MenuItem>
            <MenuItem value={300}>All</MenuItem>
          </Select>
        </Grid>


        {
          isFetchingBatteryReport ?

            <Grid style={{ paddingTop: 100 }} container justify="center"><CircularProgress /></Grid>

            : <div>
              {allGatewaysBatteryReport && allGatewaysBatteryReport.length > 0 && this.sortReportsByWorstBatteryLevels(allGatewaysBatteryReport).map((report, key) => {

                // Sort the sensors by lowest first in the row per day
                report.sensors.sort((sensor1, sensor2) => (sensor1.batteryLevel > sensor2.batteryLevel) ? 1 : -1)

                return <ListItem onActionClick={(gatewayId) => this.onFilterSort(gatewayId)} batteryReport={report} key={key} />
              })}
            </div>
        }
      </div>
    )
  }
}

BatteryReportTab.propTypes = {
  getAllBatteryLevels: PropTypes.func,
  isFetchingBatteryReport: PropTypes.bool,
  allGatewaysBatteryReport: PropTypes.array,
}

export default BatteryReportTab