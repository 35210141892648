import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  TextField,
} from '@material-ui/core'
import { withTheme, withStyles } from '@material-ui/core/styles'
import Linkifier from 'react-linkifier';

import { Link } from 'react-router-dom'

import RenderIf from '../../components/Utils/RenderIf'

import './ActiveAlert.scss'
import Skeleton from 'react-loading-skeleton'
import access from 'safe-access'

import moment from 'moment'
import InteliIcon from '../InteliIcon'

const styles = theme => ({
  radioButtonRoot: {
    padding: '2px 10px',
  },
})

export class ActiveAlert extends Component {

  static propTypes = {
    updateResolutionType: PropTypes.func.isRequired,
    updateResolutionNote: PropTypes.func.isRequired,
    resolutionType: PropTypes.string,
    resolutionNote: PropTypes.string,
    alert: PropTypes.object,
    classes: PropTypes.object,
    showInputError: PropTypes.bool,
    isGettingActiveAlerts: PropTypes.bool,
    setAlertsMinimised: PropTypes.func,
    isDeveloper: PropTypes.bool,
    isSupport: PropTypes.bool,
  }

  getAlertedBehaviours = (description) => {
    if (!description) return []

    const behaviours = []
    description = description.toLowerCase()
    if (description.includes("meal preparation") || description.includes("meal activity")) {
      behaviours.push("Meal Preparation")
    }
    if (description.includes("nighttime") || description.includes("night-time") || description.includes("night time")) {
      behaviours.push("Night-time Activity")
    }
    return behaviours
  }

  render() {
    const {
      alert,
      resolutionNote,
      resolutionType,
      updateResolutionNote,
      updateResolutionType,
      showInputError,
      classes,
      isGettingActiveAlerts,
      setAlertsMinimised,
      isDeveloper,
      isSupport,
    } = this.props

    if (!alert) {
      return (
        <Grid>
          <Skeleton width={100} height={100} circle={true} />
          <Skeleton count={2} />
          <Skeleton height={100} style={{ marginTop: 16 }}/>
        </Grid>
      )
    }

    const description = access(alert, 'recipients[0].message')
    const alertedBehaviours = this.getAlertedBehaviours(description)
    return (
      <Grid container direction="column">
        <Grid container item xs direction="row" alignContent="flex-start">

          {!isGettingActiveAlerts ? <InteliIcon
            color="#306499"
            family="material"
            name="account_circle"
            size={100}
          /> : <Skeleton width={100} height={100} circle={true} />}
          <Grid container item xs direction="column" alignContent="flex-start" justifyContent="center">
            { !isGettingActiveAlerts ? <Link onClick={() => setAlertsMinimised(true)} to={`/households/${alert.householdId}`}>
              <Typography variant="h6" automation_attribute="alertHouseholdName">{alert.householdFriendlyName}</Typography>
            </Link> : <Skeleton />}
            <Typography color="textSecondary" variant="subtitle1" automation_attribute="alertTimestamp">{!isGettingActiveAlerts ? moment(alert.timestamp).format("LLL") : <Skeleton />}</Typography>
          </Grid>
        </Grid>

        <Grid item xs>
          <Typography variant="h6">
            { !isGettingActiveAlerts ? "Description" : <Skeleton /> }
          </Typography>
          <Typography color="textSecondary" automation_attribute="alertDescription">
            {!isGettingActiveAlerts ? <Linkifier >
              <div data-test="alertDescLink">{description}</div>
            </Linkifier> : <Skeleton />}
          </Typography>
        </Grid>

        <Grid item xs>
          { !isGettingActiveAlerts ?
            <Typography variant="h6">
            Resolution
            </Typography> : <Skeleton />}
          <FormControl component="fieldset">
            {!isGettingActiveAlerts ? <RadioGroup
              onChange={updateResolutionType}
              name='notification-type'
              value={resolutionType}
            >
              <FormControlLabel
                value="FALSE_ALARM"
                control={<Radio classes={{ root: classes.radioButtonRoot }} />}
                label="False alarm" />
              <FormControlLabel
                value="ATTENDED_TO"
                control={<Radio classes={{ root: classes.radioButtonRoot }} />}
                label="I have attended to it" />
            </RadioGroup> : <Skeleton /> }
          </FormControl>

        </Grid>

        <Grid item xs>
          {!isGettingActiveAlerts ? <RenderIf ifTrue={!!resolutionType}>
            <Typography variant="h6">
              Description
            </Typography>
            <TextField
              inputProps={{
                automation_attribute: "alertReason",
              }}
              error={showInputError}
              fullWidth={true}
              helperText="Detail about the notification to inform other carers (Required, certain special characters are not permitted)"
              multiLine={true}
              onChange={(event) => updateResolutionNote(null, event.target.value)}
              value={resolutionNote}
              rows={1}
            />
            {((isDeveloper || isSupport) && alertedBehaviours.length > 0) && <Typography style={{ color: 'red', marginTop: 5 }}>
              Alerting will be reset for the following behaviours: {alertedBehaviours.join(", ")}
            </Typography>}
          </RenderIf> : <Skeleton height={100} style={{ marginTop: 12 }}/> }
        </Grid>
      </Grid>
    )
  }
}


export default withTheme(withStyles(styles)(ActiveAlert))
