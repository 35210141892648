import { Organisation, UserDetails } from "../../../api/OrganisationApi";
import { OrganisationStore } from "./interfaces";


export const addUserToOrganisationReducer = (organisation: Organisation, state: OrganisationStore) =>
{
  // Check if the user had added themselves to the parent or a sub node
  if(organisation.id !== state.organisationDetails.id)
  {
    // return the original organisation
    return state.organisationDetails;
  }

  return organisation

}

export const updateUserCache = (user: UserDetails, state: OrganisationStore) =>
{
  try
  {
    if(user)
    {
      return {
        ...state.userCache,
        [user.id]: user,
      }
    }


    return { ...state.userCache }

  }
  catch(error)
  {
    console.log(error)
    return {
      ...state.userCache,
    }
  }

}