import { connect } from 'react-redux';
import { OrganisationsView } from '../component/OrganisationsView';
const mapStateToProps = (state: any) =>
{
  return {

  }
}

const mapDispatchToProps = (dispatch: any) =>
{
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationsView)