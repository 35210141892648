import { connect } from 'react-redux'
import { ActivityStreamList } from './ActivityStreamList'
import redux from '../../../../../redux'
const getActivityData = redux.householdsV2Selector.getActivityData
const callAPI = redux.userStore.callAPI
const HOUSEHOLD_V2_ACTIVITYSTREAM = redux.apiEndpoints.HOUSEHOLD_V2_ACTIVITYSTREAM

const mapDispatchToProps = (dispatch: any) => {
  return {
    getActivityFeed: (householdId: string, start: string, end: string) => {
      if (!householdId) return
      dispatch(callAPI(HOUSEHOLD_V2_ACTIVITYSTREAM({ householdId, start, end })))
    },
  }
}

const mapStateToProps = (state: any) => ({
  activities: getActivityData(state as any as never),
  isDeveloper: state.user.isDeveloper || false,
  isSupport: state.user.isSupport || false,
  isGettingActivityStream: state.householdsV2.isGettingActivityStream,
})

export default connect(mapStateToProps, mapDispatchToProps)(ActivityStreamList)
