import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import { FixedSizeList } from 'react-window'
import HomeIcon from '@mui/icons-material/Home'

const getSubText = (household, resellerId) => {
  if (household.resellerId === resellerId) return "Linked to this partner"
  if (household.resellerId === "None" || !household.resellerId) return "Not Linked"
  if (household.resellerId) return "Linked to another partner"
}

const HouseholdList = ({ households, onItemClick, resellerId }) => {

  HouseholdList.propTypes = {
    households: PropTypes.array,
    onItemClick: PropTypes.func,
    resellerId: PropTypes.string,
    index: PropTypes.number,
    style: PropTypes.object,
  }


  const renderListItem = (props) => {
    const { index, style } = props

    return (
      <ListItem
        onClick={households[index].resellerId === resellerId ? () => { } : () => onItemClick(households[index].id)} // Don't allow linking of an alredy linked household
        button={households[index].resellerId === resellerId ? false : true}
        style={style}
        key={index}>
        <ListItemAvatar>
          <Avatar>
            <HomeIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={households[index].friendlyName}
          secondary={getSubText(households[index], resellerId)} />
      </ListItem>
    )
  }

  return (
    <div>
      <FixedSizeList height={400} width={400} itemSize={60} itemCount={households ? households.length : 0}>
        {renderListItem}
      </FixedSizeList>
    </div>
  )
}

export { getSubText }
export default HouseholdList