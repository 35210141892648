import { combineReducers } from 'redux'
import featurePreviews from './domains/featurePreviews/featurePreviewsStore'
import sharedStore from '../redux'
import exportDataStore from '../redux/domains/export/exportStore'
import { organisationReducer } from '../redux/domains/organisations/organisationReducer'
import { zoneReducer } from '../redux/domains/zones/zoneReducer'
import { settingsReducer } from '../redux/domains/settings/settingsReducer'


const appReducer = combineReducers({
  location: sharedStore.locationStore.default,
  graphs: sharedStore.graphsStore.default,
  households: sharedStore.householdsStore.default,
  householdsV2: sharedStore.householdsV2Store.default,
  reports: sharedStore.reportStore.default,
  datafeed: sharedStore.dataFeedStore.default,
  system: sharedStore.systemStore.default,
  user: sharedStore.userStore.default,
  notifications: sharedStore.notificationsStore.default,
  alexa: sharedStore.alexa.default,
  healthMetrics: sharedStore.healthMetrics.default,
  featurePreviews: featurePreviews,
  exportData: exportDataStore,
  organisations: organisationReducer,
  zones: zoneReducer,
  settings: settingsReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined
    //AppInsights.clearAuthenticatedUserContext()
  }

  return appReducer(state, action)
}

export default rootReducer
