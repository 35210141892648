import React from "react";
import moment from 'moment';

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { Sensor, ISensor, SENSOR_STATUS_REASONS } from "./Sensor";
import { getGen2OfflineReason } from "./utils/getGen2OfflineReason";

interface SensorListProps {
  household: any;
  classes: any;
  sensorsList: [];
  isDeveloper: boolean;
  isSupport: boolean;
  onDeleteModalOpen(sensor: ISensor): void;
  onUpdatePendantModalOpen(sensor: ISensor): void;
  hasZones: boolean;
}


export const SensorsList = ({
  household,
  classes,
  sensorsList,
  isDeveloper,
  isSupport,
  hasZones,
  onDeleteModalOpen,
  onUpdatePendantModalOpen,
}: SensorListProps) => {
  if (!household || !household.data || !household.data.sensors) {
    return (
      <div>
        <h3>{household ? household.friendlyName : undefined}</h3>
        <p>No data available</p>
      </div>
    );
  }

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell align="left">Status</TableCell>
          <TableCell align="left">Sensor</TableCell>
          {hasZones && <TableCell align="left">Zone</TableCell>}
          <TableCell align="left">Room</TableCell>
          <TableCell align="left">Last seen</TableCell>
          <TableCell align="left">Battery level</TableCell>
          <TableCell align="left">Status reason</TableCell>
          <TableCell align="left">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sensorsList.map((sensor: ISensor) => {
          if (sensor && sensor.id)
          {
            if (sensor.type === 'guardian-ai' && sensor.lastSeen) sensor = getGen2OfflineReason(sensor)
            if(sensor.type === 'pani') sensor = getGen2OfflineReason(sensor);
            return (
              <Sensor
                hasZones={hasZones}
                classes={classes}
                onDeleteModalOpen={() => onDeleteModalOpen(sensor)}
                onUpdatePendantModalOpen={() => onUpdatePendantModalOpen(sensor)}
                isDeveloper={isDeveloper}
                isSupport={isSupport}
                key={`systemSensor_${sensor.id}`}
                id={sensor.id}
                data={sensor}
                timezone={household.data.timezone}
              />
            );
          }
        })}
      </TableBody>
    </Table>
  );
};
