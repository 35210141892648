import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

import PropTypes from 'prop-types'
export default class TopFilterBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filterField: {
        searchValue: "",
        dataSize: 50,
        dataSizeSequence: 1,
        searchField: 'friendlyname',
      },
      showAlert: true,
    }
  }

  handleFilterField = event => {
    event.preventDefault()
    const { name, value } = event.target
    this.setState({
      filterField: { ...this.state.filterField, [name]: value },
    })
  }

  handleSearch = (e) => {
    e.preventDefault()
    const filter = {
      searchValue: !this.state.filterField.searchValue ? " " : this.state.filterField.searchValue,
      dataSize: this.state.filterField.dataSize,
      dataSizeSequence: this.state.filterField.dataSizeSequence,
      searchField: this.state.filterField.searchField || 'friendlyname',
    }
    this.props.fetchFirmwareData(filter)
  }

  render() {
    const { totalDataSizeSequence = 1 } = this.props
    const { searchValue, dataSizeSequence } = this.state.filterField
    const searchList = [
      'friendlyname',
      'gatewayid',
      'Firmware Version',
      'Update Status',
    ]
    const dataSizeList = [
      50,
      100,
      200,
      300,
    ]

    const dataSizeNumberList = () => {
      let list = []
      for (let i = 1; i <= totalDataSizeSequence; i++) {
        list.push(i)
      }
      list.splice((dataSizeSequence - 1), 1)
      return list
    }
    return (
      <form onSubmit={(e) => this.handleSearch(e)}>
        <Grid container spacing={2} style={{ padding: 12 }}>
          <Grid item xs={6}>
            <TextField
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={this.handleSearch}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>,
              }}

              label={`Search by ${this.state.filterField.searchField}`}
              fullWidth
              variant="outlined"
              placeholder={`Search by ${this.state.filterField.searchField}`}
              name='searchValue'
              type="text"
              value={searchValue}
              onChange={this.handleFilterField}
            />
          </Grid>

          <Grid item>
            <Select
              variant="outlined"
              name='searchField'
              onChange={this.handleFilterField}
              value={this.state.filterField.searchField}
            >
              {searchList &&
                    searchList.map(item => (
                      <MenuItem key={item} value={item}>
                        Search by {item}
                      </MenuItem>
                    ))}
            </Select>
          </Grid>
          <Grid item>
            <Select
              variant="outlined"
              name='dataSize'
              onChange={this.handleFilterField}
              value={this.state.filterField.dataSize}
            >
              {dataSizeList &&
                    dataSizeList.map(item => (
                      <MenuItem key={item} value={item}>
                        Rows: {item}
                      </MenuItem>
                    ))}
            </Select>
          </Grid>
          <Grid item>
            <Select
              variant="outlined"
              name='dataSizeSequence'
              onChange={this.handleFilterField}
              value={this.state.filterField.dataSizeSequence}
            >
              <MenuItem key={dataSizeSequence} value={dataSizeSequence}>Page: {dataSizeSequence} </MenuItem>
              {totalDataSizeSequence &&
                    dataSizeNumberList().map(item => (
                      <MenuItem key={item} value={item}>
                        Page: {item}
                      </MenuItem>
                    ))}
            </Select>
          </Grid>
          <Button
            startIcon={<SearchIcon />}
            type="submit"
            onClick={this.handleSearch}>
              Search
          </Button>
        </Grid>

      </form>
    )
  }
}

TopFilterBar.propTypes = {
  handleFilterField: PropTypes.string,
  filterField: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
  totalDataSizeSequence: PropTypes.number,
  fetchFirmwareData: PropTypes.func,
}