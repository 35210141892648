import moment from 'moment'

export const colors = ["#D2233B", "#F4763B", "#94CC79", "#005879", "#34115A"]

const _getTimescale = (data, width, d3) => {
  const info = data.info

  const startTime = moment(info.starttime)
  const endTime = moment(info.endtime)

  //create a scale of type time (maps from time value to drawing)
  return d3.scaleTime()
    .domain([startTime, endTime])
    .range([30, width])
}

const _getYScale = (data, height, d3) => {
  let maxCount = data.info.max
  return d3.scaleLinear()
    .domain([0, maxCount])
    .range([height, 10])
}

export const getPathData = (data, size, d3) => {
  let timescale = _getTimescale(data, size.width, d3)
  let yscale = _getYScale(data, size.height, d3)

  const valueline = d3.line()
    .curve(d3.curveMonotoneX)
    .x((d) => { return timescale(moment(d.timestamp)) })
    .y((d) => { return yscale(d.value) })

  const simplifiedData = data.data
  return {
    data: valueline(simplifiedData),
  }
}


export const getBaselineData = (data, size, d3) => {
  let timescale = _getTimescale(data, size.width, d3)
  let yscale = _getYScale(data, size.height, d3)

  const startTime = moment(data.info.starttime)
  const endTime = moment(data.info.endtime)

  let ty1 = data.trendline.slope * startTime.unix() + data.trendline.yintercept
  let ty2 = data.trendline.slope * endTime.unix() + data.trendline.yintercept

  let straightLineFunc = d3.line()
    .x((d) => { return d.x })
    .y((d) => { return d.y })

  let baselineData = straightLineFunc([
    { x: timescale(startTime), y: yscale(ty1) },
    { x: timescale(endTime), y: yscale(ty2) },
  ])

  return {
    timescale: timescale,
    yscale: yscale,
    data: baselineData,
  }
}

// eslint-disable-next-line no-unused-vars
export const getAxisData = (data, size, d3) => {
  const info = data.info

  var startTime = moment(info.starttime).fromNow()

  let max = data.data.map((datum) => datum.value).sort().pop()

  return {
    x: [startTime, "now"],
    y: [0, max],
  }
}
