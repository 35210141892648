import { useState } from 'react'

const useFilter = (events = []) => {
  const [term, setTerm] = useState("")

  events = events.filter(event => {
    try {
      if (event.deviceId.includes(term)
        || event.sensorId.includes(term)
        || event.deviceType.toLowerCase().includes(term)
        || event.roomType.toLowerCase().includes(term)) {
        return true
      }
    }
    catch (error) {
      // error
    }

  })

  return [events, setTerm]
}
export default useFilter