import React, { Component } from 'react'
import PropTypes from 'prop-types'
import access from 'safe-access'

import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'

import styles from './BehaviourTooltipStyle'

import { BEHAVIOUR_TYPES } from '@intelicare/shared/enums'
import { helper, colors } from '../../../../../redux'
const friendlyDateTime = helper.friendlyDateTime
const colorForStatusValue = colors.colorForStatusValue


class SensorDetailsView extends Component {
  render() {
    const {
      behaviour,
      sensors,
      classes,
      household,
    } = this.props

    if (!Array.isArray(sensors)) return (<div></div>)

    let renderedSensors = sensors.map((sensor, i) => {
      const statusColor = colorForStatusValue(access(sensor, 'status.value'))
      return (
        <div key={i} className={classes.margin}>
          <CardContent classes={{ root: classes.sensorCardContent }}>
            <Typography variant="overline">{sensor.friendlyname || sensor.friendlyName}</Typography>
            <Typography>
              {
                // Used for climate
              }
              {sensor.status &&
                <span>
                  <span className={classes.StatusBadge} style={{ backgroundColor: statusColor }}>
                    {sensor.status.value}</span> {sensor.status.msg}<br />
                </span>
              }
              {sensor.latestReading && sensor.latestReading.state &&
                <span>
                  Last reading ({sensor.latestReading.state}) {friendlyDateTime(sensor.latestReading.timestamp, household.timezone)}<br />
                </span>
              }
              {
                // Used for meal preparation
              }
              {(!(sensor.friendlyTime || sensor.latestReading)) &&
                <span>No data available</span>
              }
              {behaviour.type === BEHAVIOUR_TYPES.MEAL_PREP && sensor.friendlyTime &&
                <span>
                  Last used {sensor.friendlyTime.toLocaleLowerCase()}<br />
                </span>
              }
            </Typography>
          </CardContent>
        </div>
      )
    })

    return (
      <div>
        {sensors.length > 0 &&
          <div>
            {renderedSensors}
          </div>
        }
      </div>
    )
  }

  static propTypes = {
    household: PropTypes.any.isRequired,
    behaviour: PropTypes.any.isRequired,
    sensors: PropTypes.any.isRequired,
    classes: PropTypes.any.isRequired,
  }
}

export default withStyles(styles)(SensorDetailsView)
