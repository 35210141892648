import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Title from '../../../../../components/Title'
import CircularProgress from '@material-ui/core/CircularProgress'
import access from 'safe-access'
import GatewaySearchableTable from '../containers/GatewaySearchableTable'

export default class GatewayDetailsView extends Component {
  static friendlyName = 'Gateway Details View'
  static pageName = 'gatewayDetailsView'
  static propTypes = {
    fetchGatewayData: PropTypes.func,
    params: PropTypes.array,
    isFetching: PropTypes.bool,
    gatewayTitle: PropTypes.string,
  }

  componentDidMount() {
    const {
      fetchGatewayData,
      match,
    } = this.props
    fetchGatewayData(access(match, 'params.gatewayId'))
  }

  render() {
    const {
      isFetching,
      gatewayTitle,
    } = this.props

    return (
      <div className="col-main-content">
        <Title title='InteliCare system health' />
        {isFetching &&
          <CircularProgress className='system--container-busy' color='#c4274e' />
        }
        {!isFetching &&
          <GatewaySearchableTable title={gatewayTitle} noDataMessage='No sensors available' />
        }
      </div>
    )
  }
}

GatewayDetailsView.propTypes = {
  fetchGatewayData: PropTypes.func.isRequired,
  gatewayTitle: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  match: PropTypes.object,
}
