import React from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import HouseholdsIcon from '@mui/icons-material/HouseRounded';
import UsersIcon from '@mui/icons-material/Person';
import Groups from '@mui/icons-material/Groups';

interface TabViewProps {
  currentTab: number;
  onChange: (i: number) => void
}

export const TabView = ({ currentTab, onChange }: TabViewProps) => {
  return (
    <Tabs
      style={styles.container}
      textColor="secondary"
      indicatorColor="secondary"
      value={currentTab}
      onChange={(e: any, i: number) => onChange(i)}
      aria-label="Organisation detail view tabs">
      <Tab icon={<Groups />} label="Org Structure" />
      <Tab icon={<UsersIcon />} label="User Admin" />
      <Tab icon={<HouseholdsIcon />} label="Households" />
    </Tabs>
  )
}

const styles = {
  container: {
    paddingBottom: 16,
  },
}