import React from 'react'
import PropTypes from 'prop-types'

const RenderIf = ({ ifTrue, children }) => (
  <div>
    {ifTrue && children}
  </div>
)

RenderIf.propTypes = {
  ifTrue: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
}

export default RenderIf
