import { Builder } from "./ApiBuilder";

export interface IGsmRunStatistics
{
  docType: "gsmRunStatistics"
  success: boolean;
  runDuration: number;
  runStart: string;
  runEnd: string;
  nextRunTimestamp: string;
  lastRunTimestamp: string;
  notificationsProcessed: number;
  runTimezones: string[];
  pastDueTimezones: string[];
  error: string;
}

export class GsmStatsApi extends Builder
{
  constructor(public token: string, public principal: string)
  {
    super(true)
  }

  getStats = async (timeRange: string, order: "ASC" | "DESC"): Promise<IGsmRunStatistics[] | undefined> =>
  {
    try
    {
      const respsonse = await this.call(`GET`, `/api/system/management?getGsmStats=true&timeRange=${timeRange}&order=${order}`, null, this.token);
      if(respsonse && respsonse.data) return respsonse.data
      return undefined
    }
    catch(error)
    {
      return undefined;
    }
  }

}