
// const drawerWidth = '20vw' //max 310 min 200
const drawerWidth = '20vw'

export default theme => ({
  root: {},
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.7em',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      backgroundColor: 'rgba(0,0,0,.08)',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '20px',
      // outline: '1px solid slategrey'
    },
    '*::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'rgba(0,0,0,.3)',
    },
  },
  MainMenu: {
    // width: drawerWidth,
  },
  Header: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    left: `clamp(200px,${drawerWidth},310px)`,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  MainContent: {
    position: 'absolute',
    left: `clamp(200px,${drawerWidth},310px)`,
    top: '0px',
    right: '0px',
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  ContentContainer: {
    marginTop: 64,
    width: '100%',
    height: '100%',
  },
  contentShift: {
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    left: 0,
  },
})
