import { Button, Grid, Typography } from '@material-ui/core'
import React from 'react'

const iHealthIcon = require('./assets/iHealth_icon.png')
const AppleIcon = require('./assets/Apple_health_icon.png')
const GarminIcon = require('./assets/Garmin_icon.png')
const DexcomIcon = require('./assets/Dexcom_icon.png')
const OmronIcon = require('./assets/Omron_icon.jpg')
const FitbitIcon = require('./assets/Fitbit_icon.png')

const DEVICE_TO_ICON: {[key: string]: any} = {
  'IHEALTH': iHealthIcon,
  'APPLE': AppleIcon,
  'GARMIN': GarminIcon,
  'DEXCOM': DexcomIcon,
  'OMRON': OmronIcon,
  'FITBIT': FitbitIcon,
}

interface Device {
  deviceId: string;
  deviceType: string;
}

interface HealthMetricsDevicesProps {
  devices: Device[];
}

export const HealthMetricsDevices = (props: HealthMetricsDevicesProps) => {
  const { devices } = props;
  return (
    <Grid style={{ width: 400, paddingLeft: 10 }}>
      {devices && (devices.length > 0 ?
        devices.map((d, index) => {
          return (
            <Grid container key={index} style={{ paddingBottom: 12 }}>
              <div style={{ display: "flex", flexDirection: 'row', flex: 1 }}>
                <img style={{ paddingRight: 5 }} width={35} height={35} src={DEVICE_TO_ICON[d.deviceType]}/>
                <Typography variant="h6">{d.deviceType} Account</Typography>
              </div>
              <Typography variant="h6" style={{ flex: 1 }}><i>Synced</i></Typography>
            </Grid>
          )
        }) :
        <Typography variant="h6">No devices Found</Typography>
      )}
    </Grid>
  )
}