// Helper class to compare two different time ranges

import * as Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);
const TIME_FORMAT = "HH:mm";

interface AlertWindow
{
  start: string;
  end: string;
}

export class Compare {
  alertWindow1: AlertWindow;
  alertWindow2: AlertWindow;

  constructor(alertWindow1: AlertWindow, alertWindow2: AlertWindow) {
    this.alertWindow1 = alertWindow1;
    this.alertWindow2 = alertWindow2;
  }

  hasOverlapInt = (): boolean => {
    return true;
  }

  hasOverlap = (): boolean => {

    try
    {
      // Convert the alertWindows to moment values
      const alertWindow1Start = moment(this.alertWindow1.start, TIME_FORMAT);
      const alertWindow1End = moment(this.alertWindow1.end, TIME_FORMAT);
      if(alertWindow1End <= alertWindow1Start || alertWindow1Start === alertWindow1End) alertWindow1End.add(24, "hours")
      const alertWindow2Start = moment(this.alertWindow2.start, TIME_FORMAT);
      const alertWindow2End = moment(this.alertWindow2.end, TIME_FORMAT);
      if(alertWindow2End <= alertWindow2Start || alertWindow2Start === alertWindow2End) alertWindow2End.add(24, "hours")
      if (alertWindow1End <= alertWindow2Start) {
        alertWindow2Start.subtract(24, "hours")
        alertWindow2End.subtract(24, "hours")
      } else if (alertWindow2Start.clone().add(24, "hours") < alertWindow1End) {
        alertWindow2Start.add(24, "hours")
        alertWindow2End.add(24, "hours")
      }
      // Create the ranges
      const alertWindow1Range = moment.range(
        alertWindow1Start,
        alertWindow1End
      );
      const alertWindow2Range = moment.range(
        alertWindow2Start,
        alertWindow2End
      );
      // Check if they intersect
      const intersect = alertWindow1Range.intersect(alertWindow2Range)
      if(intersect) return true
      return false
    }
    catch(error)
    {
      return false
    }
  }}