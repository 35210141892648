import {
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'

function* loginAndGetUsers(action) {

  try {
    yield put({ type: "LOGIN", details: action.details })
  } catch (e) {
    yield put({ type: "PATH_CHANGE", path: '/households' })
  }
}

/*
  Starts loginAndGetUsers on each dispatched `LOGIN_START` action.
  Allows concurrent fetches.
*/
function* loginSagaWeb() {
  yield takeLatest("LOGIN_START", loginAndGetUsers)
}

export default loginSagaWeb
