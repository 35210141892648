import React from 'react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@mui/icons-material/Clear'

interface SearchBarProps
{
  value: string;
  onTextChange: (q: string) => void;
  title: string;
  onClear: () => void;
}

export const SearchBar = ({ value, onTextChange, title, onClear }: SearchBarProps) =>
{
  return(
    <TextField
      style={styles.container}
      onChange={(e: any) => onTextChange(e.target.value)}
      value={value}
      variant="outlined"
      fullWidth
      placeholder={title}
      InputProps={{
        endAdornment: <InputAdornment position="end"><IconButton onClick={onClear}><ClearIcon /></IconButton></InputAdornment>,
      }}
      label={title} />

  )
}

const styles = {
  container: {
    paddingBottom: 12,
  },
}