
export const iconForEvent = (activity) => {
  switch (activity) {
    case 'Arise':
      return { name: 'wb_sunny' }
    case 'Sleeping':
      return { name: 'hotel' }
    case 'Bathroom':
      return { name: 'wc' }
    case 'Up and about':
      return { name: 'directions_walk' }
    case 'Away from home':
      return { name: 'directions_bike' }
    case 'Leave home':
      return { name: 'home' }
    case 'Arrive home':
      return { name: 'exit_to_app' }
    case 'Kitchen Activity':
      return { name: 'local_dining' }
    case 'Resting':
      return { name: 'airline_seat_recline_extra' }
    case "mealPrep":
      return { name: "restaurant" }
    case "washing":
      return { name: "local_laundry_service" }
    case "tracker":
      return { name: "home" }
    case 'pendant':
      return { name: 'watch' }
    case 'shower':
      return { name: 'bath', family: 'awesome' }
    case 'bathVisit':
      return { name: 'sink', family: 'custom' }
    case 'bathVisitSmall':
      return { name: 'sink-small', family: 'custom' }
    case 'bed-occupied':
      return { name: 'bed', family: 'awesome' }
    case 'bed-unoccupied':
      return { name: 'bed-unoccupied', family: 'custom' }
    case 'in-range':
      return { name: 'person' }
    case 'out-of-range':
      return { name: 'person_remove' }
    case 'connection-lost':
      return { name: 'wifi-cancel' }
    case 'Door Closed':
      return { name: 'door-closed', family: 'awesome' }
    case 'Door Opened':
      return { name: 'door-open', family: 'awesome' }
    default:
      return { name: 'wifi' }
  }
}

export const nameForActivity = (activity) => {
  switch (activity.activityType) {
    case 'tracker':
      return activity.state === 'home' ? 'Arrived Home' : 'Not Home'
    case 'mealPrep':
      return 'Meal Preparation'
    case 'domesticActivity':
      return 'Domestic Activity'
    case 'pendant':
      return 'Triggered!'
    case 'washing':
      return 'Washing'
    case 'bathVisit':
      return 'Bathroom Visit'
    case 'bed-occupied':
      return 'Bed Occupied'
    case 'bed-unoccupied':
      return 'Bed Unoccupied'
    case 'Resting':
      return 'No Activity'
    case 'in-range':
      return 'In Range'
    case 'out-of-range':
      return 'Out of Range'
    case 'connection-lost':
      return 'Connection Lost'
    default:
      return activity.activityType
  }
}

export const previewNameForActivity = (activity) => {
  switch (activity.activityType) {
    case 'tracker':
      return activity.state === 'home' ? 'Arrived Home' : 'Not Home'
    case 'mealPrep':
      return 'Meal Preparation'
    case 'domesticActivity':
      return 'Domestic Activity'
    case 'pendant':
      return 'Triggered!'
    case 'washing':
      return 'Washing'
    case 'bathVisit':
      return 'Bathroom Visit'
    case 'bed-occupied':
      return 'Bed Occupied'
    case 'bed-unoccupied':
      return 'Bed Unoccupied'
    case 'Resting':
      return 'No Activity'
    case 'in-range':
      return `In Range (${activity.friendlyName})`
    case 'out-of-range':
      return `Out of Range (${activity.friendlyName})`
    case 'connection-lost':
      return `Connection Lost (${activity.friendlyName})`
    case 'Door Opened':
      return `Opened (${activity.friendlyName})`
    case 'Door Closed':
      return `Closed (${activity.friendlyName})`
    default:
      return activity.activityType
  }
}
