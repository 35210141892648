import React, { useState } from "react";
import { useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { convertTimeStringToUTC, UTCtoLocal, LocalToPretty } from "./helper/index";
import { Compare } from "./helper/Compare";
import { TimeRange } from "./TimeRange";
import { isInputValid } from "./helper/inputValidator";
import { ApplicationState } from "../../interfaces/reduxState";
import { BEHAVIOUR_TYPES } from "@intelicare/shared/enums";
import { IBehaviourData } from "@intelicare/shared/interfaces/behaviours/Behaviour"


interface DoorBehaviour extends IBehaviourData
{
  alertWindow: {
    start: string;
    end: string;
  }
}

interface ConfigureAlertsModalProps {
  handleClose(): void;
  open: boolean;
  type: string;
  onUpdate: (behaviour: DoorBehaviour) => void;
  zoneId: string;
}

export const ConfigureAlertsModal = ({ handleClose, open, type, onUpdate, zoneId }: ConfigureAlertsModalProps) => {

  const [ alertWindowStart, setAlertWindowStart ] = useState("")
  const [ alertWindowEnd, setAlertWindowEnd ] = useState("")
  const [ doorInfoWindowStart, setDoorInfoWindowStart ] = useState("")
  const [ doorInfoWindowEnd, setDoorInfoWindowEnd ] = useState("")
  const [ timeConflictError, setTimeConflictError ] = useState("")

  const getDoorAlertBehaviour = (type: BEHAVIOUR_TYPES): DoorBehaviour =>
  {
    const settings = useSelector(({ settings }: ApplicationState) => settings)

    if(settings.zones && settings.zones.length > 0)
    {
      const currentZone = settings.zones.find(zone => zone.zoneId === zoneId);
      const doorAlertBehaviour = currentZone?.behaviours.find((behaviour: IBehaviourData) => behaviour.type === type) as DoorBehaviour
      return doorAlertBehaviour
    }

    return settings.behaviours.find(behaviour => behaviour.type === BEHAVIOUR_TYPES.DOOR_ALERT) as DoorBehaviour

  }

  const doorAlertBehaviour = getDoorAlertBehaviour(BEHAVIOUR_TYPES.DOOR_ALERT)
  const doorInfoBehaviour = getDoorAlertBehaviour(BEHAVIOUR_TYPES.DOOR_INFO_MESSAGE)



  // onEnter - set the current alert window
  const setCurrentAlertWindow = () => {

    // If the alert behaviour data exists then set that
    if (doorAlertBehaviour && doorAlertBehaviour.alertWindow)
    {
      setAlertWindowStart(UTCtoLocal(doorAlertBehaviour.alertWindow.start))
      setAlertWindowEnd(UTCtoLocal(doorAlertBehaviour.alertWindow.end))
    }

    // If the door info behaviour data exists then set that
    if (doorInfoBehaviour && doorInfoBehaviour.alertWindow)
    {
      setDoorInfoWindowStart(UTCtoLocal(doorInfoBehaviour.alertWindow.start))
      setDoorInfoWindowEnd(UTCtoLocal(doorInfoBehaviour.alertWindow.end))
    }
  };

  // Run the API call
  const handleUpdateAlertTimes = () => {

    const doorAlertWindow =  {
      start: convertTimeStringToUTC(alertWindowStart),
      end: convertTimeStringToUTC(alertWindowEnd),
    };

    const doorInfoMessageWindow = {
      start: convertTimeStringToUTC(doorInfoWindowStart),
      end: convertTimeStringToUTC(doorInfoWindowEnd),
    }


    // Run the API calls
    onUpdate({ ...doorAlertBehaviour, alertWindow: doorAlertWindow });
    setTimeout(() => {
      onUpdate({ ...doorInfoBehaviour, alertWindow: doorInfoMessageWindow });

    }, 3000)

    handleClose();
  };

  const validateAlertStartTime = (date: string) => {
    setAlertWindowStart(date)
    performValidation();
  };

  const validateAlertEndTime = (date: string) => {
    setAlertWindowEnd(date)
    performValidation()
  };

  const validateInfoStartTime = (date: string) => {
    setDoorInfoWindowStart(date)
    performValidation()
  };

  const validateInfoEndTime = (date: string) => {
    setDoorInfoWindowEnd(date)
    performValidation()
  };

  const resetFields = () => {
    setAlertWindowStart("")
    setAlertWindowEnd("")
    setDoorInfoWindowStart("")
    setDoorInfoWindowEnd("")
    setTimeConflictError("")
  };

  const performValidation = () => {

    const compare = new Compare(
      {
        start: alertWindowStart,
        end: alertWindowEnd,
      },
      {
        start: doorInfoWindowStart,
        end: doorInfoWindowEnd,
      }
    );

    if (compare.hasOverlap())
    {
      return setTimeConflictError(`Door alert window ${LocalToPretty(alertWindowStart)} - ${LocalToPretty(alertWindowEnd)} conflicts with door info message window of ${LocalToPretty(
        doorInfoWindowStart
      )} - ${LocalToPretty(doorInfoWindowEnd)}`)
    }
    return setTimeConflictError("")
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onEnter={setCurrentAlertWindow}
      open={open}
      onClose={handleClose}
      aria-labelledby="configure-alerts-dialog-title"
      aria-describedby="configure-alerts-dialog-description"
    >
      <DialogTitle id="configure-alerts-dialog-title">
        <NotificationsIcon
          style={{
            position: "relative",
            top: 5,
            right: 5,
          }}
        />
        {type === "doorAlert" ? `Configure the alert time range` : `Configure the door info message time range`}
      </DialogTitle>

      <DialogContent style={styles.container}>
        <DialogContentText align="center" id="alert-dialog-description">
            Set the start time and end time to send an Alert if an exit door is opened.
        </DialogContentText>

        <Grid container direction="row" justify="center">
          <TextField
            onChange={(e) => validateAlertStartTime(e.target.value)}
            helperText="Start Time"
            style={styles.input}
            value={alertWindowStart}
            id="start-time"
            type="time"
            variant="outlined"
          />
          <TextField onChange={(e) => validateAlertEndTime(e.target.value)} helperText="End Time" style={styles.input} value={alertWindowEnd} id="end-time" type="time" variant="outlined" />
        </Grid>

        <DialogContentText align="center" id="alert-dialog-description">
            Set the start time and end time to send an information message if an exit door is opened.
        </DialogContentText>

        <Grid container direction="row" justify="center">
          <TextField
            onChange={(e) => validateInfoStartTime(e.target.value)}
            helperText="Start Time"
            style={styles.input}
            value={doorInfoWindowStart}
            id="start-time"
            type="time"
            variant="outlined"
          />
          <TextField onChange={(e) => validateInfoEndTime(e.target.value)} helperText="End Time" style={styles.input} value={doorInfoWindowEnd} id="end-time" type="time" variant="outlined" />
        </Grid>

        <TimeRange
          alertWindowStart={alertWindowStart}
          alertWindowEnd={alertWindowEnd}
          doorInfoWindowStart={doorInfoWindowStart}
          doorInfoWindowEnd={doorInfoWindowEnd}
        />

        {timeConflictError && <Alert severity="error">{timeConflictError}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button onClick={resetFields} color="primary">
            Reset
        </Button>
        <Button onClick={handleClose} color="primary">
            Close
        </Button>
        <Button disabled={isInputValid(timeConflictError, alertWindowStart, alertWindowEnd, doorInfoWindowStart, doorInfoWindowEnd)} onClick={handleUpdateAlertTimes} color="primary" autoFocus>
            Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = {
  container: {
    padding: 12,
  },
  input: {
    margin: 12,
  },
};
