import React from 'react'
import Skeleton from 'react-loading-skeleton'

export const LoadingView = () =>
{
  return(
    <div style={styles.container}>
      <Skeleton count={20} height={60} style={styles.item}/>
    </div>
  )
}

const styles = {
  container: {
    paddingTop: 12,
  },
  item: {
    marginTop: 6,
    marginBottom: 6,
  },
}