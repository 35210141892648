import { useEffect, useState } from 'react'
import { AuditLogsApi, AuditRecord } from '../../../../../api/AuditLogsApi';
const PAGINAGTION_COUNT = 20;
const DEBOUNCE_TIMEOUT = 1500

type AuditLogsHook = [
    boolean, // Loading
    string, // query
    () => void, // Page back
    () => void, // Page forward
    number, // Current page
    AuditRecord[],
    (query: string) => void,
    () => void, // Home
    () => void, // Clear search
  ]

export const useAuditLogs = (token: string): AuditLogsHook =>
{
  const [ isLoading, setIsLoading ] = useState(false);
  const [ query, setQuery ] = useState("");
  const [ order, setOrder ] = useState<"ASC" | "DESC">("DESC");
  const [ offset, setOffset ] = useState(0);
  const [ limit ] = useState(PAGINAGTION_COUNT);
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ auditRecords, setAuditRecords ] = useState<AuditRecord[]>([])
  const [ typing, setTyping ] = useState(false)
  const [ timeoutId, setTimeoutId ] = useState(0)

  useEffect(() => {
    getAuditLogs(query, order, offset, limit);
  }, [offset])

  const navHome = () =>
  {
    setOffset(0)
    setCurrentPage(1)
    getAuditLogs(query, order, offset, limit)
  }

  const clearSearch = () =>
  {
    setQuery("")
    setOffset(0)
    setCurrentPage(1)
    getAuditLogs("", order, offset, limit)
  }

  const handleSearchWithDebounce = async (query: string) =>
  {
    setTyping(true)
    clearTimeout(timeoutId)
    setQuery(query)

    if(query.length > 2)
    {

      const id = setTimeout(() => {

        // Once the timeout has passed, set typing false
        setTyping(false)
        getAuditLogs(query, order, offset, limit)
        clearTimeout(id)


      }, DEBOUNCE_TIMEOUT)


      setTimeoutId(id as unknown as any);


    }

    if(query.length === 0) getAuditLogs(query, order, offset, limit)

  }

  const pageForward = () =>
  {
    if(offset >= 0)
    {
      setCurrentPage(currentPage + 1)
      setOffset(offset + PAGINAGTION_COUNT)
      getAuditLogs(query, order, offset, limit)
    }

  }

  const pageBack = () =>
  {
    if(offset >= 0)
    {

      if(currentPage > 1)
      {
        setCurrentPage(currentPage - 1)
      }
      else
      {
        setCurrentPage(1)
      }

      if(offset >= PAGINAGTION_COUNT)
      {
        setOffset(offset - PAGINAGTION_COUNT)
      }
      else
      {
        setOffset(0)
      }
      getAuditLogs(query, order, offset, limit)
    }

  }

  const getAuditLogs = async (query: string, order: "ASC" | "DESC", offset: number, limit: number) =>
  {
    setOrder(order);
    setIsLoading(true);

    const auditApi = new AuditLogsApi(token);
    const results = await auditApi.get(query.toLowerCase(), order, offset, limit);

    setAuditRecords(results)
    setIsLoading(false)
  }

  return [ isLoading, query, pageBack, pageForward, currentPage, auditRecords, handleSearchWithDebounce, navHome, clearSearch ]

}