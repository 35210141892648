import React, { useState } from 'react'
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import { useNotifications } from './hooks/useNotifications';
import { MenuItem, Select } from '@material-ui/core';

interface SendTestMessageModalProps
{
  handleClose: () => void;
  open: boolean;
  token: string;
  householdId: string;
  clientNo: string;
}

const SendTestMessageModal = ({ open, handleClose, token, householdId, clientNo }: SendTestMessageModalProps) =>
{
  const [ msg, setMsg ] = useState("test trigger-pendant fall event")
  const [ sourceType, setSourceType ] = useState("")
  const [ severity, setSeverity ] = useState("ALERT")
  const [ sendTestNotification, isLoading, success, error ] = useNotifications(token, sourceType, clientNo);

  const handleSetType = (value: string) =>
  {
    if(value === 'pendantFall')
    {
      setMsg("test trigger pendant fall event from https://www.google.com/maps/?q=-31.9366992,115.8402178")
      setSourceType(value)
    }

    if(value === "pendant")
    {
      setMsg("test trigger pendant duress has been activated from https://www.google.com/maps/?q=-31.9366992,115.8402178")
      setSourceType(value)
    }

    if(value === "intelihubOffline")
    {
      setMsg("test trigger InteliHub is offline - has been offline for a certain amount of time")
      setSourceType(value)
    }
  }


  return(
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      style={styles.container}>
      <DialogTitle>Send Test Message</DialogTitle>
      <DialogContent>

        <Select
          variant="outlined" fullWidth onChange={(e: any) => setSeverity(e.target.value)} value={severity}>
          <MenuItem disabled  value="INFO">INFO</MenuItem>
          <MenuItem  disabled  value="NOTIFICATION">NOTIFICATION</MenuItem>
          <MenuItem value="ALERT">ALERT</MenuItem>
        </Select>

        <Select
          style={styles.select}
          variant="outlined" fullWidth onChange={(e: any) => handleSetType(e.target.value)} value={sourceType}>
          <MenuItem value="pendantFall">Pendant Fall Event</MenuItem>
          <MenuItem value="pendant">Pendant Duress</MenuItem>
          <MenuItem value="intelihubOffline">Intelihub Offline</MenuItem>
        </Select>


      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{(success || error)? "Close" : "Cancel"}</Button>
        <Button
          disabled={isLoading}
          onClick={() => sendTestNotification(householdId, "ALERT", msg)}>
          {isLoading ? "Sending..." : "Send Test"}
        </Button>
      </DialogActions>

      {error && <Alert style={styles.alert} variant="filled" severity="error">{error}</Alert>}
      {success && <Alert style={styles.alert}  variant="filled" severity="success">{success}</Alert>}

    </Dialog>
  )
}

const mapStateToProps = (state: any) =>
{
  return {
    token: state.user.token,
  }
}

export default connect(mapStateToProps)(SendTestMessageModal)


const styles = {
  container: {
    padding: 12,
  },
  alert: {
    margin: 12,
  },
  select: {
    marginTop: 10,
  },
}