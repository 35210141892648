
// ------------------------------------
// Constants
// ------------------------------------
export const TOGGLE_FEATURE = 'TOGGLE_FEATURE'

// ------------------------------------
// Actions
// ------------------------------------

export function toggleFeature(featureKey) {
  return {
    type: TOGGLE_FEATURE,
    featureKey: featureKey,
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  // newHouseholdList: false, (Left behind as an example for next time its needed)
}

export default function featurePreviewReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_FEATURE:
      return toggleFeatureReducerAction(state, action)
    default:
      return state
  }
}

function toggleFeatureReducerAction(state, action) {
  let newState = Object.assign({}, state)
  newState[action.featureKey] = !newState[action.featureKey]
  return newState
}
