import { useState } from 'react'

type UseValidation = [ (e: any) => void, string, string, (val: string) => void ]
export const useValidation = (): UseValidation =>
{
  const [ data, setData ] = useState("")
  const [ error, setError ] = useState("")

  const handleTextChange = (e: any) =>
  {
    if(e.target.value.toLowerCase().includes("common"))
    {
      setError("Common Area will be created automatically after the first zone is added")
    }
    else
    {
      setError("")
    }

    setData(e.target.value)
  }

  return [ handleTextChange, error, data, setData ]
}