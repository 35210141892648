export default theme => ({
  OverlayBackground: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1200,
  },
  Overlay: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    top: '10%',
    left: '15%',
    right: '15%',
    height: 'auto',
    maxHeight: '80%',
    // min-height: '80%',
    minWidth: '40rem',
    textAlign: 'center',
    backgroundColor: 'rgba(250, 250, 250, 1)',
    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.75)',
    zIndex: 1201,
  },
  OverlayHeader: {
    background: '#991b37',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '2rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    minHeight: '50px',
  },
  OverlayBody: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    minHeight: '200px',
    overflow: 'auto',
  },
  OverlaySidebar: {
    backgroundColor: 'rgba(250, 250, 250, 1)',
    height: '100%',
    flex: 1,
    maxWidth: '20%',
  },
  OverlayMain: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    flex: 3,
  },
  OverlaySectionAlign: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  OverlaySection: {
    flex: 9,
    padding: '1rem',
    height: '100%',
    overflow: 'auto',
  },
  OverlayFooter: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    fontSize: '1rem',
    padding: '1rem',
  },
  FillerHide: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    height: '100%',
    display: 'none',
  },
  AlignLeft: {
    textAlign: 'left',
  },
})
