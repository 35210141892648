import moment from 'moment'


export const convertTimeStringToUTC = (time: string): string => {
  // Convert the time string to UTC first
  if(time === "" || time === "Invalid date") return ""
  return moment.utc(moment(time, "HH:mm")).format("HH:mm")
};

export const UTCtoLocal = (time: string): string => {
  // Convert the UTC to local
  if(time === "" || time === "Invalid date") return ""
  return moment.utc(time, "HH:mm").local().format("HH:mm")
};

export const UTCtoLocalPretty = (time: string): string => {
  // Convert the UTC to local
  return moment.utc(time, "HH:mm").local().format("hh:mm A")
};

export const LocalToPretty = (time: string): string => {
  return moment(time, "HH:mm").format("hh:mm A")
};

export const UTCtimeStringToDate = (time: string): Date =>
{
  // Convert the UTC to local
  return moment.utc(time, "HH:mm").local().toDate()
}

export const timeStringToDate = (time: string): Date =>
{
  // Convert the UTC to local
  return moment(time, "HH:mm").local().toDate()
}