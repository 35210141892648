import React from 'react'
import { DataConsumer } from '../context/DataContext'

export const withDataSetter = (Component) => {
  return class HOC extends React.Component {
    render() {
      return (
        <DataConsumer>
          {
            ({ setData }) => (<Component {...this.props} setData={setData} />)
          }
        </DataConsumer>
      )
    }
  }
}

export const withDataGetter = (Component) => {
  return class HOC extends React.Component {
    render() {
      return (
        <DataConsumer>
          {
            ({ refs }) => (<Component {...this.props} data={refs} />)
          }
        </DataConsumer>
      )
    }
  }
}

export const withData = (Component) => withDataSetter(withDataGetter(Component))