import { connect } from 'react-redux'
import Component from '../components/MealView'

const mapDispatchToProps = (dispatch) => {
  return {}
}

const mapStateToProps = (state) => ({
  graphs: state.graphs.graphs.data || {},
  isFetching: state.graphs.graphs.isFetching,
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
