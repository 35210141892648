import { put, select, takeLatest } from "redux-saga/effects"
import { GatewayApi } from "../../../api/GatewayApi";
import { ZoneApi } from "../../../api/ZoneApi"
import { addRoomSuccess, addSensorToRoomSuccess, createZoneError, createZoneSuccess, getGatewayBegin, getGatewaySuccess, getZonesError, getZonesSuccess, removeRoomFromZoneSuccess, removeSensorFromRoomSuccess, removeZoneError, removeZoneSuccess, showSnack, updateHasZonesState, updateRoomSuccess, updateZoneBegin, updateZoneError, updateZoneSuccess } from "./actionCreators";
import { ZoneActions } from './actions';
import axios from 'axios'

export function* createZone(action: any): any {

  try {
    const state = yield select((state) => state)
    const zoneApi = new ZoneApi(state.user.token)
    const result = yield zoneApi.createZone(action.data.householdId, action.data.zone)
    yield put(createZoneSuccess(result))
    yield put(updateHasZonesState(state.households.activeHouseholdId, true))
  }
  catch (error)
  {
    if(axios.isAxiosError(error)) return yield put(createZoneError(error && error.response && error.response.data.error))
  }
}

export function* updateZone(action: any): any {

  try {
    const state = yield select((state) => state)
    const zoneApi = new ZoneApi(state.user.token)
    const result = yield zoneApi.updateZone(action.data.householdId, action.data.zone)
    yield put(updateZoneSuccess(result))
  }
  catch (error) {
    yield put(updateZoneError(error))
  }
}

export function* removeZone(action: any): any {

  try {
    const state = yield select((state) => state)
    const zoneApi = new ZoneApi(state.user.token)
    const result = yield zoneApi.removeZone(action.data.householdId, action.data.zoneId)
    yield put(removeZoneSuccess(result))
  }
  catch (error) {
    yield put(removeZoneError(error))
  }
}


export function* getZones(action: any): any {

  try {
    const state = yield select((state) => state)
    const zoneApi = new ZoneApi(state.user.token)
    const result = yield zoneApi.getZones(action.data.householdId)
    yield put(getZonesSuccess(result))
  }
  catch (error) {
    yield put(getZonesError(error))
  }
}

export function* addRoom(action: any): any
{
  try
  {
    yield put(showSnack("info", "Adding Room"))
    const state = yield select((state) => state)
    const zoneApi = new ZoneApi(state.user.token)
    const result = yield zoneApi.createRoom(action.data.householdId, action.data.room)
    yield put(addRoomSuccess(result))
    yield put(getGatewayBegin(state.householdsV2.activeGatewayId))
    yield put(showSnack("success", "Added new room to zone"))
  }
  catch(error)
  {
    if(axios.isAxiosError(error)) return yield put(showSnack("error", error && error.response && error.response.data.error))
    if(error instanceof Error) return yield put(showSnack("error", error.message))
    return yield put(showSnack("error", "Looks like there was an issue adding that room"))
  }
}

export function* removeRoom(action: any): any
{
  try
  {
    yield put(showSnack("info", "Removing room"))
    const state = yield select((state) => state)
    const zoneApi = new ZoneApi(state.user.token)
    const result = yield zoneApi.removeRoom(action.data.householdId, action.data.roomid)
    yield put(removeRoomFromZoneSuccess(result.roomid))
    yield put(showSnack("success", "Removed room from zone"))

  }
  catch(error)
  {
    if(error instanceof Error) return yield put(showSnack("error", error.message))
    return yield put(showSnack("error", "Looks like there was an issue removing that room"))
  }
}

export function* updateRoom(action: any): any
{
  try
  {
    yield put(showSnack("info", "Updating Room"))
    const state = yield select((state) => state)
    const zoneApi = new ZoneApi(state.user.token)
    const result = yield zoneApi.updateRoomName(action.data.householdId, action.data.room)
    yield put(updateRoomSuccess(result))
    yield put(showSnack("success", "Updated room"))

  }
  catch(error)
  {
    if(error instanceof Error) return yield put(showSnack("error", error.message))
    return yield put(showSnack("error", "Looks like there was an issue updating that room"))
  }

}

export function* addSensorToRoom(action: any): any
{
  try
  {
    yield put(showSnack("info", "Adding sensor to room"))
    const state = yield select((state) => state)
    const zoneApi = new ZoneApi(state.user.token)
    const response = yield zoneApi.addSensorToRoom(action.data.householdId, action.data.nodeid, action.data.roomid)
    yield put(addSensorToRoomSuccess(response.sensors, response.roomid ))
    yield put(showSnack("success", "Successfully added sensor to room"))
  }
  catch(error)
  {
    if(error instanceof Error) return yield put(showSnack("error", error.message))
    return yield put(showSnack("error", "Looks like there was an issue adding the sensor to that room"))
  }
}

export function* removeSensorFromRoom(action: any): any
{
  try
  {
    yield put(showSnack("info", "Removing sensor from room"))
    const state = yield select((state) => state)
    const zoneApi = new ZoneApi(state.user.token)
    const response = yield zoneApi.removeSensorFromRoom(action.data.householdId, action.data.nodeid)
    yield put(removeSensorFromRoomSuccess(response.nodeid))
    yield put(showSnack("success", "Successfully removed sensor from room"))

  }
  catch(error)
  {
    if(error instanceof Error) return yield put(showSnack("error", error.message))
    return yield put(showSnack("error", "Looks like there was an issue updating that room"))
  }
}

export function* getGateway(action: any): any
{
  try
  {
    const state = yield select((state) => state)
    const gwApi = new GatewayApi(state.user.token)
    const response = yield gwApi.getGateway(action.data.gatewayId)
    yield put(getGatewaySuccess(response))

  }
  catch(error)
  {
    yield put(showSnack("error", "Error getting gateway"))
  }
}




export function* zoneSagas()
{
  yield takeLatest(ZoneActions.CREATE_ZONE_BEGIN, createZone)
  yield takeLatest(ZoneActions.GET_ZONES_BEGIN, getZones)
  yield takeLatest(ZoneActions.UPDATE_ZONE_BEGIN, updateZone)
  yield takeLatest(ZoneActions.REMOVE_ZONE_BEGIN, removeZone)
  yield takeLatest(ZoneActions.ADD_ROOM_TO_ZONE, addRoom)
  yield takeLatest(ZoneActions.REMOVE_ROOM_FROM_ZONE_BEGIN, removeRoom)
  yield takeLatest(ZoneActions.UPDATE_ROOM_NAME, updateRoom)
  yield takeLatest(ZoneActions.ADD_SENSOR_TO_ROOM, addSensorToRoom)
  yield takeLatest(ZoneActions.REMOVE_SENSOR_FROM_ROOM, removeSensorFromRoom)
  yield takeLatest(ZoneActions.GET_GATEWAY_BEGIN, getGateway)
}