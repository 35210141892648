export default (theme) => ({
  extraGutter: {
    marginBottom: '1rem',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  margin: {
    margin: theme.spacing(),
  },
  // sensorCard: {
  //   marginTop: '0.5em',
  // },
  sensorCardContent: {
    padding: '0.5rem',
    paddingTop: 0,
    paddingBottom: 0,
    '&:last-child': {
      padding: '0.5rem',
      paddingTop: 0,
      paddingBottom: '0.5rem',
    },
  },
  sensorCard: {
    paddingTop: 0,
  },
  ToolTip: {
    width: '310px',
  },
  LoadingData: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  NoData: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'center',
    // alignItems: 'center',
  },
  NoDataIcon: {
    marginRight: '12px',
  },
  StatusBadge: {
    fontSize: '0.8em',
    fontWeight: 'bold',
    paddingLeft: 5,
    paddingRight: 5,
    marginRight: 3,
    marginLeft: 3,
    display: 'inline-block',
    borderRadius: 3,
    color: 'white',
  },
  TileContainer: {
    lineHeight: '1em',
    textAlign: 'center',
    paddingTop: '1em',
    paddingBottom: '1em',
    verticalAlign: 'top',
  },
  LabelContainer: {
    marginBottom: 0,
    height: '100%',
  },
  SensorInfo: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center',
    marginBottom: 15,
  },
  SensorInfoHeader: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'center',
    // alignItems: 'center',
    marginRight: '15px',
  },
  SensorInfoBody: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center',
  },
})
