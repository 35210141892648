import _ from 'lodash'
import { createSelector } from 'reselect'
import {
  augmentMessage,
} from '../../shared/selectors/helper'

import {
  getActiveHouseholdId,
} from '../householdsV2/householdsSelector'

const getNotifications = (state) => state.notifications.notifications
const getHouseholds = (state) => state.householdsV2.households

export const areAlertsMinimised = (state) => state.notifications.minimisedAlerts
export const isFetchingNotifications = (state) => state.notifications.isFetching
export const isSendingMessage = (state) => state.notifications.messageSending
export const isSendingError = (state) => state.notifications.sendError !== undefined
export const notificationResolutionSending = (state) => state.notifications.notificationResolving

export const getAllNotifications = createSelector(
  [getNotifications, getHouseholds],
  (notifications, households) => {
    var result = _(notifications)
      .map((n) => {
        var household = households.find((h) => h.householdId === n.householdId)
        return augmentMessage(n, household)
      })
      .sort((a, b) => b.timestamp - a.timestamp)
      .value()
    return result
  }
)

const KEY_MAP = {
  ALERT: 'Alerts',
  NOTIFICATION: 'Notifications',
  INFO: 'Information',
}

export const getNotificationsMap = createSelector(
  [getAllNotifications],
  (notifications) => {
    let notificationMap = notifications.filter((n) => KEY_MAP[n.type])
    notificationMap = _.groupBy(notificationMap, 'type')
    const a = _(notificationMap).map((val, key) => {
      let data = val.map((i) => {
        return {
          title: i.name,
          description: i.text,
          time: i.friendlyTime,
          status: i.status,
          type: i.type,
          id: i.id,
          householdId: i.householdId,
        }
      })
      data.slice(0, 10)
      return {
        key: KEY_MAP[key],
        data: data,
      }
    }).sortBy('key').value()
    return a
  }
)

export const getNotificationsMapForHousehold = createSelector(
  [getAllNotifications, getActiveHouseholdId],
  (notifications, activeHouseholdId) => {
    let notificationMap = notifications.filter((n) =>
      n.householdId === activeHouseholdId &&
      KEY_MAP[n.type])
    notificationMap = _.groupBy(notificationMap, 'type')
    const a = _(notificationMap).map((val, key) => {
      let data = val.map((i) => {
        return {
          title: i.name,
          description: i.text,
          time: i.friendlyTime,
          status: i.status,
          type: i.type,
          id: i.id,
          householdId: i.householdId,
        }
      })
      data.slice(0, 10)
      return {
        key: KEY_MAP[key],
        data: data,
      }
    }).sortBy('key').value()
    return a
  }
)

export const getActiveAlerts = createSelector([getAllNotifications], (notifications) => {
  var result = notifications.filter((n) => n.type === 'ALERT' && n.status === 'ACTIVE')
  return result
})

export const getActiveAlertsForHousehold = createSelector(
  [getAllNotifications, getActiveHouseholdId],
  (notifications, activeHouseholdId) => {
    var result = notifications.filter((n) => n.type === 'ALERT' && n.status === 'ACTIVE' && n.householdId === activeHouseholdId)
    return result
  })

export const inAlertMode = createSelector([getActiveAlerts], (alerts) => {
  const result = alerts && alerts.length > 0
  return result
})
