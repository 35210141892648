
import { withStyles } from '@material-ui/core/styles'
import * as React from 'react' // tslint:disable-line
import { Component } from 'react' // tslint:disable-line
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'
import { Scatter, ScatterChart, XAxis, YAxis, ZAxis, ResponsiveContainer } from 'recharts';
import { Typography } from '@material-ui/core'
import moment from 'moment'
import _ from 'lodash'

class MealTimeGraphView extends Component {

  constructor(props) {
    super(props)
    this.state = {
      width: window.innerWidth - 450,
      height: 300,
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this._resizeDebounce = _.debounce(this.updateWindowDimensions, 150)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this._resizeDebounce)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resizeDebounce)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth - 450 })
  }

  render() {
    return <div><Typography>{this.getScatterChart()}</Typography></div>;
  }

  renderTooltip(props, a) {
    const { active, payload } = props
    if (active && payload && payload.length) {
      const { dataKey } = payload[2]
      const count = payload[2].payload[dataKey]
      const name = dataKey[0].toUpperCase() + dataKey.slice(1)
      return (
        <div style={{ backgroundColor: 'white', border: '1px solid #D3D3D3', margin: 0, padding: 15 }}>
          <Typography variant='subtitle1'>{name}</Typography>
          <Typography variant='body1' style={{ color: this.props.theme.graphColors.setB[0] }}>{`${count} Meal Event${count === 1 ? '' : 's'}`}</Typography>
        </div>
      )
    }

    return null
  }

  getScatterChart() {
    const { theme } = this.props
    const { keys, values, ticks, start, end, tickFormat } = this.props.data
    const range = [0, 1200]
    const domain = [0, 8]
    if (!keys) return

    return (
      keys.map((key, index) => (
        <ResponsiveContainer
          key={index}
          height={this.state.height / 4}
          width="100%">
          <ScatterChart
            width='100%'
            height={this.state.height / 4}
            margin={{ top: 16, right: 0, bottom: 0, left: 0 }}
          >
            <XAxis
              type="category"
              // type="number"
              domain={[start.unix(), end.unix()]}
              dataKey="unixTs"
              // interval={0}
              tick={key.id === 'night' ? undefined : { fontSize: 0 }}
              ticks={ticks}
              tickLine={{ transform: 'translate(0, -6)' }}
              tickFormatter={tick => moment(tick * 1000).format(tickFormat)}
            />
            <YAxis
              type="number"
              dataKey="index"
              height={10}
              width={80}
              tick={false}
              tickLine={false}
              axisLine={false}
              label={{ value: key.label, position: 'insideRight' }}
            />
            {/* <Tooltip cursor={{ strokeDasharray: '3 3' }} wrapperStyle={{ zIndex: 100 }} content={(o) => this.renderTooltip(o)} /> */}
            <ZAxis type="number" domain={domain} dataKey={key.id} range={range} />
            <Scatter data={values} fill={theme.palette.info.main} shape="circle" />
          </ScatterChart>
        </ResponsiveContainer>
      ))

    )
  }
}

MealTimeGraphView.propTypes = {
  data: PropTypes.object,
  isFetching: PropTypes.bool,
  theme: PropTypes.object,
}

const styles = {}

export default withTheme(withStyles(styles)(MealTimeGraphView))
