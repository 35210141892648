import React from 'react'
import PresenceView from '../cards/PresenceView'
import Grid from '@material-ui/core/Grid'

import { OutingsView } from '../cards/Presence/OutingsView'
import { WeekTrends } from '../cards/Presence/WeekTrends'
import { SocialIsolation } from '../cards/Presence/SocialIsolation'
import { HomeStatus } from '../cards/Presence/HomeStatus'
import { getPresenceBehaviour } from '../cards/Presence/util/getPresenceBehaviour'
import { getSocialIsolationBehaviour } from '../cards/Presence/util/getSocialIsolationStatus'

interface PresenceTabProps {
  useNewLook: boolean;
  activeV2Household: any;
}

export const PresenceTab = ({ activeV2Household, useNewLook }: PresenceTabProps) => {

  const presence = getPresenceBehaviour(activeV2Household)
  const socialIsolation = getSocialIsolationBehaviour(activeV2Household)

  return (
    <Grid container direction="column">

      <Grid spacing={3} item container direction="row" justify="space-between">

        <Grid item xs={12} md={12} lg={4} xl={4}>
          <WeekTrends presence={presence} />
        </Grid>

        <Grid item xs={12} md={12} lg={4} xl={4}>
          <SocialIsolation socialIsolation={socialIsolation} />
        </Grid>

        <Grid item xs={12} md={12} lg={4} xl={4}>
          <HomeStatus presence={presence} />
        </Grid>

      </Grid>


      <Grid item>
        <PresenceView
          useNewLook={useNewLook}
          id="presence"
          aria-label="presence"
          householdTz={activeV2Household.timezone}
          householdV2={activeV2Household}
        />
      </Grid>

      <Grid item>
        <OutingsView
          presence={presence}
          householdTz={activeV2Household.timezone}
        />
      </Grid>

    </Grid>

  )
}