import _ from 'lodash'
import access from 'safe-access'
import moment from 'moment-timezone'
import { enums } from '@intelicare/shared'

import { colorForStatus } from '../util/colors'

const { SENSOR_STATUS } = enums

export const friendlyMessageTime = (unixTimestamp, timezone = null) => {
  if (!unixTimestamp) {
    return ''
  }

  const today = moment()
  let momentTime
  if (!timezone) {
    momentTime = moment(unixTimestamp)
  } else {
    momentTime = moment(unixTimestamp).tz(timezone)
  }

  const days = momentTime.diff(today, 'days')
  switch (days) {
    case 0:
      return momentTime.format('HH:mm')
    default:
      return momentTime.format('DD/MM')
  }
}

export const friendlyDateTime = (unixTimestamp, timezone = null) => {
  if (!unixTimestamp) {
    return '-'
  }
  if (!timezone) {
    const momentTime = moment(unixTimestamp)
    return `${dayStr(momentTime)} @ ${momentTime.format('h:mm a')}`
  } else {
    const timezoneAbbr = moment.tz(timezone).zoneAbbr()
    const momentTime = moment(unixTimestamp).tz(timezone)
    return `${dayStr(momentTime, timezone)} @ ${momentTime.format('h:mm a')} ${timezoneAbbr}`
  }
}

export const dayStr = (dayMoment, timezone = null) => {
  var now = moment().startOf('day')
  let days
  if (!timezone) {
    days = now.diff(moment(dayMoment).startOf('day'), 'days')
  } else {
    days = now.diff(moment(dayMoment).tz(timezone).startOf('day'), 'days')
  }
  switch (days) {
    case 0:
      return 'Today'
    case 1:
      return 'Yesterday'
    default:
      return days < 6 ? dayMoment.format('dddd') : dayMoment.format('Do MMM')
  }
}

export const augmentMessage = (message, household = {}) => {
  let text = ''
  if (message.recipients) {
    var carerRecipient = message.recipients.find(recipient => recipient.type === 'CARERS' || recipient.type === 'CARERS_QUIET')
    if (carerRecipient !== undefined)
      text = carerRecipient.message
    else {
      var householdRecipient = message.recipients.find(r => r.type === 'HOUSEHOLD')
      if (householdRecipient)
        text = householdRecipient.message
    }
  }
  else if (message.carer)
    text = message.carer.message
  else if (message.resident)
    text = message.resident.message
  else
    text = message.message
  return Object.assign({}, message, {
    name: household.friendlyName || 'N/A',
    avatar: household.avatar,
    type: message.type || message.notificationType,
    text: text,
    lastActivity: household.lastActivity,
    friendlyTime: friendlyDateTime(message.timestamp, household.timezone),
    contactNumber: household.contactNumber || '1234567893',
  })
}

export const iconForSensorType = (type) => {
  switch (type) {
    case 'current':
      return { name: 'settings_power', family: 'material' }
    case 'smoke':
      return { name: 'whatshot', family: 'material' }
    case 'door':
      return { name: 'exit_to_app', family: 'material' }
    case 'humidity':
      return { name: 'invert_colors', family: 'material' }
    case 'light':
      return { name: 'lightbulb_outline', family: 'material' }
    case 'motion':
      return { name: 'directions_walk', family: 'material' }
    case 'pendant':
      return { name: 'watch', family: 'material' }
    case 'power':
      return { name: 'power', family: 'material' }
    case 'shower':
      return { name: 'bath', family: 'awesome' }
    case 'temperature':
      return { name: 'thermometer-half', family: 'awesome' }
    case 'luminance':
      return { name: 'lightbulb_outline', family: 'material' }
    case 'voltage':
      return { name: 'power_settings_new', family: 'material' }
    case 'tracker':
      return { name: 'home', family: 'material' }
    case 'weight':
      return { name: 'scale-bathroom', family: 'community' }
    case 'pressure':
      return { name: 'event_seat', family: 'material' }
    case 'zwavedevice':
      return { name: 'wifi', family: 'material' }
    case 'vibration':
      return { name: 'vibration', family: 'material' }
    case 'gatewaysensor':
      return { name: 'tablet', family: 'material' }
    case 'heartrate':
      return { name: 'heartbeat', family: 'awesome' }
    case 'respiration':
      return { name: 'lungs', family: 'custom' }
    case 'occupancy':
      return { name: 'bed', family: 'awesome' }
    case 'distance':
      return { name: 'ruler', family: 'awesome' }
    case 'activitylevel':
      return { name: 'running', family: 'awesome' }
    default:
      return { name: 'report_problem', family: 'material' }
  }
}

export const textForSensorStatus = (status) => {
  switch (status) {
    case SENSOR_STATUS.ALERT:
      return 'Offline'
    case SENSOR_STATUS.WARNING:
      return 'Watch'
    default:
      return 'Okay'
  }
}

const valueForLastState = (sensor) => {
  if (!sensor.last_state) return ''

  switch (sensor.type) {
    case 'tracker':
      return sensor.last_state === 'home' ? 'Home' : 'Not Home'
    case 'temperature':
      return sensor.last_state + '°C'
    case 'door':
      return (sensor.last_state === "23" || sensor.last_state === 'closed' || sensor.last_state === 'off') ? "Closed" :
        (sensor.last_state === "22" || sensor.last_state === 'open' || sensor.last_state === 'on') ? "Open" : "Unknown"
    case 'power':
      return sensor.last_state === "0" ? "off" : "on"
    case 'vibration':
      if (sensor.last_state === '255') return 'on'
      else if (sensor.last_state === '0') return 'off'
      else return sensor.last_state
    case 'motion':
      return (sensor.last_state === "8" || sensor.last_state === "on") ? 'Active' : 'Inactive'
    case 'cpu':
      return sensor.last_state + '%'
    case 'memory':
      return sensor.last_state + 'Mb'
    case 'weight':
      return sensor.last_state + 'kg'
    case 'pressure':
      return (sensor.last_state === "on" ? "Unoccupied" : "Occupied")
    default:
      return sensor.last_state
  }
}
/** Show GUI enabled sensors */
export const deriveSensorDataForGateway = (gateway, statusCalculator, household = {}) => {

  let result =
    _(access(gateway, 'rooms') || [])
      .map((room) => {

        return _(room.sensors)
          .filter({ guivisible: true })
          .map(sensor => _transformSensor({ sensor, statusCalculator, room, household }))
          .value()
      })
      .flatten()
      .sortBy(['lastSeen', 'room'])
      .reverse()
      .value()

  return result
}

/** Enrich sensors in household settings with icons */
const addIconsToSensors = (sensors) => (sensors || []).map(sensor => {
  const icon = iconForSensorType(access(sensor, 'type'))
  sensor.icon = {
    name: icon.name,
    family: icon.family,
    color: icon.color,
  }
  return sensor
})

const setValueForSensors = (sensors) => (sensors || []).map(sensor => {
  sensor.last_state = sensor.value
  sensor.value = valueForLastState(sensor)
  return sensor
})

export const deriveSensorDataForHousehold = (sensors) => {
  addIconsToSensors(sensors)
  setValueForSensors(sensors)
}
/** Show parent sensors */
// featureToggle 'enableRootNodeSystemHealth' is true
export const deriveRootSensorDataForGateway = (gateway, statusCalculator) => {
  // Add child sensors
  const rootToChildSensorMap = {}

  _(access(gateway, 'rooms') || []).forEach(room => _(
    (access(room, 'sensors') || []).filter(sensor => sensor.parentid !== undefined).forEach(
      sensor => {
        if (rootToChildSensorMap[sensor.parentid] === undefined) {
          rootToChildSensorMap[sensor.parentid] = []
        }
        rootToChildSensorMap[sensor.parentid].push(sensor)
      }
    )
  ))

  let result =
    _(access(gateway, 'rooms') || [])
      .map((room) => {
        return _((access(room, 'sensors') || []))
          .filter(sensor => {
            return sensor.parentid === undefined && sensor.type !== "Room" && sensor.type !== "gui"
          })
          .map(sensor => _transformSensor({ sensor, statusCalculator, room }))
          .map(sensor => _addChildSensors({ sensor, rootToChildSensorMap, room }))
          .value()
      })
      .flatten()
      .sortBy([{ 'name': 'asc' }, { 'room': 'asc' }])
      .reverse()
      .value()

  return result
}

const _addChildSensors = (object) => {
  const { sensor, rootToChildSensorMap, room } = object
  if (rootToChildSensorMap[sensor.id] !== undefined) {
    sensor.childSensors = rootToChildSensorMap[sensor.id].map(sensor => {
      const icon = iconForSensorType(sensor.type)
      return {
        id: sensor.id,
        key: sensor.id,
        deviceId: sensor.deviceId,
        room: room.roomname,
        enabled: sensor.enabled,
        enabledString: sensor.enabled === true ? "Enabled" : "Disabled",
        name: sensor.friendlyname ? sensor.friendlyname.replace(/\s[sS]ensor/, '') || '' : '',
        icon: {
          name: icon.name,
          family: icon.family,
        },
      }
    })
  }
  return sensor
}

export const _transformSensor = (object) => {
  const { sensor, statusCalculator, room, household } = object
  let lastSeenMoment
  if (access(household, 'timezone')) {
    lastSeenMoment = sensor.lastseen ? moment(sensor.lastseen).tz(household.timezone) : undefined
  } else {
    lastSeenMoment = sensor.lastseen ? moment(sensor.lastseen).tz(moment.tz.guess()) : undefined
  }

  const icon = iconForSensorType(sensor.type)

  const status = statusCalculator(sensor)
  return {
    manufacturer: sensor.manufacturer,
    product: sensor.product,
    id: sensor.id,
    key: sensor.id,
    deviceId: sensor.deviceid,
    nodeId: sensor.nodeid,
    name: sensor.friendlyname ? sensor.friendlyname.replace(/\s[sS]ensor/, '') || '' : '',
    room: room.roomname,
    statusText: textForSensorStatus(status),
    icon: {
      name: icon.name,
      family: icon.family,
      color: colorForStatus(status),
    },
    timestamp: sensor.lastseen || '',
    lastSeenString: lastSeenMoment ? `${dayStr(lastSeenMoment)} @ ${lastSeenMoment.format('h:mm a')}${(!household ? ' ' + lastSeenMoment.format('z') : '')}` : '-',
    friendlyTime: lastSeenMoment ? lastSeenMoment.format('h:mm a') : '---',
    lastSeen: lastSeenMoment,
    batteryLevelString: (sensor.batterylevel !== undefined ? sensor.batterylevel + "%" : "-"),
    value: valueForLastState(sensor),
  }
}
