import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";

import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField  from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FlatIcon from "../../../FlatIcon";
import NotificationIcon from '@mui/icons-material/NotificationImportant'
import SendTestMessageModal from "./SendTestMessageModal";
import { ConfirmEnableDialog } from "./ConfirmEnableModal";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../interfaces/reduxState";
import { updateBehaviourSetting } from "../../../../redux/domains/householdsV2/householdsStore";
import { callAPI } from "../../../../redux/domains/user/userStore";
import { apiEndpoints } from '../../../../redux';
import { SnackAlert } from "../../../../routes/Organisations/component/Shared/SnackAlert";
const { HOUSEHOLD_SETTING_BULK } = apiEndpoints;
export const MonitoringService = () => {

  const [ showDialog, setShowDialog ] = useState(false);
  const [ showTestMessageModal, setShowTestMessageModal ] = useState(false)
  const serviceAgent = [ 'BLUEFORCE', 'TUNSTALL' ]
  const householdId = useSelector(({ householdsV2 }: ApplicationState) => householdsV2.activeHouseholdId)
  const isSaving = useSelector(({ households }: ApplicationState) => households.settings.isSaving)
  const household = useSelector(({ householdsV2 }: ApplicationState)  => householdsV2.households.find(household => household.householdId === householdId))
  const monitorService = household?.data?.monitorService;
  const dispatch = useDispatch();

  const handleToggleNotifications = (e: any) => {
    const { name, checked } = e.target;

    let updatedNotifications;
    if (checked)
    {
      if(monitorService && monitorService.notifications)
      {
        updatedNotifications = monitorService.notifications.concat(name)
      }
      else if(monitorService && !monitorService.notifications)
      {
        monitorService.notifications = []
        updatedNotifications = monitorService.notifications.concat(name)
      }
      dispatch(updateBehaviourSetting(householdId, "monitorService", { ...monitorService, notifications: updatedNotifications }))
    }
    else
    {
      const updatedNotifications = monitorService && monitorService.notifications.filter(n => n !== name);
      dispatch(updateBehaviourSetting(householdId, "monitorService", { ...monitorService, notifications: updatedNotifications }))
    }
  }

  const handleEnableMonitoring = () =>
  {
    dispatch(updateBehaviourSetting(householdId, "monitorService", { ...monitorService, enabled: !monitorService?.enabled }))
  }

  const handleSelectType = (e: any) =>
  {
    dispatch(updateBehaviourSetting(householdId, "monitorService", { ...monitorService, type: e.target.value }))
  }
  const handleSetServiceId = (e: any) =>
  {
    dispatch(updateBehaviourSetting(householdId, "monitorService", { ...monitorService, serviceProviderUserId: e.target.value }))
  }

  // Handle agreement confirmation
  const handleConfirmed = () => {
    setShowDialog(false)
    dispatch(updateBehaviourSetting(householdId, "monitorService", { ...monitorService, enabled: true }))
  }

  const handleSave = () =>
  {
    dispatch(callAPI(HOUSEHOLD_SETTING_BULK({ householdId: householdId, settings: { monitorService } })))
  }

  return (
    <div style={styles.container}>
      <div>
        <ConfirmEnableDialog
          open={showDialog}
          handleClose={() => setShowDialog(false)}
          handleConfirmed={handleConfirmed} />

        <Typography variant="h5" style={{ textAlign: "left", paddingLeft: 20, paddingBottom: 10 }}>Instructions</Typography>
        <Typography style={{ textAlign: "justify", paddingLeft: 20, paddingBottom: 10 }}
          variant="body1" color="textSecondary">
            InteliLiving has the ability to link to a monitoring service where Alerts can be investigated and responded to. This option provides the ability to select the monitoring service provider and enable / disable the alerts will be sent to the service. This option is only available to InteliCare support staff.
        </Typography>
        <Card style={styles.cardContainer}>
          <Typography variant="h5" style={{ textAlign: "left", paddingLeft: 20, paddingBottom: 10 }} >Monitoring Service</Typography>
          {monitorService && monitorService.enabled ? <Grid container justifyContent="center" direction="column" spacing={6}>
            <Grid container direction='row' item spacing={3}>
              <Grid item sm={4} direction="row">
                <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-evenly' }}>
                  <span style={{ marginTop: 7 }}><FlatIcon name="monitor" /></span>
                  <FormControlLabel
                    control={<Switch
                      checked={monitorService && monitorService.enabled}
                      value={monitorService && monitorService.enabled}
                      onChange={handleEnableMonitoring}
                    />}
                    label="Enabled"
                  />
                </div>
              </Grid>
              {(monitorService && monitorService.enabled) &&
                <Grid item sm={4} >
                  <Grid item container direction="column" spacing={1}>
                    <Grid item>
                      <InputLabel id="monitor-agent-name-label" style={styles.suppliersInput}>Suppliers</InputLabel>
                      <Select
                        variant="outlined"
                        fullWidth
                        id='type'
                        labelId="monitor-agent-name-label"
                        name='monitorService.type'
                        value={monitorService && monitorService.type}
                        onChange={handleSelectType}
                      >
                        {serviceAgent &&
                                serviceAgent.map(item => (
                                  <MenuItem key={item} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                      </Select>
                    </Grid>
                    <Grid item>
                      {monitorService && monitorService.type && monitorService.enabled &&

                      <TextField
                        id="monitoring-service-provisioning"
                        fullWidth
                        style={styles.suppliersInput}
                        value={monitorService && monitorService.serviceProviderUserId}
                        name={"monitorService.serviceProviderUserId"}
                        onChange={handleSetServiceId}
                        variant="outlined"
                        label="Monitoring System User Id"
                        helperText="This is the monitoring service provider user id"
                      />
                      }
                    </Grid>
                    <Grid item>
                      {monitorService && monitorService.type && monitorService.enabled && monitorService.serviceProviderUserId &&
                      <Button
                        onClick={() => setShowTestMessageModal(true)}
                        fullWidth
                        startIcon={<NotificationIcon />}
                        variant="contained" color="secondary">Send Test Message</Button>}
                    </Grid>

                    { showTestMessageModal &&
                    <SendTestMessageModal
                      clientNo={monitorService.serviceProviderUserId}
                      householdId={householdId}
                      open={showTestMessageModal}
                      handleClose={() => setShowTestMessageModal(false)}/> }

                  </Grid>

                </Grid>
              }
              { monitorService && monitorService.type && monitorService.enabled && (
                <Grid item sm={4} >
                  <FormControl component="fieldset" >
                    <FormLabel component="legend">Notifications Type</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox
                          checked={monitorService && monitorService.notifications && !!monitorService.notifications.find(i=> i == 'AriseEscalating')}
                          value={monitorService && monitorService.notifications && !!monitorService.notifications.find(i=> i == 'AriseEscalating')}
                          name="AriseEscalating"
                          onChange={handleToggleNotifications}
                        />}
                        label="Arise Escalating"
                      />
                      <FormControlLabel
                        control={<Checkbox
                          checked={monitorService && monitorService.notifications && !!monitorService.notifications.find(i=> i== 'PendantFall')}
                          value={monitorService && monitorService.notifications && !!monitorService.notifications.find(i=> i== 'PendantFall')}
                          onChange={handleToggleNotifications}
                          name="PendantFall"
                        />}
                        label="Pendant Fall"
                      />
                      <FormControlLabel
                        control={<Checkbox
                          checked={monitorService && monitorService.notifications && !!monitorService.notifications.find(i=> i== 'PendantDuress')}
                          value={monitorService && monitorService.notifications && !!monitorService.notifications.find(i=> i== 'PendantDuress')}
                          onChange={handleToggleNotifications} name="PendantDuress" />}
                        label="Pendant Duress"
                      />
                      <FormControlLabel
                        control={<Checkbox
                          checked={monitorService && monitorService.notifications && !!monitorService.notifications.find(i=> i== 'MealPrep')}
                          value={monitorService && monitorService.notifications && !!monitorService.notifications.find(i=> i== 'MealPrep')}
                          onChange={handleToggleNotifications} name="MealPrep" />}
                        label="Meal Prep"
                      />
                      <FormControlLabel
                        control={<Checkbox
                          checked={monitorService && monitorService.notifications && !!monitorService.notifications.find(i=> i== 'DoorAlert')}
                          value={monitorService && monitorService.notifications && !!monitorService.notifications.find(i=> i== 'DoorAlert')}
                          onChange={handleToggleNotifications} name="DoorAlert" />}
                        label="Door Alert"
                      />
                      <FormControlLabel
                        control={<Checkbox
                          checked={monitorService && monitorService.notifications && !!monitorService.notifications.find(i=> i== 'RedButton')}
                          value={monitorService && monitorService.notifications && !!monitorService.notifications.find(i=> i== 'RedButton')}
                          onChange={handleToggleNotifications} name="RedButton" />}
                        label="Red Button"
                      />
                      <FormControlLabel
                        control={<Checkbox
                          checked={monitorService && monitorService.notifications && !!monitorService.notifications.find(i=> i== 'IntelihubOffline')}
                          value={monitorService && monitorService.notifications && !!monitorService.notifications.find(i=> i== 'IntelihubOffline')}
                          onChange={handleToggleNotifications} name="IntelihubOffline" />}
                        label="Intelihub Offline"
                      />
                      <FormControlLabel
                        control={<Checkbox
                          checked={monitorService && monitorService.notifications && !!monitorService.notifications.find(i=> i== 'IntelihubOnBattery')}
                          value={monitorService && monitorService.notifications && !!monitorService.notifications.find(i=> i== 'IntelihubOnBattery')}
                          onChange={handleToggleNotifications} name="IntelihubOnBattery" />}
                        label="Intelihub On Battery"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid> :
          // @ts-ignore
            <Grid container justifyContent="center" align="center" direction="column" spacing={24}>
              <Grid container direction='row' item>
                <Grid item sm={4} direction="row">
                  <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <span style={{ marginTop: 7 }}><FlatIcon name="monitor" /></span>
                    <FormControlLabel
                      control={<Switch
                        checked={false}
                        onClick={() => setShowDialog(true)}
                      />}
                      label="Enabled"
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          }

        </Card>

      </div>
      <div style={styles.buttonRow}>
        <Button onClick={handleSave}>{isSaving ? "Saving... " : "Save"}</Button>
      </div>

      <SnackAlert isLoading={isSaving} alertMsg="Saving settings..." open={isSaving} color="info" onClose={() => {}}/>
    </div>
  );
}



const styles = {
  suppliersInput: {
    paddingBottom: 3,
  },
  buttonRow: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    fontSize: '1rem',
  },
  cardContainer: {
    flex: 4,
    padding: '1rem',
    height: '75%',
    margin: 16,
  },
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    margin: 24,
  },
}