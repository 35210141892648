import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { OrganisationRow } from './OrganisationRow';
import { Organisation } from '../../../../api/OrganisationApi';
import organisationListFilter from './hooks/organisationListFilter';

interface OrganisationListProps
{
  organisations: Organisation[];
}

export const OrganisationList = ({ organisations }: OrganisationListProps) =>
  <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Organisation</TableCell>
          <TableCell align="right">Users</TableCell>
          <TableCell align="right">Children</TableCell>
          <TableCell align="right">Last updated</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{organisationListFilter(organisations).map((organisation, key) =>
        <OrganisationRow key={key} organisation={organisation} />
      )}</TableBody>
    </Table>
  </TableContainer>