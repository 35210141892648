import React, { Component } from "react";
import PropTypes from "prop-types";
// Material UI Components
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

import { CircularProgress, Tooltip } from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SyncIcon from "@mui/icons-material/Sync";
import access from "safe-access";
import moment from "moment-timezone";
import { theme } from '../../../../../../themes/proTheme';

// Custom components
import MetricItem from "./MetricItem";
import HealthGraph from "../HealthGraph";
import { AllMetrics } from '../AllMetrics/AllMetrics'
// Placeholders when we have no data
import { GettingHealthMetrics } from "./GettingHealthMetrics";
import { NoHealthMetrics } from "./NoHealthMetrics";

// Modals
import AddMetricModal from "../AddMetricModal";
import { HealthMetricsEnabledModal } from "./HealthMetricsEnabledModal";

// Custom icon component
import InteliIcon from "../../../../../../components/InteliIcon";

import { HEALTH_METRIC_TYPES } from "../../enums";
import { FAHRENHEIT } from "../../../../../../helpers/healthMetrics";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ChevronLeft from "@mui/icons-material/ChevronLeft";

class HealthMetrics extends Component {
  state = {
    expanded: false,
    showAddMetricModal: false,
    showHealthMetricsEnabledModal: false,
    currentMetric: "",
    currentMetricSummary: [],
    openSettings: false,
    seeMoreMetrics: false,
  };

  handleShowAddMetricModal = (show, metricData) => {
    this.setState({ currentMetric: metricData });
    this.setState({ showAddMetricModal: show });
  };

  handleShowEnabledModal = () => {
    this.setState({ showHealthMetricsEnabledModal: true });
    this.props.enableHealthMetrics(this.props.activeHouseholdId);
  };

  componentDidMount() {
    const { activeHouseholdId, householdV2, getSummary } = this.props;
    getSummary({ householdId: activeHouseholdId });
  }

  componentWillUnmount() {
    this.props.clearSyncData();
  }

  componentDidUpdate(lastProps) {
    if (
      (lastProps.isGettingSummary === true || lastProps.isFetching === true) &&
      this.props.isGettingSummary === false &&
      this.props.isFetching === false
    ) {
      const temperatureMetric = this.props.householdDocHealthMetrics.find(
        (metric) => metric.metricName === "TEMPERATURE"
      );
      if (temperatureMetric && temperatureMetric.units === FAHRENHEIT) {
        this.temperatureToFahrenheit();
      }
    }

    if ((lastProps.isSyncingPersonaData === true || lastProps.isSyncingFitbitData === true)
      && !this.props.isSyncingPersonaData && !this.props.isSyncingFitbitData) {
      this.props.getSummary({ householdId: this.props.activeHouseholdId }); // Refresh health metrics data
    }
  }

  convertToFahrenheit(value) {
    if (value === null || value === -1) {
      return value;
    }
    return Math.round((value * 1.8 + 32) * 10) / 10; // Round to 1 decimal place
  }

  convertToCelsius(value) {
    if (value === null || value === -1) {
      return value;
    }
    return Math.round(((value - 32) / 1.8) * 100) / 100; // Round to 2 decimal place
  }

  temperatureToFahrenheit() {
    const temperatureSummary = this.props.summary.find(
      (metric) => metric.metricType === "TEMPERATURE"
    );
    if (temperatureSummary) {
      this.props.updateLatestMetrics(
        this.props.summary,
        Object.assign({}, temperatureSummary, {
          metricValue: this.convertToFahrenheit(temperatureSummary.metricValue),
          upperThreshold: this.convertToFahrenheit(
            temperatureSummary.upperThreshold
          ),
          lowerThreshold: this.convertToFahrenheit(
            temperatureSummary.lowerThreshold
          ),
          valueType: "°F",
        })
      );
    }
  }

  combineMetrics = (metrics, metricName1, metricName2) => {
    // Combine a metric with data from a complementary metric
    const metric1 = metrics.find((metric) => metric.type === metricName1);
    const metric2 = metrics.find((metric) => metric.type === metricName2);
    if (metric1 && metric2) {
      metric1.metricValue = [metric1.metricValue, metric2.metricValue];
      metric1.valueType = [metric1.valueType, metric2.valueType];
      if (!metric1.timestamp) {
        metric1.timestamp = metric2.timestamp;
      } else if (metric2.timestamp) {
        metric1.timestamp = moment(metric1.timestamp).isAfter(metric2.timestamp)
          ? metric1.timestamp
          : metric2.timestamp;
      }
      metric1.status =
        metric1.status === "WARNING" || metric2.status === "WARNING"
          ? "WARNING"
          : metric1.status === "NORMAL" || metric2.status === "NORMAL"
            ? "NORMAL"
            : "";
      metric1.message = [metric1.message, metric2.message];
      metric1.colour = metric1.colour.concat(metric2.colour);
      metric1.doubleMetric = true;
    }
  };

  enhanceMetrics = (summary) => {
    let metrics = [];
    if (summary && summary.length > 0) {
      metrics = JSON.parse(JSON.stringify(summary)).map((metric) => {
        metric = Object.assign(
          {},
          HEALTH_METRIC_TYPES[metric.metricType],
          metric
        ); // copy the extra properties into the response item
        metric.metricType = metric.metricType.toUpperCase(); // Ensure metric types are in upper case
        return metric;
      });

      this.combineMetrics(metrics, "HEART_RATE", "RESTING_HEART_RATE");
      this.combineMetrics(metrics, "WEIGHT", "BMI");
      metrics = metrics.filter(
        (metric) => metric.metricType !== "RESTING_HEART_RATE"
      ); // This is combined with the HEART_RATE item
      metrics = metrics.filter((metric) => metric.metricType !== "BMI"); // This is combined with the WEIGHT item

      metrics = metrics.filter(
        (metric) => metric.metricType !== "BLOOD_PRESSURE_DIASTOLIC"
      );
      metrics = metrics.filter(
        (metric) => metric.metricType !== "BLOOD_PRESSURE_SYSTOLIC"
      );

      return metrics;
    }
  };

  // Update the summary items state when we update a new metric
  onUpdateList = (updatedMetric) => {
    const { updateLatestMetrics, summary } = this.props;
    const newMetric = summary.find(
      (element) => element.metricType === updatedMetric.metricType
    );
    Object.assign(newMetric, updatedMetric);
    updateLatestMetrics(summary, newMetric);
  };

  syncFitbit = () => {
    if (this.props.isSyncedWithFitbit) {
      // Household already synced with Fitbit
      this.props.syncFitbitData(this.props.activeHouseholdId, moment().toISOString());
    }
  };
  syncPersona = () => {
    if (this.props.isPersonaHealthMetricsEnabled) {
      // Household already synced with Fitbit
      this.props.syncPersonaData(this.props.activeHouseholdId, moment().toISOString());
    }
  };

  isSyncEnabled = () => {
    return this.props.isSyncedWithFitbit || this.props.isPersonaHealthMetricsEnabled
  }

  getSyncStatus = () => {
    if (this.props.fitbitSyncError || this.props.personaSyncError) {
      return "Sync Error";
    } else if (!this.isSyncEnabled()) {
      return "Not Synced";
    } else {
      const { lastFitbitSync, lastPersonaSync } = this.props
      let lastSynced
      if (!lastFitbitSync && !lastPersonaSync) {
        return 'Last Synced: Unknown'
      } else if (!lastFitbitSync) {
        lastSynced = lastPersonaSync
      } else if (!lastPersonaSync) {
        lastSynced = lastFitbitSync
      } else {
        lastSynced = moment(lastFitbitSync).isAfter(moment(lastPersonaSync)) ? lastFitbitSync : lastPersonaSync
      }
      return "Last Synced: " + moment(lastSynced).fromNow();
    }
  };

  getSyncedMetrics() {
    let bloodPressureSynced = false;
    const syncedMetrics = this.props.householdDocHealthMetrics
      .filter((metric) => {
        if (metric.syncedWith) {
          if (metric.metricName.includes("BLOOD_PRESSURE")) {
            bloodPressureSynced = true;
            return false
          }
          return true
        }
        return false
      })
      .map((metric) => HEALTH_METRIC_TYPES[metric.metricName].friendlyName);
    if (bloodPressureSynced) {
      syncedMetrics.unshift(HEALTH_METRIC_TYPES['BLOOD_PRESSURE'].friendlyName)
    }
    return syncedMetrics
  }

  getSyncButtonText() {
    if (!this.isSyncEnabled()) return "Household sync not enabled";

    const syncedMetrics = this.getSyncedMetrics()
    if (syncedMetrics.length === 0)
      return "No metrics currently enabled for syncing\nPlease visit Household Settings > Health Metrics > Metric Thresholds";

    return `Sync now\nSynced metrics: ${syncedMetrics.join(", ")}`;
  }

  syncDevices() {
    if (this.getSyncedMetrics().length === 0) return

    this.syncFitbit()
    this.syncPersona()
  }

  render() {
    const {
      summary,
      isGettingSummary,
      isSyncingFitbitData,
      isHealthMetricsEnabled,
      isSyncingPersonaData,
      householdDocHealthMetrics,
      useNewLook,
      activeHouseholdId,
      isDeveloper,
      isSupport,
    } = this.props;
    const {
      expanded,
      currentMetric,
      showHealthMetricsEnabledModal,
      showAddMetricModal,
      seeMoreMetrics,
    } = this.state;

    const temperatureMetric = householdDocHealthMetrics.find(
      (metric) => metric.metricName === "TEMPERATURE"
    );

    return (
      <Card style={styles.container} automation_attribute="healthMetricsCard">
        {<CardHeader
          automation_attribute="healthMetricsHeader"
          avatar={
            <div>
              <InteliIcon
                size={55}
                name={"favorite_border"}
                family={"material"}
                color={theme.palette.okay.main}
              />
            </div>
          }
          title={<Typography variant="h5">Health Metrics</Typography>}
          subheader={
            <Typography color="textSecondary" variant="body1">
              Lastest data from health metrics
            </Typography>
          }
          action={
            <div style={{ flexDirection: "row", display: 'flex', alignItems: 'center' }}>
              <Typography variant="caption">{this.getSyncStatus()}</Typography>
              {(isSyncingFitbitData || isSyncingPersonaData) ? (
                <div style={{ margin: 8, paddingTop: 3 }}><CircularProgress size={24} /></div>
              ) : (
                <Tooltip
                  title={
                    <p
                      style={{
                        alignContent: "center",
                        textAlign: "center",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {this.getSyncButtonText()}
                    </p>
                  }
                >
                  <IconButton
                    disabled={
                      !this.isSyncEnabled()
                    }
                    onClick={() => this.syncDevices()}
                    style={{ height: 50, alignSelf: "center" }}
                  >
                    <SyncIcon
                      fontSize="large"
                      color={
                        this.isSyncEnabled() && this.getSyncedMetrics().length !== 0
                          ? "primary"
                          : "disabled"
                      }
                      style={{
                        backgroundColor: "#F0F0F0",
                        borderRadius: "50%",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}

              {!useNewLook && <IconButton
                disabled={!this.props.isHealthMetricsEnabled}
                onClick={() => this.setState({ expanded: !expanded })}
              >
                {!expanded ? (
                  <ExpandMoreIcon
                    automation_attribute="healthMetricsExpandArrow"
                    fontSize="large"
                    color={
                      this.props.isHealthMetricsEnabled ? "primary" : "disabled"
                    }
                    style={{ backgroundColor: "#F0F0F0", borderRadius: "50%" }}
                  />
                ) : (
                  <ExpandLessIcon
                    automation_attribute="healthMetricsLessArrow"
                    fontSize="large"
                    color={
                      this.props.isHealthMetricsEnabled ? "primary" : "disabled"
                    }
                    style={{ backgroundColor: "#F0F0F0", borderRadius: "50%" }}
                  />
                )}
              </IconButton>}
            </div>
          }
        ></CardHeader>}
        <div automation_attribute="healthMetricsContent">
          <CardContent>
            {/* The title items  */}
            <Grid
              container
              justify="space-around"
              style={{ flexDirection: "row" }}
            >
              {isHealthMetricsEnabled &&
                !isGettingSummary &&
                summary &&
                summary.length > 0 ? (
                  this.enhanceMetrics(this.props.summary).map((metric, key) => {
                    return (
                      <MetricItem
                        onItemClick={(metricData) =>
                          this.handleShowAddMetricModal(true, metricData)
                        }
                        key={key}
                        data={metric}
                        householdV2={this.props.householdV2}
                        temperatureUnits={access(temperatureMetric, "units")}
                      />
                    );
                  })
                ) : !isHealthMetricsEnabled ? (
                  <NoHealthMetrics
                    message={"Health metrics not enabled"}
                    showEnabledButton={true}
                    enable={() => this.handleShowEnabledModal()}
                  />
                ) : isGettingSummary ? (
                  <GettingHealthMetrics />
                ) : (
                  <NoHealthMetrics message={"No health data found"} />
                )}
            </Grid>
            {currentMetric && (
              <AddMetricModal
                metricData={currentMetric}
                open={showAddMetricModal}
                handleClose={() => this.setState({ showAddMetricModal: false })}
                onUpdateList={(updatedMetric) =>
                  this.onUpdateList(updatedMetric)
                }
                temperatureUnits={access(temperatureMetric, "units")}
              />
            )}
            <HealthMetricsEnabledModal
              open={showHealthMetricsEnabledModal}
              close={() =>
                this.setState({ showHealthMetricsEnabledModal: false })
              }
            />
          </CardContent>

          {/* The hidden graphs section */}
          <Collapse in={useNewLook || expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Grid
                container
                justify={"space-around"}
                automation_attribute="healthMetricsGraphs"
              >
                {summary &&
                  summary.length > 0 &&
                  this.enhanceMetrics(summary).map((metric, key) => (
                    <HealthGraph
                      key={key}
                      friendlyName={metric.friendlyName}
                      subtitle={metric.subtitle}
                      metricType={metric.metricType}
                      graphType={metric.graphType}
                      colour={metric.colour}
                      latestValue={metric.metricValue}
                      doubleMetric={metric.doubleMetric}
                      temperatureUnits={access(temperatureMetric, "units")}
                    />
                  ))}
              </Grid>
            </CardContent>
          </Collapse>

          <div style={styles.moreMetrics}>
            <Button
              onClick={() => this.setState({ seeMoreMetrics: !seeMoreMetrics })}
              color="primary"
              startIcon={seeMoreMetrics ? <ChevronLeft /> : <ChevronRight /> }
              variant="outlined">{seeMoreMetrics ? `See Less Metrics` :  `See More Metrics`}</Button>
          </div>

          { (isDeveloper || isSupport) && seeMoreMetrics && <AllMetrics householdId={activeHouseholdId} /> }
        </div>

      </Card>
    );
  }
}

HealthMetrics.propTypes = {
  getSummary: PropTypes.func,
  summary: PropTypes.array,
  summaryError: PropTypes.any,
  isGettingSummary: PropTypes.bool,
  activeHouseholdId: PropTypes.string.isRequired,
  updateLatestMetrics: PropTypes.func,
  householdV2: PropTypes.object,
  fitbitSyncError: PropTypes.string,
  personaSyncError: PropTypes.string,
  isSyncingFitbitData: PropTypes.bool,
  isSyncingPersonaData: PropTypes.bool,
  clearSyncData: PropTypes.func,
  syncFitbitData: PropTypes.func,
  fitbitInitialSync: PropTypes.func,
  refreshHousehold: PropTypes.func,
  updateSuccess: PropTypes.bool,
  isSaving: PropTypes.bool,
  isSyncedWithFitbit: PropTypes.bool,
  isHealthMetricsEnabled: PropTypes.bool,
  isPersonaHealthMetricsEnabled: PropTypes.bool,
  enableHealthMetrics: PropTypes.func,
  openHealthMetricSettings: PropTypes.func,
  householdDocHealthMetrics: PropTypes.object,
  isFetching: PropTypes.bool,
  updateTemperatureUnits: PropTypes.func,
  useNewLook: PropTypes.bool,
  syncPersonaData: PropTypes.func,
  lastFitbitSync: PropTypes.bool,
  lastPersonaSync: PropTypes.bool,
  id: PropTypes.string,
  isDeveloper: PropTypes.bool,
  isSupport: PropTypes.bool,
};

const styles = {
  container: {
    marginBottom: 16,
  },
  moreMetrics: {
    justifyContent: 'center',
    display: 'flex',
    padding: 24,
  },
};

export default HealthMetrics;
